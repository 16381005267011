import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getAccessToken } from '../utility/utils';
import {PUBLIC_PATH} from 'constants/index'


const PrivateRoute = ({ component: Component, ...rest }) => {

 
  return (
    <Route
      {...rest}
      render={
        props => (<Component {...props} /> )
      }
    />
  );
};

export default PrivateRoute;
