//@ts-check
import { AnnotationStepper } from 'dsilo-ui-components';
import 'dsilo-ui-components/dist/index.css';
import PlusIcon from 'images/plus-icon.svg';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { clearSuccessMsg, clearUploadPDFData, downloadContractPDFAction, getContractDataById, getDataModelById, saveDataForTraining, 
  updateDataModelData, uploadPDF, getTopSuggestions } from '../../store/actions';
import { Loader } from "../loader";
import { PUBLIC_PATH } from '../../constants';

const acceptedFiles = ['application/pdf'];
const steps = ['Upload', 'Annotate', 'Download'];

const contractTitle = {
  0: 'Upload contracts',
  1: 'Annotate contracts',
  2: 'Annotate download',
};

const PDFAnnotator = (props) => {

  const { selectedDataElement, fetchType, selectedDataModel, selectedInnerDataElement, modelDataType, isLinkedToModel } = props.data.chartData;
  const [contractAnnotations, setContractAnnotations] = useState([])
  const { formData } = props;
  const { successMsg, loading } = formData;
  const dispatch = useDispatch()
  const { uploadedPDFData } = props.formData;

  const generatePayloadForTableData = () => {
    let payloadForTableData = {
      id: props.match.params.id,
      dataModelId: selectedDataModel,
      appId: props.match.params.appid,
      fields: selectedDataElement.map(i => i.value).join(","),
      page: "0", rowsPerPage: "all"
    }
    if (fetchType) {
      payloadForTableData['fetchType'] = fetchType;
    }
    if (selectedInnerDataElement) {
      payloadForTableData['viewFields'] = selectedInnerDataElement.map(i => i.value).join(",")
    }

    return payloadForTableData;
  }

  const generatePayloadForDataModel = () => {
    let payloadForDataModel = {
      appid: props.match.params.appid,
      isDownload: false,
      openFile: false,
      dataModelId: selectedDataModel,
      orgId: props.user.orgId,
    };
    if (fetchType) {
      payloadForDataModel['fetchType'] = fetchType;
    }
    if (selectedInnerDataElement) {
      payloadForDataModel['viewFields'] = selectedInnerDataElement.map(i => i.value).join(",")
    }
    return payloadForDataModel
  }

  /**
   * useEffect
   * This useEffect used for fetching PDF and annotations from API
   * And also to fetch dataElements for Label view
   */
  useEffect(() => {
    if (props.match.params.id) {
      let payloadForPDFDownload = {
        appid: props.match.params.appid,
        _id: props.match.params.id,
        isDownload: false,
        openFile: false,
        dataModelId: selectedDataModel,
      };
      let payloadForTableData = generatePayloadForTableData();
      let payloadForDataModel = generatePayloadForDataModel()
      let promiseArray = [
        dispatch(downloadContractPDFAction({ ...payloadForPDFDownload, dataUniqueId: props.data.chartUniqueId + '_PDF' })), // download pdf
        dispatch(getContractDataById({ ...payloadForTableData, dataUniqueId: props.data.chartUniqueId + '_annotator' })),  // filter data if filter is there
        dispatch(getDataModelById({ ...payloadForDataModel, dataUniqueId: props.data.chartUniqueId + '_labels' })) // fetchLabels
      ].filter(Boolean)
      Promise.all(promiseArray)
    }

  }, [props.match.params.id]);

  const handlePositionObjectIssues = (_annotation) => {
    if (_annotation['position.boundingRect'] || _annotation['position.rects'] || _annotation['position.pageNumber']) {
      let annotation = { ..._annotation }
      annotation.position = annotation.position ? { ...annotation.position } : {}
      annotation.comment = annotation.comment ? { ...annotation.comment } : {}
      annotation.type = annotation.type ? { ...annotation.type } : {}
      annotation.group = annotation.group ? { ...annotation.group } : {}
      if (annotation['position.boundingRect']) {
        annotation['position']['boundingRect'] = annotation['position.boundingRect']
        delete annotation['position.boundingRect']
      }
      if (annotation['position.rects']) {
        annotation['position']['rects'] = annotation['position.rects']
        delete annotation['position.rects']
      }
      if (annotation['position.pageNumber']) {
        annotation['position']['pageNumber'] = annotation['position.pageNumber']
        delete annotation['position.pageNumber']
      }
      if (annotation['type.label']) {
        annotation['type']['label'] = annotation['type.label']
        delete annotation['type.label']
      }
      if (annotation['type.value']) {
        annotation['type']['value'] = annotation['type.value']
        delete annotation['type.value']
      }
      if (annotation['group.label']) {
        annotation['group']['label'] = annotation['group.label']
        delete annotation['group.label']
      }
      if (annotation['group.value']) {
        annotation['group']['value'] = annotation['group.value']
        delete annotation['group.value']
      }
      return annotation;
    } else {
      return _annotation;
    }
  }

  useEffect(() => {
    let _annotations = []
    if (formData.document[props.data.chartUniqueId + '_annotator']?.data?.length) {
      console.log("formData.document[props.data.chartUniqueId + '_annotator']", formData.document[props.data.chartUniqueId + '_annotator']);
      _annotations = formData.document[props.data.chartUniqueId + '_annotator']?.data.map(_annotation => {
        let _a = handlePositionObjectIssues(_annotation)
        console.log("_a ==", { _a });
        return _a
      })
      console.log("_annotations", { _annotations });
      setContractAnnotations([{ annotations: _annotations }])
    }

  }, [formData.document[props.data.chartUniqueId + '_annotator']])


  useEffect(() => {
    if (successMsg) {
      let payload = {
        id: props.match.params.id,
        dataModelId: selectedDataModel,
        appId: props.match.params.appid,
        fields: selectedDataElement.map(i => i.value).join(","),
      }
      if (fetchType) {
        payload['fetchType'] = fetchType;
      }
      if (selectedInnerDataElement) {
        payload['viewFields'] = selectedInnerDataElement.map(i => i.value).join(",")
      }
      dispatch(getContractDataById({ ...payload, dataUniqueId: props.data.chartUniqueId + '_data' }));
    }
  }, [successMsg])

  const onSave = (annotateData) => {
    let payloadToBeSend = {};
    payloadToBeSend._id = props.match.params.id;
    payloadToBeSend[selectedDataElement[0].value] = [...annotateData.annotations];
    let _formData = {
      appid: props.match.params.appid,
      dataModelId: selectedDataModel,
      payload: payloadToBeSend,
      isMergeable: true
    }
    dispatch(updateDataModelData(_formData))
  };

  const onUploadPDF = ({ pdf }) => {
    let extractType = props.data.chartData?.extractType;
    let dataModelId = props.data.chartData?.selectedDataModel;
    if (pdf && dataModelId) {
      let payload = { dataModelId, pdf, appId: props.match.params.appid }

      if (extractType) {
        payload.extractType = extractType
      }
      if (modelDataType) {
        payload.modelDataType = modelDataType;
      }
      dispatch(uploadPDF(payload));
    } else {
      if (!dataModelId) {
        console.log('dataModelId missing')
      } else {
        toast.info('Please select Pdf File');
      }
    };
  }


  useEffect(() => {
    if (uploadedPDFData && uploadedPDFData.length) {
      const { chartData } = props.data;
      props.clearUploadPDFData()
      // go to details page
      let id = uploadedPDFData[0].id
      //TODO:  all re directions need to be based pageId
      props.history.push(`/${PUBLIC_PATH}${props.match.params.appid}/page/${props.page.url}/${id}`)
    }
  }, [uploadedPDFData]);

  const onSaveAndTrain = () => {
    if (isLinkedToModel) {
      let payload = {
        dataModelId: selectedDataModel,
        appId: props.match.params.appid,
        type: modelDataType !== 'testing' ? 'prodtotrain' : 'testtotrain',
        data: { documentId: props.match.params.id }
      }
      props.saveDataForTraining(payload);
    }
  }

  const getTopSuggestions = ()=>{
    let payload = {
      dataModelId: selectedDataModel,
      data: {"documentId": props.match.params.id},
      appId: props.match.params.appid,
    }
    props.getTopSuggestions(payload);
  }

  const getLabels = ()=>{
    return formData.dataModel ? formData.dataModel[props.data.chartUniqueId + '_labels']?.dataElements?.filter(i=> i.isModelKey) : []
  }

  let data = {
    pdf: uploadedPDFData,
    downloadedPDF: formData.pdfDocument && formData.pdfDocument[props.data.chartUniqueId + '_PDF']?.pdf,
    contract: contractAnnotations,
    message: successMsg,
    loading: props.formData[props.data.chartUniqueId + '_annotator_loading'] || loading,
    topSuggestions: props.formData.topSuggestions,
  }
  return (<div>
    <AnnotationStepper
      noAccordion
      labels={getLabels()}
      hideHeader
      user={props.user}
      annotateType={'ciep'}
      hideSuccessMessage={() => dispatch(clearSuccessMsg())}
      acceptedFiles={acceptedFiles}
      steps={steps}
      contractTitle={contractTitle}
      PlusIcon={PlusIcon}
      Loader={<Loader />}
      redirectTo={'invoice'}
      history={props.history}
      onSaveAndTrain={onSaveAndTrain}
      getTopSuggestions={getTopSuggestions}
      getContractById={() => { }}
      uploadContractPDFAction={onUploadPDF}
      downloadContractPDF={() => { }}
      downloadAnnotations={() => { }}
      updateContractAnnotations={onSave}
      clearPDFData={() => dispatch(clearUploadPDFData())}
      reRun={() => { }}
      contractAnnotations={data}
      match={props.match}
      showSaveToTrainButton={isLinkedToModel && modelDataType}
    />
  </div>);
};

const mapDispatchToProps = {
  getContractDataById,
  updateDataModelData,
  clearSuccessMsg,
  clearUploadPDFData,
  saveDataForTraining,
  downloadContractPDFAction,
  getTopSuggestions,
};

const mapStateToProps = state => {
  return {
    appConfig: state.appConfig.app,
    apps: state.app.app,
    login: state.login,
    user: state.user,
    formData: state.form,
  };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFAnnotator)));
