import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles, Paper } from '@material-ui/core';
import {
	getLineData, getBarData, getModelData, getPieChartData,
	clearReduxChartDataOfCurrentComponent
} from '../../store/actions';
import { BarChart, Doughnut, LineChart, PieChart, Treemap } from 'dsilo-ui-components';
import BulletChart from './bulletChart/BulletChart';

const options = {
	maintainAspectRatio: true
};

const useStyles = makeStyles((theme) => ({
	paper: {
		height: '99%',
		width: '100%'
	}
}));

const Chart = (props) => {
	const [data, setData] = useState({});
	const [height, setHeight] = useState(0)
	const [width, setWidth] = useState(0)

	const classes = useStyles();

	useEffect(() => {
		setData(JSON.parse(JSON.stringify(props.data)));
	}, [props.data]);

	useEffect(() => {
		let devId = document.getElementById(props.data.chartUniqueId)
		let w = devId?.style?.width
		let h = devId?.style?.height
		let chartTitleHeight = window.screen.height > 1000 ? 43 : 60;

		// console.log("devId?.style?.width = = = = = in", parseInt(w), parseInt(h), devId, chartTitleHeight)

		let width, height
		if (w) {
			// width = parseInt(w) - (props.navBarOpen ? 290 : 120)
			width = parseInt(w)
			setWidth(width)
		}
		if (h) {
			height = parseInt(h)
			if(data?.chartData?.title) height = parseInt(h) - chartTitleHeight
			setHeight(height)
		}
	})

	const renderChart = (data) => {
		switch (data.chartType) {
			case "bar":
				return <BarChart {...props} formData={props.formData} options={options} queryString={props.queryString}
						width={width} height={height} />
			case "bullet":
				return <BulletChart {...props} formData={props.formData} options={options} queryString={props.queryString}
						width={width} height={height} />
			case "doughnut":
				return <Doughnut {...props} page={props.page} chartUniqueId={data.chartUniqueId} data={data.chartData.data}
						chartData={data.chartData} options={options} queryString={props.queryString} appId={props.appId}
						_id={data._id} width={width} height={height} />
			case "line":
				return <LineChart {...props} formData={props.formData} options={options} queryString={props.queryString}
						width={width} height={height} />
			case "pie":
				return <PieChart {...props} page={props.page} chartUniqueId={data.chartUniqueId} data={data.chartData.data}
						chartData={data.chartData} options={options} queryString={props.queryString} appId={props.appId}
						width={width} height={height} _id={data._id} />
			case "treemap":
				return <Treemap {...props} formData={props.formData} options={options} queryString={props.queryString}
						width={width} height={height} />
			default:
				return null;
		}
	}

	return (
    <>
      {data ? (
        <Paper elevation={props.layoutStyles?.chartLevelPaper ? 1 : 0} className={classes.paper}>
          {renderChart(data)}
        </Paper>
      ) : null}
    </>
  );
};

const mapDispatchToProps = {
	getBarData,
	getLineData,
	getModelData,
	getPieChartData,
	clearReduxChartDataOfCurrentComponent
};

const mapStateToProps = state => {
	return {
		formData: state.form,
		chart: state.chart,
		user: state.user,
		pages: state.pages.page,
	};
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(Chart)));
