import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import jwtService from 'jwtService';
import { ActionTypes } from 'constants/index';
import { showMessage, hideMessage, logoutUser, setUserData, submitLoginwithToken, setUserDetailsLoading } from 'store/actions'

class Auth extends Component {
    /*eslint-disable-next-line no-useless-constructor*/
    constructor(props) {
        super(props);

        /**
         * Comment the line if you do not use JWt
         */
        this.jwtCheck();
    }

    componentDidMount() {
		// Comment to make it work in local
        //TODO: We need to check why it is failing
		// window.addEventListener("focus", this.onFocus)
    }

    onFocus = () => {
        jwtService.refreshToken();
    }

    jwtCheck = () => {
        jwtService.on('onAutoLogin', () => {

            this.props.showMessage({ message: 'Logging in with JWT' });

            /**
             * Sign in and retrieve user data from Api
             */
            this.props.setUserDetailsLoading(true)
            jwtService.signInWithToken()
                .then(user => {
                    console.log("user", user);
                    if (user) {
                        this.props.submitLoginwithToken(user);
                        this.props.setUserData(user);
                        this.props.showMessage({ message: 'Logged in with JWT' });
                    }
                    this.props.setUserDetailsLoading(false)
                })
                .catch(error => {
                    this.props.showMessage({ message: error });
                    this.props.setUserDetailsLoading(false)
                })
        });

        jwtService.on('onAutoLogout', (message) => {
            if (message) {
                this.props.showMessage({ message });
            }
            this.props.logout();
        });

        jwtService.init();
    };

    render() {
        const { children } = this.props;

        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        logout: logoutUser,
        setUserData: setUserData,
        submitLoginwithToken: submitLoginwithToken,
        showMessage: showMessage,
        hideMessage: hideMessage,
        setUserDetailsLoading: setUserDetailsLoading,
    },
        dispatch);
}

export default connect(null, mapDispatchToProps)(Auth);
