import React, { useState, useEffect } from "react";
import { Grid, Tabs, Tab, AppBar, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core"
import BasicInfo from "./BasicInfo";
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import { getMembersList, clearReduxDataOfCurrentComponent, getRolesMembers, getGroups, createGroup } from '../../store/actions';
import { connect } from 'react-redux';
import { Loader } from "../../components/loader";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from "react-router-dom";
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    tabController: {
        "& .MuiTab-textColorInherit ": {
            minWidth: "0px"
        }
    },
    appBar: {
        boxShadow: "none !important"
    },
    ContactTabs: {
        marginTop: 'none !important',
        marginBottom: 'none !important',
        border: "3px solid #eeee",
    },
    paper: {
        width: '100%',
        // marginBottom: theme.spacing(2),
        border: "3px solid #eeee",
        marginLeft: '-41px !important'
    },
    backIconCls: {
        padding: '0px !important'
    }
}))

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AddGroup = (props) => {
    console.log('AddGroup-props', props)
    const [tabVal, setTabVal] = useState(0);
    const classes = useStyles();
    const [memberDetails, setMemberDetails] = useState({})
    const [membersRoles, setMembersRoles] = useState()
    const [membersList, setMembersList] = useState()
    const [fianlFormData, setFinalFormData] = useState({})
    const [validate, setValidate] = useState({})
    let history = useHistory();

    useEffect(() => {
        if (props.match.params.id !== 'new') {
            fetchGroupData()
            fetchData()
        } else {
            setMemberDetails({})
            fetchData()
        }
    }, [])

    const fetchGroupData = () => {
        Promise.all([
            props.getGroups({
                appId: props.appId,
                dataUniqueId: props.data?.chartUniqueId + '_group_data',
                id: props.match.params.id
            })
        ].filter(Boolean))
    }

    const fetchData = () => {
        Promise.all([
            props.getRolesMembers({
                appId: props.appId,
                dataUniqueId: props.data?.chartUniqueId + '_member_roles_data',
                orgId: props.user.orgId,
            }),
            props.getMembersList({
                appId: props.appId,
                dataUniqueId: props.data?.chartUniqueId + '_member_list_data',
                orgId: props.user.orgId,
            })
        ].filter(Boolean))
    }

    useEffect(() => {
        if (props.formData?.document && props.formData.document[props.data?.chartUniqueId + '_group_data']) {
            let grpObj = props.formData.document[props.data?.chartUniqueId + '_group_data']

            let obj = {
                "name": grpObj.name,
                "description": grpObj.description,
                "assignedRoles": grpObj.roles?.map(item => ({ label: item.name, value: item.id })),
                "members": grpObj.members?.map(ele => ({ label: ele.name ? ele.name : ele.label, value: ele.id ? ele.id : ele.value })),
                "_id": grpObj._id,

            }
            setFinalFormData({ ...fianlFormData, ...obj })
            // setMemberDetails(obj)
            setValidate({
                "name": obj.name ? false : true,
                "description": obj.description ? false : true
            })
        }
    }, [props.formData && props.formData?.document[props.data?.chartUniqueId + '_group_data']])

    useEffect(() => {
        if (props.formData?.document && props.formData.document[props.data?.chartUniqueId + '_member_roles_data']) {
            let memRoles = props.formData.document[props.data?.chartUniqueId + '_member_roles_data']?.map(item => ({ label: item.description, value: item._id }))
            setMembersRoles(memRoles)
        }
    }, [props.formData && props.formData?.document[props.data?.chartUniqueId + '_member_roles_data']])

    useEffect(() => {
        if (props.formData?.document && props.formData.document[props.data?.chartUniqueId + '_member_list_data']) {
            let memList = props.formData.document[props.data?.chartUniqueId + '_member_list_data']?.map(item => ({
                label: item.firstName ? item.firstName + ' ' + item.lastName : item.name,
                value: item._id,
            }))
            setMembersList(memList)
        }
    }, [props.formData && props.formData?.document[props.data?.chartUniqueId + '_member_list_data']])

    useEffect(() => {
        return () => {
            clearCurrentChartReduxStore()
            setMemberDetails({})
        }
    }, [])

    const clearCurrentChartReduxStore = () => {
        let subKeys = [
            props.data?.chartUniqueId + '_member_roles_data',
            props.data?.chartUniqueId + '_member_list_data',
            props.data?.chartUniqueId + '_group_data',
            props.data?.chartUniqueId + '_create_group'
        ]

        props.clearReduxDataOfCurrentComponent({
            key: "document",
            subKey: subKeys
        })
    }

    const handlesSaveFunctionality = (data) => {
        setFinalFormData({ ...fianlFormData, ...data })
    }

    const validator = (data) => {
        setValidate({ ...validate, ...data })
    }
    const handleBackBtnClick = () => {
        history.goBack()
    }

    if (props.formData[props.data?.chartUniqueId + '_member_roles_data_loading'] || props.formData[props.data?.chartUniqueId + '_member_list_data_loading']) {
        return <Loader />
    }

    console.log('fianlFormData', fianlFormData)

    return (
        <div style={{position: 'absolute'}}>
            <div style={{ display: 'flex' }}>
                <IconButton onClick={handleBackBtnClick} className={classes.backIconCls} title={'Back'}>
                    <ArrowBackIosIcon />
                </IconButton>
                <h1>{'Group View'}</h1>
            </div>
            <Divider />
            <Grid container style={{ marginTop:30 , position: 'relative', overflow: 'visible'}}>
                <BasicInfo
                    {...props}
                    memberDetails={memberDetails}
                    fianlFormData={fianlFormData}
                    membersList={membersList}
                    membersRoles={membersRoles}
                    createGroup={props.createGroup}
                    validator={validator}
                    validate={validate}
                    handlesSaveFunctionality={handlesSaveFunctionality}
                />
            </Grid>
        </div>);
};

const mapDispatchToProps = {
    getMembersList,
    clearReduxDataOfCurrentComponent,
    getRolesMembers,
    getGroups,
    createGroup
}
const mapStateToProps = state => {
    return {
        formData: state.form,
        member: state.appConfig.members,
        user: state.user,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddGroup)))


