import clsx from 'clsx';
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { styled } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import TitleTextField from '../TitleEditor';
import useStyles from './styles';

const ListIconButton = styled(IconButton)({
    padding: 0,
});

const ChatHistoryListItem = props => {
    const classes = useStyles();
    const { prevChat, index } = props;
    const [editTitle, setEditTitle] = useState(false);
    const [chatTitle, setChatTitle] = useState(prevChat?.name || '');

    const handleTitleChange = event => {
        setChatTitle(event.target.value);
    };

    const editChatTitle = () => {
        setEditTitle(prev => !prev);
    };

    const updateChatName = () => {
        if (chatTitle !== prevChat?.name) {
            props.updateChatTitle(prevChat, chatTitle);
        }
        setEditTitle(false);
    };

    return (
        <>
            {editTitle ? (
                <TitleTextField
                    title={chatTitle}
                    handleTitleChange={handleTitleChange}
                    editTitle={editChatTitle}
                    updateTitle={updateChatName}
                />
            ) : (
                <ListItem
                    key={prevChat._id + index}
                    className={clsx(classes.listItemRoot, {
                        [classes.selectedChat]: props.selectedChatId === prevChat._id,
                    })}
                    button
                    selected={props.selectedChatId === prevChat._id}
                >
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={2} onClick={() => props.getChatHistoryByChatId(prevChat)}>
                            <ListItemIcon
                                className={classes.listItemIconRoot}
                            // onClick={event => props.getChatHistoryByChatId(event, prevChat._id)}
                                // onClick={event => props.getChatHistoryByChatId(event, prevChat._id)}
                            >
                                <QuestionAnswerIcon fontSize="small" />
                            </ListItemIcon>
                        </Grid>
                        <Grid item xs={7} onClick={() => props.getChatHistoryByChatId(prevChat)}>
                            <ListItemText
                                classes={{
                                    root: classes.listItemTextRoot,
                                    primary: classes.listItemTextPrimary,
                                }}
                                primary={chatTitle || 'New Chat'}
                            // onClick={event => props.getChatHistoryByChatId(event, prevChat._id)}
                                // onClick={event => props.getChatHistoryByChatId(event, prevChat._id)}
                            />
                        </Grid>
                        {props.chatTabVal !== "all" && <Grid item xs={3} style={{ display: 'flex', flexDirection: 'row' }}>
                            <ListIconButton onClick={editChatTitle}>
                                <EditIcon fontSize="small" style={{ marginRight: '4px' }} />
                            </ListIconButton>
                            <ListIconButton onClick={() => props.deleteChat(prevChat._id)}>
                                <DeleteIcon fontSize="small" />
                            </ListIconButton>
                        </Grid>}
                    </Grid>
                </ListItem>
            )}
        </>
    );
};

export default ChatHistoryListItem;
