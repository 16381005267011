import { ActionTypes } from "../../constants";

export const createNewPrompt = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_PROMPT_RESPONSE, payload: data });
}

export const getChatHistory = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_CHAT_HISTORY_DATA, payload: data });
}

export const getChatHistoryById = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_CHAT_HISTORY_BY_ID_DATA, payload: data });
}

export const getAllWorkspaces = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_WORKSPACES_DATA, payload: data });
}

export const getWorkspacesById = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_WORKSPACES_BY_ID_DATA, payload: data });
}

export const clearReduxChatDataOfCurrentComponent = (data) => dispatch => {
  dispatch({ type: ActionTypes.CLEAR_REDUX_CHAT_DATA_OF_COMPONENT, payload: data });
}

export const setCurrentComponent = (data) => dispatch => {
  dispatch({ type: ActionTypes.SET_CHAT_CURRENT_COMPONENT, payload: data });
}

export const createNewWorkspace = (data) => dispatch => {
  dispatch({ type: ActionTypes.CREATE_WORKSPACE, payload: data });
}

export const updateWorkspace = (data) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_WORKSPACE, payload: data });
}

export const addNewChartToWorkspace = (data) => dispatch => {
  dispatch({ type: ActionTypes.ADD_NEW_CHART_TO_WORKSPACE, payload: data });
}

export const deleteChartFromWorkspace = (data) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_CHART_FROM_WORKSPACE, payload: data });
}

export const updateWorkspaceTitle = (data) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_WORKSPACE_TITLE, payload: data });
}

export const deleteChatFromHistory = (data) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_CHAT_FROM_HISTORY, payload: data });
}

export const deleteQuestionFromChatHistory = (data) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_QUESTION_FROM_CHAT_HISTORY, payload: data });
}

export const updateChatTitle = (data) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_CHAT_TITLE, payload: data });
}

export const publishOrDraftWorkspace = (data) => dispatch => {
  dispatch({ type: ActionTypes.PUBLISH_OR_DRAFT_WORKSPACE, payload: data });
}

export const deleteWorkspace = (data) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_WORKSPACE, payload: data });
}

export const getAllSaveForLaterChats = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_ALL_SAVEFORLATER_CHATS, payload: data });
}

export const getSingleSaveForLaterChat = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_SAVEFORLATER_BY_CHAT, payload: data });
}

export const getSampleQuestions = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_SAMPLE_QUESTIONS, payload: data });
}

export const getQuestionComments = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_QUESTION_COMMENTS, payload: data });
}

export const createComment = (data) => dispatch => {
  dispatch({ type: ActionTypes.CREATE_QUESTION_COMMENTS, payload: data });
}

export const likeQuestion = (data) => dispatch => {
  dispatch({ type: ActionTypes.QUESTION_LIKE, payload: data });
}

export const disLikeQuestion = (data) => dispatch => {
  dispatch({ type: ActionTypes.QUESTION_DISLIKE, payload: data });
}

export const savedForLater = (data) => dispatch => {
  dispatch({ type: ActionTypes.QUESTION_SAVED_FOR_LATER, payload: data });
}

export const rating = (data) => dispatch => {
  dispatch({ type: ActionTypes.QUESTION_RATING, payload: data });
}

export const updateQuestionChartType = (data) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_QUESTION_CHART_TYPE, payload: data });
}

export const questionSendEmail = (data) => dispatch => {
  dispatch({ type: ActionTypes.QUESTION_SEND_EMAIL, payload: data });
}

export const updateQuestionEmailStatus = (data) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_QUESTION_EMAIL_SUCCESS_STATE, payload: data });
}

export const updateSelectedQuestionChartData = (data) => dispatch => {
  dispatch({ type: ActionTypes.QUESTION_SELECTED_CHART_DATA, payload: data });
}

export const updateSampleQuestionResponse = (data) => dispatch => {
  dispatch({ type : ActionTypes.GET_CHAT_HISTORY_DATA_BY_ID_SUCCESS, payload: data });
}

export const getAutocompleteSuggestions = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_AUTOCOMPLETE_SUGGESTIONS, payload: data });
} 