import Box from "@material-ui/core/Box";
import React, { useEffect, useRef, useState } from "react";

function isTop(contentElement) {   
    if (!contentElement) {
        return false;
    }

    return (
        Math.floor(contentElement.scrollTop) <= 0
    );
}

const InfiniteScroll = (props) => {
    const {
        onTopHit,
        isLoading,
        hasMoreData,
        loadOnMount,
        children,
        uniqueId,
        pageNumber
    } = props;
    const [initialLoad, setInitialLoad] = useState(true);
    const contentRef = useRef(null);

    useEffect(() => {
        if (loadOnMount && initialLoad) {
            onTopHit();
            setInitialLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadOnMount, initialLoad]);

    useEffect(() => {
        const contentElement = document.getElementById(uniqueId);
        const onScroll = () => {
            if (
                !isLoading &&
                hasMoreData &&
                isTop(contentElement)
            ) {
                onTopHit();
            }
        };
        if (contentElement) contentElement.addEventListener("scroll", onScroll);
        return () => {
            if (contentElement) contentElement.removeEventListener("scroll", onScroll);
        }
    }, [isLoading, hasMoreData, pageNumber]);

    return (
        <Box ref={contentRef} height="100%">
            {children}
        </Box>
    );
}

export default InfiniteScroll;