import React, { useState } from 'react';

import StepWithBlocksComponent  from  './StepWithBlocks';

const RenderComponentBasedOnStep = (props) => {
    const { selectedStep } = props
    // console.log("it is selected step ===", selectedStep)
    return (
        <React.Fragment>
            {
                (function () {
                    switch (selectedStep.type) {
                        case "StepWithBlocks":
                            return <StepWithBlocksComponent key={selectedStep.name} {...props} />
                        default:
                            return null;
                    }
                })()
            }
        </React.Fragment>
    )
}

export default RenderComponentBasedOnStep