import React, { useState, useEffect } from "react";
import { Grid, Tabs, Tab, AppBar,IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core"
import BasicInfo from "./BasicInfo";
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import { clearReduxDataOfCurrentComponent,getTickets,createTickets,getMembersList } from '../../store/actions';
import { connect } from 'react-redux';
import { Loader } from "../loader";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from "react-router-dom";
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    tabController: {
        "& .MuiTab-textColorInherit ": {
            minWidth: "0px"
        }
    },
    appBar: {
        boxShadow: "none !important"
    },
    ContactTabs: {
        marginTop: 'none !important',
        marginBottom: 'none !important',
        border: "3px solid #eeee",
    },
    paper: {
        width: '100%',
        // marginBottom: theme.spacing(2),
        border: "3px solid #eeee",
        marginLeft:'-41px !important'
    },
    backIconCls:{
        padding:'0px !important'
    }
}))

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const AddTickets = (props) => {
    console.log('AddTickets-props',props)
    const [tabVal, setTabVal] = useState(0);
    const classes = useStyles();
    const [memberDetails,setMemberDetails]= useState({})
    const [membersList, setMembersList] = useState()
    const [fianlFormData,setFinalFormData]=useState({})
    const [validate, setValidate] = useState({})
    let history = useHistory();
    const { _id:userId } = props.member


    useEffect(()=>{
        if(props.match.params.id !=='new'){
            fetchTicketData()
            // fetchData()
        }else{
            setMemberDetails({})
            fetchData()
        }
    },[])

    const fetchTicketData=()=>{
        Promise.all([
            props.getTickets({
                appId: props.appId,
                dataUniqueId:  props.data?.chartUniqueId+'_tickets_data',
                id:props.match.params.id,
                memberid: userId,
                dataModelId: props.data?.chartData?.selectedDataModel
            }),
            props.getMembersList({
                appId: props.appId,
                dataUniqueId: props.data?.chartUniqueId + '_member_list_data',
                orgId: props.user.orgId,
            })
        ].filter(Boolean))
    }

    const fetchData=()=>{
        Promise.all([
            props.getMembersList({
                appId: props.appId,
                dataUniqueId: props.data?.chartUniqueId + '_member_list_data',
                orgId: props.user.orgId,
            })
        ].filter(Boolean))
    }

    useEffect(() => {
		if (props.formData?.document && props.formData.document[props.data?.chartUniqueId+'_tickets_data']) {
            let ccObj = props.formData.document[props.data?.chartUniqueId+'_tickets_data']
            let obj =  {
                "title": ccObj.title,
                "description": ccObj.description,
                "_id": ccObj._id,
                "assignTo": ccObj.assignTo,
                "status":ccObj.status?.map(ele=>({label:ele,value:ele}))

            }
            setFinalFormData({...fianlFormData,...obj})
			// setMemberDetails(obj)
            setValidate({
                "title": obj.title?false:true,
                "description": obj.description?false:true,

                })
		}
	}, [props.formData && props.formData?.document[props.data?.chartUniqueId+'_tickets_data']])

    useEffect(() => {
        if (props.formData?.document && props.formData.document[props.data?.chartUniqueId + '_member_list_data']) {
            let memList = props.formData.document[props.data?.chartUniqueId + '_member_list_data']?.map(item => ({
                label: item.firstName ? item.firstName + ' ' + item.lastName : item.name,
                value: item._id,
            }))
            setMembersList(memList)
        }
    }, [props.formData && props.formData?.document[props.data?.chartUniqueId + '_member_list_data']])



    useEffect(()=>{
        return()=>{
            clearCurrentChartReduxStore()
            setMemberDetails({})
        }
    },[])


    const clearCurrentChartReduxStore = () => {
		let subKeys = [
			props.data?.chartUniqueId+'_tickets_data',
            props.data?.chartUniqueId + '_member_list_data'
		]

		props.clearReduxDataOfCurrentComponent({
			key: "document",
			subKey: subKeys
		})
	}

    const handlesSaveFunctionality =(data)=>{
        setFinalFormData({...fianlFormData,...data})
    }

    const validator =(data)=>{
        setValidate({...validate,...data})
    }

    if(props.formData[props.data?.chartUniqueId+'_tickets_data_loading']){
        return <Loader />
    }

    const handleBackBtnClick =()=>{
        history.goBack()
      }

    return (
    <div>
        <div style={{ display:'flex' }}>
                <IconButton onClick={handleBackBtnClick} className={classes.backIconCls} title={'Back'}>
                    <ArrowBackIosIcon />
                </IconButton>
                <h1>{'Support Tickets View'}</h1>
            </div>
            <Divider />
      
        <Grid container style={{ marginTop:30 }}>
            <BasicInfo
                {...props} 
                memberDetails={memberDetails} 
                fianlFormData={fianlFormData}
                membersList={membersList}
                createChargeCode={props.createChargeCode}
                validator={validator}
                validate={validate} 
                handlesSaveFunctionality={handlesSaveFunctionality}
            />
        </Grid>
    </div>);
};

const mapDispatchToProps = {
    clearReduxDataOfCurrentComponent,
    getTickets,
    createTickets,
    getMembersList
}
const mapStateToProps = state => {
	return {
        formData: state.form,
        member: state.appConfig.members,
        user: state.user,
	};
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddTickets)))


