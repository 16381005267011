import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const InvoiceBoxes =(props)=>{


    const useStyles = makeStyles((theme) => ({

        paperLabel: {
            color: 'grey',
          },
        
        grid: {
            maxWidth:'15.22% !important',
            marginTop:20,
            marginBottom:20,
            marginLeft:20,
        },
        
      }));

    
      const classes = useStyles();
      const {invoiceData,amountData} =props


      const date = ()=>{
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;

        return today
      }
      
    return(
        <React.Fragment>
                <Grid  className={classes.grid}  item xs={2} style={{borderRight: '1px solid #ebedf2' }} >
                    <span className={classes.paperLabel}>{'New Invoice'}</span>
                        <div style={{display:"grid"}} >
                            <span>Name: <b>{invoiceData[0].name.slice(0, 12).concat('...')}</b></span>
                            <span>Date: <b>{date()}</b></span>
                        </div>
                        <br />
                    <span className={classes.paperLabel}>{'Old Invoice'}</span>
                        <div style={{display:"grid"}} >
                            <span>Name: <b>{invoiceData[1].name.slice(0, 12).concat('...')}</b></span>
                            <span>Date: <b>{invoiceData[1].date}</b></span>
                        </div>
                    
                </Grid>

                <Grid  className={classes.grid}  item xs={2} >
                    <span className={classes.paperLabel}>{'Highesh Amount'}</span>
                        <div style={{display:"grid"}} >
                            <span>Name: <b>{amountData[0].name.slice(0, 12).concat('...')}</b></span>
                            <span>Date: <b>{amountData[0].amount}</b></span>
                        </div>
                        <br />
                    <span className={classes.paperLabel}>{'Lowest Amount'}</span>
                        <div style={{display:"grid"}} >
                            <span>Name: <b>{amountData[1].name.slice(0, 12).concat('...')}</b></span>
                            <span>Date: <b>{amountData[1].amount}</b></span>
                        </div>
                    
                </Grid>
        </React.Fragment>
    )

}

export default InvoiceBoxes