import { ActionTypes } from 'constants/index';
import { ChartsService } from 'modules/api/index';
import { put, takeEvery } from 'redux-saga/effects';
 async function fetchAsync(func, arg) {
     const response = arg ? await func(arg) : await func();
     return response;
 }

 function* getLabelBoxData(action) {
    try {
      const response =
      yield fetchAsync(ChartsService.getLabelBoxData, action.payload);
      yield put({ type: ActionTypes.GET_LABELBOX_DATA_SUCCESS, payload: { data: response.data, dataUniqueId: action?.payload?.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_LABELBOX_DATA_FAILURE, payload: { error:e.message, dataUniqueId: action?.payload?.dataUniqueId} });
      console.log(e);
  
    }
  }
  function* getPieChartData(action) {
    try {
        const response = yield fetchAsync(ChartsService.getPieChartDataFromAPI, action.payload);
        yield put({ type: ActionTypes.GET_PIECHART_DATA_SUCCESS,
             payload: { data: response?.data, dataUniqueId: action.payload.chartUniqueId } });
    }
    catch (e) {
        yield put({ type: ActionTypes.GET_PIECHART_DATA_FAILURE, 
            payload: {dataUniqueId: action.payload.chartUniqueId, error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
    }
}

 export function* watchAppSaga() {
     yield takeEvery(ActionTypes.GET_LABELBOX_DATA, getLabelBoxData);
     yield takeEvery(ActionTypes.GET_PIECHART_DATA, getPieChartData);

 }
 
 export default watchAppSaga;
 