import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    marginTop: 10
  },
  paper: {
    height: '100%',
    width: '100%',
    border: '1px solid lightgrey !important',
    borderRadius: '5px !important'
  },
  control: {
    padding: theme.spacing(2),
  },
  questionTitle: {
    padding: '10px 0 !important',
    // borderBottom: '1px solid grey',
    fontWeight: 500,
    color: '#000000'
  },
  textValueStyle: {
    paddingTop: 'inherit',
  },
}));

export default useStyles;