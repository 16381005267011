export const setCookie = (e, t, r) => {
  const o = new Date();
  o.setTime(o.getTime() + 24 * r * 60 * 60 * 1e3);
  let n = 'expires=' + o.toUTCString();
  document.cookie = e + '=' + t + ';' + n + ';path=/';
};

export const deleteCookie = e => {
  const t = new Date();
  t.setTime(t.getTime() + 864e5);
  let r = 'expires=' + t.toUTCString();
  document.cookie = e + '=;' + r + ';path=/';
};

export const getCookie = e => {
  let t = e + '=';
  let r = decodeURIComponent(document.cookie).split(';');
  for (let e = 0; e < r.length; e++) {
    let o = r[e];
    for (; ' ' == o.charAt(0); ) o = o.substring(1);
    if (0 == o.indexOf(t)) return o.substring(t.length, o.length);
  }
  return '';
};
