const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

import { request } from 'modules/client'
import axios from 'axios'
import { APP_CONFIG } from 'constants/index'

export class FormService {

	static getEntryForms(appId) {
		const uri = `${API_BASE_URL}/forms`
		return request(uri, {
			method: 'GET',
			headers: { appId: appId }
		})
	}

	static getEntryForm({ formId, dappId }) {
		const uri = API_BASE_URL + `/forms/${formId}`
		return request(uri, {
			method: 'GET',
			headers: { appId: dappId }
		})
	}

	static updateEntryForm(data) {
		const uri = `${API_BASE_URL}/forms/${data._id}`
		return request(uri, {
			method: 'PUT',
			payload: data,
			headers: { appId: data.appId }
		})
	}

	static saveEntryForm(data) {
		const uri = `${API_BASE_URL}/forms`
		return request(uri, {
			method: 'POST',
			payload: data,
			headers: { appId: data.appId }
		})
	}

	static deleteEntryForm(data) {
		const uri = `${API_BASE_URL}/forms/${data._id}`
		return request(uri, {
			method: 'DELETE',
			payload: data,
			headers: { appId: data.appId }
		})
	}

	static getFormByName(form) {
		const uri = API_BASE_URL + "/forms/" + form.formName
		return request(uri, {
			method: 'GET',
			headers: { appId: form.appId }
		})
	}

	static getSubmittedDataBySubmissionId(form, appId) {
		const { id, formName } = form
		const uri = API_BASE_URL + "/submittedData/submissionId?id=" + id + "&formname=" + formName
		return request(uri, {
			method: 'GET',
			headers: { appId: appId }
		})
	}

	static saveSubmittedData(data) {
		const { formData } = data
		const uri = API_BASE_URL + "/submittedData/"
		return request(uri, {
			method: 'POST',
			payload: formData,
			headers: { appId: formData.appId }
		})
	}

	static getTemplates(appId){
		const uri = API_BASE_URL + "/formBuilder/getTemplates"
		return request(uri, {
			method: 'GET',
			headers: { appId: appId}
		})
	}

	// static saveUploadedFile(data) {
	//   const { files } = data;
	//   console.log("=====files", foiles)
	//   const uri = API_BASE_URL + "/submittedData/upload";
	//   const formData = new FormData();
	//   formData.append("file", files[0].file);
	//   return request(uri, {
	//     method: 'POST',
	//     payload: formData
	//   });
	// }

	static saveUploadedFile(data, appId) {
		const { files } = data;
		const uri = API_BASE_URL + "/submittedData/upload"
		return axios({
			url: uri,
			method: 'POST',
			data: files,
			headers: {
				'Content-Type': 'multipart/form-data',
				appId: appId
			},
			onUploadProgress: (p) => {
			}
		}).then((response) => {
			return response
		}).catch((error) => {
			return error
		})
		// return files.map((file) => {
		//   const formData = new FormData();
		//   formData.append('file', file.file);
		//   formData.append('submissionId', id);
		//   formData.append('name', file.name);
		//   console.log("submissionId", file)
		//   const headers = {
		//     'Content-Type': 'multipart/form-data'
		//   }
		//   return request(uri, {
		//     method: 'POST',
		//     payload: formData,
		//     headers
		//   });
		// })

	}

	static updateSubmittedData = (data, appId) => {
		const uri = API_BASE_URL + "/submittedData/updateSubmittedData/"
		return request(uri, {
			method: 'PUT',
			payload: data,
			headers: { appId: appId }
		})
	}

	static getWorkflowEvents = (workflowId, appId) => {

		if (workflowId) {
			const uri = API_BASE_URL + "/workflows/execute/" + workflowId + "/events/start"
			return request(uri, {
				method: 'GET',
				headers: { appId: appId }
			});
		} else {
			return null
		}

	}
}



export function apiBaseAddress() {
	return API_BASE_URL
}
