import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    rootContainer: {
        marginTop: "20px"
    },
    titleBlock: {
        display:"flex",
        alignItems:"center"
    },
    title:{
        fontWeight: "bold",
        fontSize: "14px"
    },
    addIcon: {
        marginLeft: "8px",
        cursor:"pointer"
    },
    tableHeaderCell:{
        // borderBottom:"none"
    },
    tableActions:{
        display:"flex",
        alignItems:"center"
    },
    actionIcon:{
        cursor:"pointer"
    },
    inputField: {
        width:"100%",
        height: "35px",
        paddingLeft: "5px"
    },
    selectField: {
        height: "35px",
    }
}))

export default useStyles