import React, { useEffect, useState } from 'react';
import Redux from './Redux';
import { ConfirmationHandler, ContactDsilo } from 'dsilo-ui-components';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';
import AppHeader from '../AppsDashboard/AppHeader';
import { clearContactDsiloMessage, contactDsilo, getAppAndTemplates } from '../../store/actions';

const Index = props => {
    const [apps, setApps] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        if (props.user && props.user.orgId) {
            props.getAppAndTemplates(props.user.orgId);
        }
    }, [props.user]);

    useEffect(() => {
        try {
            const templates = props.apps.templates.filter((temp => {
                const isInApp = props.apps.allApps.find((itm) => {
                    if (itm.type === temp.type) {
                        return true;
                    }
                    return false;
                });
                return !isInApp;
            }));
            setApps([...props.apps.allApps, ...templates.map(tmp => ({ ...tmp, isTemplate: true }))]);
        } catch (err) {
        }
    }, [props.apps.allApps, props.apps.templates]);

    const sendData = (data) => {
        let interestedUseCases = [];
        for (let i = 0; i < data.reason.length; i++) {
            let str = data.reason[i].id;
            let matches = str.match(/\b(\w)/g);
            let acronym = matches.join('');
            interestedUseCases.push(acronym);
        }
        const payload = {
            'firstName': data.firstName,
            'lastName': data.lastName,
            'companyOrganization': data.companyName,
            'jobTitle': data.role,
            'email': data.email,
            'phone': data.phone,
            'interestedUseCases': interestedUseCases,
            'comments': data.comments,
        };
        props.contactDsilo(payload);
    };

    const { loading } = props.contactDsiloData;
    const { success } = props.contactDsiloData;

    return (
        <AppHeader>
            <div className={classes.container}>
                {loading ? <Loading /> :
                    <ContactDsilo
                        {...props}
                        onSend={sendData}
                        user={props.user}
                        reasons={apps}
                        reasonLoading={props.apps.loading}
                    />
                }
                {success && <ConfirmationHandler open={success} title='alert' handleClose={props.clearContactDsiloMessage}
                    alertMessageText={'Thank you for your interest in Dsilo Ai Platform.Our customer success manager will reach out to you soon to understand your interests and provide you a customized solution'}
                    classes={classes} />}
            </div>
        </AppHeader>
    );
};

const useStyles = makeStyles({
    container: {
        padding: 20,
    },
    confirmationDialoge: {
        '& .MuiDialog-paperWidthSm': {
            height: '250px',
            width: '600px',
        },
    },
    para: {
        fontSize: '28px !important',
        fontWeight: 600,
        color: '#000',
    },
});

const mapDispatchToProps = {
    contactDsilo,
    clearContactDsiloMessage,
    getAppAndTemplates,
};
const mapStateToProps = state => {
    return {
        contactDsiloData: state.contactDsilo,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Redux(Index));

