import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
	container: {
		//   backgroundColor: color.background
	},
	contentContainer: {
		//   backgroundColor: color.background,
		alignItems: 'stretch',
		paddingTop: 10,
		paddingLeft: 10,
		paddingRight: 10,
		//   paddingBottom: screenHeight / 2
	},
	textInput: {
		fontSize: 16,
		//   backgroundColor: color.accent,
		borderRadius: 8,
		padding: 12,
		paddingLeft: 14,
		paddingRight: 40,
		// color: color.text,
		flex: 1
	},
	textInput: {
		fontSize: 20,
		//   backgroundColor: color.accent,
		borderRadius: 8,
		paddingTop: 12,
		paddingBottom: 12,
		paddingLeft: 14,
		paddingRight: 40,
		// color: color.text,
		flex: 1
	},
	textInputClearContainer: {
		position: 'absolute',
		alignItems: 'center',
		justifyContent: 'center',
		right: 0,
		top: 0,
		height: 44,
		width: 36,
	},
	textInputClear: {
		//   color: color.text
	},
	textInputContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 10,
	},
	textInputLabel: {
		fontSize: 30,
		marginRight: 6
	},
	buttonsContainer: {
		flexDirection: 'row',
		marginBottom: 10
	},
	button: {
		//   backgroundColor: color.accent,
		paddingTop: 10,
		paddingBottom: 10,
		borderRadius: 8,
		flex: 1,
		alignItems: 'center'
	},
	labelText: {
		fontSize: 22,
		marginBottom: 4,
		marginTop: 4,
		//   color: `${color.text}8`
	}
})

export default useStyles