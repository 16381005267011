import { ActionTypes } from 'constants/index'

const initialState = {
    data: null,
    loading: false,
    dataModelDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    },
    saveSuccess: false,
	updateSuccess: false
}

const dataModelReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_DATAMODEL_SUCCESS: {
            console.log("reducer=======", action);
            return {
                ...state,
                loading: false,
                data: action.payload,
                saveSuccess: false,
	            updateSuccess: false
            }
        }
        case ActionTypes.GET_DATAMODEL_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case ActionTypes.SAVE_DATAMODEL_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload,
                saveSuccess: true
            }
        }
        case ActionTypes.SAVE_DATAMODEL_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case ActionTypes.UPDATE_DATAMODEL_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload,
                updateSuccess: true
            }
        }
        case ActionTypes.UPDATE_DATAMODEL_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case ActionTypes.OPEN_NEW_DATAMODEL_DIALOG:
            {
                return {
                    ...state,
                    dataModelDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: {
                            ...action.data
                        }
                    }
                };
            }
        case ActionTypes.CLOSE_NEW_DATAMODEL_DIALOG:
            {
                return {
                    ...state,
                    dataModelDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default dataModelReducer;
