import Button from "@material-ui/core/Button";
import  CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import { AdvancedTable, TableSupport, FiltersUtils } from 'dsilo-ui-components';
import React, { useEffect, useMemo, useState } from "react";
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmationHandler from '../../components/ConfirmDialog';
import {
    getAllOthersDeletedDocs,
    getAllOtherDocs,
    clearReduxDataOfCurrentComponent,
    deleteOtherDocs,
    downloadAllOtherDocs,
    getPDFForOtherDoc,
    getOthersDeletedDoc,
    getModelDataDropDown,
    restoreOthersDeletedDoc,
    setDataToLocalDB,
  } from '../../store/actions';
import { Loader } from '../loader';

const useStyles = makeStyles({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 10,
    },
    root: {
        width: "100%"
    },
    headerClass: {
        backgroundColor: "#cecece",
        textTransform: 'capitalize'
    },
    confirmationDialoge: {
        "& .MuiDialog-paperWidthSm": {
            height: "250px",
            width: "600px"
        }
    },
    para: {
        fontSize: "28px !important",
        fontWeight: 600,
        color: "#000"
    },
    btnDownloadAll: {
        fontSize: '15px !important',
        marginRight: "10px",
        marginBottom: "10px",
        whiteSpace: 'nowrap',
        float: "right"
    },
    headerContainer: {
        // display: 'flex',
        // justifyContent: 'space-between',
        // overflow: 'auto',
        // alignItems: 'center',
        // overflowY: 'hidden',
        // marginRight: 5,
        // display: "flex",
        // direction: "rtl"
    },
    downloadLoader: {
        width: 20,
        height: 20,
        marginLeft: 5,
        color: '#ffffff',
    }
});

const sharedJSON = {
    "order": "asc",
    "orderBy": null,
    "selected": [],
    "tableData": [],
    "filterTableData": [],
    "count": 10,
    "searchText": "",
    "filterList": [],
    "filterData": []
}

const tableJson = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Document Type',
        sort: true,
    },
    {
        id: 'keyValueFields',
        numeric: false,
        disablePadding: false,
        label: 'Key Data Fields',
        sort: true,
    },

];

const OthersComponent = (props) => {

    const { data } = props
    const { chartData, chartUniqueId } = data

    const classes = useStyles()
    const [otherDocList, setOtherDocList] = useState([])
    const [selectedItems, setSelectedItems] = useState([])

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const [state, setState] = useState({ ...sharedJSON, });
    const [searchedText, setSearchedText] = useState('');
    const [allOtherDocIds, setAllOtherDocIds] = useState([])
    const [deleteConfirmState, setDeleteConfirm] = useState({
        isOpen: false,
        deleteMessage: "Are you sure you want to delete ?",
        data: null
    })

    const [fetch, setFetch] = useState(false)

    const { _id: userId } = props.member
    const { selectedDataModel, fetchType, conditions } = chartData

    const [localStorageObject, setLocalStorageObject] = useState({})
    const [isHighlighted, setHighLighted] = useState({});
    const [highLighterIdForSelectedLineItem, setHighLighterIdForSelectedLineItem] = useState('');
	const [filterConditions, setFilterConditions] = useState([]) // filter array to be sent in api call
	const [selectedFilterValues, setFilterValues] = useState([]) // actual filter object
	const [externalFilterData, setExternalFilterData] = useState([]) // actual filter object
    const pageUrl = props.page?.url
    const chartDataConditions = conditions && conditions?.length ? conditions : [];

    useEffect(() => {
        let localStoreJson = localStorage.getItem('userSession');
        let currentUserSes, _currSessPage = 0, _currSessRPP = rowsPerPage
        if (localStoreJson) {
            let currentSession = JSON.parse(window.atob(localStoreJson));
            if (Object.keys(currentSession).length > 0) {
                if (currentSession && currentSession[userId] && currentSession[userId][pageUrl]) {
                    currentUserSes = currentSession[userId][pageUrl];
                    if(currentUserSes?.page) {
                        _currSessPage = Number(currentUserSes?.page)
                        setPage(_currSessPage)
                    }
                    if(currentUserSes?.rowsPerPage) {
                        _currSessRPP = Number(currentUserSes?.rowsPerPage)
                        setRowsPerPage(_currSessRPP)
                    }
                    // let obj = {
                    //     [userId]:{
                    //         "TileListPage":pageUrl,
                    //         "selectedLineItem":currentSession[userId].selectedTile
                    //     }
                    // }
                    // setHighLighted(obj)
                }
            }
        }
        fetchData(_currSessPage, _currSessRPP)
    }, [])


    useEffect(() => {
        if (props.formData?.document[props.data?.chartUniqueId + '_data']?.data) {
            setOtherDocList(props.formData?.document[props.data?.chartUniqueId + '_data']?.data)
        }
    }, [props.formData?.document?.[props.data?.chartUniqueId + '_data']])

    useEffect(() => {
        setState({ ...TableSupport.updateTableData(state) });
    }, [state.searchText, state.filterList]);

    useEffect(() => {
        if (otherDocList) {
            const rows = tableJson;
            // const rows = listViewTableJson;
            let keysArr = ['_id'];
            // setState({ ...TableSupport.setDatafromApi2(otherDocList, keysArr, state, rows) });
            setState({
                ...TableSupport.setDatafromApi2(
                    getData(otherDocList),
                    keysArr,
                    state,
                    getColumns()),
                count: props.formData?.document[props.data?.chartUniqueId + '_data']?.total
            });
            isHighlightedTile()
        }
    }, [otherDocList]);


    useEffect(() => {
        if (localStorage.getItem('userSession')) {
            let userSession = JSON.parse(window.atob(localStorage.getItem('userSession')))
            if (Object.keys(userSession).length === 0) {
                if (Object.keys(localStorageObject).length > 0) {
                    localStorage.setItem('userSession', window.btoa(JSON.stringify({ [userId]: { [pageUrl]: { ...localStorageObject } } })))
                }
            } else {
                for (let [key, value] of Object.entries(userSession)) {
                    if (key === userId) {
                        for (let [key1, value1] of Object.entries(value)) {
                            if (key1 === pageUrl) {
                                userSession[userId][pageUrl] = { ...value1, ...localStorageObject }
                                localStorage.setItem('userSession', window.btoa(JSON.stringify(userSession)))
                            } else {
                                if (Object.keys(localStorageObject).length > 0) {
                                    userSession[userId][pageUrl] = { ...localStorageObject }
                                    localStorage.setItem('userSession', window.btoa(JSON.stringify(userSession)))
                                }
                            }
                        }
                    }
                }
            }
        } else if (Object.keys(localStorageObject).length > 0) {
            localStorage.setItem('userSession', window.btoa(JSON.stringify({ [userId]: { [pageUrl]: { ...localStorageObject } } })))
        }
    }, [localStorageObject])

    useEffect(() => {
        if (!props.formData[data.chartUniqueId + '_delete_othersdocs_loading'] && props.formData?.document?.[props.data?.chartUniqueId + "_delete_othersdocs"] && (chartData?.bulkDelete || chartData?.delete)) {
            toast.success(props.formData?.document?.[props.data?.chartUniqueId + "_delete_othersdocs"].message || 'Deleted successfully')
            props.clearReduxDataOfCurrentComponent({
                key: "document",
                subKey: [props.data?.chartUniqueId + "_delete_othersdocs"]
            })
            fetchData(page, rowsPerPage)
            setSelectedItems([])
        }
    }, [props.formData?.document?.[props.data?.chartUniqueId + "_delete_othersdocs"]]);

    useEffect(() => {
		if (props.formData && props.formData.document?.[props.data.chartUniqueId + '_restoreOthersDeletedDoc']?.acknowledged) {
            fetchData(page, rowsPerPage)
			props.clearReduxDataOfCurrentComponent({
				key: "document",
				subKey: [props.data.chartUniqueId + '_restoreOthersDeletedDoc']
			})
		}
	}, [props.formData?.document?.[props.data.chartUniqueId + '_restoreOthersDeletedDoc']?.acknowledged])

    const isHighlightedTile = async () => {
        let userSession = await localStorage.getItem('userSession')
        if (userSession) {
            userSession = JSON.parse(window.window.atob(userSession))
            let currentUserSession = userSession && userSession[userId]
            let currentUserSessionPage = userSession && currentUserSession && currentUserSession[pageUrl]
            if (currentUserSessionPage?.selectedLineItem) {
                setHighLighterIdForSelectedLineItem(currentUserSessionPage?.selectedLineItem)
            }
        }
    }

    const getData = (dataSource) => {
        const clonedDataSource = JSON.parse(JSON.stringify(dataSource))
        let allDocIds = []
        // return clonedDataSource.map((item) => {
        let dt = clonedDataSource.map((item) => {
            allDocIds.push(item._id);
            const keys = Object.keys(item)
            keys.forEach((key) => {
                if (key && typeof item[key] !== "string") {
                    if (fetchType === "keysArray" && !selectedInnerSupportDataElement) {
                        if (data?.chartData?.selectedInnerDataElement?.length) {
                            data?.chartData?.selectedInnerDataElement?.map(side => {
                                if (side.value === key) {
                                    if (side.type === "namepicker") {
                                        if (item[key] instanceof Array) {
                                            item[key] = item[key].map(obj => obj.label).join(', ')
                                        } else {
                                            item[key] = item[key]
                                                ? (typeof item[key] === "object"
                                                    ? item[key]["label"]
                                                    : item[key])
                                                : ""
                                        }
                                    } else {
                                        item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                                    }
                                }
                            })
                        } else {
                            item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                        }
                    } else {
                        if (data?.chartData?.selectedDataElement?.length) {
                            data.chartData.selectedDataElement?.map(sde => {
                                if (sde.value === key) {
                                    if (sde.type === "namepicker") {
                                        if (item[key] instanceof Array) {
                                            item[key] = item[key].map(obj => obj.label).join(', ')
                                        } else {
                                            item[key] = item[key]
                                                ? (typeof item[key] === "object"
                                                    ? item[key]["label"]
                                                    : item[key])
                                                : ""
                                        }
                                    } else {
                                        item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                                    }
                                }
                            })
                        } else {
                            item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                        }
                    }
                    // item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                }
            })
            return item
        })
        setAllOtherDocIds(allDocIds)
        return dt;
    }

    const getColumns = () => {
        let COLUMNS = []
        COLUMNS = chartData.selectedDataElement.map((i) => {
            return {
                id: i.value,
                numeric: false,
                disablePadding: false,
                label: i.label,
                sort: true,
                checked: true
            }
        })
        return COLUMNS
    }

    const fetchData = (_page = page, _rowsPerPage = rowsPerPage, _searchText = '', _conditions = [], isFilter = false) => {
        let payloadConditions = []
        if (isFilter && _conditions.length > 0) {
			payloadConditions = [...chartDataConditions, ..._conditions]
		} else {   
			payloadConditions = [...chartDataConditions]
		}
		if (payloadConditions?.length < 1 && _conditions.length > 0) {
			payloadConditions = _conditions
		}
        const data = {
            appId: props.appId,
            orgId: props.user.orgId,
            dataUniqueId: props.data?.chartUniqueId + '_data',
            dataModelId: selectedDataModel,

            payload: {
                page: _page,
                rowsPerPage: _rowsPerPage,
                ...(_searchText && { search: _searchText }),
                // ...(state.order &&
                //   state.orderBy && {
                //     sort: state.order,
                //     sortBy: state.orderBy,
                // }),
                ...(payloadConditions && payloadConditions.length && {
                    conditions: payloadConditions
                })
            },
        }
        if (props.data.chartData.isDeleted) {
            props.getAllOthersDeletedDocs(data)
        } else {
            props.getAllOtherDocs(data)      
        }

    }

    const onRowClick = (rowData, rowMeta) => {
        console.log('onRowClick');
    };

    const searchHandler = (e) => {
        setSearchedText(e ? e.target.value : "")
    }

    const onSearchEnter = (e, onSearchButton = false) => {
        if (e === null) {
            setPage(0)
            fetchData(0, rowsPerPage, '', filterConditions, Boolean(filterConditions?.length > 0))
            searchHandler(null)
        } else if (e?.keyCode === 13 || onSearchButton) {
            setPage(0)
            fetchData(0, rowsPerPage, searchedText, filterConditions, Boolean(filterConditions?.length > 0))
        }
    }

    const handleChangePage = (event, _page) => {
        fetchData(_page, rowsPerPage, searchedText || '',filterConditions, Boolean(filterConditions?.length > 0))
        setPage(_page)
        setLocalStorageObject({ ...localStorageObject, page: _page })
    };

    const onAddClick = () => {
        props.history.push({ pathname: `/${props.match.params.appid}/page/chargecode/new` });
    }

    const handleChangeRowsPerPage = event => {
        fetchData(0, event.target.value, searchedText || '', filterConditions, Boolean(filterConditions?.length > 0))
        setRowsPerPage(event.target.value)
        setPage(0)
        setLocalStorageObject({ ...localStorageObject, rowsPerPage: event.target.value, page: 0 })
    };

    const handleInputChange = event => {
        let obj = state.filterTableData;
        let list = obj.columns;
        list.map((item, index) => {
            if (item.name === event.target.value) list[index].checked = event.target.checked;
        });
        obj.columns = [...list];
        setState({ ...state, filterTableData: obj });
        setState({ ...TableSupport.updateTableData(state) });
    };

    const resetFilters = event => {
        setState({ ...state, filterList: [] });
    };

    const handleDropdownChange = (event, index, column) => {
        const value = event.target.value === 'All' ? [] : [event.target.value];
        // filterUpdate(index, value, column, 'dropdown');
        let list = state.filterList;
        list[index] = event.target.value;
        setState({ ...state, filterList: [...list] });
        setState({ ...TableSupport.updateTableData(state) });
    };

    const handleRequestSort = (event, property) => {
        let obj = TableSupport.requestSort(event, property, state);
        setState({ ...state, ...obj });
    };

    const handleSelectAllClick = event => {
        const selectedIds = TableSupport.handleTableSelectAllClick(event, state.filterTableData.datasets);
        setState({
            ...state,
            selected: selectedIds,
        });
        setSelectedItems(selectedIds)
    };

    const onClickAnyAction = (docId) => {
        const { _id: userId } = props.member
        if (localStorage.getItem('userSession')) {
            let userSession = JSON.parse(window.atob(localStorage.getItem('userSession')))
            for (let [key, value] of Object.entries(userSession)) {
                if (key === userId) { // userId matched
                    for (let [key1, value1] of Object.entries(value)) {
                        if (key1 === pageUrl) { // page matched
                            userSession[userId][pageUrl] = { ...value1, 'selectedLineItem': docId }
                            localStorage.setItem('userSession', window.btoa(JSON.stringify(userSession)))
                        } else {
                            if (Object.keys(userSession).length > 0) {
                                userSession[userId][pageUrl] = { ...value1, 'selectedLineItem': docId }
                                localStorage.setItem('userSession', window.btoa(JSON.stringify(userSession)))
                            }
                        }
                    }
                }
            }
        } else {
            localStorage.setItem('userSession', window.btoa(JSON.stringify({ [userId]: { [pageUrl]: { 'selectedLineItem': docId } } })))
        }
    }

    const calculateIndexPaggination = function calculateIndex(paginationPage, rowsPerPage, itemIndexOnPage) {
        const startIndex = paginationPage * rowsPerPage;   
        const actualIndex = startIndex + itemIndexOnPage + 1;
        return actualIndex;
    }
    const tableCrudOperations = (item, type, e ,i, j, k) => {
        if (type === 'edit') {
            props.setDataToLocalDB && props.setDataToLocalDB({ key: 'selectedDoc', data: item })
            props.history.push({
                pathname: `/${props.match.params.appid}/page/${chartData.editPage}/${item._id}`,
            })
        }
        if (type === 'delete') {
            setDeleteConfirm({
                ...deleteConfirmState,
                isOpen: true,
                data: [item._id]
            })
        }
        if (type === "view") {
            onClickAnyAction(item._id)
            props.setDataToLocalDB && props.setDataToLocalDB({ key: 'selectedDoc', data: item })
            props.history.push({
                pathname: `/${props.match.params.appid}/page/${chartData.viewPage}/${item._id}/pi/${calculateIndexPaggination(j,k,i)}`
            })
        }
        if (type === "download") {
            console.log("it is the doc id to download ===", item)
            handleOtherDocDownload(item)
        }
        if (type === 'restore') {
            props.restoreOthersDeletedDoc({
                id: item._id,
                appId: props.match.params.appid,
                payload: {},
                dataUniqueId: chartUniqueId + '_restoreOthersDeletedDoc'
            })
        }
    };

    const handleOtherDocDownload = (doc) => {
        let payload = {
            payload: {
                docId: doc._id,
            },
            isDownload: true,
            appId: props.match.params.appid,
            dataUniqueId: chartUniqueId + '_' + doc._id + '_download'
        }
        if (props.data.chartData.isDeleted) {
            props.getOthersDeletedDoc(payload)
        } else {  
            props.getPDFForOtherDoc(payload)   
        }
    }

    const onDeleteResponse = (isDelete) => {
        if (isDelete) {
            const payload = {
                appId: props.match.params.appid,
                dataUniqueId: data?.chartUniqueId + '_delete_othersdocs',
                payload: {
                    docIds: deleteConfirmState.data,
                }
            }
            props.deleteOtherDocs(payload)
        }
        setDeleteConfirm({ ...deleteConfirmState, isOpen: false, data: null })
    }

    const handleDeleteAllOtherDocs = () => {
        setDeleteConfirm({
            ...deleteConfirmState,
            isOpen: true,
            data: selectedItems
        })
    }

    const handleCheck = (event, id) => {
        const { selected } = state;
        let newSelected = TableSupport.handleSelectedRow(event, id, selected);
        setState({ ...state, selected: newSelected });
        setSelectedItems(newSelected)
        // props.checkedContractChanged(newSelected);
    };

    const clearCurrentChartReduxStore = () => {
        let subKeys = [
            props.data?.chartUniqueId + '_data',
        ]

        props.clearReduxDataOfCurrentComponent({
            key: "document",
            subKey: subKeys
        })
    }

    const downloadAllDocuments = () => {
        let payload = {
            docIds: selectedItems,
            appid: props.match.params.appid,
            dataUniqueId: data.chartUniqueId + '_list_download_all'
        }
        props.downloadAllOtherDocs(payload)
    }

    const onExternalFilterChange = (filterValues) => {
		let _page = 0, _rowsPerPage = rowsPerPage || 10;
		setPage(_page)
		setRowsPerPage(_rowsPerPage)
        let updatedConditions = FiltersUtils.createConditions(filterValues, chartData.externalFilter)
        setFilterValues(filterValues)
        setFilterConditions(updatedConditions)
        fetchData(_page, rowsPerPage, '', updatedConditions, true)
	}

    const dataElements = useMemo(() => {
        let de = []
        if(Array.isArray(chartData?.selectedDataElement) && chartData?.selectedDataElement?.length > 0) {
            chartData?.selectedDataElement.forEach(sde => {
                let newElement = {
                    name: sde?.value,
                    description: sde?.label,
                    type: sde?.type || 'string'
                }
                de.push(newElement)
            });
        }
        return de;
    }, [chartData?.selectedDataElement]);

    return (
        <div className="contracts" style={{ marginTop: 5 }}>
            <div className={classes.headerContainer} >
                {
                    chartData?.download && selectedItems.length > 0 &&
                    <Button
                        color="primary"
                        disableElevation
                        variant="contained"
                        onClick={() => downloadAllDocuments()}
                        className={clsx(classes.btnDownloadAll, 'contract-btn')}
                    >
                        {
                            (props.formData[data.chartUniqueId + '_grid_download_all_loading'] || props.formData[data.chartUniqueId + '_list_download_all_loading']) && <CircularProgress size={25} className={classes.downloadLoader} />
                        }
                        Download Selected
                    </Button>
                }
                {
                    chartData?.bulkDelete && selectedItems.length > 0 &&
                    <Button
                        color="primary"
                        disableElevation
                        variant="contained"
                        onClick={handleDeleteAllOtherDocs}
                        className={clsx(classes.btnDownloadAll, 'contract-btn')}
                        endIcon={<DeleteIcon />}
                    >
                        {
                            props.formData[data.chartUniqueId + '_delete_othersdocs_loading'] && <CircularProgress size={25} className={classes.downloadLoader} />
                        }
                        Delete Selected
                    </Button>
                }
            </div>
            <div style={{ marginTop: 10 }}>
                <AdvancedTable
                    {...props}
                    {...state}
                    name={'ContractList'}
                    dataElements={dataElements}
                    onRowClick={onRowClick}
                    chartData={chartData}
                    searchedText={searchedText}
                    searchHandler={searchHandler}
                    onSearchEnter={onSearchEnter}
                    isExport={false}
                    isViewCoulmns={false}
                    isSearch={chartData?.isSearch}
                    isLoading={props.formData[props.data?.chartUniqueId + "_data_loading"]}
                    isFilter={false}
                    isOthers={true}
                    hasCheckbox={chartData.checkbox}
                    actions={{
                        copy: false,
                        delete: chartData?.delete || false,
                        download: chartData?.download || false,
                        restore: chartData?.restore || false,
                        edit: false,
                        view: true,
                    }}
                    isTablePagination={true}
                    pagination={{ type: '' }}
                    paginationPage={page}
                    rowsPerPage={rowsPerPage}
                    isTableHead={true}
                    isTableSubHeader={false}
                    associatedFormName={chartData.associatedFormName}
                    handleInputChange={handleInputChange}
                    onAddClick={onAddClick}
                    resetFilters={resetFilters}
                    handleDropdownChange={handleDropdownChange}
                    handleRequestSort={handleRequestSort}
                    handleSelectAllClick={handleSelectAllClick}
                    tableCrudOperations={tableCrudOperations}
                    handleCheck={handleCheck}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    headerstyle={{
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        textAlign: "left",
                        backgroundColor: "#cecece"
                    }}
                    subHeaderstyle={{
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        backgroundColor: "#FFF",
                        textAlign: "left"
                    }}
                    headerClass={classes.headerClass}
                    rowStyle={{
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        backgroundColor: "none",
                        lineHeight: 1.5,
                        textAlign: "left"
                    }}
                    stripRows
                    loader={<Loader />}
                    externalFilters={chartData?.externalFilter}
                    externalFilterData={externalFilterData}
                    externalFilterValues={selectedFilterValues}
                    onExternalFilterChange={onExternalFilterChange}
                    isNestedTable={props.isNestedTable}
                    onExapandIconClick={props.onExapandIconClick}
                    nestedTableData={props.nestedTableData}
                    // isHighlighted={isHighlighted}
                    // userIdForHightList={userId}
                    highLighterIdForSelectedLineItem={highLighterIdForSelectedLineItem}
                />
                <ConfirmationHandler
                    open={deleteConfirmState.isOpen}
                    handleClose={onDeleteResponse}
                    alertMessageText={deleteConfirmState.deleteMessage}
                    classes={classes}
                    confirm
                />
            </div>
        </div>
    )
}


const mapDispatchToProps = {
    getAllOtherDocs,
    getAllOthersDeletedDocs,
    deleteOtherDocs,
    downloadAllOtherDocs,
    clearReduxDataOfCurrentComponent,
    getPDFForOtherDoc,
    getOthersDeletedDoc,
    getModelDataDropDown,
    restoreOthersDeletedDoc,
    setDataToLocalDB
};

const mapStateToProps = state => {
    return {
        formData: state.form,
        member: state.appConfig.members,
        user: state.user,
    };
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(withRouter(OthersComponent)));