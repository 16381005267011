import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Button, Grid, Box } from '@material-ui/core'

const Header = (props) => {

	return (<Grid container>
		<Grid item xs={12} md={6}>
		</Grid>
		<Grid item xs={12} md={6} className="savebtn-sec" >
			<Box display="flex" justifyContent="flex-end" mb={1} width={1}>
				<Button
					className="addnewentrybtn mr-5"
					variant="contained"
					onClick={() => props.executeRule(true)}>
					{"Execute"}
				</Button>
				<Button
					variant="contained" color="primary" className="addnewentrybtn mr-5"
					disabled={!props.canBeSubmitted()}
					onClick={() => props.match.params.id === "new"
						? props.saveDetails(true)
						: props.saveDetails(false)}>
					{props.match.params.id === "new" ? "Save" : "Update"}
				</Button>
			</Box>
		</Grid>
	</Grid>)
}

export default withRouter(Header)