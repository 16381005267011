import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import {
  downloadTop10ClausesExcel,
  getContractDataById,
  hideSuccessMessage,
  reRun,
  updateContractAnnotationsWithoutLoading,
  updateContractState,
  downloadContractPDFAction,
  downloadSheet,
} from '../../store/actions';
import { Loader } from 'components/loader';
import { PUBLIC_PATH } from 'constants/index';
import { ContractDetails } from 'dsilo-ui-components';
import adobeLogo from '../../images/adobe-pdf-icon.png';
import { withRouter } from 'react-router-dom';

const ContractDetailsComponent = (props) => {
  const { selectedDataElement } = props.data.chartData

  useEffect(() => {
    if (props.page?.pageLevelDataModel) {
      let payload = {
        id: props.match.params.id,
        dataModelId: props.page?.pageLevelDataModel,
        appId: props.match.params.appid,
        fields: selectedDataElement.map(i => i.value).join(",")
      }
      let payloadForPDFDownload = {
        appid: props.match.params.appid,
        _id: props.match.params.id,
        isDownload: false,
        openFile: false,
        dataModelId: props.page?.pageLevelDataModel,
      };
      props.downloadContractPDFAction({ ...payloadForPDFDownload, dataUniqueId: props.data.chartUniqueId + '_PDF' }) // download pdf
      props.getContractDataById({ ...payload, dataUniqueId: props.data.chartUniqueId + '_data' });
    }
  }, [props.page]);

  const { document, } = props.formData;

  const onDownloadPDF = (payload) => {
    console.log('download', payload)
    props.downloadContractPDFAction(payload)
  }

  let DATA = {
    contract: document[props.data.chartUniqueId + '_data'] && document[props.data.chartUniqueId + '_data']?.data,
    message: null,
    downloadedPDF: props.formData.pdfDocument && props.formData.pdfDocument[props.data.chartUniqueId + '_PDF']?.pdf,
  }

  if (props.formData[props.data.chartUniqueId + '_data_loading']) {
    return <Loader />
  }

  const onDownloadButtonClicked = (data) => {
    const { download } = props.data.chartData;
    if (download) {
      const { selectedDataElement, fetchType, selectedInnerDataElement } = download;
      let payload = {
        ...data, appid: props.match.params.appid,
        fields: selectedDataElement.map(i => i.value).join(","),
        downloadType: 'excel',
        dataModelId: props.page?.pageLevelDataModel,
      }
      if (fetchType) {
        payload['fetchType'] = fetchType;
      }
      if (selectedInnerDataElement?.length) {
        payload['viewFields'] = selectedInnerDataElement.map(i => i.value).join(",");
      }
      props.downloadSheet(payload);
    } else {
      let payload = {
        ...data,
        dataModelId: props.page?.pageLevelDataModel,
        downloadType: 'excel',
      }
      props.downloadSheet(payload);

    }
  }

  return (<>
    <ContractDetails
      downloadContractPDF={onDownloadPDF}
      contractAnnotations={DATA}
      tileImg={adobeLogo}
      downloadAnnotations={onDownloadButtonClicked}
      {...props}
      publicPath={PUBLIC_PATH} />
  </>);
};

const mapDispatchToProps = {
  downloadContractPDFAction,
  downloadTop10ClausesExcel,
  reRun,
  hideSuccessMessage,
  getContractDataById,
  updateContractAnnotationsWithoutLoading,
  updateContractState,
  downloadSheet,
};

const mapStateToProps = state => {
  return {
    formData: state.form,
    // contractAnnotations: state.contractAnnotations,
  };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractDetailsComponent)));
