export default {
	GET_BUSINESSRULES: undefined,
	GET_BUSINESSRULES_SUCCESS: undefined,
	GET_BUSINESSRULES_FAILURE: undefined,

	GET_BUSINESSRULE: undefined,
	GET_BUSINESSRULE_SUCCESS: undefined,
	GET_BUSINESSRULE_FAILURE: undefined,

	SAVE_BUSINESSRULE: undefined,
	SAVE_BUSINESSRULE_SUCCESS: undefined,
	SAVE_BUSINESSRULE_FAILURE: undefined,

	UPDATE_BUSINESSRULE: undefined,
	UPDATE_BUSINESSRULE_SUCCESS: undefined,
	UPDATE_BUSINESSRULE_FAILURE: undefined,

	DELETE_BUSINESSRULE: undefined,
	DELETE_BUSINESSRULE_SUCCESS: undefined,
	DELETE_BUSINESSRULE_FAILURE: undefined,

	EXECUTE_BUSINESSRULE: undefined,
	EXECUTE_BUSINESSRULE_SUCCESS: undefined,
	EXECUTE_BUSINESSRULE_FAILURE: undefined,

	SET_BUSINESSRULES_SEARCH_TEXT: undefined,
}