import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { PowerBiService } from 'services/index'
import { ActionTypes } from 'constants/index'


async function fetchAsync(func, arg) {
	const response = arg ? await func(arg) : await func()
	return response
}

function* getPowerBiReport(action) {
	try {
		const report = yield call(fetchAsync, PowerBiService.getPowerBiReport, action.payload)
		yield put({ type: ActionTypes.GET_POWER_BI_REPORT_SUCCESS, payload: report })
	} catch (e) {
		yield put({ type: ActionTypes.GET_POWER_BI_REPORT_FAILURE, error: e.data })
	}
}

export function* watchPowerBiSaga() {
	yield takeLatest(ActionTypes.GET_POWER_BI_REPORT, getPowerBiReport)
	
}

export default watchPowerBiSaga