import { ActionTypes } from 'constants/index';

const initialState = {
    loading: false,
    success:false,
    message:''
  
};

export default function contactReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.SUBMIT_CONTACT_DSILO_DATA_LOADING: {
            return {
                ...state,
                loading: true,
                success:false,
                message:''
            };
        }
        case ActionTypes.SUBMIT_CONTACT_DSILO_DATA_SUCCESS: {
            return {
                ...state,
                message: action.payload.message,
                loading: false,
                success:true
            }
        }
        case ActionTypes.SUBMIT_CONTACT_DSILO_DATA_ERROR: {
            return {
                ...state,
                loading: false,
                message: action.payload.message,
            };
        }
        case ActionTypes.CLEAR_CONTACT_DSILO_DATA_MESSAGE: {
            return {
                ...state,
                message:'',
            }
        }
        default: {
            return state;
        }
    }
}