import { Button, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getDataModelById, updateDataModel } from '../../store/actions';
import { Loader } from '../loader';
import MultiInput from './multiInput';

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: "0 20px 20px 0",
    }
}));

const ModelAliasNameSupporter = (props) => {

    const [configObj, setConfigObj] = useState([]);
    const classes = useStyles();
    const { fetchType, selectedInnerDataElement, selectedDataModel } = props.data?.chartData;
    const { formData } = props;

    useEffect(() => {
        let payload = {
            appid: props.match.params.appid,
            isDownload: false,
            openFile: false,
            dataModelId: props.match.params.id,
            orgId: props.user.orgId,
        };
        if (fetchType) {
            payload['fetchType'] = fetchType;
        }
        if (selectedInnerDataElement) {
            payload['viewFields'] = selectedInnerDataElement.map(i => i.value).join(",")
        }
        props.getDataModelById({ ...payload, dataUniqueId: props.data.chartUniqueId + '_modalAlias' })

    }, []);

    useEffect(() => {
        let _dataModel = formData.dataModel[props.data.chartUniqueId + '_modalAlias']
        if (_dataModel?.dataElements?.length) {
            let _data = _dataModel.dataElements.map((item, index) => {
                    return {
                        ...item,
                        standardField: item.description,
                        columnFromFile: item.alternateColumnNames ? item.alternateColumnNames : [],
                        id: index + "", ...item
                    }
               
            }).filter(i=> i);
            setConfigObj(_data)
        }

    }, [formData.dataModel[props.data.chartUniqueId + '_modalAlias']])

    const onKeyChange = (key, id) => {
        let index = configObj.findIndex(i => i.id === id);
        if (index > -1) {
            let dd = configObj[index];
            dd.standardField = key;
            configObj[index] = dd;
            setConfigObj([...configObj]);
            props.onSave([...configObj]);
        }
    }

    const addField = (obj, fields) => {
        let index = configObj.findIndex(i => i.id === obj.id);
        let _data = [...configObj];
        _data[index].columnFromFile = fields;
        setConfigObj(_data);
    }

    const onSave = () => {
        let _dataModel = { ...formData.dataModel[props.data.chartUniqueId + '_modalAlias'] };
        let updatedDataElements = configObj.map(item => {
            let _updatedItem = item;
            _updatedItem.alternateColumnNames = item.columnFromFile
            delete item.standardField
            delete item.columnFromFile
            delete item.id
            if(!item.isModelKey && item.alternateColumnNames === ""){
                delete item.alternateColumnNames
            }
            return _updatedItem;
        })
        _dataModel['dataElements'] = updatedDataElements;
        props.updateDataModel({ data: _dataModel, dataUniqueId: props.data.chartUniqueId + '_modalAlias' });
        console.log('_dataModel', _dataModel)
    }

    const renderSaveButton = () => {
        return <div className={classes.buttonContainer} >
            <Button variant="contained"
                color="primary"
                onClick={onSave}>
                Save
            </Button>
        </div >
    }

    if (props.formData[props.data.chartUniqueId + '_modalAlias_loading']) {
        return <Loader />
    }

    return <div>
        {configObj.length!==0?renderSaveButton():null}
        {configObj.map((e, i) =>
            (e.isModelKey?<MultiInput
                key={i}
                onKeyChange={onKeyChange}
                keyEditDisabled={true}
                id={e.id}
                placeholder='Enter Name'
                values={e.columnFromFile}
                setValues={fields => addField(e, fields)}
                required
                classes={classes}
                title={e.standardField}
            />:null)
        )}
        {configObj.length ===0?<div style={{textAlign: 'center'}}>No label configuration found</div>:null}
        {configObj.length!==0?renderSaveButton():null}

    </div>
}

const mapDispatchToProps = {
    getDataModelById,
    updateDataModel,
};

const mapStateToProps = state => {
    return {
        formData: state.form,
        user: state.user,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ModelAliasNameSupporter)));
