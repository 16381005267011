import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import { AdvancedTable, TableSupport } from "dsilo-ui-components";
import React, { useEffect, useState } from "react";
import { hot } from "react-hot-loader/root";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getDataModelById } from "../../store/actions";
import { Loader } from "../loader";
import {PUBLIC_PATH} from 'constants/index'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  headerClass: {
    backgroundColor: '#cecece'
  },
  confirmationDialoge: {
    "& .MuiDialog-paperWidthSm": {
      height: "250px",
      width: "600px"
    }
  },
  para: {
    fontSize: "28px !important",
    fontWeight: 600,
    color: "#000"
  },
}));

const tableJson = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Document Type',
    sort: true,
  },
  {
    id: 'keyValueFields',
    numeric: false,
    disablePadding: false,
    label: 'Key Data Fields',
    sort: true,
  },

];


const ModelAliasNameList = props => {

  const sharedJSON = {
    "order": "asc",
    "orderBy": null,
    "selected": [],
    "tableData": [],
    "filterTableData": [],
    "page": 0,
    "count": 10,
    "rowsPerPage": Number(props.data.chartData?.pagination?.rowsPerPage ? props.data.chartData?.pagination?.rowsPerPage : 10),
    "searchText": "",
    "filterList": [],
    "filterData": []
  }

  const classes = useStyles();

  const [state, setState] = useState({
    ...sharedJSON,
  });

  const { document } = props.formData;
  const { data } = props;
  const { chartData } = props.data;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let payload = {
      appid: props.match.params.appid,
      isDownload: false,
      openFile: false,
      dataModelId: '',
      orgId: props.user.orgId,
    };
    if (fetchType) {
      payload['fetchType'] = fetchType;
    }
    if (selectedInnerDataElement) {
      payload['viewFields'] = selectedInnerDataElement.map(i => i.value).join(",")
    }
    setLoading(true)
    props.getDataModelById({ ...payload, dataUniqueId: props.data.chartUniqueId + '_allDataModels' })

  }, []);

  useEffect(() => {
    setLoading
    if (props.formData.dataModel[props.data.chartUniqueId + '_allDataModels']) {
      let keysArr = ['_id'];
      setState({ ...TableSupport.setDatafromApi2(getData(props.formData.dataModel[props.data.chartUniqueId + '_allDataModels']), keysArr, state, tableJson), count: props.formData.dataModel[props.data.chartUniqueId + '_allDataModels'].length });
    }
  }, [props.formData.dataModel[props.data.chartUniqueId + '_allDataModels']]);



  useEffect(() => {
    if (Object.keys(state).length) {
      setState({ ...TableSupport.updateTableData(state) });
    }
  }, [state.searchText, state.filterList]);

  const { fetchType, selectedDataModel, selectedDataElement, externalFilter, download, selectedInnerDataElement } = props.data.chartData;

  const getKeyValueFields = (dataElements)=>{
    if(dataElements?.length){
     let _dataElements =  dataElements.map(i=>{
        if(i.isModelKey){
          return i.description
        } 
      })
      return _dataElements.filter(i=> i).join(', ')
    } 
    return ""
  }

  const getData = (dataSource) => {
    return dataSource.map(item=>{
      return {
        name: item.name,
        keyValueFields: getKeyValueFields(item.dataElements),
        _id: item._id,
      }
    })
  }

  const searchHandler = e => {
    setState({ ...state, searchText: e ? e.target.value : '' });
  };

  const handleChangePage = (event, page) => {
    setState({ ...state, page: page });
  };

  const handleChangeRowsPerPage = event => {
    setState({ ...state, rowsPerPage: event.target.value, page: 0 });
  };

  const handleInputChange = event => {
    console.log('value:' + event.target.value + ' checked: ' + event.target.checked);
    let obj = state.filterTableData;
    let list = obj.columns;
    list.map((item, index) => {
      if (item.name === event.target.value) list[index].checked = event.target.checked;
    });
    obj.columns = [...list];
    setState({ ...state, filterTableData: obj });
    setState({ ...TableSupport.updateTableData(state) });
  };

  const resetFilters = event => {
    setState({ ...state, filterList: [] });
  };

  const handleDropdownChange = (event, index, column) => {
    const value = event.target.value === 'All' ? [] : [event.target.value];
    // filterUpdate(index, value, column, 'dropdown');
    let list = state.filterList;
    list[index] = event.target.value;
    setState({ ...state, filterList: [...list] });
    setState({ ...TableSupport.updateTableData(state) });
  };

  const handleRequestSort = (event, property) => {
    let obj = TableSupport.requestSort(event, property, state);
    console.log('obj', obj);
    setState({ ...state, ...obj });
  };

  const handleSelectAllClick = event => {
    setState({
      ...state,
      selected: TableSupport.handleTableSelectAllClick(event, state.filterTableData.datasets),
    });
  };

  const tableCrudOperations = (item, type) => {
    console.log(item);
    if (type === 'edit') {
      props.history.push({
        pathname: `/${PUBLIC_PATH}${props.match.params.appid}/page/${chartData.editPage}/${item._id}`,

      })

    }
    if (type === 'view') {
      props.history.push({
        pathname: `/${props.PUBLIC_PATH}${props.match.params.appid}/page/${chartData.viewPage}/${item._id}`,
      })
    }
    if (type === 'delete') {
      setDeleteConfirm({ ...deleteConfirmState, isOpen: true, data: item._id })
    }
    if (type === 'download') {
    }
  };

  const handleCheck = (event, id) => {
    const { selected } = state;
    let newSelected = TableSupport.handleSelectedRow(event, id, selected);
    setState({ ...state, selected: newSelected });
  };

  if (props.formData[props.data.chartUniqueId + '_allDataModels_loading']) {
    return <Loader />
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        {state.tableData && (
          <TableContainer style={{ maxWidth: '92vw' }} component={Paper}>
            {state.tableData && (
              <AdvancedTable
                title={chartData.name}
                key={props.data.chartUniqueId}
                name={'ContractList'}
                onRowClick={() => { }}
                {...props}
                {...state}
                chartData={chartData}
                searchHandler={searchHandler}
                isExport={download}
                isViewCoulmns={true}
                isSearch={true}
                handleChangePage={handleChangePage}
                isFilter={true}
                hasCheckbox={false}
                actions={{ edit: chartData.editPage, view: false, delete: false, copy: false, download: false }}
                isTablePagination={true}
                isTableHead={true}
                isTableSubHeader={false}
                exportArray={download === 'local' ? [{ key: 'xls', name: 'Excel', isVisible: true }, { key: 'csv', name: 'csv', isVisible: true }] :
                  [{ key: 'xlsx', name: 'Excel', isVisible: true }]}
                handleInputChange={handleInputChange}
                resetFilters={resetFilters}
                pagination = {{type: 'local'}}
                handleDropdownChange={handleDropdownChange}
                handleRequestSort={handleRequestSort}
                handleSelectAllClick={handleSelectAllClick}
                tableCrudOperations={tableCrudOperations}
                handleCheck={handleCheck}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                stripRows={true} // for changing BGcolor of odd rows
                headerstyle={{
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.87)',
                  textAlign: 'left',
                  backgroundColor: '#'
                }}
                subHeaderstyle={{
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.87)',
                  backgroundColor: '#FFF',
                  textAlign: 'left',
                }}
                headerClass={classes.headerClass}
                rowStyle={{
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.87)',
                  backgroundColor: 'none',
                  lineHeight: 1.5,
                  textAlign: 'left',
                }}
              />
            )}
          </TableContainer>
        )}
      </Paper>
    </div>
  );
};


const mapDispatchToProps = {
  getDataModelById,
};

const mapStateToProps = state => {
  return {
    formData: state.form,
    user: state.user,
  };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ModelAliasNameList)));
