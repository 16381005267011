import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import classnames from 'classnames';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PUBLIC_PATH } from '../../constants';

const dummyJson = [
    {
        title: "Net Loss",
        data: [
            { fileName: 'Q2 Earnings Release.pdf', reconciled: 'Yes', noOfOccurrences: 5, _id: '617163e7a378340044b1a0cd'},
            { fileName: 'As-Filed Doma Super 8-K_A.pdf ', reconciled: 'Yes', noOfOccurrences: 8, _id: '61715dfba378340044b1a050' },
        ],
    },
    {
        title: "Earnings Per Share",
        data: [
            { fileName: 'Q2 Earnings Release.pdf', reconciled: 'Yes', noOfOccurrences: 1, _id: '617163e7a378340044b1a0cd' },
            { fileName: 'As-Filed Doma Super 8-K_A.pdf', reconciled: 'Yes', noOfOccurrences: 3 , _id: '61715dfba378340044b1a050'},
        ],
    },
]

const useStyles = makeStyles({
    button: {
        '&:hover': {
            backgroundColor: '#007bff',
        },
        height: 35,
        backgroundColor: '#005a87',
        color: '#fff',
    },
    border: {
        border: '1px solid #cecece',
    },
    container: {
        padding: 24,
        textAlign: 'center'
    },
    value: {
        fontSize: 24,
        marginBottom: 20,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0 5px 0 5px',
    },
    label: {
        margin: '15px 0 5px 0',
    },
    center: {
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        textAlign: 'start',
        marginLeft: -24,
    },
    greyBG: {
        backgroundColor: '#ededed'
    }
});

const ReconciliationDashboard = (props) => {

    const classes = useStyles();
    return (
        <div>
            {/* <>
                <div style={{ paddingBottom: 20 }}>
                    <h1>Reconciliation Dashboard</h1>
                </div>
                <Divider />
            </> */}
            {
                dummyJson.map((item, index) => {
                    return <div className={classes.container} key={index}>
                        <Typography variant="h4" gutterBottom className={classes.title}>
                            {item.title}
                        </Typography>
                        {
                            item.data.map((ii, index1) => {
                                return <Grid container key={index1}>
                                    <Grid item xs={4} className={classnames(classes.border, classes.greyBG)}>
                                        <div className={classes.label}>Document</div>
                                        <div title={ii['fileName']} className={classes.value}>{ii['fileName']}</div>
                                    </Grid>
                                    <Grid item xs={3} className={classnames(classes.border, classes.greyBG)}>
                                        <div className={classes.label}>No: of occurrences</div>
                                        <div className={classes.value}>{ii['noOfOccurrences']}</div>
                                    </Grid>
                                    <Grid item xs={3} style={{ backgroundColor: '#b1cf95' }} className={classes.border}>
                                        <div className={classes.label}>Reconciled</div>
                                        <div className={classes.value}>{ii['reconciled']}</div>
                                    </Grid>
                                    <Grid item xs={2} className={classnames(classes.border, classes.center, classes.greyBG)}>
                                        <Button size="small" className={classes.button} onClick={() => { 
                                              props.history.push({
                                                pathname: `/${PUBLIC_PATH}${props.match.params.appid}/page/${props.data.chartData.viewPage}/${ii._id}`,
                                              })
                                        }}>
                                            View Details
                                        </Button>

                                    </Grid>

                                </Grid>
                            })
                        }

                    </div>
                })
            }
        </div>
    )
}

const mapDispatchToProps = {
};

const mapStateToProps = state => {
    return {
    
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReconciliationDashboard)));
