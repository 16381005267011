import { Request } from "./clientHelper";
const API_BASE_URL = process.env.REACT_APP_ACTION_SERVICE_URL;
const API_DATA_BASE_URL = process.env.REACT_APP_DATA_SERVICE_URL;

export class ActionService {

    static initiateAction(data) {
        let uri = API_BASE_URL + "/actions/initiateaction";
        let appId = data.appId
        return Request(uri, {
            url: uri,
            method: 'POST',
            payload: data.data,
            headers: {
                appId: appId,
            },
        });
    }

    static getEvent(data) {
        let uri = API_BASE_URL + "/events";
        let appId = data.appId
        return Request(uri, {
            url: uri,
            method: 'GET',
            payload: {},
            headers: {
                appId: appId,
            },
        });
    }
    static deleteEvent(data) {
        let uri = API_BASE_URL + "/events";
        let appId = data.appId
        return Request(uri, {
            url: uri,
            method: 'DELETE',
            payload: {},
            headers: {
                appId: appId,
            },
        });
    }
    static updateEvent(data) {
        let uri = API_BASE_URL + "/events";
        let appId = data.appId
        return Request(uri, {
            url: uri,
            method: 'PUT',
            payload: {},
            headers: {
                appId: appId,
            },
        });
    }
    static getEventByID(data) {
        let uri = API_BASE_URL + "/events/" + data.id;
        let appId = data.appId
        return Request(uri, {
            url: uri,
            method: 'GET',
            payload: {},
            headers: {
                appId: appId,
            },
        });
    }
    static saveDataByUrl(data) {
        let uri = API_DATA_BASE_URL + data.url;
        let appId = data.appId
        return Request(uri, {
            url: uri,
            method: 'POST',
            payload: data.data,
            headers: {
                appId: appId,
            },
        });
    }

    static getDataByUrl(data) {
        let uri = API_DATA_BASE_URL + data.url;
        let appId = data.appId
        return Request(uri, {
            url: uri,
            method: 'GET',
            payload: {},
            headers: {
                appId: appId,
            },
        });
    }

    static updateDataByUrl(data) {
        let uri = API_DATA_BASE_URL + data.url;
        let appId = data.appId
        return Request(uri, {
            url: uri,
            method: 'PUT',
            payload: data.data,
            headers: {
                appId: appId,
            },
        });
    }
    
    static deleteDatapoint(data) {
        let uri = API_DATA_BASE_URL + data.url;
        return Request(uri, {
            method: 'DELETE',
            payload: data,
            headers: {
                appId: data.appId
            }
        });
    }
}