/**
 * @module Sagas/Pages
 */
import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { pageService } from 'modules/api/index'
import { ActionTypes } from 'constants/index';

/**
 * Application
 *
 * @param {Object} action
 *
 */

async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

function* fetchPageConfig(action) {
    try {
        const pageConfig = yield fetchAsync(pageService.getPageConfig, action.appId);
        yield put({type: ActionTypes.LOAD_PAGE_CONFIG_SUCCESS, payload: pageConfig});
    } catch (e) {
        yield put({type: ActionTypes.LOAD_PAGE_CONFIG_ERROR, error: e.message});
    }
}

function* fetchPages(action) {
    try {
        const pages = yield fetchAsync(pageService.getPages, action.data);
        yield put({type: ActionTypes.LOAD_PAGES_SUCCESS, payload: pages});
    } catch (error) {
        yield put({type: ActionTypes.LOAD_PAGES_ERROR, error: error});
    }
}

function* getPageByUrl(action) {
    try {
        const pages = yield fetchAsync(pageService.getPageByUrl, action.data);
        yield put({type: ActionTypes.GET_PAGE_BY_URL_SUCCESS, payload: pages});
    } catch (error) {
        yield put({type: ActionTypes.GET_PAGE_BY_URL_FAILURE, error: error});
    }
}

function* savePage(action) {
    try {
        const page = yield fetchAsync(pageService.savePage, action.data);
        yield put({type: ActionTypes.SAVE_PAGE_SUCCESS, payload: page});
    } catch (e) {
        yield put({type: ActionTypes.SAVE_PAGE_ERROR, error: e.message});
    }
}

export function* watchPageSaga() {
    yield takeEvery(ActionTypes.LOAD_PAGE_CONFIG_LOADING, fetchPageConfig);
    yield takeEvery(ActionTypes.LOAD_PAGES_LOADING, fetchPages);
    yield takeEvery(ActionTypes.GET_PAGE_BY_URL, getPageByUrl);
    yield takeEvery(ActionTypes.SAVE_PAGE_LOADING, savePage);
}

export default watchPageSaga;
