//@ts-check

import React from "react";
import { hot } from "react-hot-loader/root";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PUBLIC_PATH } from '../../constants/index';
import {
    addComment, addNewEntriesByDataModel, changeAssigneeRequest, clearReduxDataOfCurrentComponent,
    clearSuccessMsg, completeReviewAction, downloadContractPDFAction, downloadSheet, duplicatesGetRequest, duplicatesMoveRequest, executeWorkFlow, executeWorkFlowReject, finalDocument, getAllOtherDocs, getAuditTrailsData, getChargeCodes, getContractDataById, getDataModelById, getDataModels, getEventByID, getGroups,
    getGroupsByMemberId, getMembersList, getModelData, getModelDataDropDown, getNextRespondent, getNextTasks, getPDFForOtherDoc, getSupportingDocuments, getVISData, getWorkflow, hideSuccessMessage,
    initiateAction, lockDocument, otherDocMove, splitPDF, unlockDocument, updateContractAnnotations, updateDataModelData, uploadSupportDocument, rejectReviewAction, getDuplicateDocById
} from '../../store/actions';
import { Loader } from "../loader";
import DuplicateViewerWrapper from './DuplicateViewerWrapper';

const DuplicateViewer = (props) => {
    return <DuplicateViewerWrapper {...props} loader={<Loader />} publicPath={PUBLIC_PATH} />
}

const mapDispatchToProps = {
    downloadContractPDFAction,
    clearReduxDataOfCurrentComponent,
    getContractDataById,
    getDataModels,
    getAllOtherDocs,
    getPDFForOtherDoc,
    otherDocMove,
    hideSuccessMessage,
    updateDataModelData,
    clearSuccessMsg,
    downloadSheet,
    getModelDataDropDown,
    getEventByID,
    initiateAction,
    getMembersList,
    updateContractAnnotations,
    getDataModelById,
    getModelData,
    getAuditTrailsData,
    changeAssigneeRequest,
    completeReviewAction,
    getWorkflow,
    getNextTasks,
    executeWorkFlow,
    getNextRespondent,
    getChargeCodes,
    executeWorkFlowReject,
    addComment,
    addNewEntriesByDataModel,
    getGroups,
    getGroupsByMemberId,
    getSupportingDocuments,
    finalDocument,
    splitPDF,
    uploadSupportDocument,
    getVISData,
    lockDocument,
    unlockDocument,
    duplicatesGetRequest,
    duplicatesMoveRequest,
    rejectReviewAction,
    getDuplicateDocById
};

const mapStateToProps = state => {
    return {
        formData: state.form,
        dataModels: state.dataModels,
        user: state.user,
        db: state.db,
        action: state.action,
        appConfig: state.appConfig.app,
        PUBLIC_PATH: PUBLIC_PATH,
        members: state.appConfig.members,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(DuplicateViewer)));