import React, { useState, useEffect } from "react";
import { Grid, Tabs, Tab, AppBar, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core"
import BasicInfo from "./BasicInfo";
import SecurityAccess from "./SecurityAccess";
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import { getMembersList, clearReduxDataOfCurrentComponent, getRolesMembers, getGroups, createMember, terminateMember } from '../../store/actions';
import { connect } from 'react-redux';
import { Loader } from "../../components/loader";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from "react-router-dom";
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    tabController: {
        "& .MuiTab-textColorInherit ": {
            minWidth: "0px"
        }
    },
    appBar: {
        boxShadow: "none !important"
    },
    ContactTabs: {
        marginTop: 'none !important',
        marginBottom: 'none !important',
        border: "3px solid #eeee",
    },
    paper: {
        width: '100%',
        // marginBottom: theme.spacing(2),
        border: "3px solid #eeee",
        marginLeft: '-41px !important'
    },
    backIconCls: {
        padding: '0px !important'
    }
}))

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AddMember = (props) => {
    const [tabVal, setTabVal] = useState(0);
    const classes = useStyles();
    const [membersList, setMembersList] = useState([])
    const [memberDetails, setMemberDetails] = useState({})
    const [membersRoles, setMembersRoles] = useState()
    const [membersGroup, setMembersGroup] = useState()
    const [fianlFormData, setFinalFormData] = useState({})
    const [validate, setValidate] = useState({})
    let history = useHistory();

    useEffect(() => {
        if (props.match.params.id !== 'new') {
            fetchMemberData()
            fetchData()
        } else {
            setMemberDetails({})
            fetchData()
        }
    }, [])

    const fetchMemberData = () => {
        Promise.all([
            props.getMembersList({
                appId: props.appId,
                dataUniqueId: props.data?.chartUniqueId + "_member",
                id: props.match.params.id
            })
        ].filter(Boolean))
    }

    const fetchData = () => {
        Promise.all([
            props.getRolesMembers({
                appId: props.appId,
                dataUniqueId: props.data?.chartUniqueId + "_member_roles",
                orgId: props.user.orgId
            }),
            props.getGroups({
                appId: props.appId,
                dataUniqueId: props.data?.chartUniqueId + "_member_groups",
                orgId: props.user.orgId
            })
        ].filter(Boolean))
    }

    useEffect(() => {
        if (props.formData?.document && props.formData.document[props.data?.chartUniqueId + "_member"]) {
            let memObj = props.formData.document[props.data?.chartUniqueId + "_member"]
            let obj = {
                "firstName": memObj.firstName ? memObj.firstName : memObj.name?.split(" ")[0],
                "lastName": memObj.lastName ? memObj.lastName : memObj.name?.split(" ")[1],
                "_id": memObj._id,
                "email": memObj.email,
                "status": memObj.status,
                // "phone": memObj.phone,
                "roles": memObj.roles?.map(item => ({ label: item.name, value: item.id })),
                "groups": memObj.groups?.map(item => ({ label: item.name, value: item.id })),
                "isDisable": memObj.isDisable
            }
            if (memObj?.supervisor?.id) {
                obj.supervisor = {
                    id: memObj.supervisor.id,
                    name: memObj.supervisor.name,
                    label: memObj.supervisor.name,
                    value: memObj.supervisor.id
                }
            }
            setFinalFormData({ ...fianlFormData, ...obj })
            // setMemberDetails(obj)
            setValidate({
                "firstName": obj.firstName ? false : true,
                "lastName": obj.lastName ? false : true,
                "email": obj.email ? false : true,
                // "phone": obj.phone ? false : true
            })
        }
    }, [props.formData?.document?.[props.data?.chartUniqueId + "_member"]])

    useEffect(() => {
        if (props.formData.document?.[props.data?.chartUniqueId + "_member_roles"]) {
            let memRoles = props.formData.document[props.data?.chartUniqueId + "_member_roles"]?.map(item => ({ label: item.description, value: item._id }))
            setMembersRoles(memRoles)
            // setFinalFormData({...fianlFormData,memRoles})		
        }
    }, [props.formData?.document?.[props.data?.chartUniqueId + "_member_roles"]])

    useEffect(() => {
        if (props.formData.document?.[props.data?.chartUniqueId + "_member_groups"]) {
            let memGroup = props.formData.document[props.data?.chartUniqueId + "_member_groups"]?.map(item => ({ label: item.description, value: item._id }))
            setMembersGroup(memGroup)
            // setFinalFormData({...fianlFormData,memGroup})			
        }
    }, [props.formData?.document?.[props.data?.chartUniqueId + "_member_groups"]])

    useEffect(() => {
        if (props.formData.document?.[props.data?.chartUniqueId + "_members"]?.length) {
            let membersListData = props.formData?.document[props.data?.chartUniqueId + "_members"].map(k => ({
                id: k._id,
                name: (k.name || (k.firstName + k.lastName)),
                value: k._id,
                label: (k.name || (k.firstName + k.lastName))
            }))
            setMembersList(membersListData)
        }
    }, [props.formData?.document?.[props.data?.chartUniqueId + "_members"]])

    useEffect(() => {
        if (props.formData.document?.[props.data?.chartUniqueId + "_members"]?.length) {
        } else {
            props.getMembersList({
                appId: props.appId,
                orgId: props.user.orgId,
                dataUniqueId: props.data?.chartUniqueId + "_members"
            })
        }
    }, [])

    useEffect(() => {
        return () => {
            clearCurrentChartReduxStore()
            setMemberDetails({})
        }
    }, [])

    const clearCurrentChartReduxStore = () => {
        let subKeys = [
            props.data?.chartUniqueId + "_member",
            props.data?.chartUniqueId + "_member_roles",
            props.data?.chartUniqueId + "_member_groups",
            props.data?.chartUniqueId + '_create_member',
            props.data?.chartUniqueId + '_terminate_member'
        ]

        props.clearReduxDataOfCurrentComponent({
            key: "document",
            subKey: subKeys
        })
    }

    const handlesSaveFunctionality = (data) => {
        setFinalFormData({ ...fianlFormData, ...data })
    }

    const validator = (data) => {
        setValidate({ ...validate, ...data })
    }

    const handleBackBtnClick = () => {
        history.goBack()
    }

    if (props.formData[props.data?.chartUniqueId + "_member_loading"] || props.formData[props.data?.chartUniqueId + "_member_roles_loading"] || props.formData[props.data?.chartUniqueId + "_member_groups_loading"]) {
        return <Loader />
    }

    return (
        <div style={{position: 'absolute'}}>
            <div style={{ display: 'flex' }}>
                <IconButton onClick={handleBackBtnClick} className={classes.backIconCls} title={'Back'}>
                    <ArrowBackIosIcon />
                </IconButton>
                <h1>{'User View'}</h1>
            </div>
            <Divider />
            <Grid container style={{ marginTop: 30, position: 'relative', overflow: 'visible'}} >
                <BasicInfo
                    {...props}
                    memberDetails={memberDetails}
                    fianlFormData={fianlFormData}
                    membersRoles={membersRoles}
                    membersGroup={membersGroup}
                    membersList={membersList}
                    createMember={props.createMember}
                    terminateMember={props.terminateMember}
                    validator={validator}
                    validate={validate}
                    handlesSaveFunctionality={handlesSaveFunctionality}
                />
            </Grid>
        </div>);
};

const mapDispatchToProps = {
    getMembersList,
    clearReduxDataOfCurrentComponent,
    getRolesMembers,
    getGroups,
    createMember,
    terminateMember
}
const mapStateToProps = state => {
    return {
        formData: state.form,
        user: state.user,
        org: state.org,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddMember)))


