import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import TitleTextField from '../TitleEditor';

const ComponentHeader = props => {
  const { chartItem } = props;
  const [chartTitleEditable, setChartTitleEditable] = useState(false);
  const [chartTitle, setChartTitle] = useState(chartItem?.title || '');

  const handleTitleChange = event => {
    setChartTitle(event.target.value);
  };

  const editChartTitle = () => {
    setChartTitleEditable(prev => !prev);
  };

  const updateChartTitle = () => {
    if (chartTitle !== chartItem?.title) {
      props.updateChartTitle(chartItem, chartTitle);
    }
    setChartTitleEditable(false);
  };

  return (
    <Box>
      {chartTitleEditable ? (
        <TitleTextField
          title={chartTitle}
          handleTitleChange={handleTitleChange}
          editTitle={editChartTitle}
          updateTitle={updateChartTitle}
        />
      ) : (
        <div
          className="user-message"
          style={{
            color: '#000000',
            fontSize: '18px',
            padding: '12px',
            textTransform: 'capitalize',
          }}
        >
          {chartItem.title || ''}
          {props.workspaceTabVal !== "all" && <IconButton aria-label="Edit Title" edge="end" onClick={editChartTitle}>
            <Edit size={18} />
          </IconButton>}
        </div>
      )}
    </Box>
  );
};

export default ComponentHeader;
