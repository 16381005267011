import React, { useEffect, useState } from 'react';

import {
    Box, Button, CircularProgress, Paper, Checkbox, IconButton, Input, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Typography, Tooltip, Grid,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { getOptionsForDropDownIfDataElementConfiguredWithData, Helpers, ConfirmationHandler } from 'dsilo-ui-components'
import AddIcon from '@material-ui/icons/Add';

import HeaderCard from '../../HeaderCard';
import useStyles from '../styles';
import TableView from './tableView';
import ModelWithTable from './model.js';


const DynamicViewDocs = (props) => {
    const classes = useStyles();

    const { orgId, appId, currentDocumentId, formData, selectedStep, eachBlock, block, blockIndex, blockState, docsJson, isLoading, loader, keyName, handleFetchDataOnModelClose } = props;
    // , blockHeaders, totalRecords, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, tableRows, 
    const { document } = props.formData;
    const { chartUniqueId } = props.data
    console.log("DynamicViewDocs Props ====> ", props, "\n\n\n DynamicViewDocs formData  ===>>>> ", formData);
    const [enabledBlocks, setEnabledBlocks] = useState([])
    const [onChangeValue, setOnChangeValue] = useState(false)
    const [visibleCellIndex, setVisible] = useState(null)
    const [modalInfo, setModalInfo] = useState({
        enable: false
    })
    const [keyVal, setKey] = useState("")
    const [blockHeaders, setBlockHeaders] = useState([])
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [eachBlockDocuments, setEachBlockDocuments] = useState([])
    const [deleteConfirmState, setDeleteConfirm] = useState({
        isOpen: false,
        deleteMessage: "Are you sure you want to delete ?",
        data: null
    })
    const [currentDataModel, setCurrentDataModel] = useState({})
    const [tableData, setTableData] = useState({});
    // const [tableRows, setTableRows] = useState([])
    let tableRows = []

    let showErrorIcon = false;

    const row = docsJson;

    // Below UseEffect is used to filter out the respective table rows from the state object to highlight them in the model table data.
    useEffect(() => {
        const dataModelID = eachBlock.dataModel;
        const key = block.dataElement;
        const stateDocsData = blockState[key];

        const eachDocList = stateDocsData && stateDocsData.length ? stateDocsData.filter((each) => each.dataModel == dataModelID) : []

        const eachDocListDocuments = eachDocList && eachDocList.length && eachDocList[0].documents && eachDocList[0].documents.length ? eachDocList[0].documents : []

        console.log("Local values --------->>>>>>>>>>> ", blockState, dataModelID, key, stateDocsData, eachDocList, eachDocListDocuments);
        setEachBlockDocuments(eachDocListDocuments)
        fetchDataModel()

    }, [eachBlock, blockState[block?.dataElement]])

    // Below UseEffect is used for get the enabled Blocks from the selected Step
    useEffect(() => {
        if (selectedStep?.blocks?.length) {
            let b = selectedStep.blocks.filter((block => block.enable))
            setEnabledBlocks(b)
        }
    }, [selectedStep])

    // Below UseEffect is used getting the BlockHeader from dataElements of DataModel Data and the Current Block
    useEffect(() => {
        if (formData?.dataModel[eachBlock.dataModel || selectedStep.dataModel]) {
            let dataElements = formData.dataModel[eachBlock.dataModel || selectedStep.dataModel].dataElements || []
            if (dataElements?.length) {
                let fdes = []
                console.log("Cmng here 000 ===> ", eachBlock.dataElements, eachBlock?.dataElements?.length);
                if (eachBlock?.dataElements) {
                    console.log("Cmng here 000 ===> ", eachBlock.dataElements, dataElements);
                    fdes = JSON.parse(JSON.stringify(eachBlock.dataElements))
                    fdes = fdes.map(fde => {
                        let sde = dataElements.find(de => de.name === fde.value)
                        if (sde?.name) {
                            return {
                                ...sde,
                                ...fde
                            }
                        } else {
                            return fde
                        }
                    })
                    setBlockHeaders(fdes)
                    console.log("Fdes 000 ===> ", fdes);
                }
            }
        }
    }, [formData?.dataModel])

    // Below function is used for fetching Datamodel data
    const fetchDataModel = () => {
        let payload = {
            appid: appId,
            orgId: orgId
        }
        if (eachBlock?.dataModel || selectedStep?.dataModel) {
            let ID = eachBlock.dataModel || selectedStep.dataModel;
            if (!formData?.dataModel?.[ID]) {
                if (!formData?.dataModel?.[ID] && !formData[`${ID}_loading`]) {
                    payload['dataModelId'] = ID
                    payload['dataUniqueId'] = ID
                    props.getDataModelById(payload)
                }
            } else {
                eachBlock?.dataModel ?  setCurrentDataModel(formData?.dataModel?.[ID]) : setCurrentDataModel(formData?.dataModel?.[ID])
            }
        }
    }

    // Below UseEffect is used for calling the filter API on the below dependency parameters
    useEffect(() => {
        fetchDataModel()
        filterDocIds()
    }, [blockHeaders, blockState[block?.dataElement], page, rowsPerPage]) // TODO: add a filterparameter also to call this api

    // Below filterDocIds is used for filtering the document Ids
    const filterDocIds = () => {
        console.log("\n\n\n\n EachBlock =============>>>>>>>>", eachBlock);
        let linkedData = blockState[block.dataElement];

        let docIdsList = []
        linkedData && linkedData.length && linkedData.forEach(obj => {
            if (obj.dataModel === eachBlock.dataModel) {
                docIdsList = obj.documents;
            }
        });

        console.log("\n\n\n key from stateobj ===>>>>", linkedData, docIdsList);
        getDocsfromIds(docIdsList, eachBlock.dataModel)
    }

    // Below getDocsfromIds is used for get the documents from API using the Ids
    const getDocsfromIds = (docIdsList, dataModel) => {
        if (docIdsList && docIdsList.length > 0) {
            let payload = {
                fields: eachBlock.dataElements.map(i => i.value).join(','),
                docIds: docIdsList,
                appId: appId,
                id: dataModel,
                page: page, rowsPerPage: rowsPerPage,
                // conditions: filterConditions,
            };
            console.log("Paylaod   ===>>>> ", payload);
            props.getDocumentsFromIDsOfOpportunity({ ...payload, dataUniqueId: chartUniqueId + '_documentsfromIds_' + eachBlock.dataModel });
        } else {
            // setTableRows([])
            setTableData({})
            tableRows = [];
            setTotalRecords(0)
        }
    }

    // Below useEffect is used for updating the state variable for documents from API using the Ids
    useEffect(() => {
        console.log("00000  dynamic assosiated Data ===>>>> ", formData?.document[chartUniqueId + '_documentsfromIds_' + eachBlock.dataModel]);

        if (formData?.document[chartUniqueId + '_documentsfromIds_' + eachBlock.dataModel]?.length) {
            let dataList = formData?.document[chartUniqueId + '_documentsfromIds_' + eachBlock.dataModel] || []
            let total = formData?.document[chartUniqueId + '_documentsfromIds_' + eachBlock.dataModel]?.length || []
            console.log("assosiated Data ===>>>> ", dataList);
            // const filteredData = dataModel[dataModelKey];
            // setBlockHeaders(transformedArray(filteredData))

            // setTableRows(dataList)
            setTableData({ ...tableData, [eachBlock.dataModel]: dataList })
            tableRows = dataList;
            setTotalRecords(total)

        } else {
            // setTableRows([])
            setTableData({})
            tableRows = [];
            setTotalRecords(0)
        }
    }, [props.formData?.document[chartUniqueId + '_documentsfromIds_' + eachBlock.dataModel]])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // const onChange = (id, name, value, valIndex, type = null) => {
    //     console.log("Onchange clicked ==> ", id, name, value, valIndex, type);
    // }

    // const onBlurHandler = (name, type) => {
    //     console.log("On Blur hit", name, type);
    // }

    // Below handleAddClick is used for opening up the model
    const handleAddClick = (title, key) => {
        console.log("Add Clicked ==> ", key);
        setKey(key)
        setModalInfo({
            ...modalInfo,
            enable: !modalInfo.enable,
            title: title
        })
    }

    // Model open/close handling here
    const toggleCompareModal = () => {
        setModalInfo({
            ...modalInfo,
            enable: !modalInfo.enable
        })
        // setTableData({})
        // tableRows = [];
        // setTotalRecords(0)
    }

    // On save click in the model, we are calling the updateOpportunity API,  with user selected rows.
    const onSaveHandler = (data) => {

        console.log("On Save Click ==> ", data, " \n\n block ==> ", block, "\n\n eachBlock ==> ", eachBlock, " \n\n blockState ===> ", blockState, "\n\nenabledBlocks ==>", enabledBlocks);

        let linkedData = blockState[block.dataElement];
        let foundCurrentDataModel = false;
        let updatedLinkedReferenceDocuments = []
        if (linkedData && linkedData.length) {
            updatedLinkedReferenceDocuments = linkedData.map(obj => {
                if (obj.dataModel === eachBlock.dataModel) {
                    foundCurrentDataModel = true;
                    return { dataModel: obj.dataModel, documents: data };
                }
                return obj;
            });
        }

        if (!foundCurrentDataModel) {
            updatedLinkedReferenceDocuments.push({ dataModel: eachBlock.dataModel, documents: data })
        }

        console.log(" key from stateobj", linkedData, updatedLinkedReferenceDocuments);

        let payloadData = {
            appId: appId,
            dataModelId: selectedStep.dataModel,
            dataUniqueId: chartUniqueId + '_updateopportunity',
            opportunityId: props.match.params.id,
            payload: {
                [block.dataElement]: updatedLinkedReferenceDocuments
            }
        }
        console.log("it is payload to update updateOpportunity api Identify Dynamic table data ===>>>", payloadData)
        props.updateOpportunity(payloadData);
    }

    // after updateOpportunity API success then we are closing the model and fetching the  updated data in the Tables
    useEffect(() => {
        if (props.formData?.document[chartUniqueId + '_updateopportunity']?._id) {
            (modalInfo.enable || deleteConfirmState.isOpen)&&  handleFetchDataOnModelClose() 
            setModalInfo({
                enable: false
            })
            setDeleteConfirm({ ...deleteConfirmState, isOpen: false, data: null })
        } else {
            console.log("Error in Update Dynamic table data");
        }
    }, [props.formData?.document[chartUniqueId + '_updateopportunity']])

    // Below function is to handle the actions from the table    
    const onTableActionClick = (type, data, url) => {
        if (type === 'edit') {
            setModalInfo({
                enable: true,
                type,
                data: {
                    id: data._id
                }
            })
        }

        if (type === "view") {
            props.history.push({
                pathname: `/${props.PUBLIC_PATH}${props.match.params.appid}/page/${url}/${data._id}`
            })
        }

        if (type === "delete") {
            setDeleteConfirm({
                ...deleteConfirmState,
                isOpen: true,
                data: data._id
            })
        }

        // if (type === 'delete') {
        //     handleDelete([data._id])
        // }
    }

    //Below function is to delete the row in the table
    const onDeleteResponse = (isDelete) => {
        if (isDelete) {
            let linkedData = blockState[block.dataElement];
            let foundCurrentDataModel = false;
            let updatedLinkedReferenceDocuments = []
            if (linkedData && linkedData.length) {
                updatedLinkedReferenceDocuments = linkedData.map(obj => {
                    if (obj.dataModel === eachBlock.dataModel) {
                        foundCurrentDataModel = true;
                        return { dataModel: obj.dataModel, documents: obj.documents.filter((each) => each !== deleteConfirmState.data) };
                    }
                    return obj;
                });
            }

            if (!foundCurrentDataModel) {
                updatedLinkedReferenceDocuments.push({ dataModel: eachBlock.dataModel, documents: obj.documents.filter((each) => each !== deleteConfirmState.data) })
            }

            console.log(" key from stateobj", linkedData, updatedLinkedReferenceDocuments);

            let payloadData = {
                appId: appId,
                dataModelId: selectedStep.dataModel,
                dataUniqueId: chartUniqueId + '_updateopportunity',
                opportunityId: props.match.params.id,
                payload: {
                    [block.dataElement]: updatedLinkedReferenceDocuments
                }
            }
            console.log("it is payload to update updateOpportunity api Identify Dynamic table data ===>>>", payloadData)
            props.updateOpportunity(payloadData);
        }
    }
 
    return (
        <React.Fragment key={keyName}>
            {console.log("\n\n\tDynamicViewDocs Block Headers ==> ", blockHeaders, blockState, block, eachBlock, "\n\n\n tableRows ===>>> ", tableRows, tableData)}
            <div class="stepper" style={{ margin: "0px 0px 15px 0px" }}>
                <div class="wrapper " style={{ fontWeight: "bold", fontSize: "14px", marginTop: "5px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <p className='title'>{eachBlock.title}</p>
                        {/* <div className='addClick' style={{ marginLeft: "10px", fontSize: "25px", cursor: "pointer", width: "20px", textAlign: "center" }} onClick={() => handleAddClick(eachBlock.title, eachBlock.dataModel)}>+</div> */}
                        {
                            eachBlock.actions?.enable && eachBlock.actions.add && <Tooltip title="Add Action">
                                <AddIcon
                                    fontSize='small'
                                    className={classes.addIcon}
                                    onClick={() => handleAddClick(eachBlock.title, eachBlock.dataModel)}
                                />
                            </Tooltip>
                        }
                    </div>

                    {isLoading ? loader : blockHeaders && blockHeaders.length > 0 ? <div>
                        <TableView
                            keyName={keyName}
                            classes={classes}
                            enableActions={eachBlock.actions?.enable}
                            tableActions={eachBlock.actions}
                            tableHeaders={blockHeaders}
                            // tableRows={tableRows}
                            tableRows={tableData[eachBlock.dataModel]}
                            onTableActionClick={onTableActionClick}
                            showCheckbox={false}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            totalRecords={totalRecords}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            loader={loader}
                            isLoading={formData?.[chartUniqueId + '_documentsfromIds_' + eachBlock.dataModel + '_loading']}

                        />
                    </div>
                        :
                        <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant="subtitle1" align='center'>No data to display</Typography>
                        </Grid>
                    }
                </div>
                {console.log("eachBlockDocuments ===> ", eachBlockDocuments)}
                {
                    modalInfo.enable && <ModelWithTable
                        {...props}
                        classes={classes}
                        // block={block}
                        block={eachBlock}
                        modalInfo={modalInfo}
                        dataModelKey={keyVal}
                        toggleCompareModal={toggleCompareModal}
                        onSaveHandler={onSaveHandler}
                        defSelectedList={eachBlockDocuments}
                        loader={loader}
                        isLoading={formData?.[chartUniqueId + '_documentsfromIds_' + eachBlock.dataModel + '_loading']}

                    />
                }
                <ConfirmationHandler
                    open={deleteConfirmState.isOpen}
                    handleClose={onDeleteResponse}
                    alertMessageText={deleteConfirmState.deleteMessage}
                    classes={classes}
                    confirm
                />
            </div>
        </React.Fragment>
    )
}

export default DynamicViewDocs