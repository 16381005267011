import React, { Fragment, useEffect, useState } from "react";
import { hot } from "react-hot-loader/root";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';

import { getAutocompleteSuggestions } from '../../../store/actions';

const useStyles = makeStyles(theme => ({
	inputRoot: {
	  paddingRight: '16px !important'
	},
}));

const suggestionUniqueId = 'autoSuggestions';

const InputContainer = (props) => {
	const { enableAutocomplete, globalLoading, handleSubmit } = props;
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [selectedValue, setSelectedValue] = useState({});
	const [inputValue, setInputValue] = useState('')
	const classes = useStyles();

	useEffect(() => {
		let debounceFn = null;
		if(enableAutocomplete && (inputValue === '' || (inputValue && inputValue.length >= 3))) {
			debounceFn = setTimeout(() => {
				try {
					let searchText = inputValue && inputValue.length >= 3 ? inputValue : '';
					const payload = {
						appId: props?.appId,
						dataUniqueId: suggestionUniqueId,
						payload: {
							searchText
						}
					}
					props.getAutocompleteSuggestions(payload);
				} catch (error) {
					console.log("error ==== = == = ", error);
					// setLoading(false)
				}
			}, 200);
		}
		
		return () => {
			enableAutocomplete && clearTimeout(debounceFn);
		};
	}, [enableAutocomplete, inputValue]);

	useEffect(() => {
		const suggestions = props?.chat?.data?.[suggestionUniqueId] || [];
		if (suggestions && suggestions?.length > 0) {
			const uniqueValuesSet = new Set();
			const responseData = suggestions.filter(search => {
				const searchText = search?.queryText.trim()
				const isPresentInSet = uniqueValuesSet.has(searchText);
				uniqueValuesSet.add(searchText);
				return !isPresentInSet;
			});
			uniqueValuesSet.clear();
			setOptions(responseData);
		} else setOptions([]);
	}, [props?.chat?.data?.[suggestionUniqueId]]);

	useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	useEffect(() => {
		if(inputValue === "") {
			setSelectedValue({});
			if(open) setOpen(false);
		}
	}, [inputValue]);

	const loading = props?.chat?.[`${suggestionUniqueId}_loading`] || false;
	  
	return (
		<div className="input-container">
			<Autocomplete
				fullWidth
				disableClearable={true}
				autoComplete={true}
				clearOnBlur={false}
				id="asynchronous-demo"
				classes={{ inputRoot: classes.inputRoot }}
				filterOptions={(option) => option}
				open={enableAutocomplete ? open : false}
				onOpen={() => {
					enableAutocomplete && setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				onInputChange={(_, newValue, reason) => {
					if (reason === 'input') setInputValue(newValue);
				}}
				disabled={globalLoading}
				onChange={(_, newValue) => {
					if(enableAutocomplete) {
						setSelectedValue(newValue);
						setInputValue(newValue.queryText);
					}
				}}
				getOptionLabel={(option) => option?.queryText || ""}
				options={enableAutocomplete ? options : []}
				loading={loading}
				noOptionsText={<Typography align="center" variant="h6" fullWidth>No results found</Typography>}
				style={{
					backgroundColor: 'white',
					marginBottom: '8px',
					marginRight: '1.5rem',
				}}
				onKeyUp={(event) => {
					if(event.key === 'Enter' && inputValue !== '' && !loading && !globalLoading) {
						event.preventDefault()
					  	handleSubmit(event, inputValue, setInputValue)
					}
				}}
				value={selectedValue}
				inputValue={inputValue}
				renderInput={(params) => (
					<TextField
						{...params}
						fullWidth
						placeholder="Enter your text here"
						variant="outlined"
						InputLabelProps={{
							shrink: true
						}}
						disabled={globalLoading}
						value={inputValue}
						InputProps={{
							...params.InputProps,
							endAdornment: (
							<>
								{loading && (<Fragment>
									{loading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</Fragment>)}
								{!loading && !globalLoading && (<InputAdornment position="end">
									<Divider 
										orientation="vertical" 
										style={{ height: '50px' }} 
									/>
									<IconButton
										aria-label="toggle password visibility"
										onClick={(e) => {
											e.preventDefault()
											handleSubmit(e, inputValue, setInputValue)
										}}
										edge="end"
									>
										<SendIcon color="primary" />
									</IconButton>
								</InputAdornment>)}
							</>),
						}}
					/>
				)}
			/>
		</div>
	);
}

InputContainer.propTypes = {
	inputValue: PropTypes.string.isRequired,
	// handleInputChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
	getAutocompleteSuggestions
};

const mapStateToProps = state => {
	return {
		formData: state.form,
		chat: state.chat
	};
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(InputContainer));

// export default InputContainer;