import { ActionTypes } from 'constants/index';

const initialState = {
    data: {},
};
// this reducer will have persist data use this for local storage purpose

export default function dbReducer(state = initialState, action) {
    if (action.type === ActionTypes.SET_DATA_TO_DB) {
        let _data = { ...state.data, [action.payload.key]: action.payload.data }
        return {
            ...state,
            data: _data,
        }
    }
    if (action.type === ActionTypes.CLEAR_DATA_FROM_DB) {
        let _data = { ...state.data };
        delete _data[action.payload.key];
        return {
            ...state,
            data: _data,
        }
    }
    return state;
}

