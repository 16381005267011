import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: "0px !important",
        minHeight: 300,
    },
    muiDailogPaper: {
        "& .MuiDialog-paperScrollPaper": {
            height: '-webkit-fill-available'
        }
    },
    block: {
        marginBottom: "10px"
    },
    blockHeader: {
        padding: "4px",
        backgroundColor: "#3477eb"
        // backgroundColor: "#3456eb"
    },
    blockContent: {
        paddingLeft: "4px",
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    selectedRow: {
        backgroundColor: "red",
        cursor: 'pointer'
    },
    pointer: {
        cursor: 'pointer'
    },
    tableRows: {
        '& .MuiTableCell-body': {
            padding: '0 10px !important',
            // paddingLeft: '10px !important',
            // paddingRight: '10px !important',
            // minHeight: 35,

        },
    },
    tableCellPadding: {
        '& .MuiTableCell-body:first-child': {
            paddingRight: '0px !important',
        },
    },
    head: {
        whiteSpace: "nowrap",
        backgroundColor: "#d5d5d5",
        fontSize: '13px'
    },
    input: {
        fontSize: '0.875rem',
        minWidth: '70px'
    },
    text: {
        display: 'flex', alignItems: 'center',
        padding: '6px 0'
    },
    textArea: {
        resize: 'none',
        width: "99%"
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: "flex-end"
    },
    chipSelect: {
        minWidth: 200,
        marginTop: 10,
        border: 'none'
    },
    chipSelectMinWidth: {
        minWidth: 145,
        marginTop: 0
    },
    chipSelectGlCodeWidth: {
        minWidth: 100,
        marginTop: 0
    },
    addNewBtn: {
        height: 24,
        fontSize: 12,
        textTransform: 'capitalize',
        float: 'right',
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 4
    },
    tableContainer: {
        maxWidth: '100% !important',
        // minHeight: 300,
        maxHeight: 400,
        overflowX: 'hidden'
    },
    wrapperStyle: {
        width: '100%',
        // border: 'none 0px RED',
        overflowX: 'auto',
        overflowY: 'hidden'
    },
    wrapper1: {
        height: 10
    },
    wrapper2: {
        height: 300
    },
    scrollDiv: {
        // minWidth: 1500,
        height: 20,
    },
    tableDiv: {
        // minWidth: 1500,
        height: 300,
        overflow: 'auto',
        width: "100%"
    },
    lineItemHeight: {
        height: 500,
    },
    listItemMinHeight: {
        minHeight: 500
    },
    checkBoxRootPadding: {
        padding: 0
    },
    tableCell: {
        // padding:'0px'
        '& .MuiTableCell-root': {
            padding: '0 !important'
        }
    },
    tableCellMaxWidth: {
        width: '100%',
        maxWidth: '300px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    statusDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid #ddd',
        alignItems: 'center',
        borderRadius: 4,
        padding: 5,
        marginTop: 10,
        fontSize: 13,
    },
    statusDivAction: {
        display: 'flex',
        alignItems: 'center',
    },
    exceptions: {
        marginLeft: 5,
        marginTop: 8,
        fontSize: 12,
        color: 'red',
        display: "flex",
        flexDirection: "column"
    },
    table: {
        minWidth: 750,
    },
    paperWidthMd: {
        maxWidth: "100% !important"
    },
    tableCellWrapText: {
        // display: '-webkit-box',
        fontSize: '14px',
        overflow: 'hidden',
        WebkitLineClamp: '6',
        WebkitBoxOrient: 'vertical',
        width: '250px',
        padding: "3px !important"
    },
    tableHeaderCell: {
        width: '250px',
        padding: "3px !important"
    },
    container: {
        maxHeight: 650,
    },
    searchTextField: {
        "& .MuiOutlinedInput-input": {
            height: 11,
            width: 84
        },
    },
    searchIcon: {
        fontSize: 40,
        paddingBottom: 5
    },
    filters: {
        display: "flex",
        alignItems: "flex-end"
    },
    tableActions: {
        display: "flex",
        alignItems: "center"
    },
    actionIcon: {
        cursor: "pointer"
    },
}));

export default useStyles