/**
 * @module Sagas/Notification
 * @desc App
 */
import { put, takeEvery } from 'redux-saga/effects';

import { ActionTypes } from '../constants/index';
import { NotificationService } from '../modules/api/index';

async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

function* getAllUnreadNotifications(action) {
    try {
        const response = yield fetchAsync(NotificationService.getAllUnreadNotifications, action.payload);
        yield put({ 
            type: ActionTypes.GET_ALL_UNREAD_NOTIFICATIONS_SUCCESS, 
            payload: { 
                data: response.data, 
            } 
        });
    } catch (e) {
        yield put({
            type: ActionTypes.GET_ALL_UNREAD_NOTIFICATIONS_FAILURE, 
            payload: {
                error:e.message, 
            } 
        });
    }
}

function* getNotificationById(action) {
    try {
        const response = yield fetchAsync(NotificationService.getNotificationById, action.payload);
        const singleNotificationData =
          response?.data && Array.isArray(response?.data) && response.data?.length > 0
            ? response.data?.[0]
            : {};
        yield put({ 
            type: ActionTypes.GET_SINGLE_NOTIFICATION_SUCCESS, 
            payload: { 
                data: singleNotificationData, 
            } 
        });
    } catch (e) {
        yield put({
            type: ActionTypes.GET_SINGLE_NOTIFICATION_FAILURE, 
            payload: {
                error: e.message, 
            } 
        });
    }
}

function* clearAllNotifications(action) {
    try {
        const response = yield fetchAsync(NotificationService.clearAllNotifications, action.payload);
        yield put({ 
            type: ActionTypes.CLEAR_ALL_NOTIFICATIONS_SUCCESS, 
            payload: { 
                data: [], //response?.data, 
            } 
        });
    } catch (e) {
        yield put({
            type: ActionTypes.CLEAR_ALL_NOTIFICATIONS_FAILURE, 
            payload: {
                error: e.message, 
            } 
        });
    }
}

function* clearSingleNotification(action) {
    try {
        const response = yield fetchAsync(NotificationService.clearSingleNotification, action.payload);
        yield put({ 
            type: ActionTypes.CLEAR_SINGLE_NOTIFICATION_SUCCESS, 
            payload: action.payload.payload.processId
        });
    } catch (e) {
        yield put({
            type: ActionTypes.CLEAR_SINGLE_NOTIFICATION_FAILURE, 
            payload: {
                error:e.message, 
            } 
        });
    }
}
/*
function* updateNotifications(action) {
     // show toast message here for new notification, when data is recieved
    try {
        yield put({ 
            type: ActionTypes.UPDATE_NOTIFICATIONS_SUCCESS, 
            payload: { 
                data: action?.payload.data, 
                // dataUniqueId: action?.payload 
            } 
        });
    } catch (e) {
        yield put({
            type: ActionTypes.UPDATE_NOTIFICATIONS_FAILURE, 
            payload: {
                error:e.message, 
                // dataUniqueId: action?.payload?.dataUniqueId
            } 
        });
    }
}
*/
export function* watchNavigationSaga() {
    yield takeEvery(ActionTypes.GET_ALL_UNREAD_NOTIFICATIONS, getAllUnreadNotifications);
    yield takeEvery(ActionTypes.GET_SINGLE_NOTIFICATION, getNotificationById);
    yield takeEvery(ActionTypes.CLEAR_ALL_NOTIFICATIONS, clearAllNotifications);
    yield takeEvery(ActionTypes.CLEAR_SINGLE_NOTIFICATION, clearSingleNotification);
    // yield takeEvery(ActionTypes.UPDATE_NOTIFICATIONS, updateNotifications);
}
  
export default watchNavigationSaga;
