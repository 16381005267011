import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
	uploadNote: {
		'& .note-title': {
			marginLeft: 23,
			color: 'red',
			fontSize: 16,
			fontWeight: 500
		},
		'& li': {
			fontSize: 16,
			paddingBottom: 3,

		},
		'& .contact-dsilo': {
			color: '#3979c4',
			cursor: 'pointer',
		},
	},
}));
// TODO: use this website to convert storable html inside DB  https://coderstoolbox.net/string/#!encoding=xml&action=encode&charset=us_ascii 

export default function UploadNote({ contactClick, note }) {
	const classes = useStyles();
	const onContactClick = () => {
		if (contactClick) {
			contactClick()
		}
	}
	return (
		<div className="upload-note-container">
			<div className={classes.uploadNote + " upload-note"}>
				<div className={"note-title"} >Note:</div>
				<ul>
					{
						Array.isArray(note) && Boolean(note.length) ?
							<React.Fragment>
								{
									note.map((item, index) => {
										return <li key={index} dangerouslySetInnerHTML={{ __html: _.unescape(item) }} />
									})
								}
							</React.Fragment>
							:
							<React.Fragment>
								<li>Only <b>PDF</b> files are allowed.</li>
								<li>Upto <b>10 files</b> are allowed,</li>
								<li>Upload <b>Material contracts</b> to see best results</li>
							</React.Fragment>

					}
				</ul>

			</div>
		</div>
	)
}








