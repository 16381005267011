import { makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: 15,
    },
    paper: {
        background: '#ffffff',
        boxShadow: '2px 10px 20px rgba(0, 0, 0, 0.1)',
        borderRadius: 7,
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
        padding: '25px 0px 5px',
        margin: '0px 8px',
    },
    title: {
        fontSize: 16,
        margin: "16%",
        color: 'grey',

    },
    item: {
        padding: '8px 8px 0 8px',
    },
    value: {
        fontSize: 32,
        fontWeight: 'bold',
        color: '#000000',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        overflow: 'hidden',
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    label: {
        fontSize: 14,
        // margin: '0 -10px 15px -10px',
        color: '#000000',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
}));



const MultiLabelTile = ({ title, list, width }) => {

    const classes = useStyles();

    const getVal = (value) => {
        if (typeof value === 'number') {
            return value.toLocaleString()
        }
        return value;
    }

    return (
        <Paper>
            <span className={classes.title}>{title}</span>
            <Grid container style={{ margin: "6%" }}>
                {
                    list?.map((item, index) => {
                        return <Grid item xs={6} >
                            <span className={classes.label}>{item.label.slice(0, 20).concat('...')}</span>
                            <span className={classes.value}>{getVal(item.value)}</span>
                        </Grid>
                    })
                }
            </Grid>
        </Paper>
    )
}

export default MultiLabelTile;