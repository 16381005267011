
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: 'auto',
		padding: 0,
		'&:hover': {
			backgroundColor: "transparent !important"
		},
	},
	loader: {
		display: "block",
		margin: "0 auto",
	},
	sideIconList: {
		display: "flex", 
		flexDirection: "column", 
		marginLeft: "0.5rem",
		// justifyContent: 'space-between'
	},
	mainBox: { display: "flex" },
	childBox: { display: "flex", flexDirection: "column", width: "-webkit-fill-available" },
	promptStyle: { marginTop: '1.5rem' }
}));

export default useStyles