import { ActionTypes } from 'constants/index';

const initialState = {
    pages: [],
    pageByUrl: null,
    pageByUrlLoading: false,
    loading: false,
    error: ''
};
export default function pageReducer(state = initialState, action) {

    switch (action.type) {
        case ActionTypes.LOAD_PAGES_LOADING: {
            return {
                ...state,
                loading: true,
                error: ''
            };
        }
        case ActionTypes.LOAD_PAGES_SUCCESS: {
            return {
                ...state,
                pages: action.payload.data ? action.payload.data : [],
                loading: false
            }
        }
        case ActionTypes.LOAD_PAGES_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case ActionTypes.GET_PAGE_BY_URL: {
            return {
                ...state,
                pageByUrlLoading: true,
                pageByUrl: null,
                error: ''
            };
        }
        case ActionTypes.GET_PAGE_BY_URL_SUCCESS: {
            return {
                ...state,
                pageByUrl: action.payload.data ? action.payload.data : null,
                pageByUrlLoading: false
            }
        }
        case ActionTypes.GET_PAGE_BY_URL_ERROR: {
            return {
                ...state,
                pageByUrlLoading: false,
                pageByUrl: null,
                error: action.error
            };
        }
        default: {
            return state;
        }
    }
}