import React, { useEffect, useState } from 'react';

import { Helpers } from "dsilo-ui-components";
import useStyles from './styles';
import { Grid } from '@material-ui/core';

const SimpleGrid = (props) => {
    const classes = useStyles()

    const { block, currentDataModel, currentDocumentData } = props

    const [blockHeaders, setBlockHeaders] = useState([])

    useEffect(() => {
        if (currentDataModel?.dataElements) {
            const dataElements = currentDataModel.dataElements || []
            if (dataElements?.length) {
                let fdes = []
                if (block.config.dataElements) {
                    fdes = JSON.parse(JSON.stringify(block.config.dataElements))
                    fdes = fdes.map(fde => {
                        let sde = dataElements.find(de => de.name === fde.value)
                        if (sde?.name) {
                            return {
                                ...sde,
                                ...fde
                            }
                        } else {
                            return fde
                        }
                    })
                    setBlockHeaders(fdes)
                }
            }
        }
    }, [currentDataModel])

    return (
        <div>
            {
                block.label && <div>
                    <h4>{block.label}</h4>
                </div>
            }
            {blockHeaders.length > 0 && <Grid container spacing={3}>
                {blockHeaders.map((element, index) => {
                    const backgroundColor = element.backgroundColor || block.backgroundColor || '#3456eb'
                    const value = Helpers.generateValueByDataElementType(currentDocumentData, element, true) || 'N/A'
                    return (
                        <Grid item xs={2} key={index}>
                            <div className={classes.container + " dsilo-custom-colored-box"}
                                style={{ backgroundColor: backgroundColor }}
                            >
                                <div className={classes.flexColumn}>
                                    <h4 className={`${classes.title} ${classes.colorWhite}`}>
                                        {element.label}
                                    </h4>
                                    <h1 className={classes.colorWhite}>{value}</h1>
                                </div>
                            </div>
                        </Grid>
                    )
                })
                }
            </Grid>
            }
        </div>
    )
}

export default SimpleGrid