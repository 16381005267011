import React, { useEffect } from 'react';
import { hot } from "react-hot-loader/root";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { history } from 'dsilo-ui-components';
import { handleRedirect } from '../Login/msalService';
import { Loader } from '../../components/loader';
import jwtService from '../../jwtService';
import { saveAadUserDetails } from '../../store/actions';
import { toast } from 'react-toastify';

const MFAComponent = (props) => {
	useEffect(() => {
		try {
			const rawMsalAdConfig = localStorage.getItem('adConfig');
			console.log('rawMsalAdConfig ::: *** ', rawMsalAdConfig)
			if (rawMsalAdConfig) {
				const msalAdConfig = JSON.parse(window.atob(rawMsalAdConfig));
				async function processRedirect() {
					const result = await handleRedirect(msalAdConfig);
					console.log('result ::: *** ', result)
					if (result && result.account) {
						const accessToken = result?.accessToken || result?.idToken || '';
						jwtService.setSession(accessToken)
						const aadLoginResponse = await jwtService.aadLogin({ accessToken, orgId: msalAdConfig?.orgId });
						console.log('aadLoginResponse ::: *** ', aadLoginResponse)
						const userData = {
							...aadLoginResponse?.data,
							isAdLogin: true
						}
						props.saveAadUserDetails(userData);
						localStorage.setItem('isAdLogin', 'true');
						// localStorage.removeItem('adConfig');
						history.push(`/apploading`);
					} else {
						history.push(`/login`);
						toast.error('MFA is failed at the time of login. Please try again.');
					}
				}
				processRedirect();
			} else {
				history.push(`/login`);
				toast.error('MFA is failed at the time of login. Please try again.');
			}
		} catch (cError) {
			console.log('errror in try catch ::: *** ', cError);
		}
	}, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100vh' }}>
            Authenticating Please wait ...
            <Loader />
        </div>
    )
}


const mapDispatchToProps = {
	saveAadUserDetails
};

const mapStateToProps = ({ user, login, app }) => {
    return {
        login,
        user,
        app
    }
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(MFAComponent)));
