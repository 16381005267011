import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerBtn: {
    margin: '0 2rem 0 1rem',
  },
  sideNav: {
    '& .makeStyles-drawerOpen-6': {
      width: '280px',
    },
  },
  listItemRoot: {
    paddingTop: '4px',
    paddingBottom: '4px',
    borderBottom: '1px solid #F7F7F7',
  },
  listItemIconRoot: {
    minWidth: theme.spacing(4),
  },
  listItemTextRoot: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    // marginLeft: 12
  },
  listItemTextPrimary: {
    fontSize: '12px',
  },
  selectedChat: {
    backgroundColor: '#EDEDED !important',
  },
  circularProgress: {
    position: 'relative',
    top: '4rem',
    textAlign: 'center',
  },
  tabsPaper: {
    position: 'relative',
    top: '4rem',
    zIndex: 1312,
    display: 'flex',
    justifyContent: 'space-between',
    height: 40,
    alignItems: 'center',
    padding: '5px 10px',
    backgroundColor: '#002744',
    color: '#FFFFFF',
    opacity: '0.75',
  },
  customBadge: {
    backgroundColor: '#002744',
    color: '#FFFFFF',
    opacity: '0.75',
  },
  tabController: {
    "& .MuiTab-textColorInherit ": {
      minWidth: "0px",
      fontSize: "12px",
      // padding:"0px"
      minHeight: "0px",
      textTransform: "none"
    },
    "& .MuiTabs-indicator": {
      top: "24px",
      bottom: "none"
    }
  },
}));

export default useStyles;
