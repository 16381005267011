const BASE_URL_CREATE = process.env.REACT_APP_API_BASE_URL_CREATE;

import { request } from './../client';
import axios from "axios";

export class OrgService {
  static createOrg(data) {
    const uri = BASE_URL_CREATE + '/orgs/create';

    return request(uri, {
      method: 'POST',
      payload: data,
    });
  }
  static getOrgDetails() {
    const uri = BASE_URL_CREATE + '/orgs/getdetails';

    return request(uri, {
      method: 'GET',
    });
  }
  static addMember(data) {
    const uri = BASE_URL_CREATE + '/orgs/addmember';
    return request(uri, {
      method: 'POST',
      payload: data,
      headers: {
        appId: data.appId,
        orgId: data.orgId,
      },
    });
  }

  static updateOrg(data) {
    const uri = BASE_URL_CREATE + '/orgs/';

    return axios(uri, {
      method: 'PUT',
      data:  data.formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        appId: data.appId,
      },
    });
  }

  static getOrgLogo(logoId) {
    const uri = BASE_URL_CREATE + '/orgs/logo/'+logoId;

    return  axios(uri, {
      responseType: 'arraybuffer'
    });
  }

}

