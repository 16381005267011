
//@ts-check
import React, { useEffect, useState } from "react";

import { toast } from 'react-toastify';
import {
    ChipSelect, DuplicateDocAndDetails, DuplicateOriginalPDFs,
    Helpers,
    OriginalAndDuplicates,
    OriginalDocAndDetails
} from 'dsilo-ui-components';

import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import { CircularProgress, IconButton, makeStyles, Tooltip, Divider } from '@material-ui/core';

const Views = {
    DuplicateDocAndDetails: 'DuplicateDocAndDetails',
    DuplicateOriginalPDFs: 'DuplicateOriginalPDFs',
    OriginalDocAndDetails: 'OriginalDocAndDetails',
    OriginalAndDuplicates: 'OriginalAndDuplicates',
}

const defaultView = {
    value: Views.DuplicateDocAndDetails,
    label: 'Only duplicate document',
}

const useStyles = makeStyles(() => ({
    chipSelect: {
        width: 315,
        marginRight: 10,
    },
    container: {
        overflow: 'auto',
        height: 'calc(100vh - 190px)',
    },
    rightSection: {
        display: 'flex',
        alignItems: 'center',
    },
    header: { paddingBottom: 10, display: 'flex', justifyContent: 'space-between' }
}));

const DuplicateViewerWrapper = (props) => {

    const [view, setView] = useState(defaultView)
    const classes = useStyles()

    const getOptions = () => {
        return [
            defaultView,
            {
                value: Views.DuplicateOriginalPDFs,
                label: 'Only PDF files of duplicate and original',
            },
            {
                value: Views.OriginalDocAndDetails,
                label: 'Only original document',
            },
            {
                value: Views.OriginalAndDuplicates,
                label: 'Both documents with details',
            },
        ]
    }

    useEffect(() => {
        if (props.formData.document?.[props.data.chartUniqueId + '_move']) {
            toast.success(props.formData.document[props.data.chartUniqueId + '_move']?.message || "Successfully moved");
            props.clearReduxDataOfCurrentComponent({
                key: "document",
                subKey: [props.data?.chartUniqueId + '_move']
            })
        }
    }, [props.formData.document?.[props.data.chartUniqueId + '_move']])

    const handleMoveClick = () => {
        let payload = {
            appId: props.match.params.appid,
            dataModelId: props.data.chartData.selectedDataModel,
            dataUniqueId: props.data.chartUniqueId + '_move',
            payload: {
                docId: props.match.params.id,
                copy: true,
            }
        }
        if (props?.data?.chartData?.hasOwnProperty('qaProcess')) {
            payload.payload['qaProcess'] = props.data.chartData.qaProcess
        }
        props.duplicatesMoveRequest(payload)
    }

    const renderComponents = () => {
        switch (view.value) {
            case Views.DuplicateOriginalPDFs:
                return <DuplicateOriginalPDFs key={Views.DuplicateOriginalPDFs} {...props} />;
            case Views.OriginalDocAndDetails:
                return <OriginalDocAndDetails key={Views.OriginalDocAndDetails} {...props} />;
            case Views.OriginalAndDuplicates:
                return <OriginalAndDuplicates key={Views.OriginalAndDuplicates} {...props} />;
            default:
                return <DuplicateDocAndDetails key={Views.DuplicateDocAndDetails} {...props} />;
        }
    }

    return (
        <div>
            <div>
                <div className={classes.header}>
                    <h1>{props.page.title}</h1>
                    <div className={classes.rightSection}>
                        <ChipSelect
                            className={classes.chipSelect}
                            onChange={e => setView(e)}
                            value={view}
                            textFieldProps={{
                                InputLabelProps: {
                                    shrink: true,
                                },
                                variant: 'outlined',
                            }}
                            variant="fixed"
                            options={getOptions()} />
                        {props.data.chartData.moveOutOfDuplicates &&
                            <Tooltip title='Move'>
                                <IconButton onClick={handleMoveClick}>
                                    {Helpers.loading(props, '_move') ? <CircularProgress color='#fff' size={24} /> : <MoveToInboxIcon />}
                                </IconButton>
                            </Tooltip>}
                    </div>
                </div>

                <Divider />
            </div>
            <div className={classes.container}>
                {
                    renderComponents()
                }
            </div>
        </div>
    )
}

export default DuplicateViewerWrapper;
