import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import BarChartIcon from '@material-ui/icons/BarChart';
import DehazeIcon from '@material-ui/icons/Dehaze';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import PieChartIcon from '@material-ui/icons/PieChart';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: 'auto',
		padding: 0,
		'&:hover': {
			backgroundColor: "transparent !important"
		},
	},
}));

const StyledMenu = withStyles({
	paper: {
		border: '1px solid #d3d4d5',
	},
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		{...props}
	/>
));

const StyledMenuItem = withStyles((theme) => ({
	root: {
		'&:focus': {
			backgroundColor: theme.palette.primary.main,
			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
				color: theme.palette.common.white,
			},
		},
	},
}))(MenuItem);

const chartList = [
	{
		type: "bar",
		icon: <BarChartIcon fontSize="small" />
	},
	{
		type: "doughnut",
		icon: <DonutLargeIcon fontSize="small" />
	},
	{
		type: "pie",
		icon: <PieChartIcon fontSize="small" />
	},
	{
		type: "line",
		icon: <MultilineChartIcon fontSize="small" />
	},
	{
		type: "table",
		icon: <TableChartOutlinedIcon fontSize="small" />
	}
];

const CurrentChart = ({ type }) => {
	switch (type) {
		case "bar":
			return <BarChartIcon />
		case "doughnut":
			return <DonutLargeIcon />
		case "pie":
			return <PieChartIcon />
		case "line":
			return <MultilineChartIcon />
		case "table":
			return <TableChartOutlinedIcon />
		default:
			return <DehazeIcon />
	}
}

const ChartMenu = (props) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const classes = useStyles();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenuSelect = (value) => {
		props.toggleChartType(value, props?.index)
		handleClose();
	}

	return <>
		<Button aria-controls="simple-menu" aria-haspopup="true" className={classes.root} onClick={handleClick}>
			<CurrentChart type={props?.chartType || "bar"} />
		</Button>
		<StyledMenu
			id="customized-menu"
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={handleClose}
		>
			{chartList.map((chart, index) => (
				<StyledMenuItem
					key={index}
					onClick={() => handleMenuSelect(chart.type)} 
					value={chart.type} 
					selected={props?.chartType === chart.type}
				>
					{chart.icon}
				</StyledMenuItem>
			))}
		</StyledMenu>
	</>
}

export default ChartMenu;