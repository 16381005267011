import { ActionTypes } from 'constants/index';

const initialState = {
    entities: null,
    loading: false,
    error: '',
};

export default function entityReducer(state = initialState, action) {
    
    switch (action.type) {
        case ActionTypes.LOAD_ENTITIES_SUCCESS: {
            return {
                ...state,
                entities: action.payload,
                loading: false
            }
        }
        case ActionTypes.LOAD_ENTITIES_ERROR: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        default: {
            return state;
        }
    }
}