import { makeStyles } from "@material-ui/core";
import AssistantIcon from '@material-ui/icons/Assistant';
import ArchiveIcon from '@material-ui/icons/Archive';
import ChatIcon from '@material-ui/icons/Chat';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PeopleIcon from '@material-ui/icons/People';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import { Groups } from "@styled-icons/material/Groups";
import { FileEarmarkBinaryFill } from "@styled-icons/bootstrap/FileEarmarkBinaryFill";
import { Organization } from "@styled-icons/fluentui-system-filled/Organization";
import { Workspaces } from '@styled-icons/material';
import { Folder, FolderOpen } from "@styled-icons/boxicons-solid";
import { DocumentSearch } from "@styled-icons/fluentui-system-filled/DocumentSearch";
import { Database } from "@styled-icons/fa-solid/Database";
import UploadContracts from '../../images/menu-icon-upload-contracts-active.svg';
import { Sidebar } from "styled-icons/boxicons-regular";

const drawerWidth = 240;

export const useStyles = makeStyles(theme => {
	console.log(window.innerHeight, "window.innerHeight <= 1050 " )
	return {
		root: {
			display: 'flex',
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
		},
		menuButton: {
			marginRight: 20,
		},
		hide: {
			display: 'none',
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
		},
		drawerOpen: {
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: 'hidden',
			width: theme.spacing(7) + 1,
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9) + 1,
			},
		},
		toolbarSidebar: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
			...theme.mixins.toolbar
		},
		toolbar: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
			...theme.mixins.toolbar,
			minHeight : "34px !important"
		},
		content: {
			flexGrow: 1,
			// padding: theme.spacing(4),
			padding: "30px 10px 10px 10px",
			paddingBottom: 0,
			paddingRight: 0,
			backgroundColor: '#fff',
			// backgroundColor: "rgb(247, 247, 247)",
			minHeight: '100%',
			height: '87vh',
		},
		icon: {
			padding: 10,
		},
		large: {
			width: '35px !important',
			height: '35px !important',
			cursor: 'pointer',
		},
		nestedIcon: {
			paddingLeft: 20,
		},
		loaderContainer: {
			display: 'flex',
			margin: 'auto',
			height: '100vh',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100vw'
		},
		fullContentBgColor: {
			backgroundColor: "#EDEDED",
			minHeight: "100vh",
			height: "100%"
		}
	}
});

export const compare = (a, b) => {
	let comparison = 0;
	if (a.rank > b.rank) {
		comparison = 1;
	} else if (a.rank < b.rank) {
		comparison = -1;
	}
	if (a.rank === 0)
		comparison = 1
	return comparison;
}

const getMenuIconByPageType = (pageType) => {
	const Icons = {
		congnitivesearch: DocumentSearch,
		aiassistant: AssistantIcon,
		archive: ArchiveIcon,
		dashboard: DashboardIcon,
		delete: DeleteIcon,
		delegation: Organization,
		duplicates: FileCopyIcon,
		group: Folder,
		masterdata: Database,
		myworkspace: Workspaces,
		others: FileEarmarkBinaryFill,
		procuregpt: ChatIcon,
		search: SearchIcon,
		system: SettingsIcon,
		systemusers: PeopleIcon,
		systemgroups: Groups,
		upload: UploadContracts,
	}
	return Icons?.[pageType] || FolderOpen;
}

const generateMenuInfo = (page, type, initialUrl) => {
	let { name, title, activeIcon, icon, url, _id, rank, labelPage, isLabel, type: pageType } = page
	pageType = pageType ? pageType.toLowerCase() : "item"
	let MenuIcon = getMenuIconByPageType(pageType)
	let obj = {
		id: name,
		title,
		type,
		pageType,
		open: page.menuOpen || false,
		activeIcon,
		icon,
		MenuIcon,
		url: page.selfserviceai || page.directRoute ? `/${initialUrl}/${url}` : (`/${initialUrl}/page/${url}`),
		currentPageUrl: url,
		exact: true,
		muiIcon: (icon || activeIcon) || pageType !== 'upload', //TODO:
		children: [], //TODO:
		_id: _id,
		rank: rank
	}
	if (labelPage)
		obj.parentId = labelPage
	if (isLabel && labelPage)
		obj.isLabelAndCild = true
	return obj
}

export const generatePendingItems = (items, groups) => {
	let pendingItems = []
	items.map(item => {
		let isChild = false
		groups.map(g => {
			if (g._id === item.parentId) {
				isChild = true
				g.children.push(item)
			}
		})
		if (!isChild)
			pendingItems.push(item)
	})
	return pendingItems;
}

export const sortedDynamicMenuGroups = (groups) => {
	let gs = groups.sort(compare);
	gs.map(g => {
		if (g.children.length > 0) {
			g.children = g.children.sort(compare)
		}
	})
	return gs
}

/**
 * This fucntion will help to merge groups which will be child components of other groups
 */
export const handleGroupsWithLabelPage = (groups) => {
	let gs = [...groups]
	let deleteIndex = []
	gs.map((g, dInd) => {
		if (g.isLabelAndCild && g.parentId) {
			gs.map((gl, i) => {
				if (gl._id === g.parentId) {
					gl.children.push(g)
					deleteIndex.push(g._id)
				}
			})
		}
	})
	return gs.filter(i => !deleteIndex.includes(i._id))

}

export const generateDynamicMenuGroups = (initialUrl, ps) => {
	let groups = [], items = []
	ps.filter(i => i && !i.hide).forEach(page => {
		if (page.isLabel) {
			let obj = generateMenuInfo(page, 'group', initialUrl)
			groups.push(obj)
		} else {
			let obj = generateMenuInfo(page, 'item', initialUrl)
			items.push(obj)
		}
	})

	let pendingItems = generatePendingItems(items, groups)

	let groupKeys = {};
	groups.forEach(i => {
		groupKeys[i._id] = true;
	})

	pendingItems = pendingItems.filter(i => i.parentId ? groupKeys[i.parentId] : true)
	// to hide pages which are part of a group but group is missing

	groups = [...groups, ...pendingItems]

	groups = handleGroupsWithLabelPage(groups)

	groups = sortedDynamicMenuGroups(groups)

	return groups
}

export const generateDynamicMenu = (initialUrl, pages) => {
	if (pages && pages.length > 0) {
		let ps = [...pages]
		// ps = ps.sort(compare);
		let groups = generateDynamicMenuGroups(initialUrl, ps)
		/*return ps.filter(i => i && !i.hide).map(page => {
			return ({
				'id': page.name,
				'title': page.title,
				'type': page.menuType ?? 'item',
				'activeIcon': page.url === 'upload' ? UploadContracts : DashboardIcon, //TODO:
				'icon': page.url === 'upload' ? UploadContracts : DashboardIcon, //TODO:
				'url': `/${initialUrl}/page/${page.url}`,
				'exact': true,
				muiIcon: page.url !== 'upload', //TODO:
				'children': [], //TODO:
			})
		}) */
		return groups
	}
	return []
}

export const intermediateMenuItems = (menuItems, props) => {
	return menuItems.filter((menu) => {
		if (props.user?.userName !== "Dsilo Support" && props.user?.orgId === "6194fdb626ca6952a90412b5") {
			if (menu?.id === 'Transaction' || menu?.id === 'Rules Page' || menu?.id === 'Reconciliation') {
				return false
			} else {
				return true
			}
		}
		return true
	})
}