import { Models } from 'dsilo-ui-components';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getModels } from '../../store/actions';
import Layout from '../Layout/Layout';
import { Loader } from '../loader';

const SelfServiceModels = props => {
	if (props.models.loading) {
		return <Loader />
	}
	return (
		<Layout>
			<Models />
		</Layout>

	);
}

const mapDispatchToProps = {
	getModels,
};

const mapStateToProps = state => {
	return {
		models: state.models,
	};
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(SelfServiceModels)));
