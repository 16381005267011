import { ActionTypes } from "../constants";

const initialState = {
    data: {},
    document: {},
    error: {},
    currentComponent: "aiAssistant"
};
export default function chatReducer(state = initialState, action) {

    switch (action.type) {
        case ActionTypes.GET_PROMPT_RESPONSE: {
            const { aiType, chatId } = action?.payload;
            const tempChatId = chatId || 'newPrompt'
            const newPrompt = {
                queryText: action.payload?.query || action.payload?.question,
                aiType,
                chatId: tempChatId,
                _id: 'newPrompt',
                questionId: 'newPrompt',
            }
            let tempChatHistoryData = {
                data: [],
                page: 1,
                hasMoreData: false 
            }
            let chatHistoryData = {}
            if (tempChatId) {
                chatHistoryData = { ...state?.data?.[`${tempChatId}_chatHistory`] };
            }
            if(chatHistoryData && Object.keys(chatHistoryData).length) {
                const totalData = [...chatHistoryData.data];
                totalData.push(newPrompt);
                tempChatHistoryData = { 
                    ...chatHistoryData,
                    data: totalData
                }
            } else {
                tempChatHistoryData.data.push(newPrompt)
            }
            let updatedData = { ...state.data, [`${tempChatId}_chatHistory`]: tempChatHistoryData }
            return {
                ...state,
                ['newPrompt_loading']: true,
                data: updatedData
            };
        }
        case ActionTypes.GET_PROMPT_RESPONSE_SUCCESS: {
            const chatId = action?.payload?.data?.chatId || action?.payload?.chatId;
            let chatHistoryData = {};
            if (chatId && !state?.data?.[`newPrompt_chatHistory`]) {
                chatHistoryData = { ...state?.data?.[`${chatId}_chatHistory`] };
            } else if (state?.data?.[`newPrompt_chatHistory`]) {
                chatHistoryData = { ...state?.data?.[`newPrompt_chatHistory`] };
            }
            if(chatHistoryData && Object.keys(chatHistoryData).length) {
                const totalData = [...chatHistoryData.data];
                const newPromptQuestionindex = totalData?.findIndex(ch => ch?._id === 'newPrompt');
                if(newPromptQuestionindex > -1) {
                    totalData[newPromptQuestionindex] = { ...totalData[newPromptQuestionindex] }
                    totalData[newPromptQuestionindex] = { 
                        ...totalData[newPromptQuestionindex], 
                        ...action.payload.data,
                        _id: action.payload.data.questionId
                    }
                    chatHistoryData.data = totalData;
                }
            }
            let updatedData = { ...state.data, [`${chatId}_chatHistory`]: chatHistoryData }
            delete updatedData['newPrompt_chatHistory'];
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                newChatId: action?.payload?.data?.chatId !== action?.payload?.chatId ? action?.payload?.data?.chatId : null,
                data: updatedData,
            };
        }
        case ActionTypes.GET_PROMPT_RESPONSE_FAILURE: {
            let updatedError = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: updatedError,
            };
        }
        case ActionTypes.GET_CHAT_HISTORY_DATA:
        case ActionTypes.GET_WORKSPACES_DATA:
        case ActionTypes.DELETE_CHAT_FROM_HISTORY: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true,
            };
        }
        case ActionTypes.GET_CHAT_HISTORY_DATA_SUCCESS:
        case ActionTypes.GET_WORKSPACES_DATA_SUCCESS:
        case ActionTypes.DELETE_CHAT_FROM_HISTORY_SUCCESS: {
            let updatedData = { ...state.data, [action.payload.dataUniqueId]: action.payload.data }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.GET_CHAT_HISTORY_DATA_FAILURE:
        case ActionTypes.GET_WORKSPACES_DATA_FAILURE:
        case ActionTypes.DELETE_CHAT_FROM_HISTORY_FAILURE: {
            let updatedError = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: updatedError,
            }
        }
        case ActionTypes.GET_WORKSPACES_BY_ID_DATA: 
        case ActionTypes.ADD_NEW_CHART_TO_WORKSPACE:
        case ActionTypes.DELETE_QUESTION_FROM_CHAT_HISTORY: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true,
            };
        }
        case ActionTypes.GET_CHAT_HISTORY_BY_ID_DATA: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.dataUniqueId + '_loading']: true,
                }
            };

        }
        case ActionTypes.GET_CHAT_HISTORY_DATA_BY_ID_SUCCESS: {
            let chatHistoryById = { ...state.data?.[action.payload.dataUniqueId] };
            if (chatHistoryById && Object.keys(chatHistoryById).length === 0) {
                chatHistoryById = {
                    data: [],
                    page: action.payload.data.page,
                    hasMoreData: false
                }
            }
            const chatData = [ ...action.payload.data.data, ...chatHistoryById.data ];
            let updatedData = {
                ...state.data,
                [action.payload.dataUniqueId + '_loading']: false,
                [action.payload.dataUniqueId]: {
                    ...chatHistoryById,
                    data: chatData,
                    page: action.payload.data.page,
                    hasMoreData: chatData?.length < action.payload.data.totalCount
                }
            }
            return {
                ...state,                
                data: updatedData,
            }
        }
        case ActionTypes.GET_CHAT_HISTORY_DATA_BY_ID_FAILURE: {
            let updatedError = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.dataUniqueId + '_loading']: false,
                },
                error: updatedError,
            }
        }
        case ActionTypes.DELETE_QUESTION_FROM_CHAT_HISTORY_SUCCESS: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: {
                    ...state.data,
                    [action.payload.dataUniqueId]: {
                        ...state.data[action.payload.dataUniqueId],
                        data: state.data[action.payload.dataUniqueId]?.data?.filter(item => item?.questionId !== action.payload.data),
                    }
                }
            }
        }
        case ActionTypes.GET_WORKSPACES_DATA_BY_ID_SUCCESS:
        case ActionTypes.ADD_NEW_CHART_TO_WORKSPACE_SUCCESS:  {
            let workspaces = [...state.data?.[action.payload?.workspaceUniqueId]];
            let workspaceIndex = workspaces.findIndex(ws => ws._id === action.payload.data?._id);
            if(workspaceIndex > -1) {
                workspaces[workspaceIndex] = { ...workspaces[workspaceIndex] };
                workspaces[workspaceIndex].chart = action.payload.data?.chart?.length || workspaces[workspaceIndex].chart;
            }
            let updatedData = { 
                ...state.data,
                [action.payload?.workspaceUniqueId]:  workspaces,
                [action.payload.dataUniqueId]: action.payload.data
            }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.GET_WORKSPACES_DATA_BY_ID_FAILURE:
        case ActionTypes.ADD_NEW_CHART_TO_WORKSPACE_FAILURE:
        case ActionTypes.DELETE_QUESTION_FROM_CHAT_HISTORY_FAILURE: {
            let updatedError = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: updatedError,
            }
        }
        case ActionTypes.CLEAR_REDUX_CHAT_DATA_OF_COMPONENT: {
            if(action.payload === "clearAll") {
                return initialState;
            } else {
                let dataToClear = action.payload;
                if (Array.isArray(dataToClear.key)) {
                    let newState = JSON.parse(JSON.stringify({ ...state }))
                        dataToClear.key.forEach(k => {
                        delete newState[k]
                    })
                    return newState;
                } else {
                    let _data = { ...state[dataToClear.key] };
                    if (Array.isArray(dataToClear?.subKey)) {
                        dataToClear?.subKey?.forEach(key => {
                            delete _data[key];
                        });
                    } else {
                        delete _data[dataToClear?.subKey]
                    }
                    return {
                        ...state,
                        [dataToClear.key]: _data
                    }
                }
            }
        }
        case ActionTypes.SET_CHAT_CURRENT_COMPONENT: {
            return {
                ...state,
                currentComponent: action.payload || "aiAssistant",
            }
        }
        case ActionTypes.CREATE_WORKSPACE:
        case ActionTypes.UPDATE_WORKSPACE:
        case ActionTypes.UPDATE_WORKSPACE_TITLE: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true,
            }
        }
        case ActionTypes.CREATE_WORKSPACE_SUCCESS: {
            let workspaces = [...state.data?.[action.payload.dataUniqueId]];
            workspaces.push(action.payload.data);
            let updatedData = {
              ...state.data,
              [action.payload.dataUniqueId]: workspaces
            };
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.UPDATE_WORKSPACE_SUCCESS: {
            let workspacesById = JSON.parse(JSON.stringify(state.data?.[action.payload?.workspaceByIdUniqueId]));
            let updatedChartArray = [];
            if(action?.payload?.data && Object.keys(action?.payload?.data).length === 1) {
                updatedChartArray = workspacesById?.chart?.map(chart => { 
                    if(chart?._id === action?.payload?.data?.[0]?._id) {
                        return {
                            ...chart, 
                            chartData: {
                                ...chart.chartData,
                                title: action?.payload?.data?.[0].chartData?.title
                            }
                        }
                    } else return {...chart};
                });
            } else {
                updatedChartArray = action?.payload?.data;
            }
            let updatedData = {
              ...state.data,
              [action.payload.workspaceByIdUniqueId]: {
                ...state.data?.[action.payload.workspaceByIdUniqueId],
                chart: updatedChartArray
              },
            };
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.UPDATE_WORKSPACE_TITLE_SUCCESS: {
            let workspaces = [...state.data?.[action.payload?.workspaceUniqueId]];
            let workspacesById = { ...state.data?.[action.payload?.workspaceByIdUniqueId]};
            let workspaceIndex = workspaces.findIndex(ws => ws._id === action.payload.data?.workspaceId);
            if(workspaceIndex > -1) {
                workspaces[workspaceIndex] = { ...workspaces[workspaceIndex] };
                workspaces[workspaceIndex].title = action.payload.data?.title;
                workspaces[workspaceIndex].name = action.payload.data?.title;
            }
            if(workspacesById && Object.keys(workspacesById).length) {
                workspacesById = {
                    ...workspacesById,
                    title: action.payload.data?.title,
                    name: action.payload.data?.title
                }
            }
            let updatedData = {
                ...state.data,
                [action.payload?.workspaceUniqueId]: workspaces,
                [action.payload.workspaceByIdUniqueId]: workspacesById
            };
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.CREATE_WORKSPACE_FAILURE:
        case ActionTypes.UPDATE_WORKSPACE_FAILURE:
        case ActionTypes.UPDATE_WORKSPACE_TITLE_FAILURE: {
            let updatedError = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: updatedError,
            }
        }
        case ActionTypes.DELETE_CHART_FROM_WORKSPACE_SUCCESS: {
            let workspaces = [...state.data?.[action.payload?.dataUniqueId]];
            let workspaceIndex = workspaces.findIndex(ws => ws._id === action.payload.data);
            if(workspaceIndex > -1) {
                workspaces[workspaceIndex] = { ...workspaces[workspaceIndex] };
                workspaces[workspaceIndex].chart = workspaces[workspaceIndex].chart - 1;
            }
            let updatedData = {
                ...state.data,
                [action.payload?.workspaceUniqueId]: workspaces,
            };
            return {
                ...state,
                data: updatedData
            }
        }   
        case ActionTypes.UPDATE_CHAT_TITLE_SUCCESS: {
            let ChatHistoryList = [...state.data?.[action.payload?.dataUniqueId]];
            let updateChatIndex = ChatHistoryList.findIndex(chat => chat._id === action.payload.chatId);
            if(updateChatIndex > -1) {
                ChatHistoryList[updateChatIndex] = { ...ChatHistoryList[updateChatIndex] };
                ChatHistoryList[updateChatIndex].name = action.payload.data;
            }
            let updatedData = {
                ...state.data,
                [action.payload?.dataUniqueId]: ChatHistoryList,
            };
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.UPDATE_CHAT_TITLE_FAILURE: {
            let updatedError = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: updatedError,
            }
        }
        case ActionTypes.PUBLISH_OR_DRAFT_WORKSPACE: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true,
            };
        }
        case ActionTypes.PUBLISH_OR_DRAFT_WORKSPACE_SUCCESS: {
            let workspacesById = { ...state.data?.[action.payload?.workspaceByIdUniqueId]};
            const workspaceId = action.payload?.workspaceId;
            if(workspacesById && Object.keys(workspacesById).length && workspacesById?._id === workspaceId) {
                workspacesById = {
                    ...workspacesById,
                   status: action.payload?.data
                }
            }
            let updatedData = {
                ...state.data,
                [action.payload.workspaceByIdUniqueId]: workspacesById
            };
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.PUBLISH_OR_DRAFT_WORKSPACE_FAILURE: {
            let updatedError = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: updatedError,
            }
        }
        case ActionTypes.DELETE_WORKSPACE: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true,
            };
        }
        case ActionTypes.DELETE_WORKSPACE_SUCCESS: {
            let updatedData = { ...state.data, [action.payload.dataUniqueId]: action.payload.data }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.DELETE_WORKSPACE_FAILURE: {
            let updatedError = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: updatedError,
            }
        }
        case ActionTypes.GET_ALL_SAVEFORLATER_CHATS: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true,
            };
        }
        case ActionTypes.GET_ALL_SAVEFORLATER_CHATS_SUCCESS: {
            let updatedData = { ...state.data, [action.payload.dataUniqueId]: action.payload.data }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.GET_ALL_SAVEFORLATER_CHATS_FAILURE: {
            let updatedError = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: updatedError,
            }
        }
        case ActionTypes.GET_SAVEFORLATER_BY_CHAT: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true,
            };
        }
        case ActionTypes.GET_SAVEFORLATER_BY_CHAT_SUCCESS: {
            let updatedData = { ...state.data, [action.payload.dataUniqueId]: action.payload.data }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.GET_SAVEFORLATER_BY_CHAT_FAILURE: {
            let updatedError = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: updatedError,
            }
        }
        case ActionTypes.GET_SAMPLE_QUESTIONS: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true,
            };
        }
        case ActionTypes.GET_SAMPLE_QUESTIONS_SUCCESS: {
            let updatedData = { ...state.data, [action.payload.dataUniqueId]: action.payload.data }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.GET_SAMPLE_QUESTIONS_FAILURE: {
            let updatedError = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: updatedError,
            }
        }
        case ActionTypes.GET_QUESTION_COMMENTS: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true,
            };
        }
        case ActionTypes.GET_QUESTION_COMMENTS_SUCCESS: {
            let updatedData = { ...state.data, [action.payload.dataUniqueId]: action.payload.data }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.GET_QUESTION_COMMENTS_FAILURE: {
            let updatedError = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: updatedError,
            }
        }
        case ActionTypes.CREATE_QUESTION_COMMENTS: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true,
            };
        }
        case ActionTypes.CREATE_QUESTION_COMMENTS_SUCCESS: {
            const commentsData = [...state.data?.[action.payload.commentsUniqueId]];
            commentsData.push(action.payload.data)
            let updatedData = { ...state.data, [action.payload.commentsUniqueId]: commentsData }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.CREATE_QUESTION_COMMENTS_FAILURE: {
            let updatedError = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: updatedError,
            }
        }
        case ActionTypes.QUESTION_LIKE:
        case ActionTypes.QUESTION_DISLIKE:
        case ActionTypes.QUESTION_RATING:
        case ActionTypes.QUESTION_SAVED_FOR_LATER: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true,
            };
        }
        case ActionTypes.QUESTION_LIKE_SUCCESS:
        case ActionTypes.QUESTION_DISLIKE_SUCCESS:
        case ActionTypes.QUESTION_SAVED_FOR_LATER_SUCCESS:
        case ActionTypes.QUESTION_RATING_SUCCESS: {
            // const chatQuestionHistory = [...state.data?.chatHistoryById];
            // const responseData = action.payload.data;
            // const selectedQuestionIndex = chatQuestionHistory.findIndex(ch => ch?._id === responseData?.questionId);
            // const points = {
            //     like: responseData?.like,
            //     disLike: responseData?.disLike,
            //     rating: responseData?.rating,
            //     savedForLater: responseData?.savedForLater
            // }
            // if(selectedQuestionIndex > -1) {
            //     // chatQuestionHistory[selectedQuestionIndex] = { ...chatQuestionHistory[selectedQuestionIndex] };
            //     chatQuestionHistory[selectedQuestionIndex].points = points;
            // }
            // let updatedData = { ...state.data, chatHistoryById: chatQuestionHistory }
            // return {
            //     ...state,
            //     [action.payload.dataUniqueId + '_loading']: false,
            //     data: updatedData,
            // }
        }
        case ActionTypes.QUESTION_LIKE_FAILURE:
        case ActionTypes.QUESTION_DISLIKE_FAILURE:
        case ActionTypes.QUESTION_RATING_FAILURE:
        case ActionTypes.QUESTION_SAVED_FOR_LATER_FAILURE: {
            let updatedError = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: updatedError,
            }
        }
        case ActionTypes.QUESTION_SEND_EMAIL: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true,
            };
        }
        case ActionTypes.QUESTION_SEND_EMAIL_SUCCESS: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                [action.payload.dataUniqueId + '_success']: true,
            };
        }
        case ActionTypes.QUESTION_SEND_EMAIL_FAILURE: { 
            let updatedError = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: updatedError,
            };
        }
        case ActionTypes.UPDATE_QUESTION_EMAIL_SUCCESS_STATE: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_success']: false,
            };
        }
        case ActionTypes.QUESTION_SELECTED_CHART_DATA: {
            let chartData = [...(state.data?.[action.payload.dataUniqueId + '_chartData'] || [])];
            if (chartData && chartData?.length > 0) {
                const newValue = {...action.payload?.[action.payload.dataUniqueId]};
                const valueExists = chartData.findIndex(cd => cd.title === newValue?.title && cd.value === newValue?.value);
                if (valueExists !== -1) {
                    chartData[valueExists] = newValue;
                } else {
                    chartData.push(action.payload?.[action.payload.dataUniqueId]);
                }
            } else {
                chartData = [action.payload?.[action.payload.dataUniqueId]];
            }
            return { 
                ...state,
                data:  {
                    ...state.data,
                    [action.payload.dataUniqueId + '_chartData']: chartData
                }   
            }
        }
        case ActionTypes.GET_AUTOCOMPLETE_SUGGESTIONS: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true,
            };
        }
        case ActionTypes.GET_AUTOCOMPLETE_SUGGESTIONS_SUCCESS: {
            let updatedData = { ...state.data, [action.payload.dataUniqueId]: action.payload.data }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.GET_AUTOCOMPLETE_SUGGESTIONS_FAILURE: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
            };
        }
        default: {
            return state;
        }
    }
}