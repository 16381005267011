import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PieBarCharts from '../../SpendAnalyticsDashboard/barChart';

export const colors = ["#5dd39a", "#ff7b33", "#2d63b6", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"]

const Charts = (props) => {

    const useStyles = makeStyles((theme) => ({
    }));

    const classes = useStyles();

    let options1 = {
        chart: {
            type: 'column'
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        },
        title: {
            text: 'Invoice count by month'
        },
        plotOptions: {
            series: {
                pointWidth: 20
            }
        },

        series: [{
            name: 'Months',
            data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
            color: '#2d63b6'

        }],
        lang: {
            noData: "No Data Available"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#000'
            }
        },
    }

    let options2 = {
        chart: {
            type: 'column'
        },
        credits: {
            enabled: false
        },
        title: {
            text: 'invoice amounts by month'
        },
        xAxis: {
            categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        },

        plotOptions: {
            series: {
                pointWidth: 20
            }
        },

        series: [{
            name: 'Months',
            data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
            color: '#2d63b6'
        }],
        lang: {
            noData: "No Data Available"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#000'
            }
        },
    }

    const pietaxPercent = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        credits: {
            enabled: false
        },
        title: {
            text: 'Invoices with tax & with no tax'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                colors: colors,
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: [{
            name: 'Brands',
            colorByPoint: true,
            data: [{
                name: '00099 INV50007 BAX337531',
                y: 61.41,
                sliced: true,
                selected: true
            }, {
                name: '1426B 2201BL001D BAX340113',
                y: 11.84
            }, {
                name: '2532 68469449',
                y: 10.85
            }, {
                name: '1460 I73239 442697',
                y: 4.67
            }, {
                name: '1426B 2201KJ011A BAX339516',
                y: 4.18
            }, {
                name: '652 EXPENSE 010522 ADRIAN SEVIN',
                y: 1.64
            }, {
                name: '50887 141954 SSX338233',
                y: 1.6
            }, {
                name: '34901 70170116 CCX340115',
                y: 1.2
            }, {
                name: 'Other',
                y: 2.61
            }]
        }],
        lang: {
            noData: "No Data Available"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#000'
            }
        },
    }
    const pietaxPercent1 = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        credits: {
            enabled: false
        },
        title: {
            text: 'Invoices with required fields and missing fields'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                colors: colors,
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: [{
            name: 'Brands',
            colorByPoint: true,
            data: [{
                name: '00099 INV50007 BAX337531',
                y: 61.41,
                sliced: true,
                selected: true
            }, {
                name: '1426B 2201BL001D BAX340113',
                y: 11.84
            }, {
                name: '2532 68469449',
                y: 10.85
            }, {
                name: '1460 I73239 442697',
                y: 4.67
            }, {
                name: '1426B 2201KJ011A BAX339516',
                y: 4.18
            }, {
                name: '652 EXPENSE 010522 ADRIAN SEVIN',
                y: 1.64
            }, {
                name: '50887 141954 SSX338233',
                y: 1.6
            }, {
                name: 'QQ',
                y: 1.2
            }, {
                name: 'Other',
                y: 2.61
            }]
        }],
        lang: {
            noData: "No Data Available"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#000'
            }
        },
    }

    const pietaxPercent5 = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        credits: {
            enabled: false
        },
        title: {
            text: 'Items with quantity'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                colors: colors,
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: [{
            name: 'Brands',
            colorByPoint: true,
            data: [{
                name: 'BLACK_L',
                y: 61.41,
                sliced: true,
                selected: true
            }, {
                name: 'BLACK_S',
                y: 11.84
            }, {
                name: 'COLOR_L',
                y: 10.85
            }, {
                name: 'COLOR_S',
                y: 4.67
            }, {
                name: 'Photocopier Rental for Jan 2022',
                y: 4.18
            }, {
                name: 'Photocopier Meter Reading (07 Nov ‐ 07 Dec 2021)',
                y: 1.64
            }, {
                name: 'JAS Forwarding Worldwide',
                y: 1.6
            }, {
                name: 'Other',
                y: 2.61
            }]
        }],
        lang: {
            noData: "No Data Available"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#000'
            }
        },
    }


    const pietaxPercent6 = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        credits: {
            enabled: false
        },
        title: {
            text: 'Items with amount'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                colors: colors,
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: [{
            name: 'Brands',
            colorByPoint: true,
            data: [{
                name: 'BLACK_L',
                y: 61.41,
                sliced: true,
                selected: true
            }, {
                name: 'BLACK_S',
                y: 11.84
            }, {
                name: 'COLOR_L',
                y: 10.85
            }, {
                name: 'COLOR_S',
                y: 4.67
            }, {
                name: 'Photocopier Rental for Jan 2022',
                y: 4.18
            }, {
                name: 'Photocopier Meter Reading (07 Nov ‐ 07 Dec 2021)',
                y: 1.64
            }, {
                name: 'JAS Forwarding Worldwide',
                y: 1.6
            }, {
                name: 'Other',
                y: 2.61
            }]
        }],
        lang: {
            noData: "No Data Available"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#000'
            }
        },
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <PieBarCharts options={pietaxPercent} />
                </Grid>
                <Grid item xs={4}>
                    <PieBarCharts options={pietaxPercent1} />
                </Grid>
                <Grid item xs={4}>
                    <PieBarCharts options={options2} />
                </Grid>
            </Grid>
            <Grid container spacing={2} >
                <Grid item xs={4}>
                    <PieBarCharts options={options1} />
                </Grid>
                <Grid item xs={4}>
                    <PieBarCharts options={pietaxPercent5} />
                </Grid>
                <Grid item xs={4}>
                    <PieBarCharts options={pietaxPercent6} />
                </Grid>
            </Grid>

        </React.Fragment >
    )
}

export default Charts;
