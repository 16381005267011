import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	paperLabel: {
		padding: '2px',
		color: 'grey',
		boxShadow: "none !important",
		// paddingLeft: "4%",
		// paddingTop: "7%",
		marginBottom: '18px',
		textAlign: "center"
	},
	paperValue: {
		color: 'black',
		fontSize: 'large',
		fontWeight: 'bold',
		boxShadow: "none !important",
		paddingTop: 'inherit',
		// marginBottom: '10px',
		// textAlign: "center"
	},
	Icon: {
		// paddingRight: "17%",
		// paddingTop: "7%"
	},
	paperChart: {
		display: "flex",
		justifyContent: "space-between",
		paddingRight: 6,
		// paddingBottom:13
	},
	paperLineChart: {
		width: 100,
	},
	paperdoughnutChart: {
		width: 86,
		marginTop: '-17px'
	},
	labelValue: {
		marginTop: "30px"
	},
	grid: {
		marginTop: 10,
		// marginBottom: 20,
		// borderRight: '1px solid #ebedf2'
	},
	gridContainer: {
	},
	button: {
		padding: "3px !important"
	},
	spanTag: {
		fontWeight: 700
	},
	circularBar: {
		height: 100,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	gridContainer: {
		justifyContent: 'center',
		textAlign: 'left',
		border: '1px solid #EEEEEE'
	},
	gridItem: {
		border: '1px solid #EEEEEE',
		padding: '5px 5px 0px 5px',
		color: '#000000',
		display: 'flex',
		alignItems: 'center'
	}
}));
export default useStyles