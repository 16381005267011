import React, { Component } from 'react';
import {
	withStyles,
	Table,
	TableBody,
	TableCell,
	Input,
	List,
	ListItem,
	ListItemText,
	Box,
	Button,
	TablePagination,
	TableRow,
	Checkbox,
	TextField,
	FormControlLabel,
	FormGroup,
	Typography,
	GridList,
	Select,
	GridListTile,
	InputLabel,
	MenuItem,
	FormControl,
	Tooltip,
} from '@material-ui/core';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import TableHead from './TableHead';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { CSVLink } from "react-csv";
import { Parser } from "json2csv";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import Popper from '@material-ui/core/Popper';
import FilterIcon from '@material-ui/icons/FilterList';
import GetAppIcon from '@material-ui/icons/GetApp';
import _ from 'modules/lodash';
import {
	getFilteredArray,
	handleSelectedRow,
	isRowselected,
	requestSort,
	handleTableSelectAllClick,
	getTableRowStyles
} from 'modules/common';
const styles = theme => ({
	root: {},
	paper: {
		border: '1px solid',
		padding: theme.spacing(1),
		backgroundColor: theme.palette.background.paper,
		maxHeight: "350px",
		overflowY: 'scroll',
		overflowX: 'hidden'
	},
	actionIcon: {
		border: "1px solid #E4E4E4",
		padding: "5px",
		borderRadius: "5px",
		marginRight: "10px",
		display: "inline-block",
		textAlign: "center"
	},
	reset: {
		alignSelf: 'left',
	},
	resetLink: {
		marginLeft: '16px',
		fontSize: '12px',
		cursor: 'pointer',
	}
});


class CustomTable extends Component {
	state = {
		order: 'asc',
		orderBy: null,
		selected: [],
		data: [["Loading Data..."]],
		page: 0,
		count: 10,
		rowsPerPage: 10,
		searchText: "",
		isSearchIconHide: false,
		anchorEl: null,
		anchorEl2: null,
		anchorEl3: null,
	};

	componentWillUnmount() {
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps, prevState) {
	}

	objectByStringKey = (o, s) => {
		s = s.replace(/\[(\w+)\]/g, '.$1');
		s = s.replace(/^\./, '');
		var a = s.split('.');
		for (var i = 0, n = a.length; i < n; ++i) {
			var k = a[i];
			if (k in o) {
				o = o[k];
			} else {
				return;
			}
		}
		return o;
	}

	handleSearchClick = () => {
		this.setState({ isSearchIconHide: !this.state.isSearchIconHide },
			() => { if (!this.state.isSearchIconHide) this.props.searchHandler() });
	}

	handleColumnPopperClick = (event) => {
		this.setState({ anchorEl: this.state.anchorEl ? null : event.currentTarget });
	}
	handleFilterPopperClick = (event) => {
		this.setState({ anchorEl2: this.state.anchorEl2 ? null : event.currentTarget });
	}
	handleExportPopperClick = (event) => {
		this.setState({ anchorEl3: this.state.anchorEl3 ? null : event.currentTarget });
	}

	generateTableRows = (data, orderBy, order) => {
		let toReturn = _.orderBy(data, [
			(o) => {
				switch (orderBy) {
					default: {
						return o[orderBy];
					}
				}
			}
		], [order]);
		const { page, rowsPerPage } = this.props;
		if (rowsPerPage > 0) {
			toReturn = toReturn.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
		}
		return toReturn;
	}

	renderSelect(column, index) {
		const { filterList, filterData, handleDropdownChange } = this.props;
		return (
			<GridListTile key={'rs' + index} cols={2}>
				<FormControl key={index} fullWidth>
					<InputLabel htmlFor={column.name}>{column.label}</InputLabel>
					<Select
						fullWidth
						value={filterList[index] ? filterList[index] : "All"}
						name={column.name}
						onChange={event => handleDropdownChange(event, index, column)}
						input={<Input name={column.name} id={column.name} />}>
						<MenuItem value={"All"} key={0}>
							All
						</MenuItem>
						{filterData.length > 0 && filterData[index].map((filterValue, filterIndex) => (
							<MenuItem value={filterValue} key={filterIndex + 1}>
								{filterValue}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</GridListTile>
		);
	}

	render() {
		const open = Boolean(this.state.anchorEl);
		const id = open ? 'simple-popper' : undefined;

		const open2 = Boolean(this.state.anchorEl2);
		const id2 = open2 ? 'simple-popper-1' : undefined;

		const open3 = Boolean(this.state.anchorEl3);
		const id3 = open3 ? 'simple-popper-2' : undefined;
		const { order, orderBy, selected, rowsPerPage, count, page, filterTableData, classes, exportArray, headerstyle, subHeaderstyle, rowStyle, isTableSubHeader, name, hasCheckbox } = this.props;
		let columnsArray = filterTableData && filterTableData.columns && filterTableData.columns.map((item) => item.name);
		return (<>
			<div style={{ paddingTop: "10px", float: "right" }}>
				{this.props.isSearch && <>
					{this.state.isSearchIconHide &&
						<div style={{ backgroundColor: "#FFF", display: "inline-block", position: "relative", top: "-18px" }}>
							<TextField id="standard-basic" label="Search" value={this.props.searchText}
								onChange={this.props.searchHandler} />
						</div>}
					{this.state.isSearchIconHide
						? <CloseIcon style={{ color: "rgba(0, 0, 0, 0.87)" }} onClick={this.handleSearchClick} />
						: <SearchIcon style={{ color: "rgba(0, 0, 0, 0.87)" }} onClick={this.handleSearchClick} />}
				</>}
				{
					this.props.isExport && <div style={{ color: "rgba(0, 0, 0, 0.87)", display: "inline-block" }}>
						<CloudDownloadIcon onClick={this.handleExportPopperClick} />
						<Popper id={id3} open={open3} anchorEl={this.state.anchorEl3}>
							<div className={classes.paper}>
								<List component="nav">
									{exportArray.map((exp, index) => {
										return exp.isVisible && <React.Fragment key={'rf' + index}>
											{exp.key === "csv" || exp.key === "txt" || exp.key === "xls"
												? <ListItem button key={index}>
													<CSVLink
														style={{ textDecoration: "none", color: "#000" }}
														data={columnsArray && columnsArray.length > 0 && filterTableData.datasets
															&& filterTableData.datasets.length > 0
															&& new Parser({ columnsArray }).parse(filterTableData.datasets || [])
															|| []}
														filename={name + "." + exp.key}>
														<ListItemText primary={exp.name} />
													</CSVLink>
												</ListItem>
												: <ListItem key={index} button
													onClick={() => this.props.handleExoprtItemClick(exp, index)}>
													<ListItemText primary={exp.name} />
												</ListItem>
											}
										</React.Fragment>
									})}
								</List>
							</div>
						</Popper>
					</div>
				}

				{this.props.isViewCoulmns && <div style={{ color: "rgba(0, 0, 0, 0.87)", display: "inline-block" }}>
					<ViewColumnIcon onClick={this.handleColumnPopperClick} />
					<Popper id={id} open={open} anchorEl={this.state.anchorEl}>
						<div className={classes.paper}>
							<FormGroup>
								{filterTableData.columns && filterTableData.columns.map((item, i) => {
									return <FormControlLabel key={i}
										control={
											<Checkbox
												checked={item.checked}
												onChange={this.props.handleInputChange}
												name={item.name}
												color="primary"
											/>
										}
										label={item.label}
										value={item.name}
									/>
								})
								}
							</FormGroup>
						</div>
					</Popper>
				</div>}
				{this.props.isFilter && <div style={{ color: "rgba(0, 0, 0, 0.87)", display: "inline-block" }}>
					<FilterIcon onClick={this.handleFilterPopperClick} />
					<Popper id={id2} open={open2} anchorEl={this.state.anchorEl2}  style={{right:0,maxWidth:500}}>
						<div className={classes.paper}>
							<div className={classes.reset}>
								<Typography variant="body2" style={{ display: "inline-block" }}>Filters</Typography>
								<Button style={{ float: 'right' }}
									className={classes.resetLink}
									color="primary"
									onClick={this.props.resetFilters}>
									Reset
									</Button>
							</div>
							<GridList cellHeight="auto" cols={2} spacing={34}
								style={{ marginTop: "13px", width: "100%", color: "rgba(0, 0, 0, 0.87)" }} >
								{filterTableData.columns && filterTableData.columns.map((column, index) => {
									return this.renderSelect(column, index);
								})}
							</GridList>
						</div>
					</Popper>
				</div>}
			</div>
			<div className="w-full flex flex-col" style={{ marginTop: "10px", marginBottom: "20px" }}>
				<Table className="min-w-xl" aria-labelledby="tableTitle">
					{this.props.isTableHead && <TableHead
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						isTableSubHeader={isTableSubHeader}
						headerstyle={headerstyle}
						subHeaderstyle={subHeaderstyle}
						onSelectAllClick={this.props.handleSelectAllClick}
						onRequestSort={this.props.handleRequestSort}
						rowCount={filterTableData.datasets ? filterTableData.datasets.length : 0}
						data={filterTableData}
						chartData={this.props.chartData}
						hasCheckbox={hasCheckbox}
					/>}

					<TableBody>
						{this.generateTableRows(filterTableData.datasets, orderBy, order)
							.map((n, i) => {
								const isSelected = isRowselected(n._id, selected);
								return (
									<TableRow
										className="h-64 cursor-pointer"
										style={getTableRowStyles(rowStyle, ['backgroundColor'], i)}
										hover
										role="checkbox"
										aria-checked={isSelected}
										tabIndex={-1}
										key={n._id}
										selected={isSelected}
										onClick={event => this.props.onRowClick(n, i)}
									>
										{this.props.hasCheckbox
											&& <TableCell className="w-48 pl-4 sm:pl-12" padding="checkbox">
												<Checkbox
													style={getTableRowStyles(rowStyle, ['color'], i)}
													checked={isSelected}
													onClick={event => event.stopPropagation()}
													onChange={event => this.props.handleCheck(event, n._id)}
												/>
											</TableCell>}
										{filterTableData && filterTableData.columns && filterTableData.columns.length > 0 &&
											filterTableData.columns.map((c, cInd) => {
												if (c.checked) {
													return (<TableCell key={'cInd' + cInd}
														component="td" scope="row"
														style={getTableRowStyles(rowStyle, ['backgroundColor', 'color', 'fontSize','lineHeight'], i)}
														onClick={event => this.props.onCellClick && this.props.onCellClick(n, c)}>
														{c.name === "workflow" && n.workflow
															? <Button variant="contained">{n.workflow.actions}</Button>
															: this.objectByStringKey(n, c.name)}
													</TableCell>)
												}
											})
										}
										<TableCell
                                         align ="center"
                                        style={{minWidth:220}}
                                        // style={getTableRowStyles(rowStyle, ['backgroundColor', 'color', 'fontSize','lineHeight'], i)}
                                        >
											{this.props.actions.edit && <Box className={classes.actionIcon} component="label"
												onClick={(e) => this.props.tableCrudOperations(n, "edit", e)}>
												<Tooltip title="Edit">
													<EditIcon style={{
														...getTableRowStyles(rowStyle, ['color'], i),
														marginTop: "0px", cursor: "pointer"
													}} />
												</Tooltip>
											</Box>}
											{this.props.actions.view && <Box className={classes.actionIcon} component="label"
												onClick={(e) => this.props.tableCrudOperations(n, "view", e)}>
												<Tooltip title="View">
													<VisibilityIcon style={{
														...getTableRowStyles(rowStyle, ['color'], i),
														marginTop: "0px", cursor: "pointer"
													}} />
												</Tooltip>
											</Box>}
											{this.props.actions.delete && <Box className={classes.actionIcon} component="label"
												onClick={(e) => this.props.tableCrudOperations(n, "delete", e)}>
												<Tooltip title="Delete">
													<DeleteIcon style={{
														...getTableRowStyles(rowStyle, ['color'], i),
														marginTop: "0px", cursor: "pointer"
													}} />
												</Tooltip>
											</Box>}
											{this.props.actions.copy && <Box className={classes.actionIcon} component="label"
												onClick={(e) => this.props.tableCrudOperations(n, "copy", e)}>
												<FileCopyIcon style={{
													...getTableRowStyles(rowStyle, ['color'], i),
													marginTop: "0px", cursor: "pointer"
												}} />
											</Box>}
											{this.props.actions.download && <Box className={classes.actionIcon} component="label"
												onClick={(e) => this.props.tableCrudOperations(n, "download", e)}>
												<GetAppIcon style={{
													...getTableRowStyles(rowStyle, ['color'], i),
													marginTop: "0px", cursor: "pointer"
												}} />
											</Box>}
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
				{this.props.isTablePagination &&
					<TablePagination
						component="div"
						count={count ? count : 0}
						style={{ "color": "rgba(0, 0, 0, 0.87)" }}
						rowsPerPage={rowsPerPage}
						page={page}
						backIconButtonProps={{
							'aria-label': 'Previous Page'
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page',
							// 'marginTop': '-18px'
						}}
						onChangePage={this.props.handleChangePage}
						onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
					/>
				}

			</div>
		</>);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}

const mapStateToProps = state => {
	return {

	};
};

export default connect(mapStateToProps, null)(withStyles(styles, { withTheme: true })(CustomTable));
