import { Helpers } from 'dsilo-ui-components'
import React, { useEffect, useMemo } from 'react'
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { URLs } from '../../modules/appConfig.module'
import { getData } from '../../store/actions/action'
import Chart from './Charts'
import LabelColorBox from './LabelColorBox'
import VendorTreeMap from './VendorTreeMap/index'
import VendorTabel from './VendorTreeMap/VendorTabel'

export const colors = ['rgb(93, 211, 154)', 'rgb(255, 123, 51)', 'rgb(45, 99, 182)', 'rgb(237, 225, 91)', '#86bd45', '#abdff9', '#b43dc6'];

const StaticPages = (props) => {
    console.log('StaticPages-props', props)
    let dataModelId = props.page.chart[0].chartData?.invoiceDatamodel;

    //Row1
    let url1 = URLs.documentsreceived + dataModelId
    let url2 = URLs.invoicesnumber + dataModelId
    let url3 = URLs.processedinvoices + dataModelId
    let url4 = URLs.extractionaccuracy + dataModelId
    let url5 = URLs.unprocessedinvoices + dataModelId
    let url6 = URLs.humanreview + dataModelId
    let url13 = URLs.inreviewinvoices + dataModelId
    //Row3
    let url7 = URLs.invoicesbyvendorunprocessed + dataModelId
    let url8 = URLs.invoicesbyvendorprocessed + dataModelId
    let url9 = URLs.invoicesbyvendoraccuracy + dataModelId

    //Row2 
    let url10 = URLs.invoicesdatapointsextractionresults + dataModelId
    let url11 = URLs.invoiceextracteddatapoints + dataModelId

    //Row4 
    let url12 = URLs.invoiceinvoicesbyvendortable + dataModelId

    //invoicedatabyvendor
    let invoicedatabyvendorURL = URLs.invoicedatabyvendor + dataModelId





    useEffect(() => {
        console.log('props.page', props.page)
        let dataModelId = props.page.chart[0].chartData?.invoiceDatamodel;
        if (dataModelId) {

            //Row1
            let url1 = URLs.documentsreceived + dataModelId
            let url2 = URLs.invoicesnumber + dataModelId
            let url3 = URLs.processedinvoices + dataModelId
            let url4 = URLs.extractionaccuracy + dataModelId
            let url5 = URLs.unprocessedinvoices + dataModelId
            let url6 = URLs.humanreview + dataModelId
            let url13 = URLs.inreviewinvoices + dataModelId

            //Row3
            let url7 = URLs.invoicesbyvendorunprocessed + dataModelId
            let url8 = URLs.invoicesbyvendorprocessed + dataModelId
            let url9 = URLs.invoicesbyvendoraccuracy + dataModelId

            //Row2 
            let url10 = URLs.invoicesdatapointsextractionresults + dataModelId
            let url11 = URLs.invoiceextracteddatapoints + dataModelId

            //Row4 
            let url12 = URLs.invoiceinvoicesbyvendortable + dataModelId

            //invoicedatabyvendor
            let invoicedatabyvendorURL = URLs.invoicedatabyvendor + dataModelId

            //Row1
            let payload1 = {
                url: url1,
                appId: props.match.params.appid,
                dataUniqueId: 'api_' + url1
            }
            let payload2 = {
                url: url2,
                appId: props.match.params.appid,
                dataUniqueId: 'api_' + url2
            }
            let payload3 = {
                url: url3,
                appId: props.match.params.appid,
                dataUniqueId: 'api_' + url3
            }
            let payload4 = {
                url: url4,
                appId: props.match.params.appid,
                dataUniqueId: 'api_' + url4
            }
            let payload5 = {
                url: url5,
                appId: props.match.params.appid,
                dataUniqueId: 'api_' + url5
            }
            let payload6 = {
                url: url6,
                appId: props.match.params.appid,
                dataUniqueId: 'api_' + url6
            }
            let payload13 = {
                url: url13,
                appId: props.match.params.appid,
                dataUniqueId: 'api_' + url13
            }
            //Row3
            let payload7 = {
                url: url7,
                appId: props.match.params.appid,
                dataUniqueId: 'api_' + url7
            }
            let payload8 = {
                url: url8,
                appId: props.match.params.appid,
                dataUniqueId: 'api_' + url8
            }
            let payload9 = {
                url: url9,
                appId: props.match.params.appid,
                dataUniqueId: 'api_' + url9
            }
            //Row2
            let payload10 = {
                url: url10,
                appId: props.match.params.appid,
                dataUniqueId: 'api_' + url10
            }
            let payload11 = {
                url: url11,
                appId: props.match.params.appid,
                dataUniqueId: 'api_' + url11
            }
            let payload12 = {
                url: url12,
                appId: props.match.params.appid,
                dataUniqueId: 'api_' + url12
            }

            //invoicedatabyvendor
            let payloadInvoiceDataByvendor = {
                url: invoicedatabyvendorURL,
                appId: props.match.params.appid,
                dataUniqueId: 'api_' + 'invoicedatabyvendor'
            }
            Promise.all([
                //Row1
                props.getData(payload1),
                props.getData(payload2),
                props.getData(payload3),
                props.getData(payload4),
                props.getData(payload5),
                props.getData(payload6),
                props.getData(payload13),

                //Row3
                props.getData(payload7),
                props.getData(payload8),
                props.getData(payload9),
                //Row2
                props.getData(payload10),
                props.getData(payload11),
                //Row4
                props.getData(payload12),

                //invoicedatabyvendor
                props.getData(payloadInvoiceDataByvendor),

            ])
        }

    }, [])

    //Row1
    let data1 = props.data['api_' + url1];
    let loading1 = props.dashboardData['api_' + url1 + '_loading']
    let data2 = props.data['api_' + url2];
    let loading2 = props.dashboardData['api_' + url2 + '_loading']
    let data3 = props.data['api_' + url3];
    let loading3 = props.dashboardData['api_' + url3 + '_loading']
    let data4 = props.data['api_' + url4];
    let loading4 = props.dashboardData['api_' + url4 + '_loading']
    let data5 = props.data['api_' + url5];
    let loading5 = props.dashboardData['api_' + url5 + '_loading']
    let data6 = props.data['api_' + url6];
    let loading6 = props.dashboardData['api_' + url6 + '_loading']
    let data13 = props.data['api_' + url13];
    let loading13 = props.dashboardData['api_' + url13 + '_loading']

    //Row3
    let data7 = props.data['api_' + url7] || [];
    let loading7 = props.dashboardData['api_' + url7 + '_loading']
    let data8 = props.data['api_' + url8] || [];
    let loading8 = props.dashboardData['api_' + url8 + '_loading']
    let data9 = props.data['api_' + url9] || [];
    let loading9 = props.dashboardData['api_' + url9 + '_loading']

    //Row2
    let data10 = props.data['api_' + url10];
    let loading10 = props.dashboardData['api_' + url10 + '_loading']
    let data11 = props.data['api_' + url11];
    let loading11 = props.dashboardData['api_' + url11 + '_loading']

    //Row4
    let data12 = props.data['api_' + url12];
    let loading12 = props.dashboardData['api_' + url12 + '_loading']

    //invoicedatabyvendor
    let dataInvoicedatabyvendor = props.data['api_' + 'invoicedatabyvendor'];
    let loadingInvoicedatabyvendor = props.dashboardData['api_' + 'invoicedatabyvendor' + '_loading']

    console.log('dataInvoicedatabyvendor', dataInvoicedatabyvendor)



    //Row1
    let dd = [

        {
            "label": "No of invoices",
            "isLoading": loading2,
            "value": data2?.value || 0,
            "labelValue": (Helpers.convertToNumber(data2?.growth, 2) || 0),
            "grfColor": "#3d6eba",
            "chartType": "line",
            "chartData": data2?.chartData,
        },
        {
            "label": "Invoices Processed",
            "isLoading": loading3,
            "value": data3?.value || 0,
            "labelValue": (Helpers.convertToNumber(data3?.growth, 2) || 0),
            "grfColor": "#f47576",
            "chartType": "line",
            "chartData": data3?.chartData,
        },
        {
            "label": "Invoices Unprocessed",
            "isLoading": loading5,
            "value": data5?.value || 0,
            "labelValue": (Helpers.convertToNumber(data5?.growth, 2) || 0),
            "grfColor": "#3d6eba",
            "chartType": "doughnut",
            "chartData": data5?.chartData,

        },
        {
            "label": "Invoices in Quality Review",
            "isLoading": loading1,
            "value": data13?.value || 0,
            "labelValue": (Helpers.convertToNumber(data13?.growth, 2) || 0),
            "grfColor": "#3d6eba",
            "chartType": "line",
            "chartData": data13?.chartData,
        },
        // {
        //     "label": "Human Reviewed/Modified",
        //     "isLoading": loading6,
        //     "value": data6?.value || 0,
        //     "labelValue": (Helpers.convertToNumber(data6?.growth, 2) || 0),
        //     "grfColor": "#f47576",
        //     "chartType": "doughnut",
        //     "chartData": data6?.chartData,
        // },
        // {
        //     "label": "Extraction Accuracy",
        //     "isLoading": loading4,
        //     "value": data4?.value || 0,
        //     "labelValue": (Helpers.convertToNumber(data4?.growth, 2) || 0),
        //     "grfColor": "#3d6eba",
        //     "chartType": "line",
        //     "chartData": data4?.chartData,

        // }
    ]

    //Row3
    const unProcessedInvoices = {
        credits: {
            enabled: false
        },
        series: [{
            type: "treemap",
            layoutAlgorithm: 'stripes',
            alternateStartingDirection: true,
            levels: [{
                level: 1,
                layoutAlgorithm: 'sliceAndDice',
                dataLabels: {
                    enabled: true,
                    align: 'left',
                    verticalAlign: 'top',
                    style: {
                        fontSize: '15px',
                        fontWeight: 'bold'
                    }
                }
            }],
            data: Array.isArray(data7) ? (data7?.map((ele, index) => ({ ...ele, color: colors[index] || ('#' + (Math.random().toString(16) + '00000').slice(2, 8)) || 'green' })) || []) : []
        }],
        title: {
            text: 'Vendors by Unprocessed invoices'
        },
        lang: {
            noData: "No Data Available"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#000'
            }
        },
        loading: loading7,
        credits: {
            enabled: false
        },
    }

    const processedInvoices = useMemo(() => {
        return ({
            credits: {
                enabled: false
            },
            series: [{
                type: "treemap",
                layoutAlgorithm: 'stripes',
                alternateStartingDirection: true,
                levels: [{
                    level: 1,
                    layoutAlgorithm: 'sliceAndDice',
                    dataLabels: {
                        enabled: true,
                        align: 'left',
                        verticalAlign: 'top',
                        style: {
                            fontSize: '15px',
                            fontWeight: 'bold'
                        }
                    }
                }],
                data: Array.isArray(data8) ? (data8?.map((ele, index) => ({ ...ele, color: colors[index] || ('#' + (Math.random().toString(16) + '00000').slice(2, 8)) || 'green' })) || []) : []
            }],
            title: {
                text: 'Vendors by Processed invoices'
            },
            "loading": loading8,
            credits: {
                enabled: false
            },
            lang: {
                noData: "No Data Available"
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#000'
                }
            },
        })
    }, [data8]);

    const accuracy = {


        credits: {
            enabled: false
        },
        chart: {
            type: 'column'
        },
        title: {
            text: 'Vendors by Accuracy'
        },
        xAxis: {
            categories: data9?.map((el) => el.name.slice(0, 5).concat('...'))
        },

        plotOptions: {
            series: {
                pointWidth: 20
            }
        },
        lang: {
            noData: "No Data Available"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#000'
            }
        },
        series: [{
            data: data9?.map((el) => el.value),
            color: '#60a9e3',
            name: "Accuracy"

        }],
        "loading": loading9
    }

    //Row2
    const invoicesDatapointsExtractionresultsBarChart = useMemo(() => {
        return ({
            credits: {
                enabled: false
            },
            chart: {
                type: 'column'
            },
            credits: {
                enabled: false
            },
            title: {
                text: 'Invoice Important Datapoint Extractions Results'
            },
            xAxis: {
                categories: data10?.labels
            },

            plotOptions: {
                series: {
                    pointWidth: 20
                }
            },
            series: [{
                data: data10?.datasets[0]?.data,
                color: '#60a9e3',
                name: data10?.datasets[0]?.label

            }, {
                data: data10?.datasets[1]?.data,
                color: '#a3acb5',
                name: data10?.datasets[1]?.label

            }],
            lang: {
                noData: "No Data Available"
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#000'
                }
            },
        })
    }, [data10]);

    //dataInvoicedatabyvendor
    const dataInvoicedatabyvendorBarChart = useMemo(() => {
        return ({
            credits: {
                enabled: false
            },
            chart: {
                type: 'column'
            },
            credits: {
                enabled: false
            },
            title: {
                text: 'Invoice Total by Vendor'
            },
            xAxis: {
                categories: dataInvoicedatabyvendor?.map(el => el.vendorName),
                labels: {
                    formatter: function () {
                        return this.value.toString().substring(0, 6);
                    },
                },
            },
            yAxis: {
                title: {
                    text: 'Invoice Total ($)'
                }
            },
            plotOptions: {
                series: {
                    pointWidth: 20,
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return this.point.options.y.toFixed(2);
                        }
                    }
                }
            },
            series: [{
                data: dataInvoicedatabyvendor?.map(el => el.invoice_total),
                color: '#60a9e3',
                name: 'Vendor'

            }],
            lang: {
                noData: "No Data Available"
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#000'
                }
            },
        })
    }, [dataInvoicedatabyvendor]);

    const dataInvoicedataItemsCountbyvendorBarChart = useMemo(() => {
        return ({
            credits: {
                enabled: false
            },
            chart: {
                type: 'column'
            },
            credits: {
                enabled: false
            },
            title: {
                text: 'No:of unique products by vendor'
            },
            xAxis: {
                categories: dataInvoicedatabyvendor?.map(el => el.vendorName),
                labels: {
                    formatter: function () {
                        return this.value.toString().substring(0, 6);
                    },
                },

            },
            yAxis: {
                title: {
                    text: 'No: of unique products'
                }
            },
            plotOptions: {
                series: {
                    pointWidth: 20,
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return this.point.options.y;
                        }
                    }
                }
            },
            series: [
                {
                    data: dataInvoicedatabyvendor?.map(el => el.items_count),
                    color: '#60a9e3',
                    name: 'Vendors'

                }],
            lang: {
                noData: "No Data Available"
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#000'
                }
            },
        })
    }, [dataInvoicedatabyvendor]);







    const extracteddatapointsDoughnutChart = data11?.chartData
    const extracteddatapointsDoughnutChartValue = data11?.value

    //Row4

    console.log('dataInvoicedatabyvendorBarChart', dataInvoicedatabyvendorBarChart)

    return (
        <div style={{ background: "#f7f7f7", padding: "13px" }}>
            <LabelColorBox labelData={dd} />
            <div>
                <Chart barChart={invoicesDatapointsExtractionresultsBarChart} loadingBar={loading10}
                    doughnutChart={extracteddatapointsDoughnutChart} doughnutChartValue={extracteddatapointsDoughnutChartValue}
                    loadingDoughnut={loading11}
                    dataInvoicedatabyvendorBarChart={dataInvoicedatabyvendorBarChart}
                    loadingInvoicedatabyvendor={loadingInvoicedatabyvendor}
                    dataInvoicedataItemsCountbyvendorBarChart={dataInvoicedataItemsCountbyvendorBarChart}
                />
            </div>
            <div style={{ marginBottom: '10px' }}>
                <VendorTreeMap unProcessedInvoices={unProcessedInvoices} processedInvoices={processedInvoices} accuracy={accuracy} />
            </div>
            <div style={{ marginBottom: '10px' }}>
                <VendorTabel data={data12} loading={loading12} />
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        dashboardData: state.dashboardReducer,
        data: state.dashboardReducer.data,
    };
};

const mapDispatchToProps = {
    getData,
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps,)(StaticPages)),);
