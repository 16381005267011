const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

import { request } from './../client';

export class AuthService {
  static forgotPassword(data) {
    const uri = API_BASE_URL + '/user/forgotpwd';

    return request(uri, {
      method: 'POST',
      payload: data,
    });
  }
  
  static resendVerifyEmail(data) {
    const uri = API_BASE_URL + '/user/resend/verifyemail';

    return request(uri, {
      method: 'POST',
      payload: data,
    });
  }

  static updateProfile(data) {
    const uri = API_BASE_URL + '/user/update';

    return request(uri, {
      method: 'PUT',
      payload: data,
    });
  }
  
}
