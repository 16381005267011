import React from "react"

import { OutlinedInput } from "@material-ui/core";

const CustomInputField = (props) => {
    const { classes, element, dataObj, onChange, styles, selectedStep } = props
    // console.log("Data Obj ===>> ", dataObj, element, dataObj[element.value] || dataObj[element.name]);
    return (
        <OutlinedInput
            id={`${element.name}`}
            // ref={inputRef}
            variant="outlined"
            className={classes.inputField}
            style = {styles ? styles : {}}
            required={element.required}
            key={element.value || element.name}
            name={element.value || element.name}
            disabled={element.readOnly}
            type={element.type}
            value={(dataObj[element.value] || dataObj[element.name])}
            title={(dataObj[element.value] || dataObj[element.name])}
            onChange={(e) => {
                onChange((element.value || element.name), e.target.value, element.type);
            }}
        />
    )
}

export default CustomInputField