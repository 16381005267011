import { Button, makeStyles, withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
	button: {
		height: 35,
		backgroundColor: '#4e71be',
		color: '#fff',
	}
});

const StyledTableCell = withStyles((theme) => ({
	root: {
		border: '1px solid #ffffff',
	}
}))(TableCell);

const StyledTableCellHead = withStyles((theme) => ({
	root: {
		border: '1px solid #ffffff',
		backgroundColor: '#4e71be',
		color: '#ffffff',
		textAlign: 'center',
		height: 36,
	}
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		backgroundColor: '#e8eaf4',
		'&:nth-of-type(odd)': {
			backgroundColor: '#cfd4e7',
		},
	},
}))(TableRow);

const dummyJson = [
	{
		"jobNo": 23232,
		"jobType": "Reconciliation",
		"source": "Q2 Earnings Release.pdf",
		"keyField": "Net Loss",
		"noOfOccurences": 5,
		"misMatches": 0,
		"date": "10/21/2021 9:42 AM",
		"status": "Completed"
	},
	{
		"jobNo": 43432,
		"jobType": "Reconciliation",
		"source": "Q2 Earnings Release.pdf",
		"keyField": "Earnings per share",
		"noOfOccurences": 3,
		"misMatches": 0,
		"date": "10/21/2021 9:42 AM",
		"status": "Completed"
	},
	{
		"jobNo": 49021,
		"jobType": "Reconciliation",
		"source": "As-Filed Doma Super 8-K_A.pdf ",
		"keyField": "Net Loss",
		"noOfOccurences": 5,
		"misMatches": 0,
		"date": "10/21/2021 9:42 AM",
		"status": "Completed"
	},
	{
		"jobNo": 53241,
		"jobType": "Reconciliation",
		"source": "As-Filed Doma Super 8-K_A.pdf ",
		"keyField": "Earnings per share",
		"noOfOccurences": 5,
		"misMatches": 0,
		"date": "10/21/2021 9:42 AM",
		"status": "Completed"
	},
	{
		"jobNo": 59021,
		"jobType": "Reconciliation",
		"source": "Multiple Documents",
		"keyField": "Net Loss",
		"noOfOccurences": 5,
		"misMatches": 0,
		"date": "10/21/2021 9:42 AM",
		"status": "Completed"
	}
]

const headers = [
	"Job#",
	"Job Type",
	"Source",
	"Key Field",
	"No: of \noccurrences",
	"Mismatches",
	"Date & time",
	"Status"
]


const ReconciliationList = () => {
	const classes = useStyles();

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
					<TableRow>
						{
							headers.map(key => {
								return (<StyledTableCellHead component="th" scope="row">
									{key}
								</StyledTableCellHead>)
							})
						}
						<StyledTableCellHead component="th" scope="row"></StyledTableCellHead>
					</TableRow>
				</TableHead>
				<TableBody>
					{dummyJson.map((row, index) => (
						<StyledTableRow key={index}>
							{
								Object.keys(dummyJson[0]).map((key, index1) => {
									return (<StyledTableCell key={index1} component="th" scope="row">
										{key === 'date' ? moment(row[key]).format('lll') : row[key]}
									</StyledTableCell>)
								})
							}
							<StyledTableCell component="th" scope="row">
								<Button size="small" className={classes.button} onClick={() => { }}>
									View Details
								</Button>
							</StyledTableCell>
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

const mapDispatchToProps = {
};

const mapStateToProps = state => {
	return {
	};
};


export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReconciliationList)));
