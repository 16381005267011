const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

import { request } from 'modules/client'

let route = `${API_BASE_URL}/pages`

export class pageService {
	static getPage({ pageId, appId }) {
		const uri = API_BASE_URL + "/pages/" + pageId
		return request(uri, {
			method: 'GET',
			headers: {
				appId
			}
		})
	}

	static getPages(appId) {
		return request(route, {
			method: 'GET',
			headers: {
				appId
			}
		})
	}

	static savePage(data) {
		return request(route, {
			method: 'POST',
			payload: data,
			headers: {
				appId: data.appId
			}
		})
	}

	static updatePage(data) {
		const uri = `${route}/${data._id}`
		return request(uri, {
			method: 'PUT',
			payload: data,
			headers: {
				appId: data.appId
			}
		})
	}

	static deletePage({ pageId, appId }) {
		const uri = `${route}/${pageId}`
		return request(uri, {
			method: 'DELETE',
			headers: {
				appId
			}
		})
	}
}
