import { makeStyles } from "@material-ui/core/styles";
import { AdvancedTable, Helpers, TableSupport, FiltersUtils } from 'dsilo-ui-components';
import { useEffect, useState } from "react";
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ConfirmationHandler from '../../components/ConfirmDialog';
import { Loader } from "../../components/loader";
import { deleteMember, getMembersList, getAllMembersList, updateProfile, clearReduxDataOfCurrentComponent } from '../../store/actions';
import { getOrgDetails } from "../../store/actions/org";
import React from 'react';
import { Paper } from "@material-ui/core";
import { toast } from 'react-toastify';

const sharedJSON = {
    "order": "asc",
    "orderBy": null,
    "selected": [],
    "tableData": [],
    "filterTableData": [],
    // "page": 0,
    "count": 10,
    // "rowsPerPage": 10,
    "searchText": "",
    "filterList": [],
    "filterData": []
}

const tableJson = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Document Type',
        sort: true,
    },
    {
        id: 'keyValueFields',
        numeric: false,
        disablePadding: false,
        label: 'Key Data Fields',
        sort: true,
    },

];

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    headerClass: {
        backgroundColor: "#f6f9fc",
        textTransform: 'capitalize',
    },
    confirmationDialoge: {
        "& .MuiDialog-paperWidthSm": {
            height: "250px",
            width: "600px"
        }
    },
    para: {
        fontSize: "28px !important",
        fontWeight: 600,
        color: "#000"
    },
    table: {
        '& td': {
            padding: '4px !important',
            textAlign: 'center',
        },
    }
}))

const UserManagement = (props) => {

    const { chart } = props?.page
    const { chartData } = chart[0]
    const { data } = props;
    const classes = useStyles()

    const [membersList, setMembersList] = useState([])
    const [state, setState] = useState({
        ...sharedJSON,
    });
    const [searchedText, setSearchedText] = useState('');

    const [deleteConfirmState, setDeleteConfirm] = useState({
        isOpen: false,
        deleteMessage: "Are you sure you want to delete ?",
        data: null
    })

    const { _id: userId } = props.user
    const [localStorageObject, setLocalStorageObject] = useState({})
    const [isHighlighted, setHighLighted] = useState({});
    const pageUrl = props.page?.url
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [highLighterIdForSelectedLineItem, setHighLighterIdForSelectedLineItem] = useState('');
    const [conditions, setConditions] = useState([])
	const [filterConditions, setFilterConditions] = useState([]) // filter array to be sent in api call
	const [selectedFilterValues, setFilterValues] = useState([]) // actual filter object
	const [externalFilterData, setExternalFilterData] = useState([]) // actual filter object

    useEffect(() => {
        setState({ ...TableSupport.updateTableData(state) });
    }, [state.searchText, state.filterList]);

    useEffect(() => {
        if (membersList) {
            const rows = tableJson;
            // const rows = listViewTableJson;
            let keysArr = ['_id'];
            // setState({ ...TableSupport.setDatafromApi2(membersList, keysArr, state, rows) });
            setState({
                ...TableSupport.setDatafromApi2(
                    getData(membersList),
                    keysArr,
                    state,
                    getColumns()),
                count: props.formData?.document[props.data?.chartUniqueId + '_all_members']?.total
            });
            isHighlightedTile()
        }
    }, [membersList]);

    useEffect(() => {
        let localStoreJson = localStorage.getItem('userSession');
        let currentUserSes, _currSessPage = 0, _currSessRPP = rowsPerPage
        if (localStoreJson) {
            let currentSession = JSON.parse(window.atob(localStoreJson));
            if (Object.keys(currentSession).length > 0) {
                if (currentSession && currentSession[userId] && currentSession[userId][pageUrl]) {
                    currentUserSes = currentSession[userId][pageUrl];
                    if(currentUserSes?.page) {
                        _currSessPage = Number(currentUserSes?.page)
                        setPage(_currSessPage)
                    }
                    if(currentUserSes?.rowsPerPage) {
                        _currSessRPP = Number(currentUserSes?.rowsPerPage)
                        setRowsPerPage(_currSessRPP)
                    }
                }
            }
        }
        fetchData(_currSessPage, _currSessRPP)
        fetchAllMembersData()
    }, [])

    useEffect(() => {
        if (props.formData?.document?.[props.data?.chartUniqueId + "_delete_member"]) {
            toast.success(props.formData?.document?.[props.data?.chartUniqueId + "_delete_member"].message || 'Deleted successfully')
            props.clearReduxDataOfCurrentComponent({
                key: "document",
                subKey: [props.data?.chartUniqueId + "_delete_member"]
            })
            fetchData()
        }
    }, [props.formData?.document?.[props.data?.chartUniqueId + "_delete_member"]])

    const getAllValsAsString = (data) => {
        if (data && data.length) {
            let str = '';
            data.map((d) => str = str + d.name + ', ');
            str = str.slice(0, str.length - 2);
            return str;
        } else {
            return '';
        }
    }

    useEffect(() => {
        if (props.formData?.document && props.formData.document[props.data?.chartUniqueId + "_all_members"]?.data?.length >= 0) {
            let membersListData = props.formData?.document[props.data?.chartUniqueId + "_all_members"]?.data?.map(k => ({
                firstName: k.firstName ? k.firstName : k.name?.split(" ")[0],
                lastName: k.lastName ? k.lastName : k.name?.split(" ")[1],
                roles: getAllValsAsString(k.roles && k.roles.length ? k.roles : []),
                groups: getAllValsAsString(k.groups && k.groups.length ? k.groups : []),
                supervisor: k.supervisor && k.supervisor.id ? k.supervisor.name : '',
                _id: k._id,
                email: k.email
            }))
            setMembersList(membersListData)
        }
    }, [props.formData?.document?.[props.data?.chartUniqueId + "_all_members"]])

    useEffect(() => {
        if (props.formData?.document && props.formData.document[props.data?.chartUniqueId + "_members"]?.length) {
            let _externalFilterData = []
            const emails = [], firstNames= [], lastNames= []
            props.formData?.document[props.data?.chartUniqueId + "_members"].forEach(k => {
                let firstName = k.firstName ? k.firstName : k.name?.split(" ")?.[0];
                let lastName = k.lastName ? k.lastName : k.name?.split(" ")?.[1];
                emails.push({
                    label: k.email, value: k.email, name: 'email'
                 })
                firstNames.push({
                    label: firstName, value: firstName, name: 'firstName'
                 })
                lastNames.push({
                    label: lastName, value: lastName, name: 'lastName'
                })
            })
            _externalFilterData = [...[emails], ...[firstNames], ...[lastNames]];
            setExternalFilterData(_externalFilterData);
        }
    }, [props.formData?.document?.[props.data?.chartUniqueId + "_members"]])

    useEffect(() => {
        if (localStorage.getItem('userSession')) {
            let userSession = JSON.parse(window.atob(localStorage.getItem('userSession')))
            if (Object.keys(userSession).length === 0) {
                if (Object.keys(localStorageObject).length > 0) {
                    localStorage.setItem('userSession', window.btoa(JSON.stringify({ [userId]: { [pageUrl]: { ...localStorageObject } } })))
                }
            } else {
                for (let [key, value] of Object.entries(userSession)) {
                    if (key === userId) {
                        for (let [key1, value1] of Object.entries(value)) {
                            if (key1 === pageUrl) {
                                userSession[userId][pageUrl] = { ...value1, ...localStorageObject }
                                localStorage.setItem('userSession', window.btoa(JSON.stringify(userSession)))
                            } else {
                                if (Object.keys(localStorageObject).length > 0) {
                                    userSession[userId][pageUrl] = { ...localStorageObject }
                                    localStorage.setItem('userSession', window.btoa(JSON.stringify(userSession)))
                                }
                            }
                        }
                    }
                }
            }
        } else if (Object.keys(localStorageObject).length > 0) {
          localStorage.setItem('userSession', window.btoa(JSON.stringify({ [userId]: { [pageUrl]: { ...localStorageObject } } })))
        }
    }, [localStorageObject])

    useEffect(() => {
		if (state.order && state.orderBy) {
			fetchData()
		}
	}, [state.order, state.orderBy])

    const isHighlightedTile =  () => {
        let userSession = localStorage.getItem('userSession')
        if (userSession) {
            userSession = JSON.parse(window.atob(userSession))
            let currentUserSession = userSession && userSession[userId]
			let currentUserSessionPage = userSession && currentUserSession && currentUserSession[pageUrl]
            if (currentUserSessionPage?.selectedLineItem) {
                setHighLighterIdForSelectedLineItem(currentUserSessionPage?.selectedLineItem)
            }
        }
    }

    const fetchData = (_page = page, _rowsPerPage = rowsPerPage, _searchText = '', _conditions = []) => {
        props.getAllMembersList({
          appId: props.appId,
          dataUniqueId: props.data?.chartUniqueId + '_all_members',
          payload: {
            page: _page,
            rowsPerPage: _rowsPerPage,
            ...(_searchText && { search: _searchText }),
            ...(state.order &&
              state.orderBy && {
                sort: state.order,
                sortBy: state.orderBy,
            }),
            ...(_conditions && _conditions.length && {
                conditions: _conditions
            })
          },
        });
    }

    const fetchAllMembersData = () => {
        props.getMembersList({
            appId: props.appId,
            orgId: props?.user?.orgId,
            dataUniqueId: props.data?.chartUniqueId + '_members',
        });
    }

    const getData = (dataSource, fetchType) => {
        const clonedDataSource = JSON.parse(JSON.stringify(dataSource))
        return clonedDataSource.map((item) => {
            const keys = Object.keys(item)
            keys.forEach((key) => {
                if (key && typeof item[key] !== "string") {
                    if (fetchType === "keysArray" && !selectedInnerSupportDataElement) {
                        if (data?.chartData?.selectedInnerDataElement?.length) {
                            data?.chartData?.selectedInnerDataElement?.map(side => {
                                if (side.value === key) {
                                    if (side.type === "namepicker") {
                                        if (item[key] instanceof Array) {
                                            item[key] = item[key].map(obj => obj.label).join(', ')
                                        } else {
                                            item[key] = item[key]
                                                ? (typeof item[key] === "object"
                                                    ? item[key][label]
                                                    : item[key])
                                                : ""
                                        }
                                    } else {
                                        item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                                    }
                                }
                            })
                        } else {
                            item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                        }
                    } else {
                        if (data.chartData?.selectedDataElement?.length) {
                            data.chartData?.selectedDataElement?.map(sde => {
                                if (sde.value === key) {
                                    if (sde.type === "namepicker") {
                                        if (item[key] instanceof Array) {
                                            item[key] = item[key].map(obj => obj.label).join(', ')
                                        } else {
                                            item[key] = item[key]
                                                ? (typeof item[key] === "object"
                                                    ? item[key][label]
                                                    : item[key])
                                                : ""
                                        }
                                    } else {
                                        item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                                    }
                                }
                            })
                        } else {
                            item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                        }
                    }
                    // item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                }
            })
            return item
        })
    }

    const getColumns = () => {
        let COLUMNS = []
        if (membersList.length) {
            COLUMNS = Object.keys(
                membersList[0]
            ).map((i) => {
                return {
                    id: i,
                    numeric: false,
                    disablePadding: false,
                    label: Helpers.camelCaseToSentenceCase(i),
                    sort: true,
                    checked: true
                }
            }).filter(i => i.id !== '_id')
        }

        return COLUMNS
    }

    const [showTable, setShowTable] = useState(false);

    const handleChangePage = (event, page) => {
        fetchData(page, rowsPerPage, searchedText || '', filterConditions)
        setPage(page)
        setLocalStorageObject({ ...localStorageObject, page: page })
    };

    const onAddClick = () => {
        props.history.push({ pathname: `/${props.match.params.appid}/page/member/new` });
    }

    const handleChangeRowsPerPage = event => {
        fetchData(0, event.target.value, searchedText || '', filterConditions)
        setRowsPerPage(event.target.value)
        setPage(0)
        setLocalStorageObject({ ...localStorageObject, rowsPerPage: event.target.value, page: 0 })
    };

    const onRowClick = (rowData, rowMeta) => {
        console.log('onRowClick');
    };

    const searchHandler = (e) => {
        setSearchedText(e ? e.target.value : "")
    }

    const onSearchEnter = (e, onSearchButton = false) => {
        if (e === null) {            
            setPage(0)
            fetchData(0, rowsPerPage, '', filterConditions)
            searchHandler(null)
        } else if (e?.keyCode === 13 || onSearchButton) {
            setPage(0)
            fetchData(0, rowsPerPage, searchedText, filterConditions)
        }
    }

    const handleInputChange = event => {
        let obj = state.filterTableData;
        let list = obj.columns;
        list.map((item, index) => {
            if (item.name === event.target.value) list[index].checked = event.target.checked;
        });
        obj.columns = [...list];
        setState({ ...state, filterTableData: obj });
        setState({ ...TableSupport.updateTableData(state) });
    };

    const resetFilters = event => {
        setState({ ...state, filterList: [] });
    };

    const handleDropdownChange = (event, index, column) => {
        const value = event.target.value === 'All' ? [] : [event.target.value];
        // filterUpdate(index, value, column, 'dropdown');
        let list = state.filterList;
        list[index] = event.target.value;
        setState({ ...state, filterList: [...list] });
        setState({ ...TableSupport.updateTableData(state) });
    };

    const handleRequestSort = (event, property) => {
        let obj = TableSupport.requestSort(event, property, state);
        setState({ ...state, ...obj });
    };

    const handleSelectAllClick = event => {
        setState({
            ...state,
            selected: TableSupport.handleTableSelectAllClick(event, state.filterTableData.datasets),
        });
    };

    const onClickAnyAction = (docId) => {
        const { _id: userId } = props.user
        if (localStorage.getItem('userSession')) {
            let userSession = JSON.parse(window.atob(localStorage.getItem('userSession')))
            for (let [key, value] of Object.entries(userSession)) {
                if (key === userId) { // userId matched
                    for (let [key1, value1] of Object.entries(value)) {
                        if (key1 === pageUrl) { // page matched
                            userSession[userId][pageUrl] = { ...value1, 'selectedLineItem': docId }
                            localStorage.setItem('userSession', window.btoa(JSON.stringify(userSession)))
                        } else {
                            if (Object.keys(userSession).length > 0) {
                                userSession[userId][pageUrl] = {...value1, 'selectedLineItem': docId }
                                localStorage.setItem('userSession', window.btoa(JSON.stringify(userSession)))
                            }
                        }
                    }
                }
            }
        } else {
            localStorage.setItem('userSession', window.btoa(JSON.stringify({ [userId]: { [pageUrl]: { 'selectedLineItem': docId } } })))
        }
    }

    const tableCrudOperations = (item, type) => {
        if (type === 'edit') {
            onClickAnyAction(item._id)

            props.history.push({
                pathname: `/${props.match.params.appid}/page/${chartData.editPage}/${item._id}`,
            })
        }
        if (type === 'delete') {
            setDeleteConfirm({
                ...deleteConfirmState,
                isOpen: true,
                data: item._id
            })
        }

    };

    const onDeleteResponse = (isDelete) => {
        if (isDelete) {
            const payload = {
                appid: props.appId,
                dataUniqueId: props.data?.chartUniqueId + "_delete_member",
                orgId: props.user.orgId,
                id: deleteConfirmState.data
            }
            props.deleteMember(payload)
        }
        setDeleteConfirm({ ...deleteConfirmState, isOpen: false, data: null })
    }

    const handleCheck = (event, id) => {
        const { selected } = state;
        let newSelected = TableSupport.handleSelectedRow(event, id, selected);
        setState({ ...state, selected: newSelected });
        props.checkedContractChanged(newSelected);
    };

    const onExternalFilterChange = (filterValues) => {
		let _page = 0, _rowsPerPage = rowsPerPage || 10;
		setPage(_page)
		setRowsPerPage(_rowsPerPage)
        let updatedConditions = FiltersUtils.createConditions(filterValues, chartData.externalFilter)
        setFilterValues(filterValues)
        setFilterConditions(updatedConditions)
        fetchData(_page, _rowsPerPage, '', updatedConditions)
	}

    return (
        <>
            <Paper className={classes.table} elevation={3}>
                <AdvancedTable
                    name={'ContractList'}
                    onRowClick={onRowClick}
                    {...props}
                    {...state}
                    chartData={chartData}
                    searchedText={searchedText}
                    searchHandler={searchHandler}
                    onSearchEnter={onSearchEnter}
                    isExport={false}
                    isViewCoulmns={false}
                    isSearch={chartData?.isSearch || false}
                    isLoading={props.formData[props.data?.chartUniqueId + "_all_members_loading"]}
                    isFilter={false}
                    hasCheckbox={false}
                    actions={{ edit: true, view: false, delete: true, copy: false, download: false }}
                    isTablePagination={true}
                    pagination={{ type: '' }}
                    paginationPage={page}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    isTableHead={true}
                    isTableSubHeader={false}
                    associatedFormName={chartData.associatedFormName}
                    handleInputChange={handleInputChange}
                    onAddClick={onAddClick}
                    resetFilters={resetFilters}
                    handleDropdownChange={handleDropdownChange}
                    handleRequestSort={handleRequestSort}
                    handleSelectAllClick={handleSelectAllClick}
                    tableCrudOperations={tableCrudOperations}
                    handleCheck={handleCheck}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    headerstyle={{
                        fontSize: "14px",
                        textAlign: "center",
                        color: "rgba(0, 0, 0, 0.87)",
                        backgroundColor: "#"
                    }}
                    subHeaderstyle={{
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        backgroundColor: "#FFF",
                        textAlign: "center"
                    }}
                    headerClass={classes.headerClass}
                    rowStyle={{
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        backgroundColor: "none",
                        lineHeight: 1.5,
                        textAlign: "center",
                        padding: 0,
                    }}
                    tableBodyCss={{ border: "1px solid lightGrey" }}
                    externalFilters={chartData?.externalFilter}
                    externalFilterData={externalFilterData}
                    externalFilterValues={selectedFilterValues}
                    onExternalFilterChange={onExternalFilterChange}
                    stripRows
                    loader={<Loader />}
                    isNestedTable={props.isNestedTable}
                    onExapandIconClick={props.onExapandIconClick}
                    nestedTableData={props.nestedTableData}
                    // isHighlighted={isHighlighted}
                    // userIdForHightList={userId}
                    highLighterIdForSelectedLineItem={highLighterIdForSelectedLineItem}
                />
            </Paper>
            <ConfirmationHandler
                open={deleteConfirmState.isOpen}
                handleClose={onDeleteResponse}
                alertMessageText={deleteConfirmState.deleteMessage}
                classes={classes}
                confirm
            />
        </>
    )
}

const mapDispatchToProps = {
    updateProfile,
    getOrgDetails,
    getMembersList,
    getAllMembersList,
    deleteMember,
    clearReduxDataOfCurrentComponent
}

const mapStateToProps = state => {
    return {
        user: state.user,
        org: state.org,
        member: state.appConfig.members,
        formData: state.form,
    };
};
export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(UserManagement)));