import React from "react";

import { connect } from "react-redux";
import { hot } from "react-hot-loader/root";
import { withRouter } from "react-router-dom";
import  StepperView  from './stepperindex'
// import { ContractStepperView } from 'dsilo-ui-components'

import {
     getContractDataById, clearSuccessMsg, getDataModelById, getModelDataDropDown, initiateAction, updateDataModelData, getMembersList, clearReduxDataOfCurrentComponent, getModelData, getGroupsByMemberId, getNextRespondent, getDataModels, createOpportunity, getAllOpportunities, deleteOpportunity, getSingleOpportunity, updateOpportunity, createActionForOpportunity, getAllActionsOfOpportunity, getSingleActionOfOpportunity, updateSingleActionOfOpportunity, deleteSingleActionOfOpportunity, getDocumentsFromIDsOfOpportunity, uploadAttachmentOpportunity, deleteAttachmentsForOpportunity, downloadContractPDFAction
} from '../../store/actions';

import { Loader } from "../loader";
import { PUBLIC_PATH } from '../../constants';


const StepperViewComponent = (props) => {
    const currentDocumentId = props?.match?.params?.id;
     
    return (
        <StepperView
            {...props}
            appId={props.match.params.appid}
            orgId={props.appConfig?.org?._id}
            currentDocumentId={currentDocumentId}
            loader={<Loader />}
        />
    )
}

const mapDispatchToProps = {
    getContractDataById,
    updateDataModelData,
    clearSuccessMsg,
    getModelDataDropDown,
    initiateAction,
    getMembersList,
    clearReduxDataOfCurrentComponent,
    getDataModelById,
    getModelData,
    getDataModels,
    getGroupsByMemberId,
    getNextRespondent,
    
    createOpportunity,
    getAllOpportunities,
    deleteOpportunity,
    getSingleOpportunity,
    updateOpportunity,
    createActionForOpportunity,
    getAllActionsOfOpportunity,
    getSingleActionOfOpportunity,
    updateSingleActionOfOpportunity,
    deleteSingleActionOfOpportunity,
    getDocumentsFromIDsOfOpportunity,
    uploadAttachmentOpportunity,
    deleteAttachmentsForOpportunity,
    downloadContractPDFAction
};

const mapStateToProps = state => {
    return {
        formData: state.form,
        action: state.action,
        appConfig: state.appConfig.app,
        PUBLIC_PATH: PUBLIC_PATH,
        members: state.appConfig.members,
        dataModels: state.dataModels,
        user: state.user,
        appConfiguration: state.form.document?.appConfig
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(StepperViewComponent)));