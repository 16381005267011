import { ActionTypes } from 'constants/index'

const initialState = {
	data: [],
	searchText: "",
	error: "",
	loading: false,
	dataModel: {},
	orgDataModels: [],
	deleteSuccess: false
}

const dataModelsReducer = function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_DATAMODELS: {
			return {
				...state,
				loading: true,
				deleteSuccess: false
			}
		}
		case ActionTypes.GET_DATAMODELS_SUCCESS: {
			return {
				...state,
				data: action.payload,
				deleteSuccess: false,
				loading: false,
			}
		}
		case ActionTypes.GET_DATAMODELS_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error
			}
		}
		case ActionTypes.SET_DATAMODELS_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText,
			}
		}
		case ActionTypes.GET_ORG_DATAMODELS_SUCCESS:
			{
				return {
					...state,
					orgDataModels: action.payload
				};
			}
		case ActionTypes.GET_ORG_DATAMODELS_FAILURE:
			{
				return {
					...state,
					loading: false,
					error: action.error
				};
			}
		case ActionTypes.DELETE_DATAMODEL_SUCCESS: {
			return {
				...state,
				loading: false,
				data: [...state.data.filter(item => item._id !== action.payload)],
				deleteSuccess: true

			}
		}
		case ActionTypes.DELETE_DATAMODEL_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error,
				deleteSuccess: false
			}
		}
		default: {
			return state;
		}
	}
};

export default dataModelsReducer;
