import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { BusinessRulesService } from 'services/index'
import { ActionTypes } from 'constants/index'


async function fetchAsync(func, arg) {
	const response = arg ? await func(arg) : await func()
	return response
}

function* getBusinessRules(action) {
	try {
		const businessRules = yield call(fetchAsync, BusinessRulesService.getBusinessRules, action.payload)
		yield put({ type: ActionTypes.GET_BUSINESSRULES_SUCCESS, payload: businessRules.data })
	} catch (e) {
		yield put({ type: ActionTypes.GET_BUSINESSRULES_FAILURE, error: e.data })
	}
}

function* getBusinessRule(action) {
	try {
		const businessRule = yield call(fetchAsync, BusinessRulesService.getBusinessRule, action.payload)
		console.log("businessRule.dat====", businessRule.data);
		yield put({ type: ActionTypes.GET_BUSINESSRULE_SUCCESS, payload: businessRule.data })
	} catch (e) {
		yield put({ type: ActionTypes.GET_BUSINESSRULE_FAILURE, error: e.data })
	}
}

function* saveBusinessRule(action) {
	try {
		const businessRule = yield call(fetchAsync, BusinessRulesService.saveBusinessRule, action.payload)
		yield put({ type: ActionTypes.SAVE_BUSINESSRULE_SUCCESS, payload: businessRule.data })
	} catch (e) {
		yield put({ type: ActionTypes.SAVE_BUSINESSRULE_FAILURE, error: e.data })
	}
}

function* updateBusinessRule(action) {
	try {
		const businessRule = yield call(fetchAsync, BusinessRulesService.updateBusinessRule, action.payload)
		yield put({ type: ActionTypes.UPDATE_BUSINESSRULE_SUCCESS, payload: businessRule.data })
	} catch (e) {
		yield put({ type: ActionTypes.UPDATE_BUSINESSRULE_FAILURE, error: e.data })
	}
}

function* deleteBusinessRule(action) {
	try {
		const businessRule = yield call(fetchAsync, BusinessRulesService.deleteBusinessRule, action.payload)
		yield put({ type: ActionTypes.DELETE_BUSINESSRULE_SUCCESS, payload: businessRule.data })
	} catch (e) {
		yield put({ type: ActionTypes.DELETE_BUSINESSRULE_FAILURE, error: e.data })
	}
}

export function* watchBusinessRulesSaga() {
	yield takeEvery(ActionTypes.GET_BUSINESSRULES, getBusinessRules)
	yield takeEvery(ActionTypes.GET_BUSINESSRULE, getBusinessRule)
	yield takeEvery(ActionTypes.SAVE_BUSINESSRULE, saveBusinessRule)
	yield takeEvery(ActionTypes.UPDATE_BUSINESSRULE, updateBusinessRule)
	yield takeEvery(ActionTypes.DELETE_BUSINESSRULE, deleteBusinessRule)
}

export default watchBusinessRulesSaga;