import clsx from 'clsx';
import React from "react";
import { useDrop } from "react-dnd";
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import { PersonWorkspace } from '@styled-icons/bootstrap/PersonWorkspace';
import useStyles from "./styles";

const WorkspaceListItem = (props) => {
	const classes = useStyles();
	const {
		ws,
		index,
		addDropedItemsToSpace,
		getWorkspaceById,
		selectedWorkspace,
		receivingWorkSpace,
	} = props;
	
	const [{ isOver }, drop] = useDrop({
		accept: "component",
		drop: (item) => addDropedItemsToSpace(item, ws._id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	});

	return (
		<ListItem
			ref={drop}
			className={clsx(classes.listItemRoot, {
				[classes.selectedChat]: props.selectedWorkspace === ws._id,
			})}
			button
			selected={selectedWorkspace === ws._id}
			onClick={() => getWorkspaceById(ws)}
		>
			<Badge
				classes={{ badge: classes.customBadge }}
				badgeContent={
					selectedWorkspace === ws._id ? receivingWorkSpace?.charts?.length : ws?.chart || 0
				}
				overlap="circular"
				showZero
			>
				<ListItemIcon className={classes.listItemIconRoot}>
					<PersonWorkspace size={20} />
				</ListItemIcon>
			</Badge>
			<ListItemText
				classes={{
				root: classes.listItemTextRoot,
				primary: classes.listItemTextPrimary,
				}}
				primary={ws.title || `Dashboard ${index + 1}`}
				style={{ marginLeft: 12 }}
			/>
			{props.workspaceTabVal !== "all" && <ListItemSecondaryAction>
				<IconButton
					disabled={props.isLoading}
					edge="end"
					aria-label="delete"
					onClick={() => props.deleteWorkspace(ws._id)}
				>
					<DeleteIcon fontSize="small" />
				</IconButton>
			</ListItemSecondaryAction>}
		</ListItem>
	);
}

export default WorkspaceListItem;
