import { Button, Container, Divider, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from "react-toastify";
import { getTickets, deleteTicket, clearReduxDataOfCurrentComponent } from "../../store/actions";
import FieldTable from "../FieldTable";
import { Loader } from '../loader'
import { AdvancedTable, TableSupport } from 'dsilo-ui-components'
import ConfirmationHandler from '../../components/ConfirmDialog'

const useStyles = makeStyles({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 10,
    },
    root: {
        width: "100%"
    },
    headerClass: {
        backgroundColor: "#cecece",
        textTransform: 'capitalize'
    },
    confirmationDialoge: {
        "& .MuiDialog-paperWidthSm": {
            height: "250px",
            width: "600px"
        }
    },
    para: {
        fontSize: "28px !important",
        fontWeight: 600,
        color: "#000"
    }
});

const sharedJSON = {
    "order": "asc",
    "orderBy": null,
    "selected": [],
    "tableData": [],
    "filterTableData": [],
    "page": 0,
    "count": 10,
    "rowsPerPage": 10,
    "searchText": "",
    "filterList": [],
    "filterData": []
}

const tableJson = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Document Type',
        sort: true,
    },
    {
        id: 'keyValueFields',
        numeric: false,
        disablePadding: false,
        label: 'Key Data Fields',
        sort: true,
    },

];



const Tickets = (props) => {
    console.log('Tickets-props', props)

    const { data } = props
    const { chartData } = data

    const classes = useStyles()
    const [ticketsList, setTicketsList] = useState([])
    const [state, setState] = useState({ ...sharedJSON, });
    const [searchedText, setSearchedText] = useState('');
    const [deleteConfirmState, setDeleteConfirm] = useState({
        isOpen: false,
        deleteMessage: "Are you sure you want to delete ?",
        data: null
    })

    const { _id:userId } = props.member
    const { selectedDataModel } = chartData

    const [fetch, setFetch] = useState(false)



    useEffect(() => {
        fetchData()
        setFetch(true)
    }, [])

    useEffect(() => {
        // if (props.formData?.document && props.formData.document[props.data?.chartUniqueId + '_data']?.data?.length) {
            let ticketsListData = props.formData?.document[props.data?.chartUniqueId + '_data']?.data?.map(k => ({
                _id: k._id,
                name: k.title
            }))
            setTicketsList(ticketsListData)
        // }
    }, [props.formData && props.formData.document[props.data?.chartUniqueId + '_data']])

    useEffect(() => {
        setState({ ...TableSupport.updateTableData(state) });
    }, [state.searchText, state.filterList]);

    useEffect(() => {
        if (props.formData && props.formData?.document[props.data?.chartUniqueId+'_delete_tickets']?.message) {
            toast.success(props.formData?.document[props.data?.chartUniqueId+'_delete_tickets']?.message);
            props.clearReduxDataOfCurrentComponent({
                key: "document",
                subKey: [props.data?.chartUniqueId+'_delete_tickets']
            })
            fetchData()
        }
    }, [props.formData && props.formData?.document[props.data?.chartUniqueId+'_delete_tickets']])

    useEffect(() => {
        if (ticketsList) {
            const rows = tableJson;
            // const rows = listViewTableJson;
            let keysArr = ['_id'];
            // setState({ ...TableSupport.setDatafromApi2(ticketsList, keysArr, state, rows) });
            setState({
                ...TableSupport.setDatafromApi2(
                    getData(ticketsList),
                    keysArr,
                    state,
                    getColumns()),
                count: props.formData?.document[props.data?.chartUniqueId + '_data']?.total
            });

        }
    }, [ticketsList]);

    useEffect(() => {
        return () => {
            clearCurrentChartReduxStore()
        }
    }, [])

    useEffect(() => {
        if(fetch){
            fetchData()
        }
    }, [state.page, state.rowsPerPage])

    useEffect(() => {
        if (props.formData && props.formData?.message === 'Ticket deleted successfully') {
            fetchData()
        }
    }, [props.formData && props.formData?.message])

    const getData = (dataSource) => {
        const clonedDataSource = JSON.parse(JSON.stringify(dataSource))
        return clonedDataSource.map((item) => {
            const keys = Object.keys(item)
            keys.forEach((key) => {
                if (key && typeof item[key] !== "string") {
                    if (fetchType === "keysArray" && !selectedInnerSupportDataElement) {
                        if (data?.chartData?.selectedInnerDataElement?.length) {
                            data?.chartData?.selectedInnerDataElement?.map(side => {
                                if (side.value === key) {
                                    if (side.type === "namepicker") {
                                        if (item[key] instanceof Array) {
                                            item[key] = item[key].map(obj => obj.label).join(', ')
                                        } else {
                                            item[key] = item[key]
                                                ? (typeof item[key] === "object"
                                                    ? item[key][label]
                                                    : item[key])
                                                : ""
                                        }
                                    } else {
                                        item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                                    }
                                }
                            })
                        } else {
                            item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                        }
                    } else {
                        if (data.chartData?.selectedDataElement?.length) {
                            data.chartData?.selectedDataElement?.map(sde => {
                                if (sde.value === key) {
                                    if (sde.type === "namepicker") {
                                        if (item[key] instanceof Array) {
                                            item[key] = item[key].map(obj => obj.label).join(', ')
                                        } else {
                                            item[key] = item[key]
                                                ? (typeof item[key] === "object"
                                                    ? item[key][label]
                                                    : item[key])
                                                : ""
                                        }
                                    } else {
                                        item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                                    }
                                }
                            })
                        } else {
                            item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                        }
                    }
                    // item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                }
            })
            return item
        })
    }

    const getColumns = () => {
        let COLUMNS = []
        if (ticketsList.length) {
            COLUMNS = Object.keys(
                ticketsList[0]
            ).map((i) => {
                return {
                    id: i,
                    numeric: false,
                    disablePadding: false,
                    label: i,
                    sort: true,
                    checked: true
                }
            }).filter(ele=>ele.id !=='_id')
        }

        return COLUMNS
    }

    const fetchData = () => {
        const payload = {
            "appId": props.appId,
            "orgId": props.user.orgId,
            "dataUniqueId": props.data?.chartUniqueId + '_data',
            "dataModelId":selectedDataModel,
            "payload":{
                "page":state.page,
                "rowsPerPage":state.rowsPerPage
            }
        }
        props.getTickets(payload)
    }

    const onRowClick = (rowData, rowMeta) => {
        console.log('onRowClick');
    };

    const onSearchEnter = (e, onSearchButton = false) => {
        if (e.keyCode === 13 || onSearchButton) {
            // write api call here
        }
    }

    const handleChangePage = (event, page) => {
        setState({ ...state, page: page });
    };

    const onAddClick = () => {
        props.history.push({ pathname: `/${props.match.params.appid}/page/ticket/new` });
    }

    const handleChangeRowsPerPage = event => {
        setState({ ...state, rowsPerPage: event.target.value, page: 0 });
    };

    const searchHandler = (e) => {
        setSearchedText(e ? e.target.value : "")
    }

    const handleInputChange = event => {
        let obj = state.filterTableData;
        let list = obj.columns;
        list.map((item, index) => {
            if (item.name === event.target.value) list[index].checked = event.target.checked;
        });
        obj.columns = [...list];
        setState({ ...state, filterTableData: obj });
        setState({ ...TableSupport.updateTableData(state) });
    };

    const resetFilters = event => {
        setState({ ...state, filterList: [] });
    };

    const handleDropdownChange = (event, index, column) => {
        const value = event.target.value === 'All' ? [] : [event.target.value];
        // filterUpdate(index, value, column, 'dropdown');
        let list = state.filterList;
        list[index] = event.target.value;
        setState({ ...state, filterList: [...list] });
        setState({ ...TableSupport.updateTableData(state) });
    };

    const handleRequestSort = (event, property) => {
        let obj = TableSupport.requestSort(event, property, state);
        setState({ ...state, ...obj });
    };

    const handleSelectAllClick = event => {
        setState({
            ...state,
            selected: TableSupport.handleTableSelectAllClick(event, state.filterTableData.datasets),
        });
    };

    const tableCrudOperations = (item, type) => {
        if (type === 'edit') {
            props.history.push({
                pathname: `/${props.match.params.appid}/page/${chartData.editPage}/${item._id}`,
            })
        }
        if (type === 'delete') {
            setDeleteConfirm({
                ...deleteConfirmState,
                isOpen: true,
                data: item._id
            })
        }

    };

    const onDeleteResponse = (isDelete) => {
        if (isDelete) {
            const payload = {
                appid: props.appId,
                dataUniqueId: props.data?.chartUniqueId + '_delete_tickets',
                orgId: props.user?.orgId,
                id: deleteConfirmState.data,
                memberid: userId,
                dataModelId: props.data?.chartData?.selectedDataModel
            }
            props.deleteTicket(payload)
        }
        setDeleteConfirm({ ...deleteConfirmState, isOpen: false, data: null })
    }

    const handleCheck = (event, id) => {
        const { selected } = state;
        let newSelected = TableSupport.handleSelectedRow(event, id, selected);
        setState({ ...state, selected: newSelected });
        props.checkedContractChanged(newSelected);
    };

    const clearCurrentChartReduxStore = () => {
        let subKeys = [
            props.data?.chartUniqueId + '_data',
            props.data?.chartUniqueId + '_create_tickets'
        ]

        props.clearReduxDataOfCurrentComponent({
            key: "document",
            subKey: subKeys
        })
    }

    return (
        <>
            {props.formData[props.data?.chartUniqueId + '_data_loading'] ? <Loader /> :
                <AdvancedTable
                    name={'ContractList'}
                    onRowClick={onRowClick}
                    {...props}
                    {...state}
                    chartData={chartData}
                    searchedText={searchedText}
                    searchHandler={searchHandler}
                    onSearchEnter={onSearchEnter}
                    isExport={false}
                    isViewCoulmns={false}
                    isSearch={false}
                    isFilter={false}
                    hasCheckbox={false}
                    actions={{ edit: true, view: false, delete: true, copy: false, download: false }}
                    isTablePagination={true}
                    pagination={{ type: 'server' }}
                    isTableHead={true}
                    isTableSubHeader={false}
                    associatedFormName={chartData.associatedFormName}
                    handleInputChange={handleInputChange}
                    onAddClick={onAddClick}
                    resetFilters={resetFilters}
                    handleDropdownChange={handleDropdownChange}
                    handleRequestSort={handleRequestSort}
                    handleSelectAllClick={handleSelectAllClick}
                    tableCrudOperations={tableCrudOperations}
                    handleCheck={handleCheck}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    headerstyle={{
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        textAlign: "left",
                        backgroundColor: "#"
                    }}
                    subHeaderstyle={{
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        backgroundColor: "#FFF",
                        textAlign: "left"
                    }}
                    headerClass={classes.headerClass}
                    rowStyle={{
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        backgroundColor: "none",
                        lineHeight: 1.5,
                        textAlign: "left"
                    }}
                    stripRows
                    loader={props.loader}
                    isNestedTable={props.isNestedTable}
                    onExapandIconClick={props.onExapandIconClick}
                    nestedTableData={props.nestedTableData}
                />
            }
            <ConfirmationHandler
                open={deleteConfirmState.isOpen}
                handleClose={onDeleteResponse}
                alertMessageText={deleteConfirmState.deleteMessage}
                classes={classes}
                confirm
            />
        </>
    )
}


const mapDispatchToProps = {
    getTickets,
    deleteTicket,
    clearReduxDataOfCurrentComponent
};

const mapStateToProps = state => {
    return {
        formData: state.form,
        member: state.appConfig.members,
        user: state.user,
    };
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(withRouter(Tickets)));
