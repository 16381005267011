import { TableWrapper2 } from 'dsilo-ui-components';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PUBLIC_PATH } from '../../constants';
import {
  getMembersList,
  clearReduxDataOfCurrentComponent,
  deleteOpportunity,
  deleteContractPDFAction,
  downloadSheet,
  downloadContractPDFAction,
  getContractDataById,
  getModelData,
  getDataModelById,
  getModelDataDropDown,
  hideSuccessMsg,
  changeAssigneeRequest,
  onUploadData,
  getCognitiveSearchData,
} from '../../store/actions';
import { Loader } from '../loader';

const ListComponent = props => {
  return <TableWrapper2 {...props} PUBLIC_PATH={PUBLIC_PATH} loader={<Loader />} />;
};

const mapDispatchToProps = {
  clearReduxDataOfCurrentComponent,
  getCognitiveSearchData,
  deleteOpportunity,
  deleteContractPDFAction,
  downloadSheet,
  downloadContractPDFAction,
  getContractDataById,
  getModelData,
  getDataModelById,
  getModelDataDropDown,
  hideSuccessMsg,
  getMembersList,
  changeAssigneeRequest,
  onUploadData,
};

const mapStateToProps = state => {
  return {
    appConfig: state.appConfig.app,
    apps: state.app.app,
    login: state.login,
    user: state.user,
    formData: state.form,
    members: state.appConfig.members,
  };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ListComponent)));
