import React from 'react';
import Rating from '@material-ui/lab/Rating';
import useStyles from './style';

const HalfRating = (props) => {
    const classes = useStyles();
    const { appId, questionId, value, readOnly} = props;

    const onRatingChange = (rating, qId) => {
        props.setRating(rating);
        props.saveRating({ appId, questionId: qId, rating })
    }
    
    return (
        <div className={classes.root}>
            <Rating
                name={`half-rating-read-${questionId}`}
                value={value}
                precision={0.5}
                readOnly={readOnly || false}
                onChange={(event, rating) => onRatingChange(rating, questionId)}
            />
        </div>
    )
}

export default HalfRating;