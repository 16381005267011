import app from './app';
import appConfig from './appConfig';
import pages from './pages';
import pageConfig from './pageConfig';
import message from './message';
import user from './user';
import login from './login';
import register from './register';
import org from './org';
import theme from './theme';
import navigation from './navigation';
import entities from './entities';
import form from './form'
import cappsConfig from './cappsConfig';
import contractAnnotations from './contractAnnotations';
import transaction from './transaction';
import contactDsilo from './contactDsilo';
import model from './model';
import models from './models';
import action from './action';
import rules from './rules';
import rule from './rule';
import dataModel from './dataModel';
import dataModels from './dataModels';
import dashboardReducer from './dashboard';
import powerBi from './powerBi';
import breadcrumb from './breadcrumb'
import db from './db'
import chart from './chartReducer'
import chat from './chatReducer';
import notifications from './notifications';

export default {
	app,
	appConfig,
	chart,
	pages,
	pageConfig,
	message,
	user,
	login,
	register,
	org,
	theme,
	navigation,
	entities,
	form,
	cappsConfig,
	contractAnnotations,
    transaction,
	contactDsilo,
	model,
	models,
	action,
	rules,
	rule,
	dataModel,
	dataModels,
	dashboardReducer,
	powerBi,
	breadcrumb,
	db,
	chat,
	notifications
};
