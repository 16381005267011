import clsx from 'clsx';
import React, { forwardRef, useState } from "react";

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { styled } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppBar, Tab, Tabs } from '@material-ui/core'

import ChatHistoryListItem from './ChatListItem';
import WorkspaceListItem from './WorkspaceListItem';
import { chartHistoryUniqueId, workspaceUniqueId } from '../../ChatHelper';

import useStyles from './styles';
import { useEffect } from 'react';

const ChatWorkspaceBox = styled(Box)({
	height: '50%',
	maxHeight: '50%',
	overflowY: 'auto'
});

const SideBar = forwardRef((props, ref) => {
	const classes = useStyles();

	const chatHistoryLoading = props?.chat?.[`${chartHistoryUniqueId}_loading`] || false;
	const workspacesLoading = props?.chat?.[`${workspaceUniqueId}_loading`] || false;

	const deleteChat = (chatId) => {
		if (chatId) {
			const tempChatHistory = [...props?.chat?.data?.chatHistory];
			const newChatHistory = tempChatHistory?.filter(ch => ch._id !== chatId);
			const payload = {
				appId: props?.match?.params?.appid || "",
				chatId,
				payload: {},
				dataUniqueId: chartHistoryUniqueId,
				newChatHistory: newChatHistory
			}
			props?.deleteChatFromHistory(payload);
		}
	}

	const deleteWorkspace = (workspaceId) => {
		if (workspaceId) {
			const tempWorkspaces = [...props.workspaces];
			const newWorkspacesList = tempWorkspaces?.filter(ws => ws._id !== workspaceId);
			const payload = {
				appId: props?.match?.params?.appid || "",
				payload: {
					workspaceId,
				},
				dataUniqueId: workspaceUniqueId,
				newWorkspacesList: newWorkspacesList
			}
			props.deleteWorkspace(payload);
		}
	}

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	return (
		<div className={classes.sideNav}>
			<Drawer
				variant="permanent"
				className={clsx(classes.drawer)}
				classes={{ paper: clsx(classes.drawerPaper) }}
			>
				<Paper square className={classes.tabsPaper}>
					<Typography style={{ fontSize: '18px' }}>Chat</Typography>
					<IconButton color="primary" aria-label="add"
						onClick={() => props.updateCurrentComponent("aiAssistant", 'newPrompt')} size='small'
					>
						<AddIcon fontSize='medium' style={{ color: 'white' }} />
					</IconButton>
				</Paper>
				<Paper style={{ position: "relative", top: "4rem", maxHeight: "24px" }}>
					<Tabs
						value={props.chatTabVal}
						onChange={(e, v) => {
							props.setChatTabVal(v)
						}}
						aria-label="simple tabs example"
						className={classes.tabController}
					>
						<Tab label="My Chat" value="my" {...a11yProps(0)} />
						<Tab label="All" value="all" {...a11yProps(1)} />
					</Tabs>
				</Paper>
				<ChatWorkspaceBox mt={8}>
					{chatHistoryLoading
						? <Box className={classes.circularProgress}><CircularProgress /></Box>
						: props?.chat?.data?.chatHistory && props?.chat?.data?.chatHistory?.length > 0
							? (<List component="nav" aria-label="chat history titles">
								{props?.chat?.data?.chatHistory.map((prevChat, index) => (
									<ChatHistoryListItem
										key={prevChat._id + index}
										deleteChat={deleteChat}
										index={index}
										prevChat={prevChat}
										selectedChatId={props.selectedChatId}
										getChatHistoryByChatId={props.getChatHistoryByChatId}
										updateChatTitle={props.updateChatTitle}
										chatTabVal={props.chatTabVal}
									/>
								))}
							</List>)
							: <></>
					}
				</ChatWorkspaceBox>
				<Divider style={{ height: 4 }} />
				{props?.chartData?.workspace != false
					&& <>
						<Paper square className={classes.tabsPaper} style={{ top: 0 }}>
							<Typography style={{ fontSize: '18px' }}>Dashboard</Typography>
							<IconButton color="primary" aria-label="add"
								onClick={props.addNewWorkspace} size='small'
							>
								<AddIcon fontSize='medium' style={{ color: 'white' }} />
							</IconButton>
						</Paper>
						<Paper style={{ position: "relative", maxHeight: "24px" }}>
							<Tabs
								value={props.workspaceTabVal}
								onChange={(e, v) => {
									props.setWorkspaceTabVal(v)
								}}
								aria-label="simple tabs example"
								className={classes.tabController}
							>
								<Tab label="My Dashboard" value="my" {...a11yProps(0)} />
								<Tab label="All" value="all" {...a11yProps(1)} />
							</Tabs>
						</Paper>
						<ChatWorkspaceBox>
							{workspacesLoading
								? <Box className={classes.circularProgress}><CircularProgress /></Box>
								: props.workspaces && props.workspaces?.length > 0
									? (<List component="nav" aria-label="workspaces">
										{props.workspaces.map((ws, index) => (
											<WorkspaceListItem
												addDropedItemsToSpace={props.addDropedItemsToSpace}
												getWorkspaceById={props.getWorkspaceById}
												index={index}
												key={index}
												receivingWorkSpace={props.receivingWorkSpace}
												selectedWorkspace={props.selectedWorkspace}
												ws={ws}
												deleteWorkspace={deleteWorkspace}
												isLoading={workspacesLoading}
												workspaceTabVal={props.workspaceTabVal}
											/>
										))}
									</List>)
									: <></>
							}
							{/* {workspacesLoading && <Box className={classes.circularProgress}><CircularProgress /></Box>} */}
						</ChatWorkspaceBox>
					</>
				}
			</Drawer>
		</div>
	);
});

export default SideBar;