import { Button, Container, Divider, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from "react-toastify";
import { getAppMngmtConfig,saveAppMngmtConfig } from "../../store/actions";
import FieldTable from "../FieldTable";
import { Loader } from '../loader'

const useStyles = makeStyles({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 10,
    },
});


let fields = [
    {
        type: 'input',
        name: 'field1',

    },
    // {
    //     type: 'input',
    //     name: 'field2',

    // },
    // {
    //     type: 'dropdown',
    //     name: 'field3',
    //     values: [
    //         'abc', 'def'
    //     ]
    // },
    // {
    //     type: 'dropdown',
    //     name: 'field4',
    //     values: [
    //         {
    //             label: 'test',
    //             value: 213
    //         }
    //     ]
    // }
]

let values = [
    // {
    //     field1: '',
    // },
]


let heading1 = "Input Mail Boxes"
let heading2 = "Output Mail Boxes"

const MailBox = (props) => {

    const classes = useStyles()
    const [inputMailBoxValues, setInputMailBoxValues] = useState([{field1:''}])
    const [outputMailBoxValues, setOutputMailBoxValues] = useState([{field1:''}])

    useEffect(()=>{
        fetchData()
    },[])

    useEffect(()=>{
        if(props.formData && props.formData?.document[props.data.chartUniqueId + "_data"]){
            let mailBox =props.formData?.document[props.data.chartUniqueId + "_data"]
            let incomingMailBoxes = mailBox?.incomingMailBoxes.map(i=> ({field1:i}))
            let outgoingMailBoxes = mailBox?.outgoingMailBoxes.map(i=> ({field1:i}))
            if(incomingMailBoxes && incomingMailBoxes.length ){
                setInputMailBoxValues([...incomingMailBoxes, {field1:''}])
            }
            if( outgoingMailBoxes && outgoingMailBoxes.length){
                setOutputMailBoxValues([...outgoingMailBoxes, {field1:''}])
            }
        }
    },[props.formData.document[props.data.chartUniqueId + "_data"]])

    const fetchData = ()=>{
        const payload = {
            "appId":props.appId,
            "memberId":props.member?._id,
            "orgId": props.user.orgId,
            "dataUniqueId":props.data?.chartUniqueId+'_data'
        }
        props.getAppMngmtConfig(payload)
    }


    const onSave = () => {
        if (inputMailBoxValues.length && outputMailBoxValues.length) {
            let payload = {
                appId: props.match.params.appid,
                orgId: props.user.orgId,
                data: {
                    "appId": props.match.params.appid,
                    "memberId": props.member?._id,
                    "incomingMailBoxes": inputMailBoxValues.map(i=> i.field1).filter(i=> i),
                    "outgoingMailBoxes": outputMailBoxValues.map(i=> i.field1).filter(i=> i),
                },
            }
            props.saveAppMngmtConfig(payload)
        } else {
            toast.info('Please enter email in both fields')
        }


    }

    const onChange = (type, values) => {
        if (type === 'input') {
            setInputMailBoxValues(values)
        } else {
            setOutputMailBoxValues(values)
        }
    }

    if(props.formData[props.data?.chartUniqueId+'_data_loading']){
        return <Loader />
    }



    return (<div>
        <div>
            <div className={classes.header}>
                <h1>{'Mail Box'}</h1>
                <Button
                    onClick={onSave}
                    variant="contained" color="primary">
                    Save
                </Button>
            </div>
            <Divider />
        </div>
        <Container maxWidth={false}>
            <Grid container spacing={6} justifyContent='space-between'>
                <Grid item xs={6}>
                    <FieldTable heading={heading1}
                        onChange={(v) => onChange('input', v)}
                        fields={fields}
                        values={inputMailBoxValues} />
                </Grid>
                <Grid item xs={6}>
                    <FieldTable heading={heading2}
                        onChange={(v) => onChange('output', v)}
                        fields={fields}
                        values={outputMailBoxValues} />
                </Grid>

            </Grid>
        </Container>
    </div >)
}


const mapDispatchToProps = {
    getAppMngmtConfig,
    saveAppMngmtConfig
};

const mapStateToProps = state => {
    return {
        formData: state.form,
        member: state.appConfig.members,
        user: state.user,
    };
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(withRouter(MailBox)));
