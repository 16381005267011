import React from "react";

import { connect } from "react-redux";
import { hot } from "react-hot-loader/root";
import { withRouter } from "react-router-dom";
import { PDFStepperView } from 'dsilo-ui-components'

import {
    clearSuccessMsg, createDocument, downloadContractPDFAction, downloadSheet, getContractDataById, getEventByID, getNotDuplicateAPI,
    updateContractAnnotations, getDataModelById, addComment, deleteContractPDFAction, getModelDataDropDown, initiateAction, updateDataModelData, getMembersList, addNewEntriesByDataModel,
    clearReduxDataOfCurrentComponent, getModelData, getAuditTrailsData, changeAssigneeRequest, completeReviewAction,
    getDataModels, otherDocMove, splitPDF, getVISData, getPDFForOtherDoc, hideSuccessMessage, rejectReviewAction, getWorkflow, finalDocument, getGroupsByMemberId, getNextRespondent,
    getNextTasks, executeWorkFlow, executeWorkFlowReject, lockDocument, unlockDocument, fetchmasterDataForDataelementUsingRules, getGroups, getSupportingDocuments, uploadSupportDocument, getOthersAuditTrailsData, moveToDuplicates, getReviewInfo, executeRejectToQa, getPoInfoFromExternalSource, handleSageIntacct, getCurrentStepRespondents,
    fetchDocumentDataFromTargetDataModel, fetchDocumentDataToSuportMapping, updateMappingData, fetchDocumentDataToSuportThreeWayMatch, getDocumentAnnotations, getDocumentTablesDataById, resolveIntegrationExceptions
} from '../../store/actions';

import { Loader } from "../loader";
import { PUBLIC_PATH } from '../../constants';

const PDFStepperViewComponent = (props) => {
    return (
        <PDFStepperView
            {...props}
            loader={<Loader />}
        />
    )
}

const mapDispatchToProps = {
    getNotDuplicateAPI,
    getContractDataById,
    updateDataModelData,
    clearSuccessMsg,
    createDocument,
    downloadSheet,
    deleteContractPDFAction,
    getModelDataDropDown,
    downloadContractPDFAction,
    getEventByID,
    initiateAction,
    getMembersList,
    clearReduxDataOfCurrentComponent,
    updateContractAnnotations,
    getDataModelById,
    getModelData,
    getAuditTrailsData,
    changeAssigneeRequest,
    completeReviewAction,
    addNewEntriesByDataModel,
    getDataModels,
    otherDocMove,
    splitPDF,
    getVISData,
    getPDFForOtherDoc,
    hideSuccessMessage,
    rejectReviewAction,
    getWorkflow,
    finalDocument,
    getGroupsByMemberId,
    getNextRespondent,
    getNextTasks,
    executeWorkFlow,
    executeWorkFlowReject,
    lockDocument,
    unlockDocument,
    getGroups,
    fetchmasterDataForDataelementUsingRules,
    addComment,
    getSupportingDocuments,
    uploadSupportDocument,
    getOthersAuditTrailsData,
    moveToDuplicates,
    getReviewInfo,
    executeRejectToQa,
    getPoInfoFromExternalSource,
    handleSageIntacct,
    fetchDocumentDataFromTargetDataModel,
    fetchDocumentDataToSuportMapping,
    updateMappingData,
    fetchDocumentDataToSuportThreeWayMatch,
    getDocumentAnnotations,
    getDocumentTablesDataById,
    getCurrentStepRespondents,
    resolveIntegrationExceptions
};

const mapStateToProps = state => {
    return {
        formData: state.form,
        action: state.action,
        appConfig: state.appConfig.app,
        PUBLIC_PATH: PUBLIC_PATH,
        members: state.appConfig.members,
        dataModels: state.dataModels,
        user: state.user,
        appConfiguration: state.form.document?.appConfig
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFStepperViewComponent)));