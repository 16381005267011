import { ContractView } from 'dsilo-ui-components';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getModelData, getContractDataById, clearReduxDataOfCurrentComponent } from '../../store/actions';
import { Loader } from "../loader";
import { PUBLIC_PATH } from 'constants/index';

const ContractViewer = (props) => {

    return (
        <>
            <ContractView {...props} loader={<Loader />} publicPath={PUBLIC_PATH} />
        </>
    );
}

const mapDispatchToProps = {
	getModelData,
  getContractDataById,
  clearReduxDataOfCurrentComponent
};

const mapStateToProps = state => {
	return {
	  formData: state.form,	
	  user: state.user,
	};
  };
  

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractViewer)));
