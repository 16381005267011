import { Client } from 'dsilo-ui-components';
import { clearReduxStore } from '../../store/actions'
const PUBLIC_PATH = process.env.REACT_APP_PUBLIC_PATH
    ? process.env.REACT_APP_PUBLIC_PATH + "/"
    : ""

export const Request = (uri, options) => {
    return Client.request(uri, options, clearReduxStore, null, PUBLIC_PATH)
}

