import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { ContractUploader } from 'dsilo-ui-components';
import { withRouter } from 'react-router-dom';
import { uploadPDF, clearUploadPDFData, getDataModelById ,
	clearPDFDuplicateError} from '../../store/actions';
import { toast } from 'react-toastify';
import { PUBLIC_PATH } from '../../constants';
import Loader from 'components/Loader/index'
import { chart } from 'highcharts';

const UploadPDFComponent = (props) => {
	const { formData, data } = props
	const { loading, uploadedPDFData } = formData;
	const { chartData } = data

	const [selectedAdditionalAIParameters, setSelectedAdditionalAIParameters] = useState({})

	const [selectedDocumentType, setSelectedDocumentType] = useState()

	useEffect(() => {
		console.log("init use effect");
		if (chartData.selectedDataModel) {
			getDataModelInfo()
		}
	}, [])

	useEffect(() => {
		console.log("selectedDocumentType use effect", selectedDocumentType);
		if (chartData.selectedDataModel) {
			getDataModelInfo(selectedDocumentType)
		}
	}, [selectedDocumentType])

	let selectedDataModel = (selectedDocumentType && selectedDocumentType?.value) || chartData?.selectedDataModel
	let dataModelInfo = selectedDataModel ? formData?.dataModel[selectedDataModel] : "noData";
	useEffect(() => {
		console.log("dataModelInfo", dataModelInfo);
		if (dataModelInfo) {
			console.log("overwrite", dataModelInfo.overwrite);
		}
	}, [dataModelInfo])

	useEffect(() => {
		if (uploadedPDFData && uploadedPDFData.length) {
			props.clearUploadPDFData()
			toast.success(chartData.afterSuccessMessage
				|| 'Document has been submitted to AI to process, AI extraction process has been initiated');
			handleAfterSuccess()
		}
	}, [uploadedPDFData]);
	
	const onAdditionalAIParametersChange = (val, item) => {
		console.log("val, item", val, item);
		setSelectedAdditionalAIParameters({
			...selectedAdditionalAIParameters,
			[item.value]: val
		})
	}

	const handleAfterSuccess = () => {
		// go to details page
		let id = uploadedPDFData[0].id
		let afterSuccess = chartData.afterSuccess
		console.log("selectedDocumentType == afterSuccess ", selectedDocumentType, afterSuccess);
		let pageUrl
		//TODO:  all re-directions need to be based pageId
		if (Array.isArray(afterSuccess)) {
			if (selectedDocumentType) {
				let pageUrlObj = afterSuccess.find(suc => suc.documentType === selectedDocumentType.label)
				if (pageUrlObj) {
					pageUrl = pageUrlObj.page
				} else {
					pageUrl = afterSuccess[0].page
				}
			} else {
				pageUrl = afterSuccess[0].page
			}
		} else if (typeof afterSuccess === 'object' && afterSuccess !== null) {
			pageUrl = afterSuccess.page
		} else {
			pageUrl = afterSuccess
		}
		console.log();
		props.history.push(`/${PUBLIC_PATH}${props.match.params.appid}/page/${pageUrl}/${id}`)
	}

	const getDataModelInfo = (selectedDataModel) => {
		let dataModelId = selectedDataModel ? selectedDocumentType.value : chartData.selectedDataModel
		let payload = {
			appid: props.match.params.appid,
			dataModelId: dataModelId,
			orgId: props.user.orgId,
		};
		if (dataModelId)
			props.getDataModelById({ ...payload, dataUniqueId: dataModelId })
	}

	const handleUpgrade = () => {
	};

	const contactClick = () => {
	};

	return <div>
		<ContractUploader
			loading={loading}
			Loader={<Loader />}
			insightsType='contract'
			contractAnnotations={props.contractAnnotations}
			upgrade={true}
			note={true}
			uploadPDF={payload=> uploadPDF(payload)}
			handleUpgrade={handleUpgrade}
			contactClick={contactClick}
			selectedDocumentType={selectedDocumentType}
			setSelectedDocumentType={setSelectedDocumentType}
			dataModelInfo={dataModelInfo}
			selectedAdditionalAIParameters={selectedAdditionalAIParameters}
			onAdditionalAIParametersChange={onAdditionalAIParametersChange}
			{...props}
		/>
	</div>;
};

const mapDispatchToProps = {
	uploadPDF,
	clearUploadPDFData,
	getDataModelById,
	clearPDFDuplicateError
};

const mapStateToProps = state => {
	return {
		appConfig: state.appConfig.app,
		apps: state.app.app,
		login: state.login,
		user: state.user,
		contractAnnotations: state.contractAnnotations,
		formData: state.form,
	};
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadPDFComponent)));
