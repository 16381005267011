import React, { useEffect, useState } from 'react';

import {
    Box, Button, CircularProgress, Paper, Checkbox, IconButton, Input, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Typography, Tooltip, Grid,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import HeaderCard from '../../HeaderCard';
import { getOptionsForDropDownIfDataElementConfiguredWithData, Helpers } from 'dsilo-ui-components'

import useStyles from '../styles';
import DynamicViewDocs from './view';


const DynamicDocs = (props) => {
    const classes = useStyles();

    const { orgId, appId, currentDocumentId, formData, selectedStep, block, json, docsJson, isLoading, loader, blockState, handleFetchDataOnModelClose } = props
    const { document } = props.formData
    const { chartUniqueId } = props.data
    const [enabledBlocks, setEnabledBlocks] = useState([])
    const [onChangeValue, setOnChangeValue] = useState(false)
    const [visibleCellIndex, setVisible] = useState(null)
   
    const [fetchDataOnModelClose, setFetchDataOnModelClose] = useState(false)
    let showErrorIcon = false;
    
    const row = docsJson;

    // Below UseEffect is used for get the enabled Blocks from the selected Step
    useEffect(() => {
        if (selectedStep?.blocks?.length) {
            let b = selectedStep.blocks.filter((block => block.enable))
            setEnabledBlocks(b)
        }
    }, [selectedStep])

    // const onChange = (id, name, value, valIndex, type = null) => {
    //     console.log("Onchange clicked ==> ", id, name, value, valIndex, type);
    // }

    // const onBlurHandler = (name, type) => {
    //     console.log("On Blur hit", name, type);
    // }

    // Below function is used for get the common dataElements from dataModel and the config selected Step
    const getBlockHeaders = (eachBlock) => {
        if (formData?.dataModel[selectedStep.dataModel]) {
            let dataElements = formData.dataModel[selectedStep.dataModel].dataElements || []
            if (dataElements?.length) {
                let fdes = []
                console.log("Cmng here 000 ===> ", eachBlock.dataElements, eachBlock?.dataElements?.length);
                if (eachBlock?.dataElements) {
                    console.log("Cmng here 000 ===> ", eachBlock.dataElements, dataElements);
                    fdes = JSON.parse(JSON.stringify(eachBlock.dataElements))
                    fdes = fdes.map(fde => {
                        let sde = dataElements.find(de => de.name === fde.value)
                        if (sde?.name) {
                            return {
                                ...sde,
                                ...fde
                            }
                        } else {
                            return fde
                        }
                    })
                    setBlockHeaders(fdes)
                    console.log("Fdes 000 ===> ", fdes);
                }
            }
        }
    }
  
    return (
        <React.Fragment>
            {/* {console.log("DynamicDocs Block Headers ==> ", blockHeaders, blockState)} */}
            {console.log("fetchDataOnModelClose ====>>> ", fetchDataOnModelClose)}
            {block.config.map((eachBlock, bIndex) =>
                <DynamicViewDocs
                    keyName={eachBlock.dataModel}
                    {...props}
                    blockIndex={bIndex}
                    title={block?.title || 'Stepper Header'}
                    // headers={getBlockHeaders(eachBlock)}
                    block={block}
                    eachBlock={eachBlock}
                    blockState={blockState}
                    // isLoading={formData[`${chartUniqueId}_${selectedStep.type}_${block.name}_loading`]}
                    isLoading={false}
                    loader={props.loader}
                    handleFetchDataOnModelClose={handleFetchDataOnModelClose}
 
                />
            )}
        </React.Fragment>
    )
}

export default DynamicDocs