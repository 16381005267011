import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position:"right"
      },
      title: {
        display: false
      },
    },
  };



 const DoughnutChartSmall =(props) => {
  return <Doughnut options={options} data={props.data} width={"10%"} height={"5%"}/>;
}

export default DoughnutChartSmall
