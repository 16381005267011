import axios from 'axios/index';
import Utils from 'packages/page/utils';
import { ActionTypes } from 'constants/index'

import {
	prepareDataElements,
} from 'packages/datamodel'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const emptyDataModels = () => dispatch => {
	return dispatch({ type: ActionTypes.GET_DATAMODELS, payload: [] })
}

export const getDataModels = (appId) => dispatch => {
	return dispatch({ type: ActionTypes.GET_DATAMODELS, payload: appId })
}

export const setDataModelsSearchText = (value) => dispatch => {
	return dispatch({ type: ActionTypes.SET_DATAMODELS_SEARCH_TEXT, searchText: value })
}

export const getDataModelsByDatasource = (id, appId) => dispatch => {
	return dispatch({ type: ActionTypes.GET_DATAMODELS_BY_DATASOURCE, payload: { id, appId } })
}


export const emptyDataModel = () => dispatch => {
	return dispatch({ type: ActionTypes.GET_DATAMODEL_SUCCESS, payload: undefined })
}

export const newDataModel = () => dispatch => {
	const data = {
		id: Utils.generateGUID(),
		name: '',
		handle: '',
		description: '',
		dataModelType: '',
		active: true,
		dataElements: [],
	};
	dispatch({ type: ActionTypes.EMPTY_DATAELEMENTS })
	return dispatch({ type: ActionTypes.GET_DATAMODEL_SUCCESS, payload: data })
}

export const getDataModel = (params) => dispatch => {
	return dispatch({ type: ActionTypes.GET_DATAMODEL, payload: params })
}

export const updateDataModel = (data, dappId) => dispatch => {
	return dispatch({ type: ActionTypes.UPDATE_DATAMODEL, payload: { ...data, appId: dappId } })
}

export const deleteDataModel = (data) => dispatch => {
	return dispatch({ type: ActionTypes.DELETE_DATAMODEL, payload: data })
}

export const addDataElement = (dataModel, dataElement, currentDatamdelId, appId) => dispatch => {
	let dataModelData = prepareDataElements(dataModel, dataElement, currentDatamdelId);
	return dispatch({ type: ActionTypes.UPDATE_DATAMODEL, payload: { ...dataModelData, appId } })
}

export const emptyDataSourceCollections = () => dispatch => {
	return dispatch({ type: ActionTypes.GET_COLLECTIONS_FROM_DATASOURCE_SUCCESS, payload: undefined })
}

export const getDataElementsByDataModel = (id, appId) => dispatch => {
	return dispatch({ type: ActionTypes.GET_DATAELMENTS_BY_DATAMODEL, payload: { id, appId } })
}

export const getCollectionsFromDatasource = (id, appId) => dispatch => {
	return dispatch({ type: ActionTypes.GET_COLLECTIONS_FROM_DATASOURCE, payload: { id, appId } })
}

export const saveDataModel = (data) => dispatch => {
	return dispatch({ type: ActionTypes.SAVE_DATAMODEL, payload: data })
}

export function saveDataModelFromForm(data, id, orgId) {
	const request = axios.post(API_BASE_URL + '/dataModels/add', data, { headers: { 'appId': id, orgId } });
	return request.then((response) => {
		return response
	})
}

export function openNewDataModelDialog(data) {
	return {
		type: ActionTypes.OPEN_NEW_DATAMODEL_DIALOG,
		payload: data
	}
}

export function closeNewDataModelDialog() {
	return {
		type: ActionTypes.CLOSE_NEW_DATAMODEL_DIALOG
	}
}

export const getDataModelsByOrgId = (data) => dispatch => {
	return dispatch({ type: ActionTypes.GET_ORG_DATAMODELS, payload: data })
}