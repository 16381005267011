import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import GetIconByStatus from './GetIconByStatus';

const Steps = styled(Box)(({ theme }) => ({
	alignItems: 'center',
	display: 'flex',
	gap: 3,
	margin: '0 8px',
}));

const ReadMoreButton = styled(Link)(({ theme }) => ({
	fontSize: 10,
	marginLeft: 10,
}));

const SubProcessView = (props) => {
	const [showMore, setShowMore] = useState(false);
	const { subProcessSteps } = props;
	return <>
		{subProcessSteps?.length > 0 && subProcessSteps.slice(0, showMore ? subProcessSteps.length : 5).map((nMsg, index) => (
			<Steps key={index}>
				<GetIconByStatus status={nMsg?.status} fontSize={14} />
				<Typography variant="subtitle2" component="span" style={{ fontSize: 12 }}>
					{nMsg.message}
				</Typography>
			</Steps>
		))}
		{subProcessSteps?.length > 5 && (
			<ReadMoreButton
				color="primary"
				onClick={() => setShowMore(prev => !prev)}
				variant="body2"
			>
				Read {showMore ? 'Less' : 'More'}
			</ReadMoreButton>
		)}
	</>
}

export default SubProcessView;