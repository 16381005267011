/**
 * React core library for building user interfaces.
 * Material-UI component for creating modal dialogs.
 * Material-UI function to create custom styling for components.
 * Custom PDF viewer component from the dsilo-ui-components library.
 */
import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { PdfjsViewer } from "dsilo-ui-components";
/**
 * Defines custom styles for the modal component using Material-UI's styling solution.
 */
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 720,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxHeight: 650
    },
}));

/**
 * Returns the style object for positioning the modal at the center of the screen.
 * @returns {Object} The style object for the modal's positioning and overflow settings.
 */
function getModalStyle() {
    const top = 50
    const left = 50
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        overflow: 'auto'
    };
}

/**
 * Component for displaying a modal with PDF or other document attachments.
 * @param {Object} props - The properties passed to this component.
 * @param {boolean} props.open - Determines whether the modal is open or closed.
 * @param {string} props.appId - The application ID for fetching the document.
 * @param {string} props.docID - The document ID for fetching the document.
 * @param {string} props.dataModelId - The data model ID for fetching the document.
 * @param {Object} props.formData - The form data containing the fetched PDF or other documents.
 * @param {Function} props.downloadContractPDFAction - Function to trigger the download of the PDF document.
 * @param {Function} props.clearReduxDataOfCurrentComponent - Function to clear Redux data for the current component.
 * @param {Function} props.handlesQuickPDFClose - Function to handle the closing of the PDF modal.
 * @returns {JSX.Element} The modal component displaying the PDF or other document attachments.
 */
const ShowAttachmentsModel = (props) => {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [pdf, setPdf] = useState(null);
    const [otherDocs, setOtherDocs] = useState(null);

    /**
     * Handles the closing of the modal and triggers the necessary parent function.
     */
    const handleClose = () => {
        setOpen(false);
        props.handlesQuickPDFClose()
    };

    /**
     * Fetches the PDF or other document attachments based on the provided props.
     */
    const fetchData = () => {
        props.downloadContractPDFAction({
            appid: props.appId,
            _id: props.docID,
            dataModelId: props.dataModelId,
            field: 'path',
            modelDataType: 'attachments',
            dataUniqueId: "quickView_Attachments_Opportunity"
        })
    }

    /**
     * Clears the Redux store data related to the current component.
     */
    const clearCurrentChartReduxStore = () => {
        props.clearReduxDataOfCurrentComponent({
            key: "pdfDocument",
            subKey: ['quickView_Attachments_Opportunity', 'error', 'message']
        })
    }

    /**
     * Fetches the data when the component mounts and clears the Redux store data on unmount.
     */
    useEffect(() => {
        fetchData()
        return () => {
            clearCurrentChartReduxStore()
        }
    }, [])

    /**
     * Updates the state with the fetched PDF or other document when formData changes.
     */
    useEffect(() => {
        if (props.formData?.pdfDocument?.['quickView_Attachments_Opportunity']?.pdf && props.formData?.pdfDocument?.['quickView_Attachments_Opportunity']?.pdf?.type != 'application/pdf') {
            setOtherDocs(URL.createObjectURL(new window.Blob([props.formData?.pdfDocument['quickView_Attachments_Opportunity']?.pdf], { type: "image/jpeg" })))
            setPdf(null)
        } else if (props.formData?.pdfDocument?.['quickView_Attachments_Opportunity']?.pdf) {
            setOtherDocs(null);
            setPdf(new window.Blob([props.formData?.pdfDocument['quickView_Attachments_Opportunity']?.pdf], { type: "application/pdf" }))
        }
    }, [props?.formData?.pdfDocument])

    /**
     * Sets the modal's open state based on the passed prop.
     */
    useEffect(() => {
        setOpen(props.open)
    }, [props.open])
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={classes.paper}>
                    {
                        (props.formData?.['quickView_Attachments_Opportunity_loading']) ? (props.loader) :

                            (pdf ? <PdfjsViewer pdf={pdf} showHighlights={props.data?.chartData?.showPdfHighlights} match={props.match} /> : <img style={{ overflow: 'auto' }} id="encodedImageShow" src={otherDocs} />)
                    }
                </div>
            </Modal>
        </>
    )
}

export default ShowAttachmentsModel
