import React, { useEffect, useState } from "react"
import HorizontalLinearStepper from "./StepperComponent"

// ContractStepperViewView 
// This component is used for view invoice with multiple steps

const StepperViewView = (props) => {
    let { steps } = props

    const appId = props.match.params.appid
    const orgId = props.appConfig?.org?._id
    const currentOpportunityId = props?.match?.params?.id

    return (
        <HorizontalLinearStepper
            {...props}
            steps={steps}

            appId={appId}
            orgId={orgId}
            currentOpportunityId={currentOpportunityId}
        />
    )
}

export default StepperViewView