import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: 1201,
		color: '#fff',
	},
	mainBox: {
		padding: '2px 6px',
		// backgroundColor: '#dff9ff'
	},
	paper: {
        marginTop: 10,
		width: '100%',
		height: '98%'
	},
	filterBox: { display: 'flex', alignItems: 'center', gap: 10 },
	formControl: {
		minWidth: 120,
	},
	formatBox: { 
		display: 'flex', 
		flexDirection: 'row', 
		justifyContent: 'space-between', 
		padding: '0 10px'
	},
	heading: { 
		textAlign: 'center !important',
		marginBottom: 20,
		paddingTop: 10
	},
	labelBox: {
		backgroundColor: '#112840',
		padding: '10px 5px'
	},
	planFilter: {
        border: '1px solid #ddd',
        borderRadius: 8,
        paddingLeft: 10,
        minWidth: 100,
        marginRight: 10,
        fontWeight: 'bold',
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    active: {
        border: '1px solid tomato'
    },
	resetSelection: {
		fontWeight: 500,
        alignSelf: 'center',
        // marginTop: 16,
        cursor: "pointer",
        minWidth: 70
	}
}));

export default useStyles;