import { Grid, makeStyles, Typography } from '@material-ui/core';
import { ConfirmDialog } from 'dsilo-ui-components';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
	clearAppMessage, createApp,
	deleteDapp, getAppAndTemplates, getCategories, getTemplates, loadApps
} from '../../store/actions';
import Loading from '../../components/Loader/index';
import View from './View';
import AppHeader from './AppHeader';
import JoinOrg from '../Org/JoinOrg';

const AppsDashboard = (props) => {
	const classes = useStyles()
	const [open, setOpen] = React.useState(false)
	const [dapp, setDapp] = React.useState({})
	const [searchKeyword, setSearchKeyword] = React.useState("")
	const [categoryFilterData, setCategoryFilterData] = React.useState('');

	/**
	 * useEffect to fetch applications and templates after login
	 */
	useEffect(() => {
		if (props?.user?.orgId) {
			props.getAppAndTemplates(props?.user?.orgId);
		}
	}, [props.user])

	useEffect(() => {
		if (props.apps?.createAppMessage) {
			toast.info(props.apps?.createAppMessage)
			props.clearAppMessage()
		}
	}, [props.apps?.createAppMessage])

	// It will help to directly open the app when user has access for single app
	useEffect(() => {
		if (!props.apps?.loading) {
			if (props.apps.allApps?.length && props.apps.allApps.length === 1) {
				let app = props.apps.allApps[0];
				props.history.push({
					pathname: `/${app ? app._id : props.app[0]._id}`
				});
			} else {
				props.history.push({
					pathname: '/apps'
				});
			}
		}
		// }, [props.apps, props.apps?.loading, appid]);
	}, [props.apps, props.apps?.loading]);

	/*
		useEffect(() => {
			if (props.org?.data?.defaultLandingApplication) {
				const dappVerifyInfo = yield call(fetchAsync, AppService.verifyApp, org?.data?.defaultLandingApplication);
				console.log("dappVerifyInfo", dappVerifyInfo);
				let dapp = dappVerifyInfo.data.dapp
				if (dapp) {
					let appURL = typeof (dapp.appViewURL) !== 'undefined'
						? dapp.appViewURL
						: `/${dapp.type}/${dapp._id}`
					return window.open(`${appURL}`, "_self")
				}
			}
			history.push('/apps');
		}, [props.org])
	*/

	const deleteDappData = (dapp) => {
		setOpen(true)
		setDapp(dapp)
	}

	const handleAddNewAppClick = (data) => {
		props.createApp({ data: data, orgId: props.user?.orgId })
	}

	const updateDappData = (dapp) => {
		let obj = { ...dapp };
		obj.status = "Published"
		setDapp(obj);
		props.updateDapp(obj);
	}

	const editDapps = (id) => {
		//on edit
	}

	const handleCategoryFilter = (event) => {
		setCategoryFilterData(event.target?.value);
	}

	return (
		<AppHeader>
			<div className="recent-apps" style={{ marginBottom: 0, }}>
				<Grid container className={classes.root}  >
					<Grid item xs={12} className="recent-apps-bannerbg" >
						<Grid item lg={8} md={8}>
							<Typography variant="h4">
								{"Driving accelerated value creation and cost savings at enterprises through Automation, Intelligence and Trust."}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</div>

			{(props.apps.loading || props.user?.userDetailsLoading) ? <Loading /> : <>
				<View
					// isTemplate={true} 
					history={props.history}
					title={"Applications"}
					noTitle={"No applications found"}
					isBanner={false}
					handleAddNewAppClick={handleAddNewAppClick}
					templates={props.apps.templates}
					apps={props.apps.allApps}
					deleteDapps={props.deleteDapps}
					deleteDappData={deleteDappData}
					editDapps={editDapps}
					setSearchKeyword={setSearchKeyword}
					searchKeyword={searchKeyword}
					categoryFilterData={categoryFilterData}
					handleCategoryFilter={handleCategoryFilter}
					updateDappData={updateDappData}
				/>
				<ConfirmDialog
					title="Delete"
					open={open}
					setOpen={setOpen}
					onConfirm={() => props.deleteDapp(dapp._id)}>
					{"application"} {dapp.title}
				</ConfirmDialog>
			</>

			}
			<JoinOrg history={props.history} />

		</AppHeader>
	)
}

const mapStateToProps = (state) => {
	return {
		apps: state.app,
		user: state.user,
		org: state.org,
		login: state.login,
	}
}

const mapDispatchToProps = {
	loadApps,
	getTemplates,
	getAppAndTemplates,
	createApp,
	deleteDapp,
	getCategories,
	clearAppMessage
}

export default hot(connect(mapStateToProps, mapDispatchToProps)(withRouter(AppsDashboard)));

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	btn: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: 15
	},
	circularBar: {
		color: '#ffff',
		height: 20,
		width: 20,
	},
}))
