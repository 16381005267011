import React from 'react'

import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Tooltip, CircularProgress
} from '@material-ui/core'

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import { Helpers } from "dsilo-ui-components";

const RenderRowData = (props) => {
    const { classes, enableActions, tableHeaders, tableRows, tableActions, isLoading, formData, chartUniqueId, selectedStep, block, loader, onTableActionClick, row, rIndex } = props

    return (
        <React.Fragment>
            {tableHeaders?.map((header, index) => {
                const colField = header.name || header.value
                // let colValue = row[colField] || '-'
                // const colValue = Helpers.convertValueWithDataType(row[header.name || header.value], header) || '-'
                let colValue = Helpers.generateValueByDataElementType(row, header) || '-'
                // For now adding static condition to load value from object.
                // In Future write a util function to load value based on header type and we need to use this function in all existing components.
                if (["namepicker", "select"].includes(header.type)) {
                    colValue = typeof colValue === 'object' && colValue !== null 
                        ? colValue.name || '-' 
                        : typeof colValue === 'string'
                        ? colValue || '-' 
                        : '-';
                }
                // console.log("it is colValue ===", colValue, header)
                return (
                    <TableCell
                        key={index}
                        className={classes.tableCellWrapText}
                        style={{
                            padding: '0px !important',
                            borderBottom: 'none'
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            
                            <Typography
                                variant='body1'
                                component='span'
                                // noWrap
                                title={colValue}
                                style={{ fontSize: '14px'}}
                            >
                                {colValue}
                            </Typography>
                        </div>
                    </TableCell>
                )
            })}
            {
                enableActions && <TableCell
                    key={`actions-${rIndex}`}
                    style={{ borderBottom: 'none' }}
                >
                    <div className={classes.tableActions}>
                        {
                            tableActions.edit && <Tooltip title='Edit'>
                                <EditIcon
                                    fontSize={'small'}
                                    className={classes.actionIcon}
                                    onClick={() => onTableActionClick('edit', row)}
                                />
                            </Tooltip>
                        }
                        {
                            tableActions.delete && <Tooltip title='Delete'>
                                {
                                    formData[`${chartUniqueId}_${selectedStep.name}_${block.name}_delete_loading`]
                                        ? <CircularProgress color='#fff' size={24} />
                                        : <DeleteIcon
                                            fontSize={'small'}
                                            className={classes.actionIcon}
                                            onClick={() => onTableActionClick('delete', row)}
                                        />
                                }
                            </Tooltip>
                        }
                        {
                            tableActions.forward && <Tooltip title='Forward To'>
                                {
                                    formData[`${chartUniqueId}_${selectedStep.name}_${block.name}_forward_loading`]
                                        ? <CircularProgress color='#fff' size={24} />
                                        : <SupervisorAccountIcon
                                            fontSize={'small'}
                                            className={classes.actionIcon}
                                            onClick={(event) => onTableActionClick('forward', row, event)}
                                        />
                                }
                            </Tooltip>
                        }
                    </div>
                </TableCell>
            }
        </React.Fragment>
    )
}

export default RenderRowData;