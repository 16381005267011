import { Box, Card, CardActions, CardContent, CardHeader, makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CustomButton from '../components/Button/CustomButton';
import { verifyEmail } from '../store/actions';
import { Link } from 'react-router-dom';
import {PUBLIC_PATH} from 'constants/index'

const VerifyEmail = props => {
  const classes = useStyles();
  useEffect(() => {
    const { token } = props.match.params;
    if (token) {
      props.verifyEmail(props.match.params);
    }
  }, []);

  if (props.login.verifyEmail?.isLoading || props.login.verifyEmail?.error === '') {
    return (
      <Box
        style={{ height: '100vh', color: '#1761b9' }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress size={60} />
        <Box mt={3}>
          <Typography>Verifying Email</Typography>
        </Box>
      </Box>
    );
  } else {
    return (
      <div className="login-sec">
        <Box display="flex" justifyContent="center" alignItems="center" height={1}>
          <Card className={classes.root}>
            <CardHeader
              title={<h3 style={{ color: '#fff' }}>Verification</h3>}
              className={classes.cardHeader}
            />
            <CardContent className={classes.cardContent}>
              {props.login.verifyEmail?.error && (
                <Typography variant="h6">
                  <strong>{props.login.verifyEmail?.error}</strong>
                </Typography>
              )}

              {props.login.verifyEmail?.success && (
                <Typography variant="h6">
                  <strong>{props.login.verifyEmail?.user?.message}</strong>
                </Typography>
              )}
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Link to={`/${PUBLIC_PATH}login`}>
                <CustomButton variant="contained" color="primary">
                  Sign In
                </CustomButton>
              </Link>
            </CardActions>
          </Card>
        </Box>
      </div>
    );
  }
};

const useStyles = makeStyles({
  root: {
    minWidth: 600,
    borderRadius: 10,
  },
  cardHeader: {
    backgroundColor: '#1761b9',
  },
  cardContent: {
    padding: '30px 50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardActions: {
    padding: '20px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
});

const mapDispatchToProps = {
  verifyEmail,
};
const mapStateToProps = state => {
  return {
    login: state.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
