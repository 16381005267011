import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/styles';
import { PUBLIC_PATH } from 'constants/index';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import ConfirmDialog from '../../components/ConfirmDialog';
import InputField from '../../components/InputField/InputFiled';
import Modal from '../../components/Modal/Modal';
import {
    addMember,
    createOrg,
    getOrgDetails,
    loadApps,
    removeMessage,
    setNewUserData,
    setOrgIdInUser
} from '../../store/actions';
import './style.scss';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={2}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const JoinOrg = props => {
    const [open, setOpen] = useState(false);
    const [openModalOrg, setOpenModalOrg] = useState(false);
    const [value, setValue] = useState('internal');
    const [UserData, setUserData] = useState();
    const [modelTab, setModelTab] = useState(0);
    const [orgIdExist, setOrgIdExist] = useState();
    const [name, setName] = useState('');
    const [logo, setLogo] = useState('');
    const [industry, setIndustry] = useState('');
    const [subIndustry, setSubIndustry] = useState('');
    const [emaildomain, setEmailDomain] = useState('');
    const [appTitle, setAppTitle] = useState('rvm');
    const [appSubTitle, setAppSubTitle] = useState('rvm app');
    const [description, setDescription] = useState('');
    const [primaryContactName, setPrimaryContactName] = useState('');
    const [primaryContactEmail, setPrimaryContactEmail] = useState('');
    const [primaryContactPhone, setPrimaryContactPhone] = useState('');
    const [secondaryContactName, setSecondaryContactName] = useState('');
    const [secondaryContactEmail, setSecondaryContactEmail] = useState('');
    const [secondaryContactPhone, setSecondaryContactPhone] = useState('');
    const [status, setStatus] = useState('');
    const [active, setActive] = useState('');
    const [orgId, setOrgId] = useState('');
    const [appId, setAppId] = useState('');
    const [joinOrg, setJoinOrg] = useState(false);
    const [newConversational, setNewConversational] = useState({
        appName: '',
        ownerName: '',
        companyName: '',
        scope: 'internal',
        type: '',
        businessType: '',
        desc: '',
    });
    const classes = useStyles();
    const { history } = props;
    useEffect(() => {
        console.log("useruseruseruser", props.user)
        if (
            !props.user.orgId &&
            !props.org?.getOrgDetails?.isLoading &&
            props.org?.getOrgDetails.org &&
            Object.keys(props.org?.getOrgDetails?.org).length > 0
        ) {
            const {
                _id,
                appId,
                name,
                description,
                industry,
                subIndustry,
                emailDomain,
                active,
                status,
                appTitle,
                appSubTitle,
                primaryContactName,
                primaryContactEmail,
                primaryContactPhone,
                secondaryContactName,
                secondaryContactEmail,
                secondaryContactPhone,
            } = props.org.getOrgDetails?.org;
            console.log('values====', name, description);
            setOrgId(_id);
            setAppId(appId);
            setName(name);
            setDescription(description);
            setIndustry(industry);
            setSubIndustry(subIndustry);
            setEmailDomain(emailDomain);
            setActive(active);
            setStatus(status);
            setAppTitle(appTitle);
            setAppSubTitle(appSubTitle);
            setPrimaryContactName(primaryContactName);
            setPrimaryContactEmail(primaryContactEmail);
            setPrimaryContactPhone(primaryContactPhone);
            setSecondaryContactName(secondaryContactName);
            setSecondaryContactEmail(secondaryContactEmail);
            setSecondaryContactPhone(secondaryContactPhone);
            setJoinOrg(true)
            setOpenModalOrg(true);
        } else if (props.user.isAuthenticated && !props.user.orgId && !props.org?.getOrgDetails.org) {
            setOpenModalOrg(true);
        } else {
            if (openModalOrg) {
                setOpenModalOrg(false);
            }
        }

        console.log('props.org.getOrgDetails', props.org.getOrgDetails, openModalOrg, !props.org?.getOrgDetails.org);
    }, [props.org.getOrgDetails, props.user]);

    useEffect(() => {
        if (props.org?.addMember?.success) {
            setOpenModalOrg(false);
            if (props.org.addMember.org && props.org.addMember.org.orgDetails._id) {
                let orgId = props.org.addMember.org.orgDetails._id;
                props.setOrgIdInUser(orgId);
                // props.loadApps(orgId);
            }
        }
    }, [props.org.addMember.success]);


    useEffect(() => {
        if (props.org.org && props.org.org.data && props.org.org.data.orgId) {
            setOpenModalOrg(false);
            let orgId = props.org.org.data.orgId;
            props.setOrgIdInUser(orgId);
            history.push({ pathname: `/${PUBLIC_PATH}apps` });
            // props.loadApps(orgId);
        }
    }, [props.org]);

    const handleChangeModel = (event, newValue) => {
        setModelTab(newValue);
    };

    useEffect(() => {
        console.log("UE getOrgDetails", props.user, props.user._id, !props.user.orgId);
        if (props.user._id && !props.user.orgId) {
            props.getOrgDetails();
        }
    }, [props.user]);

    // useEffect(() => {
    //   if (props.app) {
    //     const rvmApp = (props.app && props.app.app && props.app.app.data) ?
    //       props.app.app.data.find(app => (app.type?app.type.toLowerCase() === 'rvm':false)) : null;
    //     if (rvmApp) {
    //       const newUser = { ...props.user, app: rvmApp };
    //       localStorage.setItem('jwtuserData', JSON.stringify(newUser));
    //       props.setNewUserData(newUser);
    //       if (newUser.app._id) {
    //         history.push({ pathname: `/${PUBLIC_PATH}${newUser.app._id}/rvm-dashboard` });
    //       } else {
    //         history.push(`/${PUBLIC_PATH}login`);
    //       }
    //     } else {
    //       console.log('app not found ');
    //     }
    //   }
    // }, [props.app]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleConversationalChange = e => {
        setNewConversational({ ...newConversational, [e.target.name]: e.target.value });
    };
    const conversationalSubmit = () => {
        console.log('data', newConversational);
    };
    const canSubmit = () => {
        if (
            name !== '' &&
            industry !== '' &&
            //   subIndustry !== '' &&
            // description !== '' &&
            primaryContactName !== '' &&
            primaryContactEmail !== '' &&
            primaryContactPhone !== '' &&
            emaildomain !== ''
            // secondaryContactName !== '' &&
            // secondaryContactEmail !== '' &&
            // secondaryContactPhone
        ) {
            return true;
        }
        return false;
    };

    const handleSubmit = () => {
        console.log(`${joinOrg ? 'Join Org' : 'Setup Org'}`);
        let data = {
            name,
            logo,
            industry,
            emailDomain: emaildomain,
            description,
            primaryContactName,
            primaryContactEmail,
            primaryContactPhone,
            secondaryContactName,
            secondaryContactEmail,
            secondaryContactPhone,
            subIndustry: '',
        };
        if (joinOrg) {
            let tempData = {
                ...data,
                appId,
                orgId,
            };
            console.log('submti join org', tempData);
            props.addMember(tempData);
        } else {
            console.log('submti setup org');
            data.type = 'ciep';
            console.log('data', data);
            props.createOrg(data);
        }
    };
    const { loading } = props.org;
    console.log('openModalOrg ==', openModalOrg);
    return (
        <div className='dashboard'>
            <Modal
                open={openModalOrg}
                //   onClose={() => setOpenModalOrg(false)}
                fullWidth
                maxWidth='md'
            >
                <DialogTitle>
                    <div className={classes.dialogTitle}>{`${joinOrg ? 'Join' : 'Setup'} `} Organization</div>
                </DialogTitle>
                <Tabs value={modelTab} indicatorColor='primary' onChange={handleChangeModel}>
                    <Tab label='Basic Info' id={0} />
                    <Tab label='Contact Info' id={1} />
                </Tabs>

                <div>
                    <DialogContent dividers>
                        <TabPanel value={modelTab} index={0}>
                            <Grid container spacing={3} className={classes.gridAlign}>
                                <Grid item xs={6}>
                                    <InputField
                                        placeholder='Name'
                                        label='Company Name'
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        asterisk={true}
                                        required
                                        disabled={joinOrg}
                                        asterisk={joinOrg ? false : true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {/* <input
                        accept="image/*"
                        className={`${classes.input} } `}
                        onChange={e => setLogo(e.target.files[0])}
                        id="upload-logo"
                        multiple
                        type="file"
                      />
                         */}
                                    <span className={classes.imageContainer}>
                                        {props.user && props.user.OrgImage ? (
                                            <Avatar
                                                alt='Org Image'
                                                src={props.user.OrgImage}
                                                className={classes.orgImage}
                                            />
                                        ) : (
                                            <AccountCircleIcon className={classes.orgImage} />
                                        )}
                                    </span>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} className={classes.gridAlign}>
                                <Grid item xs={6}>
                                    <InputField
                                        placeholder='Industry'
                                        label='Industry'
                                        value={industry}
                                        onChange={e => setIndustry(e.target.value)}
                                        required
                                        disabled={joinOrg}
                                        asterisk={joinOrg ? false : true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputField
                                        placeholder='Email Domain'
                                        label='Email Domain '
                                        value={emaildomain}
                                        onChange={e => setEmailDomain(e.target.value)}
                                        disabled={joinOrg}
                                        asterisk={joinOrg ? false : true}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            {/* <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <InputField
                        placeholder='Enter Description'
                        label='Description'
                        multiline
                        rows={4}
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        required
                        disabled={joinOrg}
                      />
                    </Grid>
                  </Grid> */}
                        </TabPanel>

                        <TabPanel value={modelTab} index={1}>
                            <Grid container spacing={3} className={classes.gridAlign}>
                                <Grid item xs={6}>
                                    <InputField
                                        placeholder='Primary Contact Name'
                                        label='Primary Contact Name'
                                        value={primaryContactName}
                                        onChange={e => setPrimaryContactName(e.target.value)}
                                        required
                                        disabled={joinOrg}
                                        asterisk={joinOrg ? false : true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputField
                                        placeholder='Primary Contact Email'
                                        label='Primary Contact Email'
                                        value={primaryContactEmail}
                                        onChange={e => setPrimaryContactEmail(e.target.value)}
                                        required
                                        disabled={joinOrg}
                                        asterisk={joinOrg ? false : true}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} className={classes.gridAlign}>
                                <Grid item xs={6}>
                                    <InputField
                                        placeholder='Primary Contact Phone'
                                        label='Primary Contact Phone'
                                        value={primaryContactPhone}
                                        onChange={e => setPrimaryContactPhone(e.target.value)}
                                        required
                                        disabled={joinOrg}
                                        asterisk={joinOrg ? false : true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputField
                                        placeholder='Secondary Contact Name'
                                        label='Secondary Contact Name'
                                        value={secondaryContactName}
                                        onChange={e => setSecondaryContactName(e.target.value)}
                                        required
                                        disabled={joinOrg}
                                        asterisk={false}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} className={classes.gridAlign}>
                                <Grid item xs={6}>
                                    <InputField
                                        placeholder='Secondary Contact Email'
                                        label='Secondary Contact Email'
                                        value={secondaryContactEmail}
                                        onChange={e => setSecondaryContactEmail(e.target.value)}
                                        required
                                        disabled={joinOrg}
                                        asterisk={false}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputField
                                        placeholder='Secondary Contact Phone'
                                        label='Secondary Contact Phone'
                                        value={secondaryContactPhone}
                                        onChange={e => setSecondaryContactPhone(e.target.value)}
                                        required
                                        disabled={joinOrg}
                                        asterisk={false}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </DialogContent>
                    <DialogActions
                        classes={{ root: classes.actionBottomChallenges }}
                        className='dialogActions'
                    >
                        <Box mb={2}>
                            {props.org.error && <p className={classes.errorMessage}>{`*${props.org.error}`}</p>}
                        </Box>

                        {joinOrg && (
                            <Box mb={2}>
                                {props.org.addMember.error && (
                                    <p className={classes.errorMessage}>{`*${props.org.addMember.error}`}</p>
                                )}
                            </Box>
                        )}

                        <Button
                            type='submit'
                            color='primary'
                            variant='contained'
                            disableElevation
                            classes={{ label: classes.submitButton }}
                            onClick={handleSubmit}
                            style={{ width: '150px' }}
                            disabled={joinOrg ? false : !canSubmit()}
                        >
                            {joinOrg ? (
                                props.org.addMember.loading ? (
                                    <CircularProgress className={classes.circularBar} size={23} />
                                ) : (
                                    'Join'
                                )
                            ) : loading ? (
                                <CircularProgress className={classes.circularBar} size={23} />
                            ) : (
                                'Save & Continue'
                            )}
                        </Button>
                    </DialogActions>
                </div>
            </Modal>
            {
                props.org.error &&
                <ConfirmDialog open={true} handleClose={() => props.removeMessage()}
                    alertMessageText={props.org.error} classes={classes} />
            }
        </div>
    );
};

const useStyles = makeStyles({
    icon: {
        padding: '0 5px',
    },
    body: {
        padding: '1.5em 0',
    },
    bodyTitle: {
        padding: '20px 0 ',
    },
    dialogTitle: {
        display: 'flex',
        color: '#000',
        alignItems: 'center',
    },
    radioGroup: { flexDirection: 'row' },
    actionBottom: {
        padding: '16px 24px',
    },
    actionBottomChallenges: {
        padding: '16px 24px',
    },
    radioLabel: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    radioValueLabel: {
        fontWeight: 'bold',
        color: '#5B5B60',
    },
    newAppLogo: { marginRight: 15, height: 25 },
    gridAlign: {
        marginBottom: 15,
    },
    circularBar: {
        color: '#ffff',
        height: 20,
        width: 20,
    },
    input: {
        display: 'none',
    },
    inputLabel: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    inputButton: {
        padding: 8,
        textTransform: 'capitalize',
    },
    errorMessage: {
        color: '#ff0000',
        fontSize: '14px',
    },
    submitButton: { paddingTop: 2, fontWeight: 'bold', textTransform: 'capitalize' },
    disabledInput: {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
    orgImage: {
        height: '60px',
        width: '60px',
        color: '#d3d3d3',
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '10px',
    },
});

const mapDispatchToProps = {
    createOrg,
    getOrgDetails,
    addMember,
    setNewUserData,
    loadApps,
    setOrgIdInUser,
    removeMessage,
};
const mapStateToProps = state => {
    return {
        login: state.login,
        user: state.user,
        org: state.org,
        app: state.app,
    };
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(JoinOrg));
