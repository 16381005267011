import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Button, Card, CardContent, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getDateTime } from '../ChatHelper';

const FeedbackDialog = props => {
  const [feedback, setFeedback] = useState({});
  const [selectedValues, setSelectedValues] = useState(() => {
    const initialSelectedValues = {};

    if (props.panels) {
      props.panels.forEach(panel => {
        initialSelectedValues[panel.id] = '';
      });
    }

    return initialSelectedValues;
  });

  const [openPanel, setOpenPanel] = useState(''); // Add this state to keep track of open panel

  const handleChange = panelId => event => {
    console.log('FEEDBACK CHOISES:::___', event.target.value, panelId);
    setSelectedValues(prevValues => ({
      ...prevValues,
      [panelId]: event.target.value,
    }));
  };

  const handleClose = () => {
    console.log('User feedback:', feedback);
    console.log('userData : ', props.chartData);
    props.onClose();
  };

  const handleSubmit = () => {
    console.log('User feedback:', feedback);
    console.log('userData : ', props.chartData);
    props.onSubmitFeedback(feedback);
    setFeedback({});
    // props.onClose();
  };

  const handleFeedbackChange = fieldId => event => {
    const value = event.target.value;
    setFeedback(prevFeedback => ({
      ...prevFeedback,
      [fieldId]: value,
      questionId: fieldId
    }));
  };

  const handlePanelChange = panel => (event, isExpanded) => {
    setOpenPanel(isExpanded ? panel : false);
  };
  const dialogStyle = {
    minHeight: props.dialogHeight || '300px', // defaults to '300px' if dialogHeight is not provided
    minWidth: props.dialogWidth || '300px', // defaults to '300px' if dialogWidth is not provided
    maxWidth: props.dialogWidth || '600px', // defaults to '600px' if dialogWidth is not provided
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={false}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
        },
      }}
    >
      <DialogTitle id="form-dialog-title" className="dialog-title">
        {props.dialogTitle}
      </DialogTitle>
      <DialogContent style={dialogStyle}>
        {props?.panels && Array.isArray(props?.panels) && props?.panels?.length > 0
          ? props.panels.map(panel => (
              <Accordion
                expanded={openPanel === panel.id}
                onChange={handlePanelChange(panel.id)}
                key={panel.id}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="dialog-header-colour" />}
                  aria-controls="panel1a-content"
                  className="dialog-header-colour"
                  id="panel1a-header"
                >
                  <Typography>{panel.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RadioGroup value={selectedValues[panel.id]} onChange={handleChange(panel.id)}>
                    {panel.options.map(option => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </AccordionDetails>
              </Accordion>
            ))
          : null}

        {props.dialogTitle === 'Comments' ? (
          props?.loading ? (
            <CircularProgress color="#fff" size={20} />
          ) : !props?.previousComments || props?.previousComments?.length === 0 ? (
            <Box m={1}>
              <Typography>No comments yet. Please comment.</Typography>
            </Box>
          ) : (
            props?.previousComments?.map((comment, index) => (
              <Card key={index} className="commentCard">
                <CardContent>
                  <Typography className="commentText">{comment.comment}</Typography>
                  <Typography className="commentBy" style={{ fontSize: 12 }}>
                    Commented by: {comment?.createdBy?.name} {comment?.createdAt ? `, ${getDateTime(comment?.createdAt)}` : ''}
                  </Typography>
                </CardContent>
              </Card>
            ))
          )
        ) : null}

        <Card className="commentCard">
          {props?.fields && Object.keys(props?.fields).length > 0 ? (
            <Box m={1} key={props?.fields?.questionId}>
              <DialogContentText>{props?.fields?.title}</DialogContentText>
              <TextField
                autoFocus
                key={props?.fields?.questionId}
                margin="dense"
                id={props?.fields?.questionId}
                value={feedback?.[feedback?.questionId] || ''}
                type="text"
                fullWidth
                placeholder='Enter your comment here'
                onChange={handleFeedbackChange(props?.fields?.questionId)}
              />
            </Box>
          ) : null}
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={props?.commentCreating}>
          {props?.commentCreating ? <CircularProgress color="#fff" size={20} /> : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
