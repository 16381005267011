import React, { useEffect, useState } from 'react'

import {
    Box, Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Grid,
    Typography, CircularProgress
} from '@material-ui/core'
import RenderFieldBasedOnType from '../../RenderFieldsBasedOnType'

import { handleCommonMasterDataConfigOnLoad, updateCommonMasterDataResponseToComponent } from 'dsilo-ui-components'

/* For now It is static */
const defaultOptions = {

}

const SubDataModelModal = (props) => {
    const { appId, members, classes, toggleCompareModal, modalInfo, block, formHeaders, onSaveHandler, currentOpportunityId, selectedStep, chartUniqueId, formData, loader, dataModel } = props
    const { document } = formData

    // const inputRef = React.useRef(null)

    let payloadForMasterData = {
        docId: currentOpportunityId,
        appId: appId,
        dataUniqueId: chartUniqueId,
        dmId: block.dataModel,
    }

    const [modalFormState, setModalFormState] = useState({})
    const [modifiedFields, setModifiedFields] = useState({})
    const [selectedId, setSelectedId] = useState('')

    const [commonMasterDataConfigData, setCommonMasterDataConfigData] = useState(null)
    const [commonMasterDataConfiguredElements, setCommonMasterDataConfiguredElements] = useState([])
    const [commonMasterDataFullDropDownKeys, setCommonMasterDataFullDropDownKeys] = useState([])

    const [masterDataStoreObject, setMasterDataStoreObject] = useState({})

    const [commentTypeFieldState, setCommentTypeFieldState] = useState("")


    useEffect(() => {
        if (modalInfo.enable) {
            if (modalInfo.type === 'edit' && modalInfo.data?.id) {
                setSelectedId(modalInfo.data.id)
                getDataById(modalInfo.data.id)
            }
        }
    }, [modalInfo])

    useEffect(() => {
        console.log("commonMasterData handleCommonMasterDataConfigOnLoad", props.viewMode, formHeaders)
        if (!props.viewMode && formHeaders.length > 0 && dataModel) {
            console.log("commonMasterData It is before handling handleCommonMasterDataConfigOnLoad")
            handleCommonMasterDataConfigOnLoad(formHeaders, dataModel, {}, setCommonMasterDataConfigData, setCommonMasterDataConfiguredElements, payloadForMasterData, props.getModelData, props.fetchmasterDataForDataelementUsingRules, '', props.formData)
        }
    }, [props.viewMode, dataModel])

    useEffect(() => {
        console.log("commonMasterData commonMasterDataConfigData", commonMasterDataConfigData, commonMasterDataConfiguredElements)
    }, [commonMasterDataConfigData, commonMasterDataConfiguredElements])

    useEffect(() => {
        if (!props.viewMode && commonMasterDataConfigData) {
            updateCommonMasterDataResponseToComponent(props, false, commonMasterDataConfigData, commonMasterDataFullDropDownKeys, chartUniqueId, document, '', modalFormState, [], modifiedFields, masterDataStoreObject, setModalFormState, null, null, null, null, setModifiedFields, setMasterDataStoreObject)
        }
    }, [JSON.stringify(document)])

    useEffect(() => {
        if (document?.[`${chartUniqueId}_${selectedStep.name}_${block.name}_create`]) {
            props.clearReduxDataOfCurrentComponent({
                key: 'document',
                subKey: [`${chartUniqueId}_${selectedStep.name}_${block.name}_create`]
            })
            onSaveHandler()
        }
    }, [document?.[`${chartUniqueId}_${selectedStep.name}_${block.name}_create`]])

    useEffect(() => {
        if (document?.[`${chartUniqueId}_${selectedStep.name}_${block.name}_${selectedId}_data`]) {
            let d = document?.[`${chartUniqueId}_${selectedStep.name}_${block.name}_${selectedId}_data`]
            console.log("it is selected action data ===", d)
            setModalFormState(d);
        }
    }, [document?.[`${chartUniqueId}_${selectedStep.name}_${block.name}_${selectedId}_data`]])

    useEffect(() => {
        if (document?.[`${chartUniqueId}_${selectedStep.name}_${block.name}_update`]) {
            props.clearReduxDataOfCurrentComponent({
                key: 'document',
                subKey: [`${chartUniqueId}_${selectedStep.name}_${block.name}_update`]
            })
            onSaveHandler()
        }
    }, [document?.[`${chartUniqueId}_${selectedStep.name}_${block.name}_update`]])

    const getDataById = (id) => {
        let payload = {
            appId: appId,
            dataModelId: block.mainDataModel,
            subDataModelId: block.dataModel,
            opportunityId: currentOpportunityId,
            actionId: id,
            dataUniqueId: `${chartUniqueId}_${selectedStep.name}_${block.name}_${id}_data`,
        }
        props.getSingleActionOfOpportunity(payload)
    }

    const handleOnChange = (name, value, undefined, uiFieldType, header, valueObj) => {
        console.log("Action Comment handle Changes ==>>> ", name, value, undefined, uiFieldType, header, valueObj);
        let v = value;
        if (header?.type === "namepicker" && valueObj) {
            v = {
                id: valueObj?._id,
                name: valueObj?.name
            }
        }
        if(header?.type == "comments"){
            // v = {
            //     // datetime contains when the comment was created
            //     datetime: new Date().toISOString(),
            //     // comment contains the comment text
            //     comment: value,
            //     // by contains who created the comment
            //     by: {
            //         "id": members.userId,
            //         "name": members.name
            //     },
            //     label: ""
            // }
          
           setCommentTypeFieldState({
            ...commentTypeFieldState,
            [header.value || header.name]: v
           })
        //    setModalFormState({
        //         ...modalFormState,
        //         [name]: value,
        //         [header.value || header.name]: [v]
        //     })
        //     setModifiedFields({
        //         ...modifiedFields,
        //         [header.value || header.name]: [v]
        //     })
        } else {
            setModalFormState({
                ...modalFormState,
                [name]: v
            })
            setModifiedFields({
                ...modifiedFields,
                [name]: v
            })
        }
    }

    const onSave = () => {
        let obj = JSON.parse(JSON.stringify(modalFormState))
        // let obj = JSON.parse(JSON.stringify(modifiedFields))
        if(commentTypeFieldState && Object.keys(commentTypeFieldState)){
            Object.keys(commentTypeFieldState).forEach(key => {

                console.log("Keys  ==>>> ", key, commentTypeFieldState[key]);
                let v = {
                    // datetime contains when the comment was created
                    datetime: new Date().toISOString(),
                    // comment contains the comment text
                    comment: commentTypeFieldState[key],
                    // by contains who created the comment
                    by: {
                        "id": members.userId,
                        "name": members.name
                    },
                    label: ""
                }
                if(obj[key]){
                    obj[key] = [...obj[key], v]
                }else{
                    obj[key] = [v]
                }
            })
        }
        obj["opportunityId"] = currentOpportunityId
        console.log("it is object ===", obj, modifiedFields)
        let payload = {
            appId: props.appId,
            dataModelId: block.mainDataModel,
            subDataModelId: block.dataModel,
            opportunityId: currentOpportunityId,
            data: obj
        }
        console.log("it is modalInfo.type", modalInfo.type)
        if (modalInfo.type === 'create') {
            payload['dataUniqueId'] = `${chartUniqueId}_${selectedStep.name}_${block.name}_create`
            props.createActionForOpportunity(payload)
        } else if (modalInfo.type === 'edit') {
            payload['dataUniqueId'] = `${chartUniqueId}_${selectedStep.name}_${block.name}_update`
            payload['actionId'] = selectedId
            payload['data'] = obj ? {...modifiedFields, ...obj} : modifiedFields
            props.updateSingleActionOfOpportunity(payload)
        }
        console.log("\n\n\n Action Payload creation or updation ====>>>>>>> ", payload);
    }

    const commentsPayload = (label) => {
        let obj = {};

        obj[label] = JSON.parse(JSON.stringify(modalFormState[label]))
        console.log("Obj comment clcik  ==>> ", obj);
        
        if(commentTypeFieldState && Object.keys(commentTypeFieldState)){
            Object.keys(commentTypeFieldState).forEach(key => {

                console.log("Keys  ==>>> ", label, commentTypeFieldState[label]);
                let v = {
                    // datetime contains when the comment was created
                    datetime: new Date().toISOString(),
                    // comment contains the comment text
                    comment: commentTypeFieldState[label],
                    // by contains who created the comment
                    by: {
                        "id": members.userId,
                        "name": members.name
                    },
                    label: ""
                }
                if(obj[label]){
                    obj[label] = [...obj[label], v]
                }else{
                    obj[label] = [v]
                }
            })
        }

        return obj
    }
    
    const addCommentClick = (label, key) => {
        console.log("Data from Comment box ===>>> ", label, key);
        // send only the new comment added along with the old comments already exist.
        let obj = commentsPayload(key)

        obj["opportunityId"] = currentOpportunityId
        console.log("it is object ===", obj, modifiedFields)
        let payload = {
            appId: props.appId,
            dataModelId: block.mainDataModel,
            subDataModelId: block.dataModel,
            opportunityId: currentOpportunityId,
            data: obj
        }
        console.log("it is modalInfo.type", modalInfo.type)
        
        payload['dataUniqueId'] = `${chartUniqueId}_${selectedStep.name}_${block.name}_update`
        payload['actionId'] = selectedId
        
        console.log("it is payload to update updateOpportunity api Comments click ===>>>", payload)

        props.updateSingleActionOfOpportunity(payload)

    }

    return (
        <Dialog
            open={modalInfo.enable}
            fullWidth={true}
            // fullScreen={true}
            maxWidth={'md'}
            onClose={toggleCompareModal}
            aria-labelledby='comparision-dialog-title'
            className={classes.muiDailogPaper}
        >
            <DialogTitle id='customized-dialog-title' onClose={toggleCompareModal}>
                {modalInfo?.title || 'Action'}
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={1}>
                    {
                        formData[`${chartUniqueId}_${selectedStep.name}_${block.name}_${selectedId}_data_loading`]
                            ? loader
                            : formHeaders?.length > 0
                                ? formHeaders.map((element, index) => {
                                    let widthInXs = 3
                                    if (element.xs) {
                                        widthInXs = element.xs
                                    } else {
                                        let w = 12 / formHeaders.length;
                                        if (w > 3) {
                                            widthInXs = w
                                        }
                                    }
                                    return (
                                        <Grid item key={index} xs={widthInXs}
                                            style={{ marginTop: "2px", marginBottom: "2px" }}
                                        >
                                            <Box style={{ width: "100%" }}>
                                                <Grid container
                                                    style={{ display: "grid", gridTemplateRows: "auto auto" }}
                                                >
                                                    <div>
                                                        <Typography
                                                            component='span'
                                                            style={{ fontWeight: 'bold', fontSize: '14px' }}
                                                        >
                                                            {element.label}
                                                        </Typography>
                                                    </div>
                                                    <div style={{ marginTop: "4px", width: "100%" }}>
                                                        {console.log("\n\n modalFormState ===>>> ", modalFormState, "\n\n\n modifiedFields ====>>>> ",modifiedFields, "\n\n commentTypeFieldState ==>>>", commentTypeFieldState)}
                                                        <RenderFieldBasedOnType
                                                            {...props}
                                                            classes={classes}
                                                            element={element}
                                                            onChange={handleOnChange}
                                                            dataObj={modalFormState}
                                                            commentTypeFieldState={commentTypeFieldState}
                                                            options={defaultOptions[element.value || element.name]}
                                                            masterDataStoreObject={masterDataStoreObject}
                                                            hidePostButton={modalInfo.type === 'edit' ? false : true}
                                                            addCommentClick={addCommentClick}

                                                        />
                                                    </div>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )
                                })
                                : <Typography>{`No Fields are Configured`}</Typography>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color={'primary'}
                    onClick={() => onSave()}
                >
                    {
                        formData[`${chartUniqueId}_${selectedStep.name}_${block.name}_create_loading`] || formData[`${chartUniqueId}_${selectedStep.name}_${block.name}_update_loading`]
                            ? <CircularProgress color='#fff' size={24} />
                            : "Save"
                    }
                </Button>
                <Button
                    color='inherit'
                    onClick={toggleCompareModal}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SubDataModelModal