import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { URLs } from '../../modules/appConfig.module';
import { getData } from '../../store/actions/action';
import LabelColorBox from '../InvoiceDashboard/LabelColorBox';
import BarChart from './barChart';
import MultiLabelTile from './multiLabelTile';
import { numFormatter } from '../../modules/common'
export const colors = ["#5dd39a", "#ff7b33", "#2d63b6", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"]





let options2 = {
    chart: {
        type: 'column'
    },
    credits: {
        enabled: false
    },
    title: {
        text: 'Spend with discount by vendor'
    },
    xAxis: {
        categories: ['Vendor 1', 'Vendor 2', 'Vendor 3', 'Vendor 4', 'Vendor 5', 'Vendor 6', 'Vendor 7', 'Vendor 8', 'Vendor 9', , 'Vendor 10', , 'Vendor 11', 'Vendor 12',]
    },

    plotOptions: {
        series: {
            pointWidth: 20
        }
    },

    series: [{
        data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
        color: '#2d63b6'

    }],
    lang: {
        noData: "No Data Available"
    },
    noData: {
        style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#000'
        }
    },
}

const pietaxPercent = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: 'Tax % by vendor'
    },
    credits: {
        enabled: false
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            colors: colors,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
        }
    },
    series: [{
        name: 'Brands',
        colorByPoint: true,
        data: [{
            name: 'FreightLine',
            y: 61.41,
            sliced: true,
            selected: true
        }, {
            name: 'HANSAN COMPANY HANSAN',
            y: 11.84
        }, {
            name: 'FRAGOMEN',
            y: 10.85
        }, {
            name: 'FERGUSON',
            y: 4.67
        }, {
            name: 'DONSCO R',
            y: 4.18
        }, {
            name: 'MAGOTTEAUX',
            y: 1.64
        }, {
            name: 'Microsoft',
            y: 1.6
        }, {
            name: 'NORGREN',
            y: 1.2
        }, {
            name: 'Other',
            y: 2.61
        }]
    }],
    lang: {
        noData: "No Data Available"
    },
    noData: {
        style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#000'
        }
    },
}
const pietaxPercent1 = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    credits: {
        enabled: false
    },
    title: {
        text: 'Spend by items'
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            colors: colors,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
        }
    },
    series: [{
        name: 'Brands',
        colorByPoint: true,
        data: [{
            name: 'JAS Forwarding Worldwide',
            y: 61.41,
            sliced: true,
            selected: true
        }, {
            name: 'Earle, Jim',
            y: 11.84
        }, {
            name: 'Chris Johnson',
            y: 10.85
        }, {
            name: 'Breedlove, Joshua R',
            y: 4.67
        }, {
            name: 'Joshua A. Staley',
            y: 4.18
        }, {
            name: 'Kenneth R. Back',
            y: 1.64
        }, {
            name: 'Michael Kostecky',
            y: 1.6
        }, {
            name: 'Varner, John M',
            y: 1.2
        }, {
            name: 'Other',
            y: 2.61
        }]
    }],
    lang: {
        noData: "No Data Available"
    },
    noData: {
        style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#000'
        }
    },
}




let stackChartOption1 = {
    credits: {
        enabled: false
    },
    chart: {
        type: 'pie',
    },
    title: {
        text: 'Vendor with highest and Lowest no of invoices',
    },
    xAxis: {
        categories: ['Vendor 1, vendor 2']
    },
    yAxis: {
        min: 0,
        title: {
            text: undefined
        }
    },
    legend: {
        reversed: true
    },
    colors: colors,
    plotOptions: {
        series: {
            stacking: 'normal'
        }
    },
    series: [
        {
            name: 'Invoice Amount',
            data: [{
                name: 'ANIXTER',
                y: 10000,
            }, {
                name: 'KAMAN',
                y: 15000
            }]
        },

    ],
    lang: {
        noData: "No Data Available"
    },
    noData: {
        style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#000'
        }
    },
}

const invoiceAge = [
    {
        label: 'Min',
        value: 3,
    },
    {
        label: 'Max',
        value: 8,
    },
    {
        label: 'Avg',
        value: 13,
    },
]

const vendorWithHighestLowest = [
    {
        label: 'Vendor 1',
        value: 33,
    },
    {
        label: 'Vendor 2',
        value: 8,
    }
]

const options4Tree = {
    series: [{
        type: "treemap",
        layoutAlgorithm: 'stripes',
        alternateStartingDirection: true,
        levels: [{
            level: 1,
            layoutAlgorithm: 'sliceAndDice',
            dataLabels: {
                enabled: true,
                align: 'left',
                verticalAlign: 'top',
                style: {
                    fontSize: '15px',
                    fontWeight: 'bold'
                }
            }
        }],
        data: [
            {
                id: 'A',
                name: 'LA',
                color: "#2d63b6"

            }, {
                id: 'B',
                name: 'Newyork',
                color: "#ECE100"
            }, {
                id: 'O',
                name: 'Ohio',
                color: '#EC9800'
            }, {
                name: 'Texas',
                parent: 'A',
                value: 5
            }, {
                name: 'Miami',
                parent: 'A',
                value: 3
            }, {
                name: 'Chicago',
                parent: 'A',
                value: 4
            }, {
                name: 'Montana',
                parent: 'B',
                value: 4
            }, {
                name: 'Virginia',
                parent: 'B',
                value: 10
            }, {
                name: 'Alaska',
                parent: 'B',
                value: 1
            }, {
                name: 'Washington',
                parent: 'O',
                value: 1
            }, {
                name: 'Texas',
                parent: 'O',
                value: 3
            }, {
                name: 'Florida',
                parent: 'O',
                value: 3
            }, {
                name: 'Bostan',
                parent: 'Kiwi',
                value: 2,
                color: '#9EDE00'
            }]
    }],
    title: {
        text: 'Spend by location'
    },
    credits: {
        enabled: false
    },
    lang: {
        noData: "No Data Available"
    },
    noData: {
        style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#000'
        }
    },
}
const optionsPackedbubblechart = {
    chart: {
        type: 'packedbubble',
        height: '100%'
    },
    title: {
        text: 'Carbon emissions around the world (2014)'
    },
    tooltip: {
        useHTML: true,
        pointFormat: '<b>{point.name}:</b> {point.value}m CO<sub>2</sub>'
    },
    plotOptions: {
        colors: colors,
        packedbubble: {
            minSize: '30%',
            maxSize: '120%',
            zMin: 0,
            zMax: 1000,
            layoutAlgorithm: {
                splitSeries: false,
                gravitationalConstant: 0.02
            },
            dataLabels: {
                enabled: true,
                format: '{point.name}',
                filter: {
                    property: 'y',
                    operator: '>',
                    value: 250
                },
                style: {
                    color: 'black',
                    textOutline: 'none',
                    fontWeight: 'normal'
                }
            }
        }
    },
    series: [
        {
            name: 'Europe',
            data: [{
                name: 'Germany',
                value: 767.1
            }, {
                name: 'Croatia',
                value: 20.7
            },
            {
                name: "Belgium",
                value: 97.2
            },
            {
                name: "Czech Republic",
                value: 111.7
            },
            {
                name: "Netherlands",
                value: 158.1
            },
            {
                name: "Spain",
                value: 241.6
            },
            {
                name: "Ukraine",
                value: 249.1
            },
            {
                name: "Poland",
                value: 298.1
            },
            {
                name: "France",
                value: 323.7
            },
            {
                name: "Romania",
                value: 78.3
            },
            {
                name: "United Kingdom",
                value: 415.4
            }, {
                name: "Turkey",
                value: 353.2
            }, {
                name: "Italy",
                value: 337.6
            },
            {
                name: "Greece",
                value: 71.1
            },
            {
                name: "Austria",
                value: 69.8
            },
            {
                name: "Belarus",
                value: 67.7
            },
            {
                name: "Serbia",
                value: 59.3
            },
            {
                name: "Finland",
                value: 54.8
            },
            {
                name: "Bulgaria",
                value: 51.2
            },
            {
                name: "Portugal",
                value: 48.3
            },
            {
                name: "Norway",
                value: 44.4
            },
            {
                name: "Sweden",
                value: 44.3
            },
            {
                name: "Hungary",
                value: 43.7
            },
            {
                name: "Switzerland",
                value: 40.2
            },
            {
                name: "Denmark",
                value: 40
            },
            {
                name: "Slovakia",
                value: 34.7
            },
            {
                name: "Ireland",
                value: 34.6
            },
            {
                name: "Croatia",
                value: 20.7
            },
            {
                name: "Estonia",
                value: 19.4
            },
            {
                name: "Slovenia",
                value: 16.7
            },
            {
                name: "Lithuania",
                value: 12.3
            },
            {
                name: "Luxembourg",
                value: 10.4
            },
            {
                name: "Macedonia",
                value: 9.5
            },
            {
                name: "Moldova",
                value: 7.8
            },
            {
                name: "Latvia",
                value: 7.5
            },
            {
                name: "Cyprus",
                value: 7.2
            }], color: '#' + (Math.random().toString(16) + '00000').slice(2, 8)
        }, {
            name: 'Africa',
            data: [{
                name: "Senegal",
                value: 8.2
            },
            {
                name: "Cameroon",
                value: 9.2
            },
            {
                name: "Zimbabwe",
                value: 13.1
            },
            {
                name: "Ghana",
                value: 14.1
            },
            {
                name: "Kenya",
                value: 14.1
            },
            {
                name: "Sudan",
                value: 17.3
            },
            {
                name: "Tunisia",
                value: 24.3
            },
            {
                name: "Angola",
                value: 25
            },
            {
                name: "Libya",
                value: 50.6
            },
            {
                name: "Ivory Coast",
                value: 7.3
            },
            {
                name: "Morocco",
                value: 60.7
            },
            {
                name: "Ethiopia",
                value: 8.9
            },
            {
                name: "United Republic of Tanzania",
                value: 9.1
            },
            {
                name: "Nigeria",
                value: 93.9
            },
            {
                name: "South Africa",
                value: 392.7
            }, {
                name: "Egypt",
                value: 225.1
            }, {
                name: "Algeria",
                value: 141.5
            }], color: '#' + (Math.random().toString(16) + '00000').slice(2, 8)
        }, {
            name: 'Oceania',
            data: [{
                name: "Australia",
                value: 409.4
            },
            {
                name: "New Zealand",
                value: 34.1
            },
            {
                name: "Papua New Guinea",
                value: 7.1
            }], color: '#' + (Math.random().toString(16) + '00000').slice(2, 8)
        }, {
            name: 'North America',
            data: [{
                name: "Costa Rica",
                value: 7.6
            },
            {
                name: "Honduras",
                value: 8.4
            },
            {
                name: "Jamaica",
                value: 8.3
            },
            {
                name: "Panama",
                value: 10.2
            },
            {
                name: "Guatemala",
                value: 12
            },
            {
                name: "Dominican Republic",
                value: 23.4
            },
            {
                name: "Cuba",
                value: 30.2
            },
            {
                name: "USA",
                value: 5334.5
            }, {
                name: "Canada",
                value: 566
            }, {
                name: "Mexico",
                value: 456.3
            }], color: '#' + (Math.random().toString(16) + '00000').slice(2, 8)
        }, {
            name: 'South America',
            data: [{
                name: "El Salvador",
                value: 7.2
            },
            {
                name: "Uruguay",
                value: 8.1
            },
            {
                name: "Bolivia",
                value: 17.8
            },
            {
                name: "Trinidad and Tobago",
                value: 34
            },
            {
                name: "Ecuador",
                value: 43
            },
            {
                name: "Chile",
                value: 78.6
            },
            {
                name: "Peru",
                value: 52
            },
            {
                name: "Colombia",
                value: 74.1
            },
            {
                name: "Brazil",
                value: 501.1
            }, {
                name: "Argentina",
                value: 199
            },
            {
                name: "Venezuela",
                value: 195.2
            }], color: '#' + (Math.random().toString(16) + '00000').slice(2, 8)
        }, {
            name: 'Asia',
            data: [{
                name: "Nepal",
                value: 6.5
            },
            {
                name: "Georgia",
                value: 6.5
            },
            {
                name: "Brunei Darussalam",
                value: 7.4
            },
            {
                name: "Kyrgyzstan",
                value: 7.4
            },
            {
                name: "Afghanistan",
                value: 7.9
            },
            {
                name: "Myanmar",
                value: 9.1
            },
            {
                name: "Mongolia",
                value: 14.7
            },
            {
                name: "Sri Lanka",
                value: 16.6
            },
            {
                name: "Bahrain",
                value: 20.5
            },
            {
                name: "Yemen",
                value: 22.6
            },
            {
                name: "Jordan",
                value: 22.3
            },
            {
                name: "Lebanon",
                value: 21.1
            },
            {
                name: "Azerbaijan",
                value: 31.7
            },
            {
                name: "Singapore",
                value: 47.8
            },
            {
                name: "Hong Kong",
                value: 49.9
            },
            {
                name: "Syria",
                value: 52.7
            },
            {
                name: "DPR Korea",
                value: 59.9
            },
            {
                name: "Israel",
                value: 64.8
            },
            {
                name: "Turkmenistan",
                value: 70.6
            },
            {
                name: "Oman",
                value: 74.3
            },
            {
                name: "Qatar",
                value: 88.8
            },
            {
                name: "Philippines",
                value: 96.9
            },
            {
                name: "Kuwait",
                value: 98.6
            },
            {
                name: "Uzbekistan",
                value: 122.6
            },
            {
                name: "Iraq",
                value: 139.9
            },
            {
                name: "Pakistan",
                value: 158.1
            },
            {
                name: "Vietnam",
                value: 190.2
            },
            {
                name: "United Arab Emirates",
                value: 201.1
            },
            {
                name: "Malaysia",
                value: 227.5
            },
            {
                name: "Kazakhstan",
                value: 236.2
            },
            {
                name: "Thailand",
                value: 272
            },
            {
                name: "Taiwan",
                value: 276.7
            },
            {
                name: "Indonesia",
                value: 453
            },
            {
                name: "Saudi Arabia",
                value: 494.8
            },
            {
                name: "Japan",
                value: 1278.9
            },
            {
                name: "China",
                value: 10540.8
            },
            {
                name: "India",
                value: 2341.9
            },
            {
                name: "Russia",
                value: 1766.4
            },
            {
                name: "Iran",
                value: 618.2
            },
            {
                name: "Korea",
                value: 610.1
            }], color: '#' + (Math.random().toString(16) + '00000').slice(2, 8)
        }]
}

const optionsSplitPackedbubblechart = {
    chart: {
        type: 'packedbubble',
        height: '100%'
    },
    title: {
        text: 'Carbon emissions around the world (2014)'
    },
    tooltip: {
        useHTML: true,
        pointFormat: '<b>{point.name}:</b> {point.value}m CO<sub>2</sub>'
    },
    plotOptions: {
        packedbubble: {
            minSize: '20%',
            maxSize: '100%',
            zMin: 0,
            zMax: 1000,
            layoutAlgorithm: {
                gravitationalConstant: 0.05,
                splitSeries: true,
                seriesInteraction: false,
                dragBetweenSeries: true,
                parentNodeLimit: true
            },
            dataLabels: {
                enabled: true,
                format: '{point.name}',
                filter: {
                    property: 'y',
                    operator: '>',
                    value: 250
                },
                style: {
                    color: 'black',
                    textOutline: 'none',
                    fontWeight: 'normal'
                }
            }
        }
    },
    series: [{
        name: 'Europe',
        data: [{
            name: 'Germany',
            value: 767.1
        }, {
            name: 'Croatia',
            value: 20.7
        },
        {
            name: "Belgium",
            value: 97.2
        },
        {
            name: "Czech Republic",
            value: 111.7
        },
        {
            name: "Netherlands",
            value: 158.1
        },
        {
            name: "Spain",
            value: 241.6
        },
        {
            name: "Ukraine",
            value: 249.1
        },
        {
            name: "Poland",
            value: 298.1
        },
        {
            name: "France",
            value: 323.7
        },
        {
            name: "Romania",
            value: 78.3
        },
        {
            name: "United Kingdom",
            value: 415.4
        }, {
            name: "Turkey",
            value: 353.2
        }, {
            name: "Italy",
            value: 337.6
        },
        {
            name: "Greece",
            value: 71.1
        },
        {
            name: "Austria",
            value: 69.8
        },
        {
            name: "Belarus",
            value: 67.7
        },
        {
            name: "Serbia",
            value: 59.3
        },
        {
            name: "Finland",
            value: 54.8
        },
        {
            name: "Bulgaria",
            value: 51.2
        },
        {
            name: "Portugal",
            value: 48.3
        },
        {
            name: "Norway",
            value: 44.4
        },
        {
            name: "Sweden",
            value: 44.3
        },
        {
            name: "Hungary",
            value: 43.7
        },
        {
            name: "Switzerland",
            value: 40.2
        },
        {
            name: "Denmark",
            value: 40
        },
        {
            name: "Slovakia",
            value: 34.7
        },
        {
            name: "Ireland",
            value: 34.6
        },
        {
            name: "Croatia",
            value: 20.7
        },
        {
            name: "Estonia",
            value: 19.4
        },
        {
            name: "Slovenia",
            value: 16.7
        },
        {
            name: "Lithuania",
            value: 12.3
        },
        {
            name: "Luxembourg",
            value: 10.4
        },
        {
            name: "Macedonia",
            value: 9.5
        },
        {
            name: "Moldova",
            value: 7.8
        },
        {
            name: "Latvia",
            value: 7.5
        },
        {
            name: "Cyprus",
            value: 7.2
        }], color: '#' + (Math.random().toString(16) + '00000').slice(2, 8)
    }, {
        name: 'Africa',
        data: [{
            name: "Senegal",
            value: 8.2
        },
        {
            name: "Cameroon",
            value: 9.2
        },
        {
            name: "Zimbabwe",
            value: 13.1
        },
        {
            name: "Ghana",
            value: 14.1
        },
        {
            name: "Kenya",
            value: 14.1
        },
        {
            name: "Sudan",
            value: 17.3
        },
        {
            name: "Tunisia",
            value: 24.3
        },
        {
            name: "Angola",
            value: 25
        },
        {
            name: "Libya",
            value: 50.6
        },
        {
            name: "Ivory Coast",
            value: 7.3
        },
        {
            name: "Morocco",
            value: 60.7
        },
        {
            name: "Ethiopia",
            value: 8.9
        },
        {
            name: "United Republic of Tanzania",
            value: 9.1
        },
        {
            name: "Nigeria",
            value: 93.9
        },
        {
            name: "South Africa",
            value: 392.7
        }, {
            name: "Egypt",
            value: 225.1
        }, {
            name: "Algeria",
            value: 141.5
        }], color: '#' + (Math.random().toString(16) + '00000').slice(2, 8)
    }, {
        name: 'Oceania',
        data: [{
            name: "Australia",
            value: 409.4
        },
        {
            name: "New Zealand",
            value: 34.1
        },
        {
            name: "Papua New Guinea",
            value: 7.1
        }], color: '#' + (Math.random().toString(16) + '00000').slice(2, 8)
    }, {
        name: 'North America',
        data: [{
            name: "Costa Rica",
            value: 7.6
        },
        {
            name: "Honduras",
            value: 8.4
        },
        {
            name: "Jamaica",
            value: 8.3
        },
        {
            name: "Panama",
            value: 10.2
        },
        {
            name: "Guatemala",
            value: 12
        },
        {
            name: "Dominican Republic",
            value: 23.4
        },
        {
            name: "Cuba",
            value: 30.2
        },
        {
            name: "USA",
            value: 5334.5
        }, {
            name: "Canada",
            value: 566
        }, {
            name: "Mexico",
            value: 456.3
        }], color: '#' + (Math.random().toString(16) + '00000').slice(2, 8)
    }, {
        name: 'South America',
        data: [{
            name: "El Salvador",
            value: 7.2
        },
        {
            name: "Uruguay",
            value: 8.1
        },
        {
            name: "Bolivia",
            value: 17.8
        },
        {
            name: "Trinidad and Tobago",
            value: 34
        },
        {
            name: "Ecuador",
            value: 43
        },
        {
            name: "Chile",
            value: 78.6
        },
        {
            name: "Peru",
            value: 52
        },
        {
            name: "Colombia",
            value: 74.1
        },
        {
            name: "Brazil",
            value: 501.1
        }, {
            name: "Argentina",
            value: 199
        },
        {
            name: "Venezuela",
            value: 195.2
        }], color: '#' + (Math.random().toString(16) + '00000').slice(2, 8)
    }, {
        name: 'Asia',
        data: [{
            name: "Nepal",
            value: 6.5
        },
        {
            name: "Georgia",
            value: 6.5
        },
        {
            name: "Brunei Darussalam",
            value: 7.4
        },
        {
            name: "Kyrgyzstan",
            value: 7.4
        },
        {
            name: "Afghanistan",
            value: 7.9
        },
        {
            name: "Myanmar",
            value: 9.1
        },
        {
            name: "Mongolia",
            value: 14.7
        },
        {
            name: "Sri Lanka",
            value: 16.6
        },
        {
            name: "Bahrain",
            value: 20.5
        },
        {
            name: "Yemen",
            value: 22.6
        },
        {
            name: "Jordan",
            value: 22.3
        },
        {
            name: "Lebanon",
            value: 21.1
        },
        {
            name: "Azerbaijan",
            value: 31.7
        },
        {
            name: "Singapore",
            value: 47.8
        },
        {
            name: "Hong Kong",
            value: 49.9
        },
        {
            name: "Syria",
            value: 52.7
        },
        {
            name: "DPR Korea",
            value: 59.9
        },
        {
            name: "Israel",
            value: 64.8
        },
        {
            name: "Turkmenistan",
            value: 70.6
        },
        {
            name: "Oman",
            value: 74.3
        },
        {
            name: "Qatar",
            value: 88.8
        },
        {
            name: "Philippines",
            value: 96.9
        },
        {
            name: "Kuwait",
            value: 98.6
        },
        {
            name: "Uzbekistan",
            value: 122.6
        },
        {
            name: "Iraq",
            value: 139.9
        },
        {
            name: "Pakistan",
            value: 158.1
        },
        {
            name: "Vietnam",
            value: 190.2
        },
        {
            name: "United Arab Emirates",
            value: 201.1
        },
        {
            name: "Malaysia",
            value: 227.5
        },
        {
            name: "Kazakhstan",
            value: 236.2
        },
        {
            name: "Thailand",
            value: 272
        },
        {
            name: "Taiwan",
            value: 276.7
        },
        {
            name: "Indonesia",
            value: 453
        },
        {
            name: "Saudi Arabia",
            value: 494.8
        },
        {
            name: "Japan",
            value: 1278.9
        },
        {
            name: "China",
            value: 10540.8
        },
        {
            name: "India",
            value: 2341.9
        },
        {
            name: "Russia",
            value: 1766.4
        },
        {
            name: "Iran",
            value: 618.2
        },
        {
            name: "Korea",
            value: 610.1
        }], color: '#' + (Math.random().toString(16) + '00000').slice(2, 8)
    }]
}

const SpendAnalyticsDashboard = (props) => {
    //Row1
    let dataModelId = props.page.chart[0].chartData?.invoiceDatamodel;
    let url1 = URLs.noOfUniqueItems + dataModelId
    let url2 = URLs.noOfLineItems + dataModelId
    let url3 = URLs.avgInvoiceAmount + dataModelId
    let url4 = URLs.totalSpend + dataModelId
    let url5 = URLs.invoicesNumber + dataModelId
    let url6 = URLs.noOfVendor + dataModelId

    // Item with highest and lowest invoice amount
    let url7 = URLs.invoiceamountbyvendor + dataModelId
    //Items with number of invoices by vendor 
    let url8 = URLs.numofinvoicesbyvendor + dataModelId
    // Quantity of all products
    let url9 = URLs.productsquantity + dataModelId
    // Spend by vendor
    let url10 = URLs.spendbyvendorbymonth + dataModelId
    // 
    let url11 = URLs.invoicesbyvendorbymonth + dataModelId



    useEffect(() => {
        //Row1
        let dataModelId = props.page.chart[0].chartData?.invoiceDatamodel;
        let url1 = URLs.noOfUniqueItems + dataModelId
        let url2 = URLs.noOfLineItems + dataModelId
        let url3 = URLs.avgInvoiceAmount + dataModelId
        let url4 = URLs.totalSpend + dataModelId
        let url5 = URLs.invoicesNumber + dataModelId
        let url6 = URLs.noOfVendor + dataModelId

        // Item with highest and lowest invoice amount
        let url7 = URLs.invoiceamountbyvendor + dataModelId
        //Items with number of invoices by vendor 
        let url8 = URLs.numofinvoicesbyvendor + dataModelId
        // Quantity of all products
        let url9 = URLs.productsquantity + dataModelId
        // Spend by vendor
        let url10 = URLs.spendbyvendorbymonth + dataModelId
        //
        let url11 = URLs.invoicesbyvendorbymonth + dataModelId




        console.log('dataModelId', dataModelId)
        //Row1
        let payload1 = {
            url: url1,
            appId: props.match.params.appid,
            dataUniqueId: 'api_' + url1
        }
        let payload2 = {
            url: url2,
            appId: props.match.params.appid,
            dataUniqueId: 'api_' + url2
        }
        let payload3 = {
            url: url3,
            appId: props.match.params.appid,
            dataUniqueId: 'api_' + url3
        }
        let payload4 = {
            url: url4,
            appId: props.match.params.appid,
            dataUniqueId: 'api_' + url4
        }
        let payload5 = {
            url: url5,
            appId: props.match.params.appid,
            dataUniqueId: 'api_' + url5
        }
        let payload6 = {
            url: url6,
            appId: props.match.params.appid,
            dataUniqueId: 'api_' + url6
        }

        // Item with highest and lowest invoice amount
        let payload7 = {
            url: url7,
            appId: props.match.params.appid,
            dataUniqueId: 'api_' + url7
        }

        //Items with number of invoices by vendor 
        let payload8 = {
            url: url8,
            appId: props.match.params.appid,
            dataUniqueId: 'api_' + url8
        }
        // Quantity of all products
        let payload9 = {
            url: url9,
            appId: props.match.params.appid,
            dataUniqueId: 'api_' + url9
        }

        // Spend by vendor
        let payload10 = {
            url: url10,
            appId: props.match.params.appid,
            dataUniqueId: 'api_' + url10
        }
        //
        let payload11 = {
            url: url11,
            appId: props.match.params.appid,
            dataUniqueId: 'api_' + url11
        }


        Promise.all([
            //Row1
            props.getData(payload1),
            props.getData(payload2),
            props.getData(payload3),
            props.getData(payload4),
            props.getData(payload5),
            props.getData(payload6),

            // Item with highest and lowest invoice amount
            props.getData(payload7),
            //Items with number of invoices by vendor 
            props.getData(payload8),
            // Quantity of all products
            props.getData(payload9),
            // Spend by vendor
            props.getData(payload10),
            //
            props.getData(payload11),




        ])

    }, [props.page])

    //Row1
    let data1 = props.data['api_' + url1];
    let loading1 = props.dashboardData['api_' + url1 + '_loading']
    let data2 = props.data['api_' + url2];
    let loading2 = props.dashboardData['api_' + url2 + '_loading']
    let data3 = props.data['api_' + url3];
    let loading3 = props.dashboardData['api_' + url3 + '_loading']
    let data4 = props.data['api_' + url4];
    let loading4 = props.dashboardData['api_' + url4 + '_loading']
    let data5 = props.data['api_' + url5];
    let loading5 = props.dashboardData['api_' + url5 + '_loading']
    let data6 = props.data['api_' + url6];
    let loading6 = props.dashboardData['api_' + url6 + '_loading']

    // Item with highest and lowest invoice amount
    let data7 = props.data['api_' + url7];
    let loading7 = props.dashboardData['api_' + url7 + '_loading']

    //Items with number of invoices by vendor 
    let data8 = props.data['api_' + url8];
    let loading8 = props.dashboardData['api_' + url8 + '_loading']
    //Quantity of all products
    let data9 = props.data['api_' + url9];
    let loading9 = props.dashboardData['api_' + url9 + '_loading']
    // Spend by vendor
    let data10 = props.data['api_' + url10];
    let loading10 = props.dashboardData['api_' + url10 + '_loading']
    //
    let data11 = props.data['api_' + url11];
    let loading11 = props.dashboardData['api_' + url11 + '_loading']
    console.log('data11', data11)

    const row1 = [
        {
            "label": "No of invoices",
            "isLoading": loading5,
            "value": data5?.value || 0,
            "chartColor": "blue",
            "labelValue": "7.1",
            "btnBackGroundColor": "#d1e1f9",
            "grfColor": "#3d6eba",
            "chartType": "line",
        },
        {
            "label": "Total Spend",
            "isLoading": loading4,
            "value": numFormatter(data4?.value) || 0,
            str: true,
            "chartColor": "blue",
            "labelValue": "7.1",
            "btnBackGroundColor": "#d1e1f9",
            "grfColor": "#3d6eba",
            "chartType": "line",
        },
        {
            "label": "No of vendors",
            "isLoading": loading6,
            "value": data6?.length || 0,
            "chartColor": "blue",
            "labelValue": "7.1",
            "btnBackGroundColor": "#d1e1f9",
            "grfColor": "#3d6eba",
            "chartType": "line",
        },
        {
            "label": "No of unique items/products",
            "isLoading": loading1,
            "value": data1?.value || 0,
            "chartColor": "blue",
            "labelValue": "7.1",
            "btnBackGroundColor": "#d1e1f9",
            "grfColor": "#3d6eba",
            "chartType": "line",
        },
        {
            "label": "No of Line items",
            "isLoading": loading2,
            "value": data2?.value || 0,
            "chartColor": "blue",
            "labelValue": "7.1",
            "btnBackGroundColor": "#d1e1f9",
            "grfColor": "#3d6eba",
            "chartType": "line",
        },
        {
            "label": "Average invoice amount",
            "isLoading": loading3,
            "chartColor": "blue",
            "labelValue": "7.1",
            "btnBackGroundColor": "#d1e1f9",
            "grfColor": "#3d6eba",
            "chartType": "line",
            "value": numFormatter(data3?.value) || 0,
            str: true,
        },
    ]

    const row2 = [
        {
            "label": "Average line item amount",
            "value": 144,
            "chartColor": "blue",
            "labelValue": "7.1",
            "btnBackGroundColor": "#d1e1f9",
            "grfColor": "#3d6eba",
            "chartType": "line",
        },
        {
            "label": "Quantity of all products",
            "value": data9?.value,
            "chartColor": "blue",
            "labelValue": "7.1",
            "btnBackGroundColor": "#d1e1f9",
            "grfColor": "#3d6eba",
            "chartType": "line",
            "isLoading": loading9
        },

    ]

    let stackChartOption = {
        credits: {
            enabled: false
        },
        chart: {
            type: 'pie',
        },
        title: {
            text: 'Vendor with highest and Lowest invoice amount',
        },
        xAxis: {
            categories: ['Vendor 1, vendor 2']
        },
        yAxis: {
            min: 0,
            title: {
                text: undefined
            }
        },
        legend: {
            reversed: true
        },
        colors: colors,
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        series: [
            {
                name: 'Invoice Amount',
                data: data7 && data7?.map(ele => ({ name: ele.label, y: ele.value }))
            },

        ],
        lang: {
            noData: "No Data Available"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#000'
            }
        },
    }

    let stackChartOption1 = {
        credits: {
            enabled: false
        },
        chart: {
            type: 'pie',
        },
        title: {
            text: 'Vendor with highest and Lowest no of invoices',
        },
        xAxis: {
            categories: ['Vendor 1, vendor 2']
        },
        yAxis: {
            min: 0,
            title: {
                text: undefined
            }
        },
        legend: {
            reversed: true
        },
        colors: colors,
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        series: [
            {
                name: 'Invoice Amount',
                data: data8 && data8?.map(ele => ({ name: ele.label, y: ele.value }))
            },

        ],
        lang: {
            noData: "No Data Available"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#000'
            }
        },
    }

    let options = {
        chart: {
            type: 'column'
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: data10?.map(el => el.vendorName)
        },
        title: {
            text: 'Spend by vendor'
        },
        plotOptions: {
            series: {
                pointWidth: 20
            }
        },

        series: [{
            data: data10?.map(el => el.value),
            color: '#2d63b6',
            name: "vendor"

        }]
    }

    let options1 = {
        chart: {
            type: 'column'
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: data11?.map(el => el.vendorName)
        },
        title: {
            text: 'No of Invoices by vendor'
        },
        plotOptions: {
            series: {
                pointWidth: 20
            }
        },

        series: [{
            data: data11?.map(el => el.value),
            color: '#2d63b6',
            name: "Invoice"

        }],
        lang: {
            noData: "No Data Available"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#000'
            }
        },
    }


    return <div style={{ background: '#f7f7f7', paddingLeft: 5, paddingRight: 5, }}>
        <LabelColorBox labelData={row1} />

        <Grid container style={{ display: 'flex', marginTop: 12, }} xs={12} spacing={2}>
            <Grid item xs={4}>
                <LabelColorBox xs={6} labelData={row2} />
                <MultiLabelTile width={422} title="Item with highest and lowest invoice amount " list={data7} />
                <Grid container width={422} >
                    <span style={{ fontSize: 22 }}>Invoice Age</span>
                    <Grid item xs={12} style={{ background: 'rgb(255, 123, 51)', height: 60 }}>
                        <span style={{ float: 'right' }}> 12323</span>
                        <Grid item xs={8} style={{ background: 'rgb(45, 99, 182)', height: 60 }} >
                            <span style={{ float: 'right', }}> 123</span>
                            <Grid item xs={4} style={{ background: 'rgb(93, 211, 154)', height: 60 }}>
                                <span style={{ float: 'right' }}> 23</span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <BarChart options={stackChartOption} loading={loading7} />
            </Grid>
            <Grid item xs={4}>
                <BarChart options={stackChartOption1} loading={loading8} />
            </Grid>
        </Grid>
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <BarChart options={options} loading={loading10} />
            </Grid>
            <Grid item xs={4}>
                <BarChart options={options1} loading={loading11} />
            </Grid>
            <Grid item xs={4}>
                <BarChart options={options2} />
            </Grid>
        </Grid>
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <BarChart options={pietaxPercent} />
            </Grid>
            <Grid item xs={4}>
                <BarChart options={pietaxPercent1} />
            </Grid>
            <Grid item xs={4}>
                <BarChart options={options4Tree} />
            </Grid>
        </Grid>
    </div>
}

const mapStateToProps = state => {
    return {
        dashboardData: state.dashboardReducer,
        data: state.dashboardReducer.data,
    };
};

const mapDispatchToProps = {
    getData,
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps,)(SpendAnalyticsDashboard)),);
