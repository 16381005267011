import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
	container: {
		// backgroundColor: color.background
	},
	contentContainer: {
		// backgroundColor: color.background,
		alignItems: 'stretch',
		paddingTop: 10,
		paddingLeft: 10,
		paddingRight: 10,
	},
	formula: {
		fontSize: 34,
		textAlign: 'center',
		marginTop: 20,
		marginBottom: 30,
		// color: color.text
	},
	textInput: {
		fontSize: 16,
		// backgroundColor: color.accent,
		borderRadius: 8,
		padding: 12,
		paddingLeft: 14,
		paddingRight: 40,
		// color: color.text,
		flex: 1
	},
	textInputClearContainer: {
		position: 'absolute',
		alignItems: 'center',
		justifyContent: 'center',
		right: 0,
		height: '100%',
		width: 36,
	},
	textInputClear: {
		// color: color.text
	},
	textInputContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 10,
	},
	textInputLabel: {
		fontSize: 30,
		marginRight: 6
	},
	resultText: {
		textAlign: 'center',
		fontSize: 20,
		// color: color.text
	},
	resultContainer: {
		// borderColor: color.border,
		borderRadius: 8,
		borderWidth: 1,
		paddingLeft: 10,
		paddingRight: 6,
		height: 50,
		marginTop: 10,
		marginBottom: 20,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	buttonsContainer: {
		flexDirection: 'row',
		marginBottom: 10
	},
	button: {
		// backgroundColor: color.accent,
		paddingTop: 10,
		paddingBottom: 10,
		borderRadius: 8,
		flex: 1,
		alignItems: 'center'
	},
	buttonText: {
		// color: color.text,
		fontSize: 16
	},
	labelText: {
		fontSize: 20,
		marginBottom: 4,
		marginTop: 4,
		// color: `${color.text}8`
	},
	descriptionText: {
		// color: `${color.text}8`,
		fontSize: 16
	}
})

export default useStyles