import { ActionTypes } from 'constants/index';

const initialState = {
    allApps: [],
    loading: false,
    error: '',
    templates: [],
    templateLoading: '',
    templateError: '',
};

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_APPS_LOADING: {
            return {
                ...state,
                loading: true,
                error: ''
            };
        }
        case ActionTypes.GET_APP_TEMPLATES:
			{
				return {
					...state,
					loading: true,
				};
			}
        case ActionTypes.LOAD_APPS_SUCCESS: {
            return {
                ...state,
                allApps: action.payload,
                loading: false
            }
        }
        case ActionTypes.LOAD_APPS_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case ActionTypes.GET_TEMPLATES: {
            return {
                ...state,
                templateLoading: true,
                templateError: ''
            };
        }
        case ActionTypes.GET_TEMPLATES_SUCCESS:
            {
                return {
                    ...state,
                    templateLoading: false,
                    templates: action.payload
                };
            }
        case ActionTypes.GET_TEMPLATES_FAILURE: {
            return {
                ...state,
                templateLoading: false,
                templateError: action.error
            };
        }
        case ActionTypes.HANDLE_LOADER: {
            return {
                ...state,
                loading: action.payload,
            }
        }
        default: {
            return state;
        }
    }
}