import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import item from './item.json'
import item1 from './item1.json'
import { Paper } from '@material-ui/core';

const VendorBoxes = (props) => {

    const useStyles = makeStyles((theme) => ({
        paperLabel: {
            color: 'grey',
        },
        grid: {
            marginTop: 20,
            marginBottom: 20,
            marginLeft: 20,
        },
        gridContainer: {
            background: '#ffffff',
            marginBottom: '10px'
        },

    }));

    const classes = useStyles();

    return (
        <Paper elevation={2}>

            <Grid container className={classes.gridContainer}>
                <Grid className={classes.grid} item xs={2} style={{ borderRight: '1px solid #ebedf2' }} >
                    <span className={classes.paperLabel}>{'Items'}</span>
                    <div style={{ display: "grid" }} >
                        <span>No of items: <b>{item.noOfItems}</b></span>
                        <span>Quantities: <b>{item.quantities}</b></span>
                        <span>Total Amount: <b>{item.totalAmount}</b></span>
                    </div>
                </Grid>
                <Grid className={classes.grid} item xs={5} style={{ borderRight: '1px solid #ebedf2' }} >
                    <div style={{ display: "grid" }} >
                        <h3>{`Items1:`}</h3>
                        <span>{`Names: ${item1.name}`}</span>
                        <h3>{`Supplier Names`}</h3>
                        <span>{item1.supplier_name.map(el => el).join("  |  ")}</span>
                    </div>
                </Grid>
                <Grid className={classes.grid} item xs={4} style={{ maxWidth: '39.666667% !important' }} >
                    <div style={{ display: "grid" }} >
                        <h3>{`Items2:`}</h3>
                        <span>{`Names: ${item1.name}`}</span>
                        <h3>{`Supplier Names`}</h3>
                        <span>{item1.supplier_name.map(el => el).join("  |  ")}</span>
                    </div>
                </Grid>

            </Grid>
        </Paper>
    )

}

export default VendorBoxes