import React from "react"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    customstyle: {
        width: "100%",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontSize: '1rem',
    },
    
  }));

const CustomTextAreaField = (props) => {
    const { classes, element, dataObj, key, onChange, styles, eachField, onBlurHandler, setVisible, selectedStep } = props
    const customclasses = useStyles();
      
    return (
        <div>
            <textarea
                // autoFocus={index === visibleCellIndex}
                className={`${customclasses.customstyle} ${classes.textArea}`}
                key={element.value || element.name}
                rows={7}
                // onBlur={() => {
                //     // if (visibleCellIndex !== null) {
                //     //     setVisible(null)
                //     // }
                //     onBlurHandler((element.value || element.name), element.type)
                // }}
                value={dataObj[element.value] || dataObj[element.name]}
                name={element.value || element.name}
                onChange={(e) => { onChange((element.value || element.name), e.target.value, element.type) }}
            />
        </div>
    )
}

export default CustomTextAreaField