import { ModelDetails } from 'dsilo-ui-components';
import React from 'react';
import { connect } from 'react-redux';
import { clearSuccessMsg, getDataByUrl, getDataModelById, getDataModels, getModels, saveDataByUrl, updateDataByUrl } from '../../store/actions';
import Layout from '../Layout/Layout';

const SelfServiceModelDetails = props => {
	return (
		<Layout>
			<ModelDetails {...props} />
		</Layout>
	);
}

const mapDispatchToProps = {
	getModels,
	getDataModelById,
	getDataModels,
    saveDataByUrl,
    getDataByUrl,
    updateDataByUrl,
    clearSuccessMsg,
}
const mapStateToProps = state => {
	return {
		formData: state.form,
		user: state.user,
		dataModels: state.dataModels?.data || [],
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(SelfServiceModelDetails);
