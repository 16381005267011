import React,{useEffect,useMemo} from 'react';
import LabelColorBox from './LabelColorBox'
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { URLs } from '../../modules/appConfig.module'
import { getData } from '../../store/actions/action'
import { Helpers } from 'dsilo-ui-components'
import { getModelData } from '../../store/actions';
import Charts from './Charts'
export const colors = ['rgb(93, 211, 154)', 'rgb(255, 123, 51)', 'rgb(45, 99, 182)', 'rgb(237, 225, 91)', '#86bd45', '#abdff9', '#b43dc6'];

const ContractsInsights = (props) => {

    let dataModelId = props.page.chart[0].chartData?.selectedDataModel;

    //Row1
    let url1 = URLs.documentsreceived + dataModelId
    let url2 = URLs.invoicesnumber + dataModelId
    let url3 = URLs.processedinvoices + dataModelId
    let url4 = URLs.extractionaccuracy + dataModelId
    let url5 = URLs.unprocessedinvoices + dataModelId
    let url6 = URLs.humanreview + dataModelId
    let url13 = URLs.inreviewinvoices + dataModelId

    //Row3 Contracts by type
    let contractsbytypeURL = URLs.invoicedatabyfielddocumentType + dataModelId

    //Contracts by Counter party
    let invoicedatabyfieldcounterPartyURL = URLs.invoicedatabyfieldcounterParty + dataModelId

    //Contracts signed/not signed
    let invoicedatabyfieldsignedURL = URLs.invoicedatabyfieldsigned + dataModelId

    //Contracts by age
    let invoicedatabyfieldageURL = URLs.invoicedatabyfieldage + dataModelId


    //Contracts by term
	let invoicedatabyfieldtermURL = URLs.invoicedatabyfieldterm + dataModelId



    useEffect(() => {

        let dataModelId = props.page.chart[0].chartData?.selectedDataModel;
        //Row1
        let url1 = URLs.documentsreceived + dataModelId
        let url2 = URLs.invoicesnumber + dataModelId
        let url3 = URLs.processedinvoices + dataModelId
        let url4 = URLs.extractionaccuracy + dataModelId
        let url5 = URLs.unprocessedinvoices + dataModelId
        let url6 = URLs.humanreview + dataModelId
        let url13 = URLs.inreviewinvoices + dataModelId

        //Row3 Contracts by type
        let contractsbytypeURL = URLs.invoicedatabyfielddocumentType + dataModelId

        //Contracts by Counter party
        let invoicedatabyfieldcounterPartyURL = URLs.invoicedatabyfieldcounterParty + dataModelId

        //Contracts signed/not signed
        let invoicedatabyfieldsignedURL = URLs.invoicedatabyfieldsigned + dataModelId

        //Contracts by age
        let invoicedatabyfieldageURL = URLs.invoicedatabyfieldage + dataModelId

        //Contracts by term
	    let invoicedatabyfieldtermURL = URLs.invoicedatabyfieldterm + dataModelId   

        //Row1
        let payload1 = {
            url: url1,
            appId: props.match.params.appid,
            dataUniqueId: 'api_' + url1
        }
        let payload2 = {
            url: url2,
            appId: props.match.params.appid,
            dataUniqueId: 'api_' + url2
        }
        let payload3 = {
            url: url3,
            appId: props.match.params.appid,
            dataUniqueId: 'api_' + url3
        }
        let payload4 = {
            url: url4,
            appId: props.match.params.appid,
            dataUniqueId: 'api_' + url4
        }
        let payload5 = {
            url: url5,
            appId: props.match.params.appid,
            dataUniqueId: 'api_' + url5
        }
        let payload6 = {
            url: url6,
            appId: props.match.params.appid,
            dataUniqueId: 'api_' + url6
        }
        let payload13 = {
            url: url13,
            appId: props.match.params.appid,
            dataUniqueId: 'api_' + url13
        }
            
        //Row2
        let payloadActiveContracts = {
            "fields": "status",
            "appId": props.match.params.appid,
            "id": dataModelId,
            "page": "0",
            "rowsPerPage": "all",
            "conditions": [
                {
                    "selectedConditionDataElement": "effectiveDate",
                    "selectedConditionOperator": "greaterthan",
                    "value": "${CURRENT_DATE}",
                    "selectedConditionClause": "and"
                }
            ],
            "dataUniqueId": 'api_ActiveContracts'
        }

        let payloadExpiredContracts = {
            "fields": "status",
            "appId": props.match.params.appid,
            "id": dataModelId,
            "page": "0",
            "rowsPerPage": "all",
            "conditions": [
                
                {
                    "selectedConditionDataElement": "endDate",
                    "selectedConditionOperator": "lessthan",
                    "value": "${CURRENT_DATE}",
                    "selectedConditionClause": "and"
                }
            ],
            "dataUniqueId": 'api_expiredContracts'
        }

        let payloadExpringContracts30 = {
            "fields": "status",
            "appId": props.match.params.appid,
            "id": dataModelId,
            "page": "0",
            "rowsPerPage": "all",
            "conditions": [
                {
                    "selectedConditionDataElement": "endDate",
                    "selectedConditionOperator": "lessthan",
                    "value": "30",
                    "selectedConditionClause": "and"
                },
            ],
            "dataUniqueId": 'api_ExpringContracts30'
        }

        let payloadExpringContracts60 = {
            "fields": "status",
            "appId": props.match.params.appid,
            "id": dataModelId,
            "page": "0",
            "rowsPerPage": "all",
            "conditions": [
                {
                    "selectedConditionDataElement": "endDate",
                    "selectedConditionOperator": "lessthan",
                    "value": "60",
                    "selectedConditionClause": "and"
                },
            ],
            "dataUniqueId": 'api_ExpringContracts60'
        }

        let payloadExpringContracts90 = {
            "fields": "status",
            "appId": props.match.params.appid,
            "id": dataModelId,
            "page": "0",
            "rowsPerPage": "all",
            "conditions": [
                {
                    "selectedConditionDataElement": "endDate",
                    "selectedConditionOperator": "lessthan",
                    "value": "90",
                    "selectedConditionClause": "and"
                },
            ],
            "dataUniqueId": 'api_ExpringContracts90'
        }


        // Row3 
        // Contracts by type
        let payloadContractsByType = {
            url: contractsbytypeURL,
            appId: props.match.params.appid,
            dataUniqueId: 'api_contractsbytype'
        }

        // Contracts by Counter party
        let payloadInvoicedatabyFieldCounterParty = {
            url: invoicedatabyfieldcounterPartyURL,
            appId: props.match.params.appid,
            dataUniqueId: 'api_InvoicedatabyFieldCounterParty'
        }

        //Contracts signed/not signed

        let payloadInvoicedatabyFieldsigned = {
            url: invoicedatabyfieldsignedURL,
            appId: props.match.params.appid,
            dataUniqueId: 'api_InvoicedatabyFieldsigned'
        }

        //Contracts by age
        let payloadinvoicedatabyfieldage = {
            url: invoicedatabyfieldageURL,
            appId: props.match.params.appid,
            dataUniqueId: 'api_invoicedatabyfieldage'
        }

        //Contracts by term
        let payloadinvoicedatabyfieldterm = {
            url: invoicedatabyfieldtermURL,
            appId: props.match.params.appid,
            dataUniqueId: 'api_invoicedatabyfieldterm'
        }




        Promise.all([
            //Row1
            // props.getData(payload1),
            props.getData(payload2),
            props.getData(payload3),
            props.getData(payload4),
            props.getData(payload5),
            // props.getData(payload6),
            props.getData(payload13),
            
            //Row2
            props.getModelData(payloadActiveContracts),
            props.getModelData(payloadExpringContracts30),
            props.getModelData(payloadExpringContracts60),
            props.getModelData(payloadExpringContracts90),
            props.getModelData(payloadExpiredContracts),

            //Row3
            props.getData(payloadContractsByType),
            props.getData(payloadInvoicedatabyFieldCounterParty),
            props.getData(payloadInvoicedatabyFieldsigned),
            props.getData(payloadinvoicedatabyfieldage),
            props.getData(payloadinvoicedatabyfieldterm),







        ])

    }, [props.page])

      //Row1
      let data1 = props.data['api_' + url1];
      let loading1 = props.dashboardData['api_' + url1 + '_loading']
      let data2 = props.data['api_' + url2];
      let loading2 = props.dashboardData['api_' + url2 + '_loading']
      let data3 = props.data['api_' + url3];
      let loading3 = props.dashboardData['api_' + url3 + '_loading']
      let data4 = props.data['api_' + url4];
      let loading4 = props.dashboardData['api_' + url4 + '_loading']
      let data5 = props.data['api_' + url5];
      let loading5 = props.dashboardData['api_' + url5 + '_loading']
      let data6 = props.data['api_' + url6];
      let loading6 = props.dashboardData['api_' + url6 + '_loading']
      let data13 = props.data['api_' + url13];
      let loading13 = props.dashboardData['api_' + url13 + '_loading']

      //Row2
      let activeContracts_data = props.formData?.document['api_ActiveContracts'];
      let activeContracts_loading = props.formData['api_ActiveContracts_loading']

      let expringContracts30_data = props.formData?.document['api_ExpringContracts30'];
      let epringContracts30_loading = props.formData['api_ExpringContracts30_loading']

      let expringContracts60_data = props.formData?.document['api_ExpringContracts60'];
      let epringContracts60_loading = props.formData['api_ExpringContracts60_loading']
    
      let expringContracts90_data = props.formData?.document['api_ExpringContracts90'];
      let epringContracts90_loading = props.formData['api_ExpringContracts90_loading']

      let expiredContracts_data = props.formData?.document['api_expiredContracts'];
      let expiredContracts_loading = props.formData['api_expiredContracts_loading']


      //Row3

      let contractsbytype_data = props.data['api_contractsbytype'];
      let contractsbytype_loading = props.dashboardData['api_contractsbytype_loading']

      let InvoicedatabyFieldCounterParty_data = props.data['api_InvoicedatabyFieldCounterParty'];
      let InvoicedatabyFieldCounterParty_loading = props.dashboardData['api_InvoicedatabyFieldCounterParty_loading']

      let InvoicedatabyFieldsigned_data = props.data['api_InvoicedatabyFieldsigned'];
      let InvoicedatabyFieldsigned_loading = props.dashboardData['api_InvoicedatabyFieldsigned_loading']

      let invoicedatabyfieldage_data = props.data['api_invoicedatabyfieldage'];
      let invoicedatabyfieldage_loading = props.dashboardData['api_invoicedatabyfieldage_loading']

      let invoicedatabyfieldterm_data = props.data['api_invoicedatabyfieldterm'];
      let invoicedatabyfieldterm_loading = props.dashboardData['api_invoicedatabyfieldterm_loading']


     //Row1
     let ddRow1 = [
        
        {
            "label": "Total contracts",
            "isLoading": loading2,
            "value": data2?.value || 0,
            "labelValue": (Helpers.convertToNumber(data2?.growth, 2) || 0),
            "grfColor": "#3d6eba",
            "chartType": "line",
            "chartData": data2?.chartData,
        },
        {
            "label": "Processed",
            "isLoading": loading3,
            "value": data3?.value || 0,
            "labelValue": (Helpers.convertToNumber(data3?.growth, 2) || 0),
            "grfColor": "#f47576",
            "chartType": "line",
            "chartData": data3?.chartData,
        },
        {
            "label": "Quality Review",
            "isLoading": loading1,
            "value": data13?.value || 0,
            "labelValue": (Helpers.convertToNumber(data13?.growth, 2) || 0),
            "grfColor": "#3d6eba",
            "chartType": "line",
            "chartData": data13?.chartData,
        },
        {
            "label": "Unprocessed",
            "isLoading": loading5,
            "value": data5?.value || 0,
            "labelValue": (Helpers.convertToNumber(data5?.growth, 2) || 0),
            "grfColor": "#3d6eba",
            "chartType": "doughnut",
            "chartData": data5?.chartData,

        }
    ]

    //Row2
    let ddRow2 = [
        
        {
            "label": "Active contracts",
            "isLoading": activeContracts_loading,
            "value": activeContracts_data?.total || 0,
            "labelValue": (Helpers.convertToNumber(activeContracts_data?.total, 2) || 0),
            "grfColor": "#3d6eba",
            "chartType": "line",
        },
        {
            "label": "Expired contracts",
            "isLoading": expiredContracts_loading,
            "value": expiredContracts_data?.total || 0,
            "labelValue": (Helpers.convertToNumber(expiredContracts_data?.total, 2) || 0),
            "grfColor": "#f47576",
            "chartType": "line",
        },
        {
            "label": "Contracts expiring in 30 days",
            "isLoading": epringContracts30_loading,
            "value": expringContracts30_data?.total || 0,
            "labelValue": (Helpers.convertToNumber(expringContracts30_data?.total, 2) || 0),
            "grfColor": "#3d6eba",
            "chartType": "line",
        },
        {
            "label": "in 60 days",
            "isLoading": epringContracts60_loading,
            "value": expringContracts60_data?.total || 0,
            "labelValue": (Helpers.convertToNumber(expringContracts60_data?.total, 2) || 0),
            "grfColor": "#3d6eba",
            "chartType": "doughnut",
        },
        {
            "label": "in 90 days",
            "isLoading": epringContracts90_loading,
            "value": expringContracts90_data?.total || 0,
            "labelValue": (Helpers.convertToNumber(expringContracts90_data?.total, 2) || 0),
            "grfColor": "#3d6eba",
            "chartType": "doughnut",
        }
    ]

    //Row3

    const options1 = useMemo(() => {
        return ({ 
            chart: {
                type: 'column'
            },
            credits: {
                enabled: false
            },
            title: {
                text: 'Contracts by Type'
            },
            xAxis: {
                categories: contractsbytype_data?.map(ele=>ele._id),
                labels: {
                    formatter: function() {
                        return this.value.toString().substring(0, 6);
                    }
                }
            },
        
            plotOptions: {
                series: {
                    pointWidth: 20
                }
            },
        
            series: [{
                data: contractsbytype_data?.map(ele=>ele.value),
                color: '#2d63b6',
                name:"Vendors"
        
            }],
            lang: {
                noData: "No Data Available"
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#000'
                }
            },
           
        })
    }, [contractsbytype_data]);

    const options2 = useMemo(() => {
        return ({ 
            chart: {
                type: 'column'
            },
            credits: {
                enabled: false
            },
            title: {
                text: 'Contracts by counter party'
            },
            xAxis: {
                categories: InvoicedatabyFieldCounterParty_data?.map(ele=>ele._id),
                labels: {
                    formatter: function() {
                        return this.value.toString().substring(0, 6);
                    }
                }
            },
        
            plotOptions: {
                series: {
                    pointWidth: 20
                }
            },
        
            series: [{
                data: InvoicedatabyFieldCounterParty_data?.map(ele=>ele.value),
                color: '#2d63b6',
                name:"Vendors"
        
            }],
            lang: {
                noData: "No Data Available"
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#000'
                }
            },
           
        })
    }, [InvoicedatabyFieldCounterParty_data]);

    const pietaxPercent = useMemo(() => {
        return ({ chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: 'Contracts signed/not signed'
        },
        credits: {
            enabled: false
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                colors: colors,
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: [{
            name: 'Contracts',
            colorByPoint: true,
            data: InvoicedatabyFieldsigned_data? Object.keys(InvoicedatabyFieldsigned_data).map((ele)=>({name:ele,y:InvoicedatabyFieldsigned_data[ele]||0})):[]
        }],
        lang: {
            noData: "No Data Available"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#000'
            }
        },
           
        })
    }, [InvoicedatabyFieldsigned_data]);


    const pietaxPercent1 = useMemo(() => {
        return ({
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: 'Contracts by age'
            },
            credits: {
                enabled: false
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    colors: colors,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            series: [{
                name: 'Age',
                colorByPoint: true,
                data: invoicedatabyfieldage_data? Object.keys(invoicedatabyfieldage_data).map((ele)=>({name:ele,y:invoicedatabyfieldage_data[ele]})):[]
            }],
            lang: {
                noData: "No Data Available"
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#000'
                }
            },
        })
    }, [invoicedatabyfieldage_data]);


    const pietaxPercent2 = useMemo(() => {
        return ({
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: 'Contacts by term'
            },
            credits: {
                enabled: false
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    colors: colors,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            series: [{
                name: 'Age',
                colorByPoint: true,
                data: invoicedatabyfieldterm_data?Object.keys(invoicedatabyfieldterm_data).map((ele)=>({name:ele,y:invoicedatabyfieldterm_data[ele]||0})):[]
            }],
            lang: {
                noData: "No Data Available"
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#000'
                }
            },
        })
    }, [invoicedatabyfieldterm_data]);






    return(
        <div style={{ background: "#f7f7f7", padding: "13px" }}>
           <LabelColorBox labelData={ddRow1} />
           <LabelColorBox labelData={ddRow2} />
           <Charts options1={options1} contractsbytype_loading={contractsbytype_loading} 
                    options2={options2} InvoicedatabyFieldCounterParty_loading={InvoicedatabyFieldCounterParty_loading}
                    pietaxPercent={pietaxPercent} InvoicedatabyFieldsigned_loading={InvoicedatabyFieldsigned_loading}
                    pietaxPercent1={pietaxPercent1} invoicedatabyfieldage_loading={invoicedatabyfieldage_loading}
                    pietaxPercent2={pietaxPercent2} invoicedatabyfieldterm_loading={invoicedatabyfieldterm_loading}

           />
        </div>
    )
}
const mapStateToProps = state => {
    return {
        dashboardData: state.dashboardReducer,
        data: state.dashboardReducer.data,
        formData: state.form,
    };
};

const mapDispatchToProps = {
    getData,
    getModelData
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps,)(ContractsInsights)),);