import { makeStyles, Typography } from '@material-ui/core';
import { LabelColorBoxes, ValueContainersV1, SimpleList, MiniTable } from 'dsilo-ui-components';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearReduxDataOfCurrentComponent, getContractDataById, getDataModelById, getModelData,
	deleteContractPDFAction,
    getEventByID,
    initiateAction } from '../../store/actions';
import { Loader } from '../loader';
import Layout from '../../components/Layout/Layout';
import { Card } from './card';
import SupplierMainInfoBox from './SupplierMainInfoBox'
import { toast } from "react-toastify"

// const cardValues = [
// 	{ label: 'Supplier Name', value: 'Kate Williams', backgroundColor: "#002744", color: "#fff" },
// 	{ label: 'Supplier Contact', value: 'John Smith', backgroundColor: "#002744", color: "#fff" },
// 	{ label: 'Onboarding Status', value: 'Pending', backgroundColor: "#002744", color: "#fff" },
// ]

const Values = [
	{ header: 'Onboarding', value: 'N', description: 'Supplier onboarding status', backgroundColor: 'yellow', color: 'black', id: 0 },
	{ header: 'TPRM', value: 'N', description: 'Third party risk assessment', backgroundColor: 'blue', color: 'white', id: 2 },
	{ header: 'Legel Review', value: 'N', description: 'Review by internal legal teams', backgroundColor: 'green', color: 'white', id: 3 },
	{ header: 'Signature', value: 'N', description: 'Agreement signed by both parties', backgroundColor: 'red', color: 'white', id: 4 },
]

const auditTrailsItems = [
	'Received orderform from mike on 19/05/2021 79:04:34M',
	'Supplier \'ABC Holdings LLC\' is added to vendor master on 09/01/2021 10:12:54 AM',
	'Supplier Onboarding status is initiated by John Smith on on 09/02/2021 03:34:23 AM',
]

const topAction = [
	{
		title: 'Mark All completed',
		action: '',
	},
	{
		title: 'Remove All Actions',
		action: '',
	},
	{
		title: 'Add Exceptions',
		action: '',
	},
]

const customActions = {
	isLinkedToActionEvent: true,
	label: "workflow",//default `actions`
	actions: [
		{
			"dependentDataElement": {
				"value": "status",
				"label": "Status"
			},
			"actionController": [{
				"value": "New",
				"buttonLabel": "Start",
				"action": "61a679ffac739da1a2650067"
			},{
				"value": "Inprocess",
				"buttonLabel": "Mark as Completed",
				"action": "616fb9eeaa3375a4145e2632"
			}]
		},
		{
			"buttonLabel": "Reassign",
		}
	]
}

const OnboardingOverView = (props) => {

	let myTask = {
		title: 'Required Actions',
		dataSource: [],
		columns: [
			{
				value: 'action',
				label: 'Action',
				style: {
					// width: 50,
				},
			},
			{
				value: 'assigned',
				label: 'Assigned',
				style: {
					width: 125,
				},
			},

			{
				value: 'expiryDate',
				label: 'Date',
				style: {
					width: 80,
				}
			},

			{
				value: 'action',
				label: 'Link',
				style: {
					width: 200,
					color: 'blue',
				}
			},

			{
				value: 'status',
				label: 'Status',
				style: {
					width: 80,
				}
			}],
		moreClickAction: () => {
		},
	};

	const { selectedActionsDataModel, selectedDataModel } = props.data?.chartData;

	const [data, setDataSource] = useState(JSON.parse(JSON.stringify(myTask)))
	const [cardData, setCardData] = useState([])
	const [cardValues, setCardValues] = useState([])
	const [supplier, setSupplier] = useState({})

	const changeStatus = (rowData) => {
		let _data = [...data.dataSource];
		let itemIndex = _data.findIndex(i => i.id === rowData.id)
		_data[itemIndex] = { ...rowData, status: 'Completed' }

		let _cardData = [...cardData];
		let iIndex = _cardData.findIndex(i => i.id === rowData.id)
		_cardData[iIndex] = { ..._cardData[iIndex], value: 'Y' }
		setCardData(_cardData)
		setDataSource({ ...myTask, dataSource: _data })
	}

	// const { fetchType, selectedInnerDataElement, selectedDataModel } = props.data?.chartData;
	const { formData } = props;

	const fetchSupplierDetails = () => {
		let payloadForTableData = {
			id: props.match.params.id,
			dataModelId: selectedDataModel,
			appId: props.match.params.appid,
			fields: ""
		}
		props.getContractDataById({ ...payloadForTableData, dataUniqueId: props.data.chartUniqueId })
	}

	const fetchActionDetails = (supplierId) => {
		console.log();
		let payloadForTableData = {
			id: selectedActionsDataModel,
			appId: props.match.params.appid,
			fields: "",
			conditions: [{
				"selectedConditionDataElement": "parentDocumentId",
				"selectedConditionOperator": "matches",
				"value": supplierId,
				"selectedConditionClause": "and"
			}]
		}
		props.getModelData({ ...payloadForTableData, dataUniqueId: props.data.chartUniqueId + '_cardData' })
	}

	useEffect(() => {
		fetchSupplierDetails()
		// getEventsForActions()
	}, [])

	let data1 = formData?.document[props.data.chartUniqueId]?.data;
	useEffect(() => {
		if (data1 && data1?.length > 0) {
			let sup = data1[0]
			let cardValues = [
				{ label: 'Supplier Name', value: sup.supplierName, backgroundColor: "#002744", color: "#fff" },
				{ label: 'Supplier Contact', value: sup.contact, backgroundColor: "#002744", color: "#fff" },
				{ label: 'Onboarding Status', value: sup.onboardingStatus, backgroundColor: "#002744", color: "#fff" },
			]
			setCardValues(cardValues)
			fetchActionDetails(sup._id)
			setSupplier(sup)
		}
	}, [data1])

	let actions1 = formData?.document[props.data.chartUniqueId + '_cardData']?.data;
	useEffect(() => {
		console.log("actions1 ===", actions1);
		if (actions1 && actions1?.length > 0) {
			let _cardData = [
				{
					header: 'Onboarding', value: 'N', description: 'Supplier onboarding status',
					backgroundColor: 'yellow', color: 'black', id: 0
				},
				{
					header: 'TPRM', value: 'N', description: 'Third party risk assessment',
					backgroundColor: 'blue', color: 'white', id: 2
				},
				{
					header: 'Legel Review', value: 'N', description: 'Review by internal legal teams',
					backgroundColor: 'green', color: 'white', id: 3
				},
				{
					header: 'Signature', value: 'N', description: 'Agreement signed by both parties',
					backgroundColor: 'red', color: 'white', id: 4
				},
			]
			_cardData.map(card => {
				actions1.find(action => {
					if (action.action === card.header) {
						card.value = card.status === "Completed" ? "Y" : "N"
					}
				})
			})
			setCardData(_cardData)
			setDataSource({ ...data, dataSource: actions1 })
		}
	}, [actions1])

	// if (props.formData[props.data.chartUniqueId + '_modalAlias_loading']) {
	//     return <Loader />
	// }

	const [deleteConfirmState, setDeleteConfirm] = useState({
		isOpen: false,
		deleteMessage: "Are you sure you want to delete ?",
		data: null
	})

	const onDeleteResponse = (isDelete) => {
		if (isDelete) {
			const payload = {
				ids: { ids: [deleteConfirmState.data] },
				dataModelId: selectedActionsDataModel,
				appId: props.match.params.appid
			}
			props.deleteContractPDFAction(payload)
		}
		setDeleteConfirm({ ...deleteConfirmState, isOpen: false, data: null })
	}

	const tableCrudOperations = (item, type) => {
		console.log(item)
		if (type === "edit") {
			props.history.push({
				pathname: `/${props.PUBLIC_PATH}${props.match.params.appid}/page/${chartData.editPage}/${item._id}`
			})
		}
		if (type === "view") {
			props.history.push({
				pathname: `/${props.PUBLIC_PATH}${props.match.params.appid}/page/${chartData.viewPage}/${item._id}`
			})
		}
		if (type === "delete") {
			setDeleteConfirm({
				...deleteConfirmState,
				isOpen: true,
				data: item._id
			})
		}
		if (type === "download") {
		}
		if(type === "link") {
			props.history.push({
				pathname: `/${props.PUBLIC_PATH}${props.match.params.appid}/page/${chartData.link}/${item._id}`
			})
		}
	}

	useEffect(() => {
		if (props.formData?.message) {
			toast.info("Action deleted successfully")
			props.hideSuccessMessage && props.hideSuccessMessage()
			fetchSupplierDetails()
		}
	}, [props.formData.message])

	const onActionClick = (actionId) => {
		console.log({ actionId })
		const payload = {
			data: {
				_id: props.match.params.id,
				eventId: actionId
			},
			appId: props.match.params.appid
		}
		props.initiateAction(payload)
	}

	// const renderActions = () => {
	// 	return props.actions.map((action, index) => {
	// 		return action?.triggerUIEvent?.map((uiEvent, index1) => {
	// 			if (uiEvent.type === "Button") {
	// 				return (
	// 					<Button
	// 						key={index + index1}
	// 						size='small'
	// 						className={classes.button}
	// 						onClick={() => onActionClick(action._id)}
	// 					>
	// 						{uiEvent.label}
	// 					</Button>
	// 				)
	// 			}
	// 		})
	// 	})
	// }

    // const getEventsForActions = () => {
    //     let _actions = props.data.chartData?.actions;
    //     if (Array.isArray(_actions)) {
    //         _actions.forEach(action => {
    //             if (action.isLinkedToActionEvent && action.event) {
    //                 let payload = {
    //                     id: action.event,
    //                     appId: props.match.params.appid,
    //                     dataUniqueId: props.data.chartUniqueId + action.event + '_event',
    //                 }
    //                 props.getEventByID(payload)
    //             }
    //         })
    //     }
    // }

    // useEffect(() => {
    //     const { event } = props.action;
    //     console.log({event});
    //     // there can be multiple events so every event's data will be stored in a variable according to event id. sp getting all variables and combinig them
    //     if(event){
    //         let eventObjectsAoA = Object.values(event)
    //         if(Array.isArray(eventObjectsAoA)){
    //             let allEvents = Object.values(event).flat();
    //             setActions(allEvents);
    //         }
    //     }
    // },[props.action])

	console.log("cardData ====", cardData, supplier);
	return <div>
		<SupplierMainInfoBox cardData={cardData} cardValues={cardValues} supplier={supplier} />
		<div style={{ marginBottom: 15 }}>
			<MiniTable noData={false} loading={false} data={data} maxRows={10}
			customActions={props.data?.chartData?.customActions || customActions}
			actions={{
				edit: false,
				view: false,
				delete: true,
				copy: false,
				download: false
			}}
			onDeleteResponse={onDeleteResponse}
			tableCrudOperations={tableCrudOperations}
			deleteConfirmState={deleteConfirmState}
			onActionClick={onActionClick}
			 />
		</div>
		{/* <div>
			<SimpleList data={{ title: 'Audit Trail', items: auditTrailsItems }} />
		</div> */}
	</div>
}

const mapDispatchToProps = {
	getContractDataById,
	getDataModelById,
	getModelData,
	clearReduxDataOfCurrentComponent,
	deleteContractPDFAction,
	initiateAction
};

const mapStateToProps = state => {
	return {
		formData: state.form,
		user: state.user,
	};
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(OnboardingOverView)));
