const API_BASE_URL = process.env.APP_API_BASE_ADDRESS

import { request } from 'modules/client'

export class themeService {
	static getTheme(appId) {
		const uri = `${API_BASE_URL}/themes/${appId}`
		return request(uri, {
			method: 'GET'
		})
	}
}
