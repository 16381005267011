import { Chip, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Input, MenuItem, Radio, RadioGroup, Select, TextField } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import React from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
export function RulesTab(props) {
    const { form } = props.state;
    const { classes, actionTypesList, selectedComparatorList, dataTypeStringList } = props;
    const values = props.state
    console.log("values======?", values);
    console.log("props.dataModels===?===", props.dataModels);
    return (
        form && (
            <div className="p-16 sm:p-24 max-w-2xl">
                <form className="form" noValidate autoComplete="off">
                    <FormControl row>
                        <RadioGroup
                            aria-label="Rule Type"
                            name="ruleType"
                            className={classes.group}
                            value={form.ruleType}
                            onChange={props.handleChange}
                            row
                        >
                            <FormControlLabel
                                value="data"
                                control={<Radio />}
                                label="Data"
                            />
                            <FormControlLabel
                                value="action"
                                control={<Radio />}
                                label="Action"
                            />
                            <FormControlLabel
                                value="visibility"
                                control={<Radio />}
                                label="Visibility"
                            />
                            <FormControlLabel
                                value="content"
                                control={<Radio />}
                                label="Content"
                            />
                        </RadioGroup>
                    </FormControl>
                    <div className={classes.mainContainer}>
                        <FormGroup row className="items-center">
                            <FormLabel component="legend">
                                Conditions
                            </FormLabel>
                        </FormGroup>

                        {values.conditionsUIList.length > 0
                            && values.conditionsUIList?.map((item, index) => {
                                return <div className={classes.container} key={index} >
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <Select
                                            className={classes.select}
                                            value={values.conditionsUIList[index].dataModelLeftSelected}
                                            onChange={(data) => props.handleDataModelLeftChange(data, index)}>
                                            <MenuItem value="">
                                                <em>Select Data Model</em>
                                            </MenuItem>
                                            {props.dataModels.data?.map((item, i) => {
                                                return <MenuItem key={i} value={item._id} >{item.name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: 150, padding: "0px 5px 0px 0px" }}>
                                        <Select
                                            className={classes.select}
                                            value={values.conditionsUIList[index].dataElementLeftSelected}
                                            onChange={(data) => props.handleElementsLeftChange(data, index)}>
                                            <MenuItem value="">
                                                <em>Select Data Element</em>
                                            </MenuItem>
                                            {
                                                values.conditionsUIList[index]?.dataElementLeftList?.map((element, i) => {
                                                    return <MenuItem key={i} value={element.name}>{element.name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: 150, padding: "0px 5px 0px 0px" }}>
                                        <Select
                                            className={classes.select}
                                            value={values.conditionsUIList[index].operatorSelected}
                                            onChange={(data) => props.handleOperatorChange(data, index)}>
                                            <MenuItem value="">
                                                <em>Select Operator</em>
                                            </MenuItem>
                                            {values.conditionsUIList[index].dataElementLeftSelected !== "" &&
                                                (values.conditionsUIList[index].dataElementType === "Integer"
                                                    ? dataTypeNumList
                                                    : dataTypeStringList)?.map((element, i) => {
                                                        return <MenuItem key={i} value={element.value}>{element.name}</MenuItem>
                                                    })}
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <Select
                                            className={classes.select}
                                            value={values.conditionsUIList[index].selectedComparator}
                                            onChange={(data) => props.handleCompartorChange(data, index)}>
                                            <MenuItem value="">
                                                <em>Select Comparator</em>
                                            </MenuItem>
                                            {
                                                (selectedComparatorList)?.map((element, i) => {
                                                    return <MenuItem key={i} value={element.value}>{element.name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    {values.conditionsUIList[index].selectedComparator === 'datamodel'
                                        && (<>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <Select
                                                    className={classes.select}
                                                    value={values.conditionsUIList[index].dataModelRightSelected}
                                                    onChange={(data) => props.handleDataModelRightChange(data, index)}>
                                                    <MenuItem value="">
                                                        <em>Select Data Model</em>
                                                    </MenuItem>
                                                    {props.dataModels.data?.map((item, i) => {
                                                        return <MenuItem key={i} value={item._id} >{item.name}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>

                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <Select
                                                    className={classes.select}
                                                    value={values.conditionsUIList[index].dataElementRightSelected}
                                                    onChange={(data) => props.handleElementsRightChange(data, index)}>
                                                    <MenuItem value="">
                                                        <em>Select Data Element</em>
                                                    </MenuItem>
                                                    {
                                                        values.conditionsUIList[index]?.dataElementRightList?.map((element, i) => {
                                                            return <MenuItem key={i} value={element.name}>{element.name}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </>
                                        )}

                                    {values.conditionsUIList[index].selectedComparator === 'inputField' && (
                                        <TextField
                                            className={classes.select}
                                            placeholder="Value"
                                            autoFocus
                                            id="inputField"
                                            name="inputField"
                                            value={values.conditionsUIList[index].inputField}
                                            onChange={(data) => props.handleInpuFieldChange(data, index)}
                                            variant="outlined"
                                        />
                                    )}

                                    <div className={classes.actionIcon}>
                                        <IconButton onClick={() => props.removeConditions(index)}>
                                            <RemoveCircleOutlineIcon />
                                        </IconButton>
                                        {index === 0 &&
                                            <IconButton onClick={() => props.addConditions()}>
                                                <AddCircleOutlineIcon />
                                            </IconButton>}
                                    </div>
                                </div>;
                            })}
                    </div>

                    {/* <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.enablerule}
                                    onChange={props.handleChange}
                                    value="enablerule"
                                    color="primary"
                                    name={"enablerule"}
                                />
                            }
                            label="Match whole words only"
                        />
                    </FormGroup> */}
                    <div className={classes.mainContainer}>

                        <FormGroup row className="items-center">
                            <FormLabel component="legend">
                                Actions
                            </FormLabel>
                        </FormGroup>
                        {values.actionsUIList.length > 0 && values.actionsUIList?.map((item, index) => {
                            return <div className={classes.container} key={index} >
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        className={classes.select}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-filled"
                                        value={values.actionsUIList[index].actionTypeSelected}
                                        onChange={(data) => props.handleActionTypeChange(data, index)}>
                                        <MenuItem value="">
                                            <em>Action Type</em>
                                        </MenuItem>
                                        {actionTypesList?.map((element, i) => {
                                            return <MenuItem key={i} value={element.value}>{element.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                {(values.actionsUIList[index].actionTypeSelected == "set" ||
                                    values.actionsUIList[index].actionTypeSelected == "get" ||
                                    values.actionsUIList[index].actionTypeSelected == "remove" ||
                                    values.actionsUIList[index].actionTypeSelected == "create") &&
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <Select
                                            className={classes.select}
                                            id="demo-simple-select-filled"
                                            value={values.actionsUIList[index].dataModelSelected}
                                            onChange={(data) => props.handleDataModelActionChange(data, index)}>
                                            <MenuItem value="">
                                                <em>Select Data Model</em>
                                            </MenuItem>
                                            {props.dataModels.data?.map((item, i) => {
                                                return <MenuItem key={i} value={item._id}>{item.name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>}

                                {values.actionsUIList[index].actionTypeSelected == "set" &&
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <Select
                                            className={classes.select}
                                            id="demo-simple-select-filled"
                                            value={values.actionsUIList[index]?.dataElementSelected}
                                            onChange={(data) => props.handleElementsActionChange(data, index)}>
                                            <MenuItem value="">
                                                <em>Select Data Element</em>
                                            </MenuItem>
                                            {
                                                values.actionsUIList[index]?.dataElementList?.map((element, i) => {
                                                    return <MenuItem key={i} value={element.name}>{element.name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>}
                                {(values.actionsUIList[index].actionTypeSelected == "get" ||
                                    values.actionsUIList[index].actionTypeSelected == "remove") &&
                                    <Select
                                        className={classes.select}
                                        name="dataelements"
                                        id="grouped-native-select"
                                        variant="outlined" multiple
                                        value={values.actionsUIList[index]?.dataElementMultiSelected}
                                        // onChange={(e) => props.handleChipChange(e, 'dataModels')}
                                        onChange={(data) => props.handleMultipleDataChange(data, index)}
                                        placeholder="Select multiple"
                                        input={<Input />}
                                        renderValue={(selected) => (
                                            <div className={classes.chips}>
                                                {selected && selected.map((item, i) => (
                                                    <Chip key={i} label={item.label} className={classes.chip} />
                                                ))}
                                            </div>
                                        )}>
                                        {values.actionsUIList[index]?.dataElementMultiList 
                                        && values.actionsUIList[index]?.dataElementMultiList?.map((item, i) => (
                                            <MenuItem key={i} value={item}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                }
                                {values.actionsUIList[index].actionTypeSelected == "set" &&
                                    <TextField
                                        className={classes.select}
                                        placeholder="Value"
                                        autoFocus
                                        id="setValue"
                                        name="name"
                                        value={values.actionsUIList[index].setValue}
                                        onChange={(data) => props.handleSetValueChange(data, index)}
                                        variant="outlined"
                                    />
                                }

                                {values.actionsUIList[index].actionTypeSelected == "create" &&
                                    <TextField
                                        className={classes.select}
                                        placeholder="Data Value"
                                        autoFocus
                                        id="dataValue"
                                        name="dataValue"
                                        value={values.actionsUIList[index].dataValue}
                                        onChange={(data) => props.handleDataValueChange(data, index)}
                                        variant="outlined"
                                    />
                                }
                                <div className={classes.actionIcon}>
                                    <IconButton onClick={() => props.removeActions(index)}>
                                        <RemoveCircleOutlineIcon />
                                    </IconButton>
                                    {index === 0 && <IconButton onClick={() => props.addActions()}> <AddCircleOutlineIcon /> </IconButton>}
                                </div>
                            </div>
                        })}
                    </div>
                </form>
            </div>
        )
    )
}