import React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PDFComplexViewV2 } from 'dsilo-ui-components';
import {
  clearSuccessMsg,
  downloadContractPDFAction,
  downloadSheet,
  getContractDataById,
  getEventByID,
  updateContractAnnotations,
  getDataModelById,
  getModelData,
  getModelDataDropDown,
  initiateAction,
  updateDataModelData,
  getMembersList,
  clearReduxDataOfCurrentComponent,
  getAuditTrailsData,
  completeReviewAction,
  getDataModels,
  otherDocMove,
} from '../../store/actions';
import { Loader } from '../loader';
import { PUBLIC_PATH } from '../../constants';

const PDFComplexViewV2Component = props => {
  return (
    <div>
      <PDFComplexViewV2 {...props} loader={<Loader />} />
    </div>
  );
};

const mapDispatchToProps = {
  getContractDataById,
  updateDataModelData,
  clearSuccessMsg,
  downloadSheet,
  getModelDataDropDown,
  downloadContractPDFAction,
  getEventByID,
  initiateAction,
  getMembersList,
  clearReduxDataOfCurrentComponent,
  updateContractAnnotations,
  getDataModelById,
  getAuditTrailsData,
  getModelData,
  completeReviewAction,
  getDataModels,
  otherDocMove,
};

const mapStateToProps = state => {
  return {
    action: state.action,
    formData: state.form,
    PUBLIC_PATH: PUBLIC_PATH,
    dataModels: state.dataModels,
    appConfig: state.appConfig.app,
    members: state.appConfig.members,
    currentMember: state.appConfig.members,
  };
};

export default hot(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFComplexViewV2Component)),
);
