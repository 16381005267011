import React,{useEffect, useState} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
// import { Bar } from 'react-chartjs-2';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CircularProgress, Paper } from '@material-ui/core';
import barChartData from './barChart.json'
import { makeStyles } from '@material-ui/core/styles';
import Bar from './PieBarCharts';


// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

// export const options = {
//   responsive: true,
//   scales: {
//       x:{
//         display: true,
//         grid: {
//           display: false,
//         },
        
//       },
//       y: { 
//         display: true,
//         grid: {
//           display: false,
//         },
//       },
//     },
//   plugins: {
//     datalabels: {
//       display: true,
//       color: "black",
//       align: "middle",
//       anchor: "middle",
//       font: { size: "14" }
//     },
//     legend: {
//       display: false,
//     },
//     title: {
//       display: true,
//         text: 'Invoice Important Datapoint Extractions Results'
//     },
//   },
// };



const BarChart = (props) =>  {

  console.log('BarChart-props',props)
  const useStyles = makeStyles((theme) => ({
    circularBar: {
      height: 400,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  }));

  const classes = useStyles();

  const[data,setData]=useState(barChartData)
  
  const loading = props.loading;
  

  useEffect(()=>{
    setData(props.data)
  },[props.data])


  if (loading) {
    return <div className={classes.circularBar}><CircularProgress /></div>
  }
  
  return <Bar options={data} loading={loading} />;
}
export default BarChart