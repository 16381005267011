import { ActionTypes } from "../../constants";

export const initiateAction = (data) => dispatch => {
    dispatch({ type: ActionTypes.INITIATE_ACTION, payload: data });
}

export const getEventByID = (data) => dispatch => {
    dispatch({ type: ActionTypes.GET_EVENT_BY_ID, payload: data });
}

export const getDataByUrl = (data) => dispatch => {
    dispatch({ type: ActionTypes.GET_DATA_BY_URL, payload: data });
}

export const saveDataByUrl = (data) => dispatch => {
    dispatch({ type: ActionTypes.SAVE_DATA_BY_URL, payload: data });
}

export const updateDataByUrl = (data) => dispatch => {
    dispatch({ type: ActionTypes.UPDATE_DATA_BY_URL, payload: data });
}

export const deleteDatapoint = (data) => dispatch => {
    dispatch({ type: ActionTypes.DELETE_DATAPOINT, payload: data });
}

export const getData = (data) => dispatch => {
    dispatch({ type: ActionTypes.GET_API_DATA, payload: data });
}


export const updateBreadCrumb = (data) => dispatch => {
    dispatch({ type: ActionTypes.UPDATE_ROUTE, payload: data });
}