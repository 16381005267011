import React, { useEffect } from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import BarChart from './barChart'
export const colors = ["#5dd39a", "#ff7b33", "#2d63b6", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"]

const Charts = (props) => {

    let options1 = {
        chart: {
            type: 'column'
        },
        credits: {
            enabled: false
        },
        title: {
            text: 'Contracts by Type'
        },
        xAxis: {
            categories: ['Vendor 1', 'Vendor 2', 'Vendor 3', 'Vendor 4', 'Vendor 5', 'Vendor 6', 'Vendor 7', 'Vendor 8', 'Vendor 9', , 'Vendor 10', , 'Vendor 11', 'Vendor 12',]
        },

        plotOptions: {
            series: {
                pointWidth: 20
            }
        },

        series: [{
            data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
            color: '#2d63b6'

        }],
        lang: {
            noData: "No Data Available"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#000'
            }
        },
    }

    let options2 = {
        chart: {
            type: 'column'
        },
        credits: {
            enabled: false
        },
        title: {
            text: 'Contracts by counter party'
        },
        xAxis: {
            categories: ['Vendor 1', 'Vendor 2', 'Vendor 3', 'Vendor 4', 'Vendor 5', 'Vendor 6', 'Vendor 7', 'Vendor 8', 'Vendor 9', , 'Vendor 10', , 'Vendor 11', 'Vendor 12',]
        },

        plotOptions: {
            series: {
                pointWidth: 20
            }
        },

        series: [{
            data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
            color: '#2d63b6'

        }],
        lang: {
            noData: "No Data Available"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#000'
            }
        },
    }

    const pietaxPercent = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: 'Contracts signed/not signed'
        },
        credits: {
            enabled: false
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                colors: colors,
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: [{
            name: 'Contracts',
            colorByPoint: true,
            data: [{
                name: 'Yes',
                y: 61.41,
            }, {
                name: 'No',
                y: 38.59
            }]
        }],
        lang: {
            noData: "No Data Available"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#000'
            }
        },
    }

    const pietaxPercent1 = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: 'Contracts by age'
        },
        credits: {
            enabled: false
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                colors: colors,
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: [{
            name: 'Age',
            colorByPoint: true,
            data: [{
                name: '> 3Years',
                y: 41.41,
            }, {
                name: '1Year',
                y: 30
            }, {
                name: '2Years',
                y: 28.59
            }]
        }],
        lang: {
            noData: "No Data Available"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#000'
            }
        },
    }

    const pietaxPercent2 = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: 'Contacts by term'
        },
        credits: {
            enabled: false
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                colors: colors,
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: [{
            name: 'Age',
            colorByPoint: true,
            data: [{
                name: '3Years',
                y: 36.41,
            }, {
                name: '1Year',
                y: 25
            }, {
                name: '2Years',
                y: 23.59
            }, {
                name: 'others',
                y: 15
            }]
        }],
        lang: {
            noData: "No Data Available"
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#000'
            }
        },
    }

    const useStyles = makeStyles((theme) => ({
        paper: {
            width: '100%',
            height: '100%',
        }
    }));

    const classes = useStyles();

    return (
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <Paper elevation={2} className={classes.paper}>
                    <BarChart options={props.options1} loading={props.contractsbytype_loading} />
                </Paper>

            </Grid>
            <Grid item xs={8}>
                <Paper elevation={2} className={classes.paper}>
                    <BarChart options={props.options2} loading={props.InvoicedatabyFieldCounterParty_loading} />
                </Paper>

            </Grid>
            <Grid item xs={4}>
                <Paper elevation={2} className={classes.paper}>
                    <BarChart options={props.pietaxPercent} loading={props.InvoicedatabyFieldsigned_loading} />
                </Paper>

            </Grid>
            <Grid item xs={4}>
                <Paper elevation={2} className={classes.paper}>
                    <BarChart options={props.pietaxPercent1} loading={props.invoicedatabyfieldage_loading} />
                </Paper>

            </Grid>
            <Grid item xs={4}>
                <Paper elevation={2} className={classes.paper}>
                    <BarChart options={props.pietaxPercent2} loading={props.invoicedatabyfieldterm_loading} />
                </Paper>
            </Grid >
        </Grid >
    )
}


export default Charts;