import React, { useEffect, useState } from 'react';
import './style.scss';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from '@material-ui/core';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { resetPassword, forgotPassword, verifyUser,logoutUser, clearResetPasswordError } from '../../store/actions';
import { CircularProgress } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Typography } from '@material-ui/core';
import CustomButton from '../../components/Button/CustomButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {PUBLIC_PATH} from 'constants/index' 
import { Validations } from 'dsilo-ui-components';
import dsiloLogo from '../../images/logo.png';

const ResetPassword = props => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setCnfrmPasswordVisible] = useState(false);
  const [passwordError, setPasswordError] = useState();


  useEffect(() => {
    if(props.login.reset?.error){
      props.clearResetPasswordError()
    }
    if (props.match.params.token) {
      props.verifyUser(props.match.params);
    }
  }, []);

  const handleReset = event => {
    event.preventDefault();
    const data = { password, confirmPassword, ...props.match.params };
    if (password === confirmPassword && Validations.validatePassword(password)) {
      props.resetPassword({data,logoutUser:props.logoutUser});
    }
  };

  const handleForgotPassword = event => {
    event.preventDefault();
    console.log('email', email);
    const data = { email };
    props.forgotPassword(data);
  };
  
  const clearError = ()=> {
    if(props.login.reset?.error){
      props.clearResetPasswordError()
    }
  }

  useEffect(() => {
    if (!Validations.validatePassword(password)) {
      setPasswordError(
        'Min 8 Char, One Uppercase, One Lowercase, One Number and one special Character',
      );
    }
  }, [password]);
  

  const _forgotPassword = () => {
    return (
      <Paper className={classes.paper}>
        <h3 className={classes.title}>Forgot your password </h3>

        <form onSubmit={handleForgotPassword}>
          <FormControl fullWidth className={classes.inputField}>
            <InputLabel htmlFor="email" shrink>
              Email
            </InputLabel>
            <Input
              id="email"
              placeholder="Enter email to send reset link"
              endAdornment={
                <InputAdornment position="start">
                  <DraftsOutlinedIcon className={classes.icon} />
                </InputAdornment>
              }
              disableUnderline
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </FormControl>

          <Box mb={2}>
            {props.login.forgot?.error && (
              <p className={classes.errorMessage}>{`*${props.login.forgot?.error}`}</p>
            )}

            {props.login.forgot?.success && (
              <p className={classes.successMessage}>Reset link successfully sent to your email</p>
            )}
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                fullWidth
                classes={{ label: classes.signInButton }}
                // onClick={handleLogin}
                disabled={email === '' ? true : false}
              >
                {props.login.forgot?.isLoading ? (
                  <CircularProgress className={classes.circularBar} size={24} />
                ) : (
                  'Send Link'
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  };
  const _resetPassword = () => {
    return (
      <Paper className={classes.paper}>
        <h3 className={classes.title}>Reset your password </h3>

        <form onSubmit={handleReset}>
          <FormControl fullWidth className={classes.inputField}>
            <InputLabel htmlFor="email" shrink>
              Password
            </InputLabel>
            <Input
              type={passwordVisible ? 'text' : 'password'}
              id="password"
              placeholder="Enter Password"
              endAdornment={
                <InputAdornment position="start">
                  <IconButton onClick={() => setPasswordVisible(!passwordVisible)}>
                    {passwordVisible ? (
                      <Visibility className={classes.passwordShow} fontSize="small" />
                    ) : (
                      <VisibilityOff className={classes.passwordShow} fontSize="small" />
                    )}
                  </IconButton>
                  <LockOutlinedIcon className={classes.icon} />
                </InputAdornment>
              }
              disableUnderline
              value={password}
              onChange={e => {setPassword(e.target.value);clearError()}}
              required
            />
          </FormControl>
          <FormControl fullWidth className={classes.inputField}>
            <InputLabel htmlFor="password" shrink>
              Confirm Password
            </InputLabel>
            <Input
              type={confirmPasswordVisible ? 'text' : 'password'}
              id="confirmPassword"
              placeholder="Enter Confirm Password"
              endAdornment={
                <InputAdornment position="start">
                  <IconButton onClick={() => setCnfrmPasswordVisible(!confirmPasswordVisible)}>
                    {confirmPasswordVisible ? (
                      <Visibility className={classes.passwordShow} fontSize="small" />
                    ) : (
                      <VisibilityOff className={classes.passwordShow} fontSize="small" />
                    )}
                  </IconButton>
                  <LockOutlinedIcon className={classes.icon} />
                </InputAdornment>
              }
              disableUnderline
              value={confirmPassword}
              onChange={e => {setConfirmPassword(e.target.value);clearError()}}
              required
            />
          </FormControl>
          <Box mb={2}>
            {password !== confirmPassword && (
              <p className={classes.errorMessage}>Passwords doesn't match</p>
            )}
            {props.login.reset?.error && (
              <p className={classes.errorMessage}>{`*${props.login.reset?.error}`}</p>
            )}

            {props.login.reset?.success && (
              <p className={classes.successMessage}>Reset Successfully </p>
            )}
            {password !== '' && !Validations.validatePassword(password) && (
                <p className={'error-message'}>{`*${passwordError}`}</p>
            )}
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                fullWidth
                classes={{ label: classes.signInButton }}
                // onClick={handleLogin}
                disabled={password === '' || confirmPassword === '' ? true : false}
              >
                {props.login.reset?.isLoading ? (
                  <CircularProgress className={classes.circularBar} size={24} />
                ) : (
                  'Reset Password'
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  };

  //   const { isLoading } = props.login.reset;
  return props.history.location.pathname === '/forgotpassword' ||
    props.login.verifyUser?.success ? (
    <Box className="login-sec login-ciep" display="flex" alignItems="center" justifyContent="flex-end">
      <div className="login">
      <div className="carousal-container">
        <div className="carousal">
          {/* <h1>dsilo</h1> */}
          <img src={dsiloLogo} alt="" />
          <p>ProcureGPT&trade; - AI Platform</p>
          <Box display="flex">
            <div className={classes.selectedCarousal} />
            <div className={classes.unSelectedCarousal} />
            <div className={classes.unSelectedCarousal} />
          </Box>
        </div>
       </div> 
        <div className="infoContainer">
          <Paper className={classes.header}>
            <h1>dsilo</h1>
          </Paper>

          {props.match.params.token ? _resetPassword() : _forgotPassword()}

          <Paper className={classes.bottomPaper} elevation={0}>
            {/* <Button
              variant="outlined"
              classes={{ label: classes.signInButton }}
              fullWidth
              onClick={() => handleLoginWithAzure()}
            > */}
            {/* <img src={GoogleLogo} height="30" width="30" className={classes.googleImage} /> */}
            {/* <img src={AzureLogo} height="30" className={classes.AzureLogo} />
              Sign in using Azure
            </Button> */}
            {props.match.params.token ? <Link  onClick={()=>{props.history.push(`/${PUBLIC_PATH}login`);props.logoutUser()}}>
              <p className={classes.resetPassword}>Go to Login</p>
            </Link>:
            <Link to={`/${PUBLIC_PATH}login`}>
              <p className={classes.resetPassword}>Go to Login</p>
            </Link>}
          </Paper>
        </div>
      </div>
    </Box>
  ) : props.login.verifyUser?.isLoading && props.login.verifyUser?.error === '' ? (
    <Box
      style={{ height: '100vh', color: '#1761b9' }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <CircularProgress size={60}></CircularProgress>
      <Box mt={3}>
        <Typography>Verifying User</Typography>
      </Box>
    </Box>
  ) : (
    <div className="login-sec">
      <Box display="flex" justifyContent="center" alignItems="center" height={1}>
        {!props.login.verifyUser?.isLoading && (
          <Card className={classes.card}>
            <CardHeader
              title={<h3 style={{ color: '#fff' }}>Verification</h3>}
              className={classes.cardHeader}
            />
            <CardContent className={classes.cardContent}>
              {props.login.verifyUser?.error && (
                <Typography variant="h6">
                  <strong>{props.login.verifyUser?.error}</strong>
                </Typography>
              )}

              {/* {props.login.verifyUser?.success && (
              <Typography variant="h6">
              <strong>{props.login.verifyEmail?.user?.message}</strong>
              </Typography>
            )} */}
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Link to= {`/${PUBLIC_PATH}login`}>
                <CustomButton variant="contained" color="primary">
                  Sign In
                </CustomButton>
              </Link>
            </CardActions>
          </Card>
        )}
      </Box>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    padding: '30px 50px',
    borderRadius: 0,
  },
  header: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: '#1761b9',
    textAlign: 'center',
    color: '#fff',
    padding: '20px',
  },

  title: {
    fontWeight: 'bold',
    color: '#002744',
  },
  buttonContainer: {
    marginTop: '20px',
  },
  bottomPaper: {
    borderTop: '1px solid #ececec',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    padding: '20px 50px',
    display: 'flex',
    // justifyContent: 'center',

    justifyContent: 'flex-end',
  },
  signInButton: {
    paddingTop: 2,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  inputField: {
    margin: '20px 0',
    borderBottom: '2px',
  },
  icon: {
    color: '#C5C5D5',
    fontSize: 20,
  },
  googleImage: {
    marginRight: 10,
  },
  AzureLogo: {
    marginRight: 10,
  },
  link: {
    textDecoration: 'none',
  },
  selectedCarousal: {
    backgroundColor: '#1761b9',
    width: 12,
    height: 12,
    marginRight: 10,
    borderRadius: 100,
    border: '1.5px solid #fff',
  },
  unSelectedCarousal: {
    backgroundColor: '#B3A698',
    width: 12,
    height: 12,
    marginRight: 15,
    borderRadius: 100,
    border: '1.5px solid #B3A698',
  },
  circularBar: {
    color: '#ffff',
    height: 20,
    width: 20,
  },
  errorMessage: {
    color: '#ff0000',
    fontSize: '14px',
  },
  successMessage: {
    color: 'green',
    fontSize: '14px',
  },
  resetPassword: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#1761b9',
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      textDecorationThickness: '2px',
    },
  },

  card: {
    minWidth: 600,
    borderRadius: 10,
  },
  cardHeader: {
    backgroundColor: '#1761b9',
  },
  cardContent: {
    padding: '30px 50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardActions: {
    padding: '20px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  passwordShow: {
    color: '#C5C5D5',
  },
}));

const mapDispatchToProps = {
  forgotPassword,
  resetPassword,
  verifyUser,
  logoutUser,
  clearResetPasswordError,
};
const mapStateToProps = state => {
  return {
    login: state.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
