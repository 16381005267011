import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { CircularProgress, Paper } from '@material-ui/core';
import { Helpers } from 'dsilo-ui-components';


const LabelColorBox = (props) => {

  const useStyles = makeStyles((theme) => ({
    paperLabel: {
      padding: '2px',
      color: 'grey',
      boxShadow: "none !important",
      // paddingLeft: "4%",
      // paddingTop: "7%",
      marginBottom: '18px',
      textAlign: "center"
    },
    paperValue: {
      color: 'black',
      fontSize: 'xxx-large',
      fontWeight: 'bold',
      boxShadow: "none !important",
      paddingTop: 'inherit',
      marginBottom: '10px',
      textAlign: "center"
    },
    Icon: {
      // paddingRight: "17%",
      // paddingTop: "7%"
    },
    paperChart: {
      display: "flex",
      justifyContent: "space-between",
      paddingRight: 6,
      // paddingBottom:13
    },
    paperLineChart: {
      width: 100,
    },
    paperdoughnutChart: {
      width: 86,
      marginTop: '-17px'
    },
    labelValue: {
      marginTop: "30px"
    },
    grid: {
      marginTop: 20,
      marginBottom: 20,
    },
    gridContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    button: {
      padding: "3px !important"
    },
    spanTag: {
      fontWeight: 700
    },
    circularBar: {
      height: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  }));

  const classes = useStyles();

  const { labelData } = props
  console.log('props.xs', props.xs)

  return (
    <Paper elevation={2} style={{ marginBottom: 10 }}>
      <div className={classes.gridContainer} >
        {labelData && labelData.map((item, index) => {
          return (
            <div key={index} className={classes.grid} style={{
              borderRight: props.labelData?.length - 1 !== index ? '1px solid #ebedf2' : 'none',
              width: (100 - labelData.length) + '%'
            }} >
              {
                item.isLoading ? <div className={classes.circularBar} ><CircularProgress size={32} /></div> :
                  <>
                    <div style={{ display: "grid", }}>
                      <span className={classes.paperLabel}>{item.label}</span>
                      <span className={classes.paperValue}>{item.str ? (item.value) : Helpers.convertToNumber(item.value, 2)?.toLocaleString() || 0}</span>
                    </div>

                  </>
              }
            </div>
          )
        })
        }
      </div>
    </Paper>
  );
}

export default LabelColorBox


export const data = {
  labels: ['No of Invoice', 'No of Invoice', 'No of Invoice'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'green',
        'orange',
        'blue',
        'red',
        'yellow',
        'pink',
      ],
    },
  ],
};