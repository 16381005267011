import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import classnames from "classnames";
import { Helpers } from "dsilo-ui-components";
import React, { useEffect, useState } from "react";
import { Loader } from "../../../loader";
import { isStringArray, roundOffToTwoDecimalPlaces } from '../../ChatHelper';
import useStyles from "./styles";

const rowsPerPageOptions = [10, 25, 50, 100];
const hiddenColumns = ["key_pk", "isActive" ,"id" , "_id", "fieldName", "tableName", "chunk_location"];

const SimpleTable = (props) => {
	const classes = useStyles()
	// const [displayText, setDisplayText] = useState("");
	const [value, setValue] = useState('');
	const [table, setTable] = useState(null);
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(0);
  	const [rowsPerPage, setRowsPerPage] = useState(10);

	const { data, message } = props

	useEffect(() => {
		if (data?.count) {
			setValue(data?.count)
		}
		setTable(data);
		setLoading(false);
	}, [data]);

	const getHeaders = (table) => {
		return Object.keys(table[0]).filter(columnName => !hiddenColumns.includes(columnName)).map((ii, index) => {
			// TODO: We need to make it more dynamic
			return (
				<TableCell key={ii+index.toString()}>
					{" "}
					{Helpers.camelCaseToSentenceCase(ii)}
				</TableCell>
			)
		})
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	/**
	 * This function checks and returns the formatted value
	 */
	const checkAndFormat = (rowValue) => {		
		if (rowValue !== null && rowValue !== undefined) {
			switch (true) {
				// case typeof rowValue === 'string' && rowValue.includes("$"): {
				// 	rowValue = rowValue.replace("$", "");
				// 	if (rowValue && !isNaN(rowValue)) {
				// 		rowValue = `$${Helpers.numFormatter(parseFloat(rowValue))}`
				// 	}
				// 	return rowValue;
				// }
				case typeof rowValue === 'string' && rowValue.length > 50: {
					const shortText = rowValue.slice(0, 50)?.trim() + '...';
					return shortText;
				}
				case typeof rowValue === 'number': {
					rowValue = roundOffToTwoDecimalPlaces(rowValue);
					return rowValue;
				}
				case isStringArray(rowValue): {
					return rowValue.join(', ');
				}
				case typeof rowValue === 'string' && rowValue.toLowerCase() === 'nan': {
					return '-';
				}
				default:
					return rowValue
			}
		}
		return '';
	}

	return (
		<Grid item xs={12} 
			{...(props?.useFullWidth && {
				style: {
					width: '-webkit-fill-available'
				}
			})}
		>
			<div className={classes.table}>
				<div className={classnames(classes.card)}>
					{loading ? <Loader /> : (
						<>
							{/* <span className={classes.paperLabel}>{displayText}</span> */}
							{/* <span className={classes.paperLabelValue}>{`${value}`}</span> */}
							<TableContainer>
								<Table stickyHeader aria-label="sticky table">
									<React.Fragment>
										<TableHead style={{ cursor: "pointer" }}>
											<TableRow> {table?.length > 0 && getHeaders(table)} </TableRow>
										</TableHead>
										<TableBody>
											{table && table.length
												? table.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item1, index1) => (
													<TableRow key={index1} >
														<React.Fragment>
														{Object.keys(item1).filter(columnName => !hiddenColumns.includes(columnName)).map((item2, index2) => {
																let backgroundColor
																if (item1[item2] && typeof item1[item2] === 'object' && "misMatch" in item1[item2]) {
																	backgroundColor = item1[item2].misMatch === 0
																		? "#3dc53e"
																		: "#fd3b3b"
																}
																let rowValue = item1?.[item2] || '';
																if (item1[item2] && typeof item1[item2] === 'object' && item1[item2]?.value) {
																	rowValue = item1[item2]?.value
																}
																const formattedValue = checkAndFormat(rowValue)
																return (
																	<TableCell key={index2} style={backgroundColor ? { backgroundColor } : {}}>
																		{typeof rowValue === 'string' && rowValue.length > 50 ? (
																			<Typography variant='caption' title={rowValue}  style={{ fontSize: '0.875rem' }}>
																				{formattedValue}
																			</Typography>
																		) : (
																			formattedValue
																		)}				
																	</TableCell>
																);
															})}
														</React.Fragment>
													</TableRow>
												)) 
												: <Box style={{
													textAlign: "center",
													margin: "1rem"
												}}>
													{"No data available"}
												</Box>
											}
										</TableBody>
									</React.Fragment>
								</Table>
							</TableContainer>
							{table.length > 0 && <TablePagination
								rowsPerPageOptions={rowsPerPageOptions}
								component='div'
								count={table.length || 0}
								style={{ color: "rgba(0, 0, 0, 0.87)" }}
								rowsPerPage={Number(rowsPerPage)}
								page={page}
								backIconButtonProps={{
									"aria-label": "Previous Page"
								}}
								nextIconButtonProps={{
									"aria-label": "Next Page"
									// 'marginTop': '-18px'
								}}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>}
						</>
					)}
				</div>
			</div>
		</Grid>
	)
}

export default SimpleTable;