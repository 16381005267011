const labelTextStyle = {
    "backgroundColor": "#e6e6e6",
    "color": "#112840",
    "labelFontWeight": "bold",
    "valueFontSize": "33px",
    "valueFontWeight": "bold",
}

const labelBoxConfig = [
    {
        "label": "Processed Documents",
        "dataElement": "processedDocuments",
        "metric": "count",
        "value": 0,
        "styles": labelTextStyle
    },
    {
        "label": "Invoices",
        "dataElement": "noOfInvoices",
        "metric": "count",
        "value": 0,
        "styles": labelTextStyle
    },
    {
        "label": "Credit Memos",
        "dataElement": "noOfCreditmemos",
        "metric": "count",
        "value": 0,
        "styles": labelTextStyle
    },
    {
        "label": "Deduction Worksheets",
        "dataElement": "noOfDeductionworksheets",
        "metric": "count",
        "value": 0,
        "styles": labelTextStyle
    },
    {
        "label": "Vendors",
        "dataElement": "noOfVendors",
        "metric": "count",
        "value": 0,
        "styles": labelTextStyle
    },
    {
        "label": "Customers",
        "dataElement": "noOfCustomers",
        "metric": "count",
        "value": 0,
        "styles": labelTextStyle
    }
]

export const labelColorBoxConfig1 = [
    {
        "label": "Total Documents",
        "dataElement": "totalDocuments",
        "metric": "count",
        "value": 0,
        "styles": labelTextStyle
    },
    ...labelBoxConfig
];

export const labelColorBoxConfig2 = labelBoxConfig;

export const hcOptions = {
	maintainAspectRatio: true
};

export const generateHighChartData = (data) => {
    const {
        chartType = 'bar',
        enableOnChartClick = false,
        responseData,
        generateMultiColumnChart = false,
        title = ''
    } = data;
    let highchartData = null;
    if(responseData && responseData.length > 0) {
        let labels = [],
            datasets = [],
            name = '';
        const keysLength = Object.keys(responseData[0]).length;
        if (keysLength === 2 || keysLength === 3) {
            if (generateMultiColumnChart) {
                const dataMap = {};
                responseData.forEach(item => {
                // Iterate through each entry in the data
                    for (const key in item) {
                        if (key !== 'date') {
                            // Check if the entry is not a date
                            if (!dataMap[key]) {
                                // If the key doesn't exist in the dataMap, create an array and initialize with 0 values
                                dataMap[key] = Array(responseData.length).fill(0);
                            }
                            // Find the index based on the date and update the value
                            const index = responseData.findIndex(d => d.date === item.date);
                            dataMap[key][index] = item[key];
                        } else {
                            labels.push(item[key])
                        }
                    }
                });

                // Create the final result array
                const finalResult = Object.keys(dataMap).map(key => ({
                    name: key,
                    data: dataMap[key],
                    color: key?.toLowerCase() === "inbound" ? "#003366" : "rgb(80, 127, 0)"
                }));

                datasets = finalResult;
                // console.log('generateMultiColumnChart ::: *** ', finalResult);
            } else {
                responseData.forEach(obj => {
                    let values = Object.values(obj);
                    if (values && values.length > 0) {
                        values.forEach((value, index) => {
                            // const newValue =
                            //     value && typeof value === 'string' ? value.replace('$', '') : value || '';
                            //     console.log('newValue ::: *** ', newValue, typeof newValue);
                            // if (newValue && !isNaN(newValue)) value = parseFloat(newValue);
                            if (typeof value === 'number') {
                                datasets.push(value);
                            } else if (typeof value === 'string') {
                                if (!name) {
                                    name = Object.keys(obj)[index];
                                }
                                labels.push(value);
                            } else {
                                labels.push('NA');
                            }
                        });
                    }
                });
            }
            const chartDataset = generateMultiColumnChart ? datasets : [
                    {
                        name: title,
                        data: datasets,
                    },
                ],
            //To support the highcart default chart structure
            highchartData = {
                chartData: {
                    title: '',
                    data: {
                        labels,
                        datasets: chartDataset
                    },
                    colors: ["#003366"],
                    type: chartType,
                    enableOnChartClick: enableOnChartClick,
                    showInLegend: generateMultiColumnChart,
                    plotOptions: {
                        allowPointSelect: enableOnChartClick,
                        colorByPoint: false,
                        dataLabels: {
                            enabled: true
                        },   
                    }
                },
            };
            return highchartData;
        }
    }
    return highchartData;
}
