import { PDFViewer } from "dsilo-ui-components";
import { PdfjsViewer } from "dsilo-ui-components";
import React, { useEffect, useState } from "react";
import { hot } from "react-hot-loader/root";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { downloadContractPDFAction, clearReduxDataOfCurrentComponent } from '../../store/actions';
import { Loader } from "../loader";


const PDFViewerComponent = (props) => {

    const { page, data } = props;
    const { downloadedPDF, loading1 } = props.formData;
    const [pdf, setPdf] = useState(null)
    const [fetched, setDataFetched] = useState(false);

    useEffect(() => {
        if (page && !fetched) {
            setDataFetched(true)
            let payload = {
                appid: props.match.params.appid,
                _id: props.match.params.id,
                isDownload: false,
                openFile: false,
                dataModelId: data.chartData.selectedDataModel,
            };
            props.downloadContractPDFAction(payload);
        }
    }, [page])


    useEffect(() => {
        if (downloadedPDF) {
            setPdf(new Blob([downloadedPDF], { type: 'application/pdf' }))
        }
    }, [downloadedPDF])

    if (loading1) {
        return <Loader />
    }

    return (
        <div>
            {/* <PDFViewer pdf={pdf} /> */}
            {props.pdf && <PdfjsViewer {...props} pdf={props.pdf} />}
        </div>
    )
}


const mapDispatchToProps = {
    downloadContractPDFAction,
    clearReduxDataOfCurrentComponent
};

const mapStateToProps = state => {
    return {
        formData: state.form,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFViewerComponent)));
