import { hot } from 'react-hot-loader/root';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import { connect } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmDialog = (props) => {
	let { open, handleClose, alertMessageText, classes, confirm, type } = props

	return (<>
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			disableBackdropClick={true}
			disableEscapeKeyDown={true}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			className={classes.confirmationDialoge}
		>
			<DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#047dd8" }}>
				{typeof alertMessageText == 'object' && alertMessageText.title ? alertMessageText.title : "Confirmation"}
			</DialogTitle>
			<DialogContent className={classes.dialog}>
				<DialogContentText component="div" id="alert-dialog-slide-description" className={classes.para}>
					{typeof alertMessageText == 'object'
						? <>
							{alertMessageText.p1 &&
								<h4 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '20px 0' }}>
									{alertMessageText.p1} </h4>}
							{alertMessageText.p2 && <h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '10px 0' }}>
								{alertMessageText.p2} </h6>}
							{alertMessageText.p3 && <h3 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '10px 0' }}>
								{alertMessageText.p3} </h3>}
						</>
						: <h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '10px 0' }}>{alertMessageText}</h6>}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				{
					confirm ?
						<React.Fragment>
							<Button
								color="primary" onClick={() => {
									handleClose(true)
								}}
								className="btn btn-primary btn-color border-radius-0 text-color-white">
								{"YES"}
							</Button>
							<Button
								color="primary" onClick={() => handleClose(false)}
								className="btn btn-primary btn-color border-radius-0 text-color-white">
								{"NO"}
							</Button>
						</React.Fragment>
						:
						<Button
							color="primary" onClick={() => handleClose()}
							className="btn btn-primary btn-color border-radius-0 text-color-white">
							{"OK"}
						</Button>
				}
			</DialogActions>
		</Dialog>

	</>)
}

const mapStateToProps = state => {
	return {
		user: state.user,
		pageLoading: state.pages.loading,
		member: state.appConfig.member,
		memberLoading: state.appConfig.memberLoading,
		entity: state.appConfig.entity ? state.appConfig.entity : {}
	};
};
export default hot(withRouter(connect(mapStateToProps, null)(ConfirmDialog)));
