/**
 * @module Sagas/App
 * @desc App
 */
import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { themeService } from 'modules/api/index'
import { ActionTypes } from 'constants/index';

/**
 * Application
 *
 * @param {Object} action
 *
 */

async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

function* fetchTheme(action) {
    try {
        const appConfig = yield fetchAsync(themeService.getTheme, action.appId);
        yield put({type: ActionTypes.LOAD_THEME_SUCCESS, payload: appConfig});
    } catch (e) {
        yield put({type: ActionTypes.LOAD_THEME_ERROR, error: e.message});
    }
}

export function* watchThemeSaga() {
    yield takeEvery(ActionTypes.LOAD_THEME_LOADING, fetchTheme);
}

export default watchThemeSaga;
