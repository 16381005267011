import React, { useEffect, useState } from "react"
import { handleCommonMasterDataConfigOnLoad, updateCommonMasterDataResponseToComponent } from 'dsilo-ui-components'

import StepWithBlocksComponentView from "./view";
import useStyles from './styles';
import { fetchSingleOpportunityPayload, fetchUpdateOpportunityPayload } from '../utils/payload';

const StepWithBlocksComponent = (props) => {
    const classes = useStyles()

    const { page, members, data, formData, selectedStep, appId, orgId, setSaveAndNext, saveAndNext, invokeSubmit, handleNext, loader, currentOpportunityId } = props;
    const { document } = formData
    const { chartData, chartUniqueId } = data;

    let payloadForMasterData = {
        docId: currentOpportunityId,
        appId: appId,
        dataUniqueId: chartUniqueId,
        dmId: selectedStep.dataModel,
    }

    const [enabledBlocks, setEnabledBlocks] = useState([])
    const [dataTabEdited, setDataTabEdited] = useState(false)
    const [currentDataModel, setCurrentDataModel] = useState({})
    const [json, setJson] = useState({});
    const [blockState, setBlockState] = useState(json)
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [keyName, setKeyName] = useState("");
    const [commentTypeFieldState, setCommentTypeFieldState] = useState("")
    const [modifiedFields, setModifiedFields] = useState({})

    const [isOnLoad, setIsOnLoad] = useState(true)
    const [summaryBlockHeaders, setSummaryBlockHeaders] = useState([])
    const [commonMasterDataConfigData, setCommonMasterDataConfigData] = useState(null)
    const [commonMasterDataConfiguredElements, setCommonMasterDataConfiguredElements] = useState([])
    const [commonMasterDataFullDropDownKeys, setCommonMasterDataFullDropDownKeys] = useState([])

    const [masterDataStoreObject, setMasterDataStoreObject] = useState({})

    const [accordionSupport, setAccordionSupport] = useState({})

    // Below UseEffect is used for calling fetching DataModel Data and Fetching current Opportunity Data
    useEffect(() => {
        if (selectedStep?.blocks?.length) {
            let accordion = {};
            let b = selectedStep.blocks.filter((block => {
                if (block.accordion) {
                    accordion[block.name] = block.accordion
                }
                return block.enable
            }))
            setEnabledBlocks(b)
            setAccordionSupport(accordion)
        }
        fetchDataModel()
        // fetchAllOpportunities()
        fetchSingleOpportunity()
        // deleteOpportunity()
        // onSaveClick()
        // fetchAllActionsOfOpportunity()
        // fetchSingleActionOpportunity()
        // createActionForOpportunityData()


    }, [selectedStep])

    useEffect(() => {
        if (enabledBlocks.length > 0 && currentDataModel?.dataElements && !selectedStep?.readOnly) {
            const dataElements = currentDataModel.dataElements || []
            if (dataElements?.length) {
                let fdes = []
                enabledBlocks.forEach(block => {
                    if (block.type === "summaryItems" && block.config?.dataElements) {
                        let blockfdes = JSON.parse(JSON.stringify(block.config.dataElements))
                        blockfdes = blockfdes.map(fde => {
                            let sde = dataElements.find(de => de.name === fde.value)
                            if (sde?.name) {
                                return {
                                    ...sde,
                                    ...fde
                                }
                            } else {
                                return fde
                            }
                        })
                        fdes = fdes.concat(blockfdes)
                    }
                })
                setSummaryBlockHeaders(fdes)
            }
        }
    }, [enabledBlocks, currentDataModel, selectedStep?.readOnly])

    useEffect(() => {
        console.log("commonMasterData handleCommonMasterDataConfigOnLoad", props.viewMode, summaryBlockHeaders)
        if (summaryBlockHeaders.length > 0 && currentDataModel) {
            console.log("commonMasterData It is before handling handleCommonMasterDataConfigOnLoad")
            handleCommonMasterDataConfigOnLoad(summaryBlockHeaders, currentDataModel, {}, setCommonMasterDataConfigData, setCommonMasterDataConfiguredElements, payloadForMasterData, props.getModelData, props.fetchmasterDataForDataelementUsingRules, '', props.formData)
        }
    }, [summaryBlockHeaders])

    useEffect(() => {
        if (!selectedStep?.readOnly && commonMasterDataConfigData) {
            updateCommonMasterDataResponseToComponent(props, false, commonMasterDataConfigData, commonMasterDataFullDropDownKeys, chartUniqueId, document, '', blockState, [], modifiedFields, masterDataStoreObject, setBlockState, null, null, null, null, setModifiedFields, setMasterDataStoreObject)
        }
    }, [JSON.stringify(document)])

    // Below function is used for fetching All Opportunities Data (not using currently)
    const fetchAllOpportunities = () => {
        let payload = {
            appId: appId,
            orgId: orgId,
            dataModelId: selectedStep.dataModel,
            dataUniqueId: chartUniqueId + '_getallopportunities'
        }
        props.getAllOpportunities(payload)

    }

    // Below function is used for fetching Single Opportunity Data.
    const fetchSingleOpportunity = () => {
        console.log("props.match.params ===> ", props.match.params);
        let payload = fetchSingleOpportunityPayload(appId, orgId, selectedStep, props.match.params.id, chartUniqueId)
        props.getSingleOpportunity(payload)

    }

    // Below UseEffect is used for updating the State variable after getting the current Opportunity Data
    useEffect(() => {
        if (props.formData?.document[chartUniqueId + '_getsingleopportunity']) {
            let obj = props.formData?.document[chartUniqueId + '_getsingleopportunity'] || {}
            setJson(obj);
            setBlockState(obj)
        }
    }, [props.formData?.document[chartUniqueId + '_getsingleopportunity']])


    // Below function is used for Deleting Opportunity Data (not using currently)
    const deleteOpportunity = (isDelete) => {
        if (isDelete) {
            const payload = {
                appId: appId,
                dataUniqueId: chartUniqueId + '_delete_opportunity',
                orgId: orgId,
                dataModelId: selectedStep.dataModel,
                id: idsList // need to pass Ids in list which need to be deleted
            }
            props.deleteOpportunity(payload)
        }
        // setDeleteConfirm({ ...deleteConfirmState, isOpen: false, data: null })             // for closing the delete model
    }


    // Below function is used for fetching All actions of the Opportunities Data (not using currently)
    const fetchAllActionsOfOpportunity = () => {
        let payload = {
            appId: appId,
            orgId: orgId,
            dataModelId: selectedStep.dataModel,
            opportunityId: props.match.params.opportunityId,                       // need to set in url and get it from url
            dataUniqueId: chartUniqueId + '_getallactionsofopportunity'
        }
        props.getAllActionsOfOpportunity(payload)

    }

    // Below function is used for fetching single action of the Opportunity Data (not using currently)
    const fetchSingleActionOpportunity = () => {
        let payload = {
            appId: appId,
            orgId: orgId,
            dataModelId: selectedStep.dataModel,
            opportunityId: props.match.params.opportunityId,                     // need to set in url and get it from url
            actionId: props.match.params.actionId,
            dataUniqueId: chartUniqueId + '_getsingleactionopportunity'
        }
        props.getSingleActionsOfOpportunity(payload)

    }

    // Below function is used for creating action for Opportunities Data (not using currently)
    const createActionForOpportunityData = (data) => {
        let payloadData = {
            appId: appId,
            dataModelId: selectedStep.dataModel,
            dataUniqueId: chartUniqueId + '_createActionForOpportunity',
            payload: {
                // documentId: props.match.params.id,                          // need to ge this keys which we need to pass while creating the action for opportunity
                // dataModelId: selectedStep.dataModel,
                // appId: appId,
                // name: data?.name,
                // table: data?.table
            }
        }
        props.createActionForOpportunity(payloadData);
    }

    // Below UseEffect is used for invoking the Update Opportunity Function on Save and Next button click
    useEffect(() => {
        invokeSubmit && saveAndNext ? onSaveClick() : null
    }, [invokeSubmit])

    // Below function is used for on Save and Next click handling
    const onSaveClick = () => {
        console.log("it is data to save ===", blockState, "enabled JSON ===>>> ", JSON.stringify(enabledBlocks))
        let blockStateObject = JSON.parse(JSON.stringify(blockState))
        // if (blockStateObject['_id']) {
        //     blockStateObject['id'] = blockStateObject['_id'];
        //     delete blockStateObject._id;
        // }
        const result = {};

        if ([blockStateObject]?.length) {
            let fdes = []
            if (enabledBlocks.length) {
                enabledBlocks.forEach(block => {
                    const config = block.config;
                    if (config) {
                        if (Array.isArray(config)) {
                            // If config is an array
                            config.forEach(item => {
                                item.dataElements.forEach(element => {
                                    const key = element.value;
                                    // const label = element.stateVariable;

                                    // if(label){
                                    //     let payload = commentsPayload(label, key)
                                    //     result[key] = [ ...blockState[key], payload]
                                    // }
                                    if (commentTypeFieldState && Object.keys(commentTypeFieldState)) {
                                        Object.keys(commentTypeFieldState).forEach(key => {
                                            let v = {
                                                // datetime contains when the comment was created
                                                datetime: new Date().toISOString(),
                                                // comment contains the comment text
                                                comment: commentTypeFieldState[key],
                                                // by contains who created the comment
                                                by: {
                                                    "id": members.userId,
                                                    "name": members.name
                                                },
                                                label: ""
                                            }
                                            if (result[key]) {
                                                result[key] = [...blockStateObject[key], v]
                                            } else {
                                                result[key] = [v]
                                            }
                                        })
                                    }
                                    else if (blockStateObject[key] == undefined) {
                                        result[key] = "";
                                    } else {
                                        result[key] = blockStateObject[key];
                                    }
                                });
                            });
                        } else {
                            // If config is an object
                            config.dataElements.forEach(element => {
                                const key = element.value;
                                const label = element.stateVariable;

                                // if(label){
                                //     let payload = commentsPayload(label, key)
                                //     result[key] = [ ...blockState[key], payload]
                                // }
                                if (commentTypeFieldState && Object.keys(commentTypeFieldState)) {

                                    Object.keys(commentTypeFieldState).forEach(key => {

                                        let v = {
                                            // datetime contains when the comment was created
                                            datetime: new Date().toISOString(),
                                            // comment contains the comment text
                                            comment: commentTypeFieldState[key],
                                            // by contains who created the comment
                                            by: {
                                                "id": members.userId,
                                                "name": members.name
                                            },
                                            label: ""
                                        }
                                        if (result[key]) {
                                            result[key] = [...blockStateObject[key], v]
                                        } else {
                                            result[key] = [v]
                                        }
                                    })
                                }
                                else if (blockStateObject[key] == undefined) {
                                    result[key] = "";
                                } else {
                                    result[key] = blockStateObject[key];
                                }
                            });
                        }
                    }
                });
                console.log("Save result ===>>>", result);
            }
        }

        let payloadData = fetchUpdateOpportunityPayload(appId, selectedStep, props.match.params.id, chartUniqueId, result)
        console.log("it is payload to update updateOpportunity api Identify ===>>>", payloadData, result)
        props.updateOpportunity(payloadData);

    }


    // Below UseEffect is used for taking the user to next step after Opportunity Data successfully saved
    useEffect(() => {
        if (props.formData?.document[chartUniqueId + '_updateopportunity']?._id) {
            let obj = props.formData?.document[chartUniqueId + '_updateopportunity'] || {}
            setJson(obj);
            setBlockState(obj)
            setCommentTypeFieldState("")
            invokeSubmit && handleNext()
        } else {
            console.log("Error in Update Identify Step");
        }
    }, [props.formData?.document[chartUniqueId + '_updateopportunity']])

    // Below UseEffect is used for Data Elements of respective DataModel
    useEffect(() => {
        if (props.formData?.dataModel[chartUniqueId + '_dataElements']?.dataElements) {
            let allDes = props.formData?.dataModel[chartUniqueId + '_dataElements']?.dataElements || []
            let obj = {}
            allDes.forEach(de => {
                obj[de.name] = de
            })
            console.log('allDes ::: *** ', allDes, obj);
            setAllDataElementsObj(obj)
        }
    }, [props.formData?.dataModel[chartUniqueId + '_dataElements']])

    // Below function is used for fetching Datamodel data
    const fetchDataModel = () => {
        let payload = {
            appid: appId,
            orgId: orgId
        }
        if (selectedStep?.dataModel) {
            if (!formData?.dataModel?.[selectedStep.dataModel]) {
                if (!formData?.dataModel?.[selectedStep.dataModel] && !formData[`${selectedStep.dataModel}_loading`]) {
                    payload['dataModelId'] = selectedStep.dataModel
                    payload['dataUniqueId'] = selectedStep.dataModel
                    props.getDataModelById(payload)
                }
            } else {
                setCurrentDataModel(formData?.dataModel?.[selectedStep.dataModel])
            }
        }
    }

    // Below UseEffect is used for saving the DataModel Data into state variable
    useEffect(() => {
        if (formData?.dataModel?.[selectedStep.dataModel]) {
            setCurrentDataModel(formData?.dataModel?.[selectedStep.dataModel])
        }
    }, [formData?.dataModel])

    // Below function is used for handling the onchange of the fields in the steps.
    const handleOnChange = (name, value, type, uiFieldType, header, valueObj) => {
        console.log("cmng here handleOnChange ===>>> ", name, value, type, uiFieldType, header, valueObj);
        let v = value;
        if (header?.type === "namepicker" && valueObj) {
            v = {
                id: valueObj?._id,
                name: valueObj?.name
            }
        }
        if (header?.type == "comments") {
            setCommentTypeFieldState({
                ...commentTypeFieldState,
                [header.value || header.name]: v
            })
        } else {
            setBlockState({
                ...blockState,
                [name]: v
            })
            setModifiedFields({
                ...modifiedFields,
                [name]: v
            })
        }
        setSaveAndNext(true)
    }

    // Below function is used for handling the array of checkbox type.
    const handleCheckboxChange = (name, value) => {
        setSaveAndNext(true)
        setBlockState(prevBlockState => {
            // Ensure blockState[name] is an array
            const currentOptions = Array.isArray(prevBlockState[name]) ? prevBlockState[name] : [];
            return {
                ...prevBlockState,
                [name]: currentOptions.includes(value)
                    ? currentOptions.filter(option => option !== value)
                    : [...currentOptions, value]
            };
        });
    };

    // Below function is used for fetching updated Opportunities Data after adding the documents to the associated Tables
    const handleFetchDataOnModelClose = () => {
        fetchSingleOpportunity()
    }

    // const commentsPayload = (label) => {
    //     return {
    //         // datetime contains when the comment was created
    //         datetime: new Date().toISOString(),
    //         // comment contains the comment text
    //         comment: blockState[label],
    //         // by contains who created the comment
    //         by: {
    //             "id": members.userId,
    //             "name": members.name
    //         },
    //         label: ""
    //     }
    // }

    const commentsPayload = (label) => {
        let obj = {};

        obj[label] = blockState[label] ? JSON.parse(JSON.stringify(blockState[label])) : "";
        console.log("Obj comment clcik  ==>> ", obj);

        if (Object.keys(commentTypeFieldState)) {
            let v = {
                // datetime contains when the comment was created
                datetime: new Date().toISOString(),
                // comment contains the comment text
                comment: commentTypeFieldState[label],
                // by contains who created the comment
                by: {
                    "id": members.userId,
                    "name": members.name
                },
                label: ""
            }
            Object.keys(commentTypeFieldState).forEach(key => {

                console.log("Keys  ==>>> ", label, commentTypeFieldState[label]);
                if (obj[label]) {
                    obj[label] = [...obj[label], v]
                } else {
                    obj[label] = [v]
                }
            })
        }

        return obj
    }

    const addCommentClick = (label, key) => {
        console.log("Data from Comment box ===>>> ", label, key);
        // send only the new comment added along with the old comments already exist.
        let payload = commentsPayload(key)

        let payloadData = fetchUpdateOpportunityPayload(appId, selectedStep, props.match.params.id, chartUniqueId, payload)
        console.log("it is payload to update updateOpportunity api Comments click ===>>>", payload, payloadData)
        props.updateOpportunity(payloadData);
    }

    return (
        <div class="data-analyst-view" style={{
            // overflow: 'hidden',
            height: "100%",
            paddingLeft: "10px",
            paddingRight: "20px",
            width: "calc(100% - 30px)",
            // border: "2px solid red"
        }}>
            {/* {console.log("Form State 1111111 ===========> ", props, props.formState, enabledBlocks, selectedOptions, keyName, blockState, modifiedFields)} */}
            {
                props.formData?.[chartUniqueId + '_getsingleopportunity_loading'] ? loader : <StepWithBlocksComponentView
                    {...props}
                    // config={identifyStep}
                    enabledBlocks={enabledBlocks}
                    dataTabEdited={dataTabEdited}
                    setDataTabEdited={setDataTabEdited}
                    currentDataModel={currentDataModel}

                    blockState={blockState}
                    currentDocumentData={blockState}
                    onChange={handleOnChange}
                    handleCheckboxChange={handleCheckboxChange}
                    // selectedOptions={selectedOptions}
                    keyName={keyName}
                    handleFetchDataOnModelClose={handleFetchDataOnModelClose}
                    isLoading={formData[`${selectedStep.dataModel}_loading`]}
                    addCommentClick={addCommentClick}
                    commentTypeFieldState={commentTypeFieldState}

                    masterDataStoreObject={masterDataStoreObject}
                    accordionSupport={accordionSupport}
                    setAccordionSupport={setAccordionSupport}
                />
            }
        </div>
    )
}

export default StepWithBlocksComponent