const BASE_URL_ = process.env.REACT_APP_API_BASE_URL_CONTRACT_SERVICE;
import axios from 'axios';
import { request } from './../client';

export class TxnService {
  static getTxnDetails(data) {
    const uri = BASE_URL_ + '/transaction/gettransactions';
    return request(uri, {
      method: 'GET',
      headers: {
        appId: data,
      },
    });
  }
  static createTxn(data) {
    const uri = BASE_URL_ + '/transaction/create';
    return axios({
      url: uri,
      method: 'POST',
      data: data.formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        appId: data.appId,
      },
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        throw error.response.data;
      });
  }
  static deleteTxn(data) {
    const uri = BASE_URL_ + '/transaction/delete';
    return request(uri, {
      method: 'DELETE',
      payload: data,
      headers: {
        appId: data.appid,
      },
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        throw error.response.data;
      });
  }

  static getTxnById(data) {
    const uri = BASE_URL_ + '/transaction/gettransactions';

    return request(uri, {
      method: 'POST',
      payload: data,
    });
  }
}
