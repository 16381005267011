import React,{useEffect,useState} from 'react'
import { InputLabel,Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core"
import {ChipSelect} from 'dsilo-ui-components'
const useStyles = makeStyles((theme) => ({
    paper: {
    },
    gridContainer:{

    },
    InputLabel:{
        marginTop:20
    },
    TextField:{
        width:'100%'
    },
    status:{
        display:'contents'
    },
    chipSelect:{

    }
}))

const SecurityAccess =(props)=>{
    const classes = useStyles();
    const [state, setState] = useState({})


    const onDocumentTypeChangerole = (data,type) => {
        setState({...state,[type]:data})
        props.handlesSaveFunctionality({...state,[type]:data})  
    }

    const onDocumentTypeChangegroup = (data,type) => {
        setState({...state,[type]:data}) 
        props.handlesSaveFunctionality({...state,[type]:data})
    }

    return (
            <Grid container xs={6} spacing={3} className={classes.gridContainer}>
                <Grid item xs={3} sm={3} >
                    <InputLabel htmlFor="my-input" className={classes.InputLabel}>Select user roles</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                    <ChipSelect 
                         className={classes.chipSelect}
                         id='role'
                         onChange={(val) =>onDocumentTypeChangerole(val,'roles')}
                         value={props.fianlFormData?.roles}
                         isSearchable={true}
                         isMulti
                         textFieldProps={{
                             InputLabelProps: {
                                 shrink: true,
                             },
                             variant: 'outlined',
                         }}
                         variant="fixed"
                         required
                         options={props.membersRoles}
                    />
                </Grid>
                <Grid item xs={3} sm={3} >
                    <InputLabel htmlFor="my-input" className={classes.InputLabel}>Select Groups</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                    <ChipSelect
                        id='group'
                         className={classes.chipSelect}
                         onChange={(val) =>onDocumentTypeChangegroup(val,'groups')}
                         value={props.fianlFormData?.groups}
                         isSearchable={true}
                         isMulti
                         textFieldProps={{
                             InputLabelProps: {
                                 shrink: true,
                             },
                             variant: 'outlined',
                         }}
                         variant="fixed"
                         options={props.membersGroup} 
                    />
                </Grid>
            </Grid>
    )
}

export default SecurityAccess