import { makeStyles } from "@material-ui/core";
import { EditableTable, Helpers } from "dsilo-ui-components";
import React, { useEffect, useState } from "react";
import { hot } from "react-hot-loader/root";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
    clearSuccessMsg, downloadSheet, getContractDataById, updateDataModelData, getModelDataDropDown, fetchmasterDataForDataelementUsingRules } from '../../store/actions';
import { Loader } from "../loader";

const useStyles = makeStyles(theme => ({
    

}));
let COLUMNS = [];

const EditableTableComponent = (props) => {

    const { page, data } = props;
    const [fetched, setDataFetched] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [conditions, setConditions] = useState([]);

    const classes = useStyles();

    const { selectedDataElement, fetchType, extenalFilter } = props.data.chartData;


    useEffect(() => {
        if (page && page.pageLevelDataModel && !fetched) {
            setDataFetched(true)
            let payload = {
                id: props.match.params.id,
                dataModelId: page.pageLevelDataModel,
                appId: props.match.params.appid,
                fields: selectedDataElement.map(i => i.value).join(",")
            }
            if (fetchType) {
                payload['fetchType'] = fetchType;
            }
            props.getContractDataById(payload)
            let payload1 = {
                id: page.pageLevelDataModel,
                appId: props.match.params.appid,
                fields: selectedDataElement.map(i => i.value).join(",")
            }
            if (fetchType) {
                payload1['fetchType'] = fetchType;
            }
            props.getModelDataDropDown(payload1);
        }
    }, [page]);

    const { contractData, loading, successMsg, externalFilterData } = props.formData;

    useEffect(() => {
        if (successMsg) {
            toast.info(successMsg);
            let payload = {
                id: props.match.params.id,
                dataModelId: page.pageLevelDataModel,
                appId: props.match.params.appid,
                fields: selectedDataElement.map(i => i.value).join(",")
            }
            if (fetchType) {
                payload['fetchType'] = fetchType;
            }
            props.getContractDataById(payload)
            props.clearSuccessMsg()
        }
    }, [successMsg])

    useEffect(() => {
        if (contractData && contractData.data && contractData.data.length && contractData.data[0].annotations) {
            setDataSource(contractData.data[0].annotations.map(i => i))
        }
    }, [contractData])

    useEffect(() => {
        if (data && data?.chartData) {
            if (fetchType === 'keysArrays' && contractData?.data?.length && contractData?.data?.length) {
                COLUMNS = Object.keys(contractData?.data[0]).map(i=>{
                    return ({
                        key: i,
                        label: i,
                        editable: true,
                        style: {
                            width: 80,
                        }
                    })
                })
            } else {
                if (data.chartData?.selectedDataElement?.length) {
                    COLUMNS = data.chartData.selectedDataElement.map(i => {
                        return ({
                            key: i.value,
                            label: i.label,
                            editable: true,
                            style: {
                                width: 80,
                            }
                        })
                    })
                }
            }
        }
    }, [data]);

    const getPayload = (details) => {
        let _contractData = JSON.parse(JSON.stringify(contractData));
        if (_contractData?.data && _contractData.data[0]?.annotations) {
            let updatedAnnotations = [..._contractData.data[0]?.annotations, details]
            _contractData.data[0].annotations = updatedAnnotations
        }
        return _contractData.data[0];
    }

    const onSaveDetails = (details) => {
        if (props.data.chartData?.selectedDataModel) {
            let formData = {
                appid: props.match.params.appid,
                dataModelId: props.data.chartData?.selectedDataModel,
                payload: getPayload(details),
            }
            props.updateDataModelData(formData)
        }
    }

    const onSaveAnnotations = (annotations) => {
        console.log('annotations', annotations);
        let _contractData = JSON.parse(JSON.stringify(contractData));
        if (_contractData?.data && _contractData.data[0]?.annotations) {
            let updatedAnnotations = annotations;
            _contractData.data[0].annotations = updatedAnnotations
        }
        let formData = {
            appid: props.match.params.appid,
            dataModelId: props.data.chartData?.selectedDataModel,
            payload: _contractData.data[0]
        }
        props.updateDataModelData(formData)
    }


    const onDownload = (downloadType) => {
        if(downloadType !=='server'){
            let payload = {
                ids: [props.match.params.id],
                dataModelId: props.data.chartData?.selectedDataModel,
                fields: selectedDataElement.map(i => i.value).join(",")
            }
            if (fetchType) {
                payload['fetchType'] = fetchType;
            }
            props.downloadSheet({ ...payload, appid: props.match.params.appid });
        } else {
            if(contractData?.data?.length && contractData?.data?.annotations){
                Helpers.jsonToExcelDownload(contractData.data[0].annotations)
            } else {
                console.log('No data to download')
            }
        }
      
    };
    
    const onExternalFilterChange = (filterVal, name) => {
        let updatedConditions = [];
        let index = conditions.findIndex(i => i.selectedConditionDataElement === name)
        if (index === -1) {
            let obj = {
                "selectedConditionDataElement": name,
                "selectedConditionOperator": "matches",
                "value": filterVal.value,
                "selectedConditionClause": "and"
            }
            updatedConditions = [...conditions, obj]
            setConditions(updatedConditions)
        } else {
            let obj = conditions[index];
            obj.value = filterVal.value
            updatedConditions = [...conditions]
            updatedConditions[index] = obj;
            setConditions(updatedConditions);
        }

        let payload = {
            id: props.match.params.id,
            dataModelId: page.pageLevelDataModel,
            appId: props.match.params.appid,
        };
        if (fetchType) {
            payload['fetchType'] = fetchType
        }
        if (selectedDataElement && selectedDataElement.length) {
            payload['fields'] = selectedDataElement.map(i => i.value).join(",");
            payload['conditions'] = updatedConditions;
        }
        props.getContractDataById(payload)

    }

    if (loading) {
        return <Loader />
    }

    return <div>
        <EditableTable
            addFormData={props.data}
            onSaveClickFromForm={onSaveDetails}
            onSave={onSaveAnnotations}
            onDelete={onSaveAnnotations}
            data={[...dataSource]}
            onDownload={onDownload}
            columns={COLUMNS}
            actionButtons
            onExternalFilterChange={onExternalFilterChange}
            externalFilterData={externalFilterData?.data ? externalFilterData.data : []}
            externalFilters={extenalFilter}
            externalFilterValues={conditions}

        />
    </div>
}


const mapDispatchToProps = {
    getContractDataById,
    updateDataModelData,
    clearSuccessMsg,
    downloadSheet,
    getModelDataDropDown,
    fetchmasterDataForDataelementUsingRules
};

const mapStateToProps = state => {
    return {
        formData: state.form,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditableTableComponent)));
