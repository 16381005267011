import React, { useEffect, useState } from 'react'
import { SimpleList } from 'dsilo-ui-components';
import { getAuditTrailsData } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';

const AuditTrail = (props) => {
    const [taskLogs, setTaskLogs] = useState([])
    const { selectedDataModel, selectedDataElement } = props?.data?.chartData;

    useEffect(() => {
        let payload = {
            "dataModelId": selectedDataModel,
            "appId": props.appId,
            "dataUniqueId": "",
            "documentId": props.match.params.id
        }
        props.getAuditTrailsData(payload)
    }, [])

    useEffect(() => {
        let logs = props.form.auditTrails
        if (logs && logs.data && logs.data.length) {
            let messageArray = logs.data.map((item) => item.message)
            setTaskLogs(messageArray)
        }
    }, [props.form.auditTrails])


    return (
        <React.Fragment>
            <SimpleList data={{ title: props.title || 'Audit trail', items: taskLogs || [], style: true }} />
        </React.Fragment>
    )
}


const mapStateToProps = state => {
    return {
        form: state.form,
    };
};

const mapDispatchToProps = {
    getAuditTrailsData,
};
export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(AuditTrail)));