import { ActionTypes } from 'constants/index';

const initialState = {
    loading: false,
    error: ''
};

export default function themeReducer(state = initialState, action) {
    
    switch (action.type) {
        case ActionTypes.LOAD_THEME_LOADING: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case ActionTypes.LOAD_THEME_SUCCESS: {
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        }
        case ActionTypes.LOAD_THEME_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        default: {
            return state;
        }
    }
}