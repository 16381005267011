import { ActionTypes } from 'constants/index';

const initialState = {
  pdf: null,
  loading: false,
  contractList: [],
  contract: null,
  savedContract: null,
  message: null,
  downloadedPDF: null,
  searchResults: null,
  updateSuccess:false,
  loadingSpinner: false,
  type:null,
  aiTableData: [],
};

export default function contractAnnotationsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ADD_SEARCH_DATA_SUCCESS: {
      return {
        ...state,
        searchResults: action.payload
      }
    }
    case ActionTypes.ADD_SEARCH_DATA_ERROR: {
      return {
        ...state,
        searchResults: action.payload
      }
    }
    case ActionTypes.SEARCH_DATA_SUCCESS: {
      return {
        ...state,
        searchResults: action.payload
      }
    }
    case ActionTypes.SEARCH_DATA_ERROR: {
      return {
        ...state,
        searchResults: action.payload
      }
    }
    case ActionTypes.UPLOAD_CONTRACT_SUCCESS: {
      return {
        ...state,
        pdf: action.payload,
        loading: false,
        message: action.payload.message,
      };
    }
    case ActionTypes.GET_ALL_CONTRACTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.UPLOAD_CONTRACT_PDF: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.UPLOAD_CONTRACT_ERROR: {
      // TODO: show error msg if upload in not successful
      return {
        ...state,
        loading: false,
        pdfError: true
      };
    }
    case ActionTypes.GET_CONTRACT_LIST_SUCCESS: {
      return {
        ...state,
        contractList: action.payload,
        loading: false,
      };
    }
    case ActionTypes.GET_CONTRACT_LIST_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionTypes.DOWNLOAD_CONTRACT_PDF_SUCCESS: {
      return {
        ...state,
        downloadedPDF: action.payload,
      };
    }
    case ActionTypes.DOWNLOAD_CONTRACT_PDF: {
      return {
        ...state,
        pdf: null,
        downloadedPDF: null,
      };
    }
    case ActionTypes.GET_CONTRACT_BY_ID_EMPTY: {
      return {
        ...state,
        loading: true,
        contract: []
      };
    }
    case ActionTypes.GET_CONTRACT_BY_ID: {
      return {
        ...state,
        loading: true,
        rerun: ''
      };
    }
    case ActionTypes.GET_CONTRACT_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        contract: action.payload,
      };
    }
    case ActionTypes.GET_CONTRACT_BY_ID_ERROR: {
      return {
        ...state,
        loading: false,
        contract: action.payload,
      };
    }
    case ActionTypes.SAVE_ANNOTATIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.SAVE_ANNOTATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        savedContract: action.payload,
        message: action.payload.message,
      };
    }
    case ActionTypes.SAVE_ANNOTATIONS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionTypes.UPDATE_ANNOTATIONS: {
      return {
        ...state,
        loading: true,
        updateSuccess:false
      };
    }
    case ActionTypes.UPDATE_ANNOTATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        updateSuccess:true
      };
    }
    case ActionTypes.UPDATE_ANNOTATIONS_WITHOUT_LOADER:{
      return {
        ...state,
        loadingSpinner: true,
      };
    }
    case ActionTypes.UPDATE_ANNOTATIONS_WITHOUT_LOADER_SUCCESS:{
      return {
        ...state,
        loadingSpinner: false,
      };
    }
    case ActionTypes.UPDATE_ANNOTATIONS_WITHOUT_LOADER_ERROR:{
      return {
        ...state,
        loadingSpinner: false,
      };
    }
    case ActionTypes.UPDATE_ANNOTATIONS_ERROR: {
      return {
        ...state,
        loading: false,
        updateSuccess:false
      };
    }
    case ActionTypes.DELETE_CONTRACT: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.DELETE_CONTRACT_SUCCESS: {
      let _updatedContractList = state.contractList.filter(i=> !action.payload.ids.includes(i._id));
      return {
        ...state,
        contractList: [..._updatedContractList],
        message: action.payload.message,
        loading: false,
      };
    }
    case ActionTypes.DELETE_CONTRACT_ERROR: {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    }
    case ActionTypes.HIDE_CONTRACT_UPDATE_MESSAGE: {
      return {
        ...state,
        message: null,
      };
    }
    case ActionTypes.CLEAR_PDF_DATA: {
      return {
        pdf: null,
        contract: null,
        savedContract: null,
        downloadedPDF: null,
      }
    }
    case ActionTypes.DELETE_UPLOADED_CONTRACT_SUCCESS: {
      let _updatedContractList = state.pdf.filter(i=> !action.payload.ids.includes(i.id));
      return {
        ...state,
        pdf: [..._updatedContractList],
        message: action.payload.message,
        loading: false,
      };
    }
    case ActionTypes.DELETE_UPLOADED_CONTRACT_ERROR: {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    }
    case ActionTypes.RERUN_AI_ITEM: {
      return {
        ...state,
        loading: action.data?.loading ? true : false,
        rerun: 'loading',
      };
    }
    case ActionTypes.RERUN_AI_ITEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        message: action.payload?.data?.message,
        rerun: 'success',
        type:action.payload?.type
      };
    }
    case ActionTypes.RERUN_AI_ITEM_ERROR: {
      return {
        ...state,
        loading: false,
        message: action.payload?.message,
        rerun: 'error'
      };
    }
    case ActionTypes.UPDATE_CONTRACT_REDUCER_STATE: {
      return {
        ...state,
        contract: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
