import Backdrop from '@material-ui/core/Backdrop';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState, useRef } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { Loader } from '../../../loader';
import { ComponentMap, supportedHighcharts } from '../../ChatHelper';
import ComponentHeader from './ComponentHeader';
import WorkspaceHeader from './WorkspaceHeader';
import useStyles from './styles';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

let initialLayout = [],
  defaultBreakpoint = 'lg',
  defaultMounted = false,
  defaultCompactType = 'vertical';

let layoutProps = {
  className: 'layout',
  rowHeight: 40,
  onLayoutChange: function () { },
  cols: { lg: 12, md: 12, sm: 12, xs: 4, xxs: 2 },
};

const WorkspaceScreen = props => {
  const classes = useStyles();
  const chartReflowRef = useRef(null);
  const [localLoading, setLocalLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [breakpoint, setBreakpoint] = useState(defaultBreakpoint);
  const [mounted, setMounted] = useState(defaultMounted);
  const [compactType] = useState(defaultCompactType);
  const [layouts, setLayouts] = useState({ lg: initialLayout });
  const [showSaveButton, setShowSaveButton] = useState(false);

  useEffect(() => {
    setMounted(true);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1500);
    return () => {
      console.log('on return useeffect');
    };
  }, []);

  useEffect(() => {
    setItems(props?.receivingWorkSpace?.charts || []);
  }, [props?.receivingWorkSpace?.charts]);

  useEffect(() => {
    if (props.workspaceLoading) setLocalLoading(true);
    else setLocalLoading(false);

    if (!props.workspaceLoading && showSaveButton) setShowSaveButton(false);
  }, [props?.workspaceLoading]);

  useEffect(() => {
    let tempLayouts = { ...layouts };
    setLocalLoading(true);
    let y = 0;
    let lg =
      items && items.length > 0
        ? items.map((element, i) => {
          if (element?.layout) {
            y = y > element.layout?.y ? y : element?.layout?.y + element?.chartData?.h;
            return element?.layout;
          } else {
            return {
              x: (i % 2) * 6,
              y: (i % 2) * 6,
              w: 6,
              h: 8,
              i: i.toString(),
              moved: false,
              static: false,
            };
          }
        })
        : [];

    if (items && items?.length > 1) {
      lg.push({
        x: 0,
        y: y,
        w: 12,
        h: 1,
        i: 'enforce',
        moved: false,
        static: false,
      });
    }

    tempLayouts = { lg };
    setLayouts(tempLayouts);
    setTimeout(() => {
      setLocalLoading(false);
      window.dispatchEvent(new Event('resize'));
    }, 1500);
    console.log('useEffect layouts ::: *** ', tempLayouts);
  }, [items?.length]);

  const onBreakpointChange = breakpoint => {
    setBreakpoint(breakpoint);
  };

  const onLayoutChange = (layout, layouts) => {
    console.log('onLayoutChange ::: *** ', layout, layouts);
    const tempItems = items?.map((item, index) => {
      return {
        ...item,
        layout: {
          ...item.layout,
          x: layout[index]?.x,
          y: layout[index]?.y,
          w: layout[index]?.w,
          h: layout[index]?.h,
          i: layout[index]?.i,
        },
      };
    });
    setLayouts(layouts);
    setItems(tempItems);
    setShowSaveButton(true);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1000);
    props?.setReceivingWorkSpace({ ...props.receivingWorkSpace, charts: tempItems });
  };

  const deleteChart = (chartId, workspaceId) => {
    if (chartId && workspaceId) {
      props?.deleteComponentFromWorkspace(chartId, workspaceId);
    }
  };

  const onResize = (layout, oldItem, newItem, placeholder, e, element) => {
    console.log('onResize');
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  };

  const onDrop = elemParams => {
    console.log('onDrop');
  };

  const updateWorkspaceLayout = () => {
    props.updateWorkspace(props?.receivingWorkSpace?.workspaceId);
  };

  return (
    <div className={classes.workspaceScreenRoot}>
      {(props?.workspaceLoading || localLoading) && (
        <Backdrop className={classes.backdrop} open={true}>
          <Loader className={classes.loader} />
        </Backdrop>
      )}
      <WorkspaceHeader
        publishLoading={props?.publishLoading}
        publishDraftWorkspace={props.publishDraftWorkspace}
        receivingWorkSpace={props.receivingWorkSpace}
        showSaveButton={showSaveButton}
        updateWorkspaceName={props.updateWorkspaceName}
        updateWorkspaceLayout={updateWorkspaceLayout}
        ownerDetails={props?.ownerDetails}
        workspaceTabVal={props.workspaceTabVal}
      />
      <Divider />
      <ResponsiveReactGridLayout
        style={{
          // color: '#000',
          height: '100%',
          backgroundColor: 'white',
          // background: "#f7f7f7" // Only needed when configured
        }}
        {...props}
        {...layoutProps}
        onDrop={onDrop}
        layouts={layouts}
        onResize={onResize}
        onLayoutChange={onLayoutChange}
        onBreakpointChange={onBreakpointChange}

        // WidthProvider option
        measureBeforeMount={false}
        // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
        // and set `measureBeforeMount={true}`.
        compactType={compactType}
        useCSSTransforms={mounted}
        preventCollision={!compactType}
        className={classes.reactGridLayout}
        
        autoSize={props.workspaceTabVal !== "all" ? true : false}
        isDroppable={props.workspaceTabVal !== "all" ? true : false}
        isResizable={props.workspaceTabVal !== "all" ? true : false}
        isDraggable={props.workspaceTabVal !== "all" ? true : false}
        resizeHandles={props.workspaceTabVal !== "all" ? ['se'] : []}
      >
        {items &&
          items.length > 0 &&
          items.map((item, index) => {
            let responseData = supportedHighcharts.includes(item.type)
              ? item?.highchartData
              : item?.responseData || [];
            return (
              <div
                key={index}
                id={item?.chartUniqueId}
                className="bot-message-border"
                style={{ height: 'inherit', textAlign: 'center' }}
              >
                {props.workspaceTabVal !== "all" && <IconButton
                  aria-label="delete"
                  onClick={() => deleteChart(item?._id, item)}
                  style={{ color: 'dimgrey', float: 'right' }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>}
                <ComponentHeader
                  chartItem={item}
                  workspaceId={item?.workspaceId}
                  updateChartTitle={props.updateChartTitle}
                  workspaceTabVal={props.workspaceTabVal}
                />
                {ComponentMap(item.type, index, responseData, item.title, items, chartReflowRef, {
                  chartUniqueId: item?.chartUniqueId, fromDashboard: true
                })}
              </div>
            );
          })
        }
        {items && items.length > 0 && (
          <div
            style={{
              width: '100%',
              height: 24,
              backgroundColor: 'transparent !important',
            }}
          />
        )}
      </ResponsiveReactGridLayout>
    </div>
  );
};

export default WorkspaceScreen;
