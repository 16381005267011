import { ActionTypes } from 'constants/index';

const initialState = {
    page: {},
    loading: false,
    error: '',
    pageSave: false
};

export default function pageConfigReducer(state = initialState, action) {
    
    switch (action.type) {
        case ActionTypes.LOAD_PAGE_CONFIG_LOADING: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case ActionTypes.LOAD_PAGE_CONFIG_SUCCESS: {
            return {
                ...state,
                page: action.payload,
                loading: false
            }
        }
        case ActionTypes.LOAD_PAGE_CONFIG_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case ActionTypes.SAVE_PAGE_SUCCESS: {
            return {
                ...state,
                loading: false,
                pageSave: true,
                page: action.payload
            }
        }
        default: {
            return state;
        }
    }
}