/**
 * @module Sagas/Pages
 */
import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { modelService } from 'modules/api/index'
import { ActionTypes } from 'constants/index';

/**
 * Application
 *
 * @param {Object} action
 *
 */

async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

function* fetchModel(action) {
    try {
        console.log(" action ?", action);
        const pageConfig = yield fetchAsync(modelService.getModel, action.data);
        yield put({type: ActionTypes.GET_MODEL_SUCCESS, payload: pageConfig});
    } catch (e) {
        yield put({type: ActionTypes.GET_MODEL_ERROR, error: e.message});
    }
}

function* fetchModels(action) {
    try {
        const pages = yield fetchAsync(modelService.getModels, action.data);
        yield put({type: ActionTypes.GET_MODELS_SUCCESS, payload: {key: action.data.key, data: pages}});
    } catch (error) {
        yield put({type: ActionTypes.GET_MODELS_ERROR, error: error});
    }
}

function* saveModel(action) {
    try {
        const page = yield fetchAsync(modelService.saveModel, action.data);
        yield put({type: ActionTypes.SAVE_MODEL_SUCCESS, payload: page});
    } catch (e) {
        yield put({type: ActionTypes.SAVE_MODEL_ERROR, error: e.message});
    }
}

function* updateModel(action) {
    try {
        const page = yield fetchAsync(modelService.updateModel, action.data);
        yield put({type: ActionTypes.UPDATE_MODEL_SUCCESS, payload: page});
    } catch (e) {
        yield put({type: ActionTypes.UPDATE_MODEL_ERROR, error: e.message});
    }
}

function* deleteModel(action) {
    try {
        const page = yield fetchAsync(modelService.deleteModel, action.data);
        yield put({type: ActionTypes.DELETE_MODEL_SUCCESS, payload: page});
    } catch (e) {
        yield put({type: ActionTypes.DELETE_MODEL_ERROR, error: e.message});
    }
}

function* checkForTraining(action) {
    try {
      const response = yield fetchAsync(modelService.checkForTraining, action.payload);
      yield put({ type: ActionTypes.CHECK_FOR_TRAINING_SUCCESS, payload: {data: response.data, dataUniqueId: action.payload.dataUniqueId} });

    } catch (e) {
      yield put({ type: ActionTypes.CHECK_FOR_TRAINING_FAILURE, payload: {error: action.payload.noError?null:e, dataUniqueId: action.payload.dataUniqueId}  });
      console.log(e);
    }
  }
  
  function* updateModelStatus(action) {
    try {
      const response = yield fetchAsync(modelService.updateModelStatus, action.payload);
      yield put({ type: ActionTypes.UPDATE_MODEL_STATUS_SUCCESS, payload: {data: response.data, dataUniqueId: action.payload.dataUniqueId} });
    } catch (e) {
      yield put({ type: ActionTypes.UPDATE_MODEL_STATUS_FAILURE, payload: {error: e, dataUniqueId: action.payload.dataUniqueId}  });
      console.log(e);
    }
  }
  

export function* watchModelSaga() {
    yield takeEvery(ActionTypes.GET_MODEL_LOADING, fetchModel);
    yield takeEvery(ActionTypes.GET_MODELS_LOADING, fetchModels);
    yield takeEvery(ActionTypes.SAVE_MODEL_LOADING, saveModel);
    yield takeEvery(ActionTypes.UPDATE_MODEL_LOADING, updateModel);
    yield takeEvery(ActionTypes.DELETE_MODEL_LOADING, deleteModel);
    yield takeEvery(ActionTypes.CHECK_FOR_TRAINING, checkForTraining);
    yield takeEvery(ActionTypes.UPDATE_MODEL_STATUS, updateModelStatus);

}

export default watchModelSaga;
