const API_BASE_URL = process.env.REACT_APP_MODEL_SERVICE_URL;
import { Request } from "./clientHelper";

export class modelService {
    static getModel(data) {
        console.log(" data --=?", data);
        const uri = API_BASE_URL + "/models/" + data.id;

        return Request(uri, {
            method: 'GET',
            headers: {
                appId: data.appId
            }
        });
    }
    static getModels(data) {
        let uri = API_BASE_URL + "/models";
        if (data.params) {
            uri += data.params;
        }
        return Request(uri, {
            method: 'GET',
            headers: {
                appId: data.appId
            }
        });
    }

    static saveModel(data) {
        const uri = API_BASE_URL + "/models";
        return Request(uri, {
            method: 'POST',
            payload: data,
            headers: {
                appId: data.appId
            }
        });
    }
    static updateModel(data) {
        const uri = API_BASE_URL + "/models";
        return Request(uri, {
            method: 'PUT',
            payload: data,
            headers: {
                appId: data.appId
            }
        });
    }
    static deleteModel(data) {
        const uri = API_BASE_URL + "/models";
        return Request(uri, {
            method: 'DELETE',
            payload: data, //ids:[]
            headers: {
                appId: data.appId
            }
        });
    }

    static checkForTraining(data) {
        let uri = API_BASE_URL + "/models/init/";
        if (data.params) {
            uri += data.params;
        }
        let appId = data.appId
        return Request(uri, {
            url: uri,
            method: 'POST',
            headers: {
                appId: appId,
            },
            payload: data,
        });
    }

    static updateModelStatus(data) {
        let uri = API_BASE_URL + "/models/" + data.modelId + "/" + data.modelVersion + "/" + data.params;
        let appId = data.appId
        return Request(uri, {
            url: uri,
            method: 'POST',
            headers: {
                appId: appId,
            },
            payload: data,
        });
    }
}
