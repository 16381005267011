import React, { useEffect, useState, createContext } from 'react';

import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { ExternalFilter,FiltersUtils } from 'dsilo-ui-components'
import { Box } from '@material-ui/core';

import { getReduxExternalFilter,getModelDataDropDown } from '../../store/actions';

const LayoutFilter = props => {

    const { externalFilterData,externalFilter } = props;
    const { isFiltersInterConnected,selectedDataModel } =props.page;
	
	const externalFilterDatas=externalFilterData ? externalFilterData[props.data.chartUniqueId + '_externalFilter']?.data : []
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(100)
    const [filterConditions, setFilterConditions] = useState([]); // filter array to be sent in api call
	const [filterValue, setFilterValue] = useState({}) // actual filter object
	const [cardValues, setCardValues] = useState([])

	const onExternalFilterChange = async (filterValues, name, filterType) => {
		let page = 0, rowsPerPage = 100;
		setPage(page)
		setRowsPerPage( 100)
		let updatedConditions = await FiltersUtils.createConditions(filterValues, externalFilter)
		setFilterValue(filterValues)
		setFilterConditions(updatedConditions)
        props.getReduxExternalFilter(updatedConditions);
	}

	return (
		<div >
			
						<Box style={{ overflowX: "auto", overflowY: "hidden", marginBottom: 10, }}>
							<ExternalFilter
								{...props}
								data={{chartData:{isFiltersInterConnected,selectedDataModel},
								chartUniqueId:"chartUniqueId"}}
								containerXs={10}
								externalFilterValues={filterValue}
								externalFilterData={externalFilterDatas}
								externalFilters={externalFilter}
								onExternalFilterChange={onExternalFilterChange}
							/>
						</Box> 
				</div>
				
	);
};

const mapDispatchToProps = {
    getReduxExternalFilter,
	getModelDataDropDown
};

const mapStateToProps = state => {
	return {
		chart:state.chart,
		formData: state.form,	
	};
  };
export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(LayoutFilter)));
