import { Box, Button, Chip, Grid, Paper, Typography, IconButton } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import ccm from './../../images/app/ccm.png';
import ciep from './../../images/app/ciep.png';
import cm from './../../images/app/cm.png';
import cvm from './../../images/app/cvm.png';
import ivm from './../../images/app/ivm.png';
import rvm from './../../images/app/rvm.png';
import scm from './../../images/app/scm.png';
import srm from './../../images/app/srm.png';
import tprm from './../../images/app/tprm.png';

import SettingsIcon from '@material-ui/icons/Settings';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const BUILDER_URL = process.env.REACT_APP_BUILDER_URL;

const ImgSrc = {
	cvm: cvm,
	ivm: ivm,
	scm: scm,
	srm: srm,
	cm: cm,
	rvm: rvm,
	ccm: ccm,
	ciep: ciep,
	tprm: tprm
}

const Card = (props) => {
	const [loading, setLoading] = useState(false)
	const { classes, dapp, isTemplate } = props

	const createApp = () => {
		if (isTemplate) {
			props.history.push('/contact-dsilo#' + dapp.type)
		} else {
			let appURL = typeof (dapp.appViewURL) !== 'undefined'
				? dapp.appViewURL
				// : `/${dapp.type}/${dapp._id}`
				: (dapp.type === 'rvm' ? `/${'rvm'}/${dapp._id}` : `/${dapp._id}`)
			// window.open(`${appURL}`, "_blank")
			if (dapp.type === 'rvm' || dapp.appViewURL) {
				window.open(`${appURL}`, "_blank")
			} else {
				props.history.push(`${appURL}`)
			}
		}
		//  props.createApp(dapp)
	}
	useEffect(() => {
		if (props.createData && props.createData.type === dapp.type) {
			setLoading(true)
		} else {
			setLoading(false)
		}
	}, [props.createData])

	const handleSettingsIconClick = (e) => {
		e.preventDefault();
		var url = new URL(BUILDER_URL);
		var targetDomain = url.protocol + "//" + url.host;

		const message = {
			jwtToken: localStorage.getItem('jwt_access_token'),
			appid: dapp._id
		}
		const messageToPass = JSON.stringify(message);
		var target = window.open(url.href);
		setTimeout(() => {
			target.postMessage(messageToPass, targetDomain);
		}, 2000)
	}

	const appUniqueId = useMemo(() => {
		const appTitle = dapp?.title?.replace(/\s/g, "_")?.toLowerCase();
		return `${appTitle}_${dapp._id}`;
	}, [dapp?.title]);

	return (
		<Paper className={isTemplate ? classes.template : ''} style={{ minHeight: '95%', position: 'relative' }} >
			<Typography
				style={{
					borderBottom: '1px solid #EEEEEE',
					// marginBottom: '15px'
				}}
				variant="h6">{dapp.title}
				<Typography variant="caption" style={{ float: "right", textTransform: 'capitalize' }}>
					{isTemplate ? '' : (dapp.status ? <>
						<Chip
							size="small"
							label={dapp.status}
							color="primary"
						/>
						<IconButton
							color="inherit"
							style={{ paddingTop: '0px', paddingBottom: '0px' }}
							// onClick={() => {
							// 	window.open(`${BUILDER_URL}/dapps/${dapp._id}`, '_blank')
							// }}
							onClick={(e) => handleSettingsIconClick(e)}
						>
							<SettingsIcon />
						</IconButton>
					</> : null)}
				</Typography>
			</Typography>

			<Typography variant="h6" style={{ fontSize: "16px !important" }}>{dapp.subTitle}</Typography>
			<Grid container>
				<Grid item xs={4}>
					<Box className={(dapp.logoId) ? "reapp-bg" : ""}>
						<img style={{ maxWidth: '94%' }} src={
							(dapp.logoId)
								? `${API_BASE_URL}/logo/${dapp.logoId}`
								: (dapp.logoBase64
									? dapp.logoBase64
									: (ImgSrc[dapp.type]
										? ImgSrc[dapp.type]
										: cm))
						} alt="" width="100px" />
					</Box>
				</Grid>
				<Grid item xs={8}><Typography className={classes.p} >{dapp.description}</Typography></Grid>
			</Grid>
			<Grid container className="recent-app-icons">
				<div className={classes.actions}>
					<Button
						color={isTemplate ? 'secondary' : 'primary'}
						disabled={loading}
						id={appUniqueId}
						onClick={createApp}
						size="small"
						variant="contained"
					>
						{isTemplate ? 'Request for Access' : 'Open Application'}
					</Button>

				</div>
				{/* <Grid item xs={4} style={{ cursor: "pointer" }}> */}
				{/* <Paper onClick={() => props.editDapps(dapp._id)}>
                        <img src={Editicon} alt="editimg" />
                    </Paper> */}
				{/* </Grid> */}
				{/* <Grid item xs={4} style={{ cursor: "pointer" }}> */}
				{/* <Paper onClick={() => props.updateDappData(dapp)}>
                        <PublishIcon style={{ maxWidth: "50px" }} />
                    </Paper> */}
				{/* <Paper onClick={() => props.deleteDappData(dapp)}>
                    <img src={Rubbishicon} alt="deleteimg" />
                </Paper> */}
				{/* </Grid> */}
				{/* <Grid item xs={4} style={{ cursor: "pointer" }}> */}
				{/* <Paper onClick={(event) => {
                        event.preventDefault()
                        console.log('appURL ==><', dapp.appURL);
                        window.open(dapp.appURL, "_blank")
                    }}>
                        {typeof (appURL) && (
                            <img src={Visibilityicon} alt="viewimg" />)}
                    </Paper> */}
				{/* </Grid> */}
			</Grid>
		</Paper>
	)



}

export default Card
