import { all, fork } from 'redux-saga/effects';
import app from './app';
import pages from './pages';
import login from './login';
import org from './org';
import theme from './theme';
import entities from './entities';
import form from './form';
import capps from './capps';
import extractionResult from './extractionResult';
import register from './register';
import transaction from './transaction';
import contactDsilo from './contactDsilo';
import models from './models';
import actions from './action';
import dataModels from './dataModels';
import rules from './rules';
import dashboard from './dashboard';
import powerBi from './powerBi';
import chart from './charts';
import chat from './chat';
import notification from './notification';

/**
 * rootSaga
 */
export default function* root() {
  yield all([
    fork(app),
    fork(login),
    fork(register),
    fork(org),
    fork(pages),
    fork(theme),
    fork(entities),
    fork(form),
    fork(capps),
    fork(extractionResult),
    fork(transaction),
    fork(contactDsilo),
    fork(models),
    fork(actions),
    fork(dataModels),
    fork(rules),
    fork(dashboard),
    fork(powerBi),
    fork(chart),
    fork(chat),
    fork(notification)
  ]);
}
