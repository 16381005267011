import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
const ConfirmDialog = (props) => {
    const { title, children, open, setOpen, onConfirm } = props;
    return (
        <Dialog
            className="confirm-dialog-delete"
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog">
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent><DialogContentText>Do you want to delete {children}</DialogContentText></DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setOpen(false)}
                    color="primary"
                    autoFocus>No</Button>
                <Button
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                    color="primary">Yes
        </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmDialog;