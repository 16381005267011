import { ElasticSearchTable } from "dsilo-ui-components";
import React from "react";
import { hot } from "react-hot-loader/root";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { PUBLIC_PATH } from "../../constants";
import { onSearch } from '../../store/actions';
import { Loader } from "../loader";

const ElasticSearchTableComponent = props => {

    return (
        <ElasticSearchTable
            {...props} PUBLIC_PATH={PUBLIC_PATH} loader={<Loader />} />
    );
};

const mapDispatchToProps = {
  
    onSearch,
};

const mapStateToProps = state => {
    return {
        appConfig: state.appConfig.app,
        apps: state.app.app,
        login: state.login,
        user: state.user,
        formData: state.form,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ElasticSearchTableComponent)));
