import { request } from 'modules/client'
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const route = `${API_BASE_URL}/dapps`

export class DappsService {

	static getDapps(orgId) {
		return request(route, {
			method: 'GET',
			headers: {
				'OrgId': orgId
			}
		})
	}

	static getApp(appId) {
		const uri = route + "/" + appId
		return request(uri, {
			method: 'GET'
		})
	}

	static saveDapp(data) {
		console.log("data====", data);
		let uri = route;
		if(data.files && data.s3HeadCode && data.mode) {
			uri += `/upload/fileadd`
		}
		return request(uri, {
			method: 'POST',
			payload: data
		})
	}

	static updateDapp(data) {
		const uri = route + "/" + data._id

		return request(uri, {
			method: 'PUT',
			payload: data
		})
	}

	static deleteDapp(dappId) {
		const uri = route + "/" + dappId
		return request(uri, {
			method: 'DELETE'
		})
	}

	static getCategories() {
		const uri = API_BASE_URL + "/categories"
		return request(uri, {
			method: 'GET'
		})
	}

	// static setDappsSearchText(event) {
	//     return {
	//         type: SET_DAPPS_SEARCH_TEXT,
	//         searchText: event.target.value
	//     }
	// }

	// static setCategoryFilter(event) {
	//     return {
	//         type: SET_DAPPS_CATEGORY_FILTER,
	//         category: event.target.value
	//     }
	// }

}

export function apiBaseAddress() {
	return API_BASE_URL
}