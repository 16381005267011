/**
 * @module Sagas/App
 * @desc App
 */
import { put, takeEvery, call, takeLatest } from 'redux-saga/effects'
import { AppService } from 'modules/api/index'
import { ActionTypes } from 'constants/index';

/**
 * Application
 *
 * @param {Object} action
 *
 */

async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

async function fetchMultiAsync(funcs) {
    const response = await Promise.allSettled(funcs)
    return response;
}

function* fetchAppConfig(action) {
    try {
        const appConfig = yield fetchAsync(AppService.getAppsDetails, action.payload);
        yield put({ type: ActionTypes.LOAD_APP_CONFIG_SUCCESS, payload: appConfig.data });
    } catch (e) {
        yield put({ type: ActionTypes.LOAD_APP_CONFIG_ERROR, error: e.message });
    }
}

function* verifyApp(action) {
    try {
        const dappVerifyInfo = yield call(fetchAsync, AppService.verifyApp, action.payload);
        yield put({ type: ActionTypes.VERIFY_APP_SUCCESS, payload: dappVerifyInfo.data });
    } catch (e) {
        yield put({ type: ActionTypes.VERIFY_APP_FAILURE, error: e.message });
    }
}

function* fetchApps(action) {
    try {
        const apps = yield fetchAsync(AppService.getApps, action.orgId);
        yield put({ type: ActionTypes.LOAD_APPS_SUCCESS, payload: apps });
    } catch (e) {
        yield put({ type: ActionTypes.LOAD_APPS_ERROR, error: e.message });
    }
}

function* fetchTemplates(action) {
    try {
        const apps = yield fetchAsync(AppService.getTemplates, action.orgId);
        console.log("fgghfghh", apps)
        if (Array.isArray(apps.data))
            yield put({ type: ActionTypes.GET_TEMPLATES_SUCCESS, payload: apps.data });
    } catch (e) {
        console.log("fgghfghh", e)
        yield put({ type: ActionTypes.GET_TEMPLATES_FAILURE, error: e.message });
    }
}

function* fetchRoles(action) {
    try {
        const roles = yield fetchAsync(AppService.getRoles, action.appId);
        yield put({ type: ActionTypes.LOAD_ROLES_SUCCESS, payload: roles });
    } catch (e) {
        yield put({ type: ActionTypes.LOAD_ROLES_ERROR, error: e.message });
    }
}

function* saveDapp(action) {
    try {
        const app = yield fetchAsync(AppService.saveDapp, action.data);

        yield put({ type: ActionTypes.SAVE_APP_SUCCESS, payload: app });
    } catch (e) {
        yield put({ type: ActionTypes.SAVE_APP_ERROR, error: e.message });
    }
}

function* saveDatasource(action) {
    try {
        const datasource = yield fetchAsync(AppService.saveDatasource, action.data);
        yield put({ type: ActionTypes.SAVE_DATASOURCE_SUCCESS, payload: datasource });
    } catch (e) {
        yield put({ type: ActionTypes.SAVE_DATASOURCE_ERROR, error: e.message });
    }
}

function* fetchDataelements(action) {
    try {
        const dataelements = yield fetchAsync(AppService.getDataelements, action.datasourceId);
        yield put({ type: ActionTypes.LOAD_DATAELEMENTS_SUCCESS, payload: dataelements });
    } catch (e) {
        yield put({ type: ActionTypes.LOAD_DATAELEMENTS_ERROR, error: e.message });
    }
}

function* fetchMember(action) {
    try {
        const member = yield fetchAsync(AppService.getMember, action.user);
        yield put({ type: ActionTypes.LOAD_MEMBER_SUCCESS, payload: member });
    } catch (e) {
        yield put({ type: ActionTypes.LOAD_MEMBER_ERROR, error: e.message });
    }
}

function* fetchEntity(action) {
    try {
        const entity = yield fetchAsync(AppService.getEntity, action.payload);
        yield put({ type: ActionTypes.GET_ENTITY_SUCCESS, payload: entity });
    } catch (e) {
        yield put({ type: ActionTypes.GET_ENTITY_ERROR, error: e.message });
    }
}

function* fetchMemberById(action) {
    try {
        const member = yield fetchAsync(AppService.getMemberById, action.appId);
        const data = (Array.isArray(member.data) && member.data.length) ? member.data[0] : null
        if (!data) {   
            yield put({ type: ActionTypes.NO_MEMBER_FOUND});
        } else {
            yield put({ type: ActionTypes.GET_MEMBER_SUCCESS, payload: data });
        }
    } catch (e) {
        yield put({ type: ActionTypes.GET_MEMBER_ERROR, error: e.message });
    }
}

function* saveDataModel(action) {
    try {
        const dataModel = yield fetchAsync(AppService.saveDataModel, action.data);
        yield put({ type: ActionTypes.SAVE_DATAMODEL_SUCCESS, payload: dataModel });
    } catch (e) {
        yield put({ type: ActionTypes.SAVE_DATAMODEL_ERROR, error: e.message });
    }
}

function* fetchAppsAndTemplate(action) {
    try {
        const appsAndTemplate = yield fetchMultiAsync([AppService.getApps(action.orgId)
            // , AppService.getTemplates(action.orgId)
        ]);

        let apps = appsAndTemplate[0]
        let templates = appsAndTemplate[1]
        try {
            if (apps.status === "fulfilled") {
                apps = apps.value
                if (Array.isArray(apps.data))
                    yield put({ type: ActionTypes.LOAD_APPS_SUCCESS, payload: apps.data });
            } else {
                throw apps.reason
            }
        } catch (e) {
            yield put({ type: ActionTypes.GET_APPS_FAILURE, error: e });
        }

        try {
            if (templates.status === "fulfilled") {
                templates = templates.value
                if (Array.isArray(templates.data))
                    yield put({ type: ActionTypes.GET_TEMPLATES_SUCCESS, payload: templates.data });
            } else {
                throw templates.reason
            }
        } catch (e) {
            yield put({ type: ActionTypes.GET_TEMPLATES_FAILURE, error: e });
        }
    } catch (e) {
        yield put({ type: ActionTypes.LOAD_APPS_ERROR, error: '' });
    }
}

export function* watchAppSaga() {
    yield takeEvery(ActionTypes.LOAD_APP_CONFIG_LOADING, fetchAppConfig);
    yield takeEvery(ActionTypes.LOAD_APPS_LOADING, fetchApps);
    yield takeEvery(ActionTypes.GET_TEMPLATES, fetchTemplates);
    yield takeEvery(ActionTypes.LOAD_ROLES_LOADING, fetchRoles);
    yield takeEvery(ActionTypes.SAVE_APP_LOADING, saveDapp);
    yield takeEvery(ActionTypes.SAVE_DATASOURCE_LOADING, saveDatasource);
    yield takeEvery(ActionTypes.GET_APP_TEMPLATES, fetchAppsAndTemplate);
    yield takeEvery(ActionTypes.LOAD_DATAELEMENTS_LOADING, fetchDataelements);
    yield takeEvery(ActionTypes.LOAD_MEMBER_LOADING, fetchMember);
    yield takeEvery(ActionTypes.GET_ENTITY_LOADING, fetchEntity);
    yield takeEvery(ActionTypes.GET_MEMBER_LOADING, fetchMemberById);
    yield takeEvery(ActionTypes.SAVE_DATAMODEL_LOADING, saveDataModel);
    yield takeEvery(ActionTypes.VERIFY_APP, verifyApp);
}

export default watchAppSaga;
