const API_BASE_URL = process.env.APP_API_BASE_ADDRESS
import axios from 'axios'
import { request } from 'modules/client'

export class CappsService {

	static uploadCapps(data) {
		const { files } = data
		const uri = API_BASE_URL + "/config/dapps/upload"
		return axios({
			url: uri,
			method: 'POST',
			data: files,
			headers: {
				'Content-Type': 'multipart/form-data',
				appId: data.appId
			},
			onUploadProgress: (p) => {

			}
		}).then((response) => {
			return response
		}).catch((error) => {
			return error
		})
	}

	static getCapp(data) {
		let uri = API_BASE_URL + "/dataconnect/table/" + data.id + "?" + "page=" + data.page
			+ "&" + "pageSize=" + data.pageSize + "&fields=";
		if (data.conditions && data.conditions.length > 0) {
			let cond = JSON.stringify(data.conditions);
			uri += "&conditions=" + cond;
		}
		return request(uri, {
			method: 'GET',
			headers: {
				appId: data.appId
			}
		})
	}

	static filterCappData = (data) => {
		const uri = API_BASE_URL + "/config/dapps"
		return request(uri, {
			method: 'PUT',
			payload: data,
			headers: {
				appId: data.appId
			}
		})
	}

	static ruleExcutionData = (data) => {
		const uri = API_BASE_URL + "/businessRules/config/executor/"
		return request(uri, {
			method: 'POST',
			payload: data,
			headers: {
				appId: data.appId
			}
		})
	}
}


