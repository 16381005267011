import { makeStyles, Typography } from '@material-ui/core';
import { LabelColorBoxes, ValueContainersV1 } from 'dsilo-ui-components';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getDataModelById, updateDataModel } from '../../store/actions';
import { Card } from './card';

const useStyles = makeStyles((theme) => ({
	divider: {
		width: 2,
		height: 180,
		backgroundColor: '#cecece',
		margin: '10px 0 10px 0',
	},
	healthCheck: { height: 100, width: 200, backgroundColor: 'green', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 },
	healthCheckFailed: { height: 100, width: 200, backgroundColor: 'red', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }
}));

const SupplierMainInfoBox = (props) => {

	const [cardData, setCardData] = useState(props.Values)
	const classes = useStyles();
	console.log("supplier ===", props.supplier, props.cardData);

	return <div>
		<div style={{ display: 'flex', }}>
			<div style={{ marginRight: 15, }}>
				<Card supplier={props.supplier}/>
			</div>
			<div style={{ width: '100%', }}>
				<LabelColorBoxes cardsData={props.cardValues} />
				<div style={{ display: 'flex', justifyContent: props.cardData && props.cardData.length > 0 ? 'space-around' : '' }}>
					{props.cardData && props.cardData.length > 0 && <>
						<ValueContainersV1 items={props.cardData} viewConfig={{ description: true, header: true }} />
						<div className={classes.divider} />
						</>
					}
					<div>
						<Typography variant="h6" gutterBottom component="div" style={{ margin: '10px 0 10px 0' }}>
							Overall onboarding status
						</Typography>
						<div className={props.supplier?.onboardingStatus?.toLowerCase() === "completed" 
						? classes.healthCheck : classes.healthCheckFailed}>
						<span>{props.supplier?.onboardingStatus}</span></div>
					</div>
				</div>
			</div>
		</div>
	</div>
}

const mapDispatchToProps = {
	getDataModelById,
	updateDataModel,
};

const mapStateToProps = state => {
	return {
		formData: state.form,
		user: state.user,
	};
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(SupplierMainInfoBox)));
