import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  scales: {
    x:{display: false},
    y: { display: false},
  },
  plugins: {
    legend: {
        display: false,
    },  
  },
  elements: {
    point:{
        radius: 0
    }
}
};


export default function LineChart(props) {
    console.log('LineChart-props',props)

    const data = {
        labels : ["<2 data points", "<5 data points", ">5 datappints"],
        datasets: [
          {
            data: [
              "0",
              "0.5",
              "0.6",
              "0.4",
              "0.7",
              "0.8",
              "1",
              "0.8",
              "0.7",
              "0.4",
              "0.3",
              "0.2",
              "0.4",
              "0.5"
            ],
            borderColor: props.grfColor,
          }
        ]
      };
  return <Line options={options} data={props.data} />;
}
