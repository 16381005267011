import { put, takeEvery } from 'redux-saga/effects';

import { ActionTypes } from '../constants/index';
import { ChatService } from '../modules/api/index';

async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

function* createNewPrompt(action) {
    try {
        const response = yield fetchAsync(ChatService.createNewPrompt, action.payload);
        yield put({ 
            type: ActionTypes.GET_PROMPT_RESPONSE_SUCCESS, 
            payload: { 
                data: response.data, 
                dataUniqueId: action?.payload?.dataUniqueId || 'newPrompt',
                chatId: action?.payload?.chatId || ''
            } 
        });
    } catch (e) {
        yield put({
            type: ActionTypes.GET_PROMPT_RESPONSE_FAILURE, 
            payload: {
                error:e.message, 
                dataUniqueId: action?.payload?.dataUniqueId || 'newPrompt'
            } 
        });
    }
}

function* getChatHistory(action) {
    try {
        const response = yield fetchAsync(ChatService.getChatHistory, action.payload);
        yield put({ 
            type: ActionTypes.GET_CHAT_HISTORY_DATA_SUCCESS, 
            payload: { 
                data: response.data, 
                dataUniqueId: action?.payload?.dataUniqueId 
            } 
        });
    } catch (e) {
        yield put({
            type: ActionTypes.GET_CHAT_HISTORY_DATA_FAILURE, 
            payload: {
                error:e.message, 
                dataUniqueId: action?.payload?.dataUniqueId
            } 
        });
    }
}

function* getChatHistoryById(action) {
    try {
        const response = yield fetchAsync(ChatService.getChatHistoryById, action.payload);
        yield put({ 
            type: ActionTypes.GET_CHAT_HISTORY_DATA_BY_ID_SUCCESS,
            payload: { 
                data: {
                    data: response?.data?.data,
                    page: action.payload?.payload?.page,
                    totalCount: response?.data?.total
                }, 
                dataUniqueId: action?.payload?.dataUniqueId 
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.GET_CHAT_HISTORY_DATA_BY_ID_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId 
            } 
        });
    }
}

function* getAllWorkspaces(action) {
    try {
        const response = yield fetchAsync(ChatService.getAllWorkspaces, action.payload);
        yield put({ 
            type: ActionTypes.GET_WORKSPACES_DATA_SUCCESS, 
            payload: { 
                data: response.data, 
                dataUniqueId: action?.payload?.dataUniqueId 
            } 
        });
    } catch (e) {
        yield put({
            type: ActionTypes.GET_WORKSPACES_DATA_FAILURE, 
            payload: {
                error:e.message, 
                dataUniqueId: action?.payload?.dataUniqueId
            } 
        });
    }
}

function* getWorkspaceById(action) {
    try {
        const response = yield fetchAsync(ChatService.getWorkspaceById, action.payload);
        yield put({ 
            type: ActionTypes.GET_WORKSPACES_DATA_BY_ID_SUCCESS,
            payload: { 
                data: response?.data, 
                dataUniqueId: action?.payload?.dataUniqueId,
                workspaceUniqueId: action?.payload?.workspaceUniqueId || ''
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.GET_WORKSPACES_DATA_BY_ID_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId 
            } 
        });
    }
}

function* createNewWorkspace(action) {
    try {
        const response = yield fetchAsync(ChatService.createNewWorkspace, action.payload);
        yield put({ 
            type: ActionTypes.CREATE_WORKSPACE_SUCCESS,
            payload: { 
                data: response?.data, 
                dataUniqueId: action?.payload?.dataUniqueId,
                // workspaceByIdUniqueId: action?.payload?.workspaceByIdUniqueId || ""
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.CREATE_WORKSPACE_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId,
            } 
        });
    }
}

function* updateWorkspace(action) {
    try {
        const response = yield fetchAsync(ChatService.updateWorkspace, action.payload);
        yield put({ 
            type: ActionTypes.UPDATE_WORKSPACE_SUCCESS,
            payload: { 
                data: action.payload.payload.chart, 
                dataUniqueId: action?.payload?.dataUniqueId,
                workspaceByIdUniqueId: action?.payload?.workspaceByIdUniqueId || "",
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.UPDATE_WORKSPACE_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId 
            } 
        });
    }
}

function* addNewChartToWorkspace(action) {
    try {
        const response = yield fetchAsync(ChatService.addNewChartToWorkspace, action.payload);
        yield put({ 
            type: ActionTypes.ADD_NEW_CHART_TO_WORKSPACE_SUCCESS,
            payload: { 
                data: response?.data, 
                dataUniqueId: action?.payload?.dataUniqueId,
                workspaceUniqueId: action?.payload?.workspaceUniqueId || "",
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.ADD_NEW_CHART_TO_WORKSPACE_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId 
            } 
        });
    }
}

function* deleteChartFromWorkspace(action) {
    try {
        const response = yield fetchAsync(ChatService.deleteChartFromWorkspace, action.payload);
        yield put({ 
            type: ActionTypes.DELETE_CHART_FROM_WORKSPACE_SUCCESS,
            payload: { 
                data: action?.payload?.payload?.workspaceId, 
                dataUniqueId: action?.payload?.dataUniqueId,
                workspaceByIdUniqueId: action?.payload?.workspaceByIdUniqueId || "",
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.DELETE_CHART_FROM_WORKSPACE_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId 
            } 
        });
    }
}

function* updateWorkspaceTitle(action) {
    try {
        const response = yield fetchAsync(ChatService.updateWorkspaceTitle, action.payload);
        yield put({ 
            type: ActionTypes.UPDATE_WORKSPACE_TITLE_SUCCESS,
            payload: { 
                data: {
                    title: action.payload?.payload?.title,  //response?.data, 
                    workspaceId: action.payload?.workspaceId || ''
                },
                dataUniqueId: action?.payload?.dataUniqueId,
                workspaceUniqueId: action?.payload?.workspaceUniqueId || '',
                workspaceByIdUniqueId: action?.payload?.workspaceByIdUniqueId || "",
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.UPDATE_WORKSPACE_TITLE_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId 
            } 
        });
    }
}

function* deleteChatFromHistory(action) {
    try {
        const response = yield fetchAsync(ChatService.deleteChatFromHistory, action.payload);
        yield put({ 
            type: ActionTypes.DELETE_CHAT_FROM_HISTORY_SUCCESS,
            payload: { 
                data: action.payload.newChatHistory, 
                dataUniqueId: action?.payload?.dataUniqueId,
                chatId: action?.payload?.chatId || "",
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.DELETE_CHAT_FROM_HISTORY_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId 
            } 
        });
    }
}

function* deleteQuestionFromChatHistory(action) {
    try {
        const response = yield fetchAsync(ChatService.deleteQuestionFromChatHistory, action.payload);
        yield put({ 
            type: ActionTypes.DELETE_QUESTION_FROM_CHAT_HISTORY_SUCCESS,
            payload: { 
                data: action.payload?.payload?.questionId,
                dataUniqueId: action?.payload?.dataUniqueId,
                chatId: action?.payload?.chatId || "",
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.DELETE_QUESTION_FROM_CHAT_HISTORY_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId 
            } 
        });
    }
}

function* updateChatTitle(action) {
    try {
        const response = yield fetchAsync(ChatService.updateChatTitle, action.payload);
        yield put({ 
            type: ActionTypes.UPDATE_CHAT_TITLE_SUCCESS,
            payload: { 
                data:  action.payload?.payload?.name,
                chatId: action.payload?.chatId || '',
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.UPDATE_CHAT_TITLE_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId 
            } 
        });
    }
}

function* publishOrDraftWorkspace(action) {
    try {
        const response = yield fetchAsync(ChatService.publishOrDraftWorkspace, action.payload);
        yield put({ 
            type: ActionTypes.PUBLISH_OR_DRAFT_WORKSPACE_SUCCESS,
            payload: { 
                data:  action.payload?.payload?.status,
                workspaceId: action.payload?.workspaceId || '',
                dataUniqueId: action?.payload?.dataUniqueId,
                workspaceByIdUniqueId: action?.payload?.workspaceByIdUniqueId || "",
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.PUBLISH_OR_DRAFT_WORKSPACE_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId 
            } 
        });
    }
}

function* deleteWorkspace(action) {
    try {
        const response = yield fetchAsync(ChatService.deleteWorkspace, action.payload);
        yield put({ 
            type: ActionTypes.DELETE_WORKSPACE_SUCCESS,
            payload: { 
                data: action.payload.newWorkspacesList, 
                dataUniqueId: action?.payload?.dataUniqueId,
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.DELETE_WORKSPACE_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId 
            } 
        });
    }
}

function* getAllSaveForLaterChats(action) {
    try {
        const response = yield fetchAsync(ChatService.getAllSaveForLaterChats, action.payload);
        yield put({ 
            type: ActionTypes.GET_ALL_SAVEFORLATER_CHATS_SUCCESS,
            payload: { 
                data: response?.data, 
                dataUniqueId: action?.payload?.dataUniqueId,
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.GET_ALL_SAVEFORLATER_CHATS_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId 
            } 
        });
    }
}

function* getSingleSaveForLaterChat(action) {
    try {
        const response = yield fetchAsync(ChatService.getSingleSaveForLaterChat, action.payload);
        yield put({ 
            type: ActionTypes.GET_SAVEFORLATER_BY_CHAT_SUCCESS,
            payload: { 
                data: response?.data, 
                dataUniqueId: action?.payload?.dataUniqueId,
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.GET_SAVEFORLATER_BY_CHAT_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId 
            } 
        });
    }
}

function* getSampleQuestions(action) {
    try {
        const response = yield fetchAsync(ChatService.getSampleQuestions, action.payload);
        yield put({ 
            type: ActionTypes.GET_SAMPLE_QUESTIONS_SUCCESS,
            payload: { 
                data: response?.data, 
                dataUniqueId: action?.payload?.dataUniqueId,
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.GET_SAMPLE_QUESTIONS_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId 
            } 
        });
    }
}

function* getQuestionComments(action) {
    try {
        const response = yield fetchAsync(ChatService.getQuestionComments, action.payload);
        yield put({ 
            type: ActionTypes.GET_QUESTION_COMMENTS_SUCCESS,
            payload: { 
                data: response?.data, 
                dataUniqueId: action?.payload?.dataUniqueId,
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.GET_QUESTION_COMMENTS_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId 
            } 
        });
    }
}

function* createComment(action) {
    try {
        const response = yield fetchAsync(ChatService.createComment, action.payload);
        yield put({ 
            type: ActionTypes.CREATE_QUESTION_COMMENTS_SUCCESS,
            payload: { 
                data: response?.data, 
                dataUniqueId: action?.payload?.dataUniqueId,
                commentsUniqueId: action?.payload?.commentsUniqueId
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.CREATE_QUESTION_COMMENTS_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId 
            } 
        });
    }
}

function* questionLike(action) {
    try {
        const response = yield fetchAsync(ChatService.likeQuestion, action.payload);
        yield put({ 
            type: ActionTypes.QUESTION_LIKE_SUCCESS,
            payload: { 
                data: response?.data, 
                dataUniqueId: action?.payload?.dataUniqueId || 'questionLike',
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.QUESTION_LIKE_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId || 'questionLike'
            } 
        });
    }
}

function* questionDisLike(action) {
    try {
        const response = yield fetchAsync(ChatService.disLikeQuestion, action.payload);
        yield put({ 
            type: ActionTypes.QUESTION_DISLIKE_SUCCESS,
            payload: { 
                data: response?.data, 
                dataUniqueId: action?.payload?.dataUniqueId || 'questionDisLike',
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.QUESTION_DISLIKE_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId || 'questionDisLike'
            } 
        });
    }
}

function* savedForLater(action) {
    try {
        const response = yield fetchAsync(ChatService.savedForLater, action.payload);
        yield put({ 
            type: ActionTypes.QUESTION_SAVED_FOR_LATER_SUCCESS,
            payload: { 
                data: response?.data, 
                dataUniqueId: action?.payload?.dataUniqueId || 'questionSavedForLater',
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.QUESTION_SAVED_FOR_LATER_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId || 'questionSavedForLater'
            } 
        });
    }
}

function* rating(action) {
    try {
        const response = yield fetchAsync(ChatService.rating, action.payload);
        yield put({ 
            type: ActionTypes.QUESTION_RATING_SUCCESS,
            payload: { 
                data: response?.data, 
                dataUniqueId: action?.payload?.dataUniqueId || 'questionRating',
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.QUESTION_RATING_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId || 'questionRating' 
            } 
        });
    }
}

function* updateQuestionChartType(action) {
    try {
        console.log("action.payload ======", action.payload)
        const response = yield fetchAsync(ChatService.updateQuestionChartType, action.payload);
        console.log("response ======", response)
        yield put({ 
            type: ActionTypes.UPDATE_QUESTION_CHART_TYPE_SUCCESS,
            payload: { 
                data: response?.data, 
                dataUniqueId: action?.payload?.dataUniqueId || 'questionChartType',
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.UPDATE_QUESTION_CHART_TYPE_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId || 'questionChartType'
            } 
        });
    }
}

function* sendQuestionDataAsEmail(action) {
    try {
        const response = yield fetchAsync(ChatService.sendEmail, action.payload);
        console.log("response ======", response)
        yield put({ 
            type: ActionTypes.QUESTION_SEND_EMAIL_SUCCESS,
            payload: { 
                data: response?.data, 
                dataUniqueId: action?.payload?.dataUniqueId || 'questionSendEmail',
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.QUESTION_SEND_EMAIL_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId || 'questionSendEmail'
            } 
        });
    }
}

function* getAutoCompleteSuggestions(action) {
    try {
        const response = yield fetchAsync(ChatService.getAutoCompleteSuggestions, action.payload);
        console.log("response ======", response)
        yield put({ 
            type: ActionTypes.GET_AUTOCOMPLETE_SUGGESTIONS_SUCCESS,
            payload: { 
                data: response?.data, 
                dataUniqueId: action?.payload?.dataUniqueId || 'autoSuggestions',
            }
        });
    }
    catch (e) {
        yield put({ 
            type: ActionTypes.GET_AUTOCOMPLETE_SUGGESTIONS_FAILURE, 
            payload: {
                error: e ? e.message : 'Something went wrong', 
                dataUniqueId: action?.payload?.dataUniqueId || 'autoSuggestions'
            } 
        });
    }
}

export function* watchChatSaga() {
    yield takeEvery(ActionTypes.GET_PROMPT_RESPONSE, createNewPrompt);
    yield takeEvery(ActionTypes.GET_CHAT_HISTORY_DATA, getChatHistory);
    yield takeEvery(ActionTypes.GET_CHAT_HISTORY_BY_ID_DATA, getChatHistoryById);
    yield takeEvery(ActionTypes.GET_WORKSPACES_DATA, getAllWorkspaces);
    yield takeEvery(ActionTypes.GET_WORKSPACES_BY_ID_DATA, getWorkspaceById);
    yield takeEvery(ActionTypes.CREATE_WORKSPACE, createNewWorkspace);
    yield takeEvery(ActionTypes.UPDATE_WORKSPACE, updateWorkspace);
    yield takeEvery(ActionTypes.ADD_NEW_CHART_TO_WORKSPACE, addNewChartToWorkspace);
    yield takeEvery(ActionTypes.DELETE_CHART_FROM_WORKSPACE, deleteChartFromWorkspace);
    yield takeEvery(ActionTypes.UPDATE_WORKSPACE_TITLE, updateWorkspaceTitle);
    yield takeEvery(ActionTypes.DELETE_CHAT_FROM_HISTORY, deleteChatFromHistory);
    yield takeEvery(ActionTypes.DELETE_QUESTION_FROM_CHAT_HISTORY, deleteQuestionFromChatHistory);
    yield takeEvery(ActionTypes.UPDATE_CHAT_TITLE, updateChatTitle);
    yield takeEvery(ActionTypes.PUBLISH_OR_DRAFT_WORKSPACE, publishOrDraftWorkspace);
    yield takeEvery(ActionTypes.DELETE_WORKSPACE, deleteWorkspace);
    yield takeEvery(ActionTypes.GET_ALL_SAVEFORLATER_CHATS, getAllSaveForLaterChats);
    yield takeEvery(ActionTypes.GET_SAVEFORLATER_BY_CHAT, getSingleSaveForLaterChat);
    yield takeEvery(ActionTypes.GET_SAMPLE_QUESTIONS, getSampleQuestions);
    yield takeEvery(ActionTypes.GET_QUESTION_COMMENTS, getQuestionComments);
    yield takeEvery(ActionTypes.CREATE_QUESTION_COMMENTS, createComment);
    yield takeEvery(ActionTypes.QUESTION_LIKE, questionLike);
    yield takeEvery(ActionTypes.QUESTION_DISLIKE, questionDisLike);
    yield takeEvery(ActionTypes.QUESTION_SAVED_FOR_LATER, savedForLater);
    yield takeEvery(ActionTypes.QUESTION_RATING, rating);
    yield takeEvery(ActionTypes.UPDATE_QUESTION_CHART_TYPE, updateQuestionChartType);
    yield takeEvery(ActionTypes.QUESTION_SEND_EMAIL, sendQuestionDataAsEmail);
    yield takeEvery(ActionTypes.GET_AUTOCOMPLETE_SUGGESTIONS, getAutoCompleteSuggestions);
}

export default watchChatSaga;