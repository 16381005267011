import { PDFExtractedDataTablesViewWrapper } from "dsilo-ui-components";
import React from "react";
import { hot } from "react-hot-loader/root";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    clearSuccessMsg, downloadContractPDFAction, downloadSheet, getContractDataById, getEventByID, getModelDataDropDown, 
    initiateAction, updateDataModelData, processAgain, clearReduxDataOfCurrentComponent
} from '../../store/actions';
import { Loader } from "../loader";

const PDFExtractedDataTablesViewComponent = (props) => {
    return <div>
        <PDFExtractedDataTablesViewWrapper
            {...props}
            loader={<Loader />}
        />
    </div>
}


const mapDispatchToProps = {
    getContractDataById,
    updateDataModelData,
    clearSuccessMsg,
    downloadSheet,
    getModelDataDropDown,
    downloadContractPDFAction,
    getEventByID,
    initiateAction,
    processAgain,
    clearReduxDataOfCurrentComponent
};

const mapStateToProps = state => {
    return {
        formData: state.form,
        action: state.action,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFExtractedDataTablesViewComponent)));


