/**
 * @module Actions/Models
 * @desc Models Actions
 */
import { ActionTypes } from 'constants/index';

export const getModels = (data) => dispatch => {
    dispatch({ type: ActionTypes.GET_MODELS_LOADING, data });
};

export const getModel = (data) => dispatch => {
    dispatch({ type: ActionTypes.GET_MODEL_LOADING, data });
};

export const saveModel = (data) => dispatch => {
    dispatch({ type: ActionTypes.SAVE_MODEL_LOADING, data });
};

export const updateModel = (data) => dispatch => {
    dispatch({ type: ActionTypes.UPDATE_MODEL_LOADING, data });
};

export const deleteModel = (data) => dispatch => {
    dispatch({ type: ActionTypes.DELETE_MODEL_LOADING, data });
};

export const checkForTraining = (data) => dispatch => {
    dispatch({ type: ActionTypes.CHECK_FOR_TRAINING, payload: data });
  }

export const updateModelStatus = (data) => dispatch => {
    dispatch({ type: ActionTypes.UPDATE_MODEL_STATUS, payload: data });
  }

export const clearTrainingCheckData = (data) => dispatch => {
    dispatch({ type: ActionTypes.CLEAR_TRAINING_CHECK_DATA, payload: data });
}

