import { ActionTypes } from 'constants/index';

export function hideMessage()
{
    return {
        type: ActionTypes.HIDE_MESSAGE
    }
}

export function showMessage(options)
{
    return {
        type: ActionTypes.SHOW_MESSAGE,
        options
    }
}

