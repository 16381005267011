import axios from 'axios';
import { Request } from "./clientHelper";
const API_BASE_URL = process.env.REACT_APP_DATA_SERVICE_URL;

export class CappsService {

    static uploadCapps(data) {
        const { files } = data;
        const uri = API_BASE_URL + "/config/dapps/upload";
        console.log("=====files", files)
        return axios({
            url: uri,
            method: 'POST',
            data: files,
            headers: {
                'Content-Type': 'multipart/form-data',
                appId: data.appId
            },
            onUploadProgress: (p) => {

            }
        }).then((response) => {
            return response
        }).catch((error) => {
            return error
        })
    }

    static getCapp(data) {
        console.log('datat', data);
        let uri = API_BASE_URL + "/dataconnect/table/" + data.id + "?" + "page=" + data.page
            + "&" + "pageSize=" + data.pageSize + "&fields=";
        if (data.conditions && data.conditions.length > 0) {
            let cond = JSON.stringify(data.conditions);
            uri += "&conditions=" + cond;
        }
        // const uri = API_BASE_URL + "/config/dapps" + "?" + "collectionName=" + data.collectionName + "&" + "page=" + data.page + "&" + "pageSize=" + data.pageSize;
        return Request(uri, {
            method: 'GET',
            headers: {
                appId: data.appId
            }
        });
    }

    static filterCappData = (data) => {
        const uri = API_BASE_URL + "/config/dapps";
        return Request(uri, {
            method: 'PUT',
            payload: data,
            headers: {
                appId: data.appId
            }
        });
    }

    static ruleExcutionData = (data) => {
        const uri = API_BASE_URL + "/businessRules/config/executor/";
        return Request(uri, {
            method: 'POST',
            payload: data,
            headers: {
                appId: data.appId
            }
        });
    }

}


