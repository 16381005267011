import "core-js";

export const arrayGroupByAllKeys = (arr) => {
	return arr.group((value) => {
		console.log("product ====", value)
		return arr[value];
	})
}

/**
 * Groups an array of objects by a specified key.
 *
 * @param {Array} arr - The array to be grouped.
 * @param {string} key - The key to group the array by.
 * @return {Object} - An object with keys as the unique values of the specified key and values as arrays of objects that have the same value for the specified key.
 */
export const groupByInternalLogic = (arr, key) => {
	return arr.reduce((result, item) => {
		// Get the value of the key for the current item
		const value = item[key];

		// Check if the key exists in the result object
		if (result[value]) {
			// If it exists, push the current item to the existing array
			result[value].push(item);
		} else {
			// If it doesn't exist, create a new array with the current item
			result[value] = [item];
		}

		return result;
	}, {});
}