import React from "react";
import { makeStyles } from '@material-ui/core';
import LoadingIcon from 'images/gif/loading.gif'

const Loader = (props) => {

  const useStyles = makeStyles({
    flex: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  });

  const classes = useStyles();

  return (
    <div className={classes.flex}>
      <img src={LoadingIcon} alt='loading_img' />
    </div>
  )
}

export default Loader;
