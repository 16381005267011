import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import GetIconByStatus from './GetIconByStatus';

const NotificationMsg = props => {
	const { closeToast, notification, toastProps } = props;

	const notifcationMessage = useMemo(() => {
		if(notification?.process) {
			return notification?.message;
		} else if (notification?.subprocess) {
			return `${notification?.fileName || ''} ${notification?.message}`
		}
	}, [notification]);

	return (
		<Box
			style={{
				alignItems: 'center',
				display: 'flex',
				gap: '4px',
			}}
		>
			<GetIconByStatus status={notification?.status} />
			<Typography
				variant="body1" 
				component="p" 
				color="primary" 
				style={{ 
					fontSize: 14, 
					lineBreak: 'auto'
				}}
			>
				{notifcationMessage}
			</Typography>
		</Box>
	);
};
export default NotificationMsg;
