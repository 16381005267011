import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { PersistGate } from 'redux-persist/lib/integration/react';

import { AppError } from 'dsilo-ui-components';

import App from './App';
import { msalConfig } from './authConfig';
import { register } from './serviceWorker';
import { store, persistor } from 'store/index';

import './index.scss';
import 'react-toastify/dist/ReactToastify.css'

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <PersistGate persistor={persistor}>
        <AppError>
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </AppError>
      </PersistGate>
    </MsalProvider>
  </Provider>,
  document.getElementById('root'),
);

register({
  onUpdate: () => { },
});
