import React, { useEffect, useState } from "react";
import { hot } from "react-hot-loader/root";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DeleteDocumentViewerWrapper } from "dsilo-ui-components";

import { Loader } from "../loader";
import { PUBLIC_PATH } from 'constants/index';

import {
    clearReduxDataOfCurrentComponent,
    downloadContractPDFAction
} from '../../store/actions';

const DeleteDocumentViewer = (props) => {
    return (
        <div>
            <DeleteDocumentViewerWrapper {...props} loader={<Loader />} publicPath={PUBLIC_PATH} />
        </div>
    )
}

const mapDispatchToProps = {
    clearReduxDataOfCurrentComponent,
    downloadContractPDFAction
};

const mapStateToProps = state => {
    return {
        formData: state.form,
        dataModels: state.dataModels,
        user: state.user
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteDocumentViewer)));