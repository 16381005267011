import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	drawer: {
	  	zIndex: 1210,
	},
	paper: {
		zIndex: 999,
		width: '55vh',
		top: '4.25em',
		height: 'calc(100vh - 85px)'
	},
	drawerHeader: { 
		display: 'flex',
		justifyContent: 'space-between', 
		alignItems: 'center',
		margin: '10px 10px 0 10px',
		borderBottom: '1px solid grey'
	},
	heading: {
		fontSize: theme.typography.pxToRem(14),
		fontWeight: theme.typography.fontWeightRegular,
	},
	emptyNotifications: { 
		display: 'flex', 
		flexDirection: 'column', 
		justifyContent:'center', 
		alignItems: 'center', 
		marginTop: '100px'
	},
	timer: {
		textAlign: 'end',
		fontSize: 10,
		margin: '12px 10px 10px 0'
	},
	clearAllBtn: {
		// textTransform: 'capitalize',
		fontSize: 12
	}
}));

export default useStyles