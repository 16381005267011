import { LabelColorBoxWrapper } from 'dsilo-ui-components';
import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearReduxDataOfCurrentComponent, getContractDataById,getDataModelById, getModelData,
	getLabelBoxData,getModelDataDropDown ,clearReduxChartDataOfCurrentComponent} from '../../store/actions';

import { Loader } from "../loader";
const useStyles = makeStyles((theme) => ({
	paper: {
		height: '99%',
		width: '100%'
	}
}));

const LabelColorBoxSupport = (props) => {
	const classes = useStyles();
	return <Paper elevation={props.layoutStyles?.chartLevelPaper ? 2 : 0} className={classes.paper}>
		<LabelColorBoxWrapper loader={<Loader />} {...props} />
		</Paper>
}

const mapDispatchToProps = {
    getContractDataById,
    getDataModelById,
	getModelData,
	clearReduxDataOfCurrentComponent,
	getLabelBoxData,
	getModelDataDropDown,
	clearReduxChartDataOfCurrentComponent
};

const mapStateToProps = state => {
	return {
	  formData: state.form,	
	  user: state.user,
	  labelBoxData: state.chart,
	  dataLabel: state.chart.data,
	};
  };
  
  export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(LabelColorBoxSupport)));
  