import React from 'react'
import { connect } from 'react-redux'
import * as Actions from 'store/actions'

const Redux = (PassedComponent) => {
	const mapStateToProps = (state) => {
		console.log('state == ', state);
		return {
			apps: state.app,
			user: state.user,
		}
	}

	const mapDispatchToProps = {
		getTemplates: Actions.getTemplates,
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux