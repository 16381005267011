import React, { useEffect, useState } from 'react'

import {
    Box, Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Divider, Grid,
    Typography, OutlinedInput, InputAdornment, TextField, IconButton
} from '@material-ui/core'
import RenderFieldBasedOnType from '../../RenderFieldsBasedOnType'
import { ExternalFilter, FiltersUtils, Helpers } from 'dsilo-ui-components'
// import TableView from './tableView'
import TableView from './table.js'
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import clsx from 'clsx';
import { getDatafromDataModelIdPayload } from '../../utils/payload'

const ModelWithTable = (props) => {
    const { classes, toggleCompareModal, modalInfo, block, onSaveHandler, formData, selectedStep, appId, orgId, dataModelKey, defSelectedList, loader, isLoading } = props
    const inputRef = React.useRef(null)
    let _id = props.match.params.id // id is there then it is a edit mode
    const { chartUniqueId, chartData } = props.data
    const { externalFilterData, document } = props.formData
    const { externalFilter, conditions, dataModel, isSearch } = block  // reading the externalFilters from eachBlock

    const [modalFormState, setModalFormState] = useState({})
    const [blockHeaders, setBlockHeaders] = useState([])
    const [blockState, setBlockState] = useState([])
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [totalRecords, setTotalRecords] = React.useState(0);

    const [selectedFilterValues, setSelectedFilterValues] = useState([])
    const [filterConditions, setFilterConditions] = useState([])
    const [isSearchIconHide, setIsSearchIconHide] = useState(false);
	const [searchedText, setSearchedText] = useState('');

    // Updating the state variable with default Selected rows for the model
    useEffect(() => {
        console.log("DEf Selected --------->>> ", defSelectedList);
        defSelectedList?.length > 0 && setSelected(defSelectedList)
    }, [defSelectedList])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Below fetchDataModel is used getting the DataModel Data
    const fetchDataModel = () => {
        let payload = {
            appid: appId,
            orgId: orgId
        }
        if (dataModelKey) {
            if (!formData?.dataModel?.[dataModelKey]) {
                if (!formData?.dataModel?.[dataModelKey] && !formData[`${dataModelKey}_loading`]) {
                    payload['dataModelId'] = dataModelKey
                    payload['dataUniqueId'] = dataModelKey
                    props.getDataModelById(payload)

                }
            } else {
                console.log("cmng here into else of getting dynamic dataModel", dataModelKey);
                // setCurrentDataModel(formData?.dataModel?.[dataModelKey])
            }
        }
    }

    // getting the Documents from respective dataModel on change of below dependencies
    useEffect(() => {
        getDatafromDataModelId()
    }, [page, rowsPerPage, filterConditions]) // rowsPerPage

    // Below getDatafromDataModelId fnction is used for getting Dcouments data from datamodel using Ids
    const getDatafromDataModelId = (searchKey) => {
        let modelDatapayload = getDatafromDataModelIdPayload(appId, dataModelKey, page, rowsPerPage, filterConditions)
        if (searchKey) {
            modelDatapayload['search'] = searchKey;
        }
        props.getModelData({ ...modelDatapayload, dataUniqueId: chartUniqueId + '_assosiateddata' });
    }

    // below useEffect is used to update the state variables for table in the modal
    useEffect(() => {
        // console.log("11111111  dynamic assosiated Data ===>>>> ", formData?.document[chartUniqueId + '_assosiateddata']);

        if (formData?.document[chartUniqueId + '_assosiateddata']?.data?.length) {
            let dataList = formData?.document[chartUniqueId + '_assosiateddata']?.data || []
            let total = formData?.document[chartUniqueId + '_assosiateddata']?.total || []
            // console.log("assosiated Data ===>>>> ", dataList);
            // const filteredData = dataModel[dataModelKey];
            // setBlockHeaders(transformedArray(filteredData))
            setBlockState(dataList)
            setTotalRecords(total)

        } else {
            setBlockState([])
            setTotalRecords(0)
        }
    }, [props.formData?.document[chartUniqueId + '_assosiateddata']])

    // Below UseEffect is used getting the BlockHeader from dataElements of DataModel Data and the Current Block
    useEffect(() => {
        console.log("Cmng here dynamic dataModel fetch ====>>> ", formData?.dataModel, dataModelKey);

        if (formData?.dataModel[dataModelKey]) {
            let dataElements = formData.dataModel[dataModelKey].dataElements || []
            if (dataElements?.length) {
                let fdes = []
                console.log("Cmng here dynamic ===> ", block.dataElements, block?.dataElements?.length);
                if (block?.dataElements) {
                    // console.log("Cmng here ===> ", block.dataElements, dataElements);
                    fdes = JSON.parse(JSON.stringify(block.dataElements))
                    fdes = fdes.map(fde => {
                        let sde = dataElements.find(de => de.name === fde.value)
                        if (sde?.name) {
                            return {
                                ...sde,
                                ...fde
                            }
                        } else {
                            return fde
                        }
                    })
                    setBlockHeaders(fdes)
                    // console.log("Fdes ===> ", fdes);
                }
            }
        }
    }, [formData?.dataModel])

    // Updating the modelinfo state variable
    useEffect(() => {
        if (modalInfo.enable) {
            if (modalInfo.type === 'edit') {
                setModalFormState(modalInfo.data)
            }
        }
    }, [modalInfo])

    const capitalizeLabel = (str) => {
        return str
            .replace(/([A-Z])/g, ' $1')  // Add a space before each uppercase letter
            .replace(/^./, (char) => char.toUpperCase()); // Capitalize the first character
    };

    const transformedArray = (data) => {
        const modifiedArray = data.map(obj => {
            return Object.keys(obj).map(key => {
                return {
                    value: key,
                    label: capitalizeLabel(key)
                };
            });
        });
        console.log("Modified array ======>>> ", modifiedArray[0]);
        return modifiedArray[0];
    }

    // Below UseEffect is used getting the DataModel Data and the Documents based on Ids
    useEffect(() => {
        fetchDataModel()
        getDatafromDataModelId()
    }, [dataModelKey])

    const handleOnChange = (name, value) => {
        setModalFormState({
            ...modalFormState,
            [name]: value
        })
    }

    const onSave = () => {
        // let obj = JSON.parse(JSON.stringify(modalFormState))
        // if (!modalInfo.type === 'edit') {
        //     obj['id'] = Helpers.generateGUID()
        // }
        onSaveHandler(selected)
    }


    const handleSelectAllClick = (event) => {
        console.log("handleSelectAllClick Select ====>>> ", blockState, event);
        if (event.target.checked) {
            const newSelecteds = blockState.map((n) => n.originalName);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        console.log("handleClick Select ====>>> ", selected, name, selectedIndex, selectedIndex === -1);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const onExternalFilterChange = (filterValues, name, filterType) => {
        let updatedConditions = FiltersUtils.createConditions(filterValues, externalFilter)
        setFilterConditions(updatedConditions)
        setSelectedFilterValues(filterValues)
        // getHierarchys(updatedConditions)
    }
    
	const handleSearchClick = (type) => {
		setIsSearchIconHide(!isSearchIconHide)
		if (type === 'close' && searchedText) {
			onSearchEnter(null)
		}
	}

	const searchHandler = (e) => {
		setSearchedText(e ? e.target.value : "")
        // getDatafromDataModelId( e ? e.target.value : "")
		// refresh(true, filterConditions, page, rowsPerPage, viewType, e ? e.target.value : "");
	}

	const onSearchEnter = (e, onSearchButton = false) => {
		if (e === null) {
			setSearchedText('')
			setPage(0)
            getDatafromDataModelId("")
			// setLocalStorageObject({ ...localStorageObject, page: 0 })
			// refresh(true, filterConditions, page, rowsPerPage, viewType, "");
			// onGetAllData({ id: props.data.chartData?.selectedDataModel }, [..._conditions, ...filterConditions], 0, rowsPerPage, true, viewType, '')
		} else if (e?.keyCode === 13 || onSearchButton) {
			setPage(0)
            getDatafromDataModelId(searchedText)
			// setLocalStorageObject({ ...localStorageObject, page: 0 })
			// onGetAllData({ id: props.data.chartData?.selectedDataModel }, [..._conditions, ...filterConditions], 0, rowsPerPage, true, viewType, searchedText)
		}
	}

    return (
        <Dialog
            open={modalInfo.enable}
            fullWidth={true}
            // fullScreen={true}
            maxWidth={'xl'}
            onClose={toggleCompareModal}
            aria-labelledby='comparision-dialog-title'
        // className={classes.muiDailogPaper}
        >
            <DialogTitle id='customized-dialog-title' onClose={toggleCompareModal}>
                {modalInfo?.title || ''}
            </DialogTitle>
            <DialogContent dividers>

                {externalFilter?.length > 0 && <Box
                    sx={{
                        scrollbarWidth: 'thin',
                        '&::-webkit-scrollbar': {
                            height: '0.4em',
                            width: '0.4em',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#cdcdcd',
                            borderRadius: '10px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            background: '#7c7d7d'
                        }
                    }}
                    className={classes.filters}
                >
                    <div>
                        <ExternalFilter
                            {...props}
                            externalFilterValues={selectedFilterValues}
                            externalFilterData={externalFilterData}
                            externalFilters={externalFilter}
                            conditions={conditions} // send this conditions reading from the block
                            dataModel={dataModel} // send this dataModel reading from the block
                            onExternalFilterChange={onExternalFilterChange}
                        />
                    </div>
                    <div>
                        {isSearch && (
                            <React.Fragment>
                                {isSearchIconHide && (
                                    <TextField
                                        className={classes.searchTextField}
                                        id='standard-basic'
                                        label='Search'
                                        variant="outlined"
                                        value={searchedText}
                                        onChange={searchHandler}
                                        onKeyDown={onSearchEnter}
                                        autoFocus
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        title='Search'
                                                        onClick={(e) => onSearchEnter(e, true)}

                                                    >
                                                        <SearchIcon color="primary" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                                <div
                                    style={{
                                        color: "rgba(0, 0, 0, 0.87)",
                                        display: "inline-block"
                                    }}
                                >
                                    {isSearchIconHide
                                        ? (
                                            <IconButton
                                                title='Cancel'
                                                onClick={() => handleSearchClick('close')}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        )
                                        : (
                                            <IconButton
                                                title='Search'
                                                onClick={handleSearchClick}
                                                style={{
                                                    padding: 0,
                                                    paddingRight: 10
                                                }}
                                            >
                                                <SearchIcon className={clsx(classes.searchIcon, 'icon')} />
                                            </IconButton>
                                        )}
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </Box>}

                <TableView
                    showCheckbox={true}
                    classes={classes}
                    enableActions={false}
                    tableActions={""}
                    tableHeaders={blockHeaders} // get from json
                    tableRows={blockState} // get from json
                    onTableActionClick={console.log("Action clicked")}
                    handleSelectAllClick={handleSelectAllClick}
                    handleClick={handleClick}
                    selected={selected}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    totalRecords={totalRecords}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    loader={loader}
                    isLoading={formData?.[chartUniqueId + '_assosiateddata_loading']}
                />
                {console.log("Block Headers ==>>> ", blockHeaders, "\n\n blockState ===>>> ", blockState)}
            </DialogContent>
            <DialogActions>
                <Button
                    color={'primary'}
                    onClick={() => onSave()}
                >
                    Save
                </Button>
                <Button
                    color='inherit'
                    onClick={toggleCompareModal}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModelWithTable