import React, { useEffect, useState } from "react"

import Typography from '@material-ui/core/Typography';

import StepperViewView from "./View"


const StepperView = (props) => {
    const { formData, currentDocumentId } = props
    const { document } = formData
    const { chartData } = props.data

    const [enabledSteps, setEnabledSteps] = useState([])

    // Below UseEffect is used for getting the enabled Steps from config
    useEffect(() => {
        if (chartData?.steps?.length) {
            let d = chartData.steps.filter((step => step.enable))
            setEnabledSteps(d)
        }
    }, [chartData.steps])

    useEffect(() => {
        if (props?.match?.params?.appid) {
            getMembersList()
        }
    }, [props.match.params.appid])

    const getMembersList = () => {
        const appId = props.match.params.appid
        if (document[`${appId}_membersList`]) {

        } else {
            props.getMembersList({
                appId: appId,
                dataUniqueId: `${appId}_membersList`
            })
        }
    }

    return (
        <div style={{ padding: "20px" }}>
            {
                enabledSteps?.length ?
                    <StepperViewView
                        {...props}
                        steps={enabledSteps}
                        appId={props.match.params.appid}
                        orgId={props.appConfig?.org?._id}
                        currentDocumentId={currentDocumentId}
                        
                        membersList={document[`${props.match.params.appid}_membersList`]}
                    /> : <div>
                        <Typography>Check the config</Typography>
                    </div>
            }
        </div>
    )
}

export default StepperView