import React, { useEffect, useState } from 'react'
import { TextField, InputLabel, Grid, Paper, Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { ChipSelect } from 'dsilo-ui-components'
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    paper: {
    },
    gridContainer: {

    },
    InputLabel: {
        marginTop: 20
    },
    TextField: {
        width: '100%',
        '& .MuiInputBase-formControl': {
            height: '43px !important'
        }
    },
    status: {
        display: 'contents'
    }
}))
const sampleobj = { "name": true, "description": true }

const BasicInfo = (props) => {
    console.log('BasicInfo-props', props)
    const classes = useStyles();
    const [state, setState] = useState({ status: 'Active' })
    let history = useHistory();

    // const [validate, setValidate] = useState({})

    useEffect(() => {
        if (props.memberDetails) {
            setState({ ...state, ...props.memberDetails, ...props.fianlFormData })
        }
    }, [props.memberDetails, props.fianlFormData])

    useEffect(() => {
        return () => {
            setState({})
        }
    }, [])

    const onDocumentTypeChangerMembersList = (data, type) => {
        setState({ ...state, [type]: data })
        props.handlesSaveFunctionality({ ...state, [type]: data })
    }

    const onDocumentTypeChangeMembersRoles = (data, type) => {
        setState({ ...state, [type]: data })
        props.handlesSaveFunctionality({ ...state, [type]: data })
    }

    useEffect(() => {
        if (props.fianlFormData) {
            setState({ ...props.fianlFormData })
        }
    }, [props.fianlFormData])


    const handlesFormInputs = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
        props.handlesSaveFunctionality({ ...state, [e.target.name]: e.target.value })
        props.validator({ [e.target.name]: e.target.value ? false : true })
    }

    const conditionCheck = (sampleobj) => {
        if (Object.values(sampleobj).every(ele => !ele)) {
            return true
        } else if (Object.values(sampleobj).every(ele => ele)) {
            return true
        } else if (Object.values(sampleobj).some(ele => ele)) {
            return false
        }
    }

    const handlesFinalSubmit = () => {
        if (Object.keys(state).length === 0 || Object.keys(props.validate).length === 0) {
            props.validator({ name: true, description: true })
        } else if (Object.keys(props.validate).length === 2 && conditionCheck(props.validate)) {
            let payload = { ...state, ...props.fianlFormData, appId: props.appId }
            if (payload && payload.members && payload.members.length) {
                payload['members'] = payload.members.map(item => {
                    return {
                        id: item.value,
                        name: item.label,
                        label: item.label
                    }
                })
            }
            if (payload && payload.assignedRoles && payload.assignedRoles.length) {
                payload['roles'] = payload.assignedRoles.map(item => {
                    return {
                        id: item.value,
                        name: item.label,
                        label: item.label
                    }
                })
            }
            delete payload.assignedRoles;
            console.log("it is total payload before submit", payload);
            props.createGroup({
                appId: props.appId,
                dataUniqueId: props.data?.chartUniqueId + '_create_group',
                orgId: props.user.orgId,
                payload
            })
        } else {
            const merge = (sampleobj, validate) => {
                return { ...sampleobj, ...validate };
            }
            let ss = merge(sampleobj, props.validate)
            props.validator({ ...ss })
        }
    }


    useEffect(() => {
        if (props.formData?.document && props.formData?.document[props.data?.chartUniqueId + '_create_group'] && props.formData?.document[props.data?.chartUniqueId + '_create_group']?.message) {
            toast.success(props.formData?.document[props.data?.chartUniqueId + '_create_group']?.message);
            props.history.push({ pathname: `/${props.match.params.appid}/page/groups/` });
        }
        return () => {
            props.clearReduxDataOfCurrentComponent({
                key: "document",
                subKey: [props.data?.chartUniqueId + '_create_group']
            })
        }
    }, [props.formData && props.formData?.document[props.data?.chartUniqueId + '_create_group']])

    const handleCanclebtnClick = () => {
        history.goBack()
    }

    return (
        <Grid container xs={6} spacing={3} className={classes.gridContainer}>
            <Grid item xs={3} sm={3} >
                <InputLabel htmlFor="my-input" className={classes.InputLabel}>Name</InputLabel>
            </Grid>
            <Grid item xs={9} sm={9}>
                <TextField
                    variant="outlined"
                    className={classes.TextField}
                    placeholder={'Enter Name'}
                    onChange={handlesFormInputs}
                    name='name'
                    value={state.name}
                    error={props.validate.name ? true : false}
                    id="standard-error-helper-text"
                    helperText={props.validate.name ? 'Name is required' : ''}
                />
            </Grid>

            <Grid item xs={3} sm={3}>
                <InputLabel htmlFor="my-input" className={classes.InputLabel}>Description</InputLabel>
            </Grid>
            <Grid item xs={9} sm={9}>
                <TextField
                    variant="outlined"
                    className={classes.TextField}
                    placeholder={'Enter Description'}
                    onChange={handlesFormInputs}
                    name={'description'}
                    value={state.description}
                    error={props.validate.description ? true : false}
                    id="standard-error-helper-text"
                    helperText={props.validate.description ? 'Description is required' : ''}
                />
            </Grid>
            <Grid item xs={3} sm={3} >
                <InputLabel htmlFor="my-input" className={classes.InputLabel}>Select Members</InputLabel>
            </Grid>
            <Grid item xs={9} sm={9}>
                <ChipSelect
                    className={classes.chipSelect}
                    id='role'
                    onChange={(val) => onDocumentTypeChangerMembersList(val, 'members')}
                    value={props.fianlFormData?.members}
                    isSearchable={true}
                    isMulti
                    textFieldProps={{
                        InputLabelProps: {
                            shrink: true,
                        },
                        variant: 'outlined',
                    }}
                    variant="fixed"
                    options={props.membersList}
                />
            </Grid>
            <Grid item xs={3} sm={3} >
                <InputLabel htmlFor="my-input" className={classes.InputLabel}>Select Roles</InputLabel>
            </Grid>
            <Grid item xs={9} sm={9}>
                <ChipSelect
                    id='group'
                    className={classes.chipSelect}
                    onChange={(val) => onDocumentTypeChangeMembersRoles(val, 'assignedRoles')}
                    value={props.fianlFormData?.assignedRoles}
                    isSearchable={true}
                    isMulti
                    textFieldProps={{
                        InputLabelProps: {
                            shrink: true,
                        },
                        variant: 'outlined',
                    }}
                    variant="fixed"
                    options={props.membersRoles}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Button style={{ float: 'right' }} variant="contained" color="primary" onClick={handlesFinalSubmit}>{props.fianlFormData?._id ? "Update" : "Save"}</Button>
                {props.fianlFormData?._id &&
                    <Button style={{ float: 'right', marginRight: 10 }} variant="contained" color="secondary" onClick={handleCanclebtnClick}>{'Cancle'}</Button>}
            </Grid>
        </Grid>
    )
}

export default BasicInfo