import { ActionTypes } from 'constants/index';

const initialState = {
    model: {},
    loading: false,
    error: '',
    trainingCheck: null,
    modelStatus: null,
};
export default function modelReducer(state = initialState, action) {

    switch (action.type) {
        case ActionTypes.GET_MODEL_LOADING: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case ActionTypes.GET_MODEL_SUCCESS: {
            return {
                ...state,
                model: action.payload.data?action.payload.data:{},
                loading: false
            }
        }
        case ActionTypes.GET_MODEL_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case ActionTypes.SAVE_MODEL_LOADING: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case ActionTypes.SAVE_MODEL_SUCCESS: {
            return {
                ...state,
                model: action.payload.data?action.payload.data:{},
                loading: false
            }
        }
        case ActionTypes.SAVE_MODEL_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case ActionTypes.UPDATE_MODEL_LOADING: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case ActionTypes.UPDATE_MODEL_SUCCESS: {
            return {
                ...state,
                model: action.payload.data?action.payload.data:{},
                loading: false
            }
        }
        case ActionTypes.UPDATE_MODEL_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case ActionTypes.DELETE_MODEL_LOADING: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case ActionTypes.DELETE_MODEL_SUCCESS: {
            return {
                ...state,
                model: action.payload.data?action.payload.data:{},
                loading: false
            }
        }
        case ActionTypes.DELETE_MODEL_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case ActionTypes.CHECK_FOR_TRAINING: {
            return {
              ...state,
              [action.payload.dataUniqueId+ '_loading']: true
            }
          }
          case ActionTypes.CHECK_FOR_TRAINING_SUCCESS: {
            let trainingCheck = {...state.trainingCheck, [action.payload.dataUniqueId]: action.payload.data}
            return {
              ...state,
              [action.payload.dataUniqueId+ '_loading']: false,
              trainingCheck: trainingCheck,
            }
          }
          case ActionTypes.CHECK_FOR_TRAINING_FAILURE: {
            let trainingCheck = { ...state.trainingCheck, 'error': action.payload.error}
            return {
              ...state,
              [action.payload.dataUniqueId+ '_loading']: false,
              trainingCheck: trainingCheck,
            }
          }
        case ActionTypes.CLEAR_TRAINING_CHECK_DATA: {
            return {
                ...state,
                trainingCheck: null
            }
        }
        case ActionTypes.UPDATE_MODEL_STATUS: {
            return {
              ...state,
              [action.payload.dataUniqueId+ '_loading']: true
            }
          }
        case ActionTypes.UPDATE_MODEL_STATUS_SUCCESS: {
            let modelStatus = {...state.trainingCheck, [action.payload.dataUniqueId]: action.payload.data}
            return {
              ...state,
              [action.payload.dataUniqueId+ '_loading']: false,
              modelStatus: modelStatus,
            }
          }
        case ActionTypes.UPDATE_MODEL_STATUS_FAILURE: {
            let modelStatus = { ...state.trainingCheck, 'error': action.payload.error}
            return {
              ...state,
              [action.payload.dataUniqueId+ '_loading']: false,
              modelStatus: modelStatus,
            }
          }
        default: {
            return state;
        }
    }
}