import { CircularProgress, Paper } from '@material-ui/core';
import { generateGUID } from 'modules/page/page.module';
import React, { useEffect, useState } from 'react';
import { useStyles } from '../../components/highcharts/styles';
import Highcharts from './highchartwrapper'
require("highcharts/highcharts-more")(Highcharts);

const BarChart = (props) => {
    const [options, setOptions] = useState(props.options);
    const [id, setId] = useState(`bar-${generateGUID()}`)

    const classes = useStyles();

    const loading = props?.loading;

    const highChartsRender = () => {
        if (!loading) {
            Highcharts.chart({ ...props.options, chart: { ...props.options.chart, renderTo: id } });
        }
    }

    useEffect(() => {
        highChartsRender();
    }, [props.options]);

    if (loading) {
        return <div className={classes.loaderContainer}><CircularProgress /></div>
    }

    return (
        id && <Paper elevation={2}>
            <h2 className={classes.heading}>{props.title}</h2>
            <div id={id} className="chartEqualHeight"></div>
        </Paper>
    )
}

export default BarChart;
