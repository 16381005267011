import { ActionTypes } from 'constants/index';

const initialState = {
    capps: [],
    cappData: [],
    bussinessRules: [],
    uploadSucess: false

};

export default function cappsConfigReducer(state = initialState, action) {

    switch (action.type) {
        case ActionTypes.UPLOAD_CAPPS_SUCCESS: {
            return {
                ...state,
                uploadSucess: true,
                capps: action.payload,
            };
        }
        case ActionTypes.GET_CAPP_SUCCESS: {
            return {
                ...state,
                cappData: action.payload,
            };
        }
        case ActionTypes.FILTER_CAPP_SUCCESS: {
            return {
                ...state,
                cappData: action.payload,
            };
        }

        case ActionTypes.RULE_EXCUTER_SUCCESS: {
            return {
                ...state,
                bussinessRules: action.payload,
                ruleExecutorSucess: true,
            };
        }
        default: {
            return state;
        }
    }
}