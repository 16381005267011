import React, { useEffect, useState } from "react";
import Fields from "./fields";

const FieldTable = (props) => {

    const [values, setValues] = useState(props.values)

    useEffect(()=>{
        if(props.values){
            setValues(props.values)
        }
    },[props.values])

    const onChangeField = (value, rowIndex, fieldName) => {
        let _values = [...values]
        if (length === 1) {
            _values[rowIndex] = value
            setValues(_values)
        } else {
            _values[rowIndex][fieldName] = value
            setValues(_values)
        }
        if (rowIndex + 1 === values.length) {
            let obj = {}
            props.fields.forEach(i => {
                obj[i.name] = ''
            })
            setValues([...values, obj])
            props.onChange([...values, obj])
        }
    }

    const removeField = (_index) => {
        let _values = [...values]
        _values.splice(_index, 1)
        props.onChange([..._values])
        setValues(_values)
    }

    const addExtraRow = (_values) => {
        setValues(_values)
    }


    return (<>
        <Fields title={props.heading} fields={props.fields} values={values} removeField={removeField}
            addExtraEmptyRow={addExtraRow}
            onChangeField={onChangeField} />
    </>)
}

export default FieldTable;
