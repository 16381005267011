
import { Box } from '@material-ui/core';
import React from 'react';

export const NoAccess = () => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="70vh" width='100vw'>
            <div>
                <h3>You don't have required access, Please contact admin.</h3>
            </div>
        </Box>
    );
};

export default NoAccess;
