import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import { makeStyles, styled, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  drawer: {
    zIndex: 1251,
    '& .MuiDrawer-paper': {
      overflowY: 'scroll',
      maxHeight: '90%',
    },
  },
  filterIcon: {
    padding: 10,
    position: 'fixed',
    zIndex: 998,
    top: '5rem',
    backgroundColor: '#002744',
    opacity: 0.75,
    color: 'white',
    '& svg': {
      transition: 'transform 1s ease-in-out',
      '&:hover': {
        transform: 'rotate(360deg)',
      },
    },
    '&:hover': {
      backgroundColor: '#002744',
      opacity: 0.75,
      color: 'white',
    },
  },
  pdfIcon: {
    top: '8rem !important',
    '& svg': {
      transition: 'transform 1s ease-in-out',
      '&:hover': {
		transform: 'rotate(360deg)',
      },
    },
  },
  paper: {
    zIndex: 999,
    position: 'absolute',
    margin: theme.spacing(1.5),
    width: 'fit-content',
    padding: '12px',
    marginTop: '3rem',
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
  },
  expand_icon: {
    color: 'white',
  },
  iconButton: {
    padding: 4,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  listItemRoot: {
    paddingTop: '4px',
    paddingBottom: '4px',
    borderBottom: '1px solid #F7F7F7',
  },
  selectedChat: {
    backgroundColor: '#EDEDED !important',
  },
  listItemTextRoot: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    // marginLeft: 12
  }
}));

export default useStyles;

export const DrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'space-between', // to separate the close button and search box
  position: 'absolute',
  right: 0,
  gap: '4px'
}));

export const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    float: 'right',
    width: '300px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    background: '#002744',
    // opacity: 0.75,
    color: '#FFFFFF',
    marginBottom: '1px',
  },
  content: {
    '&$expanded': {
      margin: '0 0 0 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const NestedAccordionSummary = withStyles({
  root: {
    background: '#002744',
    opacity: 0.75,
    color: '#FFFFFF',
    marginBottom: '1px',
  },
  content: {
    '&$expanded': {
      margin: '5px 0 0 12px',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    textAlign: 'start',
    margin: '0 0 0 10px',
    textTransform: 'capitalize',
    borderBottom: '1px solid #dddddd',
    borderRadius: '1px',
    fontWeight: 100,
  },
}))(MuiAccordionDetails);
