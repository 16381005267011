import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, styled } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { useState } from "react";
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FiltersList from './FiltersList';
import { getModelDataDropDown } from '../../store/actions';

const useStyles = makeStyles((theme) => ({
    drawer: {
        zIndex: 1210
    },
    filterIcon: {
        padding: 10,
        position: 'absolute',
        zIndex: 998,
        marginRight: 10,
        backgroundColor: 'deepskyblue',
        color: 'antiquewhite',
        "&:hover": {
            backgroundColor: 'deepskyblue',
            color: 'antiquewhite',
        }
    },
    paper: {
        zIndex: 999,
        position: 'absolute',
        margin: theme.spacing(1.5),
        width: "fit-content",
        padding: "12px",
        marginTop: "3rem"
    }
}));

const DrawerHeader = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    justifyContent: "flex-end",
    position: "absolute",
    right: 0
}))
  

const PageLevelFilters = props => {
    const classes = useStyles();
    const getInitialFilterValues = () => {
        let obj = {};
        props.externalFilters.forEach(filter => {
            obj[filter.value] = null
        })
        return obj
    }

    const { page } = props
    const [openFiltersDrawer, setOpenFiltersDrawer] = React.useState(false);
    const [filterValues, setFilterValues] = useState(getInitialFilterValues())

    const handleChange = () => {
        console.log("drawer open ::: *** ", filterValues)
        setOpenFiltersDrawer((prev) => !prev);
    };

    return (<>
       {page?.filterAlign && page.filterAlign === "right" ? (
            <Box display="flex" direction="row" justifyContent="end">
                <IconButton className={classes.filterIcon} onClick={handleChange}>
                    <FilterListIcon style={{ fontSize: 28 }} />
                </IconButton>
                <Drawer anchor="right" className={classes.drawer} open={openFiltersDrawer} onClose={handleChange} variant="persistent">
                    <DrawerHeader>
                        <IconButton onClick={handleChange}>
                            <CloseIcon style={{ fontSize: 24 }} />
                        </IconButton>
                    </DrawerHeader>
                    <Box role="presentation" style={{ display: "flex", flexDirection: "column", marginBottom: 3, marginTop: "2em" }}>
                        <FiltersList 
                            {...props}
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                            getInitialFilterValues={getInitialFilterValues}
                            drawerOpen={openFiltersDrawer}
                        />
                    </Box>
                </Drawer>   
            </Box>
        ) : (
            <div style={{ display: "flex", marginBottom: 3 }}>
                <FiltersList 
                    {...props}
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    getInitialFilterValues={getInitialFilterValues}
                />
            </div>
        )}
    </>);
};

const mapDispatchToProps = {
    // getReduxExternalFilter,
	getModelDataDropDown
};

const mapStateToProps = state => {
	return {
		appConfig: state.appConfig.app,
        apps: state.app.app,
        chart:state.chart,
        formData: state.form,
        model: state.model,
        members: state.appConfig.members,
        login: state.login,
        user: state.user
	};
  };

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(PageLevelFilters)));