/**
 * @module Sagas/App
 * @desc App
 */
import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { AppService } from 'modules/api/index'
import { ActionTypes } from 'constants/index';

/**
 * Application
 *
 * @param {Object} action
 *
 */

async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

function* fetchEntities(action) {
    try {
        console.log("action.payload", action.payload);
        
        const entities = yield fetchAsync(AppService.getEntities, action.payload);
        console.log("entities", entities);
        yield put({type: ActionTypes.LOAD_ENTITIES_SUCCESS, payload: entities});
    } catch (e) {
        yield put({type: ActionTypes.LOAD_ENTITIES_ERROR, error: e.message});
    }
}

export function* watchEntitiesSaga() {
    yield takeEvery(ActionTypes.LOAD_ENTITIES_LOADING, fetchEntities);
}

export default watchEntitiesSaga;
