import { ActionTypes } from 'constants/index';

const initialState = {
    models: [],
    training: [],
    testing:[],
    active: [],
    loading: false,
    error: ''
};
export default function modelsReducer(state = initialState, action) {

    switch (action.type) {
        case ActionTypes.GET_MODELS_LOADING: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case ActionTypes.GET_MODELS_SUCCESS: {
            return {
                ...state,
                [action.payload.key]: action.payload?.data?.data || [],
                loading: false
            }
        }
        case ActionTypes.GET_MODELS_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        default: {
            return state;
        }
    }
}