import React, { useEffect, useState } from 'react';

import RenderFieldBasedOnType from '../../RenderFieldsBasedOnType';
import {
    Box, Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Divider, Grid,
    Typography, OutlinedInput
} from '@material-ui/core'
import { Helpers } from "dsilo-ui-components";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    statusDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid #ddd',
        alignItems: 'center',
        borderRadius: 4,
        padding: 5,
        marginTop: 10,
        fontSize: 13,
    },
    statusDivAction: {
        display: 'flex',
        alignItems: 'center',
    },
    rootContainer: {
        marginTop: "20px"
    },
    titleBlock: {
        display: "flex",
        alignItems: "center"
    },
    title: {
        fontWeight: "bold",
        fontSize: "14px"
    },
    addIcon: {
        marginLeft: "8px",
        cursor: "pointer"
    },
    tableHeaderCell: {
        // borderBottom:"none"
    },
    tableActions: {
        display: "flex",
        alignItems: "center"
    },
    actionIcon: {
        cursor: "pointer"
    },
    inputField: {
        width: "100%",
        height: "35px",
        paddingLeft: "5px"
    },
    selectField: {
        height: "35px",
    }
}))

/* For now It is static */
const defaultOptions = {
    // vendorName: [
    //     {
    //         value: "Vendor 1",
    //         label: "Vendor 1"
    //     },
    //     {
    //         value: "Vendor 2",
    //         label: "Vendor 2"
    //     }
    // ],
    assignedTo: [
        {
            value: "User 1",
            label: "User 1"
        },
        {
            value: "User 2",
            label: "User 2"
        }
    ],
    status: [
        {
            value: "Pending",
            label: "Pending"
        },
        {
            value: "In Progress",
            label: "In Progress"
        }
    ]
}

const SummaryBlock = (props) => {
    const classes = useStyles()

    const { orgId, appId, currentDocumentId, formData, selectedStep, block, blockState, handleCheckboxChange, onChange, selectedOptions, stateObj, keyName } = props
    const { document } = props.formData
    const { chartUniqueId } = props.data

    const [blockHeaders, setBlockHeaders] = useState([])

    // console.log("Props Log ====> ", props, "\n\n\n stateObj  ===>>>> ", stateObj);

    // Below UseEffect is used getting the BlockHeader from dataElements of DataModel Data and the Current Block
    useEffect(() => {
        if (formData?.dataModel[selectedStep.dataModel]) {
            let dataElements = formData.dataModel[selectedStep.dataModel].dataElements || []
            if (dataElements?.length) {
                let fdes = []
                if (block.config.dataElements) {
                    // console.log("Cmng here 111 ===> ", block.config.dataElements, dataElements );
                    fdes = JSON.parse(JSON.stringify(block.config.dataElements))
                    fdes = fdes.map(fde => {
                        let sde = dataElements.find(de => de.name === fde.value)
                        // console.log("Sde -====>> ", sde, sde?.name);
                        if (sde?.name) {
                            return {
                                ...sde,
                                ...fde
                            }
                        } else {
                            return fde
                        }
                    })
                    setBlockHeaders(fdes)
                }
            }
        }
    }, [formData?.dataModel])

    return (
        <React.Fragment>
            {/* {console.log("\n\n\n index --------->>> Block Headers ==> ", blockHeaders, blockState)} */}

            <Grid container spacing={1}>
                {
                    blockHeaders?.length > 0 ? blockHeaders.map((element, index) => {
                        const sValue = Helpers.generateValueByDataElementType(blockState, element) || '-'

                        let nonSelectStringFields = blockHeaders.filter((each, idx) => each.type !== "string" && each.type !== "select" && each.type !== "currency").length
                        let selectStringCurrencyFields = blockHeaders.filter((each, idx) => each.type == "string" || each.type == "select" || each.type == "currency").length
                        let usedGrids = (blockHeaders.length - nonSelectStringFields) * 2;

                        return (
                            <Grid
                                item
                                key={index}
                                xs={element.xs ? element.xs : (12 / blockHeaders.length)}
                                style={{ marginTop: "2px", marginBottom: "2px" }}
                            >
                                <Box style={{ width: "100%" }}>
                                    <Grid container style={{ display: "grid", gridTemplateRows: "auto auto" }}>
                                        <div>
                                            <Typography
                                                component='span'
                                                style={{ fontWeight: 'bold', fontSize: '14px' }}
                                            >
                                                {element.label}
                                            </Typography>
                                        </div>

                                        {element.readOnly ? <div>
                                            <Typography
                                                component='span'
                                                style={{
                                                    fontSize: '14px'
                                                }}
                                            >
                                                {sValue}
                                            </Typography>
                                        </div> :
                                            <div style={{ marginTop: "4px", width: "100%" }}>
                                                <RenderFieldBasedOnType
                                                    {...props}
                                                    classes={classes}
                                                    element={element}
                                                    onChange={onChange}
                                                    handleCheckboxChange={handleCheckboxChange}
                                                    dataObj={blockState}
                                                    options={defaultOptions[element.value || element.name]}
                                                    // selectedOptions={selectedOptions}
                                                    selectedStep={selectedStep}

                                                />
                                            </div>}
                                    </Grid>
                                </Box>
                            </Grid>
                        )
                    }) :
                        <Typography>{`No Fields are Configured`}</Typography>
                }
            </Grid>
        </React.Fragment>
    )
}

export default SummaryBlock