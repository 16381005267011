/**
 * @module Actions/Organisation
 * @desc Application Actions
 */
import { ActionTypes } from 'constants/index';

export const loadOrg = () => dispatch => {
  dispatch({ type: ActionTypes.LOAD_ORG_LOADING });
};
export const createOrg = data => dispatch => {
  dispatch({ type: ActionTypes.CREATE_ORG_LOADING, payload: data });
};

export const getOrgDetails = () => dispatch => {
  dispatch({ type: ActionTypes.GET_ORG_DETAILS_LOADING });
};

export const addMember = (data) => dispatch => {
  dispatch({ type: ActionTypes.ADD_ORG_MEMBER_LOADING,payload:data });
};

export const updateOrg = (data) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_ORG_LOADING ,payload:data });
};
export const  clearOrgMessage = () => dispatch => {
  dispatch({ type: ActionTypes.CLEAR_ORG_MESSAGE });
};

export const  getOrgLogo = (logoId) => dispatch => {
  dispatch({ type: ActionTypes.GET_ORG_LOGO ,payload:logoId});
};
export const setOrgIdInUser = (id) => dispatch => {
  dispatch({ type: ActionTypes.SET_ORG_ID_TO_USER_OBJ, payload: id });
};

export const setNewUserData = (data) => dispatch => {
  dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: data });
  dispatch({ type: ActionTypes.SET_USER_DATA, payload: data });
};

export const removeMessage = ()=> dispatch => {
  dispatch({ type: ActionTypes.CLEAR_ORG_ERROR });
};