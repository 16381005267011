import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { toast } from 'react-toastify';
import { io } from "socket.io-client";
import {
	fetchAllUnreadNotifications, getNotificationById, updateNotification
} from '../../store/actions';
import NotificationMsg from './NotificationMsg';

const NotificationSocket = (props) => {
	const [socketConnection, setSocketConnection] = useState(null)
	const [newNotification, setNewNotification] = useState(null)
	const appId = props?.appId;

	useEffect(() => {
		props.fetchAllUnreadNotifications(appId);

		const notificationUrl = process.env.REACT_APP_NOTIFICATION_SERVICE_URL;

		// For local uncomment next two lines
		// const socket = io('https://platform-dev.dsilo.ai', {
		// 	path: "/notification-service/api/v1/socket.io",
		const socket = io('', {
			path: notificationUrl + '/socket.io',
			reconnection: true,
			reconnectionDelay: 1000,
			reconnectionDelayMax: 5000,
			reconnectionAttempts: 3,
			transports: ['websocket']
		});

		socket.on('connect', () => {
			console.log(`Client connected: ***  ${socket.id}, AppId: ${appId}`)
			setSocketConnection(socket)
		});

		socket.on('disconnect', (reason) => {
			console.log(`Client disconnected:  *** ${reason}`);
			setSocketConnection(null)
		});

		socket.on('connect_error', (reason) => {
			console.log(`Client connect_error: *** ${reason}`)
			// if(reason === 'Error: websocket error') {
			// 	setTimeout(() => {
			// 		socket.connect();
			// 	}, 1000);
			// } else 
			setSocketConnection(null)
		});

		return () => {
			socket.off('connect', () => { });
			socket.off('disconnect', () => { });
			socket.disconnect();
			setSocketConnection(null);
		};
	}, []);

	useEffect(() => {
		const memberId = props.members?._id || '';
		if (socketConnection && memberId && appId) {
			const notificationTopicName = `${appId}-${memberId}`;
			// console.log(`Client connected: {ref} ***  ${socketConnection?.id}`, socketConnection)
			console.log('Client notificationTopicName ::: *** ', notificationTopicName);
			socketConnection.on(notificationTopicName, (newNotification) => {
				console.log(`new notification $$$$ :::: **** `, newNotification);
				setNewNotification(newNotification)
			});
		}

		return () => {
			if (socketConnection)
				socketConnection?.off(`${appId}-${memberId}`, () => { });
		};
	}, [socketConnection, props?.members]);

	useEffect(() => {
		const notificationsData = [...(props.notifications?.data || [])].map(notification => ({ ...notification }))
		console.log("notificationsData ==== :::: **** ", notificationsData)
		if (newNotification) {
			if (newNotification?.process && newNotification?._id && newNotification?.npId) {
				props.updateNotification(newNotification, true);
			} else if (newNotification?.subprocess && newNotification?.processId && newNotification?.npId) {
				const existingNotification = notificationsData.find(
					ntf => ntf?._id === newNotification?.processId && ntf?.npId === newNotification?.npId,
				);
				console.log("existingNotification #### :::: **** ", existingNotification)
				if (existingNotification) {
					props.updateNotification(newNotification)
				} else {
					const payload = {
						processId: newNotification?.processId,
						appId
					}
					props.getNotificationById(payload);
				}
			}
			toast(<NotificationMsg notification={newNotification} />);
		}
	}, [newNotification])


	return (
		<>
			{props.children}
		</>
	)
}

const mapStateToProps = state => {
	return {
		appDetails: state.appConfig,
		app: state.app,
		appConfig: state.form.document?.appConfig,
		notifications: state.notifications,
		members: state.appConfig.members,
		user: state.user
	};
};

const mapDispatchToProps = {
	fetchAllUnreadNotifications,
	getNotificationById,
	updateNotification
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(NotificationSocket));