import React from "react";
import { hot } from "react-hot-loader/root";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PDFComplexView } from 'dsilo-ui-components'
import {
    clearSuccessMsg, downloadContractPDFAction, downloadSheet, getContractDataById, getEventByID,
    updateContractAnnotations, getDataModelById, getModelData,
    getModelDataDropDown, initiateAction, updateDataModelData, getMembersList,
    clearReduxDataOfCurrentComponent, getAuditTrailsData, completeReviewAction, getDataModels, otherDocMove, rejectReviewAction,
    fetchmasterDataForDataelementUsingRules
} from '../../store/actions';
import { Loader } from "../loader";
import { PUBLIC_PATH } from "../../constants";

const PDFComplexViewComponent = (props) => {
    return <div>
        <PDFComplexView
            {...props}
            loader={<Loader />}
        />
    </div>
}

const mapDispatchToProps = {
    getContractDataById,
    updateDataModelData,
    clearSuccessMsg,
    downloadSheet,
    getModelDataDropDown,
    downloadContractPDFAction,
    getEventByID,
    initiateAction,
    getMembersList,
    clearReduxDataOfCurrentComponent,
    updateContractAnnotations,
    getDataModelById,
    getAuditTrailsData,
    getModelData,
    completeReviewAction,
    getDataModels,
    otherDocMove,
    rejectReviewAction,
    fetchmasterDataForDataelementUsingRules
};

const mapStateToProps = state => {
    return {
        action: state.action,
        formData: state.form,
        PUBLIC_PATH: PUBLIC_PATH,
        dataModels: state.dataModels,
        appConfig: state.appConfig.app,
        members: state.appConfig.members,
        currentMember: state.appConfig.members,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFComplexViewComponent)));
