import { Dialog, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  dialogPaper: {
    minWidth: 750,
    borderRadius: 10,
    maxWidth:'75vw',
  },
});

const Modal = props => {
  const classes = useStyles();
  return (
    <Dialog open={props.open} classes={{ paper: classes.dialogPaper }} {...props}>
      {props.children}
    </Dialog>
  );
};

export default Modal;
