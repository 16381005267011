import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { toast } from 'react-toastify';
import { EditorState, ContentState, convertFromHTML, convertToRaw, AtomicBlockUtils } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { supportedHighcharts } from '../ChatHelper';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// const validEmailRegex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/gim;
const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const imageTagRegex = /<img\b[^>]*>/;

const initialState = {
    to: '',
    cc: '',
    bcc: '',
    subject: ''
}

const initialErrorState = {
    to: false,
    cc: false,
    bcc: false,
    subject: false,
}

const EmailEditor = props => {
    const emailFormRef = useRef(null);
    const [emailForm, setEmailForm] = useState(initialState);
    const [errorState, setErrorState] = useState(initialErrorState);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        if(props?.selectedQuestion?.queryText) {
            setEmailForm({
                ...emailForm, 
                subject: props?.selectedQuestion?.queryText 
            });
        }
    }, [props?.selectedQuestion?.queryText]);

    useEffect(() => {
        if(props?.selectedQuestion?.chartType === 'text') {
            const previousContent = `<p>${props?.selectedQuestion?.responseData || ''}</p>`;
            const blocksFromHTML = convertFromHTML(previousContent);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            setEditorState(EditorState.createWithContent(contentState));
        } else if (props?.chartBase64String && supportedHighcharts.includes(props?.selectedQuestion?.chartType)) {
            // Convert base64 image to a ContentState with an atomic block containing the image
            const contentState = editorState.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', { 
                src: props?.chartBase64String,
                alt: props?.selectedQuestion?.queryText, 
                style: { width: '300px', height: '300px' }
            });
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
            const editorStateWithImage = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
            setEditorState(editorStateWithImage);
        }
    }, [props?.chartBase64String, props?.selectedQuestion?.chartType, props?.selectedQuestion?.data]);

    useEffect(() => {
        if (props?.emailSentSuccess) {
            toast.success('Email sent sucessfully.');
            handleClose();
        }
    }, [props?.emailSentSuccess]);

    const onEditorStateChange = editorState => {
        setEditorState(editorState);
    };

    const handleClose = () => {
        setEmailForm(initialState);
        setEditorState(EditorState.createEmpty());
        setErrorState(initialErrorState);
        props.onClose();
    };

    const isValid = () => {
        let validForm = true;
        const emailToValue = emailForm.to?.trim().split(',');
        const validToEmail = emailToValue.filter(str => {
            const value = str.trim();
            if (value !== '') return value;
        }).every((email) => {
            const singleEmail = email.trim();
            return validEmailRegex.test(singleEmail);
        });
        if (!validToEmail) {
            setErrorState({
                ...errorState,
                to: true,
            });
            validForm = false;
            if (emailFormRef?.current) {
                emailFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        } else {
            setErrorState({
                ...errorState,
                to: false,
            });
            validForm = true
        }
        return validForm;
    };

    const readContentFromEditor = () => {
        const contentState = editorState.getCurrentContent();
        const contentRaw = convertToRaw(contentState);
        // console.log("contentRaw ::: *** ", contentRaw); // You can use the contentRaw as needed
        const htmlContent = draftToHtml(contentRaw);
        return htmlContent;
    };

    const replaceImageTagWithCID = (editorBodyValue) => {
        const newImgTag = '<img src="cid:PGPTChart" alt="PGPT Image">';
        const newEmailBodyValue = editorBodyValue.replace(imageTagRegex, newImgTag);
        return newEmailBodyValue;
    }

    const handleSubmit = () => {
        if (isValid()) {
            const editorValue = readContentFromEditor();
            const emailBody = replaceImageTagWithCID(editorValue);
            const emailPayloadObj = {
                ...emailForm,
                body: emailBody,
                questionId: props?.selectedQuestion?.questionId,
                type: props?.selectedQuestion?.chartType === 'tile' ? 'table' : props?.selectedQuestion?.chartType,
                base64String: props?.chartBase64String || ''
            }
            props.onSubmit(emailPayloadObj);
        }
    };

    const dialogStyle = {
        minHeight: props.dialogHeight || '300px', // defaults to '300px' if dialogHeight is not provided
        // minWidth: props.dialogWidth || '500px', // defaults to '300px' if dialogWidth is not provided
        // maxWidth: props.dialogWidth || '700px', // defaults to '600px' if dialogWidth is not provided
    };

    const handleEmailFormChange = (event, keyToUpdate) => {
        setEmailForm({
            ...emailForm,
            [keyToUpdate]: event.target.value,
        });
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth={false}
            BackdropProps={{
                style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
                },
            }}
        >
            <DialogTitle id="form-dialog-title" className="dialog-title">
                {props.dialogTitle}
            </DialogTitle>
            <DialogContent style={dialogStyle}>
                <div className="" ref={emailFormRef}>
                    <form
                        className="pgpt-email-form"
                        autoComplete="off"
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <FormControl>
                            <FormLabel variant="h6">Recipients</FormLabel>
                            {['to', 'cc', 'bcc'].map((field, index) => (
                                <TextField
                                    key={field+index.toString()}
                                    error={errorState?.[field]}
                                    helperText={errorState?.[field] ? 'Please enter a valid email address.' : ''}
                                    fullWidth
                                    id={`outlined-${field}`}
                                    placeholder={field.toUpperCase()}
                                    value={emailForm?.[field]}
                                    onChange={event => handleEmailFormChange(event, field)}
                                    variant="outlined"
                                    style={{ backgroundColor: '#fff', margin: '10px 0' }}
                                    size="small"
                                />
                            ))}
                        </FormControl>
                        <FormControl>
                            <FormLabel variant="h6">Message Subject</FormLabel>
                            <TextField
                                error={errorState.subject}
                                helperText={errorState.subject ? 'Please enter subject' : ''}
                                fullWidth
                                id="email-subject"
                                placeholder="Sbject"
                                value={emailForm.subject}
                                onChange={event => handleEmailFormChange(event, 'subject')}
                                variant="outlined"
                                style={{ backgroundColor: '#fff', margin: '10px 0' }}
                                size="small"
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel variant="h6" style={{ marginBottom: '5px' }}>
                                Message Body
                            </FormLabel>
                            <Editor
                                editorStyle={{ minHeight: '200px', border: '1px solid #EEEEEE', margin: '5px 0' }}
                                editorState={editorState}
                                wrapperClassName="html-wrapper"
                                editorClassName="html-editor"
                                onEditorStateChange={onEditorStateChange}
                                toolbar={{
                                    inline: { inDropdown: true },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: true },
                                    link: { inDropdown: true },
                                    history: { inDropdown: true },
                                }}
                            />
                        </FormControl>
                        {/* {props?.chartBase64String && (
                            <img src={props?.chartBase64String} alt={props?.selectedQuestion?.title} />
                        )} */}
                        {['tile', 'table'].includes(props?.selectedQuestion?.chartType) && (
                            <Typography component="p" variant="body1">
                                <b>NOTE: </b> The data will be sent with this message as an attachment.
                            </Typography>
                        )}
                    </form>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} type='button' color="primary" disabled={props?.emailSending}>
                    {props?.emailSending ? <CircularProgress color="primary" size={20} /> : 'Submit'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EmailEditor;
