import { ActionTypes } from 'constants/index';

const initialState = {
    routes: [],
};

export default function breadcrumbReducer(state = initialState, action) {
    if(action.type === ActionTypes.UPDATE_ROUTE){
        return {
            ...state,
            routes: action.payload,
        }
    }
    return state;
}

// TODO: Plan is to create whole breadcrumb but currently using it to show selected item in Sidebar