import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper, Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow, Typography, Tooltip, Checkbox, TableSortLabel,
} from '@material-ui/core'
import PropTypes from 'prop-types';
import { Helpers } from "dsilo-ui-components";

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, tableHeaders, showCheckbox } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {showCheckbox && <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>}
                {tableHeaders?.map((header, lIndex) => (
                    <TableCell
                        key={lIndex}
                        className={classes.tableHeaderCell}
                        title={header.label || header.description}
                        style={{ minWidth: "200px" }}
                    >
                        {header.label || header.description}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const StickyHeadTable = (props) => {
    const { classes, enableActions, tableHeaders, tableRows, totalRecords, tableActions, isLoading, loader, onTableActionClick, showCheckbox, selected, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, handleClick, handleSelectAllClick, } = props

    const isSelected = (name) => selected ? selected.indexOf(name) !== -1 : false;

    // const classes = useStyles();

    return (
        <div className={classes.root} style={{ width: "100% !important" }}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                   
                    <EnhancedTableHead
                        classes={classes}
                        numSelected={selected?.length}
                        // order={order}
                        // orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        // onRequestSort={handleRequestSort}
                        rowCount={tableRows?.length}
                        showCheckbox={showCheckbox}
                        tableHeaders={tableHeaders}
                    />
                    <TableBody>
                      
                        {isLoading ? loader : tableRows?.length > 0 ?
                            (
                                tableRows.map((row, rIndex) => {
                                    const isItemSelected = isSelected(row._id);
                                    const labelId = `enhanced-table-checkbox-${rIndex}`;

                                    return (<TableRow
                                        hover
                                        onClick={(event) => showCheckbox ? handleClick(event, row._id) : null}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        selected={isItemSelected}
                                        key={rIndex}
                                        className={classes.tableHeadCellPadding}
                                    >
                                        {showCheckbox && <TableCell padding="checkbox" style={{ borderBottom: 'none' }}>
                                            <Checkbox
                                                checked={isItemSelected}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </TableCell>}
                                        {tableHeaders?.map((header, index) => {
                                            const colField = header.name || header.value
                                            // const colValue = row[colField] || '-'
                                            // const colValue = Helpers.convertValueWithDataType(row[header.name || header.value], header) || '-'
                                            const colValue = Helpers.generateValueByDataElementType(row, header) || '-'

                                            return (
                                                <TableCell
                                                    key={index}
                                                    className={classes.tableCellWrapText}
                                                    style={{
                                                        padding: '0px !important',
                                                        // ...(index !== 0 && {
                                                        //     maxWidth: '100px'
                                                        // }),
                                                        borderBottom: 'none'
                                                    }}
                                                >
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Typography
                                                            variant='body1'
                                                            component='span'
                                                            // noWrap
                                                            title={colValue}
                                                            style={{ fontSize: '14px' }}
                                                        >
                                                            {colValue}
                                                            {/* {column.format && typeof value === 'number' ? column.format(value) : value} */}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                            )
                                        })}
                                        {
                                            enableActions && <TableCell
                                                key={`actions-${rIndex}`}
                                                style={{ borderBottom: 'none' }}
                                            >
                                                <div className={classes.tableActions}>
                                                    {
                                                        tableActions.edit && <Tooltip title='Edit'>
                                                            <EditIcon
                                                                fontSize={'small'}
                                                                className={classes.actionIcon}
                                                                onClick={() => onTableActionClick('edit', row)}
                                                            />
                                                        </Tooltip>
                                                    }
                                                    {
                                                        tableActions.delete && <Tooltip title='Delete'>
                                                            <DeleteIcon
                                                                fontSize={'small'}
                                                                className={classes.actionIcon}
                                                                onClick={() => onTableActionClick('delete', row)}
                                                            />
                                                        </Tooltip>
                                                    }
                                                    {
                                                        tableActions.forward && <Tooltip title='Forward To'>
                                                            <SupervisorAccountIcon
                                                                fontSize={'small'}
                                                                className={classes.actionIcon}
                                                                onClick={() => onTableActionClick('forward', row)}
                                                            />
                                                        </Tooltip>
                                                    }
                                                </div>
                                            </TableCell>
                                        }
                                    </TableRow>)
                                })
                            ) : (
                                <TableRow>
                                    {tableHeaders?.map((header, lIndex) => (
                                        <TableCell
                                            key={lIndex}
                                            className={classes.tableCellWrapText}
                                            style={{
                                                padding: '0px !important',
                                                // ...(index !== 0 && {
                                                //     maxWidth: '100px'
                                                // }),
                                                borderBottom: 'none'
                                            }}
                                        >
                                            {(tableHeaders.length/2) == lIndex ?  <div style={{ display: "flex", alignItems: "center" }}>
                                                <Typography align='center'>
                                                    No Data to Display
                                                </Typography>
                                            </div> : null}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )
                        }




                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}

export default StickyHeadTable