import { it } from 'date-fns/locale';
import _ from 'modules/lodash';

export function getFilteredArray(data, searchText) {
	if (searchText.length === 0) {
		return data;
	}
	return _.filter(data, item => item.name.toLowerCase().includes(searchText.toLowerCase()));
};

export function selectedRow(event, _id, selected, selectedIndex) {
	let newSelected = [];

	if (selectedIndex === -1) {
		newSelected = newSelected.concat(selected, _id);
	}
	else if (selectedIndex === 0) {
		newSelected = newSelected.concat(selected.slice(1));
	}
	else if (selectedIndex === selected.length - 1) {
		newSelected = newSelected.concat(selected.slice(0, -1));
	}
	else if (selectedIndex > 0) {
		newSelected = newSelected.concat(
			selected.slice(0, selectedIndex),
			selected.slice(selectedIndex + 1)
		);
	}
	return newSelected;
}

export function handleSelectedRow(event, _id, selected) {
	const selectedIndex = selected.indexOf(_id);
	return selectedRow(event, _id, selected, selectedIndex);
}

export function isRowselected(_id, selected) {
	return selected.indexOf(_id) !== -1;
}

export function requestSort(event, property, data) {
	const orderBy = property;
	let order = 'desc';
	if (data.orderBy === property && data.order === 'desc') {
		order = 'asc';
	}
	return {
		order,
		orderBy
	};
};

export function handleTableSelectAllClick(event, data) {
	return event.target.checked ? data.map(n => n._id) : [];
};

function getTableHeader(data, keys) {
	let tableHeader = [];
	if (data && data.length > 0 && keys && keys.length > 0) {
		for (let key in data[0]) {
			if (keys.indexOf(key) === -1) {
				let headerItem = {
					id: key.replace(' ', ''),
					name: key,
					label: key,
					disablePadding: false,
					numeric: false,
					sort: true
				}
				tableHeader.push(headerItem)
			}
		}
	}
	return tableHeader;
};

export function updateTableData(state) {
	let obj = state.tableData;
	let filterObj = state.filterTableData;
	let list = obj.datasets ? obj.datasets : [];
	let finalList = [];
	if (state.searchText !== "") {
		finalList = list.map((item) => {
			let isFilter = false;
			Object.keys(item).map((key) => {
				filterObj.columns.map(keyobj => {//when columns are check
					if (keyobj.checked && key === keyobj.name) {
						if (item[key].toString().toLowerCase().includes(state.searchText.toLowerCase())) {
							isFilter = true;
						}
					}
				})
			})
			if (isFilter) return item;
		}).filter(function (element) {
			return element !== undefined;
		});
	} else {
		finalList = [...list];
	}
	state.filterList.map((item, index) => {
		finalList = finalList.map((dataSet) => {
			if (item && item !== "All") {
				if (dataSet[`${filterObj.columns[index].name}`] === item) {
					return dataSet
				}
			} else {
				return dataSet
			}
		}).filter(function (element) {
			return element !== undefined;
		});
	})


	filterObj.datasets = [...finalList]
	return { ...state, filterTableData: { ...filterObj } };
}

 



export function setDatafromApi(data, keysArr, state, rows) {
	if (data) {
		let tableHeader = getTableHeader(data, keysArr);
		let r = {
			columns: tableHeader,
			datasets: data
		}
		let obj = JSON.parse(JSON.stringify(r));
		let list = [];
		if (rows && rows.length > 0) {
			obj.columns.map((item) => {
				let isRec = false;
                console.log("Item",item.name)
				rows.map((column) => {
                    // console.log("Column",column) 
					if (column.id === item.name) {
						item.checked = true;
						item.label = column.label;
						list.push(item);
						isRec = true;
					}
				})
				if (!isRec) {
					item.checked = false;
					list.push(item);
				}
			});
		} else {
			list = obj.columns.map((item) => {
				return { ...item, checked: true, label: item.label }
			});
		}
		obj.columns = [...list];

		return {
			...state,
			tableData: r, filterTableData: obj, isLoading: false,
			count: data ? data.length : 0, filterData: r.columns ? r.columns.map((col) => r.datasets.map(item => item[col.label])) : []
		}
	}
}

export function setDatafromApiV2(data, keysArr, state, rows ,isAll) {
	if (data) {
		let  tableHeader = Array.isArray(rows)?[...rows]:[]
		 tableHeader =tableHeader.map(itm=>{
			 return {
				 name:itm.id,
				 checked:itm.checked?true:false,
				 ...itm     
			 }
		 })
		if(isAll){
		let dataHeader = getTableHeader(data, keysArr);
		dataHeader.forEach(itm=>{
		   let isAvail=   tableHeader.find(row=>{
				   if(row.id===itm.name){
					
                    return true;
				   }else{
					   return false;
				   }
			  })
			if(!isAvail){
				itm.checked=false
				tableHeader.push(itm)
             
			}
	   })
	}

	 
		let r = {
			columns: tableHeader,
			datasets: data
		}
		let obj = JSON.parse(JSON.stringify(r));
		let filterData= r.columns ? r.columns.map((col) =>( Array.from(new Set(r.datasets.map(item => item[col.name])))) ) : []
	
		return {
			...state,
			tableData: r, filterTableData: obj, isLoading: false,
			count: data ? data.length : 0, filterData:filterData 
		}
	}
}