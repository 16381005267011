import React, { useState } from 'react';

import Box from '@material-ui/core/Box'

import SimpleDataTable from '../SimpleDataTable';

let dummyData = [
  {
    "url": "https://example.com/invoice/001",
    "vendorName": "Acme Corporation",
    "billToEntity": "Acme HQ",
    "paymentTermContracts": "Net 30",
    "paymentTermInvoices": "Net 30",
    "actualPaymentTerms": "Matched",
    "systemTerms": "Net 30",
    "optimalTerms": "Net 25",
    "invoiceTotal": "$1200.50",
    "earlyPaymentClause": "Yes",
    "earlyPaymentAmount": "$24.01",
    "latePaymentClause": "Yes",
    "latePaymentAmount": "$18.00"
  },
  {
    "url": "https://example.com/invoice/002",
    "vendorName": "Globex Corporation",
    "billToEntity": "Globex HQ",
    "paymentTermContracts": "Net 45",
    "paymentTermInvoices": "Net 45",
    "actualPaymentTerms": "Matched",
    "systemTerms": "Net 45",
    "optimalTerms": "Net 40",
    "invoiceTotal": "$2500.75",
    "earlyPaymentClause": "No",
    "earlyPaymentAmount": "$0.00",
    "latePaymentClause": "Yes",
    "latePaymentAmount": "$25.01"
  },
  {
    "url": "https://example.com/invoice/003",
    "vendorName": "Initech",
    "billToEntity": "Initech HQ",
    "paymentTermContracts": "Net 60",
    "paymentTermInvoices": "Net 60",
    "actualPaymentTerms": "Matched",
    "systemTerms": "Net 60",
    "optimalTerms": "Net 55",
    "invoiceTotal": "$3450.00",
    "earlyPaymentClause": "Yes",
    "earlyPaymentAmount": "$34.50",
    "latePaymentClause": "Yes",
    "latePaymentAmount": "$69.00"
  },
  {
    "url": "https://example.com/invoice/004",
    "vendorName": "Umbrella Corp",
    "billToEntity": "Umbrella HQ",
    "paymentTermContracts": "Net 30",
    "paymentTermInvoices": "Net 25",
    "actualPaymentTerms": "Unmatched",
    "systemTerms": "Net 30",
    "optimalTerms": "Net 25",
    "invoiceTotal": "$870.30",
    "earlyPaymentClause": "No",
    "earlyPaymentAmount": "$0.00",
    "latePaymentClause": "Yes",
    "latePaymentAmount": "$13.05"
  },
  {
    "url": "https://example.com/invoice/005",
    "vendorName": "Stark Industries",
    "billToEntity": "Stark HQ",
    "paymentTermContracts": "Net 15",
    "paymentTermInvoices": "Net 20",
    "actualPaymentTerms": "Unmatched",
    "systemTerms": "Net 15",
    "optimalTerms": "Net 10",
    "invoiceTotal": "$5000.00",
    "earlyPaymentClause": "Yes",
    "earlyPaymentAmount": "$100.00",
    "latePaymentClause": "Yes",
    "latePaymentAmount": "$100.00"
  },
  {
    "url": "https://example.com/invoice/006",
    "vendorName": "Wayne Enterprises",
    "billToEntity": "Wayne HQ",
    "paymentTermContracts": "Net 30",
    "paymentTermInvoices": "Net 30",
    "actualPaymentTerms": "Matched",
    "systemTerms": "Net 30",
    "optimalTerms": "Net 28",
    "invoiceTotal": "$2700.00",
    "earlyPaymentClause": "Yes",
    "earlyPaymentAmount": "$54.00",
    "latePaymentClause": "No",
    "latePaymentAmount": "$0.00"
  },
  {
    "url": "https://example.com/invoice/007",
    "vendorName": "Oscorp",
    "billToEntity": "Oscorp HQ",
    "paymentTermContracts": "Net 60",
    "paymentTermInvoices": "Net 60",
    "actualPaymentTerms": "Matched",
    "systemTerms": "Net 60",
    "optimalTerms": "Net 55",
    "invoiceTotal": "$3200.00",
    "earlyPaymentClause": "No",
    "earlyPaymentAmount": "$0.00",
    "latePaymentClause": "Yes",
    "latePaymentAmount": "$64.00"
  },
  {
    "url": "https://example.com/invoice/008",
    "vendorName": "Stark Enterprises",
    "billToEntity": "Stark HQ",
    "paymentTermContracts": "Net 45",
    "paymentTermInvoices": "Net 40",
    "actualPaymentTerms": "Unmatched",
    "systemTerms": "Net 45",
    "optimalTerms": "Net 40",
    "invoiceTotal": "$4100.00",
    "earlyPaymentClause": "Yes",
    "earlyPaymentAmount": "$82.00",
    "latePaymentClause": "No",
    "latePaymentAmount": "$0.00"
  },
  {
    "url": "https://example.com/invoice/009",
    "vendorName": "LexCorp",
    "billToEntity": "LexCorp HQ",
    "paymentTermContracts": "Net 30",
    "paymentTermInvoices": "Net 30",
    "actualPaymentTerms": "Matched",
    "systemTerms": "Net 30",
    "optimalTerms": "Net 25",
    "invoiceTotal": "$600.00",
    "earlyPaymentClause": "No",
    "earlyPaymentAmount": "$0.00",
    "latePaymentClause": "Yes",
    "latePaymentAmount": "$9.00"
  },
  {
    "url": "https://example.com/invoice/010",
    "vendorName": "Daily Planet",
    "billToEntity": "Daily Planet HQ",
    "paymentTermContracts": "Net 15",
    "paymentTermInvoices": "Net 20",
    "actualPaymentTerms": "Unmatched",
    "systemTerms": "Net 15",
    "optimalTerms": "Net 10",
    "invoiceTotal": "$2500.00",
    "earlyPaymentClause": "Yes",
    "earlyPaymentAmount": "$50.00",
    "latePaymentClause": "No",
    "latePaymentAmount": "$0.00"
  },
  {
    "url": "https://example.com/invoice/011",
    "vendorName": "Pied Piper",
    "billToEntity": "Pied Piper HQ",
    "paymentTermContracts": "Net 30",
    "paymentTermInvoices": "Net 35",
    "actualPaymentTerms": "Unmatched",
    "systemTerms": "Net 30",
    "optimalTerms": "Net 28",
    "invoiceTotal": "$1800.00",
    "earlyPaymentClause": "Yes",
    "earlyPaymentAmount": "$36.00",
    "latePaymentClause": "Yes",
    "latePaymentAmount": "$27.00"
  },
  {
    "url": "https://example.com/invoice/012",
    "vendorName": "Monsters Inc.",
    "billToEntity": "Monsters Inc HQ",
    "paymentTermContracts": "Net 45",
    "paymentTermInvoices": "Net 45",
    "actualPaymentTerms": "Matched",
    "systemTerms": "Net 45",
    "optimalTerms": "Net 40",
    "invoiceTotal": "$7200.00",
    "earlyPaymentClause": "Yes",
    "earlyPaymentAmount": "$144.00",
    "latePaymentClause": "No",
    "latePaymentAmount": "$0.00"
  },
  {
    "url": "https://example.com/invoice/013",
    "vendorName": "Weyland-Yutani",
    "billToEntity": "Weyland-Yutani HQ",
    "paymentTermContracts": "Net 60",
    "paymentTermInvoices": "Net 60",
    "actualPaymentTerms": "Matched",
    "systemTerms": "Net 60",
    "optimalTerms": "Net 55",
    "invoiceTotal": "$8400.00",
    "earlyPaymentClause": "No",
    "earlyPaymentAmount": "$0.00",
    "latePaymentClause": "Yes",
    "latePaymentAmount": "$126.00"
  },
  {
    "url": "https://example.com/invoice/014",
    "vendorName": "Cyberdyne Systems",
    "billToEntity": "Cyberdyne HQ",
    "paymentTermContracts": "Net 30",
    "paymentTermInvoices": "Net 30",
    "actualPaymentTerms": "Matched",
    "systemTerms": "Net 30",
    "optimalTerms": "Net 25",
    "invoiceTotal": "$5500.00",
    "earlyPaymentClause": "Yes",
    "earlyPaymentAmount": "$110.00",
    "latePaymentClause": "No",
    "latePaymentAmount": "$0.00"
  },
  {
    "url": "https://example.com/invoice/015",
    "vendorName": "Blue Sun Corporation",
    "billToEntity": "Blue Sun HQ",
    "paymentTermContracts": "Net 15",
    "paymentTermInvoices": "Net 15",
    "actualPaymentTerms": "Matched",
    "systemTerms": "Net 15",
    "optimalTerms": "Net 10",
    "invoiceTotal": "$900.00",
    "earlyPaymentClause": "No",
    "earlyPaymentAmount": "$0.00",
    "latePaymentClause": "Yes",
    "latePaymentAmount": "$13.50"
  }
];


const MultiDMTableBlock = (props) => {
  const { enabledBlocks, block, loader } = props

  // const [ tableHeaders, setTableHeaders ] = useState(block?.config?.dataElements);

  const currentDocumentId = props?.match?.params?.id

  console.log("Multi Table Block =====????? ", block, props, dummyData);
  return (
    <Box style={{
      height: "85%",
      // width: "99%"
    }}>
      <React.Fragment>
        <SimpleDataTable
          {...props}
          currentDocumentId={currentDocumentId}
          blocks={enabledBlocks}
          tableHeaders={block?.config?.dataElements}
          data={dummyData}
          loader={loader}
          // isLoading={isLoading}
        />
      </React.Fragment>
    </Box>
  )
}

export default MultiDMTableBlock