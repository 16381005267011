import { ActionTypes } from "../constants";

const initialState = {
    event: null,
    message: null,
    apiData: null,
    successMsg: null,
};
export default function actionReducer(state = initialState, action) {

    switch (action.type) {
        case ActionTypes.INITIATE_ACTION: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true
            }
        }
        case ActionTypes.INITIATE_ACTION_SUCCESS: {
            let actionResponse = {...state.message, [action.payload.dataUniqueId]: action.payload.data}
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                message: actionResponse,
            }
        }
        case ActionTypes.INITIATE_ACTION_FAILURE: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
            }
        }

        case ActionTypes.GET_EVENT_BY_ID: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true
            }
        }
        case ActionTypes.GET_EVENT_BY_ID_SUCCESS: {
            let eventResponse = {...state.event, [action.payload.dataUniqueId]: action.payload.data}
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                event: eventResponse,
            }
        }
        case ActionTypes.GET_EVENT_BY_ID_FAILURE: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
            }
        }

        case ActionTypes.GET_DATA_BY_URL: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true
            }
        }
        case ActionTypes.GET_DATA_BY_URL_SUCCESS: {
            let apiData = {...state.apiData, [action.payload.dataUniqueId]: action.payload.data}
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                apiData: apiData,
            }
        }
        case ActionTypes.GET_DATA_BY_URL_FAILURE: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
            }
        }

        case ActionTypes.SAVE_DATA_BY_URL: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true
            }
        }
        case ActionTypes.SAVE_DATA_BY_URL_SUCCESS: {
            let apiData = {...state.apiData, [action.payload.dataUniqueId]: action.payload.data}
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                apiData: apiData,
            }
        }
        case ActionTypes.SAVE_DATA_BY_URL_FAILURE: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
            }
        }

        case ActionTypes.UPDATE_DATA_BY_URL: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true
            }
        }
        case ActionTypes.UPDATE_DATA_BY_URL_SUCCESS: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                successMsg: action.payload.data,
            }
        }
        case ActionTypes.UPDATE_DATA_BY_URL_FAILURE: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
            }
        }

        case ActionTypes.CLEAR_SUCCESS_MSG: {
            return {
              ...state,
              successMsg: null,
            }
          }
        
        case ActionTypes.DELETE_DATAPOINT: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true,
            };
        }
        case ActionTypes.DELETE_DATAPOINT_FAILURE: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
            };
        }
        case ActionTypes.DELETE_DATAPOINT_SUCCESS: {
            return {
                ...state,
                message: action.payload?.message,
                [action.payload.dataUniqueId + '_loading']: false,
            };
        }  

        default: {
            return state;
        }
    }
}