 import { ActionTypes } from 'constants/index';
import { DashboardService } from 'modules/api/index';
import { put, takeEvery } from 'redux-saga/effects';
 
 async function fetchAsync(func, arg) {
     const response = arg ? await func(arg) : await func();
     return response;
 }

 function* getData(action) {
    try {
      const response =  yield fetchAsync(DashboardService.getData, action.payload);
      yield put({ type: ActionTypes.GET_API_DATA_SUCCESS, payload: { data: response.data, dataUniqueId: action?.payload?.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_API_DATA_FAILURE, payload: { error:e.message, dataUniqueId: action?.payload?.dataUniqueId} });
      console.log(e);
  
    }
  }
 export function* watchAppSaga() {
     yield takeEvery(ActionTypes.GET_API_DATA, getData);

 }
 
 export default watchAppSaga;
 