export const fetchSingleOpportunityPayload = (appId, orgId, selectedStep, opportunityId, chartUniqueId) => {
    return {
        appId: appId,
        orgId: orgId,
        dataModelId: selectedStep.dataModel,
        opportunityId: opportunityId, 
        dataUniqueId: chartUniqueId + '_getsingleopportunity'
    }
}

export const fetchUpdateOpportunityPayload = (appId, selectedStep, opportunityId, chartUniqueId, result) => {
    return {
        appId: appId,
        dataModelId: selectedStep.dataModel,
        dataUniqueId: chartUniqueId + '_updateopportunity',
        opportunityId: opportunityId,
        payload: {
            ...result
        }
    }
}

export const getDatafromDataModelIdPayload = (appId, dataModelKey, page, rowsPerPage, filterConditions) => {
    return {
        // fields: props.data.chartData.selectedDataElementsForTiles.map(i => i.value).join(','),
        appId: appId,
        id: dataModelKey,
        page: page,
        rowsPerPage: rowsPerPage,
        conditions: filterConditions,
    };
}