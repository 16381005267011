import React from 'react';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import { ContractFieldsSummaryView } from 'dsilo-ui-components';

import {
    getModelData,
    getDataModelById,
    getContractDataById,
    clearReduxDataOfCurrentComponent,
} from '../../store/actions';

import { Loader } from '../loader';
import { PUBLIC_PATH } from '../../constants';

const ContractFieldsSummaryViewComponent = props => {
    return (
        <div style={{ backgroundColor: "#eee", height:"100%", width:"100%" }}>
            <ContractFieldsSummaryView {...props} env={process.env} loader={<Loader />} />
        </div>
    );
};

const mapDispatchToProps = {
    getModelData,
    getDataModelById,
    getContractDataById,
    clearReduxDataOfCurrentComponent,
}

const mapStateToProps = state => {
    return {
        action: state.action,
        formData: state.form,
        PUBLIC_PATH: PUBLIC_PATH,
        dataModels: state.dataModels,
        appConfig: state.appConfig.app,
        members: state.appConfig.members,
        currentMember: state.appConfig.members,
    };
};

export default hot(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractFieldsSummaryViewComponent)),
);