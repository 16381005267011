import React from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TreeMap from './TreeMap'
import { Paper } from '@material-ui/core';

const VendorTreeMap = (props) => {

  console.log('VendorTreeMap-props', props)

  const useStyles = makeStyles((theme) => ({
    paper: {
      height: '100%',
      width: '100%'
    },
    grid1: {
      maxWidth: "33% !important"
    }

  }));

  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={2} className={classes.paper} >
          <TreeMap options={props.processedInvoices} />
        </Paper>
      </Grid>
      {/* <Grid item xs={4} className={classes.grid1}>
            <div className={classes.paperPieChart} >
              <TreeMap options={props.unProcessedInvoices} />
            </div>
        </Grid>
        <Grid item xs={4} >
            <div className={classes.paperPieChart} >
              <TreeMap options={props.accuracy} />
            </div>
        </Grid> */}
    </Grid>
  )
}

export default VendorTreeMap;