export const layoutStylesGeneration = (layout) => {
	let layoutStyles = {
		gridLayoutStyles: {},
		chartStyles: {},
		chartLevelPaper: false,
		chartTitleBackgroundColor: {
			backgroundColor: "#fff",
			fontColor: "#000"
		}
	}
	switch (layout) {
		case "layout2":
			layoutStyles = {
				gridLayoutStyles: {
					backgroundColor: "#f7f7f7"
				},
				chartStyles: {
					backgroundColor: "#fff"
				},
				chartLevelPaper: false,
				chartTitleBackgroundColor: {
					backgroundColor: "#fff",
					fontColor: "#000"
				}
			}
			break;
		case "layout3":
			layoutStyles = {
				gridLayoutStyles: {
					backgroundColor: "#f7f7f7"
				},
				chartStyles: {
					backgroundColor: "#fff"
				},
				chartLevelPaper: true,
				chartTitleBackgroundColor: {
					backgroundColor: "#fff",
					fontColor: "#000"
				}
			}
			break;
		case "layout1":
		default:
			break;
	}
	return layoutStyles
}