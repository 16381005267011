import './style.css';

import React, { useState, useEffect } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { getPowerBiReport, clearPowerBiState } from '../../store/actions/powerBi';

import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Loader } from "../loader";

const PowerBi = props => {

	const initialState = {
		title: undefined,
		type: 'report',
		embedUrl: undefined,
		tokenType: models.TokenType.Embed,
		accessToken: undefined,
		settings: undefined,
		iframe: undefined,
		init: true
	}

	const [isLoading, setIsLoading] = useState(true);
	const [reportConfig, setReportConfig] = useState(initialState);

	const eventHandlers = new Map([
		['loaded', function () {
			console.log('Report has loaded');
		}],
		['rendered', function () {
			console.log('Report has rendered');
		}],
		['error', function (event) {
			if (event) {
				console.error(event.detail);
			}
		}]
	]);

	useEffect(() => {
		return () => {
			props.clearPowerBiState()
		}
	}, [])

	useEffect(() => {
		const { page } = props;
		const { appid } = props.match.params;
		const { orgId } = props.user.orgId;

		if (page?.powerBi?.reportId && page?.powerBi?.workspaceId) {
			const { workspaceId, reportId } = page.powerBi

			setIsLoading(true)
			props.getPowerBiReport({ workspaceId, reportId, appId: appid, orgId })
		}

	}, [props?.page?.powerBi])

	useEffect(() => {
		const updateState = (currentSate) => {
			setReportConfig(reportConfig => { return currentSate });
		}
		if(!props?.powerBi?.loading) setIsLoading(false)
		if (!props?.powerBi?.data) {
			const embedUrl = props?.page?.powerBi?.embedUrl;
			const title = props?.page?.powerBi?.title;

			updateState({
				...initialState,
				title,
				embedUrl,
				init: false
			});
		} else {
			const data = props?.powerBi?.data;
			let dataUpdate = {
				...initialState,
				title: data?.embedUrl[0].reportName,
				id: data?.embedUrl[0].reportId,
				embedUrl: data?.embedUrl[0].embedUrl,
				accessToken: data?.accessToken,
				permissions: models.Permissions.All,
				settings: {
					filterPaneEnabled: true,
					navContentPaneEnabled: true
				},
				init: false
			}
			updateState(dataUpdate)
		}
	}, [props?.powerBi])

	return isLoading && reportConfig.init
		? <Loader />
		: <>
			{reportConfig.id
				? <PowerBIEmbed
					embedConfig={reportConfig}
					eventHandlers={eventHandlers}
					cssClassName={"report"}
					getEmbeddedComponent={(embeddedReport) => {
						window.report = embeddedReport;
					}}
				/>
				: <>
					{reportConfig.embedUrl
						? <iframe title={reportConfig.title}
							style={{ overflow: "hidden", "width": "100%", height: "100%" }}
							width="100%" height="100%"
							src={reportConfig.embedUrl}
							frameBorder="0" allowFullScreen={true}>
						</iframe>
						: <div style={{ textAlign: 'center', margin: 100 }}>
							Dashboard loading....
						</div>
					}
				</>
			}
		</>
}

const mapDispatchToProps = {
	getPowerBiReport,
	clearPowerBiState
};

const mapStateToProps = state => {
	return {
		powerBi: state?.powerBi || {},
		user: state.user,
	};
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(PowerBi)));