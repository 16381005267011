const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
import { request } from 'modules/client'

const uri = `${API_BASE_URL}/privileges`

export class PrivilegesService {
	static getPrivileges(params) {
		return request(uri, {
			method: 'GET',
			headers: { 'appId': params.dappId }
		})
	}
}