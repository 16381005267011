import { FormControlLabel, FormGroup, Checkbox } from "@material-ui/core"
import React from "react"
import { makeStyles } from '@material-ui/core/styles';

const CustomArrayOfCheckboxField = (props) => {
    const { classes, element, dataObj, onChange, styles, eachField, handleCheckboxChange, selectedStep, block } = props
    const backgroundColor = block.accordion?.backgroundColor || "#4D9FEC";

    // TODO: need to read the Options from DataModel if configured
    const options = element?.data?.type == "custom" ? element?.data?.options : []
    return (
        <FormGroup row={true}>
            {options.map(option => (
                <FormControlLabel
                    style={{ fontSize: "12px" }}
                    control={
                        <Checkbox
                            color="primary" 
                            style={{
                                color: backgroundColor,
                                '&.Mui-checked': {
                                    color: backgroundColor,
                                },
                                '&.Mui-checked:hover': {
                                    color: backgroundColor,
                                },
                            }}
                            checked={dataObj[(element.value || element.name)]?.includes(option.value)}
                            onChange={() => handleCheckboxChange((element.value || element.name), option.value, element.type)}
                        />
                    }
                    label={option.label}
                />
            ))}
        </FormGroup>
    )
}

export default CustomArrayOfCheckboxField