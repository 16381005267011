import { ActionTypes } from 'constants/index';

export const checkEmailForAad = (data) => dispatch => {
  dispatch({ type: ActionTypes.CHECK_EMAIL_AAD, payload: data });
};

export const submitLogin = (data) => dispatch => {
  dispatch({ type: ActionTypes.LOGIN_LOADING, payload: data });
};

export const submitLoginwithToken = (data) => dispatch => {
  dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: data });
};

export const resetPassword = payload => dispatch => {
  dispatch({ type: ActionTypes.RESET_PASSWORD_LOADING, payload });
};
export const verifyEmail = payload => dispatch => {
  dispatch({ type: ActionTypes.VERIFY_EMAIL_LOADING, payload });
};

export const forgotPassword = payload => dispatch => {
  dispatch({ type: ActionTypes.FORGOT_PASSWORD_LOADING, payload });
};

export const verifyUser = payload => dispatch => {
  dispatch({ type: ActionTypes.VERIFY_USER_LOADING, payload });
};

export const resendVerifyEmail = payload => dispatch => {
  dispatch({ type: ActionTypes.RESEND_VERIFY_EMAIL_LOADING, payload });
};

export const updateProfile = payload => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_PROFILE_LOADING, payload });
};

export const clearResetPasswordError = () => dispatch => {
  dispatch({ type: ActionTypes.CLEAR_RESET_PASSWORD_ERROR });
};

export const saveAadUserDetails = (payload) => dispatch => {
  dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload });
  dispatch({ type: ActionTypes.SET_USER_DATA, payload });
};
