import React from 'react';
import "./styles.scss"
import Loader from '../../images/loader.svg'

export default function PreAppLoading(props) {
  return (
    <div className='loader-main'>
      <div className='pre-app-loader'>
        <img alt='loader' src={Loader} />
        <div className="blob blob-0"></div>
        <div className="blob blob-1"></div>
        <div className="blob blob-2"></div>
        <div className="blob blob-3"></div>
        <div className="blob blob-4"></div>
        <div className="blob blob-5"></div>
      </div>
    </div>
  );
}
