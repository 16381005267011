import Box from "@material-ui/core/Box";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MailIcon from '@material-ui/icons/Mail';
import ChatBubbleOutlinedIcon from '@material-ui/icons/ChatBubbleOutlined';
import LinkIcon from '@material-ui/icons/Link';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import TurnedInIcon from '@material-ui/icons/TurnedIn';
import React, { memo, useState } from "react";
import HalfRating from "./Rating";

const QuestionReactions = (props) => {
    const {
      appId,
      chatReactions,
      externalUrl,
      handleCommentModal,
      handleEmailEditorModal,
      msg,
      questionLike,
      questionDisLike,
      saveSavedForLater,
      saveRating,
    } = props;
    const [like, setLike] = useState(msg?.points?.like || false);
	const [disLike, setDisLike] = useState(msg?.points?.disLike || false);
	const [rating, setRating] = useState(msg?.points?.rating || 0);
	const [saveLater, setSaveLater] = useState(msg?.points?.savedForLater || false);

    return (
        <Box style={{ display: "flex", marginTop: "0.5rem", justifyContent: 'flex-end' }} spacing={2}>
            {chatReactions?.externalUrl && externalUrl && (
                <Tooltip title="Demo Link">
                    <IconButton 
                        style={{ color: '#000', padding: 0, marginTop: 1 }}
                        href={externalUrl}
                        target="_blank"
                    >
                        <LinkIcon />
                    </IconButton>
                </Tooltip>
            )}
            {chatReactions?.sendEmail ? (
                <Tooltip title="Send Email">
                    <IconButton
                        onClick={() => handleEmailEditorModal()}
                        style={{ color: '#000', padding: 0, marginTop: 1 }} 
                    >
                        <MailIcon />
                    </IconButton>
                </Tooltip>
            ) : null}
            {chatReactions?.like ? (
                <Tooltip title="Like">
                    {like ? (
                        <ThumbUpAltIcon />
                    ) : (
                        <IconButton 
                            style={{ color: '#000', padding: 0, marginTop: 2 }}
                            onClick={() => {
                                setLike(true);
                                setDisLike(false);
                                questionLike({ appId, questionId: msg?.questionId })
                            }}
                        >
                            <ThumbUpAltOutlinedIcon />
                        </IconButton>
                    )}
                </Tooltip>
            ) : null}
            
            {chatReactions?.disLike ? (
                <Tooltip title="DisLike">
                    {disLike ? (
                        <ThumbDownAltIcon />
                    ) : (
                        <IconButton 
                            style={{ color: '#000', padding: 0, marginTop: 2 }}
                            onClick={() => {
                                setDisLike(true);
                                setLike(false);
                                questionDisLike({ appId, questionId: msg?.questionId })
                            }} 
                        >
                            <ThumbDownAltOutlinedIcon />
                        </IconButton>
                    )}
                </Tooltip>
            ) : null}

            {chatReactions?.comments ? (
                <Tooltip title="Comment(s)">
                    <ChatBubbleOutlinedIcon onClick={() => handleCommentModal(msg)} style={{ cursor: "pointer" }} />
                </Tooltip>
            ) : null}
            
            {chatReactions?.saveForLater ? (
                <Tooltip title="Save for later">
                    {saveLater ? (
                        <TurnedInIcon
                            onClick={() => {
                                setSaveLater(false)
                                saveSavedForLater({ appId, savedForLater: false, questionId: msg?.questionId })
                            }}
                            style={{ cursor: "pointer" }} 
                        />
                    ) : (
                        <TurnedInNotIcon
                            onClick={() => {
                                setSaveLater(true)
                                saveSavedForLater({ appId, savedForLater: true, questionId: msg?.questionId })
                            }}
                            style={{ cursor: "pointer" }} 
                        />
                    )}
                </Tooltip>
            ) : null}
            {chatReactions?.rating ? (
                <HalfRating
                    key={msg?.questionId}
                    appId={appId}
                    value={rating} 
                    questionId={msg?.questionId}
                    readOnly={msg?.points?.readOnly || false}
                    setRating={setRating} 
                    saveRating={saveRating}
                />
            ) : null}
        </Box>
    );
}

export default memo(QuestionReactions);