import React, { useMemo }  from "react";
import { useDrag } from "react-dnd";
import { supportedHighcharts } from "../../ChatHelper";

const ChatMessage = (props) => {
	const { chartType, message, responseMessage, questionId } = props;
	const dragItem = useMemo(() => {
		return {
			id: questionId,
			chartType
		}
	}, [chartType, questionId]);

	const [{ isDragging }, drag] = useDrag(() => ({
		type: "component", // message.type,
		item: dragItem,
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
	}), [chartType, questionId]);
  
	return (
		<React.Fragment key={questionId}>
			{message && typeof message?.queryText ? (
				<div>{message?.queryText}</div>
			) : (
				<div
					ref={drag}
					key={questionId}
					style={{
						cursor: 'pointer',
						width: '100%',
						...(supportedHighcharts.includes(chartType) && { zIndex: 1 }),
					}}
				>
					{responseMessage && responseMessage?.()}
				</div>
			)}
		</React.Fragment>
	);
};

export default ChatMessage