import { Divider, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AdvancedTable, TableSupport } from 'dsilo-ui-components';
import { useEffect, useState } from "react";
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ConfirmationHandler from '../../components/ConfirmDialog';
import { Loader } from "../../components/loader";
import { FormService } from '../../modules/api/form';
import React from 'react';
import { toast } from "react-toastify";
import _ from 'lodash';

const sharedJSON = {
    "order": "asc",
    "orderBy": null,
    "selected": [],
    "tableData": [],
    "filterTableData": [],
    "page": 0,
    "count": 10,
    "rowsPerPage": 10,
    "searchText": "",
    "filterList": [],
    "filterData": []
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    headerClass: {
        backgroundColor: "#f6f9fc",
        textTransform: 'capitalize',
    },
    confirmationDialoge: {
        "& .MuiDialog-paperWidthSm": {
            height: "250px",
            width: "600px"
        }
    },
    para: {
        fontSize: "28px !important",
        fontWeight: 600,
        color: "#000"
    },
    table: {
        '& td': {
            padding: '4px !important',
            textAlign: 'center',
        },
        margin: '15px 5px 0 5px',
    },
}))

const MailList = (props) => {
    const { page } = props
    const { chart } = page
    const { chartData } = chart[0]
    const classes = useStyles()

    const [state, setState] = useState({
        ...sharedJSON,
    });
    const [searchedText, setSearchedText] = useState('');

    const [deleteConfirmState, setDeleteConfirm] = useState({
        isOpen: false,
        deleteMessage: "Are you sure you want to delete ?",
        data: null
    })
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setState({ ...TableSupport.updateTableData(state) });
    }, [state.searchText, state.filterList]);

    const getColumns = () => {
        let COLUMNS = [
            {
                id: 'email',
                numeric: false,
                disablePadding: false,
                label: 'Email',
                sort: true,
                checked: true
            },
            {
                id: 'type',
                numeric: false,
                disablePadding: false,
                label: 'Type',
                sort: true,
                checked: true
            }, {
                id: 'dataModel',
                numeric: false,
                disablePadding: false,
                label: 'Data Model',
                sort: true,
                checked: true
            },

        ]

        return COLUMNS
    }

    const handleChangePage = (event, page) => {
        setState({ ...state, page: page });
    };

    const onAddClick = () => {
        props.history.push({ pathname: `/${props.match.params.appid}/page/mail-box/new` });
    }

    const handleChangeRowsPerPage = event => {
        setState({ ...state, rowsPerPage: event.target.value, page: 0 });
    };

    const onRowClick = (rowData, rowMeta) => {
        console.log('onRowClick');
    };

    const searchHandler = (e) => {
        setSearchedText(e ? e.target.value : "")
    }

    const onSearchEnter = (e, onSearchButton = false) => {
        if (e.keyCode === 13 || onSearchButton) {
            // write api call here
        }
    }

    const handleInputChange = event => {
        let obj = state.filterTableData;
        let list = obj.columns;
        list.map((item, index) => {
            if (item.name === event.target.value) list[index].checked = event.target.checked;
        });
        obj.columns = [...list];
        setState({ ...state, filterTableData: obj });
        setState({ ...TableSupport.updateTableData(state) });
    };

    const resetFilters = event => {
        setState({ ...state, filterList: [] });
    };

    const handleDropdownChange = (event, index, column) => {
        const value = event.target.value === 'All' ? [] : [event.target.value];
        // filterUpdate(index, value, column, 'dropdown');
        let list = state.filterList;
        list[index] = event.target.value;
        setState({ ...state, filterList: [...list] });
        setState({ ...TableSupport.updateTableData(state) });
    };

    const handleRequestSort = (event, property) => {
        let obj = TableSupport.requestSort(event, property, state);
        setState({ ...state, ...obj });
    };

    const handleSelectAllClick = event => {
        setState({
            ...state,
            selected: TableSupport.handleTableSelectAllClick(event, state.filterTableData.datasets),
        });
    };

    const tableCrudOperations = (item, type) => {

        console.log('item', item)
        console.log('type', type)
        if (type === 'edit') {
            props.history.push({
                pathname: `/${props.match.params.appid}/page/${chartData.editPage}/${item._id}`,
            })
        }
        if (type === 'delete') {
            setDeleteConfirm({
                ...deleteConfirmState,
                isOpen: true,
                data: item._id
            })
        }

    };

    const onDeleteResponse = (isDelete) => {
        if (isDelete) {
            const payload = {
                id: deleteConfirmState.data,
                memberId: props.member?._id,
                "appId": props.appId,
                "orgId": props.user.orgId,
            }
            FormService.deleteMailConfig(payload).then((res) => {
                console.log('resresresres', res)
                fetchData()
                toast.success(res.data.message || 'Delete successful')
            }).catch(e => {
                console.log('e', e)
                toast.error(e.message || 'Unable to delete')
            })
        }
        setDeleteConfirm({ ...deleteConfirmState, isOpen: false, data: null })
    }

    const handleCheck = (event, id) => {
        const { selected } = state;
        let newSelected = TableSupport.handleSelectedRow(event, id, selected);
        setState({ ...state, selected: newSelected });
        props.checkedContractChanged(newSelected);
    };

    useEffect(() => {
        fetchData()
    }, [state.page, state.rowsPerPage])

    const fetchData = () => {
        const payload = {
            data: {
                "page": state.page,
                "rowsPerPage": state.rowsPerPage
            },
            "appId": props.appId,
            "orgId": props.user.orgId,
            "dataUniqueId": props.data?.chartUniqueId + '_data'
        }
        setLoading(true)
        FormService.getMailBoxData(payload).then((res) => {
            if (Array.isArray(res.data.data)) {
                let keysArr = ['_id'];
                let data = res.data.data.map(i => ({ ...i, dataModel: i.dataModel.name, type: _.capitalize(i.type) }))
                setState({
                    ...TableSupport.setDatafromApi2(
                        data,
                        keysArr,
                        state,
                        getColumns()),
                    count: res.data.total
                });
                setLoading(false)
            }
        }).catch(e => {
            setLoading(false)
            console.log('Error', e)
        })
    };

    return (
        <>
            {loading ? <Loader /> :
                <Paper className={classes.table} elevation={2}>
                    <AdvancedTable
                        name={'ContractList'}
                        onRowClick={onRowClick}
                        {...props}
                        {...state}
                        chartData={chartData}
                        searchedText={searchedText}
                        searchHandler={searchHandler}
                        onSearchEnter={onSearchEnter}
                        isExport={false}
                        isViewCoulmns={false}
                        isSearch={false}
                        rowsPerPage={state.rowsPerPage}
                        paginationPage={state.page}
                        isFilter={false}
                        hasCheckbox={false}
                        actions={{ edit: true, view: false, delete: true, copy: false, download: false }}
                        isTablePagination={true}
                        pagination={{ type: 'server' }}
                        isTableHead={true}
                        isTableSubHeader={false}
                        associatedFormName={chartData.associatedFormName}
                        handleInputChange={handleInputChange}
                        onAddClick={onAddClick}
                        resetFilters={resetFilters}
                        handleDropdownChange={handleDropdownChange}
                        handleRequestSort={handleRequestSort}
                        handleSelectAllClick={handleSelectAllClick}
                        tableCrudOperations={tableCrudOperations}
                        handleCheck={handleCheck}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        tableBodyCss={{border:"1px solid lightGrey"}}
                        stripRows
                        headerstyle={{
                            fontSize: "14px",
                            textAlign: "center",
                            color: "rgba(0, 0, 0, 0.87)",
                            backgroundColor: "#"
                        }}
                        subHeaderstyle={{
                            fontSize: "14px",
                            color: "rgba(0, 0, 0, 0.87)",
                            backgroundColor: "#FFF",
                            textAlign: "center"
                        }}
                        headerClass={classes.headerClass}
                        rowStyle={{
                            fontSize: "14px",
                            color: "rgba(0, 0, 0, 0.87)",
                            backgroundColor: "none",
                            lineHeight: 1.5,
                            textAlign: "center",
                            padding: 0,
                        }}
                        loader={props.loader}
                        isNestedTable={props.isNestedTable}
                        onExapandIconClick={props.onExapandIconClick}
                        nestedTableData={props.nestedTableData}
                    />
                </Paper>
            }
            <ConfirmationHandler
                open={deleteConfirmState.isOpen}
                handleClose={onDeleteResponse}
                alertMessageText={deleteConfirmState.deleteMessage}
                classes={classes}
                confirm
            />
        </>
    )
}

const mapDispatchToProps = {
}
const mapStateToProps = state => {
    return {
        user: state.user,
        org: state.org,
        formData: state.form,
        member: state.appConfig.members,
    };
};
export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(MailList)));