export const tablejson = [
  {
    id: '_id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
    sort: true, 
  },

  {
    id: 'originalName',
    numeric: false,
    disablePadding: false,
    label: 'File Name ',
    sort: true,
    checked:true
  },
  {
    id: 'createdDate',
    numeric: false,
    disablePadding: false,
    label: 'Date',
    sort: true,
    checked:true
  },
  {
    id: 'createdByName',
    numeric: false,
    disablePadding: false,
    label: 'Uploaded by',
    sort: true,
    checked:true
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    sort: true,
    checked:true
  },
  {
    id: 'appId',
    numeric: false,
    disablePadding: false,
    label: 'AppId',
    sort: true,
    checked:false
  },
];
