import userManagementJSON from './SystemStaticPageJSONs/UserManagement.json'
import GroupJSON from './SystemStaticPageJSONs/Groups.json'
import SupportTicketJSON from './SystemStaticPageJSONs/SupportTickets.json'
import ArchivesJSON from './SystemStaticPageJSONs/Archives.json'
import DeletedDocumentJSON from "./SystemStaticPageJSONs/DeletedDocument.json"
import DelegationJSON from "./SystemStaticPageJSONs/Delegation.json"
import MailBoxJSON from "./SystemStaticPageJSONs/MailBox.json"
import OthersJSON from "./SystemStaticPageJSONs/Others.json"
import DuplicatesDocument from "./SystemStaticPageJSONs/DuplicatesDocument.json"

export const selfServicePage = [
	{
		"selfserviceai": true,
		"selectedRoles": [],
		"rank": 100,
		"hide": false,
		"isActive": true,
		"isCustom": false,
		"conditions": [],
		"isLabel": "true",
		"_id": "self_service",
		"title": "Self Service AI",
		"name": "Self Service AI",
		"url": "selfserviceai",
		"description": "Dashboard",
		"type": "dashboard",
		"hasLabel": "true",
		"default": false,
	},
	{
		"selfserviceai": true,
		"selectedRoles": [],
		"rank": 100,
		"hide": false,
		"isActive": true,
		"isCustom": false,
		"conditions": [],
		"createdDate": "2022-02-07T05:45:04.739Z",
		"updatedDate": "2022-02-07T05:45:04.739Z",
		"_id": "self_service1",
		"title": "Models",
		"name": "Models",
		"url": "selfserviceai/models",
		"description": "Dashboard",
		"type": "dashboard",
		"hasLabel": "true",
		"labelPage": "self_service",
		"default": false,
	},
	{
		"selfserviceai": true,
		"selectedRoles": [],
		"rank": 100,
		"hide": true,
		"isActive": true,
		"isCustom": false,
		"conditions": [],
		"createdDate": "2022-02-07T05:45:04.739Z",
		"updatedDate": "2022-02-07T05:45:04.739Z",
		"_id": "self_service2",
		"title": "Model details",
		"name": "Model details",
		"url": "selfserviceai/modeldetails",
		"description": "Dashboard",
		"type": "dashboard",
		"hasLabel": "true",
		"labelPage": "self_service",
		"default": false,
	}
]


export const SystemJSONs = [
	{
		"selectedRoles": [],
		"rank": 1000,
		"hide": false,
		"isActive": true,
		"isCustom": false,
		"conditions": [],
		"createdDate": "2022-05-25T10:35:34.803Z",
		"updatedDate": "2022-05-25T10:35:34.803Z",
		"_id": "system",
		"isLabel": "true",
		"title": "System",
		"name": "System",
		"url": "system",
		"description": "System",
		"type": "system",
		"chart": [],
		"default": false,
		"appId": "6238540af705cbda0a3b2874",
		"__v": 0
	},
	// ...MailBoxJSON, // Commenting as we no longer need the mailbox page in system dashboard
	...userManagementJSON,
	...GroupJSON,
	...SupportTicketJSON,
	...ArchivesJSON,
	...DeletedDocumentJSON,
	...DelegationJSON,
	...OthersJSON,
	...DuplicatesDocument
]