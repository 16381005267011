import React from "react";
import { makeStyles } from '@material-ui/core';

export const Loader = ()=>{

  const useStyles = makeStyles({
    flex: { display: 'flex', alignItems: 'center',justifyContent: 'center', width: '100%' },
  });

  const classes = useStyles();

  return (
    <div className={classes.flex}>
      <img src={require('../images/gif/loading.gif')} alt='loading_img'  />
    </div>
  )
}
