import React, { useState, useEffect } from 'react';
import { hot } from "react-hot-loader/root";
import { connect } from 'react-redux';
import { BarChart, Doughnut, LineChart, PieChart } from 'dsilo-ui-components';
import { updateSelectedQuestionChartData } from '../../../store/actions';

const ChartComponent = props => {
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        if (props?.chartUniqueId) {
            let devId = document.getElementById(props?.chartUniqueId);
            let w = devId?.style?.width;
            let h = devId?.style?.height;
            let chartTitleHeight = window.screen.height > 1000 ? 60 : 100;
            let width, height;
            if (w) {
                // width = parseInt(w) - (props.navBarOpen ? 290 : 120)
                width = parseInt(w);
                setWidth(width);
            }
            if (h) {
                height = parseInt(h) - chartTitleHeight;
                // if(props?.chartData?.title) height = parseInt(h) - chartTitleHeight
                setHeight(height);
            }
        }
    });

    const renderChart = () => {
        switch (props?.chartType) {
            case 'bar':
                return (
                    <BarChart
                        {...props}
                        addCustomStyles={props?.addCustomStyles}
                        data={{ ...props?.responseData, chartUniqueId: props?.chartUniqueId || '' }} 
                        id={props?.id} 
                        ref={props?.highChartsRef}
                        {...(props?.chartUniqueId) && {
                            height,
                            width
                        }}
                    />
                );
            case 'doughnut':
                return (
                    <Doughnut
                        {...props}
                        addCustomStyles={props?.addCustomStyles}
                        chartData={props?.chartData || {}}
                        id={props?.id}
                        ref={props.highChartsRef}
                        height={height}
                        width={width}
                    />
                );
            case 'line':
                return (
                    <LineChart
                        {...props}
                        addCustomStyles={props?.addCustomStyles}
                        data={props?.responseData} 
                        id={props?.id} 
                        ref={props?.highChartsRef}
                        {...(props?.chartUniqueId) && {
                            height,
                            width
                        }}
                    />
                );
            case 'pie':
                return (
                    <PieChart
                        {...props}
                        addCustomStyles={props?.addCustomStyles}
                        chartData={props?.chartData || {}}
                        id={props?.id}
                        ref={props.highChartsRef}
                        height={height}
                        width={width}
                    />
                );
            default:
                return null;
        }
    };

  return (
    <div className="chart" style={{ display: 'flex', justifyContent: 'center' }}>
      {/* <h2>{props?.title}</h2> */}
      {renderChart()}
    </div>
  );
};


const mapDispatchToProps = {
	updateChartPointData: updateSelectedQuestionChartData
};

const mapStateToProps = state => {
	return {
		chat: state.chat
	};
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(ChartComponent));
