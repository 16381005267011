import React, { useEffect, useState } from 'react';
import { FilterSelect } from "dsilo-ui-components";

import { Tooltip, Typography } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import SubDataModelTable from './SubDataModelTable/index.js';
import SubDataModelModal from './SubDataModelModal';

import useStyles from './styles';

const SubDataModelBlock = (props) => {
    const classes = useStyles()

    const { orgId, appId, members, currentOpportunityId, formData, selectedStep, block, loader } = props
    const { document } = props.formData
    const { chartUniqueId } = props.data

    const [currentDataModel, setCurrentDataModel] = useState({})
    const [tableHeaders, setTableHeaders] = useState([])
    const [formHeaders, setFormHeaders] = useState([])
    const [blockState, setBlockState] = useState([])
    const [selectedId, setSelectedId] = useState('')
    const [forwardableMemberList, setForwardableMemberList] = useState([])

    const [anchorEl, setAnchorEl] = useState(null);
    const [modalInfo, setModalInfo] = useState({
        enable: false
    })

    const labelStyle = block.labelStyle || {};

    useEffect(() => {
        if (block.enable) {
            fetchDataModel()
            getNeededDataForBlock(block)
            getMembersList();
        }
    }, [block])

    useEffect(() => {
        if (formData?.dataModel[block?.dataModel]) {
            const dataElements = formData.dataModel[block.dataModel].dataElements || []
            let tHeaders = []
            let fHeaders = []
            if (block.list?.dataElements?.length) {
                tHeaders = getHeaders(dataElements, block.list.dataElements)
            }
            if (block.form?.dataElements?.length) {
                fHeaders = getHeaders(dataElements, block.form.dataElements)
            }
            setTableHeaders(tHeaders)
            setFormHeaders(fHeaders)
            setCurrentDataModel(formData.dataModel[block.dataModel])
        }
    }, [formData?.dataModel[block?.dataModel]])

    useEffect(() => {
        if (document?.[`${chartUniqueId}_${selectedStep.name}_${block.name}`]) {
            const data = JSON.parse(JSON.stringify(document[`${chartUniqueId}_${selectedStep.name}_${block.name}`]))
            console.log("It is response in subdataModel Block ====>", data);
            setBlockState(data)
        }
    }, [document?.[`${chartUniqueId}_${selectedStep.name}_${block.name}`]])

    useEffect(() => {
        if (document[`${appId}_membersList`]?.length) {
            let list = document[`${appId}_membersList`];
            let fmList = []
            list.forEach((item) => {
                let isValid = false;
                if (item.roles.length) {
                    let isQA = item.roles.find(role => role.name === 'Quality Analyst' || role.name === 'Quality Lead')
                    if (isQA) {
                        isValid = false;
                    } else {
                        isValid = true;
                    }
                } else {
                    isValid = true;
                }
                if (isValid) {
                    fmList.push({
                        ...item,
                        value: item.name,
                        label: item.name
                    })
                }
            })
            setForwardableMemberList(fmList);
        }
    }, [document[`${appId}_membersList`]])

    useEffect(() => {
        if (document?.[`${chartUniqueId}_${selectedStep.name}_${block.name}_delete`]) {
            props.clearReduxDataOfCurrentComponent({
                key: 'document',
                subKey: [`${chartUniqueId}_${selectedStep.name}_${block.name}_delete`]
            })
            getNeededDataForBlock()
        }
    }, [document?.[`${chartUniqueId}_${selectedStep.name}_${block.name}_delete`]])

    useEffect(() => {
        if (document?.[`${chartUniqueId}_${selectedStep.name}_${block.name}_forward`]) {
            props.clearReduxDataOfCurrentComponent({
                key: 'document',
                subKey: [`${chartUniqueId}_${selectedStep.name}_${block.name}_forward`]
            })
            getNeededDataForBlock()
        }
    }, [document?.[`${chartUniqueId}_${selectedStep.name}_${block.name}_forward`]])

    const getHeaders = (dataElements, selectedElements) => {
        let fdes = []
        if (selectedElements?.length) {
            fdes = JSON.parse(JSON.stringify(selectedElements))
        }
        if (dataElements?.length) {
            fdes = fdes.map(fde => {
                let sde = dataElements.find(de => de.name === fde.value)
                if (sde?.name) {
                    return {
                        ...sde,
                        ...fde
                    }
                } else {
                    return fde
                }
            })
        }
        return fdes
    }

    const getNeededDataForBlock = () => {
        let payload = {
            appId: appId,
            orgId: orgId,
            dataModelId: block.mainDataModel,
            subDataModelId: block.dataModel,
            opportunityId: currentOpportunityId,
            dataUniqueId: `${chartUniqueId}_${selectedStep.name}_${block.name}`
        }
        props.getAllActionsOfOpportunity(payload)
    }

    const fetchDataModel = () => {
        let payload = {
            appid: appId,
            orgId: orgId
        }
        if (block?.dataModel) {
            if (!formData?.dataModel?.[block.dataModel] && !formData[`${block.dataModel}_loading`]) {
                payload['dataModelId'] = block.dataModel
                payload['dataUniqueId'] = block.dataModel
                props.getDataModelById(payload)
            }
        }
    }

    const getMembersList = () => {
        props.getMembersList({
            appId: appId,
            dataUniqueId: `${appId}_membersList`
        })
    }
    const onAddActionClick = () => {
        let obj = {
            enable: true,
            type: "create",
            data: {}
        }
        setModalInfo(obj)
    }

    const toggleCompareModal = () => {
        setModalInfo({
            enable: !modalInfo.enable
        })
    }

    const onSaveHandler = (data) => {
        getNeededDataForBlock()
        setModalInfo({
            enable: false
        })
    }

    const onTableActionClick = (type, data, event) => {
        if (type === 'edit') {
            setModalInfo({
                enable: true,
                type,
                data: {
                    id: data._id
                }
            })
        }

        if (type === 'delete') {
            handleDelete([data._id])
        }

        if (type === 'forward') {
            handleForward(data._id, event)
        }
    }

    const handleDelete = (ids) => {
        let payload = {
            appId: appId,
            orgId: orgId,
            dataModelId: block.mainDataModel,
            subDataModelId: block.dataModel,
            opportunityId: currentOpportunityId,
            data: {
                ids: ids
            },
            dataUniqueId: `${chartUniqueId}_${selectedStep.name}_${block.name}_delete`
        }
        props.deleteSingleActionOfOpportunity(payload)
    }

    const handleForward = (id, event) => {
        setSelectedId(id)
        setAnchorEl(event.currentTarget);
    }
    const onForwardModalClose = () => {
        setAnchorEl(null);
    }

    const onForwardModalSave = (data) => {
        setAnchorEl(null);
        console.log("it is onForwardModalSave", data)
        let d = data[0];
        let payload = {
            appId: props.appId,
            dataModelId: block.mainDataModel,
            subDataModelId: block.dataModel,
            opportunityId: currentOpportunityId,
            data: {
                assignedTo: {
                    id: d._id,
                    name: d.name
                }
            },
            dataUniqueId: `${chartUniqueId}_${selectedStep.name}_${block.name}_forward`,
            actionId: selectedId
        }
        props.updateSingleActionOfOpportunity(payload)
        setSelectedId('')
    }

    return (
        <div className={classes.rootContainer}>
            <div className={classes.titleBlock} style={{marginBottom: '20px'}}>
                <p className={classes.title} variant={''} style={{ ...labelStyle }}>{block.title || block.label}</p>
                {
                    block.actions?.enable && block.actions.add && 
                    // <Tooltip title="Add Action">
                    //     <AddIcon
                    //         fontSize='small'
                    //         className={classes.addIcon}
                    //         onClick={() => onAddActionClick()}
                    //     />
                    // </Tooltip>
                    <Button
                    variant="contained"
                    color="primary" 
                    style={{padding: '2px 3px', fontSize: 10,marginLeft: 10,}}  
                    onClick={onAddActionClick}                
                    className="btn btn-primary btn-color border-radius-0 text-color-white">
                        
                        {"New Action"}
                    </Button>
                }
            </div>
            <div >
                <SubDataModelTable
                    classes={classes}
                    enableActions={block.actions?.enable}
                    tableActions={block.actions}
                    tableHeaders={tableHeaders}
                    tableRows={blockState}
                    onTableActionClick={onTableActionClick}
                    stripedTable={block.list?.stripedTable}
                    tableStyle={block.list?.tableStyle || {}}
                    stickyHeader={block.list?.stickyHeader}
                    headerStyle={block.list?.headerStyle || {}}

                    formData={formData}
                    loader={loader}
                    chartUniqueId={chartUniqueId}
                    selectedStep={selectedStep}
                    block={block}
                    isLoading={formData[`${chartUniqueId}_${selectedStep.name}_${block.name}_loading`]}
                />
            </div>
            {
                modalInfo?.enable && <SubDataModelModal
                    {...props}
                    classes={classes}
                    formData={formData}
                    block={block}
                    selectedStep={selectedStep}
                    currentOpportunityId={currentOpportunityId}
                    chartUniqueId={chartUniqueId}

                    modalInfo={modalInfo}
                    formHeaders={formHeaders}
                    toggleCompareModal={toggleCompareModal}
                    onSaveHandler={onSaveHandler}
                    membersList={document[`${appId}_membersList`]}
                    dataModel={currentDataModel}
                />
            }

            <FilterSelect
                isOpen={!!anchorEl}
                anchorEl={anchorEl}
                id={anchorEl ? 'transitions-popper' : undefined}
                loader={props.loader}
                enablingLoader={false}

                allValues={forwardableMemberList}
                onClose={onForwardModalClose}
                onSave={(val) => {
                    onForwardModalSave(val)
                }}
            />
        </div>
    );
}

export default SubDataModelBlock