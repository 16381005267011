import { Box } from '@material-ui/core';
import React from 'react';
import { NotFound as Page404 } from 'dsilo-ui-components';
import ErrorImg from "../images/error.png"

const NotFound = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
      <Page404 notFoundImg={ErrorImg}/>
    </Box>
  );
};

export default NotFound;
