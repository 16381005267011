import React,{useEffect, useState} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CircularProgress, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import doughnutChartData from '../Charts/doughnutChartData.json'
ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position:"bottom",
        usePointStyle :true
      },
      title: {
        display: true,
        text: 'Extracted DataPoints',
        color:'black',
        font:{
          weight:'normal',
          size:"18"
        }
      }
    },
    elements: {
      center: {
        text: 'Red',
      }
    }
  }


 const DoughnutChart =(props) => {

  const useStyles = makeStyles((theme) => ({
    circularBar: {
      height: 165,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  }));

  const classes = useStyles();

  const[data,setData]=useState(doughnutChartData)
  const loading = props.loading;


   useEffect(()=>{
    if(props.data){
      let finalData = JSON.parse(JSON.stringify(props.data))
      let dds = finalData?.datasets.map((ele)=>{
        if(!('backgroundColor' in ele)){
          ele.backgroundColor=["#3b64af","#ef8246",'red',"#7dd09e"]
        }
        return ele
      })
      finalData.datasets = dds
      setData(finalData)
    }
  },[props.data])


  const plugins = [
    {
      afterDraw: function (chart) {
        console.log(chart);
        if (props.data?.datasets[0]?.data.length < 1) {
          let ctx = chart.ctx;
          let width = chart.width;
          let height = chart.height;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "20px Arial";
          ctx.fillText("No data available", width / 2, height / 2);
          ctx.restore();
        }
      },
    },
  ];

  if (loading) {
    return <div className={classes.circularBar}><CircularProgress /></div>
  }

  return <Doughnut plugins={plugins}   options={options} data={data} width={'10%'} height={'10%'} />;
}

export default DoughnutChart
