const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
import { request } from 'modules/client';

let route = `${API_BASE_URL}/notifications`

export class NotificationsService {

	static getNotifications(appId) {
		return request(route, {
			method: 'GET',
			headers: { 'appId': appId }
		})
	}

	static getNotification(params) {
		const uri = `${route}/${params.notificationId}`
		return request(uri, {
			method: 'GET',
			headers: { 'appId': params.dappId }
		})
	}

	static saveNotification(data) {
		return request(route, {
			method: 'POST',
			headers: { 'appId': data.appId },
			payload: data
		})
	}

	static updateNotification(data) {
		const uri = `${route}/${data._id}`
		return request(uri, {
			method: 'PUT',
			headers: { 'appId': data.appId },
			payload: data
		})
	}

	static deleteNotification(data) {
		const uri = `${route}/${data._id}`
		return request(uri, {
			method: 'DELETE',
			headers: { 'appId': data.appId },
		})
	}
}