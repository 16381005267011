import React from 'react';

const ArrayOfStringsBlock = (props) => {
    const { json, dataObj, selectedStep  } = props;

    // console.log("ArrayOfStringsBlock dataObj ===> ", dataObj, json);

    return (
        <div  style={{ marginTop: "5px" }}>
            {/* <p style={{ fontWeight: "bold", fontSize: "14px" }}>Findings</p> */}
            <div style={{  }}>
                {
                    dataObj?.findings && dataObj.findings.length ? dataObj.findings.map((str, index) => (
                        <div key={index}  style={{ fontSize: "14px", lineHeight: "2rem" }}>{str}</div>
                    )) :
                    <div>
                        <p>No Data</p>
                    </div>
                }
            </div>
        </div>
    );
};

export default ArrayOfStringsBlock;