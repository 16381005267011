import { Box, Button, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './style.scss';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import AppsIcon from '@material-ui/icons/Apps';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ContractGrid from './ContractGrid';
import ContractList from './ContractList';
import {
  deleteContract,
  downloadAnnotations,
  getAllContracts,
  hideSuccessMessage, clearPDFData
} from '../../store/actions/extractionResult';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { Loader } from '../../components/loader';
import { toast } from 'react-toastify';
import ConfirmDialog from '../../components/ConfirmDialog';
import {PUBLIC_PATH} from 'constants/index';
const StyledToggleButtonGroup = withStyles(theme => ({
  grouped: {
    border: '2px solid rgba(0, 0, 0, 0.12)',
    padding: '5px 10px',
  },
}))(ToggleButtonGroup);

const useStyles = makeStyles({
  toggleIcons: {
    marginRight: '5px',
    fontSize: 20,
  },
  confirmationDialoge: {
    '& .MuiDialog-paperWidthSm': {
      height: '250px',
      width: '600px'
    }
  },
  para: {
    fontSize: '28px !important',
    fontWeight: 600,
    color: '#000'
  }
});
const Contracts = props => {
  const [viewType, setView] = useState('grid');
  const [contracts, setContracts] = useState([]);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [deleteConfirmState, setDeleteConfirm] = useState({
    isOpen: false,
    deleteMessage: 'Are you sure you want to delete ?', data: null,
  });

  const handleAlignment = (event, newAlignment) => {
    setSelectedContracts([]);
    if (newAlignment !== null) {
      setView(newAlignment);
    }
  };

  useEffect(() => {
    props.clearPDFData()
    let appId = props.match.params.appid;
    console.log('appid', appId);
    props.getAllContracts(appId);
  }, []);

  useEffect(() => {
    if(Array.isArray(props.contractAnnotations.contractList)){
    setContracts(props.contractAnnotations.contractList); 
  }
  }, [props.contractAnnotations.contractList]);

  useEffect(() => {
    if (props.contractAnnotations.message) {
      toast.info(props.contractAnnotations.message);
      props.hideSuccessMessage();
    }
  }, [props.contractAnnotations.message]);

  const downloadAnnotationsOfContract = (id) => {
    let data = {
      appid: props.match.params.appid,
      ids: [id],
    };
    props.downloadAnnotations(data);
  };

  const downloadAllAnnotations = () => {
    let data;
    if (viewType === 'list' && selectedContracts.length > 0) {
      data = {
        appid: props.match.params.appid,
        ids: selectedContracts.map(i => `${i}`),
      };
    } else {
      data = {
        appid: props.match.params.appid,
        ids: contracts.map(i => `${i._id}`),
      };
    }
    props.downloadAnnotations(data);
  };

  const onCheckedContractsChange = (ids) => {
    setSelectedContracts([...ids]);
  };

  const onDeleteResponse = (isDelete) => {
    if (isDelete) {
      props.deleteContract(deleteConfirmState.data);
    }
    setDeleteConfirm({ ...deleteConfirmState, isOpen: false, data: null });
  };

  const classes = useStyles();
  return (
    <div className="contracts">
      <div className="title">
        <h1>Contracts</h1>
        <div style={{ float: 'right' }}>
      
          <Button
            variant="contained"
            color="primary"
            className="contract-btn"
            disableElevation
            style={{ marginLeft: '10px' }}
            onClick={() => {
              props.history.push({
                pathname: `/${PUBLIC_PATH}${props.match.params.appid}/contract-annotation/new`,
              });
            }}
          >
            Upload Contracts
          </Button>
          <Button
          variant="contained"
          color="primary"
          className="contract-btn"
          disableElevation
          style={{ marginLeft: '10px' }}
          onClick={downloadAllAnnotations}
        >
      Download Insights
        </Button>
    
        </div>
      </div>
      <Divider />
      <Box display="flex" justifyContent="flex-end" mt={2} mb={4}>
      
        <StyledToggleButtonGroup value={viewType} exclusive onChange={handleAlignment}>
          <ToggleButton value="grid">
            <AppsIcon className={classes.toggleIcons} />
            {' Grid'}
          </ToggleButton>
          <ToggleButton value="list">
            <FormatListBulletedOutlinedIcon className={classes.toggleIcons} />
            {' List'}
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Box>
      {props.contractAnnotations.loading ? (
        <Loader />
      ) : (
        <>
          {viewType === 'grid' && (
            <ContractGrid
              {...props}
              contracts={contracts}
              downloadAnnotations={downloadAnnotationsOfContract}
              onDeleteContract={data => setDeleteConfirm({ ...deleteConfirmState, isOpen: true, data: data })
              }
            />
          )}
          {viewType === 'list' && (
            <ContractList
              {...props}
              contracts={contracts}
              checkedContractChanged={onCheckedContractsChange}
              downloadAnnotations={downloadAnnotationsOfContract}
              onDeleteContract={data => setDeleteConfirm({ ...deleteConfirmState, isOpen: true, data: data })
              }
            />
          )}
        </>
      )}
      <ConfirmDialog open={deleteConfirmState.isOpen} handleClose={onDeleteResponse}
        alertMessageText={deleteConfirmState.deleteMessage} classes={classes} confirm />

    </div>
  );
};

const mapDispatchToProps = {
  getAllContracts,
  deleteContract,
  hideSuccessMessage,
  downloadAnnotations,
  clearPDFData
};

const mapStateToProps = state => {
  return {
    appConfig: state.appConfig.app,
    apps: state.app.app,
    login: state.login,
    user: state.user,
    contractAnnotations: state.contractAnnotations,
  };
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(Contracts));
