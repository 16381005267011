import React  from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
	container: {
		flex: 1,
		// backgroundColor: color.background,
		alignItems: 'stretch'
	},
	itemContainer: {
		borderBottomWidth: 1,
		// borderBottomColor: color.border,
		justifyContent: 'center',
		padding: 20
	},
	itemTitle: {
		fontSize: 20,
		// color: color.text
	},
	noFormulasText: {
		textAlign: 'center',
		fontSize: 40,
		marginLeft: "auto",
		marginRight: "auto"
	}
})

export default useStyles