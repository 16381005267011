import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    statusDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid #ddd',
        alignItems: 'center',
        borderRadius: 4,
        padding: 5,
        marginTop: 10,
        fontSize: 13,
    },
    statusDivAction: {
        display: 'flex',
        alignItems: 'center',
    }
}))

export default useStyles