import { ActionTypes } from "../constants";

const initialState = {
    data: {},
    document: {},
    filterConditions:[],
    error: {},
};
export default function chartReducer(state = initialState, action) {

    switch (action.type) {
        case ActionTypes.GET_LABELBOX_DATA: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true,
            };
        }
        case ActionTypes.GET_LABELBOX_DATA_SUCCESS: {
            let updatedData = { ...state.data, [action.payload.dataUniqueId]: action.payload.data }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.GET_LABELBOX_DATA_FAILURE: {
            let updatedError = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: updatedError,
            }
        }
        case ActionTypes.GET_PIECHART_DATA: {
            return {
              ...state,
              [action.payload.chartUniqueId ]: true, 
            }
          }
          case ActionTypes.GET_PIECHART_DATA_SUCCESS: {
             let updatedData = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
            return {
                ...state,
                document: updatedData,
                [action.payload.dataUniqueId ]: false,
            }   
          }       
          case ActionTypes.GET_PIECHART_DATA_FAILURE: {
            let updatedError = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
              ...state,
              [action.payload.chartUniqueId ]: false,
              error:updatedError
            }
          }
          case ActionTypes.GET_REDUX_EXTERNAL_FILTER: {
            return {
              ...state,
              filterConditions:[...action.payload]
            }
          }
          case ActionTypes.CLEAR_REDUX_CHART_DATA_OF_COMPONENT: {
            let dataToClear = action.payload;
            let _document = { ...state[dataToClear.key] };
            if (Array.isArray(dataToClear?.subKey)) {
              dataToClear?.subKey?.forEach(key => {
                delete _document[key];
              })
            } else {
              delete _document[dataToClear?.subKey]
            }
            return {
              ...state,
              [dataToClear.key]: _document
            }
          }
        default: {
            return state;
        }
    }
}