import React, { useState } from 'react';

import CustomInputField from './input';
import CustomSelectField from './select';
import CustomTextAreaField from './textArea';
import ArrayOfStringsBlock from './arrayOfStrings';
import CustomNumberInputField from './numberInput';
import CustomArrayOfCheckboxField from './arrayOfCheckbox';
import CustomCommentSection from "./comments";
import { getUIFieldTypeFromDataElement } from 'dsilo-ui-components';
import CustomDateRangePicker from './dateRangePicker';

const RenderFieldBasedOnType = (props) => {
    const { element } = props;
    console.log("element in RenderFieldBasedOnType ==>> ", element);
    
    let currentType = getUIFieldTypeFromDataElement(element);
    
    return (
        <React.Fragment>
            {
                (function () {
                    switch (currentType) {
                        case "string":
                            return <CustomInputField key={element.type} {...props} />
                            break;
                        case "number":
                        case "currency":
                            return <CustomNumberInputField key={element.type} {...props} />
                            break;
                        case "select":
                        case "namepicker":
                            return <CustomSelectField key={element.type} {...props} />
                            break;
                        case "MultiCheckbox":
                            return <CustomArrayOfCheckboxField key={element.type} {...props} />
                            break;
                        case "textarea":
                            return <CustomTextAreaField key={element.type} {...props} />
                            break;
                        case "comments" :
                            return <CustomCommentSection key={element.type} {...props} />
                            break;
                        case "array":
                            return <ArrayOfStringsBlock key={element.type} {...props} />
                            break;
                        case "dateRange":
                            return <CustomDateRangePicker key={element.type} {...props} />
                        default:
                            return <CustomInputField key={element.type} {...props} />
                            break;
                    }
                })()
            }
        </React.Fragment>
    )
}

export default RenderFieldBasedOnType