import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from "clsx";
import React, { memo, useRef, useEffect, useState } from "react";
// import { useInView } from 'react-intersection-observer';
import {
	extractMatchedChartFromQuestion,
	generateHighchartData,
	getRandomChartType,
	getResponseDataByAIType,
	isNumberArray,
	isObject,
	isStringArray,
	responseAiType,
	supportedHighcharts,
} from '../../ChatHelper';
import {
  ChartComponent,
  HighchartModal,
  MultiResponseComponent,
  PromptSuggestions,
  SimpleList,
  SimpleTable,
  SimpleTile,
  TextComponent,
} from '../../ResponseComponents';
import { initialText, textMsg } from '../../constants';
import "../../index.css";
import EmailEditor from "../EmailEditor";
import FeedbackDialog from "../FeedbackDialog";
import ChatMessage from "./ChatMessage";
import QuestionActions from "./QuestionActions";
import QuestionReactions from "./QuestionReactions";
import useStyles from "./styles";

const Chat = (props) => {
	const classes = useStyles();	
	const appId = props?.match?.params?.appid || "";
	const chatId = props?.selectedChatId || "";
	const { msg, index, messages, updateQuestionChartType } = props;
	// the `chatReactions` object holds the info to enable/disable the respective reaction.
	const { chatReactions, externalUrl } = props?.chartData;
	const highChartsRef = useRef(null);
	const [showChartResponse, setShowChartResponse] = useState(false);
	const [openChartModal, setOpenChartModal] = useState(false);
	const [highcartInfo, setHighchartInfo] = useState({
		showChartMenu: false,
		highchartData: {}
	});
	const [questionType, setQuestionType] = useState('');
	const [promptSuggestions, setPromptSuggestions] = useState([]);
	const [openCommentsModal, setOpenCommentsModal] = useState({
		open: false,
		field: {}
	})
	const [openEmailEditor, setOpenEmailEditor] = useState({
		open: false,
		field: {},
		chartBase64String: ''
	})
	const [commentsLoading, setCommentsLoading] = useState(false)

	// const { ref, inView, entry } = useInView({
	// 	/* Optional options */
	// 	threshold: 0,
	// 	triggerOnce: true,
	// });
	// useEffect(() => {
	// 	console.log('isInViewPort ::: *** ', inView, msg?.questionId || msg?._id);
	// }, [inView]);

	useEffect(() => {
		const questionChartData = [...(props?.chat?.data?.[msg?.questionId + '_chartData'] || [])]
		if(questionChartData && questionChartData?.length > 0) {
			setShowChartResponse(true);
		}
	}, [props?.chat?.data?.[msg?.questionId + '_chartData']]);

	useEffect(() => {
		if(props?.chat?.[`questionComments_loading`] !== undefined || props?.chat?.[`questionComments_loading`] !== null) {
			setCommentsLoading(props?.chat?.[`questionComments_loading`]);
		}
	}, [props?.chat?.[`questionComments_loading`]]);

	const toggleChartType = (value, index) => {
		if (supportedHighcharts.includes(value)) {
			if (highcartInfo?.highchartData && highcartInfo?.highchartData?.length === 0) {
				const responseData = generateHighchartData(msg?.data, value, props?.chartData);
				setHighchartInfo({
					showChartMenu: responseData && Object.keys(responseData).length > 0,
					highchartData: responseData
				});
			}
		}
		setQuestionType(value);
		updateQuestionChartType({ appId, chartType: value, questionId: msg?.questionId })
	};

	const handleEmailEditorModal = () => {
		// If chartType is highcharts then, get the chart image as base64 string.
		let chartBase64String = "";
		if (supportedHighcharts.includes(questionType) && highChartsRef && highChartsRef?.current) {
			chartBase64String = highChartsRef?.current.convertHighChartToBase64Img();
		}
		const { responseData } = getResponseDataByAIType(msg);
		const selectedQuestion = {
			...msg, 
			responseData,
			chartType: questionType
		}
		setOpenEmailEditor({ open: true, field: selectedQuestion, chartBase64String })
	}

	const handleCommentModal = (selectedQuestion) => {
		const payload = {
			appId,
			questionId: selectedQuestion.questionId,
			dataUniqueId: 'questionComments'
		}
		props.getQuestionComments(payload)
		setOpenCommentsModal({
			open: true,
			field: selectedQuestion
		});
	}

	const handleCloseModal = (modalType = 'comments') => {
		if(modalType === 'comments') {
			props.clearReduxChatDataOfCurrentComponent({
				key: "data",
				subKey: "questionComments"
			})
			setOpenCommentsModal({
				open: false,
				field: {}
			});
		} else {
			setOpenEmailEditor({
				open: false,
				field: {}
			});
			props?.updateEmailSuccessStatus({ dataUniqueId: 'questionSendEmail' })
		}
	}

	const createComment = (commentData) => {
		const payloadData = {
			appId: appId,
			dataUniqueId: 'createComment',
			commentsUniqueId: 'questionComments',
			payload: {
				questionId: commentData?.questionId,
				chatId,
				comment: commentData?.[commentData?.questionId]
			}
		}
		props.createComment(payloadData);
	}

	const handleSendEmail = (emailPayload) => {
		const payloadData = {
			appId: appId,
			dataUniqueId: 'questionSendEmail',
			payload: {
				...emailPayload,
				chatId
			}
		}
		props?.sendEmail(payloadData)
	}

	const ResponseComponent = (msg, respIndex) => {
		if (msg?.data) {
			const { responseData: tempResponseData, resultType, isMultiResponse } = getResponseDataByAIType(msg);
			let responseData = tempResponseData;
			// TODO: Once AI fixes the response, then remove the below code
			if (tempResponseData && isObject(tempResponseData) && !isMultiResponse) {
				let newResponse = [];
				Object.keys(tempResponseData).map(key => {
					newResponse.push({
						name: key,
						value: tempResponseData[key]
					})
				})
				responseData = newResponse;
			}
			switch (true) {
				case msg?.aiType === 'pgpt-v2' && isMultiResponse && responseData && Array.isArray(responseData) && responseData?.length > 1: {
					if (questionType !== 'multiResponse') setQuestionType('multiResponse');
					return (
						<MultiResponseComponent 
							index={index}
							message={msg}
							resultData={responseData}
						/>
					);
				}
				case msg?.aiType === "pgpt-qaa":
				case msg?.aiType === "pgpt-md-qna": {
					if (Array.isArray(responseData) && isStringArray(responseData)) {
						if (questionType !== 'simpleList') setQuestionType('simpleList');
						return <SimpleList responseList={responseData} />;
					} else {
						if (questionType !== 'text') setQuestionType('text');
						const responseText = responseData ? responseData : textMsg;
						return <TextComponent applyTextStyles={true} responseData={responseText} resultType={resultType} aiType={msg?.aiType} />;
					}
				}
				case responseAiType.includes(msg?.aiType) && typeof responseData === 'string':
				case responseAiType.includes(msg?.aiType) && typeof responseData === 'number': {
					if (questionType !== 'text') setQuestionType('text');
            		return <TextComponent applyTextStyles={true} responseData={responseData} heading={msg?.heading} resultType={resultType} />;
				}
				case responseAiType.includes(msg?.aiType) && Array.isArray(responseData) && responseData?.length > 0 && isStringArray(responseData):
				case responseAiType.includes(msg?.aiType) && Array.isArray(responseData) && responseData?.length > 0 && isNumberArray(responseData): {
					// if the array contains only strings then it's a text message with multiple lines of data
					if (questionType !== 'simpleList') setQuestionType('simpleList');
					return <SimpleList responseList={responseData} />;
				}
				case responseAiType.includes(msg?.aiType) && Array.isArray(responseData) && responseData?.length === 1: {
					if (questionType !== 'tile') setQuestionType('tile');
					return <SimpleTile data={responseData[0]} message={msg?.queryText} />;
				}
				case responseAiType.includes(msg?.aiType) && responseData && isObject(responseData) && Object.keys(responseData)?.length > 0: {
					if (questionType !== 'tile') setQuestionType('tile');
					return <SimpleTile data={responseData} message={msg?.queryText} />;
				}
				case responseAiType.includes(msg?.aiType) && Array.isArray(responseData) && responseData?.length > 1:
				case responseAiType.includes(msg?.aiType) && Array.isArray(responseData) && responseData?.length === 0: {
					const extractedChartType = extractMatchedChartFromQuestion(msg?.queryText);
					// console.log("extractedChartType ::: *** ", extractedChartType, msg?.queryText);
					let chartType = questionType || msg?.chartType || extractedChartType || getRandomChartType();
					const highchartData = generateHighchartData(responseData, chartType, props?.chartData);
					if(!highcartInfo.showChartMenu && highchartData && Object.keys(highchartData).length > 0) {
						setHighchartInfo({
							showChartMenu: highchartData && Object.keys(highchartData).length > 0,
							highchartData: highchartData
						});
					}
					if (highchartData && Object.keys(highchartData).length && supportedHighcharts.includes(chartType)) {
						if (questionType !== chartType) {
							setQuestionType(chartType);
						}
						return (
							<ChartComponent
								chartType={chartType}
								addCustomStyles={true}
								chartData={highchartData?.chartData || {}}
								responseData={highchartData}
								id={messages.length}
								highChartsRef={highChartsRef}
								title={msg?.queryText}
								chartUniqueId={msg?._id || msg?.questionId}
							/>
						);
					} else {
						if (questionType !== 'table') setQuestionType('table');
						return <SimpleTable index={index} data={responseData} message={msg?.queryText} />;
					}
				}
				default: {
					// Show Prompt Suggestions, when the result doesnot match with any of the above cases.
					const similarPrompt = msg?.similarPrompt || "";
					if (props?.chartData?.aiType && props?.chartData?.aiType === 'pgpt-v1' && props?.chartData?.showSuggestions && similarPrompt) {				
						let promptSuggestions = []
						if (typeof similarPrompt === 'string') {
							promptSuggestions.push(similarPrompt)
						} else {
							promptSuggestions = [...similarPrompt]
						}
						setPromptSuggestions(promptSuggestions);
					}
					const responseText = messages.length === 0
						? initialText
						: textMsg;
					if (questionType !== 'text') setQuestionType('text');
            		return <TextComponent applyTextStyles={false} responseData={responseText} resultType={resultType} />;
				}
			}
		} else {
			return <TextComponent applyTextStyles={false} responseData={textMsg} />;
		}
	}

	const toggleChartModal = () => {
		setOpenChartModal((prev) => !prev);
	}

	return (
		<div key={msg?._id || msg?.questionId} id={msg?._id || msg?.questionId} /* ref={ref} */>
			{/* {inView ? ( */}
			<Box>
				<Box 
					key={msg._id+index}
					className={`message user-message`}
					style={{ width: '-webkit-fill-available' }}
				>
					<ChatMessage key={msg?._id} message={msg} responseMessage={null} questionId={msg?._id || msg?.questionId} />
				</Box>
				<Box
					key={index}
					className={clsx({
						[classes.mainBox]: true,
						[classes.promptStyle]: questionType === 'prompt'
					})}
				>
					<Box
						className={clsx({
							[classes.childBox]: true,
						})} >
						<Box
							key={index}
							className={`message ${questionType === "tile" ? "bot-message" : "bot-message-border"}`}
							style={{ 
								width: '-webkit-fill-available', 
								// ...(questionType === 'multiResponse' && {
								// 	backgroundColor: 'lightgrey'
								// }) 
							}}
						>
							<ChatMessage 
								key={msg?.id}
								chartType={questionType}
								responseMessage={() => ResponseComponent(msg)} 
								message={null} 
								questionId={msg?._id || msg?.questionId} 
							/>
						</Box>
						{props?.selectedChatId !== 'sampleQuestion' && (
							<QuestionReactions
								appId={appId}
								chatReactions={chatReactions}
								externalUrl={externalUrl}
								handleEmailEditorModal={handleEmailEditorModal}
								handleCommentModal={handleCommentModal}
								msg={msg}
								{...props}
							/>
						)}
					</Box>
					{props?.selectedChatId !== 'sampleQuestion' && (
						<QuestionActions
							appId={appId}
							chatId={chatId}
							highcartInfo={highcartInfo}
							highChartsRef={highChartsRef}
							msg={msg}
							questionType={questionType}
							toggleChartModal={toggleChartModal}
							toggleChartType={toggleChartType}
							{...props}
						/>
					)}
				</Box>
				{showChartResponse && supportedHighcharts.includes(questionType) && (
					<Box 
						className={`message bot-message-border`} 
						style={{ marginRight: '30px', marginTop: '10px', width: '-webkit-fill-available' }}
					>
						<SimpleTable
							useFullWidth
							index={props.index}
							data={props?.chat?.data?.[msg?.questionId + '_chartData']}
							message={msg?.title}
						/>
						{/* Add Delete Option Here to remove the chart response , after adding the delete action check above inline styles needed or not */}
						<Button
							className={classes.root}
							onClick={() => setShowChartResponse(false)}
							aria-label="Hide Chart Responses" 
						>
							<DeleteIcon />
						</Button>
					</Box>
				)}
				{promptSuggestions && promptSuggestions?.length > 0 ? (
					<PromptSuggestions 
						{...props}
						promptSuggestions={promptSuggestions} 
						clearPrompts={setPromptSuggestions} 
					/>
				) : null}
				{openChartModal && (
					<HighchartModal
						msg={msg}
						open={openChartModal}
						handleClose={toggleChartModal}
						highchartData={highcartInfo.highchartData}
						type={questionType}
					/>
				)}
				{openCommentsModal.open && (
					<FeedbackDialog
						loading={commentsLoading}
						open={openCommentsModal.open}
						dialogTitle={'Comments'}
						onClose={handleCloseModal}
						onSubmitFeedback={createComment}
						previousComments={props?.chat?.data?.[`questionComments`]}
						fields={openCommentsModal.field}
						commentCreating={props?.chat?.[`createComment_loading`]}
					/>
				)}
				{openEmailEditor.open && (
					<EmailEditor
						open={openEmailEditor.open}
						dialogTitle={'Send Email'}
						onClose={() => handleCloseModal('email')}
						onSubmit={handleSendEmail}
						selectedQuestion={openEmailEditor.field}
						chartBase64String={openEmailEditor.chartBase64String}
						emailSending={props?.chat?.[`questionSendEmail_loading`]}
						emailSentSuccess={props?.chat?.[`questionSendEmail_success`]}
					/>
				)}
			</Box>
			{/* ) : <div style={{ height: 300 }} ></div>} */}
		</div>
	)
}; 

export default memo(Chat);