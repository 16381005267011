import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Top5Categ from './Top5Categ';
// import SpendView from './SpendView';
// import InfoWidgets from './InfoWidgets';
// import ClausesView from './ClausesView';
import { SpendView } from 'dsilo-ui-components'
import { getDataModelById, getLineData } from '../../store/actions'
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const spend_view_data = [
    {
        "label":"Total Spend",
        "value":"$457M"
    },
    {
        "label": "Addressable Spend",
        "value": "$239"
    },
    {
        "label": "Unaddressable Spend",
        "value": "$218M"
    }
]

const info_widgets_data = [
    {
        "label": "No: of Contracts",
        "value": "328",
        "color": "#90C4DB"
    },
    {
        "label": "Total Suppliers",
        "value": "314",
        "color": "#EF8450"
    },
    {
        "label": "Active Contracts",
        "value": "287",
        "color": "#BDF89B"
    },
    {
        "label": "Total Value",
        "value": "$328 M",
        "color": "#96B8F6"
    },
    {
        "label": "Valuie Leakage",
        "value": "$21 M",
        "color": "#6E7683"
    },
    {
        "label": "Contracts about to expire",
        "value": "12",
        "color": "#02DDE3"
    }
]
const data = [
    {
        "title": "No contract invoice",
        "invoice": {
            "val": "287",
            "items": "Ryzen™M 7 PRO 5750G"
        },
        "totalTrasactionValue": {
            "val": "$89 M",
            "valueLekaage": "$12 M"
        },
        "spendCategories": {
            "val": "Drilling Equipment Oil Wells"
        }
    },
    {
        "title": "Missing Discount",
        "invoice": {
            "val": "137",
            "items": "Jergen 9MM"
        },
        "totalTrasactionValue": {
            "val": "$45 M",
            "valueLekaage": "$5 M"
        },
        "spendCategories": {
            "val": "Trasportation Pipelines and Equipment"
        }
    },
    {
        "title": "Wrong Pricing Tier",
        "invoice": {
            "val": "89",
            "items": ""
        },
        "totalTrasactionValue": {
            "val": "$34 M",
            "valueLekaage": "$1.4 M"
        },
        "spendCategories": {
            "val": "Drilling Equipment Oil Wells"
        }
    }
]
const useStyles = makeStyles({
    parent_div: {
        display: 'flex',
        height: '100%',
    },
    parent_clauses_div: {
        marginBottom:'30px'
    }
});

const Totalspend = props => {
    console.log('Totalspend-props', props)
  const classes = useStyles();
    const { data } = props
  return (
      <React.Fragment>
          {/* <div className={classes.infoWidgets_div}>
              <InfoWidgets info_widgets_data={info_widgets_data} />
          </div> */}
          {/* <div className={classes.parent_clauses_div} >
              <ClausesView data={data} />
          </div> */}
          <div className={classes.parent_div}>
              <SpendView {...props} data={data} spend_view_data={spend_view_data} getDataModelById={getDataModelById}/>
              {/* <Top5Categ /> */}
          </div>
          
      </React.Fragment>
      
  );
}

const mapDispatchToProps = {
    getDataModelById,
    getLineData
};
const mapStateToProps = state => {
    return {
        user: state.user,
        formData: state.form,
        
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(Totalspend)));