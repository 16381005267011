import { Helpers } from 'dsilo-ui-components';
import React from 'react';
import moment from 'moment';
import { ChartComponent, SimpleList, SimpleTable, SimpleTile, TextComponent, MultiResponseComponent } from './ResponseComponents';
import { initialText, textMsg } from './constants';
import { arrayGroupByAllKeys, groupByInternalLogic } from './Utils/index'

//Currently supporting the below charts
export const supportedHighcharts = ['bar', 'pie', 'line', 'doughnut'];
export const responseAiType = ['pgpt-v1', 'pgpt-v2'];

export const chartHistoryUniqueId = 'chatHistory';
export const chartHistoryByIdUniqueId = 'chatHistory';
export const workspaceUniqueId = 'workspaces';
export const workspaceByIdUniqueId = 'workspaceById';
export const createWorkspaceUniqueId = 'createWorkspace';
export const updateWorkspaceUniqueId = 'updateWorkspace';
export const sampleQuestionsUniqueId = 'sampleQuestionsId';

export const ComponentMap = (
    type,
    index,
    responseData,
    lastMessage,
    messages,
    highChartsRef,
    options = {},
) => {
    const { addCustomStyles = false, chartUniqueId = '', aiType = '', resultType = null, messageObj = {}, fromDashboard = false } = options;
    switch (type) {
        case 'bar':
        case 'line':
            return (
                <ChartComponent
                    chartType={type}
                    addCustomStyles={addCustomStyles}
                    responseData={responseData}
                    id={messages.length}
                    highChartsRef={highChartsRef}
                    title={lastMessage}
                    chartUniqueId={chartUniqueId}
                />
            );
        case 'doughnut':
        case 'pie':
            return (
                <ChartComponent
                    chartType={type}
                    addCustomStyles={addCustomStyles}
                    chartData={responseData?.chartData || {}}
                    id={messages.length}
                    index={index}
                    highChartsRef={highChartsRef}
                    title={lastMessage}
                    chartUniqueId={chartUniqueId}
                />
            );
        case 'text': {
            const responseText = responseData ?? (messages.length === 0 ? initialText : textMsg);
            return <TextComponent applyTextStyles={responseData || responseData === 0 ? true : false} resultType={resultType} responseData={responseText} />;
        }
        case 'tile':
            return <SimpleTile data={responseData} message={lastMessage} />;
        case 'table':
            return <SimpleTable index={index} data={responseData} message={lastMessage} />;
        case 'multiResponse':
            return <MultiResponseComponent index={index} message={messageObj} resultData={responseData} resultType={resultType} fromDashboard={fromDashboard} />;
        case 'simpleList': 
            return <SimpleList index={index} message={lastMessage} responseList={responseData} />
        default:
            return <TextComponent applyTextStyles={false} responseData={messages.length === 0 ? initialText : textMsg} />;
    }
};

/**
 * 
 * @param {string} date 
 * @returns string - Returns date time string based on system locale.
 */
export const getDateTime = date => {
    const systemLang = Helpers.getLang();
    const options = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
    const formattedDate = new Date(
        moment(date)
            .format('MM/DD/YYYY HH:mm:ss')
            .toString(),
    ).toLocaleDateString(systemLang || 'en-US', options);
    return formattedDate;
};

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const checkAndFormat = text => {
    if (typeof text === 'number') {
        if (text > 1000) {
            return formatter.format(text);
        }
        return text;
    }
    return text;
};

/**
 * This functions generates an result data, which is supported for showing the highcharts data.
 * Currently this function supports pie, bar, dounghnut, line, multiline.
 * @param {array} responseData - Response data to build the respective highchart data.
 * @param {string} chartType - Question selected chartType.
 * @param {object} pageConfig - Page Chart Config, fo applying page relevant configs.
 * @returns object - Return an object which supports to display highcarts in UI.
 */
export const generateHighchartData = (responseData, chartType, pageConfig) => {
    let highchartData = null;
    if (responseData && responseData?.length > 0) {
        let labels = [],
            datasets = [],
            name = '';
        const keysLength = Object.keys(responseData[0]).length;
        const responseDataValues =  Object.values(responseData[0]);
        const modifiedResponseValues = responseDataValues.map(rValue => {
            if (typeof rValue === "string" && rValue.includes("$")) {
                return parseFloat(rValue.replace(/\$|,/g, '')) || rValue; 
            }
            return rValue;
        });
        const isValidData = modifiedResponseValues.some(val => typeof val === 'number');
        if (keysLength === 2 && isValidData) {
            responseData.forEach(obj => {
                let values = Object.values(obj);
                if (values && values.length > 0) {
                    values.forEach((value, index) => {
                        const newValue =
                            value && typeof value === 'string' ? value.replace(/\$|,/g, '') : value || '';
                        if (newValue && !isNaN(newValue)) value = parseFloat(newValue);
                        if (typeof value === 'number') {
                            datasets.push(value);
                        } else if (typeof value === 'string') {
                            if (!name) {
                                name = Object.keys(obj)[index];
                            }
                            labels.push(value);
                        } else {
                            labels.push('NA');
                        }
                    });
                }
            });
            //To support the highcart default chart structure
            highchartData = {
                chartData: {
                    title: '',
                    data: {
                        labels,
                        datasets: [
                            {
                                name: Helpers.camelCaseToSentenceCase(name),
                                data: datasets,
                            },
                        ],
                    },
                    ...(supportedHighcharts.includes(chartType) && {
                        type: 'column', //to show the bar chart in vertical position
                        showDataLabels: chartType === 'line' || labels.length <= 20,
                        showInLegend: labels.length > 20,
                        enableOnChartClick: pageConfig?.enableOnChartClick
                    }),
                },
            };
            return highchartData;
        } else if (keysLength === 3 && isValidData) {
            let isMultiChart = isValidDataForMultiLineChart(responseData);
            console.log("isMultiChart ====", isMultiChart)
            if (isMultiChart?.isMultiChart) {
                let { labels, series } = getChartDataForMultiLineChart(responseData, isMultiChart)
                // To support the highcart default chart structure
                highchartData = {
                    chartType: "line",
                    chartData: {
                        title: '',
                        data: {
                            labels: labels,
                            datasets: series,
                        },
                        ...(supportedHighcharts.includes(chartType) && {
                            type: 'column', //to show the bar chart in vertical position
                            showDataLabels: chartType === 'line' || labels.length <= 20,
                            showInLegend: labels.length > 20,
                            enableOnChartClick: pageConfig?.enableOnChartClick
                        }),
                    },
                };
            }
        }
    }
    return highchartData;
};

const isValidDataForMultiLineChart = (responseData) => {
    console.log("responseData ====", responseData)
    // expected is string:2 & number:1
    let match = {
        string: 0,
        number: 0,
        labels: [], data: 0
    }
    let obj = responseData[0]
    let values = Object.values(obj);
    if (values && values.length > 0) {
        values.forEach((value, index) => {
            const newValue = value && typeof value === 'string' ? value.replace('$', '') : value || '';
            if (newValue && !isNaN(newValue))
                value = parseFloat(newValue);
            if (typeof value === 'number') {
                match.number++
                match.data = Object.keys(obj)[index]
            } else if (typeof value === 'string') {
                match.string++
                match.labels.push(Object.keys(obj)[index])
            }
        })
    }

    console.log("match ====", match)
    if (match.string === 2 && match.number === 1) {
        return { ...match, isMultiChart: true }
    }
    return { ...match, isMultiChart: false }

}

const generateLines = (d1, d2) => {
    return lines
}
const generateSeries = (d1, d2) => {
    return series
}

const getChartDataForMultiLineChart = (responseData, isMultiChart) => {
    let labels = [],
        datasets = [],
        name = '',
        series = [],
        lines = [],
        yaxis = [];
    let d1 = groupByInternalLogic(responseData, isMultiChart.labels[0])
    let d2 = groupByInternalLogic(responseData, isMultiChart.labels[1])
    // console.log("d1 ====", d1)
    // console.log("d2 ====", d2)
    let l1 = Object.keys(d1)
    let l2 = Object.keys(d2)
    // console.log("l1 ====", l1)
    // console.log("l2 ====", l2)
    if (l1.length > l2.length) {
        yaxis = l1
        lines = l2
        let data = d1,
            label = isMultiChart.labels[1]
        series = lines.map(line => {
            return {
                name: Helpers.camelCaseToSentenceCase(line),
                data: yaxis.map(point => {
                    // console.log("point ====", point)
                    // console.log("data[point] ====", data[point])
                    // console.log("label ===", label)
                    let dq = data[point].filter(item => item?.[label] === line)
                    // console.log("dq ====", dq)
                    return dq?.reduce((result, item) => { return result + parseFloat(item?.[isMultiChart.data]) }, 0)
                        || null
                })
            }
        })
        // console.log("series ==== 11", series)
    } else {
        yaxis = l2
        lines = l1
        let data = d2,
            label = isMultiChart.labels[0]
        series = lines.map(line => {
            return {
                name: line,
                data: yaxis.map(point => {
                    // console.log("point ====", point)
                    // console.log("data[point] ====", data[point])
                    // console.log("label ===", label)
                    let dq = data[point].filter(item => item?.[label] === line)
                    // console.log("dq ====", dq)
                    return dq?.reduce((result, item) => { return result + parseFloat(item?.[isMultiChart.data]) }, 0)
                        || null
                })
            }
        })
        // console.log("series ==== 22", series)
    }
    console.log("series ====", series)
    return { labels: yaxis, series };
}

export const getChartType = componentType => {
    switch (componentType) {
        case 'bar':
        case 'doughnut':
        case 'pie':
        case 'line':
            return componentType;
        case 'table':
            return 'list';
        case 'list':
            return 'table';
        case 'tile':
            return 'tile'; // Later need to change to labelcolorboxes, need to do some changes for that.
        case 'text':
            return 'text';
        case 'multiResponse':
            return 'multiResponse';
        case 'simpleList': 
            return 'simpleList';
        default:
            return 'text';
    }
};

export const generateChartConfig = (ws, index, pageConfig= {}) => {
    const chartType = getChartType(ws?.chartType);
    const { responseData } = getResponseDataByAIType(ws);
    const highchartData = generateHighchartData(responseData, chartType, pageConfig)
    // const responseData = chartType === 'tile' && ws?.data && ws?.data?.length === 1 ? ws?.data[0] : ws?.data;
    let data = {
        chartData: {
            layout: {
                x: ws?.layout?.x || (index % 2) * 6,
                y: ws?.layout?.y || (index % 2) * 6,
                w: ws?.layout?.w || 6,
                h: ws?.layout?.h || 8,
                i: ws?.layout?.i || index?.toString(),
                static: ws?.layout?.static || false,
                moved: ws?.layout?.moved || false,
            },
            data: highchartData?.chartData?.data || null,
            responseData,
            name: ws?.queryText,
            title: ws?.queryText,
            question: ws?.questionId || ws?._id || '',
            rowsPerPage: 10,
            ...(supportedHighcharts.includes(chartType) && {
                type: 'column', //to show the bar chart in vertical position
                showDataLabels: chartType === 'line' || highchartData?.chartData?.data?.labels?.length <= 20,
                showInLegend: highchartData?.chartData?.data?.labels?.length > 20,
            }),
        },
        chartUniqueId: `${chartType + ws?._id + index}`,
        chartType,
    };
    return data;
};

/**
 * This function generates a random number from 0-4, based on which the chart type is decided.
 * @param {number} max - Max number you want to generate the random number.
 * @returns string- Returns a chartType based on the random number.
 */
export function getRandomChartType(max = 5) {
    const randomValue = Math.floor(Math.random() * max);
    const chartType = {
        0: 'bar',
        1: 'doughnut',
        2: 'line',
        3: 'pie',
        4: 'table',
    };
    return chartType?.[randomValue];
}

export const getResponseDataByAIType = (chatResponse) => {
    let responseData, resultType = null, isMultiResponse = false;
    const { aiType, data } = chatResponse;
    switch (true) {
        case aiType === 'pgpt-v2': {
            if (data && Array.isArray(data) && data?.length === 1) {
                responseData = data[0]?.output;
                resultType = data[0]?.outputType;
            } else if (data && isObject(data) && Object.keys(data).length > 0) {
                responseData = data?.output ?? '';
                resultType = data?.outputType ? data?.outputType : 'text';
            } else {
                responseData = data;
                isMultiResponse = data && Array.isArray(data) && data?.length > 1;
            }
            break;
        }
        case aiType === 'pgpt-v0':
        case aiType === 'pgpt-v1':
        case aiType === "pgpt-qaa":
        case aiType === "pgpt-md-qna":
        case aiType === "t5": {
            responseData = data;
            break;
        }
        default: {
            responseData = data;
            break;
        }
    }

    return {
        responseData,
        resultType,
        isMultiResponse
    }
}

/**
 * This functions checks if the passed argument is an number array or not.
 * @param {array} numberArray - Response Array Data
 * @returns boolean - true/false
 */
export const isNumberArray = (numberArray) => {
    if (numberArray && Array.isArray(numberArray)) {
        return numberArray.every(num => typeof num === 'number' && !isNaN(num));
    }
    return false;
}

/**
 * This functions checks if the passed argument is an string array or not.
 * @param {array} strArray - Array of data
 * @returns boolean - true/false
 */
export const isStringArray = (strArray) => {
    if (strArray && Array.isArray(strArray)) {
        return strArray.every(str => typeof str === 'string');
    }
    return false;
}

/**
 * This function is used to check if the passed the argument is a valid object or not.
 * @param {object} respObject - API response data
 * @returns boolean - true/false
 */
export const isObject = (respObject) => {
    return typeof respObject === 'object' && respObject !== null && !Array.isArray(respObject)
}

const chartKeywords = ['pie', 'pie chart', '#pie', 'bar', 'bar chart', '#bar', 'doughnut', 'doughnut chart', '#doughnut', 'line', 'line chart', '#line'];
/**
 * This function checks and extracts the chart type if user asked in the question/prompt.
 * @param {string} question - User asked prompt/question text.
 * @param {array[string]} chartsArray - Charts array, currently holds all the supported charts in the application.
 * @returns string/null - Returns the matched chartType or null.
 */
export const extractMatchedChartFromQuestion = (question, chartsArray = chartKeywords) => {
    if (question) {
        for (const chart of chartsArray) {
            const regex = new RegExp(`\\b${chart}\\b`, 'i');
            const match = question.match(regex);
            if (match) return match[0];
        }
    }
    return null; // Return null if no match is found
}

export const roundOffToTwoDecimalPlaces = (num) => {
    // Check if the number has decimal places
    let value = num;
    if (value % 1 !== 0) {
        value = Number(Math.round(value * 100) / 100).toFixed(2); // Round to two decimal places
    }
    return value;
}
