import clsx from 'clsx';
import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Drawer, Divider,
  Grid,
  IconButton, InputAdornment,
  ListItem, ListItemText,
  TextField, Tooltip,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';

import { sampleQuestionsUniqueId } from '../../ChatHelper';
import useStyles, {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DrawerHeader,
  NestedAccordionSummary,
} from './styles';

const SettingsContainer = props => {
  const classes = useStyles();

  const [dataModels, setDataModels] = useState([]);
  const [openDataModelMenu, setOpenDataModelMenu] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    props.getDataModels(props.appId);
    props.getSampleQuestions({ appId: props.appId, dataUniqueId: sampleQuestionsUniqueId });
  }, []);

  useEffect(() => {
    if (Array.isArray(props?.dataModels) && props?.dataModels?.length) {
      const validDataModels = [...props.dataModels].filter(
        dataModel => dataModel.isLinkedToAIModel,
      );
      let filteredDataModels = validDataModels;
      if (searchText && searchText?.length >= 2) {
        filteredDataModels = validDataModels.map(dataModel => {
          let dmDataElements = [];
          if (dataModel?.dataElements && dataModel?.dataElements?.length > 0) {
            dmDataElements = dataModel.dataElements.filter(
              de =>
                de?.isModelKey && de?.description?.toLowerCase().includes(searchText.toLowerCase()),
            );
          }
          return {
            ...dataModel,
            dataElements: dmDataElements,
          };
        });
      }
      setDataModels(filteredDataModels);
    }
  }, [props?.dataModels, searchText]);

  const handleChange = () => {
    setOpenDataModelMenu(prev => !prev);
  };

  const handleTextChange = event => {
    const { value } = event?.target;
    setSearchText(value ?? '');
  };

  return (
    <div className="accord-container">
      <Box display="flex" direction="row" justifyContent="end">
        <IconButton className={classes.filterIcon} onClick={handleChange}>
          <SettingsIcon style={{ fontSize: 24 }} />
        </IconButton>
        <Drawer
          anchor="right"
          className={classes.drawer}
          open={openDataModelMenu}
          onClose={handleChange}
          variant="persistent"
        >
          <DrawerHeader>
            <TextField
              id="Field Search"
              onChange={handleTextChange}
              placeholder="Search..."
              variant="outlined"
              inputProps={{ 'aria-label': 'search' }}
              value={searchText}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchText && (
                      <>
                        <Divider className={classes.divider} orientation="vertical" />
                        <Tooltip title="Clear search">
                          <IconButton
                            color="primary"
                            className={classes.iconButton}
                            onClick={() => setSearchText('')}
                            aria-label="clear search"
                            edge="end"
                          >
                            <CloseIcon style={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start" style={{ marginRight: 0 }}>
                    <IconButton
                      type="button"
                      edge="start"
                      className={classes.iconButton}
                      aria-label="search"
                    >
                      <SearchIcon style={{ fontSize: 16 }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <Tooltip title="Close"> */}
              <IconButton onClick={handleChange}>
                <CloseIcon style={{ fontSize: 24 }} />
              </IconButton>
            {/* </Tooltip> */}
          </DrawerHeader>
          <Box
            role="presentation"
            style={{ display: 'flex', flexDirection: 'column', marginBottom: 3, marginTop: '3em' }}
          >
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMore className={classes.expand_icon} />}>
                Fields
              </AccordionSummary>
              <AccordionDetails>
                {dataModels.length > 0 &&
                  dataModels.map((dm, index) => (
                    <Accordion key={dm.name + index}>
                      <NestedAccordionSummary
                        expandIcon={<ExpandMore className={classes.expand_icon} />}
                      >
                        {dm.name}
                      </NestedAccordionSummary>
                      {dm?.dataElements && dm?.dataElements.length > 0 ? (
                        dm.dataElements.map((de, deIndex) => (
                          <Fragment key={de.name + deIndex}>
                            {de.isModelKey ? (
                              <AccordionDetails style={{ padding: '8px' }}>
                                {de.description}
                              </AccordionDetails>
                            ) : null}
                          </Fragment>
                        ))
                      ) : (
                        <AccordionDetails style={{ padding: '8px', textAlign: 'center' }}>
                          No fields found
                        </AccordionDetails>
                      )}
                    </Accordion>
                  ))}
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMore className={classes.expand_icon} />}>
                Sample Questions
              </AccordionSummary>
              {props.sampleQuestions && props.sampleQuestions.length > 0 ? (
                props.sampleQuestions.map((sq, index) => (
                    <ListItem
                      key={index}
                      className={clsx(classes.listItemRoot, {
                        // [classes.selectedChat]: props.selectedChatId === prevChat._id,
                      })}
                      button
                      onClick={() => props?.showSampleQuestionResponse(sq)}
                      // selected={props.selectedChatId === prevChat._id}
                    >
                      <Grid container spacing={1} alignItems="center">
                          <Grid item>
                              <ListItemText
                                  classes={{
                                      root: classes.listItemTextRoot
                                  }}
                                  primary={sq.queryText || 'New Chat'}
                              />
                          </Grid>
                      </Grid>
                    </ListItem>
                ))
              ) : (
                <Typography variant="h6" component="h6">
                  No sample questions
                </Typography>
              )}
            </Accordion>
          </Box>
        </Drawer>
      </Box>
    </div>
  );
};

export default SettingsContainer;
