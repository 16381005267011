import { Box, Grid, Input, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChipSelect, CustomButton } from 'dsilo-ui-components';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { useState } from 'react';
import { defaultStyles, FileIcon } from "react-file-icon";
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import SparkMD5 from 'spark-md5';
import { PUBLIC_PATH } from '../../constants';
import { bulkUploadPDF, bulkUploadPDFFinal, uploadPDFAsync } from '../../store/actions';
import LinearProgressWithLabel from './LinearProgressWithLabel';
import { Upload } from "./upload/Upload";
import UploadNote from './UploadNote';
import { generateGUID } from '../../utility/utils';

window.Buffer = window.Buffer || require("buffer").Buffer;

var totalFileCountArray = []
var finalArrayToNavigate = []

const useStyles = makeStyles(() => ({
	label: {
		border: "2px solid",
		padding: "90px",
		paddingLeft: "40%"
	},
	btn: {
		width: '10px'
	},
	body: {
		padding: '1.5em 0',
	},
	dropZone: {
		width: 900,
		'& .MuiGrid-grid-xs-4': {
			flexBasis: "19.333333% !important"
		}
	},
	notes: {
		display: 'flex',
		justifyContent: 'center',
	},
	title: {
		paddingBottom: 20,
	},
	chipSelect: {
		minWidth: 250,
	},
	errorText: {
		color: 'red',
	},
	errorContainer: {
		display: 'flex',
		justifyContent: 'center',
		height: 30,
	},
}));

const BulkUploadComponent = (props) => {

	const { data, appId } = props
	const { chartData } = data
	const { insightsType, loading, page,
		selectedDocumentType, setSelectedDocumentType,
		selectedAdditionalAIParameters, onAdditionalAIParametersChange
	} = props

	const getUploadFieldsInitialValue = () => {
		let val = []
		props.chartData?.uploadFields?.map(field => {
			// if (field.type === 'select') {
			// 	val.push({ label: '', value: '' })
			// } else {
			val.push(null)
			// }
		})
		return val;
	}

	const fileInput = React.useRef();
	const [files, setFiles] = useState([])
	const [pdfError, setPdfError] = useState(null);
	const [totalParts, setTotalParts] = useState([])
	const [uploadSuccess, setUploadSuccess] = useState(false)
	const [totalPartsArray, setTotalPartsArray] = useState([])
	const [totalPartsLength, setTotalPartsLength] = useState([])
	const [fileUploadProgress, setFileUploadProgress] = useState(false)
	const [uploadFields, setUploadFields] = useState(getUploadFieldsInitialValue())
	const [uploadBatchUniqueId, setUploadBatchUniqueId] = useState(generateGUID())
	
	const chunkSize = 5 * 1024 * 1024; // Size of each chunk, set to 5 Mb
	// Blob. slice method is used to segment files.
	// At the same time, this method is used in different browsers in different ways.
	const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;

	const classes = useStyles();

	const uploadFileHandler = (file) => {
		setFiles(file);
	};

	const fileSubmitHandler = (event) => {
		event.preventDefault();
		setFileUploadProgress(true)
	}

	const removeFile = (event, index) => {
		event.preventDefault();
		let test = Array.from(files);
		test.splice(index, 1)
		setFiles(test)
	}

	const hashFile = (file) => {
		return new Promise((resolve, reject) => {

			const chunks = Math.ceil(file.size / chunkSize);
			let currentChunk = 0;
			const spark = new SparkMD5.ArrayBuffer();
			const fileReader = new FileReader();
			function loadNext() {
				const start = currentChunk * chunkSize;
				const end = start + chunkSize >= file.size ? file.size : start + chunkSize;
				fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
			}
			fileReader.onload = e => {
				spark.append(e.target.result); // Append array buffer
				currentChunk += 1;
				if (currentChunk < chunks) {
					loadNext();
				} else {
					// console.log('finished loading');
					const result = spark.end();
					// If you simply use result as a hash value, if the content of the file is the same and the name is different
					// Two files that you want to keep cannot be retained. So add the file name.
					const sparkMd5 = new SparkMD5();
					sparkMd5.append(result);
					sparkMd5.append(file.name);
					const hexHash = sparkMd5.end();
					resolve(hexHash);
				}
			};
			fileReader.onerror = () => {
				console.warn('file reading failed! ')
			};
			loadNext();
		}).catch(err => {
			console.log(err);
		});
	}

	const updatePayload = (payload) => {
		if (chartData.QAProcess !== undefined) {
			payload.QAProcess = chartData.QAProcess
		}
		if (chartData.disableDuplicateCheck) {
			payload.disableDuplicateCheck = chartData.disableDuplicateCheck
		}
		if (chartData.uploadFields?.length) {
			let saveToDocument = []
			chartData.uploadFields.forEach((i, index) => {
				if (i.sendOnlyOptionValue && i.type === 'select') {
					payload[i.name] = uploadFields[index]?.value
				} else {
					payload[i.name] = uploadFields[index]
				}
				saveToDocument.push(i.name)

			})
			payload.saveToDocument = saveToDocument
		}
		if (chartData.modelDataType) {
			payload.modelDataType = chartData.modelDataType
		}
		payload.uploadFields = chartData.uploadFields
		payload.uploadBatchUniqueId = uploadBatchUniqueId
		console.log("it is payload to upload api ===", payload)
		return payload
	}

	const uploadSinglePDF = async (v) => {
		let payload = updatePayload(v)
		let result = await props.uploadPDFAsync(payload)
		console.log("it is result after upload", result)
		if (result && result.error === '0') {
			setUploadSuccess(true)
		}
	}

	const uploadBulkPDFs = async (v) => {
		let payload = updatePayload(v)
		await props.bulkUploadPDF(payload)
	}

	const uploadFile = (event) => {
		event.preventDefault();
		setFileUploadProgress(true)
		// console.log("files ====== ", files);
		Array.from(files).forEach(async (file, index) => {
			const hash = await hashFile(file); // file hash 

			const target = {
				key: file.name,
				body: file,
				index,
				name: file.name,
				total: Math.ceil(file.size / chunkSize),
				size: file.size,
				hash
			};

			try {
				const parallelUploads = new Upload({
					params: target,

				}, {
					bulkUploadPDF: uploadBulkPDFs,
					// bulkUploadPDF: props.bulkUploadPDF,
					bulkUploadPDFFinal: props.bulkUploadPDFFinal,
					uploadPDF: uploadSinglePDF,
				}, appId, data);

				parallelUploads.onTotalParts("totalPartsOfChunk", (totalPartsss) => {
					totalFileCountArray.push(totalPartsss)
					setTotalParts(totalFileCountArray)
					console.log('totalFileCountArray ?', totalFileCountArray)
					console.log('totalPartsss', totalPartsss)

				});
				let progressObj = {};
				parallelUploads.on("httpUploadProgress", (progress) => {

					if (progressObj[progress.Key]) {
						progressObj[progress.Key] = [progress.part, ...progressObj[progress.Key]]
					} else {
						progressObj[progress.Key] = [progress.part]
					}
					console.log('progressObj', progress, progressObj)
					setTotalPartsLength({ ...progressObj })
				});

				parallelUploads.init();
			} catch (e) {
				console.log(e);
			}
		})
	}

	const onDocumentTypeChange = (val, op) => {
		if (pdfError) {
			setPdfError(null)
		}
		setSelectedDocumentType(val)
	}

	const onAIParametersChange = (val, item) => {
		if (pdfError) {
			setPdfError(null)
		}
		onAdditionalAIParametersChange(val, item)
	}

	const onChangeUploadFields = (value, index) => {
		setPdfError(null);
		let _uploadFields = [...uploadFields];
		_uploadFields[index] = value;
		setUploadFields(_uploadFields)
	}

	const navigationToViewScreen = async (val) => {
		setUploadBatchUniqueId(generateGUID())
		toast.success(chartData.afterSuccessMessage
			|| 'Document has been submitted to AI to process, AI extraction process has been initiated');
		let lastPage; // if user session is available redirect to user session page otherwise redirect to configured URL
		//TODO:  all re-directions need to be based pageId
		let userSession = await localStorage.getItem('userSession')
		if (userSession) {
			userSession = JSON.parse(atob(userSession))
			const userId = props.user?.id || props.user?._id
			lastPage = userSession[userId]?.TileListPage;
			if (lastPage) {
				props.history.push(`/${PUBLIC_PATH}${props.match.params.appid}/page/${lastPage}`)
			}
		}
		if (!lastPage) {
			finalArrayToNavigate.push(val)
			if (finalArrayToNavigate.length === totalParts.length) {
				props.history.push(`/${PUBLIC_PATH}${props.match.params.appid}/page/${props.data?.chartData?.afterSuccess}`)
			}
		}
	}

	const onUploadClick = (event) => {
		if (chartData.uploadFields?.length) {
			let error = false;
			chartData.uploadFields.forEach((i, index) => {
				if (!uploadFields[index]) {
					setPdfError('Please enter ' + i.label)
					error = true
				}
			})
			if (!error) {
				uploadFile(event)
			}
		} else {
			uploadFile(event)
		}

	}


	return (
		<div className={classes.body}>
			<Grid container style={{ position: 'relative' }}>
				<Grid item xs={10}>
					<Box mt={2} pl={30} pr={10} mb={1}>
						<h2 style={{ textAlign: 'center' }}>
							{chartData?.header || 'Digitize your PDF contract document with few clicks.'}
						</h2>
					</Box>
				</Grid>

				{!(!chartData.upgrade) ?
					<Grid item xs={2}>
						<Box mt={2} mb={1}>
							<h4 style={{ textAlign: 'center' }}>Do you want to get full benefits of DSilo AI?</h4>
						</Box>
					</Grid> : null
				}
				<Grid item xs={10}>
					<Box mt={2} pl={30} pr={10} mb={4}>
						<h2 style={{ textAlign: 'center', fontWeight: 'normal' }}>
							{chartData?.subHeader
								? chartData?.subHeader
								: <span>Upload Bulk PDF files and click on &#8216;Extract Insights&#8217; to process the document by Dsilo
									AI platform and get insights</span>
							}
						</h2>
					</Box>
				</Grid>
				{!(!chartData.upgrade) ?
					<Grid item xs={2}>
						<Box mt={2} mb={4} display="flex" justifyContent="center" alignItems="center">
							<CustomButton
								onClick={props.handleUpgrade}
								style={{ backgroundColor: ' #002744', color: '#fff' }}
								variant="contained">
								Upgrade
							</CustomButton>
						</Box>
					</Grid> : null
				}
			</Grid>
			{chartData?.selectedDocumentTypes && <Grid container justifyContent="center" style={{ position: 'relative' }}>
				<Grid item xs={2}> <div style={{ marginRight: 10, marginBottom: 20 }}>
					<span>{"Document Types"}</span>
					<ChipSelect
						className={classes.chipSelect}
						onChange={onDocumentTypeChange}
						value={selectedDocumentType}
						isSearchable={true}
						textFieldProps={{
							InputLabelProps: {
								shrink: true,
							},
							variant: 'outlined',
						}}
						variant="fixed"
						options={chartData?.selectedDocumentTypes ? chartData?.selectedDocumentTypes : []} />
				</div></Grid>
			</Grid>}

			{chartData?.uploadFields?.length && <Grid container justifyContent="center" style={{ position: 'relative' }}>
				{
					chartData?.uploadFields.map((field, index) => {
						return (
							<Grid item xs={2}> <div style={{ marginRight: 10, marginBottom: 20 }} key={index}>
								<span>{field.label || field.description || field.name}</span>
								{
									field.type === 'select' && <ChipSelect
										className={classes.chipSelect}
										onChange={(e) => onChangeUploadFields(e, index)}
										value={uploadFields && uploadFields[index]}
										isClearable={true}
										isSearchable={true}
										textFieldProps={{
											InputLabelProps: {
												shrink: true,
											},
											variant: 'outlined',
										}}
										variant="fixed"
										options={field.options || []} />
								}

								{
									field.type === 'string' && <Input
										value={uploadFields && uploadFields[index]}
										onChange={(e) => onChangeUploadFields(e.target.value, index)}
									/>
								}

							</div></Grid>
						)
					})
				}

			</Grid>}

			{chartData?.additionalAIInputParameters &&
				chartData?.additionalAIInputParameters.map(item => {
					return <div style={{ display: 'flex', justifyContent: 'center' }}>
						<div style={{ marginRight: 10, marginBottom: 20, display: 'flex' }}>
							<div style={{ display: 'flex', margin: '10px' }} >{item.label} <span>{item.required && '*'}</span> </div>
							<div>
								<ChipSelect
									className={classes.chipSelect}
									onChange={(val) => onAIParametersChange(val, item)}
									value={selectedAdditionalAIParameters
										&& selectedAdditionalAIParameters[item.value]}
									isSearchable={true}
									textFieldProps={{
										InputLabelProps: {
											shrink: true,
										},
										variant: 'outlined',
									}}
									variant="fixed"
									options={item?.options ? item?.options : []} />
							</div>
						</div>
					</div>
				})
			}
			<div className={classes.errorContainer}>
				{
					pdfError && <span className={classes.errorText}>{pdfError}</span>
				}
			</div>


			<Grid container justifyContent="center" style={{ position: 'relative' }}>
				<Grid item className={classes.dropZone}>
					<DropzoneArea
						maxFileSize={204800000}
						filesLimit={10}
						showPreviews={false}
						onChange={file => { uploadFileHandler(file) }}
						acceptedFiles={chartData.acceptedFiles || ['application/pdf', 'application/zip', 'application/x-zip-compressed']}
					/>
				</Grid>
			</Grid>

			{!fileUploadProgress && <Box display="flex" justifyContent="center" my={3}>
				<CustomButton onClick={(event) => onUploadClick(event)} size="large"
					color="primary" variant="contained" style={{ marginRight: '20px', marginLeft: '20px', testTransform: 'capitalize' }}>
					{chartData.buttonName ? chartData.buttonName : 'Extract Insights'}
				</CustomButton>
			</Box>}


			{fileUploadProgress && <Grid container spacing={3}>
				<Grid item xs={12} >
					<Paper style={{ marginTop: '40px' }} elevation={0}>
						{
							Array.from(files).map((file, index) =>
								<Grid container spacing={3} display="flex" alignItems="center" key={index}>
									<Grid item xs={6} sm={3}>
										<div style={{ width: '48px' }}>
											<FileIcon extension={file.name.split('.').pop()} {...defaultStyles[file.name.split('.').pop()]} />
										</div>
										<Paper elevation={0}>{file.name}</Paper>
									</Grid>
									<Grid item xs={6} sm={3}>
										<LinearProgressWithLabel
											totalParts={totalParts?.find((el) => { if (el.name === file.name) return el.totalPartNumber })}
											// totalPartsLength={totalPartsLength?.find((pl) => { if (pl.key === file.name) return pl.data })}
											totalPartsLength={totalPartsLength[file.name]?.length}
											progressStatus={navigationToViewScreen}
											uploadSuccess={uploadSuccess}
										/>
									</Grid>
								</Grid>
							)
						}
					</Paper>
				</Grid>
			</Grid>}

			{props.note || true ?
				<div className={classes.notes}>
					<UploadNote note={chartData.note} contactClick={props.contactClick} />
				</div> : null
			}
		</div>
	)
}

const mapDispatchToProps = {
	bulkUploadPDF,
	bulkUploadPDFFinal,
	uploadPDFAsync
};

const mapStateToProps = state => {
	return {
		formData: state.form,
		user: state.user,
	};
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(BulkUploadComponent)));