/**
 * Importing necessary modules and components:
 * 
 * - `React, { useEffect, useRef }` from 'react': 
 *   Core React library and hooks used for side effects (`useEffect`) and creating references (`useRef`).
 * 
 * - `List`, `ListItem`, `ListItemText` from '@material-ui/core/List':
 *   Material-UI components for rendering lists. `List` is the container for list items, 
 *   `ListItem` represents each item in the list, and `ListItemText` displays the text within each item.
 * 
 * - `VisibilityIcon` from '@material-ui/icons/Visibility': 
 *   Icon component representing visibility, commonly used to indicate actions like "view" or "show".
 * 
 * - `DeleteIcon` from '@material-ui/icons/Delete': 
 *   Icon component representing deletion, used for actions like "delete" or "remove".
 * 
 * - `Button` from '@material-ui/core/Button': 
 *   Material-UI button component used for various clickable actions within the UI.
 *  Import the ShowAttachmentsModel and DownloadIcon components.
 * 
 * - ShowAttachmentsModel is a module that likely contains the model or 
 *   component logic related to showing attachments.
 * 
 * - DownloadIcon is a Material UI icon component used to represent 
 *   the action of downloading, typically displayed as a download button.
 */
import React, { useEffect, useRef, useState } from 'react'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import VisibilityIcon from "@material-ui/icons/Visibility"
import DeleteIcon from "@material-ui/icons/Delete"
import Button from '@material-ui/core/Button';
import ShowAttachmentsModel from './model';
import DownloadIcon from '@material-ui/icons/GetApp';
const UploadComponent = (props) => {
    const {
        orgId,
        appId,
        currentOpportunityId,
        blocks,
        block,
        selectedStep,
        data: { chartUniqueId },
        formData: { document }
    } = props;
    /**
     * @constant
     * @type {string[]}
     * @description
     * A list of supported file formats for both PDF and image types. 
     * This array combines various PDF standards and common image formats.
     * 
     * PDF formats include:
     * - 'pdf': Standard PDF format.
     * - 'pdf/a': PDF/A - for digital preservation.
     * - 'pdf/x': PDF/X - for print-ready documents.
     * - 'pdf/e': PDF/E - for engineering documents.
     * - 'pdf/ua': PDF/UA - for accessible documents.
     * 
     * Image formats include:
     * - 'jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp', 'svg', 'ico', 'apng', 'heif', 'heic'
     * 
     * Usage:
     * This array is used to validate whether a given file type is supported by the application 
     * for download and viewing operations.
     */
    const SUPPORTED_FORMATS = [
        'pdf',    // Standard PDF format
        'pdf/a',  // PDF/A - for digital preservation
        'pdf/x',  // PDF/X - for print-ready documents
        'pdf/e',  // PDF/E - for engineering documents
        'pdf/ua', // PDF/UA - for accessible documents
        'jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp', 'svg', 'ico', 'apng', 'heif', 'heic' // Image formats
    ];
    /**
     * Reference to the file input element. 
     * Used to trigger the file input programmatically.
     */
    const fileInputRef = useRef(null);
    const [quickPdfView, setQuickPdfView] = useState(false)
    const [docID, setId] = useState('')

    const handleClick = () => {
        /**
         * Handles the click event to trigger the file input.
         * Simulates a click on the file input element to open the file picker dialog.
         */
        fileInputRef.current.click();
    };

    /**
     * Retrieves the background color for the block's accordion section.
     * If the background color is not defined, it will be `undefined`.
     */
    const backgroundColor = block?.accordion?.backgroundColor;
    /**
     * Fetches all actions related to the opportunity if they haven't been fetched yet.
     * This effect runs whenever the specified unique document key changes.
     */
    useEffect(() => {
        if (!document[`${chartUniqueId}_${selectedStep.name}_${block.name}`]) {
            let payload = {
                appId: appId,
                orgId: orgId,
                dataModelId: block.mainDataModel,
                subDataModelId: block.dataModel,
                opportunityId: currentOpportunityId,
                dataUniqueId: `${chartUniqueId}_${selectedStep.name}_${block.name}`
            }
            props.getAllActionsOfOpportunity(payload)
        }
    }, [document[`${chartUniqueId}_${selectedStep.name}_${block.name}`]])

    /**
    * Handles the file change event when a file is selected for upload.
    * Initiates the upload of the selected file as an attachment.
    * @param {Object} event - The event object triggered by the file input.
    */
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            props.uploadAttachmentOpportunity(payloadForUploadSupportingDocument(file))
        }
    };

    /**
    * Fetches all actions related to the opportunity when specific conditions are met.
    * This effect runs whenever the document key, form data loading state, or delete action loading state changes.
    */
    useEffect(() => {
        if (document[`${chartUniqueId}_${selectedStep.name}_${block.name}`]) {
            let payload = {
                appId: appId,
                orgId: orgId,
                dataModelId: block.mainDataModel,
                subDataModelId: block.dataModel,
                opportunityId: currentOpportunityId,
                dataUniqueId: `${chartUniqueId}_${selectedStep.name}_${block.name}`
            }
            props.getAllActionsOfOpportunity(payload)
        }
    }, [
        document[`${chartUniqueId}_upload_attachments_file`],
        props.formData[props.data.chartUniqueId + "_upload_attachments_file_loading"],
        props.formData[`${chartUniqueId}_${selectedStep.name}_${block.name}_delete_attachments_file_loading`]
    ])

    /**
    * Prepares the payload required for uploading a supporting document.
    * 
    * @param {File} files - The file object selected for upload.
    * @returns {Object} payload - The payload object containing form data and relevant metadata.
    */
    const payloadForUploadSupportingDocument = (files) => {
        let formData = new FormData();
        formData.append('file', files)
        return {
            formData,
            appId: props.match.params.appid,
            dataModelId: block.mainDataModel,
            subDataModelId: block.dataModel,
            opportunityId: currentOpportunityId,
            dataUniqueId: props.data.chartUniqueId + "_upload_attachments_file"
        }
    }

    /**
    * Handles the deletion of an attachment for an opportunity.
    * Constructs the payload and triggers the delete action.
    * 
    * @param {Object} e - The event object triggered by the delete action.
    * @param {Object} item - The item to be deleted, typically containing its unique identifier.
    */
    const handleDelete = (e, item) => {
        const idsArray = [item['_id']];
        const result = { ids: idsArray };
        let payload = {
            appId: appId,
            orgId: orgId,
            dataModelId: block.mainDataModel,
            subDataModelId: block.dataModel,
            opportunityId: currentOpportunityId,
            data: result,
            dataUniqueId: `${chartUniqueId}_${selectedStep.name}_${block.name}_delete_attachments_file`
        }
        props.deleteAttachmentsForOpportunity(payload)
    };
    /**
    * Closes the quick PDF view by clearing the current item's ID 
    * and disabling the quick PDF view state.
    */
       const handlesQuickPDFClose = () => {
           setId('')
           setQuickPdfView(false)
       }
       /**
    /**
     * Opens the quick PDF view by setting the current item's ID and 
     * enabling the quick PDF view state.
     * @param {Object} item - The item object containing file details, including its _id.
     */
    const handlesQuickPDFOpen = (item) => {
        setId(item['_id'])
        setQuickPdfView(true)
    }

    /**
     * @function handleDownload
     * @description
     * Handles the download of a file based on its type. If the file type is not in the list of supported formats 
     * (PDF and image formats), the function triggers a download action with additional parameters for handling 
     * unsupported file types. If the file type is supported, a simpler download action is triggered.
     * 
     * @param {Event} e - The event object associated with the download action.
     * @param {Object} item - The item containing file details, including the file type.
     * @param {string} item.type - The type of the file to be downloaded.
     * @param {string} item._id - The unique identifier for the item.
     * 
     * @returns {void}
     * 
     * @example
     * // Example usage:
     * handleDownload(event, { type: 'pdf', _id: '12345' });
     */
    const handleDownload = (e, item) => {
        const fileType = item['type'];
        if (!isInArray(fileType, SUPPORTED_FORMATS)) {
            props.downloadContractPDFAction({
                appid: appId,
                _id: item['_id'],
                enableOtherAttachmentUpload: true,
                isDownload: true,
                openFile: false,
                pdfLoadRefresh: false,
                noType: true,
                isNotAFile: true,
                dataModelId: block.mainDataModel,
                field: 'path',
                downloadFileType: fileType,
                modelDataType: 'attachments',
                dataUniqueId: "quickView_Attachments_download"
            });
        } else {
            props.downloadContractPDFAction({
                appid: appId,
                _id: item['_id'],
                isDownload: true,
                openFile: false,
                pdfLoadRefresh: false,
                noType: true,
                dataModelId: block.mainDataModel,
                field: 'path',
                modelDataType: 'attachments',
                dataUniqueId: "quickView_Attachments_download"
            });
        }
    }
    /**
     * Checks if a value is present in a given array.
     * @param {string} value - The value to check.
     * @param {Array<string>} array - The array to check against.
     * @returns {boolean} - True if the value is in the array, false otherwise.
     */
    const isInArray = (value, array) => array.includes(value);
    return (
        <React.Fragment>
            {(props.formData[props.data.chartUniqueId + "_upload_attachments_file_loading"] || props.formData[`${chartUniqueId}_${selectedStep.name}_${block.name}_delete_attachments_file_loading`]) ? props.loader :
                <>
                    <div style={{
                        'display': 'flex',
                        'width': '100%',
                        'justifyContent': 'space-between',
                        'alignItems': 'center'
                    }}>
                        <h3 style={{ 'paddingLeft': '16px', 'paddingTop': '15px' }}>Attachments</h3>
                        <div style={{ 'marginRight': '16px' }} onClick={handleClick}>
                            <Button size="small" style={{
                                cursor: "pointer", backgroundColor: backgroundColor, color: 'white', fontSize: '12px'
                            }}>Add</Button>
                        </div>
                        <input
                            ref={fileInputRef}
                            type="file"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                    </div>
                    <List>
                        {document[`${chartUniqueId}_${selectedStep.name}_${block.name}`] ?
                            document[`${chartUniqueId}_${selectedStep.name}_${block.name}`].map((item, index) => (
                                <ListItem key={index}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        gap: '40px',
                                        width: '100%'
                                    }}>
                                        <ListItemText primary={item.originalName} />
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '20px'
                                        }}>
                                            {SUPPORTED_FORMATS.includes(item?.type) && (
                                                <VisibilityIcon
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => { handlesQuickPDFOpen(item) }}
                                                />
                                            )}
                                            <DeleteIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => { handleDelete(e, item) }}
                                            />
                                            <DownloadIcon
                                                style={{ cursor: "pointer" }} onClick={(e) => { handleDownload(e, item) }}
                                            />
                                        </div>
                                    </div>
                                </ListItem>
                            )) :
                            ''
                        }
                    </List>
                    {quickPdfView &&
                        <ShowAttachmentsModel
                            {...props}
                            open={quickPdfView}
                            formData={props.formData}
                            loader={props.loader}
                            handlesQuickPDFClose={handlesQuickPDFClose}
                            docID={docID}
                            appId={appId}
                            orgId={orgId}
                            dataModelId={block.mainDataModel}
                            subDataModelId={block.dataModel}
                            opportunityId={currentOpportunityId}
                        />
                    }
                </>
            }
        </React.Fragment >
    )
}

export default UploadComponent
