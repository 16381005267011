import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import GetAppIcon from '@material-ui/icons/GetApp';
import ListIcon from '@material-ui/icons/List';
import React, { memo, useState } from "react";
import xlsx from "xlsx";
import { chartHistoryUniqueId, getResponseDataByAIType, supportedHighcharts } from '../../ChatHelper';
import { ChartMenu } from '../../ResponseComponents';
import useStyles from "./styles";

const QuestionActions = (props) => {
    const {
        appId,
        chatId,
        index,
        msg,
        questionType,
        highcartInfo,
        highChartsRef,
        toggleChartModal,
        toggleChartType,
    } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const donwloadChartImage = (type = "") => {
		if (type && highChartsRef && highChartsRef?.current) {
			highChartsRef?.current.downloadChart(type)
		}
		setAnchorEl(null);
	};

    const downloadTableData = (messageIndex) => {
		const { responseData } = getResponseDataByAIType(msg);
		const workbook = xlsx.utils.book_new()
		const ws = xlsx.utils.json_to_sheet(responseData) // aoa_to_json returning empty excel.
		xlsx.utils.book_append_sheet(workbook, ws, "sheet")
		xlsx.writeFile(workbook, `${"dsilo-procuregpt"}.xlsx`, { type: "file" })
	}

	const deleteQuestion = (item) => {
		const payloadData = {
			appId,
			chatId,
			dataUniqueId: `${chatId}_${chartHistoryUniqueId}`,
			payload: {
				questionId: item?.questionId
			}
		}
		props?.deleteQuestionFromChatHistory(payloadData);
	}

    return (
        <Box className={classes.sideIconList} spacing={2}>
            {!["tile", "text", "multiResponse", "simpleList"].includes(questionType) && highcartInfo?.showChartMenu	&& (
                <>
                    <ChartMenu
                        index={index}
                        chartType={questionType}
                        toggleChartType={toggleChartType}
                        {...props}
                    />
                    {supportedHighcharts.indexOf(questionType) !== -1 ? (
                        <Button
                            aria-controls="Highcharts zoomIn"
                            aria-haspopup="true"
                            onClick={toggleChartModal}
                            className={classes.root} >
                            <FullscreenIcon />
                        </Button>
                    ) : null}
                </>
            )}
            {!["text"].includes(questionType) && (
                <Button
                    className={classes.root}
                    onClick={() => { }}
                    aria-label="Settings" 
                >
                    <ListIcon />
                </Button>
            )}
            {supportedHighcharts.includes(questionType) ? (
                <>
                    <Button
                        aria-controls="Download Highcharts"
                        aria-haspopup="true"
                        onClick={handleClick}
                        className={classes.root} >
                        <GetAppIcon />
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => donwloadChartImage()}
                        variant="menu"
                    >
                        <MenuItem onClick={() => donwloadChartImage("image/png")}>
                            Download as PNG
                        </MenuItem>
                        <MenuItem onClick={() => donwloadChartImage("image/jpeg")}>
                            Download as JPEG
                        </MenuItem>
                        <MenuItem onClick={() => donwloadChartImage("application/pdf")}>
                            Download as PDF
                        </MenuItem>
                    </Menu>
                </>
            ) : null}
            {questionType === "table" ? (
                <Tooltip title="Download">
                    <Button
                        className={classes.root}
                        onClick={() => downloadTableData(msg.id)}
                        aria-label="Download Table Data" >
                        <GetAppIcon />
                    </Button>
                </Tooltip>
            ): null}
            {props.chatTabVal !== "all" ? (
                <Tooltip title="Delete prompt">
                    <Button
                        className={classes.root}
                        onClick={() => deleteQuestion(msg)}
                        aria-label="Delete Question"
                    >
                        <DeleteIcon />
                    </Button>
                </Tooltip>
            ) : null}
        </Box>
    );
}

export default memo(QuestionActions);