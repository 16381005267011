import { AnnotationPdfTableWrapper } from "dsilo-ui-components";
import React from "react";
import { hot } from "react-hot-loader/root";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    clearSuccessMsg, downloadContractPDFAction, downloadSheet, getContractDataById, getEventByID, 
    getModelDataDropDown, initiateAction, updateDataModelData,getMembersList, clearReduxDataOfCurrentComponent,getDataModelById
} from '../../store/actions';
import { Loader } from "../loader";

const AnnotationPdfTableViewerComponent = (props) => {

    return <div>
        <AnnotationPdfTableWrapper
            {...props}
            loader={<Loader />}
        />
    </div>
}


const mapDispatchToProps = {
    getContractDataById,
    updateDataModelData,
    clearSuccessMsg,
    downloadSheet,
    getModelDataDropDown,
    downloadContractPDFAction,
    getEventByID,
    initiateAction,
    getMembersList,
    clearReduxDataOfCurrentComponent,
    getDataModelById
};

const mapStateToProps = state => {
    console.log('state.form',state.form)
    return {
        formData: state.form,
        action: state.action,
        appConfig: state.appConfig.app,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(AnnotationPdfTableViewerComponent)));
