import { PDFCompleteViewerWrapper } from "dsilo-ui-components";
import React, { useEffect, useState } from "react";
import { hot } from "react-hot-loader/root";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    downloadContractPDFAction, clearReduxDataOfCurrentComponent, hideSuccessMessage,
    getContractDataById, getDataModels, getAllOtherDocs, getPDFForOtherDoc, getOthersDeletedDoc,otherDocMove, getAuditTrailsData, getOthersAuditTrailsData, clearDataFromLocalDB, moveToDuplicates
} from '../../store/actions';
import { Loader } from "../loader";
import { PUBLIC_PATH } from 'constants/index';

const PDFCompleteViewer = (props) => {
    return (
        <div>
            <PDFCompleteViewerWrapper {...props} loader={<Loader />} publicPath={PUBLIC_PATH} />
        </div>
    )
}

const mapDispatchToProps = {
    downloadContractPDFAction,
    clearReduxDataOfCurrentComponent,
    getContractDataById,
    getDataModels,
    getAllOtherDocs,
    getPDFForOtherDoc,
    getOthersDeletedDoc,
    otherDocMove,
    hideSuccessMessage,
    getAuditTrailsData,
    getOthersAuditTrailsData,
    clearDataFromLocalDB,
    moveToDuplicates
};

const mapStateToProps = state => {
    return {
        formData: state.form,
        dataModels: state.dataModels,
        user: state.user,
        PUBLIC_PATH: PUBLIC_PATH,
        db: state.db
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFCompleteViewer)));