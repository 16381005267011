import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import useStyles from './styles';

const PromptSuggestions = props => {
    const { promptSuggestions } = props;
    const classes = useStyles();
    const [selectedPromptIndex, setSelectedPromptIndex] = useState(null)
    
    useEffect(() => {
        if (selectedPromptIndex !== null) {
            const selectedPrompt = promptSuggestions?.[selectedPromptIndex]
            if (selectedPrompt && Object.keys(selectedPrompt).length) {
                // props.setInputValue(selectedPrompt);
                props.setLoading(true);
                props.updateMessageWithSelectedPrompt(selectedPrompt);
                props?.clearPrompts([])
            }
        }
    }, [selectedPromptIndex]);

    const handlePromtSelection = (selectedIndex) => {
        setSelectedPromptIndex(selectedIndex)
    }

    return (
        <Box className={clsx(classes.promptParentBox, { [classes.justifyCenter]: promptSuggestions?.length === 1 })} >
            {promptSuggestions.map((prompt, index) => (
                <Box 
                    key={index.toString()} 
                    className={clsx(classes.promptChildBox,  {
                        [classes.selectedPrompt]: selectedPromptIndex === index
                    })} 
                    onClick={() => handlePromtSelection(index)}
                >
                    {prompt}
                    {selectedPromptIndex === index && (
                        <CheckCircleIcon style={{ color: 'green', fontSize: 24 }} />
                    )}
                </Box>    
            ))}
        </Box>
    );
};

export default PromptSuggestions;
