import React from "react"
import { getOptionsForDropDownIfDataElementConfiguredWithData, ChipSelect } from 'dsilo-ui-components'

const CustomSelectField = (props) => {
    const { classes, className, element, dataObj, options, onChange, isLoading, commonMasterDataFullDropDownKeys, fullDropDownFetchedList, selectedStep, masterDataStoreObject, membersList } = props

    const { chartUniqueId } = props.data
    let key = element.name || element.value
    let value = typeof (dataObj[key]) === 'string' ? { value: dataObj[key], label: dataObj[key] } : dataObj[key]
    let variant = 'fixed';
    console.log("it is value in select", value)
    if (element.type === 'namepicker' && element?.data?.type === 'members' && value) {
        value = {
            value: value.name,
            label: value.name
        }
    }
    console.log("it is value in select after namepicker condition", value)
    const getOptions = (eachField, rowIndex, commonMasterDataFullDropDownKeys, currentValue) => {
        return getOptionsForDropDownIfDataElementConfiguredWithData(eachField, masterDataStoreObject,
            chartUniqueId, fullDropDownFetchedList, rowIndex, false, commonMasterDataFullDropDownKeys, eachField?.data?.variant, currentValue, { members: membersList })
    }
    return (
        <ChipSelect
            id={`${key}`}
            // label={element.label || element.description}
            isSearchable
            value={value}
            placeholder=''
            isClearable={true}
            menuPlacement='top'
            isDisabled={element.readOnly}
            options={options ? options : getOptions(element, dataObj.guId, commonMasterDataFullDropDownKeys, value?.value)}
            // options={options ? options : []}
            className={classes.selectField}
            textFieldProps={{
                InputLabelProps: {
                    shrink: true
                },
                variant: "outlined",
                disabled: element.readonly
            }}
            variant={variant}
            onChange={(v) => { onChange(key, v?.value, undefined, 'select', element, v) }}
            isLoading={isLoading}
        />
    )
}

export default CustomSelectField