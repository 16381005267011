import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
    Button, Grid, Typography, makeStyles, Box, AppBar, Tabs, Tab, Divider
} from '@material-ui/core'
import 'styles/styles.scss'
import BusinessRuleDetails from './../BusinessRuleDetails'
import { RulesTab } from './../RulesTab'
import { TabPanel } from './@tabpanel/tabpanel'
import RuleQueryBuilder from './../RuleQuery'

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    tabs: {
        borderBottom: '1px solid #d3d3d3',
    },
    select: {
        width: 175,
        height: 45,
        maxWidth: 220,
    },
    formControl: {
        width: 175,
        height: 45,
        marginRight: 5,
        marginBottom: '8px !important',
    },
    container: {
        display: 'flex',
        height: 70,
        overflow: 'auto',
    },
    actionIcon: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start', width: 100,
        marginLeft: 10
    },
    mainContainer: {
        border: '1px solid #cecece',
        width: 'fit-content',
        borderRadius: 4,
        padding: 12,
        marginBottom: 15,
    }
}))

const ViewTabs = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <React.Fragment>
            <Tabs value={value}
                indicatorColor="primary"
                onChange={handleChange}
                className={`${classes.tabs} model-tab-3`}>
                <Tab label="Basic Info" {...a11yProps(0)} />
                <Tab label="Business Rule" {...a11yProps(1)} />
                <Tab label="Rule Query" {...a11yProps(2)} />
            </Tabs>
            <Divider />
            <TabPanel value={value} index={0}>
                <BusinessRuleDetails {...props} classes={classes} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <RulesTab {...props} classes={classes} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <RuleQueryBuilder {...props} classes={classes} />
            </TabPanel>
        </React.Fragment>
    )
}

export default ViewTabs