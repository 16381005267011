import _ from 'modules/lodash'

class Utils {

	static getFilteredArray(data, searchText) {
		if (searchText.length === 0) {
			return data
		}
		return _.filter(data, item => item.name.toLowerCase().includes(searchText.toLowerCase()))
	}

	static generateGUID = () => {
		function S4() {
			return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
		}
		return S4() + S4()
	}

}

export default Utils