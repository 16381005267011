import { call, put, takeEvery } from 'redux-saga/effects';
import { TxnService } from 'modules/api/index';
import { ActionTypes } from 'constants/index';

async function fetchAsync(func, arg) {
  const response = await func(arg);
  return response;
}

function* getTxnDetails(action) {
  try {
    const result = yield call(fetchAsync, TxnService.getTxnDetails, action.payload);
    yield put({ type: ActionTypes.GET_TRANSACTION_DETAILS_SUCCESS, payload: result.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_TRANSACTION_DETAILS_ERROR, error: e.message });
  }
}

function* createTxn(action) {
  const formData = new FormData();
  formData.append('file', action.data.files);

  try {
    const result = yield call(fetchAsync, TxnService.createTxn, {
      formData,
      appId: action.data.appId,
    });
    yield put({ type: ActionTypes.ADD_TRANSACTION_SUCCESS, payload: result.data });
    yield put({ type: ActionTypes.GET_TRANSACTION_DETAILS_LOADING, payload: action.data.appId });
  } catch (e) {
    yield put({ type: ActionTypes.ADD_TRANSACTION_ERROR, error: e.message });
  }
}

function* deleteTxn(action) {
  try {
    const result = yield call(fetchAsync, TxnService.deleteTxn, action.payload);
    yield put({ type: ActionTypes.DELETE_TRANSACTION_SUCCESS, payload: result.data });
    yield put({ type: ActionTypes.GET_TRANSACTION_DETAILS_LOADING, payload: action.payload.appid });
  } catch (e) {
    yield put({ type: ActionTypes.DELETE_TRANSACTION_ERROR, error: e.message });
  }
}
export function* watchTxnSaga() {
  yield takeEvery(ActionTypes.GET_TRANSACTION_DETAILS_LOADING, getTxnDetails);
  yield takeEvery(ActionTypes.ADD_TRANSACTION_LOADING, createTxn);
  yield takeEvery(ActionTypes.DELETE_TRANSACTION_LOADING, deleteTxn);
}

export default watchTxnSaga;
