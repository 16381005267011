import _ from 'modules/lodash';

export function getFilteredArray(data, searchText) {
	if (searchText.length === 0) {
		return data;
	}
	return _.filter(data, item => item.name.toLowerCase().includes(searchText.toLowerCase()));
};

export function selectedRow(event, _id, selected, selectedIndex) {
	let newSelected = [];

	if (selectedIndex === -1) {
		newSelected = newSelected.concat(selected, _id);
	}
	else if (selectedIndex === 0) {
		newSelected = newSelected.concat(selected.slice(1));
	}
	else if (selectedIndex === selected.length - 1) {
		newSelected = newSelected.concat(selected.slice(0, -1));
	}
	else if (selectedIndex > 0) {
		newSelected = newSelected.concat(
			selected.slice(0, selectedIndex),
			selected.slice(selectedIndex + 1)
		);
	}
	return newSelected;
}

export function handleSelectedRow(event, _id, selected) {
	const selectedIndex = selected.indexOf(_id);
	return selectedRow(event, _id, selected, selectedIndex);
}

export function isRowselected(_id, selected) {
	return selected.indexOf(_id) !== -1;
}

export function requestSort(event, property, data) {
	const orderBy = property;
	let order = 'desc';
	if (data.orderBy === property && data.order === 'desc') {
		order = 'asc';
	}
	if (data.orderBy === property && data.order === 'asc') {
		order = 'desc';
	}
	return {
		order,
		orderBy
	};
};

export function handleTableSelectAllClick(event, data) {
	return event.target.checked ? data.map(n => n._id) : [];
};

export function getPageUrl(selectedLinkValue, pages) {
	let page = pages.find(p => {
		return p._id === selectedLinkValue
	})
	return page && page.url;
}

export function numFormatter(num) {
	return (Math.abs(num) > 999999
		? Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)) + 'M'
		: (Math.abs(num) > 999
			? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K'
			: Math.sign(num) * Math.abs(num)))
}

export function getTableHeaderStyles(style, reqStyleArray) {
	let defaultStyle = { backgroundColor: "#000", color: "#FFF", fontSize: "14px", textAlign: "left" }
	let styleObj = {};
	reqStyleArray.map((item) => {
		styleObj[`${item}`] = (style && style[`${item}`]) ? style[`${item}`] : defaultStyle[`${item}`];
	})
	return styleObj;
}

export function getTableRowStyles(style, reqStyleArray, index) {
	let defaultStyle = { fontSize: "14px", color: "#FFF", backgroundColor: "#181818", row1BackgroundColor: "#181818", row2BackgroundColor: "#181818" };
	let styleObj = {};
	reqStyleArray.map((item) => {
		if (item === "backgroundColor") {
			if (style && style.row1BackgroundColor && style.row2BackgroundColor) {
				if (index % 2 === 0) {
					styleObj[`${item}`] = style.row1BackgroundColor;
				} else {
					styleObj[`${item}`] = style.row2BackgroundColor;
				}
			} else {
				styleObj[`${item}`] = (style && style[`${item}`]) ? style[`${item}`] : defaultStyle[`${item}`];
			}

		} else {
			styleObj[`${item}`] = (style && style[`${item}`]) ? style[`${item}`] : defaultStyle[`${item}`];
		}

	})
	return styleObj;
}

export function validateEmail(mail) {
	if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
		return (true)
	}
	return (false)
}

export function validateMobileNumber(number) {
	if (/^\d{10}$/.test(number)) {
		return (true)
	}
	return (false)
}