import React from 'react';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { styled } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

const IButton = styled(IconButton)({
	padding: '8px',
});

const TitleTextField = props => {
    const { title, handleTitleChange, editTitle, updateTitle } = props;
    return (
        <TextField
            id="outlined-name"
            placeholder="Title"
            value={title}
            onChange={handleTitleChange}
            variant="outlined"
            style={{ backgroundColor: '#fff', padding: '10px' }}
            size="small"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Divider orientation="vertical" style={{ height: '40px' }} />
                        <IButton
                            aria-label="Cancel Title Edit"
                            edge="end"
                            onClick={editTitle}
                            style={{ color: 'dimgrey' }}
                        >
                            <CloseIcon fontSize="small" />
                        </IButton>
                        <IButton aria-label="Save Workspace Title" onClick={updateTitle} edge="end">
                            <SaveIcon color="primary" fontSize="small" />
                        </IButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default TitleTextField;