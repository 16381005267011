import { Grid } from "@material-ui/core";
import { Loader } from "../loader";
import { DocumentComparisonTable, VerticalCards } from "dsilo-ui-components";
import React, { useEffect, useState } from "react";
import { hot } from "react-hot-loader/root";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getModelData } from '../../store/actions';

const AdvanceDocComparison = (props) => {

	const [selectedDocuments, setSelected] = useState([]);
	const [dataSource, setDataSource] = useState([])

	useEffect(() => {
		let payload = {
			fields: props.data.chartData.selectedDataElementsForTiles.map(i => i.value).join(','),
			appId: props.match.params.appid,
			id: props.data.chartData.selectedDataModel,
			page: "0", rowsPerPage: "all",
			conditions: props.data.chartData.conditions,
		};
		props.getModelData({ ...payload, dataUniqueId: props.data.chartUniqueId + '_data' });
	}, [])

	useEffect(() => {
		setDataSource(getComparisonData())
	}, [props.formData?.document[props.data.chartUniqueId + '_dataComparison']])

	const onSelectionChange = (selectedDocumentIds) => {
		setSelected(selectedDocumentIds)
		console.log("selectedDocumentIds ===== ", selectedDocumentIds);
		if (selectedDocumentIds?.length > 0) {
			let payload = {
				appId: props.match.params.appid,
				ids: selectedDocumentIds,
				id: props.data.chartData.selectedDataModel,
				page: "0", rowsPerPage: "all",
				conditions: props.data.chartData.conditions,
			};
			props.getModelData({ ...payload, dataUniqueId: props.data.chartUniqueId + '_dataComparison' });
		} else {
			setDataSource([])
		}
	}

	const getComparisonData = () => {
		return props.formData?.document[props.data.chartUniqueId + '_dataComparison']
			? props.formData.document[props.data.chartUniqueId + '_dataComparison'].data
			: []
	}

	const isLoading = () => {
		return props.formData[props.data.chartUniqueId + '_dataComparison_loading']
	}

	const isLoading1 = () => {
		return props.formData[props.data.chartUniqueId + '_data_loading']
	}

	const getCardsData = () => {
		return props.formData?.document[props.data.chartUniqueId + '_data']
			? props.formData.document[props.data.chartUniqueId + '_data'].data
			: []
	}

	return <div>
		<Grid container spacing={2}>
			<Grid item xs={2} style={{ textAlign: 'center' }}>
				{isLoading1() ? <span>Loading...</span> : <VerticalCards onSelectionChange={onSelectionChange} cardsData={getCardsData()} />}
			</Grid>
			<Grid item xs={10}>
				{
					isLoading()
						? <Loader />
						: <DocumentComparisonTable {...props} dataSource={dataSource} />
				}
			</Grid>
		</Grid>
	</div>
}

const mapDispatchToProps = {
	getModelData,
};

const mapStateToProps = state => {
	return {
		formData: state.form,
		action: state.action,
	};
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(AdvanceDocComparison)));
