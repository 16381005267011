import { ActionTypes } from 'constants/index';

const initialState = {
    data: [],
    error: {},
    loading: false,
    singleNLoading: false
};

const notification = function (state = initialState, action) {

    switch (action.type) {
        case ActionTypes.GET_ALL_UNREAD_NOTIFICATIONS: {
            return {
                ...state,
                loading: true
            };
        }
        case ActionTypes.GET_ALL_UNREAD_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload?.data?.map(d => {
                    let sp = d.subProcess.map(s => Object.assign({}, s))
                    return Object.assign({}, d, { subProcess: sp })
                })
            };
        }
        case ActionTypes.GET_ALL_UNREAD_NOTIFICATIONS_FAILURE:
        case ActionTypes.CLEAR_ALL_NOTIFICATIONS_FAILURE: {
            let updatedError = { ...state.error, error: action.payload.error }
            return {
                ...state,
                loading: false,
                error: updatedError,
            }
        }
        case ActionTypes.UPDATE_NOTIFICATIONS: {
            if (action.payload?.newNotification) {
                let allNs = state?.data?.map(d => {
                    return Object.assign({}, d)
                })
                const newNotification = { ...action.payload?.data };
                const filteredList = allNs?.filter(nf => nf?.npId !== newNotification?.npId);
                filteredList.unshift(newNotification);
                return {
                    ...state,
                    data: filteredList
                }
            } else {
                let newNs = state?.data?.map(d => {
                    let sp = d.subProcess.map(s => Object.assign({}, s))
                    return Object.assign({}, d, { subProcess: sp })
                })
                const newSpNotification = { ...action.payload?.data };
                const en = newNs.find((ntf) => ntf?._id === newSpNotification?.processId);
                let subProcess = en.subProcess.map(s => Object.assign({}, s))
                let existingSubProcessIndex = subProcess.findIndex((sp) => sp?._id === newSpNotification?._id)
                if(existingSubProcessIndex > -1) {
                    subProcess[existingSubProcessIndex] = newSpNotification
                } else {
                    subProcess.unshift(newSpNotification);
                }
                if(newSpNotification?.processInfo?.status) {
                    en.status = newSpNotification?.processInfo?.status
                }
                en.updatedAt = newSpNotification?.updatedAt
                en.subProcess = subProcess
                let allNs = newNs.filter((n) => n?._id !== newSpNotification?.processId);
                allNs.unshift(en)
                return {
                    ...state,
                    data: allNs?.map(d => {
                        let sp = d.subProcess.map(s => Object.assign({}, s))
                        return Object.assign({}, d, { subProcess: sp })
                    })
                };

            }
        }
        /*case ActionTypes.UPDATE_NOTIFICATIONS_SUCCESS: {
            const notificationsData = [ ...state.data ];
            const notification = { ...action.payload.data };
            if (action.payload?.replaceExistingNotification) {
                const selectedNotificationIndex = notificationsData.findIndex((n) => n?._id === notification?._id);
                if(selectedNotificationIndex > -1) {
                    notificationsData[selectedNotificationIndex] = notification
                }
            } else {
                notificationsData.push(notification);
            }
            return {
                ...state,
                data: notificationsData,
            };
        }*/
        case ActionTypes.UPDATE_NOTIFICATIONS_FAILURE: {
            let updatedError = { ...state.error, error: action.payload.error }
            return {
                ...state,
                // loading: false,
                error: updatedError,
            }
        }
        case ActionTypes.CLEAR_ALL_NOTIFICATIONS: {
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.CLEAR_ALL_NOTIFICATIONS_SUCCESS:
        case ActionTypes.CLEAR_NOTIFICATION_REDUX_STATE: {
            return initialState
        }
        case ActionTypes.CLEAR_SINGLE_NOTIFICATION:
        case ActionTypes.GET_SINGLE_NOTIFICATION: {
            return {
                ...state, singleNLoading: true
            }
        }
        case ActionTypes.CLEAR_SINGLE_NOTIFICATION_SUCCESS: {
            const processId = action.payload;
            const notificationsData = [...state.data];
            const filteredNotificationsData = notificationsData.filter((n) => n?._id !== processId);
            return {
                ...state,
                singleNLoading: false,
                data: filteredNotificationsData
            }
        }
        case ActionTypes.CLEAR_SINGLE_NOTIFICATION_FAILURE:
        case ActionTypes.GET_SINGLE_NOTIFICATION_FAILURE: {
            let updatedError = { ...state.error, error: action.payload.error }
            return {
                ...state,
                singleNLoading: false,
                error: updatedError,
            }
        }
        case ActionTypes.GET_SINGLE_NOTIFICATION_SUCCESS: {
            const notificationsData = state?.data?.map(d => {
                return Object.assign({}, d)
            });
            const newNotification = { ...action.payload?.data };
            if(newNotification && Object.keys(newNotification).length > 0) {
                notificationsData.unshift(newNotification)
            }
            return {
                ...state,
                data: notificationsData
            }
        }
        default: {
            return state
        }
    }
};

export default notification;