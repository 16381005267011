import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import { ActionService } from '../modules/api/action';
import { toast } from 'react-toastify';

async function fetchAsync(func, arg) {
	const response = arg ? await func(arg) : await func();
	return response;
}

function* initiateAction(action) {
	try {
		const response = yield fetchAsync(ActionService.initiateAction, action.payload);
		toast.info(response?.data?.message?response?.data?.message:'Review completed successfully')
		yield put({ type: ActionTypes.INITIATE_ACTION_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
	} catch (e) {
		toast.error(e?.response?.message?e.response.message:'Unable to complete the requested event/action')
		yield put({ type: ActionTypes.INITIATE_ACTION_FAILURE, payload: { error: e, dataUniqueId: action.payload.dataUniqueId } });
		console.log(e);
	}
}
function* getEventByID(action) {
	try {
		const response = yield fetchAsync(ActionService.getEventByID, action.payload);
		yield put({ type: ActionTypes.GET_EVENT_BY_ID_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
	} catch (e) {
		yield put({ type: ActionTypes.GET_EVENT_BY_ID_FAILURE, payload: { error: e, dataUniqueId: action.payload.dataUniqueId } });
		console.log(e);
	}
}

function* getDataByUrl(action) {
	try {
		const response = yield fetchAsync(ActionService.getDataByUrl, action.payload);
		yield put({ type: ActionTypes.GET_DATA_BY_URL_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
	} catch (e) {
		yield put({ type: ActionTypes.GET_DATA_BY_URL_FAILURE, payload: { error: e, dataUniqueId: action.payload.dataUniqueId } });
		console.log(e);
	}
}

function* saveDataByUrl(action) {
	try {
		const response = yield fetchAsync(ActionService.saveDataByUrl, action.payload);
		yield put({ type: ActionTypes.updateDataByUrlL_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
	} catch (e) {
		yield put({ type: ActionTypes.updateDataByUrlL_FAILURE, payload: { error: e, dataUniqueId: action.payload.dataUniqueId } });
		console.log(e);
	}
}

function* updateDataByUrl(action) {
	try {
		const response = yield fetchAsync(ActionService.updateDataByUrl, action.payload);
		yield put({ type: ActionTypes.UPDATE_DATA_BY_URL_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
	} catch (e) {
		yield put({ type: ActionTypes.UPDATE_DATA_BY_URL_FAILURE, payload: { error: e, dataUniqueId: action.payload.dataUniqueId } });
		console.log(e);
	}
}

function* deleteDatapoint(action) {
	try {
		const response = yield fetchAsync(ActionService.deleteDatapoint, action.payload);
		yield put({ type: ActionTypes.DELETE_DATAPOINT_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
		toast.info(response.data?.message || 'Datapoint deleted successfully')
	} catch (e) {
		yield put({ type: ActionTypes.DELETE_DATAPOINT_FAILURE, payload: { error: e, dataUniqueId: action.payload.dataUniqueId } });
		console.log(e);
	}
}

export function* watchActionSaga() {
	yield takeLatest(ActionTypes.INITIATE_ACTION, initiateAction);
	yield takeEvery(ActionTypes.GET_EVENT_BY_ID, getEventByID);
	yield takeEvery(ActionTypes.GET_DATA_BY_URL, getDataByUrl);
	yield takeEvery(ActionTypes.SAVE_DATA_BY_URL, saveDataByUrl);
	yield takeEvery(ActionTypes.UPDATE_DATA_BY_URL, updateDataByUrl);
	yield takeEvery(ActionTypes.DELETE_DATAPOINT, deleteDatapoint);

}

export default watchActionSaga;
