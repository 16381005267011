import { request } from '../modules/client';
import axios from "axios";
import FileDownload from "js-file-download";

let API_BASE_URL = process.env.REACT_APP_API_BASE_URL_CONTRACT_SERVICE;
const REACT_APP_SEARCH_SERVICE_URL = process.env.REACT_APP_SEARCH_SERVICE_URL
export class ContractsServices {

  static updateBaseUrl(type){
    API_BASE_URL=API_BASE_URL+'/'+type
  }

  static uploadPdf(data) {
    console.log('data-url',data)
    let uri = API_BASE_URL + '/contract/extract/contractinsights';
    if (data.type === 'contractinsights')
      uri = API_BASE_URL + '/contract/extract/contractinsights';
    else if (data.type === 'genericinsights')
      uri = API_BASE_URL + '/contract/extract/genericinsights';
    else if (data.type === 'top10clauses')
      uri = API_BASE_URL + '/contract/extract/clauses';
    else if (data.type === 'tables')
      uri = API_BASE_URL + '/contract/extract/tables';
    else if (data.type === 'keywords')
      uri = API_BASE_URL + '/contract/extract/keywords';
    else if (data.type === 'signature')
      uri = API_BASE_URL + '/contract/extract/signature';
    else if (data.type === 'complaint')
      uri = API_BASE_URL + '/contract/extract/complaint';
    else if (data.type === 'footnotes')
      uri = API_BASE_URL + '/contract/extract/footnotes';

    return axios({
      url: uri,
      method: 'POST',
      data: data.formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'appId': data.appid,
      },
    })
      .then(response => {
        return response;
      })
    // .catch(error => {
    //   return error;
    // });
  }

  static reRun(data) {
    let uri = `${API_BASE_URL}/contract/extract/clauses/rerun/${data.contractid}`;

    if (data.type === 'contractinsights')
      uri = `${API_BASE_URL}/contract/extract/contractinsights/rerun/${data.contractid}`
    else if (data.type === 'genericinsights')
      uri = `${API_BASE_URL}/contract/extract/genericinsights/rerun/${data.contractid}`
    else if (data.type === 'top10clauses')
      uri = `${API_BASE_URL}/contract/extract/clauses/rerun/${data.contractid}`
    else if (data.type === 'tables')
      uri = `${API_BASE_URL}/contract/extract/tables/rerun/${data.contractid}`
    else if (data.type === 'keywords')
      uri = `${API_BASE_URL}/contract/extract/keywords/rerun/${data.contractid}`
    else if (data.type === 'signature')
      uri = `${API_BASE_URL}/contract/extract/signature/rerun/${data.contractid}`
    else if (data.type === 'complaint')
      uri = `${API_BASE_URL}/contract/extract/complaint/rerun/${data.contractid}`

    return axios({
      url: uri,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        'appId': data.appid,
      },
    })
      .then(response => {
        return response;
      })
    // .catch(error => {
    //   return error;
    // });
  }

  static saveAnnotations(data) {
    const uri = API_BASE_URL + '/contract/create/';
    return request(uri, {
      method: 'POST',
      payload: data,
      headers: {
        appId: data.appId,
      },
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  static updateAnnotations(data) {
    const uri = API_BASE_URL + '/contract/';
    return request(uri, {
      method: 'PUT',
      payload: data,
      headers: {
        appId: data.appId,
      },
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  static fetchContracts(appId) {
    const uri = API_BASE_URL + '/contract';

    return request(uri, {
      method: 'GET',
      headers: {
        appId: appId,
      },
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  static fetchContractById(data) {
    const uri = API_BASE_URL + `/contract/${data._id}`;
    return request(uri, {
      method: 'GET',
      headers: {
        appId: data.appid,
      },
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  static downloadContractPDF(data) {
    const uri = API_BASE_URL + `/contract/downloadcontractpdf/${data._id}`;
    return request(uri, {
      method: 'GET',
      headers: {
        appId: data.appid,
      },
    })
      .then(response => {
        if(data.isDownload){
          FileDownload(response, data.filename?data.filename:'contract.pdf');
        }else if(data.openFile){
          data.openFile(response)
        }

        return response;
      })
      .catch(error => {
        return error;
      });
  }

  static deleteContract(data) {
    const uri = API_BASE_URL + '/contract';
    return request(uri, {
      method: 'DELETE',
      payload: { ids: data.ids },
      headers: {
        appId: data.appid,
      },
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  static downloadAnnotations(data) {
    let uri = API_BASE_URL + '/contract/extractdata';
    return request(uri, {
      url: uri,
      method: 'POST',
      responseType: 'blob',
      payload: { ids: data.ids },
      headers: {
        appId: data.appid,
      },
    }).then((response) => {
      FileDownload(response, 'contract.xlsx');
      return response;
    }).catch((error) => {
      return error;
    });
  }

  static getSearchData(data) {
    const uri = REACT_APP_SEARCH_SERVICE_URL + `/elastic/getdata?text=${data.text}`;
    return request(uri, {
      method: 'GET',
      headers: {
        appId: data.appid,
      },
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  static addSearchData(data) {
    const uri = REACT_APP_SEARCH_SERVICE_URL + '/elastic/adddata';
    return request(uri, {
      method: 'POST',
      payload: data,
      headers: {
        appId: data.appId,
      },
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  static downloadTop10ClausesExcel(data) {
    let uri = API_BASE_URL + '/contract/extract/clauses/download';
    return request(uri, {
      url: uri,
      method: 'POST',
      responseType: 'blob',
      payload: { id: data.id },
      headers: {
        appId: data.appid,
      },
    }).then((response) => {
      FileDownload(response, 'top10Clauses.xlsx');
      return response;
    }).catch((error) => {
      return error;
    });
  }


}
