import clsx from 'clsx';
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Typography } from '@material-ui/core'
import Loading from '../../components/Loader/index'
import 'styles/styles.scss'
import CustomCard from './Card'
const APPRENDERER_URL = process.env.REACT_APP_APPRENDERER_URL

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	h: {
		fontSize: '0.9rem'
	},
	p: {
		fontSize: '0.8rem',
		marginBottom:13
	},
	circularBar: {
		color: '#ffff',
		height: 20,
		width: 20,
	},
	actions:{
		textAlign: 'end',
		width: '100%' ,
		position: 'absolute',
		bottom: 15 ,
		right: 10
	},
	template:{
		borderBottom:'3px solid darkred !important'
	},
	appContainer:{
		display: 'flex',
		flexWrap: 'wrap',
	},
	app:{
		margin: 8,
		width: 'calc(25% - 16px)',
		'@media(min-width:1900px)': {
			width: 'calc(20% - 16px)',
		},
	},
	shortDescriptionWidth: {
		width: '47vh !important'
		// minWidth: '25%',
		// width: '100%'
	}
}))


const DappsView = (props) => {
	const [apps, setapps] = useState([])
	const [createData, setCreateData] = useState(null)
	const classes = useStyles()
	const { loading, isTemplate } = props

	useEffect(() => {
		try {
		
				const templates = props.templates.filter((temp => {
					const isInApp = props.apps.find((itm) => {
						if (itm.type === temp.type) {
							return true
						}
						return false;
					})
					return !isInApp;
				}))
				setapps([...props.apps,...templates.map(tmp=>({...tmp,isTemplate:true}))])
				setCreateData(null)
		  

		} catch (err) { }
	


	}, [props.apps, props.templates])

	const createApp = (data) => {
		setCreateData({ ...data })
		props.handleAddNewAppClick(data)
	}
	return (
		<Box>
			<div className="recent-apps" >
				<Grid container className={classes.root} >
					<Box className="pxy20">
						<Grid item xs={12} className="">
							<Typography variant="h4">{props.title}</Typography>
						</Grid>

						<div className="recent-apps-card">
							{apps?.length === 0 ? <Typography variant="h6" style={{ textAlign: 'center', color: '#999' }}>{props.noTitle}</Typography> : null}
							<div className={classes.appContainer}>
								{apps && apps.length > 0 && apps.map((dapp, index) => {
									const overrideWidth =
                    dapp?.description && dapp?.description?.length < 50 && apps.length === 1;
									let appURL = typeof (dapp.appViewURL) !== 'undefined'
										? dapp.appViewURL
										: `${APPRENDERER_URL}/apps/${dapp._id}`
									let isFilter = false;
									if (props.categoryFilterData.length === 0 && props.searchKeyword.length === 0) {
										isFilter = true;
									}

									if ((props.searchKeyword && props.searchKeyword.length > 0 && (dapp.title && (dapp.title.toLowerCase()).includes(props.searchKeyword.toLowerCase()) ||
										(dapp.subTitle && (dapp.subTitle.toLowerCase()).includes(props.searchKeyword.toLowerCase())) || (dapp.description && (dapp.description.toLowerCase()).includes(props.searchKeyword.toLowerCase())) ||
										(dapp.footer && (dapp.footer.toLowerCase()).includes(props.searchKeyword.toLowerCase()))))) {
										isFilter = true
									}

									if (props.categoryFilterData.length > 0) {
										let category = props.categoryFilterData;
										if ((dapp.footer && (dapp.footer.toLowerCase()).includes(category.toLowerCase()))
											|| (dapp.description && (dapp.description.toLowerCase()).includes(category.toLowerCase()))
											|| (dapp.subTitle && (dapp.subTitle.toLowerCase()).includes(category.toLowerCase()))
											|| (dapp.title && (dapp.title.toLowerCase()).includes(category.toLowerCase()))) {
											isFilter = true
										}
									}
									if (isFilter) {
										return (
											<div
												className={clsx(classes.app, {
													[classes.shortDescriptionWidth]: overrideWidth,
												})}
												key={index}
											>
												<CustomCard
													{...props}
													createData={createData}
													createApp={createApp}
													classes={classes}
													dapp={dapp}
													isTemplate={dapp.isTemplate}
												/>
											</div>
										);
									}
								})}
							</div>
						</div>
						{loading && <Loading />}

					</Box>
				</Grid>

			</div>
		</Box>
	)
}

export default DappsView
