import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { CircularProgress } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    borderRadius: '25px',
    border: '2px solid #ebedf2',
    background: '#ffffff'
  },
  
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  circularBar: {
    height: 165,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noDataCell:{
		textAlign:'center',
		borderBottom: 'none !important',
		fontWeight:900,
		fontSize:'large'
	  },
}));

const  VendorTabel = (props) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {

      const newSelecteds = props.data?.map((n) => n.vendorName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;


  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };


  if (props.loading) {
    return <div className={classes.circularBar}><CircularProgress /></div>
}



  return (
    <Paper elevation={2} className={classes.root}>
        <h3 style={{padding:"22px"}}  >{'Vendor'}</h3>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < props.data?.length}
                  checked={props.data?.length > 0 && selected.length === props.data?.length}
                  onChange={handleSelectAllClick}
                  inputProps={{ 'aria-label': 'select all desserts' }}
                />
              </TableCell>
                <TableCell style={{color:'grey'}} align="left">{'Vendor'}</TableCell>
                <TableCell style={{color:'grey'}} align="left">{'Confidence'}</TableCell>
                <TableCell style={{color:'grey'}} align="left">{'No: of unique products by vendor'}</TableCell>
                <TableCell style={{color:'grey'}} align="left">{'Invoice Total'}</TableCell>
                <TableCell style={{color:'grey'}} align="left">{'Proccessed invoices'}</TableCell>
                <TableCell style={{color:'grey'}} align="left">{'Quality Review'}</TableCell>
                <TableCell style={{color:'grey'}} align="left">{'Unproccessed invoices'}</TableCell>
                {/* <TableCell style={{color:'grey'}} align="left">{'Accuracy'}</TableCell> */}
              </TableRow>

            </TableHead>

                {!props.data && <TableBody>  
                      <TableRow>
										<TableCell colSpan='100%' className={classes.noDataCell}>
											{'No data available'}
										</TableCell>
									</TableRow>
								</TableBody> }
            <TableBody>
              {
                props.data && props.data.map((row,index)=>{
                  const isItemSelected = isSelected(row.vendorName);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return(
                    <TableRow
                      role="checkbox"
                      aria-checked={isItemSelected}
                      onClick={(event) => handleClick(event, row.vendorName)}
                      tabIndex={-1}
                      key={row.vendorName}
                      selected={isItemSelected}
                      >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none" align="left">
                        {row.vendorName}
                      </TableCell>
                      <TableCell align="left">{row.confidence}</TableCell>
                      <TableCell align="left">{row.unique_items??0}</TableCell>
                      <TableCell align="left">{row.invoiceTotal?row.invoiceTotal.toFixed(2):0}</TableCell>
                      <TableCell align="left">{row.Processed}</TableCell>
                      <TableCell align="left">{row.qualityReview??0}</TableCell>
                      <TableCell align="left">{row.Unprocessed}</TableCell>
                      {/* <TableCell align="left">{row.accuracy}</TableCell> */}
                  </TableRow>
                  )
                      
                }
                  
                  
                  )
              }
            </TableBody>
          </Table>
        </TableContainer>
    </Paper>
  );
}

export default VendorTabel