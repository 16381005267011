import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { InputLabel, TextField } from '@material-ui/core';
import moment from 'moment';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import { getModel } from 'store/actions';
import { Loader } from "../../../components/loader";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        // padding: '94px',
        textAlign: 'center',
        minHeight: '60px',
        backgroundColor: 'white',
        // fontWeight: 'bold',
        // paddingLeft: '419px'

    },
    inputLabel1: {
        color: 'black',
        fontSize: '18px',
        padding: '10px',
        textAlign: 'left'
    },
    commonIcon: {
        fontSize: 18,
        verticalAlign: 'text-bottom',
    },
    statusInfoCompleted: {
        display: 'inline-block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 119,
        color: '#7AD0A2',
        backgroundColor: '#EBF8F1',
        padding: '5px 10px',
        borderRadius: '5px',
        marginTop: '3px',
        fontSize: 14,
        // marginLeft: '-61px'
    },
}));


const ModelDetailsWrapper = (props) => {
    const classes = useStyles();

    useEffect(() => {
        console.log("props.match.params ===", props.match.params);
        let d = {
            appId: props.match.params.appid,
            id: props.match.params.id
        }
        props.getModel(d)
    }, [])

    const { model } = props.model;

    if (props.model.loading) {
        return <Loader />
    }

    const Detail = (props) => <Grid container>
        <Grid item xs={6}>
            <InputLabel className={classes.inputLabel1} style={{ color: model[0].color, fontWeight: 'bold' }}>
                {props.label}
            </InputLabel>
        </Grid>
        <Grid item xs={6}>
            <InputLabel className={classes.inputLabel1} style={{ color: model[0].color }}>
                {props.label.toLowerCase() === 'status'
                    ? <span className={classes.statusInfoCompleted}>
                        <CheckCircleOutlineOutlinedIcon className={classes.commonIcon} />
                        {model[0].status}
                    </span>
                    : props.value
                }
            </InputLabel>
        </Grid>
    </Grid>

    return (
        <div className={classes.root}>
            <div className={classes.paper} >
                {model?.length ?
                    <Grid container style={{ borderRadius: 10, border: '1px solid #cecece', padding: '25px 10px' }}>
                        <Grid item xs={6} sm={6} style={{ borderRight: '1px solid #cecece' }}>
                            <Detail label="Name" value={model[0].name} />
                            <Detail label="Description" value={model[0].description} />
                            <Detail label="Model Id" value={model[0].modelId} />
                            <Detail label="Model Location" value={model[0].modelLocation} />
                            <Detail label="Model EndPoint" value={model[0].modelEndPoint} />
                            <Detail label="Model Version" value={model[0].modelVersion} />
                        </Grid>
                        <Grid item xs={6} sm={6} style={{ paddingLeft: '20px' }}>
                            <Detail label="Model Owner" value={model[0].modelOwner} />
                            <Detail label="Created By" value={model[0].createdBy} />
                            <Detail label="Created Date" value={moment(model[0].createdDate).format('ll')} />
                            <Detail label="Updated By" value={model[0].updatedBy} />
                            <Detail label="Updated Date" value={moment(model[0].updatedDate).format('ll')} />
                            <Detail label="Status" value={model[0].status} />
                        </Grid>
                    </Grid>
                    : null}
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    getModel,
};

const mapStateToProps = state => {
    return {
        model: state.model,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ModelDetailsWrapper)));