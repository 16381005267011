import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import GetIconByStatus from './GetIconByStatus';

const NotificationHeader = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center'
}));

const NotificationTile = (props) => {
	const { handleOnClear, notification } = props;
	return (
		<NotificationHeader>
			<Grid container spacing={1} alignItems='center'>
				<Grid item xs={1}>
					<GetIconByStatus status={notification?.status} />
				</Grid>
				<Grid item xs={9} style={{ marginLeft: -5 }}>
					<Typography
						variant="body1" 
						component="p" 
						color='primary' 
						style={{ 
							fontSize: 13, 
							fontWeight: 'bold',
							lineBreak: 'anywhere'
						}}
					>
						{notification?.message}
					</Typography>
				</Grid>
				<Grid item xs={2} style={{ textAlign: 'end' }}>
					<IconButton onClick={handleOnClear} style={{ padding: '4px' }}>
						<ClearIcon fontSize='small' style={{ fontSize: 16 }} />
					</IconButton>
				</Grid>
			</Grid>
		</NotificationHeader>
	);
}

export default NotificationTile;