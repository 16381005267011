import { Button, makeStyles } from '@material-ui/core';
import React from 'react';

const CustomButton = props => {
  const classes = useStyles();
  return (
    <Button
      className={classes.button}
      disableElevation
      variant={props.variant}
      color={props.color}
      {...props}
    >
      {props.children}
    </Button>
  );
};

const useStyles = makeStyles({
  button: {
    textTransform: 'capitalize',
    // fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 6,
  },
  disableButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
});
export default CustomButton;
