import React from 'react'

import {
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, Tooltip, Checkbox, TableSortLabel,
} from '@material-ui/core'
import PropTypes from 'prop-types';

import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from "@material-ui/icons/Visibility"
import DeleteIcon from '@material-ui/icons/Delete';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import { Helpers } from "dsilo-ui-components";

function EnhancedTableHead(props) {
    const { classes, enableActions, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, tableHeaders, showCheckbox } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {showCheckbox && <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>}
                {tableHeaders?.map((header, lIndex) => (
                    <TableCell
                        key={lIndex}
                        // className={classes.tableHeaderCell}
                        title={header.label || header.description}
                        style={{ minWidth: "200px" }}
                    >
                        {header.label || header.description}
                    </TableCell>
                ))}
                {enableActions && <TableCell
                    key={'actionsColumn'}
                    className={classes.tableHeaderCell}
                    title={'Actions'}
                >
                    {'Actions'}
                </TableCell>}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const TableView = (props) => {
    const { classes, enableActions, tableHeaders, tableRows, tableActions, isLoading, loader, onTableActionClick, showCheckbox, selected, handleClick, handleSelectAllClick, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, keyName } = props

    const isSelected = (name) => selected ? selected.indexOf(name) !== -1 : false;

    return (
        <Paper className={classes.root} key={keyName}>
            <TableContainer>
                {console.log("selected  ==>>>>>", selected)}
                <Table
                    size={'small'}
                    aria-labelledby='tableTitle'
                    // aria-label='enhanced table'
                    stickyHeader aria-label="sticky table"
                >
                    <EnhancedTableHead
                        classes={classes}
                        numSelected={selected?.length}
                        // order={order}
                        // orderBy={orderBy}
                        enableActions={enableActions}
                        onSelectAllClick={handleSelectAllClick}
                        // onRequestSort={handleRequestSort}
                        rowCount={tableRows?.length}
                        showCheckbox={showCheckbox}
                        tableHeaders={tableHeaders}
                    />

                    <TableBody>
                        {isLoading ? loader : tableRows?.length > 0 ?
                            (
                                tableRows.map((row, rIndex) => {
                                    const isItemSelected = isSelected(row.originalName);
                                    const labelId = `enhanced-table-checkbox-${rIndex}`;

                                    return (<TableRow
                                        hover
                                        onClick={(event) => showCheckbox ? handleClick(event, row.originalName) : null}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        selected={isItemSelected}
                                        key={rIndex}
                                    // className={classes.tableHeadCellPadding}
                                    >
                                        {showCheckbox && <TableCell padding="checkbox" style={{ borderBottom: 'none' }}>
                                            <Checkbox
                                                checked={isItemSelected}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </TableCell>}
                                        {tableHeaders?.map((header, index) => {
                                            const colField = header.name || header.value
                                            // const colValue = row[colField] || '-'
                                            // const colValue = Helpers.convertValueWithDataType(row[header.name || header.value], header) || '-'
                                            const colValue = Helpers.generateValueByDataElementType(row, header) || '-'

                                            return (
                                                <TableCell
                                                    key={index}
                                                    // className={classes.tableCellWrapText}
                                                    style={{
                                                        padding: '0px !important',
                                                        // ...(index !== 0 && {
                                                        //     maxWidth: '100px'
                                                        // }),
                                                        borderBottom: 'none'
                                                    }}
                                                >
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Typography
                                                            variant='body1'
                                                            component='span'
                                                            // noWrap
                                                            title={colValue}
                                                            style={{ fontSize: '14px' }}
                                                        >
                                                            {colValue}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                            )
                                        })}
                                        {
                                            enableActions && <TableCell
                                                key={`actions-${rIndex}`}
                                                style={{ borderBottom: 'none' }}
                                            >
                                                <div className={classes.tableActions}>
                                                    {
                                                        tableActions.edit && <Tooltip title='Edit'>
                                                            <EditIcon
                                                                fontSize={'small'}
                                                                className={classes.actionIcon}
                                                                onClick={() => onTableActionClick('edit', row)}
                                                            />
                                                        </Tooltip>
                                                    }
                                                    {tableActions.view.enable && (
                                                        <Tooltip title='View'>
                                                            <VisibilityIcon
                                                                fontSize={'small'}
                                                                className={classes.actionIcon}
                                                                onClick={() => onTableActionClick('view', row, tableActions.view.url)}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                    {
                                                        tableActions.delete && <Tooltip title='Delete'>
                                                            <DeleteIcon
                                                                fontSize={'small'}
                                                                className={classes.actionIcon}
                                                                onClick={() => onTableActionClick('delete', row)}
                                                            />
                                                        </Tooltip>
                                                    }
                                                    {
                                                        tableActions.forward && <Tooltip title='Forward To'>
                                                            <SupervisorAccountIcon
                                                                fontSize={'small'}
                                                                className={classes.actionIcon}
                                                                onClick={() => onTableActionClick('forward', row)}
                                                            />
                                                        </Tooltip>
                                                    }
                                                </div>
                                            </TableCell>
                                        }
                                    </TableRow>)
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={tableHeaders?.length + 1}>
                                    <Typography align='center'>
                                        No Data to Display
                                    </Typography>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {tableRows && tableRows.length > 10 ? <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={tableRows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> : null}
        </Paper>
    )
}

export default TableView