/**
 * @module Sagas/Org
 * @desc Org
 */
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { AppService, OrgService } from 'modules/api/index';
import { ActionTypes,PUBLIC_PATH } from 'constants/index';
import jwtService from 'jwtService';
import { CryptoHelper, history } from 'dsilo-ui-components';



async function fetchAsync(func, arg) {
  const response = await func(arg);
  return response;
}

function* createOrg(action) {
  try {
    const org = yield call(fetchAsync, OrgService.createOrg, action.payload);
    console.log('create org saga', org);

    const { appId, orgId } = org.data;
    const userData = JSON.parse(localStorage.getItem('jwtuserData'));
    const updatedUserData = { ...userData, appId, orgId };
    localStorage.setItem('jwtuserData', JSON.stringify(updatedUserData));
    yield put({ type: ActionTypes.CREATE_ORG_SUCCESS, payload: org });
    history.push(`/${PUBLIC_PATH}apps`);
  } catch (e) {
    yield put({ type: ActionTypes.CREATE_ORG_ERROR, error: e.message });
  }
}

function* getOrgDetails(action) {
  try {
    const org = yield call(fetchAsync, OrgService.getOrgDetails, action.payload);
    let orgConfig = { ...org.data };
    if (orgConfig?.authConfig && typeof orgConfig?.authConfig === 'string' && orgConfig?.iv) {
      yield new Promise(async (resolve) => {
        const secretKey = process.env.REACT_APP_CRYPT_SECRET_KEY || '';
        const decryptedConfig = await CryptoHelper.decodeData(orgConfig?.authConfig, orgConfig?.iv, secretKey)
        if (decryptedConfig) {
          const parsedConfig = JSON.parse(decryptedConfig);
          orgConfig = {
            ...orgConfig,
            authConfig: parsedConfig
          }
        }
        resolve();
      });
    }
    yield put({ type: ActionTypes.GET_ORG_DETAILS_SUCCESS, payload: orgConfig });
  } catch (e) {
    yield put({ type: ActionTypes.GET_ORG_DETAILS_ERROR, error: e.message });
  }
}

function* addMember(action) {
  try {
    const org = yield call(fetchAsync, OrgService.addMember, action.payload);
    console.log(' org -------', org);
    const { appId, orgId } = action.payload;
    const userData = JSON.parse(localStorage.getItem('jwtuserData'));
    const updatedUserData = { ...userData, appId, orgId };
    console.log('--Updateduserdata', updatedUserData);
    localStorage.setItem('jwtuserData', JSON.stringify(updatedUserData));
    yield put({ type: ActionTypes.ADD_ORG_MEMBER_SUCCESS, payload: org.data });
    history.push(`/${PUBLIC_PATH}apps`);
  } catch (e) {
    yield put({ type: ActionTypes.ADD_ORG_MEMBER_ERROR, error: e.message });
  }
}

function*  updateOrg(action) {
  try {
   
    const formData = new FormData();
    const keys=Object.keys(action.payload)
    keys.forEach(key=>{
      if(key==='logo'){
        if (action.payload[key].length) {
          for (const file of action.payload[key]) {
            formData.append('file', file);
          }
        } else {
          formData.append('file', action.payload[key]);
        }
      }else if(key==='_id'){
        formData.append('id',action.payload[key])
        formData.append('_id',action.payload[key])
      }
      else{
        formData.append(key,action.payload[key])
      }
     
    }) 
    const org = yield call(fetchAsync, OrgService.updateOrg, {formData,appId:action.payload.appId}); 
    yield put({ type: ActionTypes.GET_ORG_DETAILS_SUCCESS, payload: org.data.data });
    yield put({ type: ActionTypes.UPDATE_ORG_SUCCESS, payload: {message:"Organization Updated Successfully!"} });
  } catch (e) {      
     let err={}
      try{err= e.response.data;}catch(e){err={message:"Something Went Wrong!"} }
    yield put({ type: ActionTypes.UPDATE_ORG_ERROR, error: err });
  }
}

function* getOrgLogo(action) {
  try {
    const  data  = yield call(fetchAsync, OrgService.getOrgLogo, action.payload);

    const blob = new Blob( [ data.data ] );
    const src = URL.createObjectURL( blob );
    yield put({ type: ActionTypes.GET_ORG_LOGO_SUCCESS, payload:src});
  } catch (e) {
  
  }
}
export function* watchOrgSaga() {
  yield takeEvery(ActionTypes.CREATE_ORG_LOADING, createOrg);
  yield takeEvery(ActionTypes.GET_ORG_DETAILS_LOADING, getOrgDetails);
  yield takeEvery(ActionTypes.ADD_ORG_MEMBER_LOADING, addMember);
  yield takeEvery(ActionTypes.UPDATE_ORG_LOADING, updateOrg);
  yield takeEvery(ActionTypes.GET_ORG_LOGO, getOrgLogo);
  //   yield takeEvery(ActionTypes.LOAD_ORG_LOADING, fetchOrg);
}

export default watchOrgSaga;
