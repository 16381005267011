import { put, takeLatest } from 'redux-saga/effects';
import { ExtractionResultServices } from 'modules/api/index';
import { ActionTypes } from 'constants/index';
import { ContractsServices } from '../services/contracts';
import { downloadAnnotations } from '../store/actions/extractionResult';
import {history} from 'dsilo-ui-components';  
import {PUBLIC_PATH} from 'constants/index';

async function fetchAsync(func, arg) {
  const response = arg ? await func(arg) : await func();
  return response;
}

function* fetchPdfData(action) {
  const formData = new FormData();
  console.log(action, 'here is ac');
  formData.append('file', action.file);
  try {
    const appConfig = yield fetchAsync(ExtractionResultServices.uploadPdf, formData);
    console.log(appConfig, 'appcon');
  } catch (err) {
    console.log(err);
  }
}

function* uploadPdfData(action) {
  const formData = new FormData();
    console.log('FormData', action.data.pdf.length);
  if (action.data.pdf.length) {
    for (const file of action.data.pdf) {
      formData.append('file', file);
    }
  } else {
    formData.append('file', action.data.pdf);
  }

  try {
    const uploadPDFResponse = yield fetchAsync(ContractsServices.uploadPdf, {
      formData,
      type: action.data.type,
      appid: action.data.appid,
    });
    console.log('uploadPDFResponse', typeof uploadPDFResponse);
    yield put({ type: ActionTypes.UPLOAD_CONTRACT_SUCCESS, payload: uploadPDFResponse?.data?.data });
    console.log('Upload PDF reseponse', uploadPDFResponse?.data?.data);
  } catch (e) {
    let err={}
    try{err= e.response.data;}catch(e){err={message:"Something Went Wrong!"} }
    yield put({ type: ActionTypes.UPLOAD_CONTRACT_ERROR, payload: err });
    if(err && err.status===403)
    history.push({ pathname: `/${PUBLIC_PATH}${action.data.appid}/contact-dsilo` });
    console.log(err);
  }
}

function* saveAnnotations(action) {
  try {
    const response = yield fetchAsync(ContractsServices.saveAnnotations, action.data);
    yield put({ type: ActionTypes.SAVE_ANNOTATIONS_SUCCESS, payload: response.data });
    console.log('saving annotations', response.data);
  } catch (err) {
    console.log(err);
    yield put({ type: ActionTypes.SAVE_ANNOTATIONS_ERROR, payload: null });
  }
}

function* updateAnnotations(action) {
  try {
    const updateAnnotationsResponse = yield fetchAsync(
      ContractsServices.updateAnnotations,
      action.data,
    );
    yield put({
      type: ActionTypes.UPDATE_ANNOTATIONS_SUCCESS,
      payload: updateAnnotationsResponse.data,
    });
    console.log('updating annotations', updateAnnotationsResponse);
  } catch (err) {
    console.log(err);
  }
}
// without showing success
function* updateAnnotations2(action) {
  try {
    const updateAnnotationsResponse = yield fetchAsync(
      ContractsServices.updateAnnotations,
      action.data,
    );
    yield put({
      type: ActionTypes.UPDATE_ANNOTATIONS_WITHOUT_LOADER_SUCCESS,
      payload: updateAnnotationsResponse.data,
    });
  } catch (e) {
    let err={}
    try{err= e.response.data;}catch(e){err={message:"Something Went Wrong!"} }
    yield put({ type: ActionTypes.UPDATE_ANNOTATIONS_WITHOUT_LOADER_ERROR, payload: err });
    console.log(err);
  }
}

function* getAllContracts(action) {
  try {
    const contractList = yield fetchAsync(ContractsServices.fetchContracts, action.data);
    yield put({ type: ActionTypes.GET_CONTRACT_LIST_SUCCESS, payload: contractList.data });
  } catch (err) {
    yield put({ type: ActionTypes.GET_CONTRACT_LIST_ERROR, payload: [] });
    console.log(err);
  }
}

function* getContractById(action) {
  try {
    const contract = yield fetchAsync(ContractsServices.fetchContractById, action.data);
    yield put({ type: ActionTypes.GET_CONTRACT_BY_ID_SUCCESS, payload: contract.data });
  } catch (err) {
    yield put({ type: ActionTypes.GET_CONTRACT_BY_ID_ERROR, payload: null });
    console.log(err);
  }
}

function* downloadContractPDF(action) {
  try {
    const pdf = yield fetchAsync(ContractsServices.downloadContractPDF, action.data);
    yield put({ type: ActionTypes.DOWNLOAD_CONTRACT_PDF_SUCCESS, payload: pdf });
  } catch (err) {
    console.log(err);
  }
}

function* deleteContract(action) {
  try {
    const response = yield fetchAsync(ContractsServices.deleteContract, action.data);
    yield put({ type: ActionTypes.DELETE_CONTRACT_SUCCESS, payload: response.data });
  } catch (err) {
    yield put({ type: ActionTypes.DELETE_CONTRACT_ERROR, payload: null });
    console.log(err);
  }
}

function* deleteUploadedContractFromBulkUpload(action) {
  try {
    const response = yield fetchAsync(ContractsServices.deleteContract, action.data);
    yield put({ type: ActionTypes.DELETE_UPLOADED_CONTRACT_SUCCESS, payload: response.data });
  } catch (err) {
    yield put({ type: ActionTypes.DELETE_UPLOADED_CONTRACT_ERROR, payload: null });
    console.log(err);
  }
}

function* downloadContractsAnnotations(action) {
  try {
    const response = yield fetchAsync(ContractsServices.downloadAnnotations, action.data);
    yield put({ type: ActionTypes.DOWNLOAD_ANNOTATIONS_SUCCESS, payload: response.data });
  } catch (err) {
    yield put({ type: ActionTypes.DOWNLOAD_ANNOTATIONS_ERROR, payload: null });
    console.log(err);
  }
}

function* getSearchData(action) {
  try {
    const response = yield fetchAsync(ContractsServices.getSearchData, action.data);
    yield put({ type: ActionTypes.SEARCH_DATA_SUCCESS, payload: response.data });
  } catch (err) {
    yield put({ type: ActionTypes.SEARCH_DATA_ERROR, payload: null });
    console.log(err);
  }
}

function* addSearchData(action) {
  try {
    const response = yield fetchAsync(ContractsServices.addSearchData, action.data);
    yield put({ type: ActionTypes.ADD_SEARCH_DATA_SUCCESS, payload: response.data });
  } catch (err) {
    yield put({ type: ActionTypes.ADD_SEARCH_DATA_ERROR, payload: null });
    console.log(err);
  }
}

function* reRun (action) {
  try {
    const response = yield fetchAsync(ContractsServices.reRun, action.data);
    yield put({ type: ActionTypes.RERUN_AI_ITEM_SUCCESS, payload: {data:response.data,type:action.data.type}});
  } catch (err) {
    yield put({ type: ActionTypes.RERUN_AI_ITEM_ERROR, payload: null });
    console.log(err);
  }
}

function* downloadTop10ClausesExcel(action) {
  try {
    const response = yield fetchAsync(ContractsServices.downloadTop10ClausesExcel, action.data);
    yield put({ type: ActionTypes.DOWNLOAD_TOP10CLAUSES_EXCEL_SUCCESS, payload: response.data });
  } catch (err) {
    yield put({ type: ActionTypes.DOWNLOAD_TOP10CLAUSES_EXCEL_ERROR, payload: null });
    console.log(err);
  }
}

export function* extractionSaga() {
  yield takeLatest(ActionTypes.LOAD_PDF, fetchPdfData);
  yield takeLatest(ActionTypes.UPLOAD_CONTRACT_PDF, uploadPdfData);
  yield takeLatest(ActionTypes.SAVE_ANNOTATIONS, saveAnnotations);
  yield takeLatest(ActionTypes.UPDATE_ANNOTATIONS, updateAnnotations);
  yield takeLatest(ActionTypes.UPDATE_ANNOTATIONS_WITHOUT_LOADER, updateAnnotations2);
  yield takeLatest(ActionTypes.GET_ALL_CONTRACTS, getAllContracts);
  yield takeLatest(ActionTypes.DOWNLOAD_CONTRACT_PDF, downloadContractPDF);
  yield takeLatest(ActionTypes.GET_CONTRACT_BY_ID, getContractById);
  yield takeLatest(ActionTypes.DELETE_CONTRACT, deleteContract);
  yield takeLatest(ActionTypes.DELETE_UPLOADED_CONTRACT, deleteUploadedContractFromBulkUpload);
  yield takeLatest(ActionTypes.DOWNLOAD_ANNOTATIONS, downloadContractsAnnotations);
  yield takeLatest(ActionTypes.SEARCH_DATA_LOADING, getSearchData);
  yield takeLatest(ActionTypes.ADD_SEARCH_DATA_LOADING, addSearchData);
  yield takeLatest(ActionTypes.RERUN_AI_ITEM, reRun);
  yield takeLatest(ActionTypes.DOWNLOAD_TOP10CLAUSES_EXCEL, downloadTop10ClausesExcel);
}

export default extractionSaga;
