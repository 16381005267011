import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { Helpers } from 'dsilo-ui-components';
import Grid from '@material-ui/core/Grid';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import { Loader } from '../../../loader';
import { isStringArray, roundOffToTwoDecimalPlaces } from '../../ChatHelper';
import useStyles from './styles';

const rowsPerPageOptions = [10, 25, 50, 100];

const SimpleTile = ({ data, message }) => {
	const classes = useStyles();
	// const [displayText, setDisplayText] = useState('');
	const [tileData, setTileData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(0);
  	const [rowsPerPage, setRowsPerPage] = useState(10);

	useEffect(() => {
		if (Object.keys(data).length) {
			setTileData(Object.entries(data));
		}
		setLoading(false);
	}, [data]);

	const checkAndFormat = (value) => {
		let formattedValue = value;
		if (formattedValue !== null && formattedValue !== undefined) {
			switch (true) {
				case typeof formattedValue === 'string' && formattedValue.includes("$"): {
					// formattedValue = formattedValue.replace("$", "");
					// if (formattedValue && !isNaN(formattedValue)) {
					// 	formattedValue = `$${Helpers.numFormatter(parseFloat(formattedValue))}`
					// }
					return formattedValue;
				}
				case typeof formattedValue === 'number': {
					formattedValue = roundOffToTwoDecimalPlaces(formattedValue);
					return formattedValue;
				}
				case isStringArray(formattedValue): {
					return formattedValue.join(', ');
				}
				case typeof formattedValue === 'string' && formattedValue.toLowerCase() === 'nan': {
					return '-';
				}
				default:
					return formattedValue
			}
		}
		return formattedValue;
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const tileResponseData = useMemo(() => {
		if (tileData && tileData?.length > 10) {
			const sliceTileData = [ ...tileData ];
			sliceTileData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
			return sliceTileData;
		}
		return tileData;
	}, [tileData, page, rowsPerPage]);

	return (
		<Grid item xs={12} className={classes.grid}>
			<div style={{ display: 'grid' }}>
				{/* <span className={classes.paperLabel}>{displayText}</span> */}
				{loading ? (
					<Loader />
				) : (
					<>
						{Array.isArray(tileData) && tileData.length === 1 && (
							<span key={'tileCount'} className={classes.paperValue}>
								{tileData[0][1] && !isNaN(tileData[0][1])
								? Helpers.convertToNumber(tileData[0][1], 2)?.toLocaleString()
								: tileData[0][1] !== null && tileData[0][1] !== undefined && tileData[0][1] !== ''
								? checkAndFormat(tileData[0][1])
								: '-'}
							</span>
						)}
						{Array.isArray(tileResponseData) && tileResponseData.length > 1 ? (
							<Grid container className={classes.gridContainer}>
								{tileResponseData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((td, index) => {
									const tileValue = td[1] && !isNaN(td[1])
										? Helpers.convertToNumber(td[1], 2)?.toLocaleString()
										: td[1] !== null && td[1] !== undefined
										? checkAndFormat(td[1])
										: '-';
									return (
										<Fragment key={index}>
											<Grid item xs={6} className={classes.gridItem}>
												<Typography variant='body1' component='span'>{Helpers.camelCaseToSentenceCase(td[0])} </Typography>
											</Grid>
											<Grid item xs={6} className={classes.gridItem}>
												<Typography 
													variant='subtitle2' 
													component='span' 
													className={classes.paperValue} 
													style={{ marginLeft: '8px', wordBreak: 'break-word' }}
												>
													{' '}
													{tileValue}
												</Typography>
											</Grid>
										</Fragment>
									)
								})}
								{tileData?.length > 10 && <TablePagination
									rowsPerPageOptions={rowsPerPageOptions}
									component='div'
									count={tileData?.length || 0}
									style={{ color: "rgba(0, 0, 0, 0.87)" }}
									rowsPerPage={Number(rowsPerPage)}
									page={page}
									backIconButtonProps={{
										"aria-label": "Previous Page"
									}}
									nextIconButtonProps={{
										"aria-label": "Next Page"
										// 'marginTop': '-18px'
									}}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>}
							</Grid>
						) : null}
					</>
				)}
			</div>
		</Grid>
	);
};

export default SimpleTile;
