import { ActionTypes } from "../../constants";

export const getLabelBoxData = (data) => dispatch => {
    dispatch({ type: ActionTypes.GET_LABELBOX_DATA, payload: data });
}

export const getPieChartData = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_PIECHART_DATA, payload: data });
};

export const clearReduxChartDataOfCurrentComponent = (data) => dispatch => {
    dispatch({ type: ActionTypes.CLEAR_REDUX_CHART_DATA_OF_COMPONENT, payload: data });
  }
export const getReduxExternalFilter = (data) => dispatch => {
    dispatch({ type: ActionTypes.GET_REDUX_EXTERNAL_FILTER, payload: data });
  }