import { ActionTypes } from 'constants/index';

const initialState = {
	success: false,
	isLoading: false,
	error: '',
	token: '',
	user: null,
	reset: {
		isLoading: false,
		success: false,
		user: {},
		error: ''
	},
	forgot: {
		isLoading: false,
		success: false,
		user: {},
		error: ''
	},
	verifyUser: {
		isLoading: false,
		success: false,
		error: ''
	},
	verifyEmail: {
		isLoading: false,
		success: false,
		error: ''
	},
	resendVerifyEmail: {
		isLoading: false,
		success: false,
		error: ''
	}
};

const login = function (state = initialState, action) {

	switch (action.type) {

		case ActionTypes.LOGIN_LOADING: {
			return {
				...state,
				isLoading: true,
				error: '',
			};
		}

		case ActionTypes.LOGIN_SUCCESS: {
			return {
				success: true,
				isLoading: false,
				user: action.payload,
			};
		}

		case ActionTypes.LOGIN_ERROR: {
			return {
				success: false,
				error: action?.error,
				isLoading: false,
			};
		}
		case ActionTypes.CHECK_EMAIL_AAD: {
			return {
				isCheckEmailLoading: true,
			}
		}
		case ActionTypes.CHECK_EMAIL_AAD_SUCCESS: {
			return {
				isCheckEmailLoading: false,
				emailCheckData: action.payload
			}
		}
		case ActionTypes.CHECK_EMAIL_AAD_ERROR: {
			return {
				isCheckEmailLoading: false,
				emailCheckError: action?.error && action?.error?.message ? action?.error?.message : 'Something went wrong.'
			}
		}
		case ActionTypes.RESET_PASSWORD_LOADING: {
			return {
				...state,
				reset: {
					isLoading: true,
				},
			};
		}

		case ActionTypes.RESET_PASSWORD_SUCCESS: {
			return {
				...state,
				reset: {
					isLoading: false,
					success: true,
					user: action.payload,
				},
			};
		}

		case ActionTypes.RESET_PASSWORD_ERROR: {
			return {
				...state,
				reset: {
					isLoading: false,
					success: false,
					error: action.error,
				},
			};
		}

		case ActionTypes.FORGOT_PASSWORD_LOADING: {
			return {
				...state,
				forgot: {
					isLoading: true,
				},
			};
		}

		case ActionTypes.FORGOT_PASSWORD_SUCCESS: {
			return {
				...state,
				forgot: {
					isLoading: false,
					success: true,
					user: action.payload,
				},
			};
		}

		case ActionTypes.FORGOT_PASSWORD_ERROR: {
			return {
				...state,
				forgot: {
					isLoading: false,
					success: false,
					error: action.error,
				},
			};
		}

		case ActionTypes.VERIFY_USER_LOADING: {
			return {
				...state,
				verifyUser: {
					isLoading: true,
				},
			};
		}

		case ActionTypes.VERIFY_USER_SUCCESS: {
			return {

				...state,
				verifyUser: {
					isLoading: false,
					success: true,
					user: action.payload,
				},
			};
		}

		case ActionTypes.VERIFY_USER_ERROR: {
			return {
				...state,
				verifyUser: {
					isLoading: false,
					success: false,
					error: action.error,
				},
			};
		}

		case ActionTypes.VERIFY_EMAIL_LOADING: {
			return {
				...state,
				verifyEmail: {
					isLoading: true,
				},
			};
		}

		case ActionTypes.VERIFY_EMAIL_SUCCESS: {
			return {
				...state,
				verifyEmail: {
					isLoading: false,
					success: true,
					user: action.payload,
				},
			};
		}

		case ActionTypes.VERIFY_EMAIL_ERROR: {
			return {
				...state,
				verifyEmail: {
					isLoading: false,
					success: false,
					error: action.error,
				},
			};
		}

		case ActionTypes.RESEND_VERIFY_EMAIL_LOADING: {
			return {
				...state,
				resendVerifyEmail: {
					isLoading: true,
				},
			};
		}

		case ActionTypes.RESEND_VERIFY_EMAIL_SUCCESS: {
			return {
				...state,
				error: '',
				resendVerifyEmail: {
					isLoading: false,
					success: true,
					...action.payload,
				},
			};
		}

		case ActionTypes.RESEND_VERIFY_EMAIL_ERROR: {
			return {
				...state,
				resendVerifyEmail: {
					isLoading: false,
					success: false,
					error: action.error,
				},
			};
		}

		case ActionTypes.CLEAR_RESET_PASSWORD_ERROR: {
			return {
				...state,
				reset: {
					isLoading: false,
					success: false,
					error: '',
				},
			};
		}

		default: {
			return state;
		}
	}

};

export default login;