import { LabelColorBoxWrapper } from 'dsilo-ui-components';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearReduxDataOfCurrentComponent, getContractDataById, getDataModelById, getModelData } from '../../store/actions';
import { Loader } from "../loader";

const LabelColorBoxSupport = (props) => {
	return (
		<div>
			<LabelColorBoxWrapper loader={<Loader />} {...props} />
		</div>
	)
}

const mapDispatchToProps = {
    getContractDataById,
    getDataModelById,
	getModelData,
	clearReduxDataOfCurrentComponent,
};

const mapStateToProps = state => {
	return {
	  formData: state.form,	
	  user: state.user,
	};
  };
  
  export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(LabelColorBoxSupport)));
  