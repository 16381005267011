import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import BarChart from './BarCharts'
import DoughnutChart from '../DoughnutChart';
import barChartData from './barChart.json'
import doughnutChartData from './doughnutChartData.json'


const Charts = (props) => {

  const useStyles = makeStyles((theme) => ({
    paper: {
      height: '100%',
      width: '100%'
    }

  }));

  const classes = useStyles();


  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <Paper elevation={2} className={classes.paper}>
            <BarChart data={props.barChart || barChartData} loading={props.loadingBar} />
          </Paper>
        </Grid>
        <Grid item xs={3}>
        <Paper elevation={2} className={classes.paper}>
            <DoughnutChart data={props.doughnutChart || doughnutChartData} loading={props.loadingDoughnut} value={props.doughnutChartValue} />
          </Paper>
        </Grid>
        <Grid item xs={6}>
        <Paper elevation={2} className={classes.paper}>
            <BarChart data={props.dataInvoicedatabyvendorBarChart || barChartData} loading={props.loadingInvoicedatabyvendor} />
          </Paper>
        </Grid>
        <Grid item xs={6} >
        <Paper elevation={2} className={classes.paper}>
            <BarChart data={props.dataInvoicedataItemsCountbyvendorBarChart || barChartData} loading={props.loadingInvoicedatabyvendor} />
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Charts;