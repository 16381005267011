
import {
	generateGUID
} from 'modules/page/page.module'

export function addCondAndLink(ary, data) {
	console.log("ary,data", ary, data);
	let arys = [...data];
	if (ary.id && ary.index > -1 && ary.editMode) {
		arys[ary.index] = { ...ary, editMode: false };
	} else {
		let obj = ary.id ? ary : { id: generateGUID(), ...ary };
		arys.push({ ...obj });
	}
	return arys;
}

export function editCondAndLink(object, index, data) {
	let obj = { ...data[index] };
	obj.editMode = true;
	obj.index = index;
	let ary = [...data];
	ary[index] = obj;
	return { ary, obj };
}

export function deleteCondAndLink(object, index, data) {
	let ary = [...data];
	ary.splice(index, 1);
	return ary;
}

export const URLs = {
	//Row1
	documentsreceived: '/invoice/documentsreceived/',
	labelBoxColor: '/dataconnect/charts/labelcolorboxes/',
	invoicesnumber: '/invoice/invoicesnumber/',
	processedinvoices: '/invoice/processedinvoices/',
	extractionaccuracy: '/invoice/extractionaccuracy/',
	unprocessedinvoices: '/invoice/unprocessedinvoices/',
	humanreview: '/invoice/humanreview/',
	inreviewinvoices:'/invoice/inreviewinvoices/',
	//Row3
	invoicesbyvendorunprocessed: '/invoice/invoicesbyvendor/Unprocessed/',
	invoicesbyvendorprocessed: '/invoice/invoicesbyvendor/Processed/',
	invoicesbyvendoraccuracy: '/invoice/invoicesbyvendor/accuracy/',
	//Row2
	invoicesdatapointsextractionresults: '/invoice/datapointsextractionresults/',
	invoiceextracteddatapoints: '/invoice/extracteddatapoints/',
	//Row4
	invoiceinvoicesbyvendortable: '/invoice/invoicesbyvendor/table/',


	// spend Analytics row 1
	noOfUniqueItems: '/invoice/numberofuniqueitems/',
	noOfLineItems: '/invoice/numberoflineitems/',
	avgInvoiceAmount: '/invoice/invoiceamountavg/',
	totalSpend: '/invoice/totalspend/',
	invoicesNumber: '/invoice/invoicesnumber/',
	noOfVendor: '/invoice/invoicesbyvendor/all/',

	// invoice data by vendor
	invoicedatabyvendor:'/invoice/invoicedatabyvendor/',

	// Item with highest and lowest invoice amount
	invoiceamountbyvendor:'/invoice/invoiceamountbyvendor/',
	//Items with number of invoices by vendor
	numofinvoicesbyvendor:'/invoice/numofinvoicesbyvendor/',
	// Quantity of all products
	productsquantity:'/invoice/productsquantity/',
	// Spend by vendor
	spendbyvendorbymonth:'/invoice/spendbyvendorbymonth/',
	// invoices by vendor
	invoicesbyvendorbymonth:'/invoice/invoicesbyvendorbymonth/',

	// Contracts by type
	invoicedatabyfielddocumentType:'/invoice/databyfield/documentType/',
	//Contracts by Counter party
	invoicedatabyfieldcounterParty:'/invoice/databyfield/counterParty/',
	//Contracts signed/not signed
	invoicedatabyfieldsigned:'/invoice/databyfield/signed/',
	//Contracts by age
	invoicedatabyfieldage:'/invoice/databyfield/age/',
	//Contracts by term
	invoicedatabyfieldterm:'/invoice/databyfield/term/',
}