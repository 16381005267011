import React, { useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';
import { hot } from "react-hot-loader/root";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
	addNewChartToWorkspace,
	deleteChatFromHistory,
	deleteQuestionFromChatHistory,
	deleteChartFromWorkspace,
	deleteWorkspace,
	getChatHistory,
	getChatHistoryById,
	getDataModels,
	clearReduxChatDataOfCurrentComponent,
	setCurrentComponent,
	getAllWorkspaces,
	getWorkspacesById,
	createNewWorkspace,
	updateChatTitle,
	updateWorkspace,
	updateWorkspaceTitle,
	publishOrDraftWorkspace,
	getSampleQuestions,
	getAllSaveForLaterChats,
	getSingleSaveForLaterChat,
	updateSampleQuestionResponse
} from '../../store/actions';
import ChatContainer from "./HomeComponents/ChartContainer";
// import DownloadPDF from './HomeComponents/DownloadPDF';
import InputContainer from "./HomeComponents/InputContainer";
import SettingsContainer from './HomeComponents/SettingsContainer';
import SideBar from "./HomeComponents/Sidebar";
// import ToolbarContainer from "./HomeComponents/ToolbarContainer";
import WorkSpaceScreen from "./HomeComponents/Workspace";
import {
	createWorkspaceUniqueId,
	chartHistoryUniqueId,
	chartHistoryByIdUniqueId,
	generateChartConfig,
	generateHighchartData,
	getChartType,
	workspaceUniqueId,
	workspaceByIdUniqueId,
	updateWorkspaceUniqueId,
	sampleQuestionsUniqueId,
} from './ChatHelper';
import "./index.css";

const strCompare = (Str1, Str2) => {
	return Str1.includes(Str2.toLowerCase());
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	chatOwnerBox: {
		position: 'fixed',
		marginLeft: -5,
		marginTop: -12
	},
	chatOwnerText: {
		fontSize: 12,
		fontWeight: 'lighter'
	},
	ownerName: { fontSize: 16 }
}));

const ProcureGptComponent = (props) => {
	const classes = useStyles();
	const [messages, setMessages] = useState([]);
	const [loading, setLoading] = useState(false);
	const [workspaces, setWorkspaces] = useState([]);
	const [selectedChatId, setSelectedChatId] = useState("");
	const [selectedWorkspace, setSelectedWorkspace] = useState("");
	const [workspaceLoading, setWorkspaceLoading] = useState(false);
	const [receivingWorkSpace, setReceivingWorkSpace] = useState({});
	const [ownerDetails, setOwnerDetails] = useState({});
	const [chatTabVal, setChatTabVal] = useState('my');
	const [workspaceTabVal, setWorkspaceTabVal] = useState('my');
	const [selectedPgptSupport, setSelectedPgptSupport] = useState(null);
	const chatContainerEndDivRef = useRef(null);
	const chatContainerRef = useRef(null)

	const { ref, refs } = props.chartData;
	const appId = props?.match?.params?.appid || "";
	const loggedInUserId = props?.user?._id || props?.user?.id;

	const getLatestChatHistory = (type = '') => {
		const _refs = refs && Array.isArray(refs) && refs?.length > 0 ? refs : [ref || 'main'];
		console.log('chat history _refs ::: *** ', _refs);
		const data = {
			appId,
			dataUniqueId: chartHistoryUniqueId,
			type: type,
			payload: {
				refs: _refs
			}
		}
		props.getChatHistory(data);
	}

	const getWorkspaces = (type = '') => {
		const data = {
			appId,
			dataUniqueId: workspaceUniqueId,
			type: type
		}
		props.getAllWorkspaces(data);
	}

	const scrollToBottom = () => {
		chatContainerEndDivRef?.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		// if(props?.hideCompleteSideNavBar) props.hideCompleteSideNavBar(true)
		// getLatestChatHistory('my');
		// getWorkspaces('my')

		return () => {
			if (props?.hideCompleteSideNavBar) props.hideCompleteSideNavBar(false)
			props?.clearReduxChatDataOfCurrentComponent("clearAll");
		}
	}, []);

	useEffect(() => {
		getLatestChatHistory(chatTabVal)
	}, [chatTabVal])

	useEffect(() => {
		getWorkspaces(workspaceTabVal)
	}, [workspaceTabVal])

	useEffect(() => {
		const chatHistoryLoading = props?.chat?.data?.[`${selectedChatId}_${chartHistoryByIdUniqueId}_loading`];
		if (chatHistoryLoading !== null || chatHistoryLoading !== undefined)
			setLoading(chatHistoryLoading);
	}, [props?.chat?.[`${selectedChatId}_${chartHistoryByIdUniqueId}_loading`]]);

	useEffect(() => {
		if (
			props?.chat?.[`${workspaceByIdUniqueId}_loading`] ||
			props?.chat?.[`${createWorkspaceUniqueId}_loading`] ||
			props?.chat?.[`${updateWorkspaceUniqueId}_loading`]
		) {
			setWorkspaceLoading(true);
		} else {
			setWorkspaceLoading(false);
		}
	}, [
		props?.chat?.[`${workspaceByIdUniqueId}_loading`],
		props?.chat?.[`${createWorkspaceUniqueId}_loading`],
		props?.chat?.[`${updateWorkspaceUniqueId}_loading`],
	]);

	useEffect(() => {
		if (props?.chat?.data?.workspaceById && Object.keys(props?.chat?.data?.workspaceById).length > 0) {
			const workspaceById = { ...props?.chat?.data?.workspaceById };
			const tempWorkspaces = workspaceById?.chart?.map(chart => {
				let chartType = getChartType(chart?.chartType);
				let originalResponseData = chart?.chartData?.responseData || chart?.chartData?.workspaceItem?.responseData || null;
				const highchartData = generateHighchartData(originalResponseData, chartType, props?.chartData);
				return {
					_id: chart?._id,
					layout: chart?.chartData?.layout,
					highchartData: highchartData,
					responseData: originalResponseData,
					title: chart?.chartData?.title,
					workspaceId: workspaceById?._id,
					chartUniqueId: chart?.chartUniqueId,
					type: chartType
				}
			});
			setReceivingWorkSpace({
				...receivingWorkSpace,
				workspaceName: workspaceById?.title,
				workspaceId: workspaceById?._id,
				charts: tempWorkspaces,
				status: workspaceById?.status || ''
			});
		}
	}, [props?.chat?.data?.workspaceById]);

	useEffect(() => {
		// if (props?.chat?.data?.workspaces && props?.chat?.data?.workspaces?.length > 0) {
		if (props?.chat?.data?.workspaces) {
			if (props.chat.data.workspaces.length > 0) {
				setWorkspaces(props.chat.data.workspaces);
			} else {
				setWorkspaces([])
			}
		}
	}, [props?.chat?.data?.workspaces]);

	const addMessage = (messageObj) => {
		const {
			message,
			isBot,
			type = 'text',
			responseData = null,
			showChartMenu = false,
			highchartData = null,
			title = '',
			questionId = '',
			points = {},
			aiType = '',
			resultType = null
    	} = messageObj;
		const newMessage = {
			message,
			isBot,
			type,
			responseData,
			showChartMenu,
			highchartData,
			title: title || message || "",
			questionId,
			points,
			aiType,
			resultType
		}
		setMessages(prevMessages => [...prevMessages, { ...newMessage, id: prevMessages?.length }]);
	};

	const updateMessageWithSelectedPrompt = (selectedPrompt) => {
		const newPromptQuestion = {
			message: selectedPrompt,
			isBot: false,
			type: "text",
			responseData: undefined,
			showChartMenu: undefined,
			highchartData: undefined,
			title: selectedPrompt,
			questionId: "",
			points: {},
			aiType: 'pgpt-v1'
		}
		setMessages((prevMessages) => {
			prevMessages.push({ ...newPromptQuestion, id: prevMessages?.length });
			return prevMessages;
		});
		setLoading(true);
	}

	const handleSubmit = async (event, inputValue, setInputValue) => {
		if(inputValue?.trim() !== '') {
			event.preventDefault();
			addMessage({
				message: inputValue, 
				isBot: false
			});
			setLoading(true);
			setInputValue("");
		}
	};

	const addDropedItemsToSpace = (item, workspaceId) => {
		const chatQuestions = [...(props?.chat?.data?.[`${selectedChatId}_${chartHistoryByIdUniqueId}`]?.data) || []]
		const componentList = chatQuestions.find((question) => item.id === question._id);
		let workspaceChart = {}
		const newWorkspace = {
			...componentList,
			chartType: item?.chartType || componentList?.chartType
		}
		if (newWorkspace && Object.keys(newWorkspace).length > 0) {
			const workspaceChartsCount = receivingWorkSpace?.charts?.length || 0;
			workspaceChart = generateChartConfig(newWorkspace, workspaceChartsCount, props?.chartData);
			props.addNewChartToWorkspace({
				appId,
				payload: {
					workspaceId,
					chartObj: workspaceChart
				},
				dataUniqueId: workspaceByIdUniqueId,
				workspaceUniqueId: workspaceUniqueId
			});

			updateCurrentComponent("workSpace");
			if (workspaceId) {
				setSelectedWorkspace(workspaceId);
			}
		}
	};

	const clearMessages = (chatId = "") => {
		setMessages([]);
		const subKeys = [
			`${selectedChatId}_${chartHistoryByIdUniqueId}`, 
			`${selectedChatId}_${chartHistoryByIdUniqueId}_loading`, 
			workspaceByIdUniqueId
		];
		props?.clearReduxChatDataOfCurrentComponent({
			key: "data",
			subKey: subKeys
		});
		setSelectedChatId(chatId);
		setOwnerDetails({});
	}

	const updateCurrentComponent = (type = "aiAssistant", chatId = "") => {
		if (type === "aiAssistant") {
			clearMessages(chatId);
		}
		props.setCurrentComponent(type);
	}

	const getChatHistoryByChatId = (selectedChat) => {
		const prevSelectedChatId = selectedChatId;
		let subKeys = [
			`${prevSelectedChatId}_${chartHistoryByIdUniqueId}`, 
			`${prevSelectedChatId}_${chartHistoryByIdUniqueId}_loading`
		];
		props.clearReduxChatDataOfCurrentComponent({
			key: "data",
			subKey: subKeys
		})
		const data = {
			appId,
			chatId: selectedChat?._id,
			dataUniqueId: `${selectedChat?._id}_${chartHistoryByIdUniqueId}`,
			payload: { page: 1, rowsPerPage: 10 } //Need to make the rowsperPage as config
		}
		setMessages([]);
		setSelectedChatId(selectedChat?._id);
		props.getChatHistoryById(data);
		setLoading(true);
		updateCurrentComponent("aiAssistant", selectedChat?._id);
		let chatOwnerDetails = {};
		if (selectedChat?.userId && selectedChat?._id && selectedChat?.userName) {
			chatOwnerDetails = {
				_id: selectedChat?.userId,
				name: selectedChat?.userName || ''
			}
		}
		setOwnerDetails(chatOwnerDetails);
	}

	const getWorkspaceById = (selectedWorkspace) => {
		setReceivingWorkSpace({});
		setSelectedWorkspace(selectedWorkspace?._id);
		updateCurrentComponent("workSpace");
		if (selectedWorkspace?._id && selectedWorkspace?._id !== 'new_workspace') {
			const data = {
				appId,
				workspaceId: selectedWorkspace?._id,
				dataUniqueId: workspaceByIdUniqueId,
				workspaceUniqueId: workspaceUniqueId
			}
			props.getWorkspacesById(data);
			let workspaceOwnerDetails = {};
			if (selectedWorkspace?.createdBy?.id && selectedWorkspace?._id && selectedWorkspace?.userName) {
				workspaceOwnerDetails = {
					_id: selectedWorkspace?.createdBy?.id,
					name: selectedWorkspace?.userName || ''
				}
			}
			setOwnerDetails(workspaceOwnerDetails);
		}
	}

	const updateWorkspaceName = (workspaceId, workspaceTitle) => {
		const payload = {
			appId,
			workspaceId,
			payload: {
				title: workspaceTitle
			},
			dataUniqueId: 'updateWorkspaceTitle',
			workspaceUniqueId: workspaceUniqueId,
			workspaceByIdUniqueId: workspaceByIdUniqueId,
		}
		props.updateWorkspaceTitle(payload)
	}

	const deleteComponentFromWorkspace = (chartId, workspaceItem) => {
		const workspaceId = workspaceItem?.workspaceId;
		const payload = {
			appId,
			payload: {
				workspaceId,
				chartId,
			},
			dataUniqueId: workspaceUniqueId,
		}
		props.deleteChartFromWorkspace(payload);
		const selectedWorkspace = workspaces.find(ws => ws?._id === workspaceId) || { _id: workspaceId };
		setTimeout(() => getWorkspaceById(selectedWorkspace), 0);
	}

	const addNewWorkspace = () => {
		const apiData = {
			appId,
			dataUniqueId: workspaceUniqueId,
			payload: {
				name: `Dashboard ${workspaces.length + 1}`,
				title: `Dashboard ${workspaces.length + 1}`,
				description: "Dashboards",
				type: "dashboard",
				chart: [], //send Empty Array, remove later when API is fixed.
				default: false,
				appId
			}
		}
		setReceivingWorkSpace({});
		props.createNewWorkspace(apiData);
	}

	const updateWorkspaceChartTitle = (chartItem, newChartTitle) => {
		if (chartItem?._id && chartItem?.workspaceId && props?.chat?.data?.workspaceById
			&& Object.keys(props?.chat?.data?.workspaceById).length > 0) {
			const workspaceById = props?.chat?.data?.workspaceById
				&& JSON.parse(JSON.stringify(props?.chat?.data?.workspaceById));
			const updatedChart = workspaceById?.chart?.filter((item, index) => item._id === chartItem?._id);
			if (updatedChart && updatedChart?.length > 0) {
				updatedChart[0].chartData.title = newChartTitle;
			}
			const payload = {
				appId,
				workspaceId: chartItem?.workspaceId,
				dataUniqueId: updateWorkspaceUniqueId,
				workspaceByIdUniqueId: workspaceByIdUniqueId,
				payload: {
					chart: updatedChart
				}
			}
			props.updateWorkspace(payload);
		}
	}

	const updateWorkspaceLayout = workspaceId => {
		if (
			workspaceId &&
			receivingWorkSpace?.workspaceId === workspaceId &&
			props?.chat?.data?.workspaceById &&
			Object.keys(props?.chat?.data?.workspaceById).length > 0
		) {
			const workspaceById =
				props?.chat?.data?.workspaceById &&
				JSON.parse(JSON.stringify(props?.chat?.data?.workspaceById));
			const updatedCharts = workspaceById?.chart?.map((chart, index) => {
				let updatedChartLayout = receivingWorkSpace?.charts?.find(
					(wsChart, index) => wsChart?._id === chart?._id,
				);
				if (updatedChartLayout && Object.keys(updatedChartLayout).length) {
					return {
						...chart,
						chartData: {
							...chart?.chartData,
							layout: updatedChartLayout?.layout,
						},
					};
				} else {
					return { ...chart };
				}
			});
			const payload = {
				appId,
				workspaceId,
				dataUniqueId: updateWorkspaceUniqueId,
				workspaceByIdUniqueId: workspaceByIdUniqueId,
				payload: {
					chart: updatedCharts,
				},
			};
			props.updateWorkspace(payload);
		}
	};

	const updateChatName = (selectedChat, chatNewName) => {
		if (chatNewName && selectedChat?._id) {
			const payload = {
				appId,
				chatId: selectedChat?._id,
				payload: {
					name: chatNewName
				},
				dataUniqueId: chartHistoryUniqueId
			}
			props.updateChatTitle(payload);
		}
	}

	const publishDraftWorkspace = (type, workspaceId) => {
		if (type && workspaceId) {
			const payload = {
				appId,
				workspaceId,
				publishType: type,
				payload: {
					status: type === 'publish' ? 'Published' : 'Draft'
				},
				dataUniqueId: 'publishDraftWorkspace',
				workspaceByIdUniqueId
			}
			props.publishOrDraftWorkspace(payload);
		}
	}

	/**
	 * This function handles to show the response of the Selected SampleQuestion.
	 * @param {Object} selectedQuestion - Question Object stored in DB.
	 */
	const showSampleQuestionResponse = (selectedQuestion) => {
		setLoading(true);
		const prevSelectedChatId = selectedChatId;
		let subKeys = [
			`${prevSelectedChatId}_${chartHistoryByIdUniqueId}`, 
			`${prevSelectedChatId}_${chartHistoryByIdUniqueId}_loading`
		];
		props.clearReduxChatDataOfCurrentComponent({
			key: "data",
			subKey: subKeys
		})
		setMessages([]);
		setSelectedChatId('sampleQuestion');
		updateCurrentComponent("aiAssistant", 'sampleQuestion');
		const sampleQuestionPayload = {
			dataUniqueId: `sampleQuestion_${chartHistoryByIdUniqueId}`,
			data: {
				data: [selectedQuestion],
				page: 1,
				totalCount: 1
			}
		}
		props.updateSampleQuestionResponse(sampleQuestionPayload);
	}

	return (
		<Box className={classes.root}>
			<SideBar
				{...props}
				addDropedItemsToSpace={addDropedItemsToSpace}
				addNewWorkspace={addNewWorkspace}
				getChatHistoryByChatId={getChatHistoryByChatId}
				getWorkspaceById={getWorkspaceById}
				receivingWorkSpace={receivingWorkSpace}
				selectedChatId={selectedChatId}
				selectedWorkspace={selectedWorkspace}
				updateCurrentComponent={updateCurrentComponent}
				workspaces={workspaces}
				updateChatTitle={updateChatName}
				getLatestChatHistory={getLatestChatHistory}
				getWorkspaces={getWorkspaces}
				chatTabVal={chatTabVal}
				setChatTabVal={setChatTabVal}
				workspaceTabVal={workspaceTabVal}
				setWorkspaceTabVal={setWorkspaceTabVal}
			/>
			{props.chat?.currentComponent === "aiAssistant" ? (
				<main className={classes.content}>
					<div className="container" style={{ position: 'relative' }}>
						{/* Show owner details when the chat is not created by logged-in user. */}
						{ownerDetails?._id && ownerDetails?.name ? (
							<Box className={classes.chatOwnerBox} display="flex" alignItems="center">
								<Typography className={classes.chatOwnerText} variant="subtitle2" component="span">
									Owner: <b className={classes.ownerName}>{ownerDetails?.name}</b>
								</Typography>
							</Box>
						) : null}
						<ChatContainer
							ref={chatContainerRef}
							{...props}
							loading={loading}
							messages={messages}
							setLoading={setLoading}
							scrollToBottom={scrollToBottom}
							selectedChatId={selectedChatId}
							setSelectedChatId={setSelectedChatId}
							getLatestChatHistory={getLatestChatHistory}
							updateMessageWithSelectedPrompt={updateMessageWithSelectedPrompt}
							chatTabVal={chatTabVal}
							selectedPgptSupport={selectedPgptSupport}
							setMessages={setMessages}
						/>
						{chatTabVal !== 'all' && selectedChatId !== 'sampleQuestion' && (
							<InputContainer
								appId={appId}
								enableAutocomplete={
									'enableAutocomplete' in props?.chartData
										? props?.chartData?.enableAutocomplete 
										: true
								}
								handleSubmit={handleSubmit}
								globalLoading={loading}
							/>
						)}
						<SettingsContainer
							appId={appId}
							sampleQuestions={props.chat?.data?.[sampleQuestionsUniqueId]}
							dataModels={props?.dataModels}
							getDataModels={props.getDataModels}
							getSampleQuestions={props.getSampleQuestions}
							showSampleQuestionResponse={showSampleQuestionResponse}
						/>
						{/* <ToolbarContainer
							chartData={props.chartData}
							selectedPgptSupport={selectedPgptSupport}
							setSelectedPgptSupport={setSelectedPgptSupport}
						/> */}
						<div ref={chatContainerRef} />
						{/* <DownloadPDF refOfChatContainer={chatContainerRef} /> */}
						<div ref={ chatContainerEndDivRef} />
					</div>
				</main>
			) : (
				<WorkSpaceScreen
					{...props}
					receivingWorkSpace={receivingWorkSpace}
					setReceivingWorkSpace={setReceivingWorkSpace}
					deleteComponentFromWorkspace={deleteComponentFromWorkspace}
					workspaceLoading={workspaceLoading}
					updateWorkspaceName={updateWorkspaceName}
					updateChartTitle={updateWorkspaceChartTitle}
					updateWorkspace={updateWorkspaceLayout}
					publishDraftWorkspace={publishDraftWorkspace}
					publishLoading={props?.chat?.["publishDraftWorkspace_loading"]}
					ownerDetails={ownerDetails}
					workspaceTabVal={workspaceTabVal}
				/>
			)}
		</Box>
	);
}

const mapDispatchToProps = {
	addNewChartToWorkspace,
	deleteChatFromHistory,
	deleteChartFromWorkspace,
	deleteQuestionFromChatHistory,
	deleteWorkspace,
	createNewWorkspace,
	clearReduxChatDataOfCurrentComponent,
	getAllWorkspaces,
	getChatHistory,
	getChatHistoryById,
	getDataModels,
	getSampleQuestions,
	getWorkspacesById,
	setCurrentComponent,
	updateChatTitle,
	updateWorkspace,
	updateWorkspaceTitle,
	publishOrDraftWorkspace,
	getAllSaveForLaterChats,
	getSingleSaveForLaterChat,
	updateSampleQuestionResponse
};

const mapStateToProps = state => {
	return {
		dataModels: state.dataModels?.data || [],
		formData: state.form,
		chat: state.chat,
		user: state.user,
		pages: state.pages.page,
	};
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProcureGptComponent)));