import { ActionTypes } from 'constants/index';

const initialState = {
  org: {},
  loading: false,
  error: '',
  getOrgDetails: {
    org: null,
    loading: false,
    error: '',
  },
  addMember: {
    org: {},
    loading: false,
    error: '',
    success: false,
  },
  updateOrg: {
    org: {},
    loading: false,
     error: '',
    success: false,
  },
  message:'',
  logo:''
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CREATE_ORG_LOADING: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case ActionTypes.CREATE_ORG_SUCCESS: {
      return {
        ...state,
        org: action.payload,
        loading: false,
      };
    }
    case ActionTypes.CREATE_ORG_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ActionTypes.GET_ORG_DETAILS_LOADING: {
      return {
        ...state,
        getOrgDetails: {
          loading: true,
          error: '',
        },
      };
    }
    case ActionTypes.GET_ORG_DETAILS_SUCCESS: {
      return {
        ...state,
        getOrgDetails: {
          loading: false,
          org: action.payload,
          error: '',
        },
      };
    }
    case ActionTypes.GET_ORG_DETAILS_ERROR: {
      return {
        ...state,
        getOrgDetails: {
          loading: false,
          error: action.error,
        },
      };
    }

    case ActionTypes.ADD_ORG_MEMBER_LOADING: {
      return {
        ...state,
        addMember: {
          loading: true,
          error: '',
          success: false,
        },
      };
    }
    case ActionTypes.ADD_ORG_MEMBER_SUCCESS: {
      return {
        ...state,
        addMember: {
          loading: false,
          org: action.payload,
          error: '',
          success: true,
        },
      };
    }
    case ActionTypes.ADD_ORG_MEMBER_ERROR: {
      return {
        ...state,
        addMember: {
          loading: false,
          error: action.error,
          success: false,
        },
      };
    }
    // case ActionTypes.LOAD_ORG_LOADING: {
    //     return {
    //         ...state,
    //         loading: true,
    //         error:''
    //     };
    // }
    // case ActionTypes.LOAD_ORG_SUCCESS: {
    //     return {
    //         ...state,
    //         org: action.payload,
    //         loading: false
    //     }
    // }
    // case ActionTypes.LOAD_ORG_ERROR: {
    //     return {
    //         ...state,
    //         loading: false,
    //         error: action.error
    //     };
    // }
    case ActionTypes.UPDATE_ORG_LOADING: {
      return {
        ...state,
         updateOrg: {
          loading: true,
          error: '',
          success: false,
        },
      };
    }
    case ActionTypes.UPDATE_ORG_SUCCESS: {
      return {
        ...state,
        updateOrg: {
          loading: false,
          org: action.payload,
          error: '',
          success: true,
        },
        message:action.payload.message
      };
    }
    case ActionTypes.UPDATE_ORG_ERROR: {
      return {
        ...state,
        updateOrg: {
          loading: false,
          error: action.error,
          success: false,
        },
        message:action.error.message
      };
    }
    case ActionTypes.CLEAR_ORG_MESSAGE: {
      return {
        ...state,
        message:''
      };
    }
    case ActionTypes.GET_ORG_LOGO: {
      return {
        ...state,
         logo:''
      };
    }
    case ActionTypes.GET_ORG_LOGO_SUCCESS: {
      return {
        ...state,
         logo:action.payload
      };
    }
    case ActionTypes.CLEAR_ORG_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    default: {
      return state;
    }
  }
}
