/**
 * @module Actions/Application
 * @desc Application Actions
 */
import { ActionTypes } from 'constants/index';
export const contactDsilo = (data) => dispatch => {
	dispatch({ type: ActionTypes.SUBMIT_CONTACT_DSILO_DATA_LOADING, payload: data });
};

export const clearContactDsiloMessage = (data) => dispatch => {
	dispatch({ type: ActionTypes.CLEAR_CONTACT_DSILO_DATA_MESSAGE, payload: data });
};


