import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { BarChart, LabelColorBoxes } from 'dsilo-ui-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchProcessDashboard1Data, getModelDataDropDown, setSelectedChartItems } from '../../store/actions';
import { Loader } from "../loader";
import DashboardFilters from './DashboardFilters';
import { labelColorBoxConfig1, generateHighChartData } from './utils';
import useStyles from './styles';

const totalBacklog = 'backlogGroup';
const ProcessDashboard1 = (props) => {
    const { appId, data } = props;
    const { chartUniqueId, chartData } = data;
    const { autoRefresh, selectedDataModel, subCharts } = chartData;
    const classes = useStyles();
    const [pageFilterConditions, setPageFilterConditions] = useState([]);   
    const [selectedChartItem, setSelectedChartItem] = useState({});
    const [dateFormat, setDateFormat] = useState({
        [totalBacklog]: 'MM/DD/YYYY'
    });
    const [clickedItems, setClickedItems] = useState({});
    const [dashboardData, setDashboardData] = useState({});

    const fetchDashboardData = useCallback(() => {
        let payload = {}
        if (pageFilterConditions && Array.isArray(pageFilterConditions) && pageFilterConditions.length > 0) {
            payload = {
                conditions: pageFilterConditions
            }
        }
        if (clickedItems && clickedItems?.[totalBacklog]) {
            console.log("clickedItems =====", clickedItems)
            payload = {
                ...payload,
                publishConditions: clickedItems?.[totalBacklog]
            }
        }
        if (dateFormat?.[totalBacklog]) {
            payload = {
                ...payload,
                [totalBacklog]: {
                    format: dateFormat?.[totalBacklog]
                }
            }
        }
        props.fetchProcessDashboard1Data({
            appId,
            dataModelId: selectedDataModel,
            dataUniqueId: `${chartUniqueId}_pb1`,
            payload
        });
    }, [pageFilterConditions, dateFormat, clickedItems]);
    
    useEffect(() => {
        fetchDashboardData();
    }, [fetchDashboardData]);
    
    useEffect(() => {
        let intervalId = 0;
        const timeout = autoRefresh?.timeout || 5 * 60 * 1000;
        if(autoRefresh && autoRefresh?.enable) {
            intervalId = setInterval(fetchDashboardData, timeout);
        }
    
        return () => {
          clearInterval(intervalId);
        };
    }, [autoRefresh, pageFilterConditions, clickedItems]);

    useEffect(() => {
        if (!clickedItems?.[totalBacklog]) {
            const formData = { ...props?.formData?.document?.[`${chartUniqueId}_pb1`] };
            let tempDashboardData = { ...dashboardData };
            const totalBacklogByDay = formData?.totalBacklogs;
            let totalBacklogData = null;
            if (totalBacklogByDay && Array.isArray(totalBacklogByDay) && totalBacklogByDay?.length > 0) {
                const subChartConfig = subCharts.find(chart =>  chart.name === 'totalBacklogs');
                const enableChartClick = 'enableOnChartClick' in subChartConfig ? subChartConfig?.enableOnChartClick : true;
                const data = {
                    responseData: totalBacklogByDay,
                    title: 'Total Backlog by Received Date',
                    chartType: 'column',
                    enableOnChartClick: enableChartClick
                }
                totalBacklogData = generateHighChartData(data);
            }
            tempDashboardData = {
                ...dashboardData,
                ...tempDashboardData,
                totalBacklogData
            }
            setDashboardData(tempDashboardData)
        }
    }, [clickedItems, props?.formData?.document?.[`${chartUniqueId}_pb1`]?.totalBacklogs]);

    useEffect(() => {
        if (selectedChartItem && Object.keys(selectedChartItem).length > 0) {
            const _selectedChartItem = selectedChartItem?.[totalBacklog]?.selectedChartItem;
            const dataElementName = selectedChartItem?.[totalBacklog]?.dataElementName;
            const _selectedChartType = selectedChartItem?.[totalBacklog]?.selectedChartType;
            const subChartConfig = subCharts.find(sc =>  sc.name === _selectedChartType)
            const { title } = _selectedChartItem;
            const prevPublishConditions = clickedItems?.[totalBacklog] || [];
            const prevValue = prevPublishConditions.find((pv, index) => pv.selectedConditionDataElement === dataElementName)?.value || '';
            if (prevValue === title ||
                (title === 'AI Processing' && prevValue?.length > 0) ||
                (prevValue === 'Others' && title === 'Others-Document')
            ) {
                resetChartSelection();
            } else {
                let selectedValue;
                switch (title) {
                    case 'AI Processing': 
                        selectedValue = ['In Processing', 'In Quality Review', 'In Level 1 Review', 'In Level 2 Review', 'In Level 3 Review'];
                        break;
                    case 'Others-Document': 
                        selectedValue = 'Others';
                        break;
                    default:
                        selectedValue = title;
                }
                const selectedChartCondition =  {
                    selectedConditionDataElement: dataElementName,
                    selectedConditionOperator: selectedValue && typeof selectedValue === 'string' ? "equals" : "in",
                    value: selectedValue,
                    selectedConditionClause: "and",
                    format: dateFormat?.[totalBacklog]
                }
                let publishConditions = []
                if (subChartConfig && subChartConfig?.publishConditions?.length > 0) {
                    if (_selectedChartType === 'totalBacklogs') {
                        publishConditions = [ ...subChartConfig?.publishConditions ]
                        publishConditions.push(selectedChartCondition);
                    } else {
                        const temoPublishConditions = [ ...subChartConfig?.publishConditions ]
                        publishConditions = temoPublishConditions.map((cond, index) => {
                            if(index === 0) {
                                return {
                                    ...cond,
                                    ...selectedChartCondition
                                }
                            }
                            return cond;
                        })
                    }
                } else {
                    publishConditions = [selectedChartCondition]
                }
                console.log('publishConditions ::: *** ', publishConditions)
                setSelectedChartItem({});
                setClickedItems(() => {
                    return {
                        [totalBacklog]: publishConditions
                    }
                });
            }
        }
    }, [selectedChartItem, dateFormat?.[totalBacklog], clickedItems]);

    const labelColorData = useMemo(() => {
        const labelColorData = props?.formData?.document?.[`${chartUniqueId}_pb1`]?.vcb;
        let cardData = [];
        if (labelColorData && Object.keys(labelColorData).length > 0) {
            cardData = labelColorBoxConfig1.map(card => {
                if (labelColorData.hasOwnProperty(card.dataElement)) {
                    card.value = labelColorData?.[card.dataElement];
                }
                return card;
            });
        }        
        return cardData;
    }, [props?.formData?.document?.[`${chartUniqueId}_pb1`]?.vcb]);

    const totalDocumentsStatus = useMemo(() => {
        let totalDocStatusData = null;
        const documentStatusData = props?.formData?.document?.[`${chartUniqueId}_pb1`]?.totalDocumentsStatus;
        if (documentStatusData && Array.isArray(documentStatusData) && documentStatusData?.length > 0) {
            const subChartConfig = subCharts.find(chart =>  chart.name === 'totalDocumentsStatus');
            const enableChartClick = 'enableOnChartClick' in subChartConfig ? subChartConfig?.enableOnChartClick : true;
            const data = {
                responseData: documentStatusData,
                title: 'Total Documents Status',
                enableOnChartClick: enableChartClick
            }
            totalDocStatusData = generateHighChartData(data);
        }
        return totalDocStatusData;
    }, [props?.formData?.document?.[`${chartUniqueId}_pb1`]?.totalDocumentsStatus]);

    const invoicesByStatus = useMemo(() => {
        let invoiceData = null;
        const invoicesByDocStatus = props?.formData?.document?.[`${chartUniqueId}_pb1`]?.noOfInvoicesByDocumentStatus;
        if (invoicesByDocStatus && Array.isArray(invoicesByDocStatus) && invoicesByDocStatus?.length > 0) {
            const subChartConfig = subCharts.find(chart =>  chart.name === 'noOfInvoicesByDocumentStatus');
            const enableChartClick = 'enableOnChartClick' in subChartConfig ? subChartConfig?.enableOnChartClick : true;
            const data = {
                responseData: invoicesByDocStatus,
                title: 'No. of Invoices by Document Status',
                enableOnChartClick: enableChartClick
            }
            invoiceData = generateHighChartData(data);
            
        }
        return invoiceData;
    }, [props?.formData?.document?.[`${chartUniqueId}_pb1`]?.noOfInvoicesByDocumentStatus]);

    const cmsByStatus = useMemo(() => {
        let cmsData = null;
        const cmsByDocStatus = props?.formData?.document?.[`${chartUniqueId}_pb1`]?.noOfCMsByDocumentStatus;
        if (cmsByDocStatus && Array.isArray(cmsByDocStatus) && cmsByDocStatus?.length > 0) {
            const subChartConfig = subCharts.find(chart =>  chart.name === 'noOfCMsByDocumentStatus');
            const enableChartClick = 'enableOnChartClick' in subChartConfig ? subChartConfig?.enableOnChartClick : true;
            const data = {
                responseData: cmsByDocStatus,
                title: 'No. of Credit Memos by Document Status',
                enableOnChartClick: enableChartClick
            }
            cmsData = generateHighChartData(data);
            
        }
        return cmsData;
    }, [props?.formData?.document?.[`${chartUniqueId}_pb1`]?.noOfCMsByDocumentStatus]);

    const dwsByStatus = useMemo(() => {
        let dwsData = null;
        const dwsByDocStatus = props?.formData?.document?.[`${chartUniqueId}_pb1`]?.noOfDWsByDocumentStatus;
        if (dwsByDocStatus && Array.isArray(dwsByDocStatus) && dwsByDocStatus?.length > 0) {
            const subChartConfig = subCharts.find(chart =>  chart.name === 'noOfDWsByDocumentStatus');
            const enableChartClick = 'enableOnChartClick' in subChartConfig ? subChartConfig?.enableOnChartClick : true;
            const data = {
                responseData: dwsByDocStatus,
                title: 'No. of Deduction Worksheets by Document Status',
                enableOnChartClick: enableChartClick
            }
            dwsData = generateHighChartData(data);
            
        }
        return dwsData;
    }, [props?.formData?.document?.[`${chartUniqueId}_pb1`]?.noOfDWsByDocumentStatus]);

    const handleDashboardItemClick = (dataElementName, chartType, selectedChartItem, selectedChartType = '') => {
        const chartItem = {
            [chartType]: {
                selectedChartItem,
                dataElementName,
                selectedChartType
            }
        }
        props?.setSelectedChartItems(selectedChartType)
        setSelectedChartItem(chartItem);
    };

    const resetChartSelection = () => {
        props?.setSelectedChartItems("")
        setSelectedChartItem({});
        setPageFilterConditions([]);
        setClickedItems({});
    }

    const handleChange = (event) => {
        setDateFormat(() => {
            return {
                [totalBacklog]: event.target.value
            }
        });
    }

    return (
        <Box className={classes.mainBox}>
            <Box className={classes.filterBox}>
                <DashboardFilters 
                    {...props}
                    key={chartUniqueId}
                    pageFilterConditions={pageFilterConditions}
                    setPageFilterConditions={setPageFilterConditions}
                />
                {clickedItems && Object.keys(clickedItems).length > 0 && (
                    <Button 
                        variant='outlined'
                        key={'reset-selected-chart'}
                        className={classes.resetSelection}
                        onClick={resetChartSelection}
                    >
                        Reset
                    </Button>
                )}
            </Box>
            
            <>
                {props?.formData?.[`${chartUniqueId}_pb1_loading`] && (
                    <Backdrop className={classes.backdrop} open={true}>
                        <Loader />
                    </Backdrop>
                )}
                <Paper elevation={1} className={classes.paper}>
                    {labelColorData && labelColorData?.length > 0 && (
                        <Box className={classes.labelBox}>
                            <LabelColorBoxes cardsData={labelColorData} chartUniqueId={chartUniqueId} />
                        </Box>
                    )}
                </Paper>
                <Grid container spacing={1}>
                    {totalDocumentsStatus && (
                        <Grid item xs={4}>
                            <Paper elevation={1} className={classes.paper}>
                                <h3 className={classes.heading}>Total Documents Status</h3>
                                <BarChart
                                    {...props}
                                    data={totalDocumentsStatus}
                                    id={chartUniqueId}
                                    handleDashboardItemClick={(selectedChartItem) => {
                                        handleDashboardItemClick('status', totalBacklog, selectedChartItem, 'totalDocumentsStatus')
                                    }}
                                />
                            </Paper>
                        </Grid>
                    )}
                    {dashboardData?.totalBacklogData && (
                        <Grid item xs={8}>
                            <Paper elevation={1} className={classes.paper}>
                                <Box className={classes.formatBox}>
                                    <h3 className={classes.heading}>Total Backlog by Received Date</h3>
                                    <FormControl className={classes.formControl}>
                                        <Select
                                            value={dateFormat?.[totalBacklog] || ''}
                                            onChange={handleChange}
                                            displayEmpty
                                            labelId="chart-date-format"
                                            id="chart-date-format"
                                        >
                                            {/* <MenuItem value={''}>Select</MenuItem> */}
                                            <MenuItem value={'MM/DD/YYYY'}>MM/DD/YYYY</MenuItem>
                                            <MenuItem value={'MM/YYYY'}>MM/YYYY</MenuItem>
                                            <MenuItem value={'YYYY'}>YYYY</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <BarChart
                                    {...props}
                                    data={dashboardData?.totalBacklogData} 
                                    id={chartUniqueId}
                                    handleDashboardItemClick={(selectedChartItem) => {
                                        handleDashboardItemClick('createdDate', totalBacklog, selectedChartItem, 'totalBacklogs')
                                    }}
                                />
                            </Paper>
                        </Grid>
                    )}
                    {invoicesByStatus && (
                        <Grid item xs={4}>
                            <Paper elevation={1} className={classes.paper}>
                                <h3 className={classes.heading}>No. of Invoices by Document Status</h3>
                                <BarChart
                                    {...props}
                                    data={invoicesByStatus} 
                                    id={chartUniqueId}
                                    handleDashboardItemClick={(selectedChartItem) => {
                                        handleDashboardItemClick('status', totalBacklog, selectedChartItem, 'noOfInvoicesByDocumentStatus')
                                    }}
                                />
                            </Paper>
                        </Grid>
                    )}
                    {cmsByStatus && (
                        <Grid item xs={4}>
                            <Paper elevation={1} className={classes.paper}>
                                <h3 className={classes.heading}>No. of Credit Memos by Document Status</h3>
                                <BarChart
                                    {...props}
                                    data={cmsByStatus} 
                                    id={chartUniqueId}
                                    handleDashboardItemClick={(selectedChartItem) => {
                                        handleDashboardItemClick('status', totalBacklog, selectedChartItem, 'noOfCMsByDocumentStatus')
                                    }}
                                />
                            </Paper>
                        </Grid>
                    )}
                    {dwsByStatus && (
                        <Grid item xs={4}>
                            <Paper elevation={1} className={classes.paper}>
                                <h3 className={classes.heading}>No. of Deduction Worksheets by Document Status</h3>
                                <BarChart
                                    {...props}
                                    data={dwsByStatus} 
                                    id={chartUniqueId}
                                    handleDashboardItemClick={(selectedChartItem) => {
                                        handleDashboardItemClick('status', totalBacklog, selectedChartItem, 'noOfDWsByDocumentStatus')
                                    }}
                                />
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            </>
        </Box>
    );
}

const mapDispatchToProps = {
    fetchProcessDashboard1Data,
    getModelDataDropDown,
    setSelectedChartItems
};

const mapStateToProps = state => {
	return {
        formData: state.form,	
        user: state.user,
        dataLabel: state.chart.data,
	};
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProcessDashboard1)));