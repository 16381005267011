import { Button, CircularProgress, Grid, InputLabel, makeStyles, TextField } from '@material-ui/core';
import { ChipSelect } from 'dsilo-ui-components';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    chipSelect: {
        '& input': {
            height: 27,
        },
    },
    label: {
        display: 'flex',
        alignItems: 'center',
    },
    TextField: {
        width: '100%',
        '& .MuiInputBase-formControl': {
            height: '43px !important'
        }
    },
    status: {
        display: 'contents'
    }
}))
const sampleobj = { "firstName": true, "lastName": true, "email": true } // ,"phone": true

const BasicInfo = (props) => {

    const classes = useStyles();
    const [state, setState] = useState({ status: 'Active' })
    // const [validate, setValidate] = useState({})
    let history = useHistory();
    useEffect(() => {
        if (props.memberDetails) {
            setState({ ...state, ...props.memberDetails, ...props.fianlFormData })
        }
    }, [props.memberDetails, props.fianlFormData])

    useEffect(() => {
        return () => {
            setState({})
        }
    }, [])

    useEffect(() => {
        if (props.formData?.document?.[props.data?.chartUniqueId + '_create_member']?.message) {
            toast.success(props.formData.document[props.data?.chartUniqueId + '_create_member'].message);
            props.history.push({ pathname: `/${props.match.params.appid}/page/userManagement/` });
        }
    }, [props.formData?.document?.[props.data?.chartUniqueId + '_create_member']])

    useEffect(() => {
        if (props.formData?.document?.[props.data?.chartUniqueId + '_terminate_member']?.message) {
            toast.success(props.formData.document[props.data?.chartUniqueId + '_terminate_member'].message);
            props.history.push({ pathname: `/${props.match.params.appid}/page/userManagement/` });
        }
    }, [props.formData?.document?.[props.data?.chartUniqueId + '_terminate_member']])


    const handlesFormInputs = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
        props.handlesSaveFunctionality({ ...state, [e.target.name]: e.target.value })
        props.validator({ [e.target.name]: e.target.value ? false : true })
    }

    const conditionCheck = (sampleobj) => {
        if (Object.values(sampleobj).every(ele => !ele)) {
            return true
        } else if (Object.values(sampleobj).every(ele => ele)) {
            return true
        } else if (Object.values(sampleobj).some(ele => ele)) {
            return false
        }
    }

    const handlesFinalSubmit = () => {
        if (Object.keys(state).length === 0 || Object.keys(props.validate).length === 0) {
            props.validator({ firstName: true, lastName: true, email: true })
        } else if (Object.keys(props.validate).length === 3 && conditionCheck(props.validate)) {
            let payload = { ...state, ...props.fianlFormData, appId: props.appid }
            if (payload && payload.roles?.length) {
                payload['roles'] = payload.roles.map(item => {
                    return {
                        id: item.value,
                        name: item.label,
                        label: item.label
                    }
                })
            }
            if (payload && payload.groups?.length) {
                payload['groups'] = payload.groups.map(item => {
                    return {
                        id: item.value,
                        name: item.label,
                        label: item.label
                    }
                })
            }
            if (payload && payload.supervisor?.value) {
                payload['supervisor'] = {
                    id: payload.supervisor.id,
                    name: payload.supervisor.name
                }
            }
            console.log("it is payload before users submit", payload)
            props.createMember({
                appId: props.appId,
                dataUniqueId: props.data?.chartUniqueId + "_create_member",
                orgId: props.user.orgId,
                payload
            })
        } else {
            const merge = (sampleobj, validate) => {
                return { ...sampleobj, ...validate };
            }
            let ss = merge(sampleobj, props.validate)
            props.validator({ ...ss })
        }
    }

    const handleCancel = () => {
        history.goBack()
    }

    const handleEnabaleDisableUserClick = (url) => {
        props.terminateMember({
            appId: props.appId,
            dataUniqueId: props.data?.chartUniqueId + "_terminate_member",
            orgId: props.user.orgId,
            id: props.fianlFormData?._id,
            dataLink: url
            // payload: {id:props.fianlFormData?._id,appId:props.appid}
        })
    }
    const handleOnSelectHandler = (data, type) => {
        setState({ ...state, [type]: data })
        props.handlesSaveFunctionality({ ...state, [type]: data })
    }

    const isLoading = () => {
        return props.formData?.[props.data?.chartUniqueId + "_create_member_loading"]
    }
    const isTerminateLoading = () => {
        return props.formData?.[props.data?.chartUniqueId + "_terminate_member_loading"]
    }

    return (
        <Grid>
            <Grid container xs={6} spacing={3} className={classes.gridContainer}>
                <Grid item xs={3} sm={3} className={classes.label}>
                    <InputLabel htmlFor="my-input">Email</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                    {
                        !props.fianlFormData?._id ?
                            <TextField
                                variant="outlined"
                                className={classes.TextField}
                                placeholder={'Enter Email id'}
                                onChange={handlesFormInputs}
                                name='email'
                                value={state.email}
                                error={props.validate.email ? true : false}
                                id="standard-error-helper-text"
                                helperText={props.validate.email ? 'Email is required' : ''}
                            />
                            :
                            <div>{state.email}</div>

                    }
                </Grid>


                <Grid item xs={3} sm={3} className={classes.label}>
                    <InputLabel htmlFor="my-input" className={classes.InputLabel}>First Name</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                    <TextField
                        variant="outlined"
                        className={classes.TextField}
                        placeholder={'Enter First Name'}
                        onChange={handlesFormInputs}
                        name={'firstName'}
                        value={state.firstName}
                        error={props.validate.firstName ? true : false}
                        id="standard-error-helper-text"
                        helperText={props.validate.firstName ? 'First Name is required' : ''}
                    />
                </Grid>

                <Grid item xs={3} sm={3} className={classes.label}>
                    <InputLabel htmlFor="my-input">Last Name</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                    <TextField
                        variant="outlined"
                        className={classes.TextField}
                        placeholder={'Enter Last Name'}
                        onChange={handlesFormInputs}
                        name={'lastName'}
                        value={state.lastName}
                        error={props.validate.lastName ? true : false}
                        id="standard-error-helper-text"
                        helperText={props.validate.lastName ? 'Last Name is required' : ''}
                    />
                </Grid>

                {/* <Grid item xs={3} sm={3}>
                    <InputLabel htmlFor="my-input">Contact</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                    <TextField 
                        variant="outlined" 
                        className={classes.TextField} 
                        placeholder={'Enter Contact'}
                        onChange={handlesFormInputs} 
                        name={'phone'}
                        value={state.phone}

                        error={props.validate.phone?true:false}
                        id="standard-error-helper-text"
                        helperText={props.validate.phone?'Contact is required':''} 
                    />
                </Grid> */}

                {/* <Grid item xs={12} sm={12}>
                    <InputLabel htmlFor="my-input">Status</InputLabel>
                    <RadioGroup aria-label="status" name="status" value={state.status} onChange={handlesFormInputs} className={classes.status}>
                        <FormControlLabel value="Active" control={<Radio />} label="Active" />
                        <FormControlLabel value="In Active" control={<Radio />} label="In Active" />
                        <FormControlLabel value="Suspended" control={<Radio />} label="Suspended" />
                        <FormControlLabel value="Terminated" control={<Radio />} label="Terminated" />
                    </RadioGroup>
                </Grid> */}
                {/* <Grid container xs={6} spacing={3} className={classes.gridContainer}> */}
                <Grid item xs={3} sm={3} className={classes.label}>
                    <InputLabel htmlFor="my-input">Select user roles</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                    <ChipSelect
                        className={classes.chipSelect}
                        id='role'
                        onChange={(val) => handleOnSelectHandler(val, 'roles')}
                        value={props.fianlFormData?.roles}
                        isSearchable={true}
                        isMulti
                        textFieldProps={{
                            InputLabelProps: {
                                shrink: true,
                            },
                            variant: 'outlined',
                        }}
                        variant="fixed"
                        required
                        options={props.membersRoles}
                    />
                </Grid>
                <Grid item xs={3} sm={3} className={classes.label} >
                    <InputLabel htmlFor="my-input">Select Groups</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                    <ChipSelect
                        id='group'
                        className={classes.chipSelect}
                        onChange={(val) => handleOnSelectHandler(val, 'groups')}
                        value={props.fianlFormData?.groups}
                        isSearchable={true}
                        isMulti
                        textFieldProps={{
                            InputLabelProps: {
                                shrink: true,
                            },
                            variant: 'outlined',
                        }}
                        variant="fixed"
                        options={props.membersGroup}
                    />
                </Grid>
                <Grid item xs={3} sm={3} className={classes.label} >
                    <InputLabel htmlFor="my-input" className={classes.InputLabel}>Select Supervisor</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                    <ChipSelect
                        id='supervisor'
                        className={classes.chipSelect}
                        onChange={(val) => handleOnSelectHandler(val, 'supervisor')}
                        value={props.fianlFormData?.supervisor}
                        isSearchable={true}
                        textFieldProps={{
                            InputLabelProps: {
                                shrink: true,
                            },
                            variant: 'outlined',
                        }}
                        variant="fixed"
                        options={props.membersList.filter((mem) => mem?.id !== props.fianlFormData._id)}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button style={{ float: 'right' }}
                        variant="contained"
                        color="primary"
                        onClick={handlesFinalSubmit}>
                        {isLoading() ?
                            <CircularProgress style={{ color: '#ffff', }} size={24} /> :
                            (props.fianlFormData?._id ? "Update" : "Save")}
                    </Button>
                    {props.fianlFormData?._id &&
                        <>
                            <Button style={{ float: 'right', marginRight: 10 }}
                                variant="contained" color="secondary"
                                onClick={handleCancel}>{'Cancel'}</Button>
                            <Button
                                style={{
                                    float: 'right',
                                    marginRight: 10
                                }}
                                variant="contained"
                                color="secondary"
                                onClick={() => handleEnabaleDisableUserClick(props.fianlFormData && props.fianlFormData.isDisable ? 'enable' : 'disable')}>
                                {isTerminateLoading() ? <CircularProgress style={{ color: '#ffff', }} size={24} /> : (props.fianlFormData?.isDisable ? 'Activate' : 'Terminate')}
                            </Button>

                        </>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default BasicInfo