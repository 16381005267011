import React,{useEffect,useState} from 'react'
import { TextField,InputLabel,Grid,Paper,Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core"
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    paper: {
    },
    gridContainer:{

    },
    InputLabel:{
        marginTop:20
    },
    TextField:{
        width:'100%',
        '& .MuiInputBase-formControl':{
            height:'43px !important'
        }
    },
    status:{
        display:'contents'
    }
}))
const sampleobj  = {"chargeCode": true}

const BasicInfo =(props)=>{
    console.log('Charge Code-BasicInfo-props',props)
    const classes = useStyles();
    const [state, setState] = useState({status:'Active'})
    // const [validate, setValidate] = useState({})
    const { _id:userId } = props.member
    let history = useHistory();

    
      useEffect(()=>{
          if(props.memberDetails){
              setState({...state,...props.memberDetails,...props.fianlFormData})
          }
      },[props.memberDetails,props.fianlFormData])

      useEffect(()=>{
          return ()=>{
              setState({})
          }
      },[])

    useEffect(()=>{
        if(props.fianlFormData){
            setState({...props.fianlFormData})
        }

    },[props.fianlFormData])


    const handlesFormInputs = (e)=>{
        setState({...state,[e.target.name]:e.target.value})
        props.handlesSaveFunctionality({...state,[e.target.name]:e.target.value})
        props.validator({[e.target.name]:e.target.value?false:true})
    }

    const conditionCheck =(sampleobj)=>{
        if(Object.values(sampleobj).every(ele=>!ele)){
            return true
          } else if (Object.values(sampleobj).every(ele=>ele)){
            return true
          } else if(Object.values(sampleobj).some(ele=>ele)){
            return false
          }
    }

    const handlesFinalSubmit = ()=>{
        if(Object.keys(state).length===0 || Object.keys(props.validate).length===0){
            props.validator({chargeCode:true})
        }else if(Object.keys(props.validate).length===1 && conditionCheck(props.validate) ){
            props.createChargeCode({
                appId: props.appId,
                dataUniqueId:  props.data?.chartUniqueId+'_create_chargecode',
                orgId: props.user.orgId,
                payload: {...state,...props.fianlFormData},
                memberid: userId,
                dataModelId: props.data?.chartData?.selectedDataModel
            })
        }else {
            const merge = (sampleobj, validate) => {
                return { ...sampleobj, ...validate};
              }
            let ss = merge(sampleobj,props.validate)
            props.validator({...ss})
        }
      }


    useEffect(() => {
        if (props.formData?.document && props.formData?.document[props.data?.chartUniqueId+'_create_chargecode'] && props.formData?.document[props.data?.chartUniqueId+'_create_chargecode']?.message) {
            toast.success(props.formData?.document[props.data?.chartUniqueId+'_create_chargecode']?.message);
            props.history.push({ pathname: `/${props.match.params.appid}/page/chargecodes/`});   
        }
        if (props.formData?.document && props.formData?.document[props.data?.chartUniqueId+'_create_chargecode']?.chargeCode) {
            toast.success(props.formData?.document[props.data?.chartUniqueId+'_create_chargecode']?.chargeCode+' added successfully ');
            props.history.push({ pathname: `/${props.match.params.appid}/page/chargecodes/`});   
        }
        return ()=>{
            props.clearReduxDataOfCurrentComponent({
                key: "document",
                subKey: [props.data?.chartUniqueId+'_create_chargecode']
            })
        }
    }, [props.formData && props.formData?.document[props.data?.chartUniqueId+'_create_chargecode']])

    const handleCanclebtnClick = () => {
        history.goBack()
    }

    return (
            <Grid container xs={6} spacing={3} className={classes.gridContainer}>
                <Grid item xs={3} sm={3} >
                    <InputLabel htmlFor="my-input" className={classes.InputLabel}>Charge Code</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                    <TextField  
                        variant="outlined" 
                        className={classes.TextField} 
                        placeholder={'Enter Charge Code'}
                        onChange={handlesFormInputs}
                        name='chargeCode'
                        value={state.chargeCode}
                        error={props.validate.chargeCode?true:false}
                        id="standard-error-helper-text"
                        helperText={props.validate.chargeCode?'Charge Code is required':''} 
                        />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button style={{float:'right'}} variant="contained" color="primary" onClick={handlesFinalSubmit}>{props.fianlFormData?._id?"Update":"Save"}</Button>
                    {props.fianlFormData?._id && 
                        <Button style={{float:'right', marginRight:10}} variant="contained" color="secondary" onClick={handleCanclebtnClick}>{'Cancle'}</Button>}
                </Grid>
            </Grid>
    )
}

export default BasicInfo