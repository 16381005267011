import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'; // Success case
import ErrorIcon from '@material-ui/icons/Error'; // Failed Case
import InfoIcon from '@material-ui/icons/Info'; // Init Case
import { Ellipses } from '@styled-icons/open-iconic/Ellipses'; // Pending / Processing case

const GetIconByStatus = (props) => {
    const { status, fontSize = 16 } = props;
    const Icons = {
        Init: <InfoIcon fontSize='small' color='primary' style={{ fontSize}} />,
        Processing: <Ellipses style={{ color: 'black', height: 22 }} />,
        Pending: <Ellipses style={{ color: 'black', height: 22 }} />,
        Completed: <CheckCircleIcon fontSize='small' style={{ color: 'green', fontSize }} />,
        Failed: <ErrorIcon fontSize='small' color='error' style={{ fontSize: 16 }} />
    }
    return Icons?.[status] || <InfoIcon fontSize='small' color='primary' style={{ fontSize }} />;
}

export default GetIconByStatus