import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import TableContainer from "@material-ui/core/TableContainer"
import { AdvancedTable, TableSupport, ConfirmationHandler } from "dsilo-ui-components"
import React, { useEffect, useState } from "react"
import { hot } from "react-hot-loader/root"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { PUBLIC_PATH } from "../../constants"
import { getDataByUrl, deleteDatapoint } from "../../store/actions"
import { Loader } from "../loader"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    headerClass: {
        backgroundColor: "#cecece"
    },
    confirmationDialoge: {
        "& .MuiDialog-paperWidthSm": {
            height: "250px",
            width: "600px"
        }
    },
    para: {
        fontSize: "28px !important",
        fontWeight: 600,
        color: "#000"
    }
}))

const DatapointsListWrapper = (props) => {
    const sharedJSON = {
        order: "asc",
        orderBy: null,
        selected: [],
        tableData: [],
        filterTableData: [],
        page: 0,
        count: 10,
        rowsPerPage: props.data?.chartData?.pagination?.rowsPerPage
            ? props.data?.chartData?.pagination?.rowsPerPage
            : 10,
        searchText: "",
        filterList: [],
        filterData: []
    }

    const classes = useStyles()

    const [state, setState] = useState({
        ...sharedJSON
    })
    const [conditions, setConditions] = useState([])
    const [filterConditions, setFilterConditions] = useState([])
    const [fetched, setFetched] = useState(false)
    const [parentInvoked, setParentInvoked] = useState(false)
    const [parentData, setParentData] = useState([])
    const [showTable, setShowTable] = useState(false)
    const [deleteConfirmState, setDeleteConfirm] = useState({
        isOpen: false,
        deleteMessage: "Are you sure you want to delete ?",
        data: null
    })
    const { apiData } = props.formData
    const { data } = props
    const { chartData } = props.data

    const {
        fetchType,
        selectedDataModel,
        selectedDataElement,
        externalFilter,
        download,
        selectedInnerDataElement,
        isFiltersInterConnected
    } = props.data?.chartData
    const { externalFilterData } = props.formData


    useEffect(() => {
        fetchData()
    }, []);


    const fetchData = ()=>{
        let payload = {
            url: '/datapoints',
            appId: props.match.params.appid,
            dataUniqueId: props.data.chartUniqueId + '_getDataPoints'
        }
        props.getDataByUrl(payload)
    }


    let reducerData = props.formData?.apiData ? props.formData?.apiData[props.data.chartUniqueId + "_getDataPoints"] : []

    console.log('reducerData', reducerData)
    useEffect(() => {
        if (apiData && apiData[props.data.chartUniqueId + "_getDataPoints"]) {
            const keysArr = ["_id"]
            setState({
                ...TableSupport.setDatafromApi2(
                    getData(apiData[props.data.chartUniqueId + "_getDataPoints"]),
                    keysArr,
                    state,
                    getColumns()
                ),
                count: apiData[props.data.chartUniqueId + "_getDataPoints"]?.length
            })
        }
    }, [reducerData])

    const getData = (dataSource) => {
        const clonedDataSource = JSON.parse(JSON.stringify(reducerData))
        return clonedDataSource.map((item) => {
            const keys = Object.keys(item)
            keys.forEach((key) => {
                if (key && typeof item[key] !== "string") {
                    item[key] = item[key] ? JSON.stringify(item[key]) : ""
                }
            })
            return item
        })
    }


    const getColumns = () => {
        let COLUMNS = [
            {
                id: 'name',
                numeric: false,
                disablePadding: false,
                label: 'Datapoint name',
                sort: true
            }
        ]

        return COLUMNS
    }

    const handleChangePage = (event, page) => {
        setState({ ...state, page: page })
    }

    const handleChangeRowsPerPage = (event) => {
        setState({ ...state, rowsPerPage: event.target.value, page: 0 })
    }

    const onRowClick = (rowData, rowMeta) => {
        console.log("onRowClick")
    }

    const searchHandler = (e) => {
        setState({ ...state, searchText: e ? e.target.value : "" })
    }

    const handleInputChange = (event) => {
        console.log(
            "value:" + event.target.value + " checked: " + event.target.checked
        )
        const obj = state.filterTableData
        const list = obj.columns
        list.map((item, index) => {
            if (item.name === event.target.value)
                list[index].checked = event.target.checked
        })
        obj.columns = [...list]
        setState({ ...state, filterTableData: obj })
        setState({ ...TableSupport.updateTableData(state) })
    }

    const resetFilters = (event) => {
        setState({ ...state, filterList: [] })
    }

    const handleDropdownChange = (event, index, column) => {
        const value = event.target.value === "All" ? [] : [event.target.value]
        // filterUpdate(index, value, column, 'dropdown');
        const list = state.filterList
        list[index] = event.target.value
        setState({ ...state, filterList: [...list] })
        setState({ ...TableSupport.updateTableData(state) })
    }

    const handleRequestSort = (event, property) => {
        const obj = TableSupport.requestSort(event, property, state)
        console.log("obj", obj)
        setState({ ...state, ...obj })
    }

    const handleSelectAllClick = (event) => {
        setState({
            ...state,
            selected: TableSupport.handleTableSelectAllClick(
                event,
                state.filterTableData.datasets
            )
        })
    }

    const tableCrudOperations = (item, type) => {
        console.log(item)
        if (type === "edit") {
            props.history.push({
                pathname: `/${PUBLIC_PATH}${props.match.params.appid}/page/${chartData.editPage}/${item._id}`
            })
        }
        if (type === "view") {
            props.history.push({
                pathname: `/${PUBLIC_PATH}${props.match.params.appid}/page/${chartData.viewPage}/${item._id}`
            })
        }
        if (type === "delete") {
            setDeleteConfirm({
                ...deleteConfirmState,
                isOpen: true,
                data: item._id
            })
        }
        if (type === "download") {
        }
        if (type === "link") {
            props.history.push({
                pathname: `/${PUBLIC_PATH}${props.match.params.appid}/page/${chartData.link}/${item._id}`
            })
        }
    }

    const handleCheck = (event, id) => {
        const { selected } = state
        const newSelected = TableSupport.handleSelectedRow(event, id, selected)
        setState({ ...state, selected: newSelected })
    }

    const onApiDownloadClick = (config, index) => {
        console.log("download api data", config, index)

        const {
            download,
            selectedDataElement,
            fetchType,
            selectedInnerDataElement
        } = props.data.chartData
        if (download) {
            const payload = {
                dataModelId: selectedDataModel,
                appid: props.match.params.appid,
                fields: selectedDataElement.map((i) => i.value).join(","),
                downloadType: "excel"
            }
            if (fetchType) {
                payload.fetchType = fetchType
            }
            console.log({ selectedInnerDataElement })
            if (selectedInnerDataElement?.length) {
                payload.viewFields = selectedInnerDataElement
                    .map((i) => i.value)
                    .join(",")
            }
            payload.conditions = [...conditions, ...filterConditions]

            props.downloadSheet(payload)
        }
    }

    const onAddNewClick = () => {
        props.history.push({
            pathname: `/${PUBLIC_PATH}${props.match.params.appid}/page/${chartData.associatedFormName}`
        })
    }
    const onDeleteResponse = (isDelete) => {
        console.log('deleteConfirmState', deleteConfirmState)
        if (isDelete) {
            let payload = {
                "ids": [deleteConfirmState.data],
                appId: props.match.params.appid,
            }
            payload.url = '/datapoints'
            payload.appId = props.match.params.appid
            props.deleteDatapoint(payload)
        }
        setDeleteConfirm({ ...deleteConfirmState, isOpen: false, data: null });
        fetchData();
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                {state.tableData && (
                    <TableContainer
                        style={{ maxWidth: "92vw" }}
                        component={Paper}
                    >
                        {state.tableData && (
                            <AdvancedTable
                                onAddClick={onAddNewClick}
                                title={chartData.title}
                                key={props.data.chartUniqueId}
                                name='ContractList'
                                onRowClick={onRowClick}
                                {...props}
                                {...state}
                                chartData={chartData}
                                searchHandler={searchHandler}
                                isExport={download}
                                isViewCoulmns
                                isSearch
                                isFilter
                                hasCheckbox={false}
                                actions={{
                                    edit: chartData.editPage,
                                    view: chartData.viewPage,
                                    delete: chartData.delete,
                                    copy: false,
                                    download: true,
                                    link: chartData.link
                                }}
                                isTablePagination
                                isTableHead
                                isTableSubHeader={false}
                                exportArray={
                                    download === "local"
                                        ? [
                                            {
                                                key: "xls",
                                                name: "Excel",
                                                isVisible: true
                                            },
                                            {
                                                key: "csv",
                                                name: "csv",
                                                isVisible: true
                                            }
                                        ]
                                        : [
                                            {
                                                key: "xlsx",
                                                name: "Excel",
                                                isVisible: true
                                            }
                                        ]
                                }
                                handleInputChange={handleInputChange}
                                handleExoprtItemClick={onApiDownloadClick}
                                resetFilters={resetFilters}
                                pagination={props.data.chartData?.pagination}
                                handleDropdownChange={handleDropdownChange}
                                chartData={props.data.chartData}
                                handleRequestSort={handleRequestSort}
                                handleSelectAllClick={handleSelectAllClick}
                                tableCrudOperations={tableCrudOperations}
                                handleCheck={handleCheck}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={
                                    handleChangeRowsPerPage
                                }
                                onExternalFilterChange={() => { }}
                                externalFilterData={
                                    externalFilterData
                                        ? fetchType
                                            ? [
                                                ...(externalFilterData[
                                                    props.data
                                                        .chartUniqueId +
                                                    "_externalFilter_viewFields"
                                                ]?.data || []),
                                                ...(externalFilterData[
                                                    props.data
                                                        .chartUniqueId +
                                                    "_externalFilter_fields"
                                                ]?.data || [])
                                            ]
                                            : externalFilterData[
                                                props.data.chartUniqueId +
                                                "_externalFilter"
                                            ]?.data
                                        : []
                                }
                                externalFilters={externalFilter}
                                externalFilterValues={filterConditions}
                                stripRows // for changing BGcolor of odd rows
                                headerstyle={{
                                    fontSize: "14px",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    textAlign: "left",
                                    backgroundColor: "#"
                                }}
                                subHeaderstyle={{
                                    fontSize: "14px",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    backgroundColor: "#FFF",
                                    textAlign: "left"
                                }}
                                headerClass={classes.headerClass}
                                rowStyle={{
                                    fontSize: "14px",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    backgroundColor: "none",
                                    lineHeight: 1.5,
                                    textAlign: "left"
                                }}
                                isLoading={
                                    props.formData[props.data.chartUniqueId + "_getDataPoints_loading"]
                                }
                                loader={<Loader />}
                            />
                        )}
                    </TableContainer>
                )}
            </Paper>
            <ConfirmationHandler open={deleteConfirmState.isOpen} handleClose={onDeleteResponse}
                alertMessageText={deleteConfirmState.deleteMessage} confirm />
        </div>
    )
}

const mapDispatchToProps = {
    getDataByUrl, deleteDatapoint
};

const mapStateToProps = state => {
    return {
        appConfig: state.appConfig.app,
        apps: state.app.app,
        login: state.login,
        user: state.user,
        formData: state.action,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(DatapointsListWrapper)));
