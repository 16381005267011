import { ActionTypes } from "../constants";

const initialState = {
    data: {},
    error: {},
};
export default function dashboardReducer(state = initialState, action) {

    switch (action.type) {
        case ActionTypes.GET_API_DATA: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true,
            };
        }
        case ActionTypes.GET_API_DATA_SUCCESS: {
            let updatedData = { ...state.data, [action.payload.dataUniqueId]: action.payload.data }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.GET_API_DATA_FAILURE: {
            let updatedError = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: updatedError,
            }
        }
        default: {
            return state;
        }
    }
}