import { Button, Container, Divider, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from "react-toastify";
import { getGroups, deleteGroup, clearReduxDataOfCurrentComponent, clearSuccessMsg } from "../../store/actions";
import FieldTable from "../FieldTable";
import { Loader } from '../loader'
import { AdvancedTable, TableSupport } from 'dsilo-ui-components'
import ConfirmationHandler from '../../components/ConfirmDialog'

const useStyles = makeStyles({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 10,
    },
    root: {
        width: "100%"
    },
    headerClass: {
        backgroundColor: "#cecece",
        textTransform: 'capitalize'
    },
    confirmationDialoge: {
        "& .MuiDialog-paperWidthSm": {
            height: "250px",
            width: "600px"
        }
    },
    para: {
        fontSize: "28px !important",
        fontWeight: 600,
        color: "#000"
    }
});

const sharedJSON = {
    "order": "asc",
    "orderBy": null,
    "selected": [],
    "tableData": [],
    "filterTableData": [],
    // "page": 0,
    "count": 10,
    // "rowsPerPage": 10,
    "searchText": "",
    "filterList": [],
    "filterData": []
}

const tableJson = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Document Type',
        sort: true,
    },
    {
        id: 'keyValueFields',
        numeric: false,
        disablePadding: false,
        label: 'Key Data Fields',
        sort: true,
    },

];



const Groups = (props) => {
    const { data } = props
    const { chartData } = data

    const classes = useStyles()
    const [groupList, setGroupList] = useState([])
    const [state, setState] = useState({ ...sharedJSON, });
    const [searchedText, setSearchedText] = useState('');
    const [deleteConfirmState, setDeleteConfirm] = useState({
        isOpen: false,
        deleteMessage: "Are you sure you want to delete ?",
        data: null
    })
    const { successMsg } = props.formData
    const { _id: userId } = props.user
	const pageUrl = props.page?.url
    const [localStoageObject, setLocalStoageObject] = useState({})
    const [isHighlighted, setHighLighted] = useState({});
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [highLighterIdForSelectedLineItem, setHighLighterIdForSelectedLineItem] = useState('');



    useEffect(() => {
        let localStoreJson = localStorage.getItem('userSession');
        let currentUserSes
        if (localStoreJson) {
          let currentSession = JSON.parse(atob(localStoreJson));
          if (Object.keys(currentSession).length > 0) {
            if (currentSession && currentSession[userId] && currentSession[userId][pageUrl]) {
              currentUserSes = currentSession[userId][pageUrl];
              if(currentUserSes?.page){
                setPage(Number(currentUserSes?.page))
              }
              if(currentUserSes?.rowsPerPage){
                setRowsPerPage(Number(currentUserSes?.rowsPerPage))
              }
            }
          }
        }
        fetchData()
    }, [])

    useEffect(() => {
        if (props.formData?.document && props.formData.document[props.data?.chartUniqueId + '_data']?.length) {
            let groupListData = props.formData?.document[props.data?.chartUniqueId + '_data'].map(k => ({
                id: k._id,
                name: k.name,
                description: k.description,
                members: k.members.map((m) => m.name).join(', ')
            }))
            setGroupList(groupListData)
        }
    }, [props.formData && props.formData.document[props.data?.chartUniqueId + '_data']])

    useEffect(() => {
        setState({ ...TableSupport.updateTableData(state) });
    }, [state.searchText, state.filterList]);

    useEffect(() => {
        if (groupList) {
            const rows = tableJson;
            // const rows = listViewTableJson;
            let keysArr = ['_id'];
            // setState({ ...TableSupport.setDatafromApi2(groupList, keysArr, state, rows) });
            setState({
                ...TableSupport.setDatafromApi2(
                    getData(groupList),
                    keysArr,
                    state,
                    getColumns()),
                count: groupList.length
            });
            isHighlightedTile()
        }
    }, [groupList]);

    useEffect(() => {
        return () => {
            clearCurrentChartReduxStore()
        }
    }, [])


    useEffect(() => {
        if (successMsg) {
            toast.info(successMsg)
            props.clearSuccessMsg()
            fetchData()
        }
    }, [successMsg])

    useEffect(() => {
        if (localStorage.getItem('userSession')) {
          let userSession = JSON.parse(atob(localStorage.getItem('userSession')))
          if (Object.keys(userSession).length === 0) {
            if (Object.keys(localStoageObject).length > 0) {
              localStorage.setItem('userSession', btoa(JSON.stringify({ [userId]: { [pageUrl]: { ...localStoageObject } } })))
            }
          } else {
            for (let [key, value] of Object.entries(userSession)) {
              if (key === userId) {
                for (let [key1, value1] of Object.entries(value)) {
                  if (key1 === pageUrl) {
                    userSession[userId][pageUrl] = { ...value1, ...localStoageObject }
                    localStorage.setItem('userSession', btoa(JSON.stringify(userSession)))
                  } else {
                    if (Object.keys(localStoageObject).length > 0) {
                      userSession[userId][pageUrl] = { ...localStoageObject }
                      localStorage.setItem('userSession', btoa(JSON.stringify(userSession)))
                    }
                  }
                }
              }
            }
          }
        } else if (Object.keys(localStoageObject).length > 0) {
          localStorage.setItem('userSession', btoa(JSON.stringify({ [userId]: { [pageUrl]: { ...localStoageObject } } })))
        }
      }, [localStoageObject])


      const isHighlightedTile = async () => {
        let userSession = await localStorage.getItem('userSession')
        if (userSession) {
            userSession = JSON.parse(atob(userSession))
            let currentUserSession = userSession && userSession[userId]
			let currentUserSessionPage = userSession && currentUserSession && currentUserSession[pageUrl]
            if (currentUserSessionPage?.selectedLineItem) {
                setHighLighterIdForSelectedLineItem(currentUserSessionPage?.selectedLineItem)
            }
        }
    }


    const getData = (dataSource) => {
        const clonedDataSource = JSON.parse(JSON.stringify(dataSource))
        let fetchType;
        return clonedDataSource.map((item) => {
            const keys = Object.keys(item)
            keys.forEach((key) => {
                if (key && typeof item[key] !== "string") {
                    if (fetchType === "keysArray" && !selectedInnerSupportDataElement) {
                        if (data?.chartData?.selectedInnerDataElement?.length) {
                            data?.chartData?.selectedInnerDataElement?.map(side => {
                                if (side.value === key) {
                                    if (side.type === "namepicker") {
                                        if (item[key] instanceof Array) {
                                            item[key] = item[key].map(obj => obj.label).join(', ')
                                        } else {
                                            item[key] = item[key]
                                                ? (typeof item[key] === "object"
                                                    ? item[key][label]
                                                    : item[key])
                                                : ""
                                        }
                                    } else {
                                        item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                                    }
                                }
                            })
                        } else {
                            item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                        }
                    } else {
                        if (data.chartData?.selectedDataElement?.length) {
                            data.chartData?.selectedDataElement?.map(sde => {
                                if (sde.value === key) {
                                    if (sde.type === "namepicker") {
                                        if (item[key] instanceof Array) {
                                            item[key] = item[key].map(obj => obj.label).join(', ')
                                        } else {
                                            item[key] = item[key]
                                                ? (typeof item[key] === "object"
                                                    ? item[key][label]
                                                    : item[key])
                                                : ""
                                        }
                                    } else {
                                        item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                                    }
                                }
                            })
                        } else {
                            item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                        }
                    }
                    // item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                }
            })
            return item
        })
    }

    const getColumns = () => {
        let COLUMNS = []
        if (groupList.length) {
            COLUMNS = Object.keys(
                groupList[0]
            ).map((i) => {
                return {
                    id: i,
                    numeric: false,
                    disablePadding: false,
                    label: i,
                    sort: true,
                    checked: true
                }
            }).filter(i => i.id !== 'id')
        }
        return COLUMNS
    }

    const fetchData = () => {
        const payload = {
            "appId": props.appId,
            "orgId": props.user.orgId,
            "dataUniqueId": props.data?.chartUniqueId + '_data'
        }
        props.getGroups(payload)
    }

    const onRowClick = (rowData, rowMeta) => {
        console.log('onRowClick');
    };

    const onSearchEnter = (e, onSearchButton = false) => {
        if (e.keyCode === 13 || onSearchButton) {
            // write api call here
        }
    }

    const handleChangePage = (event, page) => {
        setPage(page)
        setLocalStoageObject({ ...localStoageObject, page: page })
    };

    const onAddClick = () => {
        props.history.push({ pathname: `/${props.match.params.appid}/page/group/new` });
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value)
        setPage(0)
        setLocalStoageObject({ ...localStoageObject, rowsPerPage: event.target.value, page: 0 })
    };

    const searchHandler = (e) => {
        setSearchedText(e ? e.target.value : "")
    }

    const handleInputChange = event => {
        let obj = state.filterTableData;
        let list = obj.columns;
        list.map((item, index) => {
            if (item.name === event.target.value) list[index].checked = event.target.checked;
        });
        obj.columns = [...list];
        setState({ ...state, filterTableData: obj });
        setState({ ...TableSupport.updateTableData(state) });
    };

    const resetFilters = event => {
        setState({ ...state, filterList: [] });
    };

    const handleDropdownChange = (event, index, column) => {
        const value = event.target.value === 'All' ? [] : [event.target.value];
        // filterUpdate(index, value, column, 'dropdown');
        let list = state.filterList;
        list[index] = event.target.value;
        setState({ ...state, filterList: [...list] });
        setState({ ...TableSupport.updateTableData(state) });
    };

    const handleRequestSort = (event, property) => {
        let obj = TableSupport.requestSort(event, property, state);
        setState({ ...state, ...obj });
    };

    const handleSelectAllClick = event => {
        setState({
            ...state,
            selected: TableSupport.handleTableSelectAllClick(event, state.filterTableData.datasets),
        });
    };

    const tableCrudOperations = (item, type) => {
        if (type === 'edit') {
            onClickAnyAction(item.id)
            props.history.push({
                pathname: `/${props.match.params.appid}/page/${chartData.editPage}/${item.id}`,
            })
        }
        if (type === 'delete') {
            setDeleteConfirm({
                ...deleteConfirmState,
                isOpen: true,
                data: item.id
            })
        }

    };

    const onDeleteResponse = (isDelete) => {
        if (isDelete) {
            const payload = {
                appid: props.appId,
                dataUniqueId: props.data?.chartUniqueId + '_delete_group',
                orgId: props.user?.orgId,
                id: deleteConfirmState.data
            }
            props.deleteGroup(payload)
        }
        setDeleteConfirm({ ...deleteConfirmState, isOpen: false, data: null })
    }

    const handleCheck = (event, id) => {
        const { selected } = state;
        let newSelected = TableSupport.handleSelectedRow(event, id, selected);
        setState({ ...state, selected: newSelected });
        props.checkedContractChanged(newSelected);
    };

    const onClickAnyAction = (docId) => {
        const { _id: userId } = props.user
        if (localStorage.getItem('userSession')) {
            let userSession = JSON.parse(atob(localStorage.getItem('userSession')))
            for (let [key, value] of Object.entries(userSession)) {
                if (key === userId) { // userId matched
                    for (let [key1, value1] of Object.entries(value)) {
                        if (key1 === pageUrl) { // page matched
                            userSession[userId][pageUrl] = { ...value1, 'selectedLineItem': docId }
                            localStorage.setItem('userSession', btoa(JSON.stringify(userSession)))
                        } else {
                            if (Object.keys(userSession).length > 0) {
                                userSession[userId][pageUrl] = {...value1, 'selectedLineItem': docId }
                                localStorage.setItem('userSession', btoa(JSON.stringify(userSession)))
                            }
                        }
                    }
                }
            }
        } else {
            localStorage.setItem('userSession', btoa(JSON.stringify({ [userId]: { [pageUrl]: { 'selectedLineItem': docId } } })))
        }
      }

    const clearCurrentChartReduxStore = () => {
        let subKeys = [
            props.data?.chartUniqueId + '_data',
            props.data?.chartUniqueId + '_create_group'
        ]

        props.clearReduxDataOfCurrentComponent({
            key: "document",
            subKey: subKeys
        })
    }

    return (
        <>
            {props.formData[props.data?.chartUniqueId + '_data_loading'] ? <Loader /> :
                <AdvancedTable
                    name={'ContractList'}
                    onRowClick={onRowClick}
                    {...props}
                    {...state}
                    chartData={chartData}
                    searchedText={searchedText}
                    searchHandler={searchHandler}
                    onSearchEnter={onSearchEnter}
                    isExport={false}
                    isViewCoulmns={false}
                    isSearch={false}
                    isFilter={false}
                    hasCheckbox={false}
                    actions={{ edit: true, view: false, delete: true, copy: false, download: false }}
                    isTablePagination={true}
                    pagination={{ type: 'local' }}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    isTableHead={true}
                    isTableSubHeader={false}
                    associatedFormName={chartData.associatedFormName}
                    handleInputChange={handleInputChange}
                    onAddClick={onAddClick}
                    resetFilters={resetFilters}
                    handleDropdownChange={handleDropdownChange}
                    handleRequestSort={handleRequestSort}
                    handleSelectAllClick={handleSelectAllClick}
                    tableCrudOperations={tableCrudOperations}
                    handleCheck={handleCheck}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    headerstyle={{
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        textAlign: "left",
                        backgroundColor: "#"
                    }}
                    subHeaderstyle={{
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        backgroundColor: "#FFF",
                        textAlign: "left"
                    }}
                    headerClass={classes.headerClass}
                    rowStyle={{
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        backgroundColor: "none",
                        lineHeight: 1.5,
                        textAlign: "left"
                    }}
                    stripRows
                    loader={props.loader}
                    isNestedTable={props.isNestedTable}
                    onExapandIconClick={props.onExapandIconClick}
                    nestedTableData={props.nestedTableData}
                    highLighterIdForSelectedLineItem={highLighterIdForSelectedLineItem}
                />
            }
            <ConfirmationHandler
                open={deleteConfirmState.isOpen}
                handleClose={onDeleteResponse}
                alertMessageText={deleteConfirmState.deleteMessage}
                classes={classes}
                confirm
            />
        </>
    )
}

const mapDispatchToProps = {
    getGroups,
    deleteGroup,
    clearReduxDataOfCurrentComponent,
    clearSuccessMsg
};

const mapStateToProps = state => {
    return {
        formData: state.form,
        member: state.appConfig.members,
        user: state.user,
    };
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(withRouter(Groups)));