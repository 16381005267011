import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { isNumberArray, isObject, isStringArray, supportedHighcharts } from '../../ChatHelper';
import { textMsg } from '../../constants';
import { SimpleList, SimpleTile, TextComponent } from '../index';
import MultiResponseTable from './TableComponent';
import useStyles from './styles';

export const QuestionTitle = ({ title }) => {
    const classes = useStyles();
    return (
        <Typography variant="subtitle1" component="p" className={classes.questionTitle}>
            {title}
        </Typography>
    );
}

export const MultiResponseComponent = (props) => {
    const { index, message, resultData } = props;
    const classes = useStyles();
    const [responseData, setResponseData] = useState([]);

    const getItemSize = item => {
        const { output, outputType } = item;
        const fullColumns = ['stringNumberArray', 'tile', 'table'];
        const responseType = getResponseType(output, outputType);
        const itemGridSize = fullColumns.indexOf(responseType) !== -1 ? 12 : 6;
        return itemGridSize;
    };

    useEffect(() => {
        let newResponseData = [];
        if (Array.isArray(resultData) && resultData?.length > 1) {
            let isPreviousRowFull = false;
            newResponseData = resultData.map((currentItem, index) => {
                let itemGridSize = getItemSize(currentItem);
                console.log('itemGridSize ::: *** ', itemGridSize, isPreviousRowFull);
                if (itemGridSize === 12) {
                    isPreviousRowFull = true;
                } else {
                    const nextResultItem = resultData?.[index + 1];
                    if (nextResultItem) {
                        let nextItemGridSize = getItemSize(nextResultItem);
                        if (nextItemGridSize === 12 && (isPreviousRowFull || index === 0)) {
                            itemGridSize = 12;
                        } else {
                            isPreviousRowFull = false;
                        }
                    } else if (!nextResultItem && isPreviousRowFull) {
                        itemGridSize = 12;
                    }
                }
                return {
                    ...currentItem,
                    gridSize: itemGridSize,
                };
            });
            console.log('newResponseData ::: *** ', newResponseData);
            setResponseData(newResponseData);
        }
    }, [resultData]);

    const getResponseType = (responseData, outputType) => {
        let responseType = '';
        switch (true) {
            case responseData && Array.isArray(responseData) && responseData?.length > 0 && isStringArray(responseData):
            case responseData && Array.isArray(responseData) && responseData?.length > 0 && isNumberArray(responseData): {
                responseType = 'stringNumberArray';
                break;
            }
            case responseData && Array.isArray(responseData) && responseData?.length > 1:
            case responseData && Array.isArray(responseData) && responseData?.length === 0: {
                responseType = 'table';
                break;
            }
            case responseData && Array.isArray(responseData) && responseData?.length === 1:
            case responseData && isObject(responseData) && Object.keys(responseData)?.length > 0: {
                responseType = 'tile';
                break;
            }
            case typeof responseData === 'string':
            case typeof responseData === 'number' && !isNaN(responseData): {
                responseType = 'text';
                break;
            }
            default:
                responseType = '';
                break;
        }
        return responseType;
    };

    return (
        <Grid item xs={12} className={classes.grid}>
            <React.Fragment>
                {Array.isArray(responseData) && responseData?.length > 1 ? (
                    <Grid container className={classes.root} spacing={1}>
                        {responseData.map((result, rIndex) => {
                            const { label, output, outputType, gridSize } = result;
                            let responseType = getResponseType(output, outputType);
                            const queryTexts = message?.title?.split('.');
                            // console.log('newComponent ::: *** ', responseType, result)
                            switch (responseType) {
                                case 'stringNumberArray': {
                                    return (
                                        <Grid item xs={gridSize} key={rIndex}>
                                            {/* <Paper className={classes.paper}> */}
                                                <QuestionTitle title={label} />
                                                <SimpleList responseList={output} />
                                            {/* </Paper> */}
                                        </Grid>
                                    );
                                }
                                case 'text': {
                                    const responseMessage = output ?? textMsg;
                                    return (
                                        <Grid item xs={gridSize} key={rIndex}>
                                            {/* <Paper className={classes.paper}> */}
                                                <QuestionTitle title={label} />
                                                <div style={{ margin: '10px 0', fontSize: 'larger', fontWeight: 'bolder' }}>
                                                    <TextComponent
                                                        applyTextStyles={true}
                                                        responseData={responseMessage}
                                                        textStyle={classes.textValueStyle}
                                                    />
                                                </div>
                                            {/* </Paper> */}
                                        </Grid>
                                    );
                                }
                                case 'tile': {
                                    const responseData = output && Array.isArray(output) ? output?.[0] : output;
                                    return (
                                        <Grid item xs={gridSize} key={rIndex}>
                                            {/* <Paper className={classes.paper}> */}
                                                <QuestionTitle title={label} />
                                                <SimpleTile data={responseData} message={queryTexts?.[rIndex]} />
                                            {/* </Paper> */}
                                        </Grid>
                                    );
                                }
                                case 'table': {
                                    return (
                                        <MultiResponseTable
                                            key={rIndex}
                                            label={label}
                                            result={result}
                                            rIndex={rIndex}
                                            output={output}
                                            message={message}
                                            classes={classes}
                                            queryTexts={queryTexts}
                                            chartData={props?.chartData}
                                            supportedHighcharts={supportedHighcharts}
                                            columnSize={gridSize}
                                            fromDashboard={props?.fromDashboard}
                                        />
                                    );
                                }
                                default:
                                    return null;
                            }
                        })}
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <TextComponent applyTextStyles={false} responseData={''} />
                    </Grid>
                )}
            </React.Fragment>
        </Grid>
    );
}