const API_BASE_URL = process.env.REACT_APP_DATA_SERVICE_URL;

import { URLs } from '../../modules/appConfig.module'

import { Request } from "./clientHelper";

export class ChartsService {

	static getPieChartDataFromAPI(data) {
		let uri = `/dataconnect/piechart/${data.id}`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data,
			headers: { appId: data.appId }
		});
	}

    static getLabelBoxData(data) {
        const uri = `${API_BASE_URL}` +URLs.labelBoxColor
        return Request(uri, {
            method: 'POST',
            payload: data.boxes,
            headers: { appId: data.appId }
        })
    }
}

