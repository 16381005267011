import React,{ useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import { getTaskData, updateTaskData, deleteTaskData, descriptionUpdated } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import EditIcon from '@material-ui/icons/Edit'; 
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton } from '@material-ui/core';
import { Loader } from "../loader";
import { ConfirmationHandler } from 'dsilo-ui-components'
import { toast } from "react-toastify";
import { SimpleList} from 'dsilo-ui-components';

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 29
    const left = 35

    return {
        top: `${top}%`,
        left: `${left}%`,
    };
}

const useStyles = makeStyles((theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            width: 520,
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2, 4, 3),
        },
        label:{ 
            marginLeft: '10px'
        },
        link:{
            marginLeft: '10px'
        },
        description:{
            marginTop: '5%',
        },
        btmLink:{
            marginTop: '5%', 
            marginBottom: '10%'
        },
        btmButtons:{
            margin:'15px'
        },
        labelName:{
            fontSize: '20px',
            marginLeft: '10px'
        },
        textField:{
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "black"
            },
        }
    }),
);

const Task = props => {
    console.log('Task-props', props)

    const classes = useStyles();
    const [description, setDescription] = useState('')
    const [descriptionEnable, setDescriptionEnable] = useState(true)
    const [userData, setUserData] = useState([])
    const [open, setOpen] = React.useState(false);
    const [selectUser, setSelectUser] = React.useState('');
    const [modalStyle] = React.useState(getModalStyle);
    const { appId } = props
    const { chartUniqueId, _id } = props.data
    const { selectedDataModel, selectedDataElement } = props.data.chartData
    const [taskData, setTaskData] = useState([])
    const [originalData, setOriginalData]= useState(null)
    const [deleteConfirmState, setDeleteConfirm] = useState({
        isOpen: false,
        deleteMessage: "Are you sure you want to delete ?", data: null
    });
    const [taskLogs, setTasklogs]=useState([])
    const [comments, setComments] = useState('')
    const [saveCancelIcon, setSaveCancelIcon] = useState(false)



    const handleComplete=()=>{
        let _taskData = { ...taskData }
        _taskData.status = 'Completed'
        _taskData.previousStatus = originalData?.status
        setTaskData(_taskData)
        let payload = {
            data: _taskData,
            appId: appId,
            "dataUniqueId": chartUniqueId + '_updateTask',
        }
        props.updateTaskData(payload)
        setDescriptionEnable(!descriptionEnable)
    }
    const handleDelegate = () => {
        console.log('delegate');
        setOpen(true)
    }
    const handleRemove = (id) => {
        setDeleteConfirm({ ...deleteConfirmState, isOpen: true, data: id });
    }
    const onDeleteResponse = (isDelete) => {
        if (isDelete) {
            let payload = {
                "ids": [deleteConfirmState.data]
        }
        payload.appId = appId
        props.deleteTaskData(payload)
        props.history.goBack()
        }
        setDeleteConfirm({ ...deleteConfirmState, isOpen: false, data: null });
    };

    const handleTaskOwnerLink = () => {
        console.log('TaskOwnerLink');
    }
    const handleViewPageLink = () => {
        console.log('ViewPageLink');
    }

    const handleClose = () => {
        setOpen(false);
    };
    const handleChangeUser=(event)=>{
        console.log('handleChangeUser',event.target.value)
        setSelectUser(event.target.value)
    }

    const handleDelegateModal=(event)=>{
        console.log('handleDelegateModal', selectUser)
        let _taskData = { ...taskData }
        _taskData.owner = selectUser
        _taskData.previousOwner = originalData?.owner
        setTaskData(_taskData)
        let payload = {
            data: _taskData,
            appId: appId,
            "dataUniqueId": chartUniqueId + '_updateTask',
        }
        console.log('payload',payload)
        props.updateTaskData(payload)
        setOpen(false);
    }

    const handleChangeDescription=(event)=>{
        console.log('edit Description')
        setDescriptionEnable(false)
    }

    const handleEditComments=(event)=>{

        // let _taskData = {...taskData}
        // _taskData.description = event.target.value
        // _taskData.previousDescription = originalData?.description
        setComments(event.target.value)
        setSaveCancelIcon(true)
        // setTaskData(_taskData)
    }
    const handleCancle = (event) => {
        // setComments('')
        setComments(originalData.comments)
        setSaveCancelIcon(false)

        // let _taskData = { ...taskData }
        // console.log('_taskData',_taskData)
        // _taskData.description = originalData?.description
        // console.log('originalData', originalData)
        // setTaskData(_taskData)
        // setDescriptionEnable(!description)

    }
    const handleUpdateComments = (event) =>{
        if (comments){
            let _taskData = { ...taskData }
            _taskData.comments = comments
            _taskData.previousComments = originalData?.comments
            let obj={}
            obj._id = _taskData?._id
            obj.comments = comments
            obj.previousComments = originalData?.comments
            setTaskData(_taskData)
            let payload = {
                data: obj,
                appId: appId,
                "dataUniqueId": chartUniqueId + '_updateTask',
            }
            props.updateTaskData(payload)
            setSaveCancelIcon(false)
        }
        // setComments('')
    }
    useEffect(()=>{
        fetchData()
        if (props.form?.taskData[chartUniqueId + '_task'] && props.form?.taskData[chartUniqueId + '_task'][0]) {
            setOriginalData(props.form?.taskData[chartUniqueId + '_task'][0])
        }
    },[])

    useEffect(()=>{
        if (props.form.taskData[chartUniqueId + '_updateTask']){
            fetchData()
            // setDescriptionEnable(true)
        }
    }, [props.form?.taskData[chartUniqueId + '_updateTask']])

    useEffect(() => {
        const { taskData } = props.form
        if (props.form?.taskData[chartUniqueId + '_task'] && props.form?.taskData[chartUniqueId + '_task'][0]){
            setTaskData(props.form?.taskData[chartUniqueId + '_task'][0])
            setComments(props.form?.taskData[chartUniqueId + '_task'][0]?.comments || '')
            setOriginalData(props.form?.taskData[chartUniqueId + '_task'][0])
            let logs = props.form?.taskData[chartUniqueId + '_task'][0]?.taskLogs
            let messageArray = logs.map((item) => item.message)
            setTasklogs(messageArray)
        }
    }, [props.form?.taskData[chartUniqueId +'_task']])



    const fetchData = ()=>{
        let payload = {
            "appId": appId,
            "dataUniqueId": chartUniqueId + '_task',
            "dataModelId": selectedDataModel,
            "id": props.match.params.id
        }
        props.getTaskData(payload)
    }

    useEffect(() => {
        return () => {
            console.log('componentWillUnmount')
            setComments('')
        }
    }, [])

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <div style={{ backgroundColor:'#0F78DB',marginLeft:'-33px',marginTop:'-16px',width:'109%',padding:'9px'}}>
                <h3 style={{color:'white'}} >{'Select User'}</h3>
            </div>
            <div style={{ marginTop: '20px' }} >
                <Select
                    native
                    value={selectUser}
                    onChange={(event)=>{handleChangeUser(event)}}
                >
                    <option value={props.members?.userId}>{props.members?.name}</option>
                </Select>
            </div>

            <div style={{ float: 'right', marginTop:'5%'}} >
                <Button variant="contained" color="primary" onClick={(event) => { handleDelegateModal(event) }}>
                    {'Delegate'}
                </Button>
            </div>
        </div>
    );




    
    if (props.form &&
        (props.form[chartUniqueId +"_updateTask_loading"] || props.form[chartUniqueId +"_task_loading"])){
        return <Loader />
    }

    return (
        <React.Fragment>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description">
                    {body}
                </Modal>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                             <div style={{ display: 'flex' }}>
                                <h3 >{'Name:'}</h3>
                                <label className={classes.labelName} >{taskData.name}</label>
                            </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item xs={6} sm={3}>
                                <div style={{ display: 'flex' }}>
                                    <h4 >{'Owner:'}</h4>
                                    <label className={classes.label}>{taskData.ownerName}</label>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <div style={{ display: 'flex' }}>
                                    <h4 >{'Assigned Date:'}</h4>
                        <label className={classes.label}>{taskData.assignedDate?moment(taskData.assignedDate).format('ll'):'-'}</label>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item xs={6} sm={3}>
                                <div style={{ display: 'flex' }}>
                                    <h4 >{'Status:'}</h4>
                                    <label className={classes.label}>{taskData.status}</label>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <div style={{ display: 'flex' }}>
                                    <h4 >{'Created Date:'}</h4>
                                    <label className={classes.label}>{taskData.createdDate? moment(taskData.createdDate).format('ll'):'-'}</label>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={6}>
                                <div style={{ display: 'flex', justifyContent:'space-between' }} >
                                    <div style={{ display: 'flex' }}>
                                        <h4 >{'Details'}</h4>
                                    </div>
                                </div>
                                <div style={{ margin: 12 }}>{taskData.description}</div>
                                <h4 >{'Comments'}</h4>
                                <div style={{ display: 'flex' }}>
                                        <TextField
                                            id="filled-full-width"
                                            style={{ margin: 8 }}
                                            className={classes.textField}
                                            margin="normal"
                                            onChange={(event) => { handleEditComments(event) }}
                                            multiline={true}
                                            variant="outlined"
                                            value={comments}
                                            fullWidth
                                            rows={2}
                                        />
                                        {
                                                saveCancelIcon ? <div style={{ float: 'right' }}>
                                                <IconButton style={{ marginTop: '-7px', marginLeft: '-11px' }} onClick={(event) => { handleUpdateComments(event) }} >
                                                    <SaveIcon />
                                                </IconButton>
                                                <IconButton style={{ marginTop: '-7px', marginLeft: '-11px' }} onClick={(event) => { handleCancle(event) }} >
                                                    <ClearIcon />
                                                </IconButton>
                                            </div> : null
                                        }
                                </div>
                                
                            </Grid>
                        </Grid>
                    <hr style={{ marginTop: '10%' }} />
                    <div style={{ display: 'flex', float: 'right' }}>
                        {
                            taskData.status !== 'Completed'? 
                                <React.Fragment>
                                    <Button className={classes.btmButtons} variant="contained" color="primary" onClick={(event) => { handleComplete(event, taskData._id) }}>
                                        {'Complete'}
                                    </Button>
                                    <Button className={classes.btmButtons} variant="contained" color="primary" onClick={(event) => { handleDelegate(event, taskData._id) }}>
                                        {'Delegate'}
                                    </Button>
                                </React.Fragment>
                            :
                            null
                        }
                        <Button className={classes.btmButtons} variant="contained" color="primary" onClick={() => { handleRemove(taskData._id) }}>
                            {'Remove'}
                        </Button>
                    </div>
                    { taskLogs.length ? <SimpleList data={{ title: 'Audit trail', items: taskLogs || [],style:true }} />:null }
            
            <ConfirmationHandler open={deleteConfirmState.isOpen} handleClose={onDeleteResponse}
                        alertMessageText={deleteConfirmState.deleteMessage} confirm />
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    console.log('form', state.form)
    return {
        form: state.form,
        members: state.appConfig.members
    };
};

const mapDispatchToProps = {
    getTaskData, 
    updateTaskData, 
    deleteTaskData,
    descriptionUpdated
};
export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(Task)));
