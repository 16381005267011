import { ActionTypes } from 'constants/index';

export const loadPdfAction = file => ({ type: ActionTypes.LOAD_PDF, file });

export const uploadContractPDFAction = data => ({ type: ActionTypes.UPLOAD_CONTRACT_PDF, data });

export const addAnnotationsToPDFAction = data => ({ type: ActionTypes.SAVE_ANNOTATIONS, data });

export const updateContractAnnotations = data => ({ type: ActionTypes.UPDATE_ANNOTATIONS, data });

export const updateContractState = data => ({ type: ActionTypes.UPDATE_CONTRACT_REDUCER_STATE, data });

export const updateContractAnnotationsWithoutLoading = data => ({ type: ActionTypes.UPDATE_ANNOTATIONS_WITHOUT_LOADER, data });

export const getAllContracts = data => ({ type: ActionTypes.GET_ALL_CONTRACTS, data });

export const getContractById = data => ({ type: ActionTypes.GET_CONTRACT_BY_ID, data });

export const emptyContractById = data => ({ type: ActionTypes.GET_CONTRACT_BY_ID_EMPTY });

export const downloadContractPDF = data => ({ type: ActionTypes.DOWNLOAD_CONTRACT_PDF, data });

export const deleteContract = data => ({ type: ActionTypes.DELETE_CONTRACT, data });

export const hideSuccessMessage = data => ({ type: ActionTypes.HIDE_CONTRACT_UPDATE_MESSAGE, data });

export const clearPDFData = data => ({ type: ActionTypes.CLEAR_PDF_DATA, data });

export const deleteUploadedContract = data => ({ type: ActionTypes.DELETE_UPLOADED_CONTRACT, data });

export const downloadAnnotations =  data => ({ type: ActionTypes.DOWNLOAD_ANNOTATIONS, data });

export const getSearchData =  data => ({ type: ActionTypes.SEARCH_DATA_LOADING, data });

export const addSearchData =  data => ({ type: ActionTypes.ADD_SEARCH_DATA_LOADING, data });

export const reRun = data => ({ type: ActionTypes.RERUN_AI_ITEM, data });

export const downloadTop10ClausesExcel =  data => ({ type: ActionTypes.DOWNLOAD_TOP10CLAUSES_EXCEL, data });