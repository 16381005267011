import React from 'react';

import SummaryBlock from './SummaryBlock';
import DynamicDocs from './DynamicDocs';
import SubDataModelBlock from './SubDataModelBlock';
import MultiDMTableBlock from './MultiDMTableBlock';
import SimpleGrid from './SimpleGrid';
import UploadComponent from './Upload';

const RenderComponentBasedOnBlock = (props) => {
    const { block } = props

    return (
        <React.Fragment>
            {
                (function () {
                    switch (block.type) {
                        case "summaryItems":
                            return <SummaryBlock key={block.name} {...props} />
                            break;
                        case "subDataModel":
                            return <SubDataModelBlock key={block.name} {...props} />
                            break;
                        case "dynamicDocumentLinks":
                            return <DynamicDocs key={block.name} {...props} />
                            break;
                        case "multiDMDataTable": // For FCFA
                            return <MultiDMTableBlock key={block.name} {...props} />
                            break;
                        case "simpleGrid":
                            return <SimpleGrid key={block.name} {...props} />
                        case "upload":
                            return <UploadComponent key={block.name} {...props} loader={props.loader}/>
                        default:
                            return null;
                    }
                })()
            }
        </React.Fragment>
    )
}

export default RenderComponentBasedOnBlock