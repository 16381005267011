const API_BASE_URL = process.env.REACT_APP_DATA_SERVICE_URL;

import { Request } from "./clientHelper";

export class DashboardService {

    static getData(data) {
        const uri = `${API_BASE_URL}` + data.url
        return Request(uri, {
            method: 'GET',
            headers: { appId: data.appId }
        })
    }

}