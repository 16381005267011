import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    workspaceScreenRoot: {
      width: '100%',
      height: '100%',
    },
    loader: {
      display: 'block',
      margin: '0 auto',
    },
    workSpacetitle: {
      alignItems: 'center',
      display: 'flex',
      margin: '0.5rem',
      justifyContent: 'space-between',
    },
    reactGridLayout: {
      '& > .react-grid-item': {
        '& > .react-resizable-handle': {
          display: 'block',
        },
      },
    },
    publishButton: { margin: '0 5px' },
    ownerText: {
      fontSize: 12,
      fontWeight: "lighter",
    },
    ownerName: { fontSize: 16 }
}));

export default useStyles;
