import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Model } from 'dsilo-ui-components';
import { Loader } from '../../../components/loader';
import { getModels } from '../../../store/actions';

const ModelWrapper = props => {

	useEffect(() => {
		Promise.all([
			// props.getModels({ appId: props.match.params.appid, key: 'models' }),
			props.getModels({ appId: props.match.params.appid, params: '/list/training', key: 'training' }),
			// props.getModels({ appId: props.match.params.appid, params: '/list/testing', key: 'testing' }),
			props.getModels({ appId: props.match.params.appid, params: '/list/active', key: 'active' }),
		])
	}, [])

	let cardsData = {
		// 'testing': props.models?.testing,
		'training': props.models?.training,
		'active': props.models?.active,
	}

	if (props.models.loading) {
		return <Loader />
	}

	return (
		<div>
			<Model cardsData={cardsData} {...props} />
		</div>
	);
};

const mapDispatchToProps = {
	getModels,
};

const mapStateToProps = state => {
	return {
		models: state.models,
	};
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ModelWrapper)));