const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
import { request } from 'modules/client'

let route = `${API_BASE_URL}/pipelines`

export class PipelinesService {

	static getPipelines(appId) {
		return request(route, {
			method: 'GET',
			headers: { 'appId': appId }
		})
	}

	static getPipeline(params) {
		const uri = `${route}/${params.pipelineId}`
		return request(uri, {
			method: 'GET',
			headers: { 'appId': params.dappId }
		})
	}

	static savePipeline(data) {
		return request(route, {
			method: 'POST',
			headers: { 'appId': data.appId },
			payload: data
		})
	}

	static updatePipeline(data) {
		const uri = `${route}/${data._id}`
		return request(uri, {
			method: 'PUT',
			headers: { 'appId': data.appId },
			payload: data
		})
	}

	static deletePipeline(data) {
		const uri = `${route}/${data._id}`
		return request(uri, {
			method: 'DELETE',
			headers: { 'appId': data.appId },
		})
	}
}