import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import TitleTextField from '../TitleEditor';
import useStyles from './styles';

const WorkspaceHeader = (props) => {
    const [titleEditable, showTitleEditable] = useState(false);
    const [name, setName] = useState('');
    const classes = useStyles();

    const { ownerDetails } = props;

    useEffect(() => {
        setName(props?.receivingWorkSpace?.workspaceName || '');
    }, [props?.receivingWorkSpace?.workspaceName]);

    const handleChange = event => {
        setName(event.target.value);
    };

    const editWorkspaceTitle = () => {
        showTitleEditable(prev => !prev);
    };

    const updateWorkspaceTitle = () => {
        if (name !== props?.receivingWorkSpace?.workspaceName) {
            props.updateWorkspaceName(props?.receivingWorkSpace?.workspaceId, name);
        }
        showTitleEditable(false);
    };

    return (
        <Box className={classes.workSpacetitle}>
            {titleEditable ? (
                <>
                    <TitleTextField
                        title={name}
                        handleTitleChange={handleChange}
                        editTitle={editWorkspaceTitle}
                        updateTitle={updateWorkspaceTitle}
                    />
                </>
            ) : name ? (
                <Box display="flex" alignItems="center">
                    <Typography variant="h5" component="h5">
                        {name || ''}
                    </Typography>
                    {props.workspaceTabVal !== "all" && <IconButton aria-label="Edit Title" edge="end" onClick={editWorkspaceTitle}>
                        <Edit size={20} />
                    </IconButton>}
                </Box>
            ) : null}
            <Box style={{ display: 'flex', gap: '4px' }}>
                {ownerDetails?.name && (
                    <Box display="flex" alignItems="center">
                        <Typography className={classes.ownerText} variant="subtitle2" component="span">
                            Owner: <b className={classes.ownerName}>{ownerDetails?.name}</b>
                        </Typography>
                    </Box>
                )}
                {name && (
                    <>
                        {props?.receivingWorkSpace?.status === 'New' && props.workspaceTabVal !== "all" && (
                            <Button
                                className={classes.publishButton}
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => props.publishDraftWorkspace('publish', props?.receivingWorkSpace?.workspaceId)}
                            >
                                {props?.publishLoading ? <CircularProgress color="#fff" size={20} /> : 'Publish'}
                            </Button>
                        )}
                        {props.workspaceTabVal !== "all" && ['Draft', 'Published'].includes(props?.receivingWorkSpace?.status) && (
                            <Button
                                className={classes.publishButton}
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => props.publishDraftWorkspace('draft', props?.receivingWorkSpace?.workspaceId)}
                            >
                                {props?.publishLoading ? <CircularProgress color="#fff" size={20} /> : 'Draft'}
                            </Button>
                        )}
                        {props.showSaveButton && props.workspaceTabVal !== "all" && (
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={props.updateWorkspaceLayout}
                                startIcon={<SaveIcon />}
                            >
                                Save
                            </Button>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
}

export default WorkspaceHeader;
