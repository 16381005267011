import { FormControl, IconButton, makeStyles } from "@material-ui/core";
import { ChipSelect, InputField, typeOf } from "dsilo-ui-components";
import React, { useEffect } from "react";
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/styles';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
    inputBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    inputBox1: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 15,
        marginTop: 10,
    },
    chipSelect: {
        minWidth: 120,
        marginTop: 5,
    },
    deleteIcon: {
        // marginTop: 15,
    }
});

const Label = withStyles({
    root: {
        fontSize: 18,
        fontWeight: 'bold',
    },
})(InputLabel);

const Fields = (props) => {

    const classes = useStyles()

    useEffect(() => {
        let obj = {}
        props.fields.forEach(i => {
            obj[i.name] = ''
        })
        props.addExtraEmptyRow([...props.values, obj])
    }, [])

    const getValues = (_values) => {
        if (_values?.length && typeof (_values[0]) === 'string') {
            return _values.map(i => ({ label: i, value: i }))
        }
        return _values || [];
    }

    const onDropdownChange = (e, index, field) => {
        if (field.values?.length && typeof (field.values[0]) === 'string') {
            props.onChangeField(e.value, index, field.name)
        } else {
            props.onChangeField(e, index, field.name)
        }
    }

    return (<div>
        <div className={classes.header}>
            <h3>{props.title}</h3>
        </div>
        {
            [...props.values].map((value, index) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }} key={index} className={props.fields.length === 1 ? classes.inputBox1 : classes.inputBox}>
                        {
                            props.fields.map((field, colIndex) => {
                                return (<div key={index + (colIndex || '')}
                                    style={{ marginRight: 3, marginBottom: 5 }}>
                                    {
                                        field.type === 'input' &&
                                        <InputField onChange={e => props.onChangeField(e.target.value, index, field.name)}
                                            value={value[field.name]} style={{ width: props.fields.length === 1 ? 350 : undefined }}
                                            label={field.label || 'false'} />
                                    }
                                    {
                                        field.type === 'dropdown' &&
                                        <div>
                                            {field.label && <Label shrink>
                                                {field.label}
                                            </Label>}
                                            <ChipSelect
                                                onChange={(e) => onDropdownChange(e, index, field)}
                                                className={classes.chipSelect}
                                                value={typeof (value[field.name]) !== 'string' ? value[field.name] : { label: value[field.name], value: value[field.name] }}
                                                isSearchable={true}
                                                name={field.label || ''}
                                                textFieldProps={{
                                                    InputLabelProps: {
                                                        shrink: true,
                                                    },
                                                    variant: 'outlined',
                                                }}
                                                variant="fixed"
                                                options={getValues(field.values)} />
                                        </div>
                                    }

                                </div>)
                            })
                        }
                        {
                            (index + 1 !== props.values.length) && <IconButton className={classes.deleteIcon} title={'Remove row'} onClick={() => props.removeField(index)}>
                                <DeleteIcon />
                            </IconButton>

                        }
                    </div>
                )
            })
        }
    </div >
    )
}

export default Fields;
