import Box from "@material-ui/core/Box";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { hot } from "react-hot-loader/root";
import { connect } from 'react-redux';
import {
	createNewPrompt,
	createComment,
	disLikeQuestion,
	getQuestionComments,
	likeQuestion,
	questionSendEmail,
	rating,
	savedForLater,
	updateQuestionChartType,
	updateQuestionEmailStatus
} from '../../../../store/actions';
import InfiniteScroll from "../../../InfiniteScroll";
import { Loader } from "../../../loader";
import { chartHistoryByIdUniqueId, isStringArray } from '../../ChatHelper';
import Chat from "./Chat";
import ChatMessage from "./ChatMessage";
import useStyles from "./styles";
import "../../index.css";

const ChatContainer = (props) => {
	const classes = useStyles();
	const { messages, loading, selectedPgptSupport } = props;
	const { multiplePGPTSupport, ref, sendPreviousQuery, selectedDataModel } = props.chartData;
	const rowsPerPage = 10;

	const appId = props?.match?.params?.appid || "";
	const chatId = props?.selectedChatId || "";
	const [paginationLoading, setPaginationLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [newPromptAdded, setNewPromptAdded] = useState(false);

	useEffect(() => {
		if (props?.chat?.newChatId) {
			props.getLatestChatHistory();
			props?.setSelectedChatId(props?.chat?.newChatId);
		}
	}, [props?.chat?.newChatId]);

	const chatQuestionsHistory = useMemo(() => {
		let questionsHistory = {
			data: [],
			page: 0,
			hasMoreData: false
		}
		if (props?.chat?.data?.[`${chatId}_${chartHistoryByIdUniqueId}`] && props?.chat?.data?.[`${chatId}_${chartHistoryByIdUniqueId}`]?.data?.length) {
			setPaginationLoading(false);
			setPage(props?.chat?.data?.[`${chatId}_${chartHistoryByIdUniqueId}`]?.page);
			const lastRecord = props?.chat?.data?.[`${chatId}_${chartHistoryByIdUniqueId}`]?.data?.at(-1);
			if (lastRecord && Object.keys(lastRecord).length && lastRecord?._id === 'newPrompt' && lastRecord?.questionId === 'newPrompt') {
				setNewPromptAdded(true);
			}
			questionsHistory = {
				data: props?.chat?.data?.[`${chatId}_${chartHistoryByIdUniqueId}`]?.data,
				page: props?.chat?.data?.[`${chatId}_${chartHistoryByIdUniqueId}`]?.page,
				hasMoreData: props?.chat?.data?.[`${chatId}_${chartHistoryByIdUniqueId}`]?.hasMoreData
			}
			props.setLoading(false);
		}
		return questionsHistory;
	}, [
		props?.chat?.data?.[`${chatId}_${chartHistoryByIdUniqueId}`]?.data,
		props?.chat?.data?.[`${chatId}_${chartHistoryByIdUniqueId}`]?.hasMoreData,
		props?.chat?.data?.[`${chatId}_${chartHistoryByIdUniqueId}`]?.page,
	]);
	
	useEffect(() => {
		const tempMessages = [...messages];
		const lastMessage = tempMessages[tempMessages.length - 1];
		if (lastMessage && !lastMessage.isBot && lastMessage?.message) {
			let payload = { appId };
			try {
				const userQuestion = lastMessage?.message;
				if (multiplePGPTSupport?.enable) {
					if (selectedPgptSupport?.value) {
						payload.aiType = selectedPgptSupport.value
					} else if (multiplePGPTSupport.pgpts?.length) {
						payload.aiType = multiplePGPTSupport.pgpts[0].aiType
					}
				} else {
					payload.aiType = props?.chartData?.aiType || 'pgpt-v0'
				}
				console.log("payload.aiType ======= ", payload.aiType)
				if (sendPreviousQuery && chatId) {
					const lastChatQuestionObj = chatQuestionsHistory?.data?.at(-1);
					console.log('lastChatQuestionObj ::: *** ', lastChatQuestionObj);
					if (lastChatQuestionObj && Object.keys(lastChatQuestionObj).length) {
						payload.previousQuery = lastChatQuestionObj?.queryText
					}
				}
				if (selectedDataModel) payload.dataModelId = selectedDataModel
				let isT5 = false
				if (payload?.aiType && isStringArray(payload?.aiType) && payload?.aiType?.length > 0) {
					isT5 = payload?.aiType.indexOf('t5') !== -1
				} else if(typeof payload?.aiType === 'string') {
					isT5 = ['t5'].includes(payload?.aiType)
				}
				if (payload?.aiType && isT5) {
					payload.question = userQuestion
				} else {
					payload.query = userQuestion
				}
				if (ref) payload.ref = ref || 'main'
				payload = {
					...payload,
					...(chatId && chatId !== 'newPrompt' && { chatId })
				}
				console.log("payload ======= *** ", payload)
				props?.createNewPrompt(payload);
				props.setLoading(false);
				props.setMessages([]);
			} catch (error) {
				console.log("error ==== = == = ", error);
			}
		}
	}, [messages, chatQuestionsHistory?.data]);

	useEffect(() => {
		const chatQuestionsHistoryLoading = props?.chat?.data?.[`${chatId}_${chartHistoryByIdUniqueId}_loading`];
		if (!chatQuestionsHistoryLoading) {
			props.setLoading(false);
			setPaginationLoading(false);
		}
	}, [props?.chat?.data?.[`${chatId}_${chartHistoryByIdUniqueId}_loading`]]);

	useEffect(() => {
		let scrollToQuestionElement = '';
		if (chatQuestionsHistory.page === 1 || newPromptAdded) {
			scrollToQuestionElement = chatQuestionsHistory.data.at(-1);
		} else {
			scrollToQuestionElement = chatQuestionsHistory.data.at(rowsPerPage + 1);
		}
		const scrollElementId = scrollToQuestionElement?._id || scrollToQuestionElement?.questionId;
		const delay = newPromptAdded ? 10 : 1000;
		console.log("scrollElementId ::: *** ", scrollElementId)
		console.log("chatQuestionsHistory.page ::: *** ", chatQuestionsHistory.page)
		setTimeout(() => {
			const scrollToViewElement = document.getElementById(scrollElementId);
			if (scrollToViewElement) {
				console.log("scrollToViewElement ::: *** ", scrollToViewElement)
				scrollToViewElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
			}
		}, delay);
	}, [chatQuestionsHistory, newPromptAdded]);

	const getNextPageData = () => {
		if (chatQuestionsHistory?.hasMoreData && !paginationLoading && page === chatQuestionsHistory?.page) {
			const data = {
				appId,
				chatId,
				dataUniqueId: `${chatId}_${chartHistoryByIdUniqueId}`,
				payload: { 
					page: page + 1, 
					rowsPerPage  // Need to make the rowsPerPage as config
				}
			}
			props.getChatHistoryById(data);
			setPaginationLoading(true);
		}
	};

	return (
		<Box className="chat-container" id="chat-container">
			{loading && <Loader className={classes.loader} />}
			<InfiniteScroll
				onTopHit={getNextPageData}
				isLoading={paginationLoading && loading}
				hasMoreData={chatQuestionsHistory?.hasMoreData}
				loadOnMount={false}
				uniqueId={props?.chartUniqueId}
				pageNumber={chatQuestionsHistory?.page}
			>
				<Fragment>
					{chatQuestionsHistory?.data && chatQuestionsHistory?.data?.length > 0 ? (
						<Fragment>
							{paginationLoading && <Loader className={classes.loader} />}
							{chatQuestionsHistory.data.map((msg, index) => (
								msg?._id === 'newPrompt' ? (
									<Box 
										key={msg?._id}
										id={msg?._id}
										className={`message user-message`}
										style={{ width: '-webkit-fill-available' }}
									>
										<ChatMessage
											key={msg?._id}
											message={msg}
											responseMessage={null}
											questionId={msg?._id || msg?.questionId}
										/>
										<Loader className={classes.loader} />
									</Box>
								) : (
									<Chat
										key={msg._id + index}
										msg={msg}
										index={index}
										messages={chatQuestionsHistory?.data}
										chat={props.chat}
										deleteQuestionFromChatHistory={props.deleteQuestionFromChatHistory}
										{...props}
									/>
								)
							))}
						</Fragment>
					) : null}
				</Fragment>
			</InfiniteScroll>
			
		</Box>
	);
};

const mapDispatchToProps = {
	createNewPrompt,
	createComment,
	getQuestionComments,
	questionLike: likeQuestion,
	questionDisLike: disLikeQuestion,
	saveSavedForLater: savedForLater,
	saveRating: rating,
	updateQuestionChartType,
	sendEmail: questionSendEmail,
	updateEmailSuccessStatus: updateQuestionEmailStatus
};

const mapStateToProps = state => {
	return {
		dataModels: state.dataModels?.data || [],
		formData: state.form,
		chat: state.chat
	};
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(ChatContainer));