import React,{useEffect,useState} from 'react'
import { TextField,InputLabel,Grid,Paper,Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core"
import { toast } from "react-toastify";
import { Editor } from 'react-draft-wysiwyg';
import { useHistory } from "react-router-dom";
import { ChipSelect } from 'dsilo-ui-components'


const useStyles = makeStyles((theme) => ({
    paper: {
    },
    gridContainer:{

    },
    InputLabel:{
        marginTop:20
    },
    TextField:{
        width:'100%',
        '& .MuiInputBase-formControl':{
            height:'43px !important'
        }
    },
    TextArea:{
        width:'100%',
    },
    status:{
        display:'contents'
    }
}))
const sampleobj  = {"title": true,"description": true}

const statusArray = [
    {
        "label":"To Do",
        "value":"To Do"
    },
    {
        "label":"In Progress",
        "value":"In Progress"
    },
    {
        "label":"Done",
        "value":"Done"
    }
]

const BasicInfo =(props)=>{
    console.log('BasicInfo-props',props)
    const classes = useStyles();
    const [state, setState] = useState({status:'Active'})
    const [roleConfig, setRoleConfig] = useState(false)

    // const [validate, setValidate] = useState({})
    const { _id:userId } = props.member
    let history = useHistory();


    
    useEffect(()=>{
        if(props.memberDetails){
            setState({...state,...props.memberDetails,...props.fianlFormData})
        }
    },[props.memberDetails,props.fianlFormData])

    useEffect(()=>{
        //TODO:Need handle dynamically
        let roleCon = props.member?.roles?.filter(ele=>ele.name==='Super Admin')
        setRoleConfig(roleCon)
        return ()=>{
            setState({})
        }
    },[])

    useEffect(()=>{
        if(props.fianlFormData){
            setState({...props.fianlFormData})
        }

    },[props.fianlFormData])


    const handlesFormInputs = (e)=>{
        setState({...state,[e.target.name]:e.target.value})
        props.handlesSaveFunctionality({...state,[e.target.name]:e.target.value})
        props.validator({[e.target.name]:e.target.value?false:true})
    }

    const conditionCheck =(sampleobj)=>{
        if(Object.values(sampleobj).every(ele=>!ele)){
            return true
          } else if (Object.values(sampleobj).every(ele=>ele)){
            return true
          } else if(Object.values(sampleobj).some(ele=>ele)){
            return false
          }
    }

    const handlesFinalSubmit = ()=>{
        if(Object.keys(state).length===0 || Object.keys(props.validate).length===0){
            props.validator({title:true,description:true})
        }else if(Object.keys(props.validate).length===2 && conditionCheck(props.validate) ){
            let sample_obj = {...state,...props.fianlFormData}
            props.createTickets({
                appId: props.appId,
                dataUniqueId:  props.data?.chartUniqueId+'_create_tickets',
                orgId: props.user.orgId,
                payload: {...sample_obj,assignTo:{
                                "label":sample_obj.assignTo.label,
                                "name":sample_obj.assignTo.label,
                                "id":sample_obj.assignTo.value,
                            },
                    status:[sample_obj.status.label] 
                    },
                memberid: userId,
                dataModelId: props.data?.chartData?.selectedDataModel
            })
        }else {
            const merge = (sampleobj, validate) => {
                return { ...sampleobj, ...validate};
              }
            let ss = merge(sampleobj,props.validate)
            props.validator({...ss})
        }
      }

    const onEditorStateChange = (editorState)=>{
        setState({...state,editorState:editorState})
    }

    useEffect(() => {
        if (props.formData?.document && props.formData?.document[props.data?.chartUniqueId+'_create_tickets'] && props.formData?.document[props.data?.chartUniqueId+'_create_tickets']?.message) {
            
            if(props.formData?.document[props.data?.chartUniqueId+'_create_tickets']?.data?.message){
                toast.success(props.formData?.document[props.data?.chartUniqueId+'_create_tickets']?.data?.message);
            }else{
                toast.success("Ticket created successfully");
            }
            props.history.push({ pathname: `/${props.match.params.appid}/page/tickets/`});   
        }
        return ()=>{
            props.clearReduxDataOfCurrentComponent({
                key: "document",
                subKey: [props.data?.chartUniqueId+'_create_tickets']
            })
        }
    }, [props.formData && props.formData?.document[props.data?.chartUniqueId+'_create_tickets']])

    const handleCanclebtnClick = () => {
        history.goBack()
    }

    const onDocumentTypeChangerMembersList = (data, type) => {
        setState({ ...state, [type]: data })
        props.handlesSaveFunctionality({ ...state, [type]: data })
    }
    const onDocumentTypeChangerStatus = (data, type) => {
        setState({ ...state, [type]: data })
        props.handlesSaveFunctionality({ ...state, [type]: data })
    }


    return (
            <Grid container xs={6} spacing={3} className={classes.gridContainer}>
                <Grid item xs={3} sm={3} >
                    <InputLabel htmlFor="my-input" className={classes.InputLabel}>Ticket Subject</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                    <TextField  
                        variant="outlined" 
                        className={classes.TextField} 
                        placeholder={'Enter Tickets Subject'}
                        onChange={handlesFormInputs}
                        name='title'
                        value={state.title}
                        error={props.validate.title?true:false}
                        id="standard-error-helper-text"
                        helperText={props.validate.title?'Ticket subject is required':''} 
                        />
                </Grid>
                <Grid item xs={3} sm={3} >
                    <InputLabel htmlFor="my-input" className={classes.InputLabel}>Ticket body</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                    {/* <Editor
                        editorState={state.editorState}
                        wrapperClassName="html-wrapper"
                        editorClassName="html-editor"
                        onEditorStateChange={onEditorStateChange}
                        editorStyle={{ border: "0.5px solid lightgray" }}
                        toolbar={{
                            inline: { inDropdown: true },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                        }}
                    /> */}
                    <TextField  
                        variant="outlined" 
                        className={classes.TextArea} 
                        placeholder={'Enter Tickets Description'}
                        onChange={handlesFormInputs}
                        name='description'
                        value={state.description}
                        multiline
                        rows={4}
                        error={props.validate.description?true:false}
                        id="standard-error-helper-text"
                        helperText={props.validate.description?'Tickets description is required':''} 
                        />
                </Grid>

                <Grid item xs={3} sm={3} >
                    <InputLabel htmlFor="my-input" className={classes.InputLabel}>{roleConfig?'Select Assign To':'Assign To'}</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                {
                    roleConfig ?
                    <ChipSelect
                        className={classes.chipSelect}
                        id='role'
                        onChange={(val) => onDocumentTypeChangerMembersList(val, 'assignTo')}
                        value={props.fianlFormData?.assignTo}
                        isSearchable={true}
                        // isMulti
                        textFieldProps={{
                            InputLabelProps: {
                                shrink: true,
                            },
                            variant: 'outlined',
                        }}
                        variant="fixed"
                        options={props.membersList}
                    />
                    :<div style={{marginTop:17}}>{props.fianlFormData?.assignTo?.label}</div>
                }
                </Grid>

                <Grid item xs={3} sm={3} >
                    <InputLabel htmlFor="my-input" className={classes.InputLabel}>{roleConfig?'Select Status':'Status'}</InputLabel>
                </Grid>
                <Grid item xs={9} sm={9}>
                {
                    roleConfig ?
                    <ChipSelect
                        className={classes.chipSelect}
                        id='role'
                        onChange={(val) => onDocumentTypeChangerStatus(val, 'status')}
                        value={props.fianlFormData?.status}
                        isSearchable={true}
                        // isMulti
                        textFieldProps={{
                            InputLabelProps: {
                                shrink: true,
                            },
                            variant: 'outlined',
                        }}
                        variant="fixed"
                        options={statusArray}
                    />
                    :<div style={{marginTop:17}}>{props.fianlFormData?.status?.label}</div>
                }
                </Grid>
               
                <Grid item xs={12} sm={12}>
                    <Button style={{float:'right'}} variant="contained" color="primary" onClick={handlesFinalSubmit}>{props.fianlFormData?._id?"Update":"Save"}</Button>
                    {props.fianlFormData?._id && 
                        <Button style={{float:'right', marginRight:10}} variant="contained" color="secondary" onClick={handleCanclebtnClick}>{'Cancle'}</Button>}
                </Grid>
            </Grid>
    )
}

export default BasicInfo