import React from 'react'
import { TextField, FormControl, FormLabel } from '@material-ui/core'

export default function BusinessRuleDetails(props) {
    const { form } = props.state
    const { classes } = props
    console.log("BusinessRuleDetails", props)
    return (
        <>
            {form && (
                <div className="p-16 sm:p-24 max-w-2xl">
                    <form className="form" noValidate autoComplete="off" >
                        {/* <FormControl row>
                            <FormLabel variant="h6" className="flex-1">
                                Enabled
                        </FormLabel>
                            <FormControlLabel
                                style={{ flex: 3 }}
                                control={
                                    <Checkbox
                                        checked={form.active}
                                        onChange={props.handleChange}
                                        // onChange={(data) => this.setState({ enableRule: data.target.checked })}
                                        value="enableRule"
                                        color="primary"
                                        name="active"
                                    />
                                }
                                label="Rule is active"
                            />
                        </FormControl> */}

                        <FormControl row className="flex items-center max-w-full">
                            <FormLabel variant="h6" className="flex-1">Rule Name*</FormLabel>
                            <TextField
                                className="mt-8 mb-16"
                                style={{ flex: 3 }}
                                error={form.name === ''}
                                required
                                placeholder="Name"
                                autoFocus
                                id="name"
                                name="name"
                                value={form.name}
                                onChange={props.handleChange}
                                variant="outlined"
                                fullWidth
                            />
                        </FormControl>

                        <FormControl row className="flex items-center  max-w-full">
                            <FormLabel variant="h6" className="flex-1">Rule Description</FormLabel>
                            <TextField
                                className="mt-8 mb-16"
                                style={{ flex: 3 }}
                                required
                                placeholder="Description"
                                autoFocus
                                id="description"
                                name="description"
                                value={form.description}
                                onChange={props.handleChange}
                                variant="outlined"
                                fullWidth
                            />
                        </FormControl>

                        {/* <FormGroup row className="flex itemGroup">
                            <FormLabel variant="h6" className="flex-1">Rule Type</FormLabel>
                            <RadioGroup
                                aria-label="Rule Type"
                                name="ruleType"
                                className={classes.group}
                                value={form.ruleType}
                                onChange={props.handleChange}
                                // onChange={(data) => this.setState({ ruleType: data.target.value })}
                                style={{ flex: 3 }}
                                row>
                                <FormControlLabel
                                    value="action"
                                    control={<Radio />}
                                    label="Action"
                                />
                                <FormControlLabel
                                    value="visibility"
                                    control={<Radio />}
                                    label="Visibility"
                                />
                                <FormControlLabel
                                    value="content"
                                    control={<Radio />}
                                    label="Content"
                                />
                            </RadioGroup>
                        </FormGroup>
                        <FormGroup row className="flex itemGroup">
                            <FormLabel variant="h6" className="flex-1">Rule Context</FormLabel>
                            <RadioGroup
                                aria-label="Rule Context"
                                name="ruleContext"
                                className={classes.group}
                                style={{ flex: 3 }}
                                value={form.ruleContext}
                                onChange={props.handleChange}
                                // onChange={(data) => this.setState({ ruleContext: data.target.value })}
                                row>
                                <FormControlLabel
                                    value="page"
                                    control={<Radio />}
                                    label="Page"
                                />
                                <FormControlLabel
                                    value="request"
                                    control={<Radio />}
                                    label="Request"
                                />
                                <FormControlLabel
                                    value="session"
                                    control={<Radio />}
                                    label="Session"
                                />
                                <FormControlLabel
                                    value="workflow"
                                    control={<Radio />}
                                    label="Workflow"
                                />
                            </RadioGroup>
                        </FormGroup>


                        <FormGroup row className="flex items-center">
                            <FormLabel variant="h6" className="flex-1">
                                Allow Rule to be embedded
            </FormLabel>
                            <FormGroup row style={{ flex: 3 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={form.smartContracts}
                                            onChange={props.handleChange}
                                            // onChange={(data) => this.setState({ smartContracts: data.target.checked })}
                                            value="smartContracts"
                                            name="smartContracts"
                                        />
                                    }
                                    label="Smart Contracts"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={form.workFlow}
                                            onChange={props.handleChange}
                                            // onChange={(data) => this.setState({ workFlow: data.target.checked })}
                                            value="workFlow"
                                            name="workFlow"
                                            color="primary"
                                        />
                                    }
                                    label="Workflow"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={form.pages}
                                            onChange={props.handleChange}
                                            // onChange={(data) => this.setState({ pages: data.target.checked })}
                                            value="pages"
                                            name="pages"
                                            color="primary"
                                        />
                                    }
                                    label="Pages"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={form.forms}
                                            onChange={props.handleChange}
                                            // onChange={(data) => this.setState({ forms: data.target.checked })}
                                            value="forms"
                                            name="forms"
                                            color="primary"
                                        />
                                    }
                                    label="Forms"
                                />
                            </FormGroup>
                        </FormGroup> */}
                    </form>
                </div>
            )}
        </>
    )
}