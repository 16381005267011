import { ActionTypes } from 'constants/index'

const initialState = {
	data: [],
	searchText: "",
	error: "",
	loading: false,
	businessRule: {}
}

const rulesReducer = function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_BUSINESSRULES: {
			return {
				...state,
				loading: true,
			}
		}
		case ActionTypes.GET_BUSINESSRULES_SUCCESS: {
			return {
				...state,
				loading: false,
				data: action.payload
			}
		}
		case ActionTypes.GET_BUSINESSRULES_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error
			}
		}
		case ActionTypes.SET_BUSINESSRULES_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText,
			}
		}
		case ActionTypes.DELETE_BUSINESSRULE_SUCCESS: {
			return {
				...state,
				loading: false,
				data: [...state.data.filter(item => item !== action.payload)] 
			}
		}
		case ActionTypes.DELETE_BUSINESSRULE_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error
			}
		}
		default: {
			return state
		}
	}
}

export default rulesReducer
