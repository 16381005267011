const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

import { request } from './../client';

export class themeService {
    static getTheme(appId) {
        const uri = API_BASE_URL + "/themes/" + appId;

        return request(uri, {
            method: 'GET'
        });
    }
}
