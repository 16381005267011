import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
// import moment from 'moment';
import { clearAllNotifications, clearSingleNotification, clearNotificationReduxState } from '../../store/actions';
import SubProcessView from './SubProcesView';
import NotificationTile from './NotificationTile';
import useStyles from './styles';

const NotificationBox = styled(Box)(({ theme }) => ({
	borderBottom: '1px solid grey',
	margin: '0 10px 10px 10px'
}));

const DrawerHeader = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	justifyContent: 'flex-end',
	position: 'absolute',
	right: 0,
}));

const NotificationView = (props) => {
	const classes = useStyles();
	const [notifications, setNotifications] = useState(props?.notifications?.data || [])

	useEffect(() => {
		console.log('Notifications ::: *** ', props.notifications)
		setNotifications(props?.notifications?.data || [])
	}, [props.notifications]);

	const clearAll = () => {
		const payload = {
			appId: props.appId
		}
		props.clearAllNotifications(payload);
		props.clearNotificationReduxState();
	}

	const removeSingleNotification = (processId) => {
		const data = {
			payload: {
				processId,
			},
			appId: props.appId
		}
		props.clearSingleNotification(data);
	}

	return (
		<div className="notifications-tray">
			<Box display="flex" direction="row" justifyContent="end">
				<Drawer
					anchor="right"
					classes={{
						paper: classes.paper,
						root: classes.drawer
					}}
					open={props.openN}
					onClose={props.handleNotificationTray}
					variant="persistent"
				>
					<Box className={classes.drawerHeader}>
						<Typography variant="h5" component="b" style={{ fontSize: 18 }}>
							Notifications
						</Typography>
						<DrawerHeader>
							<Link
								className={classes.clearAllBtn}
								component='button'
								onClick={clearAll}
								variant="body2"
							>
								Clear all
							</Link>
							<IconButton onClick={props.handleNotificationTray}>
								<CloseIcon style={{ fontSize: 18 }} />
							</IconButton>
						</DrawerHeader>
					</Box>
					{notifications?.length > 0 && notifications.map((notification, index) => {
						// let nDate = new Date(notification?.updatedAt)
						return (
							<NotificationBox key={notification?._id + index}>
								<NotificationTile
									handleOnClear={() => removeSingleNotification(notification?._id)}
									notification={notification}
								/>
								{notification?.subProcess && notification?.subProcess?.length > 0 && (
									<SubProcessView subProcessSteps={notification?.subProcess} />
								)}
								{/* <Typography className={classes.timer} variant="body1" component="p">
									{moment.utc(nDate).local().fromNow()}
								</Typography> */}
							</NotificationBox>
						)
					})}
					{props.notifications.loading && (
						<CircularProgress color="inherit" size={24} style={{ margin: 'auto' }} />
					)}
					{notifications?.length === 0 && !props.notifications.loading && (
						<Box role="presentation" className={classes.emptyNotifications}>
							<NotificationsOutlinedIcon fontSize='large' />
							No new notifications from this session
						</Box>
					)}
				</Drawer>
			</Box>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		appDetails: state.appConfig,
		app: state.app,
		appConfig: state.form.document?.appConfig,
		notifications: state.notifications
	};
};

const mapDispatchToProps = {
	clearAllNotifications,
	clearNotificationReduxState,
	clearSingleNotification,	
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(NotificationView));