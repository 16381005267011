export default {

	GET_DATAELEMENTS: undefined,
	GET_DATAELEMENTS_SUCCESS: undefined,
	GET_DATAELEMENTS_FAILURE: undefined,

	GET_DATAMODELS: undefined,
	GET_DATAMODELS_SUCCESS: undefined,
	GET_DATAMODELS_FAILURE: undefined,

	GET_DATAMODEL: undefined,
	GET_DATAMODEL_SUCCESS: undefined,
	GET_DATAMODEL_FAILURE: undefined,

	SAVE_DATAMODEL: undefined,
	SAVE_DATAMODEL_SUCCESS: undefined,
	SAVE_DATAMODEL_FAILURE: undefined,

	UPDATE_DATAMODEL: undefined,
	UPDATE_DATAMODEL_SUCCESS: undefined,
	UPDATE_DATAMODEL_FAILURE: undefined,

	DELETE_DATAMODEL: undefined,
	DELETE_DATAMODEL_SUCCESS: undefined,
	DELETE_DATAMODEL_FAILURE: undefined,

	DELETE_DATAMODELS: undefined,
	DELETE_DATAMODELS_SUCCESS: undefined,
	DELETE_DATAMODELS_FAILURE: undefined,

	GET_COLLECTIONS_FROM_DATASOURCE: undefined,
	GET_COLLECTIONS_FROM_DATASOURCE_SUCCESS: undefined,
	GET_COLLECTIONS_FROM_DATASOURCE_FAILURE: undefined,

	GET_DATAELMENTS_BY_DATAMODEL: undefined,
	GET_DATAELMENTS_BY_DATAMODEL_SUCCESS: undefined,
	GET_DATAELMENTS_BY_DATAMODEL_FAILURE: undefined,

	GET_DATAMODELS_BY_DATASOURCE: undefined,
	GET_DATAMODELS_BY_DATASOURCE_SUCCESS: undefined,
	GET_DATAMODELS_BY_DATASOURCE_FAILURE: undefined,

	SET_DATAMODELS_SEARCH_TEXT: undefined,

	OPEN_NEW_DATAMODEL_DIALOG: undefined,
	CLOSE_NEW_DATAMODEL_DIALOG: undefined,

	GET_ORG_DATAMODELS: undefined,
	GET_ORG_DATAMODELS_SUCCESS: undefined,
	GET_ORG_DATAMODELS_FAILURE: undefined,
	EMPTY_DATAELEMENTS: undefined
}