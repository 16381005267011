import React, { useContext, useState, useEffect } from 'react'
import { Typography, Box, TextField, Button } from '@material-ui/core'

// import Mexp from 'math-expression-evaluator'

import Mexp from '../../../../math-expression-evaluator-master'

const mexp = new Mexp()

import useStyles from './styles'

import Context from '../Context'

const Formula = () => {
  const styles = useStyles();
  // const { selectedFormula: item } = useContext(Context)
  let item = {
    "prefix": "",
    "suffix": "",
    "decimalPlaces": "",
    "notation": "fixed",
    "id": "TDmaG3Y7I4YvVtydgUcLL",
    "name": "Add",
    "variables": [
      {
        "id": "QoPDfvk1mMDddyfXwUTTR",
        "name": "V1",
        "defaultValue": "6",
        "symbol": "v1"
      },
      {
        "id": "807ZyXwCUM1jP9G_u40cW",
        "name": "V2",
        "defaultValue": "8",
        "symbol": "v2"
      }], 
    "expressionArray": [
      {
        "id": "QoPDfvk1mMDddyfXwUTTR"
      },
      "+",
      {
        "id": "807ZyXwCUM1jP9G_u40cW"
      },
    ]
  }
  const [result, setResult] = useState('')
  const [error, setError] = useState('')
  const [variableValues, setVariableValues] = useState(item?.variables.map(item => ({ ...item, value: '' })))

  const resultText = `${item?.resultPrefix ? item?.resultPrefix : ''}
    ${item?.resultDecimalPlaces
      ? Number(result).toFixed(Number(item?.resultDecimalPlaces))
      : result
    }
    ${item?.resultSuffix ? item?.resultSuffix : ''}`

  const onClearFieldsPress = () => {
    setVariableValues(item?.variables.map(v => ({ ...v, value: '' })))
  }

  const onCopyPress = () => {
    if (result?.length > 0)
      console.log(resultText)
  }

  const getInfinityText = () => {
    const infinityMessages = [
      'This hurts my 🧠...',
      'A lot 🤪',
      'Too much 😤',
      "💫💫💫"
    ]
    return infinityMessages[Math.floor(Math.random() * infinityMessages?.length)]
  }

  useEffect(() => {
    console.log("variableValues = = ======== xxxxxx", variableValues)
    if (variableValues?.every(({ value, defaultValue }) => value?.length > 0 || (defaultValue && defaultValue?.length > 0))) {
      const formulaString = item?.expressionArray.map(char => {
        if (char === '×') return '*'
        if (char === '÷') return '/'
        if (char === '−') return '-'
        if (char === 'π') return 'pi'
        if (char === '√') return 'root'
        console.log('variableValues: ', variableValues)

        const match = variableValues?.find(({ id }) => {
          if(typeof char === "object") {
            return id === char.id
          } 
        })
        if(match) {
          return match.value?.length > 0 ? match.value : match.defaultValue
        }
        return char
      }).join('')
      try {
        console.log('formulaString: ', formulaString)
        const resultString = mexp.eval(formulaString)
        setResult(`${resultString}`)
        if (error?.length > 0) setError('')
      } catch (e) {
        if (e.message) setError(e.message)
      }
    } else {
      setError('')
      setResult('')
    }
  }, [variableValues])


  console.log("item = = == = == == = ", item)

  return (
    <Box sx={{ height: '100vh', overflow: 'auto' }}>
      <Typography className={styles.formula}>{item?.expressionArray.join('')}</Typography>
      {item?.variables.map(({ label, emoji, defaultValue, defaultEmoji }, index) => (
        <Box key={`${index}`} className={styles.textInputContainer}>
          <Typography className={styles.textInputLabel}>{emoji || defaultEmoji}</Typography>
          <TextField
            // keyboardType="numeric"
            type="number"
            className={styles.textInput}
            placeholder={
              `${label ? label : `Variable ${index + 1}`
              }${defaultValue ? ` (${defaultValue})` : ''
              }`
            }
            value={variableValues[index].value}
            onChange={text => {
              const newArray = variableValues.slice()
              newArray[index] = { ...variableValues[index], value: text }
              setVariableValues(newArray)
            }}
          />
          {variableValues[index].value?.length > 0 && (
            <Button
              className={styles.textInputClearContainer}
              onClick={() => {
                const newVariableValues = variableValues.slice()
                newVariableValues[index] = { ...variableValues[index], value: '' }
                setVariableValues(newVariableValues)
              }}
            >
              {/* <AntDesign
                name="closecircle"
                color={theme.color.text}
                size={18}
                style={styles.textInputClear(theme)}
              /> */}
            </Button>
          )}
        </Box>
      ))}
      <Box className={styles.resultContainer}>
        {result?.length > 0 && error?.length === 0 ? (
          <Typography className={styles.resultText} selectable={true}>
            {result === 'Infinity' ? getInfinityText() : resultText}
          </Typography>
        ) : (
          <Typography className={styles.resultText}>
            {
              error?.length > 0
                ? error
                  .replace('*', '×')
                  .replace('−', '-')
                  .replace('pi', 'π')
                  .replace('/', '÷')
                : 'Result'
            }
          </Typography>
        )}
        {/* <AntDesign
          name="copy1"
          color={theme.color.text}
          size={26}
          onPress={onCopyPress}
          style={{ opacity: result.length > 0 ? 1 : 0.4 }}
        /> */}
      </Box>
      <Box className={styles.buttonsContainer}>
        <Button
          // underlayColor={theme.color.underlay}
          className={styles.button}
          onClick={onClearFieldsPress}
        >
          <Typography className={styles.buttonText}>Clear Inputs</Typography>
        </Button>
      </Box>
      {item?.description?.length > 0 && (
        <>
          <Typography className={styles.labelText}>Description</Typography>
          <Typography className={styles.descriptionText}>{item?.description}</Typography>
        </>
      )}
    </Box>
  )
}

export default Formula
