import React from 'react';
import LabelColorBox from './LabelColorBox'
import labelData from './LabelColorBox/LabelData.json'
import Charts from './charts'
import VendorBoxes from './VendorBoxes'

const VendorDashBoard =()=>{

    return(
        <div style={{background:"#f7f7f7",padding:"13px"}}>
            <LabelColorBox labelData={labelData} />
            <VendorBoxes />
            <Charts />
        </div>
    )

}

export default VendorDashBoard