import React, { useEffect, useState } from 'react';
import { Query, Builder, BasicConfig, Utils as QbUtils } from 'react-awesome-query-builder';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import throttle from 'lodash/throttle';
// For AntDesign widgets only:
// import AntdConfig from 'react-awesome-query-builder/lib/config/antd';
// import 'react-awesome-query-builder/css/antd.less'; // or import "antd/dist/antd.css";
// For Material-UI widgets only:
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
// Choose your skin (ant/material/vanilla):
// const InitialConfig = AntdConfig; // or MaterialConfig or BasicConfig

import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css'; //optional, for more compact styles

// You need to provide your own config. See below 'Config format'
const config = {
  ...MaterialConfig,
  fields: {
    qty: {
      label: 'Qty',
      type: 'number',
      fieldSettings: {
        min: 0,
      },
      valueSources: ['value'],
      preferWidgets: ['number'],
    },
    price: {
      label: 'Price',
      type: 'number',
      valueSources: ['value'],
      fieldSettings: {
        min: 10,
        max: 100,
      },
      preferWidgets: ['slider', 'rangeslider'],
    },
    color: {
      label: 'Color',
      type: 'select',
      valueSources: ['value'],
      fieldSettings: {
        listValues: [
          { value: 'yellow', title: 'Yellow' },
          { value: 'green', title: 'Green' },
          { value: 'orange', title: 'Orange' }
        ],
      }
    },
    is_promotion: {
      label: 'Promo?',
      type: 'boolean',
      operators: ['equal'],
      valueSources: ['value']
    },
  }
};

// You can load query value from your backend storage (for saving see `Query.onChange()`)
// const queryValue = {"id": QbUtils.uuid(), "type": "group"};


const RuleQueryBuilder = (props) => {
  const [state, setState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(props.state.form.queryBuilder), config),
    config: config
  })

  useEffect(() => {
    updateResult();
  }, [props.state.form.queryDatamodel])

  const updateResult = throttle(() => {
    console.log("config", getConfig(), props.state.form.queryBuilder);
    let tree = { ...props.state.form.queryBuilder };
    if (tree.children1) {
      Object.keys(tree.children1).map((key) => {
        tree.children1[key].properties.defaultValue = [...tree.children1[key].properties.value]
      })
    }
    setState({ ...state, tree: QbUtils.checkTree(QbUtils.loadTree(tree), getConfig()), config: getConfig() })
  }, 100)

  const getConfig = () => {
    let cg = { ...MaterialConfig }
    cg['fields'] = {};
    let list = props.dataModels.data;
    for (let i = 0; i < list.length; i++) {
      if (props.state.form.queryDatamodel === list[i]._id) {
        let dataElements = list[i].dataElements;
        for (let k = 0; k < list[i].dataElements.length; k++) {
          cg.fields[`${dataElements[k].name}`] = {
            label: dataElements[k].name,
            type: getDataElementType(dataElements[k].type),
            valueSources: ['value'],
            preferWidgets: getDataElementWidget(dataElements[k].type)
          }
        }
      }
    }
    console.log("cg", cg);
    return cg;
  }

  const getDataElementType = (type) => {
    let ty = type.toLowerCase();
    switch (ty) {
      case "string":
      case "text":
        return "text"
      case "number":
      case "integer":
        return "number"
      case "date":
        return "date"
      case "datetime":
        return "datetime"
      default:
        return "text"
    }

  }

  const getDataElementWidget = (type) => {
    let ty = type.toLowerCase();
    switch (ty) {
      case "string":
      case "text":
        return ['text']
      case "number":
      case "integer":
        return ["number"]
      case "date":
        return ['date']
      case "datetime":
        return ['datetime']
      default:
        return ['text']
    }

  }

  const renderBuilder = (props) => (
    <div className="query-builder-container" style={{ padding: '10px 0px' }}>
      <div className="query-builder qb-lite" style={{ margin: '0px' }}>
        <Builder {...props} />
      </div>
    </div>
  )

  const renderResult = ({ tree: immutableTree, config }) => (
    <div className="query-builder-result">
      <div>Query string: <pre>{JSON.stringify(QbUtils.queryString(immutableTree, config))}</pre></div>
      <div>MongoDb query: <pre>{JSON.stringify(QbUtils.mongodbFormat(immutableTree, config))}</pre></div>
      <div>SQL where: <pre>{JSON.stringify(QbUtils.sqlFormat(immutableTree, config))}</pre></div>
      <div>JsonLogic: <pre>{JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config))}</pre></div>
    </div>
  )

  const onChange = (immutableTree, config) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    setState({ ...state, tree: immutableTree, config: config });
    console.log("immutableTree", immutableTree);
    let jsonTree = QbUtils.getTree(immutableTree, false);
    console.log("jsonTree", jsonTree, typeof (jsonTree.children1));
    console.log("tree=====>", jsonTree);
    props.handleChangeTree(jsonTree);
    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  }

  return (
    <div className="rule-query">
      {console.log("props.state.form.queryBuilder", props.state.form.queryBuilder)}
      <FormControl variant="outlined" className={props.classes.formControl} style={{ width: "30%", marginLeft: "0px" }}>
        <Select
          value={props.state.form.queryDatamodel}
          onChange={(data) => props.handleQueryDatamodelChange(data)}>
          <MenuItem value="">
            <em>Select Data Model</em>
          </MenuItem>
          {props.dataModels.data.map((item, i) => {
            return <MenuItem key={i} value={item._id}>{item.name}</MenuItem>
          })}
        </Select>
      </FormControl>
      <Query
        {...state.config}
        value={state.tree}
        onChange={onChange}
        renderBuilder={renderBuilder}
      />
      {/* {renderResult(state)} */}
    </div>
  )
}

export default RuleQueryBuilder;