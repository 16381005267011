import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { updateProfile } from '../../store/actions';
import { getOrgDetails } from "../../store/actions/org"
import { PUBLIC_PATH } from 'constants/index';
import { ProfileDetail as ProfileComp } from "dsilo-ui-components"
import AppHeader from '../AppsDashboard/AppHeader';

const Profile = props => {
	return (
		<AppHeader>
			<ProfileComp
				orgPath={`/org`}
				resetPath={`/${PUBLIC_PATH}resetpassword/${localStorage.getItem('jwt_access_token')}`}
				updateProfile={props.updateProfile}
				getOrgDetails={props.getOrgDetails}
				user={{ token: localStorage.getItem('jwt_access_token'), ...props.user }}
				org={props.org}
				appId={props.match.params.appid}
				history={props.history}
			/>
		</AppHeader>

	);
}

const mapDispatchToProps = {
	updateProfile,
	getOrgDetails
}
const mapStateToProps = state => {
	return {
		user: state.user,
		org: state.org
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
