export function conditionObj() {
    return {
        isSelectedValue: 1,
        dataModelLeftSelected: '',
        dataElementLeftList: [],
        dataElementLeftSelected: '',
        dataElementType: '',
        operatorSelected: '',
        inputField: '',
        selectedComparator: '',
        dataModelRightSelected: '',
        dataElementRightList: [],
        dataElementRightSelected: '',
    }
}

export function actionObj() {
    return {
        isSelectedValue: 1,
        actionTypeSelected: "",
        dataModelSelected: '',
        dataElementList: [],
        dataElementMultiList: [],
        dataElementMultiSelected: [],
        dataElementSelected: '',
        setValue: '',
        dataValue: '',
        ruleContext: '',
        isError: false
    }
}

export const dataTypeNumList = [
    { "value": "is_empty", "name": "is empty" },
    { "value": "is_not_empty", "name": "is not empty" },
    { "value": "is_equal_to", "name": "is equal to" },
    { "value": "is_not_equal_to", "name": "is not equal to" },
    { "value": "is_greater_than", "name": "is greater than" },
    { "value": "is_less_than", "name": "is less than" },
    { "value": "in_between", "name": "in between" }]

export const dataTypeStringList = [
    { "value": "contains", "name": "contains" },
    { "value": "starts_with", "name": "starts with" },
    { "value": "ends_with", "name": "ends with" },
    { "value": "matches", "name": "matches" },
    { "value": "not_matches", "name": "doesn't match" },
    { "value": "includes", "name": "includes" },
    { "value": "is_empty", "name": "is empty" },
    { "value": "is_not_empty", "name": "is not empty" },
    { "value": "is_equal_to", "name": "is equal to" },
    { "value": "is_not_equal_to", "name": "is not equal to" },
    { "value": "is_greater_than", "name": "is greater than" },
    { "value": "is_less_than", "name": "is less than" },
    { "value": "in_between", "name": "in between" }]

export const actionTypesList = [
    { "value": "set", "name": "Set" },
    { "value": "get", "name": "Get" },
    { "value": "remove", "name": "Remove" },
    { "value": "create", "name": "Create" },
    { "value": "send", "name": "Send" }]

export const selectedComparatorList = [
    { "value": "datamodel", "name": "Data Model" },
    { "value": "inputField", "name": "Input Field" },
    { "value": "datamodelWithTables", "name": "Datamodel with Tables" },

]


export function retriveconditionsData(conditionData, dataModels) {
    let conditionList = [];
    console.log("conditionData", conditionData, dataModels);
    conditionData.map((item) => {
        let obj = {
            isSelectedValue: 1,
            dataModelLeftSelected: item.from_datamodel,
            dataElementLeftList: (dataModels.data.filter((element) => element._id === item.from_datamodel).map((ele) => ele.dataElements))[0] || [],
            dataElementLeftSelected: item.from_dataelement,
            dataElementType: item.dataElementType,
            operatorSelected: item.operator,
            selectedComparator: item.selectedComparator,
            inputField: item.inputField,
            dataModelRightSelected: item.to_datamodel,
            dataElementRightList: (dataModels.data.filter((element) => element._id === item.to_datamodel).map((ele) => ele.dataElements))[0] || [],
            dataElementRightSelected: item.to_dataelement,
        }
        conditionList.push(obj);
    })
    return conditionList;
}


export function retriveActionsData(actionData, dataModels) {
    let actionList = [];
    console.log("actionData", actionData, dataModels);
    actionData.map((item) => {
        if (item) {
            let dataEleList = dataModels && dataModels.data ? dataModels.data.filter((element) => element._id === item.data_model).map((dataM) => dataM.dataElements) : [];
            if (dataEleList.length > 0) {
                let eleList = dataEleList.map((dataM) => dataM.dataElements);
            }
            let obj = {
                isSelectedValue: 1,
                actionTypeSelected: item.action_type || "",
                dataModelSelected: item.data_model,
                dataElementList: dataEleList[0],
                // dataElementMultiList: z1,
                dataElementMultiList: dataEleList && dataEleList.length > 0 ? dataEleList[0].map((ele) => { return { label: ele.name, value: ele.name }; }) : [],
                dataElementMultiSelected: item.data_elements ? item.data_elements.map((item) => { let data = { label: item, value: item }; return data }) : [],
                dataElementSelected: item.data_element ? item.data_element : "",
                setValue: item.action_type === "set" ? item.data_value : "",
                dataValue: "",
                ruleContext: ''
            }
            actionList.push(obj);
        }
    })
    return actionList;
}

export function constructConditionsData(conditionsUIList, dataModels) {

    return (conditionsUIList.map((item, index) => {
        if (item.dataModelLeftSelected !== "" && item.dataElementLeftSelected !== "" &&
            ((item.dataModelRightSelected !== "" && item.dataElementRightSelected !== "")
                || item.inputField !== "" || item.operatorSelected === "is_empty")) {
            let obj = {};
            obj.condition_seq = index + 1;
            //console.log("props.dataModels", props.dataModels);
            dataModels.data.map((element) => {
                //console.log("state.dataModels11", element);
                if (element._id === item.dataModelLeftSelected) {
                    obj.from_datamodel = element._id;
                }
                if (element._id === item.dataModelRightSelected) {
                    obj.to_datamodel = element._id;
                }
            });
            obj.from_dataelement = item.dataElementLeftSelected;
            obj.to_dataelement = item.dataElementRightSelected;
            obj.dataElementType = item.dataElementType;
            obj.operator = item.operatorSelected;
            obj.selectedComparator = item.selectedComparator;
            obj.inputField = item.inputField;
            return obj
        }
    }).filter(function (element) {
        return element !== undefined;
    }))
}

export function constructActionsData(actionsUIList, dataModels) {
    return (actionsUIList.map((item) => {
        if (item.actionTypeSelected !== "") {
            if (item.actionTypeSelected === "get" && item.dataModelSelected !== "" && item.dataElementMultiSelected.length > 0) {
                let obj = {};
                obj.action_type = "get";
                dataModels.data.map((ele) => {
                    if (ele._id === item.dataModelSelected) {
                        obj.data_model = ele._id;
                    }
                });
                obj.data_elements = [];
                item.dataElementMultiSelected.map((multiEle) => {
                    obj.data_elements.push(multiEle.label);
                })
                return obj;
            } else if (item.actionTypeSelected === "set" && item.dataModelSelected !== "" && item.dataElementSelected !== "" && item.setValue !== "") {
                let obj = {};
                obj.action_type = "set";
                dataModels.data.map((ele) => {
                    if (ele._id === item.dataModelSelected) {
                        obj.data_model = ele._id;
                    }
                });
                obj.data_element = item.dataElementSelected;
                obj.data_value = item.setValue;
                return obj;
            }
        }
    }).filter(function (element) {
        return element !== undefined;
    }))
}