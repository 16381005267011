import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/styles';
import { FormControl } from '@material-ui/core';

const Input = withStyles({
  root: {
    'label + &': {
      marginTop: 25,
    },
  },

  input: {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    verticalAlign: 'top',
  },
})(InputBase);
const Label = withStyles({
  root: {
    fontSize: 18,
    fontWeight: 'bold',
  },
})(InputLabel);

const InputField = props => {
  if (props.label === 'false' || props.label === false)
    return <Input placeholder={props.placeholder} {...props} />;
  else {
    return (
      <FormControl fullWidth>
       {props.label && <Label shrink>
          {props.label} {props.asterisk && <span style={{ color: 'red' }}>*</span>}{' '}
        </Label>}
        <Input placeholder={props.placeholder} {...props} />
      </FormControl>
    );
  }
};

export default InputField;
