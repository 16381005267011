import { ActionTypes } from 'constants/index';

const initialState = {
    prevPath: '',
    prevSearch: '',
    usePrevPath: false
};

const navigation = function (state = initialState, action) {
    
    switch ( action.type )
    {
        case ActionTypes.SET_PREV_PATH: {
            return {
                ...state,
                prevPath: action.path.prevPath,
                prevSearch: action.path.prevSearch,
                usePrevPath: true,
            };
        }
        case ActionTypes.EMPTY_PREV_PATH: {
            return {
                ...state,
                ...initialState,
            };
        }
        default:
        {
            return state
        }
    }
};

export default navigation;