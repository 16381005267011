import { ActionTypes } from 'constants/index';

const initialState = {
    success: false,
    isLoading: false,
    error  : ''
};

const register = function (state = initialState, action) {
    switch ( action.type )
    {
        case ActionTypes.REGISTER_LOADING: {
            return {
                ...state,
                isLoading: true,
                error: ''
            };
        }
        case ActionTypes.REGISTER_SUCCESS:
        {
            return {
                ...initialState,
                success: true,
                isLoading: false,
            };
        }
        case ActionTypes.REGISTER_ERROR:
        {
            return {
                success: false,
                error  : action.error,
                isLoading: false,
            };
        }
        default:
        {
            return state
        }
    }
};

export default register;
