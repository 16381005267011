import { PDFEditableTableWrapper } from "dsilo-ui-components";
import React from "react";
import { hot } from "react-hot-loader/root";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    getDataModelById,clearSuccessMsg, createDocument, downloadContractPDFAction, downloadSheet,
    getContractDataById, getModelDataDropDown, processAgain, updateDataModelData, clearReduxDataOfCurrentComponent,
    getEventByID, initiateAction,getModelData, addNewEntriesByDataModel
} from '../../store/actions';
import { Loader } from "../loader";
import { PUBLIC_PATH } from 'constants/index';

const PDFEditableTableWrapperComponent = (props) => {

    return (<PDFEditableTableWrapper
        loader={<Loader />}
        {...props}
        publicPath={PUBLIC_PATH}
    />)
}

const mapDispatchToProps = {
    getDataModelById,
    getContractDataById,
    updateDataModelData,
    clearSuccessMsg,
    downloadSheet,
    getModelDataDropDown,
    downloadContractPDFAction,
    createDocument,
    processAgain,
    clearReduxDataOfCurrentComponent,
    getEventByID,
    initiateAction,
    getModelData,
    addNewEntriesByDataModel
};

const mapStateToProps = state => {
    return {
        formData: state.form,
        appConfig: state.appConfig.app,
        action: state.action,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFEditableTableWrapperComponent)));
