const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_APP_BASE_URL = process.env.REACT_APP_API_BASE_URL_CREATE;
const API_DATA_SERVICE = process.env.REACT_APP_DATA_SERVICE_URL;
import { request } from './../client';

import { APP_CONFIG } from 'constants/index';
import { useRadioGroup } from '@material-ui/core';

export class AppService {
	static getAppConfig({ appId, orgId }) {
		const uri = API_BASE_URL + "/dapps/" + appId;
		console.log("orgId, appId", orgId, appId);
		return request(uri, {
			method: 'GET',
			headers: {
				orgId
			}
		});
	}
	static getApps(orgId) {
		const uri = API_APP_BASE_URL  + "/dapps/apps";
		console.log("orgId", orgId);
		return request(uri, {
			method: 'GET',
			headers: {
				orgId
			}
		});
	}
	
	static getAppsDetails(data) {
		const uri = API_APP_BASE_URL  + "/dapps/verify";
		return request(uri, {
			method: 'GET',
			headers: {
				appid: data.appId,
			}
		});
	}
	static  getTemplates(orgId) {
		const uri = API_APP_BASE_URL  + "/dapps/apptemplates";
		return request(uri, {
			method: 'GET',
			headers: {
				orgId
			}
		});
	}




	static getEntities({ appId, orgId }) {
		const uri = API_BASE_URL + "/entities";
		return request(uri, {
			method: 'GET',
			headers: {
				orgId,
				appId
			}
		});
	}
	static getRoles(appId) {
		const uri = API_BASE_URL + "/roles";
		return request(uri, {
			method: 'GET',
			headers: {
				appId
			}
		});
	}
	static getOrgDetails() {
		const uri = API_BASE_URL + '/orgs/getdetails';
		return request(uri, {
		  method: 'GET',
		});
	  }
	  
	static getApiDataFromDataUrl(dataUrl, appId) {

		return request(dataUrl, {
			method: 'GET',
			headers: { "appId": appId }
		});
	}
	static saveDapp(data, appId) {
		const uri = API_BASE_URL + "/dapps/add";

		return request(uri, {
			method: 'POST',
			payload: data,
			headers: { "appId": appId }
		});
	}
	static saveDatasource(data) {
		const uri = API_BASE_URL + "/datasources/add";

		return request(uri, {
			method: 'POST',
			payload: data,
			headers: {
				appId: data.appId
			}
		});
	}
	static getDataelements(datasourceId, appId) {
		const uri = `${API_BASE_URL}/datasources/dataelements/${datasourceId}`;

		return request(uri, {
			method: 'GET',
			headers: { "appId": appId }
		});
	}
	static getMember(user) {
		console.log("user=====>", user);
		const uri = `${API_BASE_URL}/member/uid`;
		return request(uri, {
			method: 'POST',
			payload: { id: user.id, email: user.email },
			headers: { 'appId': user.app._id }
		});
	}
	static getEntity({ appId, entityId }) {
		const uri = API_BASE_URL + "/entities/" + entityId;
		return request(uri, {
			method: 'GET',
			headers: {
				appId
			}
		});
	}
	static getMemberById(appId) {
		const uri = API_APP_BASE_URL+"/orgs/member";

		return request(uri, {
			method: 'GET',
			headers: {
				appId: appId
			}
		});
	}
	static saveDataModel(data) {
		const uri = API_BASE_URL + "/dataModels/add"
		let { appId, orgId } = data
		delete data.appId
		delete data.orgId
		return request(uri, {
			method: 'POST',
			payload: data,
			headers: {
				appId,
				orgId
			}
		})
	}
	static getApiDataTopFromDataUrl(obj, appId) {
		const uri = API_BASE_URL + obj.url;
		return request(uri, {
			method: 'POST',
			headers: { "appId": appId },
			payload: obj
		});
	}

	static verifyApp(appId) {
		const uri = API_APP_BASE_URL + "/dapps/verify"
		return request(uri, {
			method: 'GET',
			headers: {
				'appId': appId
			}
		})
	}

}

export function apiBaseAddress() {
	return API_BASE_URL;
}
