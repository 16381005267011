import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    flexColumn: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    container: props => ({
        height: props.height || '100px',
        minHeight: '100px',
        width: props.width,
        backgroundColor: props.bgColor ? props.bgColor : '#dc143c',
        margin: '10px 3px',

    }),
    colorWhite: {
        color: '#fff',
    },
    title: {
        margin: '10px 0px 20px 0px',
    }
}))

export default useStyles