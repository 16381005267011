import { Button, makeStyles } from '@material-ui/core';
import { ConfirmationHandler } from 'dsilo-ui-components';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PUBLIC_PATH } from '../../constants';
import { checkForTraining, clearTrainingCheckData, updateModel } from '../../store/actions';
import { Loader } from "../loader";

const ActionsComponent = props => {
    const classes = useStyles();
    const [confirmationState, setConfirmationState] = useState({
        isOpen: false,
        message: ""
    });
    const [checkState, setCheckState] = useState({
        isOpen: false,
        message: ""
    });
    const [state, setState] = useState(null)

    useEffect(() => {
        props.clearTrainingCheckData()
    }, [])

    const isTestingOrTrainingAvailable = (action) => {
        let selectedModelId = props.model.model[0].modelId;
        let index = action.redirectUrl.findIndex(item => item.modelId === selectedModelId)
        return index !== -1
    }

    const onClick = (action) => {
        if (action.custom.hasAction) {
            if (action.custom.action === 'INITMODELFORTRAINING') {
                let payload = {
                    modelId: action.redirectUrl[0].modelId,
                    appId: props.match.params.appid,
                }
                if (isTestingOrTrainingAvailable(action)) {
                    props.checkForTraining({ ...payload, dataUniqueId: props.data.chartUniqueId + '_data', params: 'training' })
                    setState(action);
                } else {
                    setCheckState({
                        isOpen: true,
                        message: 'There is no configuration available for this model. Please update configuration to start training'
                    })
                }
            }
            if (action.custom.action === 'UPDATEMODELSTATUSTESTING') {
                let payload = {
                    modelId: action.redirectUrl[0].modelId,
                    appId: props.match.params.appid,
                    status: action.name,
                    _id: props.model.model[0]._id, // send modelID and backend will find and update training to update.
                }
                if (isTestingOrTrainingAvailable(action)) {
                    props.updateModel({ ...payload, dataUniqueId: props.data.chartUniqueId + '_data' })
                    setState(action);
                } else {
                    setCheckState({
                        isOpen: true,
                        message: 'There is no configuration available for this model. Please update configuration to start testing'
                    })
                }

            }
        } else {
            props.history.push(`/${PUBLIC_PATH}${props.match.params.appid}/page/${action.pageUrl}`)
        }
    }

    const onClose = () => {
        setConfirmationState({ ...confirmationState, isOpen: false })
        props.clearTrainingCheckData()
        if(state?.redirectUrl[0]?.url){
            props.history.push(`/${PUBLIC_PATH}${props.match.params.appid}/page/${state?.redirectUrl[0]?.url}`)
        }
    }

    useEffect(() => {
        if (props.model?.trainingCheck) {
            if (props.model?.trainingCheck && props.model.trainingCheck?.error?.status === 400) {
                setConfirmationState({
                    isOpen: true,
                    message: props.model.trainingCheck.error.response.message
                })
            } else if(state?.redirectUrl[0]?.url){
                props.history.push(`/${PUBLIC_PATH}${props.match.params.appid}/page/${state?.redirectUrl[0]?.url}`)
            }
        }
        // testing case  
        if (state?.name === 'Testing' && props.model?.model?.message === 'Model updated successfully') {
            props.history.push(`/${PUBLIC_PATH}${props.match.params.appid}/page/${state?.redirectUrl[0]?.url}`)
        }

    }, [props.model])

    const onCloseCheckModel = () => {
        setCheckState({ ...checkState, isOpen: false })
    }

    return (
        <div style={{ float: props.data.chartData?.actionsConfig?.contentAlign }}>
            {
                props.data.chartData?.actions?.map((action, index) => {
                    return (<Button
                        key={index}
                        color="primary"
                        onClick={() => onClick(action)}
                        className={classes.button}
                        disableElevation
                        variant={props.variant}
                    >{action.label}</Button>);
                })
            }
            <ConfirmationHandler open={confirmationState.isOpen} handleClose={onClose}
                alertMessageText={{ p2: confirmationState.message }} classes={classes} />
            <ConfirmationHandler open={checkState.isOpen} handleClose={onCloseCheckModel}
                alertMessageText={{ p2: checkState.message }} classes={classes} />
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    button: {
        '&:hover': {
            backgroundColor: '#007bff',
        },
        marginRight: theme.spacing(1),
        backgroundColor: '#005a87',
        color: '#fff',
    },
}))

const mapDispatchToProps = {
    checkForTraining,
    clearTrainingCheckData,
    updateModel
};

const mapStateToProps = state => {
    return {
        model: state.model,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ActionsComponent)));
