import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
	heading: {
		backgroundColor: "#cecece",
		fontWeight: "bold"
	},
	card: {
		padding: "5px 15px",
		// boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
		marginLeft: 5,
		marginRight: 5
	},
	marginTop: {
		marginTop: 15
	},
	header: {
		display: "flex",
		justifyContent: "space-between",
		borderBottom: "2px solid #cecece"
	},
	actionContainer: {
		display: "flex",
		justifyContent: "flex-end"
	},
	button: {
		"&:hover": {
			backgroundColor: "#007bff"
		},
		height: 35,
		marginLeft: theme.spacing(1),
		backgroundColor: "#005a87",
		color: "#fff",
		marginBottom: 10
	},
	table: {
		// height: 'calc(100vh - 250px)',
		height: '100%',
		overflowY: 'auto',
		width: "100%"
	},
	noDataText: {
		display: 'flex',
		justifyContent: "center",
		alignItems: 'center',
		height: 'calc(100vh - 450px)',
	},
	paperLabel: {
		padding: '2px',
		color: 'grey',
		boxShadow: "none !important",
		// paddingLeft: "4%",
		// paddingTop: "7%",
		marginBottom: '18px',
		textAlign: "center"
	},
    paperLabelValue: {
		padding: '2px',
		color: '#022e05',
		boxShadow: "none !important",
		// paddingLeft: "4%",
		// paddingTop: "7%",
		marginBottom: '18px',
		textAlign: "center",
        fontWeight: "bold"
    }
}))

export default useStyles