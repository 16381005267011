import { makeStyles } from '@material-ui/core';
import { PUBLIC_PATH } from 'constants/index';
import { MiniTable } from 'dsilo-ui-components';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getTasks } from '../../store/actions';

const useStyles = makeStyles(theme => ({
    gridItem: {},
}));

const MyTasks = (props) => {

    let recentPromotionContracts = {
        title: 'Recent Promotion Contracts',
        dataSource: [],
        columns: [
            {
                key: 'originalName',
                label: 'File',
                style: {
                    // width: 25,
                },
            },
            {
                key: 'createdDate',
                label: 'Date',
                style: {},
            },
            {
                key: 'status',
                label: 'Status',
                style: {},
            },
            {
                key: 'createdByName',
                label: 'Created By',
                style: {

                },
            },
        ],
        styleConfig: {
            status: { conditions: { 'No Results': { color: 'red' }, "Processed": { color: 'green' } } }
        },
        moreClickAction: () => {
            let pageUrl = chartData?.moreLink || 'documents'
            props.history.push({
                pathname: `/${PUBLIC_PATH}${props.match.params.appid}/page/${pageUrl}`,
            });
        },
    };

    const classes = useStyles();
    const [recentPromo, setRecentPromo] = useState(recentPromotionContracts);
    const { chartData } = props.data;

    recentPromo.title = chartData.name
    recentPromo.columns = chartData.selectedDataElement
    if (chartData.calculatedFields) {
        chartData.calculatedField?.map(field => {
            recentPromo.columns.push(field)
        })
    }

    recentPromo.dataSource = (props.formData?.tasks && props.formData?.tasks[props.data.chartUniqueId + '_tasks']) ?
        props.formData?.tasks[props.data.chartUniqueId + '_tasks'] : []
    // if (recentPromo?.dataSource) {
    //     recentPromo.dataSource = recentPromo?.dataSource?.map(item => {
    //         if (Object.keys(item).includes('_id')) {
    //             item[chartData.groupByDataElement?.value] = item._id
    //         }
    //         return item
    //     }
    //     )
    // }

    useEffect(() => {
        let payload = {
            appId: props.match.params.appid,
            dataUniqueId: props.data.chartUniqueId + '_tasks'
        };
        props.getTasks(payload);
    }, []);

    const isNoData = () => {
        return props.formData?.tasks[props.data.chartUniqueId + '_tasks']?.length === 0
    }

    const loading = props.formData[props.data.chartUniqueId + '_tasks_loading'];

    console.log('recentPromo', recentPromo)
    return (
        <div style={{ height: 'calc(100% - 10px)' }}>
            {recentPromo && <MiniTable isMoreLink={chartData?.moreLink} noData={isNoData()} viewPage={chartData.viewPage}
                loading={loading} {...props} data={recentPromo} />}
        </div>
    );
};


const mapDispatchToProps = {
    getTasks,
};

const mapStateToProps = state => {
    return {
        formData: state.form,
    };
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(withRouter(MyTasks)));
