import React, { useEffect, useState } from 'react';
import {
    Chip,
    IconButton,
    makeStyles, Tooltip,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import InputField from '../../components/InputField/InputFiled';
import { Grid } from '@material-ui/core';

export default function MultiInput(props) {
    const classes = useStyles();
    const { values, setValues, id, label, placeholder } = props;

    const handleAltName = (e, i) => {
        values[i] = e.target.value.replace(/[^@-_.,&a-zA-Z0-9 \/-]/g, '');
        setValues([...values]);
    };

    const addAltName = () => {
        let updatedVales = values;
        updatedVales.push("")
        setValues([...updatedVales]);
    };
    const deleteAltName = (i) => {
        console.log('i', i)
        values.splice(i, 1);
        setValues([...values]);
    };

    const deleteRow = (i)=> {
        props.deleteRowDatapoint(i);
    }

    useEffect(() => {
        if (!Array.isArray(values)) {
            setValues([values]);
        }
    }, [values])

    useEffect(() => {
        if (!Array.isArray(values)) {
            setValues([values]);
        }
    }, [])

    const getData = () => {
        if (values && Array.isArray(values)) {
            return values;
        } else if (values && typeof (values) === "string") {
            return [values]
        }
        return [];
    }

    return (
        <Grid container alignItems="center">
            <div className={classes.rowContainer}>
                <div className={classes.itemHeadingContainer}>
                    {
                        props.keyEditDisabled ?
                            <span className={classes.marginView}>
                                {props.title}
                            </span> : 
                            <React.Fragment>
                                <InputField
                                    className={classes.inputField}
                                    onChange={event => props.onKeyChange(event.target.value, id)}
                                    value={props.title}
                                />
                                {
                                    props.rowDelete?
                                    <Tooltip title={'Remove this row'} aria-label={'Remove this row'}>
                                        <IconButton onClick={() => deleteRow(props.id)}>
                                            <DeleteIcon className={classes.icon} />
                                        </IconButton>
                                    </Tooltip> 
                                    : null
                                }
                                
                            </React.Fragment>
                    }
                </div>
                <div className={classes.itemValContainer} >
                    <>
                        {getData().map((altName, i) => {
                            return (<div className={classes.multiField} key={i + id}>
                                <div className={classes.input}>
                                    <InputField
                                        id={i + ""}
                                        placeholder={placeholder}
                                        onChange={e => handleAltName(e, i)}
                                        value={altName}
                                        className={i === 0 ? classes.inputField : classes.inputFieldMargin}
                                        required
                                    />
                                </div>
                                {(values.length === 1) ?
                                    null :
                                    <Tooltip title={'Remove this field'} aria-label={'Remove this field'}>
                                        <IconButton onClick={() => deleteAltName(i)}>
                                            <DeleteIcon className={classes.icon} />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </div>);
                        })}
                        <Tooltip title={'Add another field'} aria-label={'Add another field'}>
                            <IconButton onClick={addAltName} >
                                <AddCircleIcon fontSize="medium" className={classes.icon} />
                            </IconButton>
                        </Tooltip>
                    </>
                </div>
            </div>
        </Grid>
    );


}


const useStyles = makeStyles({
    multiField: {
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    input: { flex: 1, marginLeft: 10, marginRight: 5 },
    inputField: {
        width: '100%',
        flex: 1,
        fontSize: '14px'
    },
    inputFieldMargin: {
        width: '100%',
        flex: 1,
    },
    icon: {
        cursor: 'pointer',
    },
    itemHeadingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 0.2,
        marginRight: 20,
    },
    itemValContainer: {
        borderLeft: '1px solid #cecece',
        paddingLeft: 15,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        flex: 0.8,
    },
    rowContainer: {
        width: '100%',
        minHeight: '52px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #cecece',
        borderRadius: 5,
        padding: 15,
        marginBottom: 10,
    },
    marginView: {
        margin: '0px',
        textAlign: 'left',
    },
});