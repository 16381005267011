import { Box, Button, CircularProgress, Divider, Grid, IconButton, InputLabel, makeStyles, TextField } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import { Helpers, ChipSelect } from "dsilo-ui-components";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { hot } from 'react-hot-loader/root';
import { useHistory, withRouter } from 'react-router-dom';

import { getDataModelById, getContractDataById, updateDataModelData, createDocument, clearReduxDataOfCurrentComponent } from '../../store/actions';
import { Loader } from "../loader";

const useStyles = makeStyles((theme) => ({
    tabController: {
        "& .MuiTab-textColorInherit ": {
            minWidth: "0px"
        }
    },
    appBar: {
        boxShadow: "none !important"
    },
    ContactTabs: {
        marginTop: 'none !important',
        marginBottom: 'none !important',
        border: "3px solid #eeee",
    },
    paper: {
        width: '100%',
        border: "3px solid #eeee",
        marginLeft: '-41px !important'
    },
    backIconCls: {
        padding: '0px !important'
    },
    InputLabel: {
        marginTop: 10
    },
    TextField: {
        width: '100%',
        '& .MuiInputBase-formControl': {
            height: '43px !important'
        }
    },
    gridStyle1: { display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'flex-start' },
    gridStyle2: { display: 'flex', gap: '5px' }
}));

const dataElementTypes = ['array(string)', 'array(number)', 'array(currency)', 'array(date)'];

const SimpleForm = (props) => {
    const classes = useStyles();
    let history = useHistory();
    const { chartUniqueId, chartData } = props.data
    let _id = props.match.params.id // id is there then it is a edit mode
    let selectedDataElements = chartData?.selectedDataElement || []

    const [state, setState] = useState({})
    const [allDataElementsObj, setAllDataElementsObj] = useState({})

    useEffect(() => {
        //Fetch dataModel
        let payload = {
            appid: props.match.params.appid,
            orgId: props.appConfig?.org?._id,
            dataModelId: chartData.selectedDataModel,
            dataUniqueId: chartUniqueId + '_dataElements'
        }
        props.getDataModelById(payload)

        let fields = '';
        if (selectedDataElements?.length) {
            let isUpdated = false;
            let obj = {};
            selectedDataElements.map(sd => {
                fields += sd.value + ','
                if (sd.defaultValue) {
                    obj[sd.value] = sd.defaultValue;
                    isUpdated = true;
                }
            })
            if (isUpdated) {
                setState({ ...state, ...obj })
            }
        }

        if (_id) {
            let payload = {
                id: _id,
                dataModelId: chartData.selectedDataModel,
                appId: props.match.params.appid,
                // fields: selectedDataElements.map(i => i.value).join(","),
                fields: fields,
                dataUniqueId: chartUniqueId + '_get'
            }
            props.getContractDataById(payload)
        }
        return () => {
            clearCurrentChartReduxStore()
        }
    }, [])

    useEffect(() => {
        if (props.formData?.dataModel[chartUniqueId + '_dataElements']?.dataElements) {
            let allDes = props.formData?.dataModel[chartUniqueId + '_dataElements']?.dataElements || []
            let obj = {}
            allDes.forEach(de => {
                obj[de.name] = de
            })
            console.log('allDes ::: *** ', allDes, obj);
            setAllDataElementsObj(obj)
        }
    }, [props.formData?.dataModel[chartUniqueId + '_dataElements']])

    const clearCurrentChartReduxStore = () => {
        let subKeys = [
            chartUniqueId + '_get',
            chartUniqueId + '_save',
            chartUniqueId + '_update',
            chartUniqueId + '_dataElements',
        ]

        props.clearReduxDataOfCurrentComponent({
            key: "document",
            subKey: subKeys
        })
    }

    useEffect(() => {
        let res = props.formData.document[props.data.chartUniqueId + '_get'];
        if (res?.data?.[0]) {
            let news = res?.data?.[0] || {}
            setState({ ...state, ...news })
        }
    }, [props.formData.document[props.data.chartUniqueId + '_get']])

    useEffect(() => {
        let res = props.formData.document[props.data.chartUniqueId + '_save'];
        if (res) {
            toast.success(res.message || 'Document Created Successfully')
            handleBackBtnClick()
        }
    }, [props.formData?.document?.[props.data.chartUniqueId + '_save']])

    useEffect(() => {
        let res = props.formData.document[props.data.chartUniqueId + '_update'];
        if (res) {
            handleBackBtnClick()
        }
    }, [props.formData?.document?.[props.data.chartUniqueId + '_update']])

    const handleAddTextbox = (fieldName, fieldType) => {
        let _state = cloneDeep(state)
        let defaultValue = ["number", "currency"].includes(fieldType) ? 0 : ""
        if (!_state?.[fieldName]) {
            _state[fieldName] = [defaultValue]
        } else {
            _state[fieldName].push(defaultValue)
        }
        
        setState(_state)
    }

    const handleDeleteTextbox = (fieldName, fieldIndex) => {
        let _state = cloneDeep(state)
        _state[fieldName] = _state?.[fieldName]?.filter((item, i) => i !== fieldIndex)
        setState(_state)
    }

    const onChangeField = (key, value, fieldType = '', fieldIndex = 0) => {
        let _state = cloneDeep(state)
        if (fieldType && dataElementTypes.indexOf(fieldType) !== -1) {
            let isNumber = ["array(number)", "array(currency)"].includes(fieldType);
            let newValue = isNumber ? parseInt(value) : value;
            if (!_state?.[key]) {
                _state[key] = [newValue]
            } else {     
                _state[key][fieldIndex] = newValue
            }
        } else {
            _state[key] = value;
        }
        console.log('_state ::: *** ', _state)
        setState(_state)
    }

    const handleBackBtnClick = () => {
        history.goBack()
    }

    const onSaveClick = () => {
        let data = JSON.parse(JSON.stringify(state))
        selectedDataElements.forEach(sd => {
            if ((sd.hide || sd.readonly) && sd.defaultValue) {
                data[sd.value] = sd.defaultValue
            }
        })

        console.log("it is data ==", data)
        if (_id) {
            let formData = {
                appid: props.match.params.appid,
                dataModelId: props.data.chartData?.selectedDataModel,
                dataUniqueId: props.data.chartUniqueId + '_update',
                // payload: { ...state },
                payload: data,
            }
            props.updateDataModelData(formData)
        } else {
            let formData = {
                appid: props.match.params.appid,
                dataModelId: props.data.chartData?.selectedDataModel,
                dataUniqueId: props.data.chartUniqueId + '_save',
                // payload: state,
                payload: data,
            }
            props.createDocument(formData)
        }
    }

    const getValue = (key) => {
        let value = { value : state[key], label : state[key] }
        if (value && (value.value === null || value.value === undefined)) {
            value.value = '';
        }
        if (value && (value.label === null || value.label === undefined)) {
            value.label = '';
        }
        return value
    }

    const getOptions = (de) => {
        let options = []
        if (de.data?.type === 'custom') {
            if (de.data?.options?.length > 0) {
                options = de.data.options.map(option => {
                    return {
                        value: option?.value?.toString(),
                        label: option?.label?.toString()
                    }
                })
            }
        }
        return options;
    }

    if (Helpers.loading(props, '_get')) {
        return <Loader />
    }

    return (
        <div style={{position: 'absolute'}}>
            <div style={{ padding: "5px 0px", display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }} >
                    <IconButton onClick={handleBackBtnClick} className={classes.backIconCls} title={'Back'}>
                        <ArrowBackIosIcon />
                    </IconButton>
                    <h1>{props.page.title}</h1>
                </div>
                <div>
                    <Button
                        color="primary" onClick={onSaveClick}
                        style={{ textTransform: 'capitalize' }}
                        variant="contained">
                        {
                            (Helpers.loading(props, '_save') || Helpers.loading(props, '_update')) ? <CircularProgress color='#fff' size={24} /> : (_id ? "Update" : "Add")
                        }
                    </Button>
                </div>
            </div>
            <Divider />

            <Grid xs={12} spacing={2} container style={{ marginTop: 10, position: 'relative', overflow: 'visible' }}>
                {selectedDataElements.filter(item => !item?.hide).map((item, sdeIndex) => {
                    let de = allDataElementsObj[item.value]
                    let type = de?.type || 'string'
                    if(dataElementTypes.indexOf(type) !== -1) {
                        return (
                            <React.Fragment key={sdeIndex}>
                                <Grid className={classes.gridStyle1} item xs={2} sm={2}>
                                    <InputLabel 
                                        htmlFor="my-input"
                                        className={classes.InputLabel}
                                        id={`${item.value}_label_${sdeIndex}`}
                                    >
                                        {item.label}
                                    </InputLabel>
                                    <IconButton id={`${item.value}_add-new-box`} onClick={() => handleAddTextbox(item.value, type)}>
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                    {Array.isArray(state?.[item.value]) &&
                                    state?.[item.value]?.length > 0 ? (
                                        state?.[item.value]?.map((fieldValue, index) => (
                                            <Box key={index} className={classes.gridStyle2}>
                                                <TextField
                                                    id={`${item.value}_${sdeIndex}_${index}`}
                                                    name={`multiple-${item.value}`}
                                                    variant="outlined"
                                                    disabled={item?.readonly}
                                                    className={classes.TextField}
                                                    value={fieldValue}
                                                    placeholder={'Enter ' + item.label}
                                                    onChange={e =>
                                                        onChangeField(item.value, e.target.value, type, index)
                                                    }
                                                />
                                                {Array.isArray(state?.[item.value]) && state?.[item.value]?.length > 0  && state?.[item.value]?.length !== 1 && (
                                                    <IconButton
                                                        id={`${item.value}_delete_${index}`}
                                                        onClick={() => handleDeleteTextbox(item.value, index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                )}
                                            </Box>
                                            ))
                                        ) : (
                                            <TextField
                                                id={`${item.value}_${index}`}
                                                name={`multiple-${item.value}`}
                                                variant="outlined"
                                                disabled={item?.readonly}
                                                className={classes.TextField}
                                                value={state?.[item.value] || ''}
                                                placeholder={'Enter ' + item.label}
                                                onChange={e =>
                                                    onChangeField(item.value, e.target.value, type, index)
                                                }
                                            />
                                        )
                                    }
                                </Grid>
                            </React.Fragment>
                        );
                    }
                    return (
                        <React.Fragment>
                            <Grid item xs={2} sm={2} >
                                <InputLabel 
                                    htmlFor="my-input" 
                                    className={classes.InputLabel}
                                    id={`${item.value}_selectLabel_${sdeIndex}`}
                                >
                                    {item.label}
                                </InputLabel>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                {(type === 'select' || de?.data?.type === "custom") ? (
                                    <ChipSelect
                                        id={`${item.value}_${sdeIndex}`}
                                        label=''
                                        isSearchable
                                        placeholder=''
                                        isClearable={true}
                                        menuPlacement='top'
                                        options={getOptions(de)}
                                        isDisabled={item.readonly}
                                        value={getValue(item.value)}
                                        // className={classes.chipSelect}
                                        className={classes.TextField}
                                        textFieldProps={{
                                            InputLabelProps: {
                                                shrink: true
                                            },
                                            variant: "outlined",
                                            disabled: item.readonly
                                        }}
                                        variant={'fixed'}
                                        onChange={(v) => onChangeField(item.value, v?.value) }
                                        qapage={true}
                                    />
                                ) : (
                                    <TextField
                                        id={`${item.value}_${sdeIndex}`}
                                        name={item.value}
                                        variant="outlined"
                                        type={type === "currency" ? "number" : type}
                                        disabled={item.readonly}
                                        className={classes.TextField}

                                        value={state[item.value]}
                                        placeholder={'Enter ' + item.label}
                                        onChange={(e) => onChangeField(item.value, e.target.value)}
                                    />
                                )}
                            </Grid>
                        </React.Fragment>
                    )
                })}
            </Grid>
        </div>
    );
};

const mapDispatchToProps = {
    getDataModelById,
    getContractDataById,
    updateDataModelData,
    createDocument,
    clearReduxDataOfCurrentComponent,
}

const mapStateToProps = state => {
    return {
        formData: state.form,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(SimpleForm)))