import { IconButton, Paper, Popper } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CodeIcon from '@material-ui/icons/Code';
import FilterListIcon from '@material-ui/icons/FilterList';
import classNames from 'classnames';
import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { DateRangePicker, FilterMultiSelect, RangeSlider, FiltersUtils } from 'dsilo-ui-components';

import { Loader } from '../loader';

const useStyles = makeStyles({
    chipSelect: {
        minWidth: 250,
        marginTop: 10,
    },
    popper: {
        padding: 16,
    },
    planFilter: {
        border: '1px solid #ddd',
        borderRadius: 8,
        paddingLeft: 10,
        minWidth: 100,
        marginRight: 10,
        fontWeight: 'bold',
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    active: {
        border: '1px solid tomato'
    },
    clearAll: {
        fontWeight: 500,
        alignSelf: 'center',
        marginTop: 16,
        cursor: "pointer",
        minWidth: 70
    },
    filterTopStyle: {
        alignSelf: 'center', 
        marginRight: 10 
    },
    filterRightStyle: {
        margin: 5,
        padding: 5 
    }
});

const FiltersList = props => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorRef, setAnchorRef] = useState(null)
    const [localExternalFilterData, setLocalExternalFilterData] = useState(null)

    const open = Boolean(anchorRef);

    let _conditions = props.page?.conditions || [];

    const getFilterValue = (filterName, type) => {
        let filterFound = props?.filterValues?.[filterName];
        if (type === 'date' && filterFound) {
            return { startDate: filterFound?.startDate, endDate: filterFound?.endDate }
        }
        if (type === 'number' && filterFound) {
            return filterFound?.join(",")
        }
        if (type === 'namepicker' && filterFound) {
            return filterFound.value
        }
        if (filterFound) {
            return filterFound.value
        }
        return null;
    }

    const getNumberFilterValues = (value) => {
        let _data = localExternalFilterData && localExternalFilterData[value]
        if (_data) {
            let values = []
            _data.forEach(ii => {
                if (ii.value && ii.value?.replace) {
                    let val = ii.value?.replace(',', '')?.replace('$', '')
                    values.push(parseFloat(val))
                } else if (ii.value) {
                    values.push(parseFloat(ii.value))

                }
            })
            return { min: Math.min.apply(null, values), max: Math.max.apply(null, values) || 0 }
        }
        return { min: 0, max: 0 }
    }

    const generatelocalExternalFilterData = () => {
        let refData = localExternalFilterData
        if (props.externalFilters && props.formData?.externalFilterData) {
            if (!refData) {
                refData = {}
            }
            props.externalFilters.map((fil, index) => {
                refData[fil.value] = props.formData?.externalFilterData[props.chartUniqueId + '_externalFilter_' + fil.value]
                let value = getFilterValue(fil.value) || ''
                if (value) {
                    console.log('refData', refData)
                }
            })
        }
        setLocalExternalFilterData({ ...refData })
    }

    useEffect(() => {
        generatelocalExternalFilterData()
    }, [props.formData?.externalFilterData])

    useEffect(() => {
        if(!props?.drawerOpen && open) setAnchorRef(null);
    }, [props?.drawerOpen])

    const onClose = (isSave, plans, e) => {
        if (anchorEl.current && anchorEl.current.contains(e.target)) {
            return;
        }
        setAnchorEl(null)
    }

    const onPageExternalFilterChange = (pageFilterValues) => {
		let updatedConditions = FiltersUtils.createConditions(pageFilterValues, props?.externalFilters)
		props.setFilterValues(pageFilterValues)
		props.savePageFilterConditions(updatedConditions)
	}

    const onFilterChange = (name, value) => {
        let filterValues = { ...props.filterValues }
        filterValues[name] = value
        props.setFilterValues({ ...filterValues })
        onPageExternalFilterChange(filterValues)
    }

    const handleMultiFilterClick = (event, index) => {
        if (anchorEl && anchorEl[index]) {
            setAnchorEl(null);
        } else {
            let externalFilters = props.externalFilters
            console.log(" === handleMultiFilterClick === ***", index);
            console.log("externalFilters ==== ***", externalFilters[index]);
            let field = externalFilters && externalFilters[index] && externalFilters[index].value;
            if (field && !localExternalFilterData || !localExternalFilterData[field]) {
                fetchFilterData(externalFilters[index])
            }
            setAnchorEl({ [index]: event.currentTarget });
        }
    }

    const fetchFilterData = (externalFilter) => {
        if (externalFilter && props.page.selectedDataModel) {
            // _conditions
            if (props?.filterConditions?.length) {
                let updatedConditions = props.filterConditions.filter(cond => cond.selectedConditionDataElement !== externalFilter.value)
                // updatedConditions ==> don't send same filter condition to same filter api
                _conditions = [..._conditions, ...updatedConditions]
            }
            let payloadForDropDown = {
                appId: props.match.params.appid || props.match.params.dappId,
                id: props.page?.selectedDataModel,
                rowsPerPage: "all",
                conditions: _conditions
            };
            if (props.members && props.members.roles?.length > 0) {

                let isQALead = props.members.roles.find(role => role.name === 'Quality Lead')
				console.log('isQALead abc ===>>> ', isQALead)
				if (!isQALead) {
					let b = props.members.roles?.filter(ele => ele.name === 'Quality Analyst')
					if (b?.length > 0) {
						let obj = {
							"selectedConditionDataElement": "qualityReviewer.id",
							"selectedConditionOperator": "is_equal_to",
							"value": "CURRENT_MEMBER_ID",
							"selectedConditionClause": "and"
						}
						payloadForDropDown['conditions'] = [...(payloadForDropDown['conditions']), obj]
					}
				}
            }
            delete payloadForDropDown['modelDataType']
            delete payloadForDropDown['fields']

            payloadForDropDown.field = externalFilter.value
            payloadForDropDown['dataUniqueId'] = props.chartUniqueId + '_externalFilter' + '_' + externalFilter.value;
            console.log("payload for dropdown before calling api *** ", payloadForDropDown);
            return props.getModelDataDropDown({
                ...payloadForDropDown,
                dataUniqueId: payloadForDropDown['dataUniqueId']
            })
        }
    }

    const isLoading = (externalFilter) => {
        return !!props.formData?.[props.chartUniqueId + '_externalFilter' + '_' + externalFilter.value + "_loading"]
    }

    // pushing clear all as filter type because of conditional code in html
    // which is returing a JSX for every filter type
    // As a JSX is already returned so clearAll is not getting rendered as per desired behaviour
    const getUpdatedFilterList = (filters) => {
        let newFilters = [...filters];
        // flag to show clear all only when a filter is applied (hide clear all when no filter applied)
        let filterValues = { ...props.filterValues }
        let flag = false;
        for (let vals in filterValues) {
            if (!(filterValues[vals] === null)) {
                flag = true;
                break;
            }
        }
        if (flag) {
            newFilters.push({ value: 'clear', label: 'Clear All', type: 'clear' });
        }
        return newFilters;
    }

    const onClearAllClick = () => {
        let filterValues = { ...props.filterValues }
        for (let vals in filterValues) {
            filterValues[vals] = null;
        }
        props.setFilterValues(props.getInitialFilterValues())
        onPageExternalFilterChange(filterValues)
    }

    return (<>
        {Boolean(props.externalFilters?.length) && getUpdatedFilterList(props.externalFilters).map((filter, index) => {
            if (filter.type === 'string') {
                let filterData = localExternalFilterData && filter?.value && localExternalFilterData[filter.value]
                let valueObj = props?.filterValues?.[filter.value]?.length
                return (
                    <div key={filter.type+index.toString()} className={classNames(props?.page?.filterAlign === "right" ? classes.filterRightStyle : classes.filterTopStyle)}>
                        {/* <span>{filter.label}</span> */}
                        <div
                            className={
                                classNames(classes.planFilter, !!valueObj ? classes.active : '')
                            }
                        >
                            <span>{`--All ${filter.label}--`}</span>
                            <IconButton style={{ padding: 10 }}
                                onClick={(e) => handleMultiFilterClick(e, index)}>
                                <FilterListIcon style={{ fontSize: 14 }} />
                            </IconButton>
                        </div>
                        {anchorEl && anchorEl[index] && <FilterMultiSelect
                            allValues={Boolean(filterData && filterData.length) ? filterData : []}
                            anchorEl={anchorEl ? anchorEl[index] : null}
                            id={anchorEl ? anchorEl[index] ? 'transitions-popper' : undefined : undefined}
                            selectedValues={props?.filterValues[filter.value] || []}
                            onClose={(e) => onClose(e)}
                            isOpen={anchorEl ? anchorEl[index] : null}
                            onSave={v => {
                                setAnchorEl(null);
                                onFilterChange(filter.value, v)
                            }}
                            showLoader={isLoading(props.externalFilters[index])}
                            filterData={filterData}
                            loader={<Loader />}
                        />}
                    </div>
                )
            }

            if (filter.type === 'date') {
                return (<div key={filter.type+index.toString()} className={classNames(props?.page?.filterAlign === "right" ? classes.filterRightStyle : classes.filterTopStyle)}>
                    <DateRangePicker label=""
                        dateRange={getFilterValue(filter.value, 'date')}
                        onDateRangeChange={(val) => onFilterChange(filter.value, val)}
                    />
                </div>)
            }

            if (filter.type === 'number') {
                return (<div key={filter.type+index.toString()} className={classNames(props?.page?.filterAlign === "right" ? classes.filterRightStyle : classes.filterTopStyle)}>
                    <span style={{ whiteSpace: 'nowrap' }}>
                        {filter.label}
                    </span>
                    <IconButton
                        title={'Range'}
                        onClick={(e) => {
                            handleMultiFilterClick(e, index);
                            setAnchorRef(anchorRef ? null : e.currentTarget)
                        }}
                    >
                        <CodeIcon />
                    </IconButton>
                    <Popper open={open} anchorEl={anchorRef} style={{ zIndex: 1211 }}>
                        <Paper className={classes.popper} elevation={3}>
                            <RangeSlider
                                onRangeSelect={(val) => {
                                    setAnchorRef(null);
                                    onFilterChange(filter.value, val)
                                }}
                                isLoading={isLoading(props.externalFilters[index])}
                                range={getNumberFilterValues(filter.value)}
                                value={getFilterValue(filter.value, 'number')}
                                label={filter.label}
                                submitBtnLabel="Apply"
                            />
                        </Paper>
                    </Popper>
                </div>)
            }

            if (filter.type === 'namepicker') {
                let filterData = localExternalFilterData && filter?.value && localExternalFilterData[filter.value]
                let valueObj = props?.filterValues[filter.value]?.length
                return (
                    <div key={filter.type+index.toString()} className={classNames(props?.page?.filterAlign === "right" ? classes.filterRightStyle : classes.filterTopStyle)}>
                        {/* <span>{filter.label}</span> */}
                        <div
                            className={
                                classNames(classes.planFilter, !!valueObj ? classes.active : '')
                            }
                        >
                            <span>{`--All ${filter.label}--`}</span>
                            <IconButton style={{ padding: 10 }}
                                onClick={(e) => handleMultiFilterClick(e, index)}>
                                <FilterListIcon style={{ fontSize: 14 }} />
                            </IconButton>
                        </div>
                        {anchorEl && anchorEl[index] && <FilterMultiSelect
                            allValues={Boolean(filterData && filterData.length) ? filterData : []}
                            anchorEl={anchorEl ? anchorEl[index] : null}
                            id={anchorEl ? anchorEl[index] ? 'transitions-popper' : undefined : undefined}
                            selectedValues={props?.filterValues[filter.value] || []}
                            onClose={(e) => onClose(e)}
                            isOpen={anchorEl ? anchorEl[index] : null}
                            onSave={v => {
                                setAnchorEl(null);
                                onFilterChange(filter.value, v)
                            }}
                            showLoader={isLoading(props.externalFilters[index])}
                            filterData={filterData}
                            loader={<Loader />}
                        />}
                    </div>
                )
            }

            if (filter.type === 'clear') {
                return (
                    <div key={filter.type+index.toString()} className={classNames(classes.clearAll)} onClick={() => onClearAllClick()}>Clear All
                    </div>
                )
            }
        })}
    </>);
};

export default hot(withRouter(FiltersList));