import React, { memo, useEffect, useState } from 'react';
import FiltersList from '../PageLevelFilters/FiltersList';

const DashboardFilters = (props) => {
    const [filters, setFilters] = useState([]);
    const [filterValues, setFilterValues] = useState({});

    const { data, pageFilterConditions, setPageFilterConditions } = props;
    const { chartUniqueId, chartData } = data;
    const { externalFilter, selectedDataModel } = chartData;

    const getInitialFilterValues = () => {
        let obj = {};
        externalFilter.forEach(filter => {
            obj[filter.value] = null;
        })
        return obj;
    }

    useEffect(() => {
        if (externalFilter && Array.isArray(externalFilter) && externalFilter.length > 0) {
            setFilters(externalFilter);
            const initialFilterValues = getInitialFilterValues();
            setFilterValues(initialFilterValues);
        }
    }, [externalFilter]);

    return (
        <div style={{ display: "flex", margin: '5px 0px' }}>
            <FiltersList 
                {...props}
                chartUniqueId={chartUniqueId}
                externalFilters={filters}
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                getInitialFilterValues={getInitialFilterValues}
                filterConditions={pageFilterConditions}
                savePageFilterConditions={setPageFilterConditions}
                page={{ selectedDataModel }}
            />
        </div>
    );
}

export default memo(DashboardFilters);