import { Loader } from 'components/loader';
import { TileListWrapper } from 'dsilo-ui-components';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PUBLIC_PATH } from '../../constants';
import adobeLogo from '../../images/adobe-pdf-icon.png';
import {
  archivedData, bulkAssign,
  changeAssigneeRequest, checkForTraining, clearPDFData, clearReduxDataOfCurrentComponent, deleteContract, deleteContractPDFAction,
  downloadAnnotations, downloadContractPDFAction, downloadSheet, duplicatesGetRequest, duplicatesMoveRequest, executeWorkFlow, getContractDataById,
   getDataModelById, getDeletedDoc, getMembersList, getModelData, getModelDataDropDown, getNextRespondent, hideSuccessMsg, initWorkFlow, 
   restoreDeleteDoc, serviceBusStatusUpdateTileList, updateDataModelData, updateModelStatus,otherDocMove, getGroups, processAgain,
   getWorkflowById, setDataToLocalDB, getCognitiveSearchData,getCurrentStepRespondents
} from '../../store/actions';

const TileListComponent = (props) => {
  console.log(props, 'props')
  return <div>
    <TileListWrapper
      loader={<Loader />}
      PUBLIC_PATH={PUBLIC_PATH}
      adobeLogo={adobeLogo}
      {...props}
    />
  </div>;
};

const mapDispatchToProps = {
  updateModelStatus,
  getCognitiveSearchData,
  getCurrentStepRespondents,
  getModelData,
  deleteContractPDFAction,
  deleteContract,
  hideSuccessMsg,
  downloadAnnotations,
  clearPDFData,
  downloadSheet,
  updateDataModelData,
  getDataModelById,
  checkForTraining,
  downloadContractPDFAction,
  getModelDataDropDown,
  getContractDataById,
  serviceBusStatusUpdateTileList,
  clearReduxDataOfCurrentComponent,
  archivedData,
  getDeletedDoc,
  restoreDeleteDoc,
  getMembersList,
  bulkAssign,
  changeAssigneeRequest,
  getNextRespondent,
  executeWorkFlow,
  duplicatesGetRequest, 
  duplicatesMoveRequest,
  initWorkFlow,
  otherDocMove,
  getGroups,
  processAgain,
  getWorkflowById,
  setDataToLocalDB,
};

const mapStateToProps = state => {
  return {
    appConfig: state.appConfig.app,
    apps: state.app.app,
    login: state.login,
    user: state.user,
    formData: state.form,
    contractAnnotations: state.contractAnnotations,
    model: state.model,
    members: state.appConfig.members,
    chart:state.chart,
    appConfiguration: state.form.document?.appConfig
  };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(TileListComponent)));
