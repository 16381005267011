import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: 'calc(100% + 8px)',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    }
}));

export default function Loading() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CircularProgress />
        </div>
    );
}