import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import LineChart from './line'
import CallMadeIcon from '@material-ui/icons/CallMade';
import Button from '@material-ui/core/Button';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import InvoiceBoxes from './InvoiceBoxes'
import invoiceData from './invoiceData.json'
import amountData from './amountData.json'
import { Helpers } from 'dsilo-ui-components';
import { Paper } from '@material-ui/core';


const LabelColorBox = (props) => {

  const useStyles = makeStyles((theme) => ({
    paperLabel: {
      padding: '2px',
      color: 'grey',
      boxShadow: "none !important",
      marginBottom: '18px',
      textAlign: "center"

    },
    paperValue: {
      color: 'black',
      fontSize: 'xxx-large',
      fontWeight: 'bold',
      boxShadow: "none !important",
      paddingTop: 'inherit',
      marginBottom: '10px',
      textAlign: "center"
    },
    Icon: {
    },
    paperChart: {
      display: "flex",
      justifyContent: "space-between",
      paddingRight: 6,
    },
    paperLineChart: {
      width: 100,
    },
    paperdoughnutChart: {
      width: 86,
      marginTop: '-17px'
    },
    labelValue: {
      marginTop: "30px"
    },
    grid: {
      maxWidth: '15.22% !important',
      marginTop: 20,
      marginBottom: 20,
    },
    gridContainer: {
      background: '#ffffff',
      marginBottom: '10px'
    },
    button: {
      padding: "3px !important"
    },
    spanTag: {
      fontWeight: 700
    }
  }));

  const classes = useStyles();

  const { labelData } = props
  ChartJS.register(ArcElement, Tooltip, Legend);

  return (
    <Paper elevation={2}>
    <Grid container className={classes.gridContainer} xs={12}>
      {labelData && labelData.map((item, index) => (
        <Grid item xs={2} key={index} className={classes.grid} style={{ borderRight: '1px solid #ebedf2' }} >
          <div style={{ display: "grid", }}>
            <span className={classes.paperLabel}>{item.label}</span>
            <span className={classes.paperValue}>{Helpers.convertToNumber(item.value, 2) || 0}</span>
          </div>
          {/* <div className={classes.paperChart}>
                      <div className={classes.labelValue}>
                          <Button
                              style={{"background":item.btnBackGroundColor}}
                              color={item.chartColor==='red'?'secondary':'Primary'}
                              className={classes.button}
                              startIcon={item.chartColor==='red'?<TrendingDownIcon />:<CallMadeIcon />}
                            >
                              <span className={classes.spanTag}>{item.labelValue}</span>
                            </Button>
                      </div>
                      <div className={item.chartType === 'line'?classes.paperLineChart:classes.paperdoughnutChart}>
                        {
                          item.chartType === 'line'?<LineChart data={item.chartData} color={item.chartColor} grfColor={item.grfColor} />
                          : null
                        }
                      </div>
                    </div> */}
        </Grid>

      ))
      }
      <InvoiceBoxes invoiceData={invoiceData} amountData={amountData} />
    </Grid>
    </Paper>
  );
}

export default LabelColorBox
