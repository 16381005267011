import { Typography } from "@material-ui/core";
import React from "react";
import Supplier from '../../images/supplier.png';

export const Card = (props) => {
    return <div style={{ textAlign: 'center', padding:15, backgroundColor:'#cccccb',height: 250 }}>
        <img style={{ height: 75 }} src={Supplier} />
        <Typography variant="h6" gutterBottom component="div">
            {props.supplier.supplierName}
        </Typography>
        {/* <Typography variant="subtitle2" gutterBottom component="div">
            143, Main St, Dallas, Tx 19034
        </Typography> */}
    </div>
}