import React from "react"

import { CommentSection } from 'dsilo-ui-components';

const CustomCommentSection = (props) => {
    const { formData, classes, element, isLoading, dataObj, onChange, styles, selectedStep, addCommentClick, hidePostButton, commentTypeFieldState } = props;
    const comments = dataObj?.[element.value || element.name] || []

    // console.log("\n\n\n Data Obj Comments ===>> ", dataObj, element, dataObj[element.value] || dataObj[element.name]);
    return (
        <CommentSection
            comments={comments}
            value={commentTypeFieldState ? (commentTypeFieldState[element.value || element.name] || '') : ""}
            hidePostButton={hidePostButton}
            // isCurrentUserNotifier={isCurrentUserNotifier()}
            // isRejectCommentRequired={isRejectCommentRequired}
            loading={isLoading}
            addCommentClick={() => addCommentClick(element.stateVariable, element.value || element.name)}
            item={element}
            onChange={(e) => {
                onChange(element.stateVariable, e, undefined, 'comments', element, e);
            }}
            disabled={element.readOnly}
            hideLabel={true}
        />
    )
}

export default CustomCommentSection

