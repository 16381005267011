export const powerBiPages = [
	{
		"selectedRoles": [],
		"rank": 116,
		"hide": false,
		"isActive": true,
		"isCustom": false,
		"conditions": [],
		"title": "Contract Analytics",
		"name": "Contract Analytics",
		"url": "contractanalytics",
		"description": "Dashboard",
		"type": "Contract Analytics",
		powerBi: {
			"enable": true,
			"embedUrl": "https://app.powerbi.com/reportEmbed?reportId=0bfa4092-6fa3-4534-aa2b-fe69045fb194&autoAuth=true&ctid=862f8f1e-a4b0-4d68-b123-57e2de3da430&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLWNlbnRyYWwtYS1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9"
		},
		"chart": [
			{
				"chartData": {
					"name": "Contract Analytics",
                    "layout": {
                    "w": 12,
                    "h": 22,
                    "x": 0,
                    "y": 0,
                    "moved": false,
                    "static": false,
                    "i": "0"
                },},
				"_id": "61c3076ee72c9dccf71a9929",
				"chartUniqueId": "3dgds5435s",
				"chartType": "powerbi",
			}
		],
		"default": true,
		"createdBy": "62384aef7349460047cff60a",
		"appId": "62b318d976c64c72f7acdd9d",
		"__v": 0
	}
]