import { ActionTypes } from 'constants/index';

const initialState = {
  transactionList: [],
  loading: false,
  error: '',
  addTransaction:{
      loading:false,
      error:'',
      list:{}
  }
};

const transaction = function (state = initialState, action) {
    switch ( action.type )
    {
        case ActionTypes.GET_TRANSACTION_DETAILS_LOADING: {
            return {
                ...state,
                loading: true,
                error: ''
            };
        }
        case ActionTypes.GET_TRANSACTION_DETAILS_SUCCESS:
        {
            return {
                ...state,
                loading: false,
                transactionList:action.payload
            };
        }
        case ActionTypes.GET_TRANSACTION_DETAILS_ERROR:
        {
            return {
                ...state,
                error  : action.error,
                loading: false,
            };
        }
        case ActionTypes.ADD_TRANSACTION_LOADING: {
            return {
                ...state,
                addTransaction:{
                    loading: true,
                    error: '',
                    message:''
                }
            };
        }
        case ActionTypes.ADD_TRANSACTION_SUCCESS:
        {
            return {
                ...state,
                addTransaction:{
                    loading: false,
                    error: '',
                    message:action.payload.data.message
                },
                // transactionList:action.payload
                
            };
        }
        case ActionTypes.ADD_TRANSACTION_ERROR:
        {
            return {
                ...state,
                addTransaction:{
                    loading: false,
                    error: action.error,
                }
            };
        }
        default:
        {
            return state
        }
    }
};

export default transaction;
