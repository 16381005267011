import { Button, Grid, IconButton, makeStyles, Tooltip, Typography, TextField  } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { ChipSelect } from 'dsilo-ui-components';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clearSuccessMsg, getDataByUrl, getDataModels, saveDataByUrl, updateDataByUrl } from '../../store/actions';
import { Loader } from '../loader';
import MultiInput from '../ModelAliasNameSupporter/multiInput';

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: "0 20px 20px 0",
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
    },
    container: {
        padding: 5,
    },
    chipSelect: {
        minWidth: 250,
    },
    divider: {
        backgroundColor: '#cecece',
        height: 2,
        margin: 15,
    },
    TextField:{
        "& .MuiOutlinedInput-input": {
            height: "5px"
        },
    },
}));

const initialData = [{
    title: "",
    values: [""]
}]

const AliasNameList = (props) => {

    const [headerConfig, setHeaderConfig] = useState(initialData);
    // const [monthConfig, setMonthConfig] = useState(initialData);
    const [selectedDataModelId, setDataModel] = useState('')
    const [datapointName, setDatapointName] = useState('')
    const classes = useStyles();
    useEffect(() => {
        if (props.match.params.id) {
            let payload = {
                url:  '/datapoints/' + props.match.params.id,
                appId: props.match.params.appid,
                dataUniqueId: props.data.chartUniqueId + '_getDataPointById'
            }
            props.getDataByUrl(payload)
        } else {
            // setMonthConfig(initialData)
            setHeaderConfig(initialData)
            setDataModel('')
        }
        props.getDataModels(props.match.params.appid);
    }, [props.match.params])

    let reducerData = props.formData?.apiData ? props.formData?.apiData[props.data.chartUniqueId + "_getDataPointById"] : []
    const { apiData } = props.formData

    useEffect(() => {
        if (apiData && apiData[props.data.chartUniqueId + "_getDataPointById"]) {
            let response = apiData[props.data.chartUniqueId + "_getDataPointById"]
            let _datapoints = response?.datapoints;
            console.log('_datapoints', _datapoints)
            if (_datapoints) {
                let _headerConfig = []
                Object.keys(_datapoints).forEach(key => {
                    _headerConfig.push({ title: key, values: _datapoints[key] })
                })
                setHeaderConfig(_headerConfig)
            }
            // let months = response?.months;
            // if (months) {
            //     let _monthConfig = []
            //     Object.keys(months).forEach(key => {
            //         _monthConfig.push({ title: key, values: months[key] })
            //     })
            //     setMonthConfig(_monthConfig)
            // }
            setDatapointName(response.name)
        }
    }, [reducerData])

    useEffect(()=>{
        if (props.dataModels?.length && apiData && apiData[props.data.chartUniqueId + "_getDataPointById"]) {
            let datamodel = props.dataModels.find(dataModel => dataModel._id === apiData[props.data.chartUniqueId + "_getDataPointById"].dataModelId)
            if (datamodel){
                setDataModel({ label: datamodel.name,value:datamodel._id})
            }
        }

    }, [reducerData, props.dataModels])

    useEffect(() => {
        if (props.formData?.successMsg) {
          toast.success(props.formData.successMsg.message || 'Updated successfully')
          props.clearSuccessMsg()
          let payload = {
            url:  '/datapoints/' + props.match.params.id,
            appId: props.match.params.appid,
            dataUniqueId: props.data.chartUniqueId + '_getDataPointById'
        }
        props.getDataByUrl(payload)
        }
    }, [props.formData?.successMsg])


    const onKeyChange = (key, index, type) => {
        if (type === 'header') {
            let _headerConfig = JSON.parse(JSON.stringify(headerConfig))
            let obj = _headerConfig[index];
            obj.title = key;
            _headerConfig[index] = obj;
            setHeaderConfig([..._headerConfig]);
        } else {
            // let _monthConfig = JSON.parse(JSON.stringify(monthConfig))
            // let obj = _monthConfig[index];
            // obj.title = key;
            // _monthConfig[index] = obj;
            // setMonthConfig([..._monthConfig]);
        }
    }


    const onChangeValues = (values, index, type) => {
        if (type === 'header') {
            let _headerConfig = JSON.parse(JSON.stringify(headerConfig))
            let obj = _headerConfig[index];
            obj.values = values;
            _headerConfig[index] = obj;
            setHeaderConfig([..._headerConfig]);
        } else {
            // let _monthConfig = JSON.parse(JSON.stringify(monthConfig))
            // let obj = _monthConfig[index];
            // obj.values = values;
            // _monthConfig[index] = obj;
            // setMonthConfig([..._monthConfig]);
        }
    }

    const addNewRow = (type) => {
        if (type === 'header') {
            let _data = [...headerConfig];
            _data.push(initialData[0])
            setHeaderConfig(_data)
        } else {
            // let _data = [...monthConfig];
            // _data.push(initialData[0])
            // setMonthConfig(_data)
        }
    }

    const onSave = () => {
        if (selectedDataModelId?.value && datapointName) {
            let _headerConfigPayload = {};
            [...headerConfig].forEach(ii => {
                _headerConfigPayload[ii.title] = ii.values.filter(i => i)
            })
            // let _monthConfigPayload = {};
            // [...monthConfig].forEach(ii => {
            //     _monthConfigPayload[ii.title] = ii.values.filter(i => i)
            // })
            let data = {
                dataModelId: selectedDataModelId.value,
                datapoints: _headerConfigPayload,
                // months: _monthConfigPayload,
            }
            let payload = {
                url:  '/datapoints',
                appId: props.match.params.appid,
                data,
                dataUniqueId: props.data.chartUniqueId + '_saveDataPoints'
            }
            if(props.match.params.id){
                payload['dataUniqueId'] = props.data.chartUniqueId + '_updateDataPoints'
                payload['data']['_id'] = props.match.params.id,
                    payload['data']['name'] = datapointName
                console.log('update', payload)
                props.updateDataByUrl(payload)
            } else {
                console.log('save', payload)
                payload['data']['name'] = datapointName
                props.saveDataByUrl(payload)

            }

        } else {
            if (!selectedDataModelId?.value){
                toast.info('Please select Datamodel')
            } else if (!datapointName) {
                toast.info('Please enter Datapoint name ')
            }
        }

    }

    const handleDatapointName = (e) => {
        setDatapointName(e.target.value)
    }

    const deleteRowDatapoint = (i)=>{
        let data = JSON.parse(JSON.stringify(headerConfig))
        data.splice(i, 1);
        setHeaderConfig(data)
    }

    const renderSaveButton = () => {
        return <div className={classes.buttonContainer} >
            <Button variant="contained"
                color="primary"
                onClick={onSave}>
                {props.match.params.id?'Update':'Save'}
            </Button>
        </div>
    }

    if (props.formData && props.formData[props.data.chartUniqueId + '_modalAlias_loading']) {
        return <Loader />
    }

    return <div key={props.match.params.id?'new':'update'}>
        <div className={classes.header}>
            <div className={classes.header}>
                <Typography variant="h6" gutterBottom component="div" style={{ marginRight: 15, }}>Data Model</Typography>
                <ChipSelect
                    className={classes.chipSelect}
                    onChange={(e) => setDataModel(e)}
                    value={selectedDataModelId}
                    isSearchable={true}
                    textFieldProps={{
                        InputLabelProps: {
                            shrink: true,
                        },
                        variant: 'outlined',
                    }}
                    variant="fixed"
                    options={props.dataModels.map(dataModel => ({ label: dataModel.name, value: dataModel._id }))} />
                <Typography variant="h6" gutterBottom component="div" style={{ marginLeft: 15, marginRight: 15, }}>{'Datapoint Name'}</Typography>
                <TextField id="outlined-basic" variant="outlined" className={classes.TextField} style={{ marginTop:1 }} onChange={(e) => handleDatapointName(e)} value={datapointName} />
            </div>
            {headerConfig.length !== 0 ? renderSaveButton() : null}

        </div>

        <div className={classes.container}>
            <Grid container>
                <Grid item xs={3}>
                    <Typography variant="h6" gutterBottom component="div" style={{ paddingLeft: 15 }}>Datapoint</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" gutterBottom component="div">Alternative name</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Tooltip title={'Add new row'} aria-label={'Add new row'}>
                        <IconButton onClick={() => addNewRow('header')} >
                            <AddCircleIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            {headerConfig.map((e, i) =>
                <MultiInput
                    key={i}
                    onKeyChange={(k, l) => onKeyChange(k, l, 'header')}
                    id={i}
                    placeholder='Enter Name'
                    values={e.values}
                    setValues={fields => onChangeValues(fields, i, 'header')}
                    required
                    classes={classes}
                    rowDelete={true}
                    title={e.title}
                    deleteRowDatapoint={deleteRowDatapoint.bind()}
                />)
            }
            {headerConfig.length === 0 ? <div style={{ textAlign: 'center' }}>No configuration found</div> : null}

        </div>
        {/* <div className={classes.divider} />
        <div className={classes.container}>
            <Grid container>
                <Grid item xs={3} style={{ paddingLeft: 15 }}>
                    <Typography variant="h6" gutterBottom component="div">Month</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" gutterBottom component="div">Alternative name</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Tooltip title={'Add new row'} aria-label={'Add new row'}>
                        <IconButton onClick={() => addNewRow()} >
                            <AddCircleIcon fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            {monthConfig.map((e, i) =>
                <MultiInput
                    key={i}
                    onKeyChange={onKeyChange}
                    id={i}
                    placeholder='Enter Name'
                    values={e.values}
                    setValues={fields => onChangeValues(fields, i,)}
                    required
                    classes={classes}
                    title={e.title}
                />)
            }
        </div>
        {monthConfig.length === 0 ? <div style={{ textAlign: 'center' }}>No configuration found</div> : null} */}
    </div>
}

const mapDispatchToProps = {
    getDataModels,
    saveDataByUrl,
    getDataByUrl,
    updateDataByUrl,
    clearSuccessMsg,
};

const mapStateToProps = state => {
    return {
        dataModels: state.dataModels?.data || [],
        user: state.user,
        formData: state.action,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(AliasNameList)));
