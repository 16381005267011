import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import CustomTable from '../../components/@table/Table';
import TableContainer from '@material-ui/core/TableContainer';
import { updateTableData,  setDatafromApiV2 } from 'packages/common';
import sharedJSON from 'packages/sharedJSON';
import { handleSelectedRow, requestSort, handleTableSelectAllClick } from 'modules/common';
import { tablejson } from './tableJson';
import Tooltip from '@material-ui/core/Tooltip';
import {PUBLIC_PATH} from 'constants/index'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

const ContractList = props => {
  const classes = useStyles();

  const [state, setState] = useState({
    ...sharedJSON,
  });

  useEffect(() => {
    setState({ ...updateTableData(state) });
  }, [state.searchText, state.filterList]);

  useEffect(() => {
    const rows = tablejson;
    let keysArr = ['_id'];
    console.log('props in lsit', props.contracts);
    setState({ ...setDatafromApiV2(props.contracts, keysArr, state, rows) });
    
  }, [props.contracts]);

  const [showTable, setShowTable] = useState(false);

  const handleChangePage = (event, page) => {
    setState({ ...state, page: page });
  };

  const handleChangeRowsPerPage = event => {
    setState({ ...state, rowsPerPage: event.target.value, page: 0 });
  };

  const onRowClick = (rowData, rowMeta) => {
    console.log('onRowClick');
  };

  const searchHandler = e => {
    setState({ ...state, searchText: e ? e.target.value : '' });
  };

  const handleInputChange = event => {
    console.log('value:' + event.target.value + ' checked: ' + event.target.checked);
    let obj = state.filterTableData;
    let list = obj.columns;
    list.map((item, index) => {
      if (item.name === event.target.value) list[index].checked = event.target.checked;
    });
    obj.columns = [...list];
    setState({ ...state, filterTableData: obj });
    setState({ ...updateTableData(state) });
  };

  const resetFilters = event => {
    setState({ ...state, filterList: [] });
  };

  const handleDropdownChange = (event, index, column) => {
    const value = event.target.value === 'All' ? [] : [event.target.value];
    // filterUpdate(index, value, column, 'dropdown');
    let list = state.filterList;
    list[index] = event.target.value;
    setState({ ...state, filterList: [...list] });
    setState({ ...updateTableData(state) });
  };

  const handleRequestSort = (event, property) => {
    let obj = requestSort(event, property, state);
    console.log('obj', obj);
    setState({ ...state, ...obj });
  };

  const handleSelectAllClick = event => {
    setState({
      ...state,
      selected: handleTableSelectAllClick(event, state.filterTableData.datasets),
    });
  };

  const tableCrudOperations = (item, type) => {
    if (type === 'edit') {
      props.history.push({
        pathname: `/${PUBLIC_PATH}${props.match.params.appid}/contract-annotation/${item._id}`,
      });
    }
    if(type==='view'){
      props.history.push({
        pathname: `/${PUBLIC_PATH}${props.match.params.appid}/basiccontractview/${item._id}`,
      })
    }
    if (type === 'delete') {
      props.onDeleteContract({ ids: [item._id], appid: props.match.params.appid });
    }
    if(type==='download'){
      props.downloadAnnotations(item._id)
    }
  };

  const handleCheck = (event, id) => {
    const { selected } = state;
    let newSelected = handleSelectedRow(event, id, selected);
    setState({ ...state, selected: newSelected });
    props.checkedContractChanged(newSelected);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        {state.tableData && (
          <TableContainer style={{ maxWidth: '92vw' }} component={Paper}>
            {state.tableData && (
              <CustomTable
                name={'ContractList'}
                onRowClick={onRowClick}
                {...state}
                searchHandler={searchHandler}
                isExport={false}
                isViewCoulmns={true}
                isSearch={true}
                isFilter={true}
                hasCheckbox={true}
                actions={{ edit: true, view: true, delete: true, copy: false,download:true }}
                isTablePagination={true}
                isTableHead={true}
                isTableSubHeader={false}
                handleInputChange={handleInputChange}
                resetFilters={resetFilters}
                handleDropdownChange={handleDropdownChange}
                handleRequestSort={handleRequestSort}
                handleSelectAllClick={handleSelectAllClick}
                tableCrudOperations={tableCrudOperations}
                handleCheck={handleCheck}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                headerstyle={{
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.87)',
                  backgroundColor: '#FFF',
                  textAlign: 'left',
                }}
                subHeaderstyle={{
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.87)',
                  backgroundColor: '#FFF',
                  textAlign: 'left',
                }}
                rowStyle={{
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.87)',
                  backgroundColor: '#FFF',
                  lineHeight: 1.5,
                  textAlign: 'left',
                }}
              />
            )}
          </TableContainer>
        )}
      </Paper>
    </div>
  );
};

export default ContractList;
