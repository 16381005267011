import { Button, Divider, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, makeStyles, Radio, RadioGroup, TextField } from "@material-ui/core";
import { ChipSelect, InputField } from "dsilo-ui-components";
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAppMngmtConfig, getDataModels, saveAppMngmtConfig } from "../../store/actions";
import { Loader } from '../loader';
import React, { useEffect, useState } from 'react';
import { FormService } from "../../modules/api/form";
import { Validations } from 'dsilo-ui-components';
import { toast } from "react-toastify";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles({
    header: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 10,
    },
    box: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
    },
    TextField: {
        width: '100%',
        height: 35,
    },
    chipSelect: {
        '& input': {
            height: 40,
        },
    },
    error: {
        marginLeft: 14,
        color: 'red',
    }
});

let errorObj = {
    email: null,
    type: null,
    dataModel: null,
}
const MailConfig = (props) => {

    const classes = useStyles()
    const [data, setData] = useState({
        email: null,
        type: null,
        dataModel: null,
    })
    const [error, setError] = useState(errorObj)
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        props.getDataModels(props.match.params.appid);
    }, [])

    const id = props.match.params.id

    useEffect(() => {
        if (id && id !== 'new') {
            const payload = {
                id: id,
                memberId: props.member?._id,
                "appId": props.appId,
                "orgId": props.user.orgId,
            }
            setLoading(true)
            FormService.getMailBox(payload).then((res) => {
                setData({
                    email: res.data.email,
                    type: res.data.type,
                    dataModel: {
                        value: res.data.dataModel.id,
                        label: res.data.dataModel.name,
                    },
                })
                setLoading(false)
            }).catch(e => {
                setLoading(false)
            })
        }
    }, [props.match.params.id])

    const getDataModels = () => {
        return (props.dataModels?.data?.map(i => ({ value: i._id, label: i.name })) || [])
    }

    const onSave = () => {
        let _error = {
            email: true,
            type: true,
            dataModel: true,
        }

        if (Validations.validateEmail(data.email)) {
            _error.email = null
        }
        if (data.type) {
            _error.type = null
        }
        if (data.dataModel) {
            _error.dataModel = null
        }
        if (!_error.email && !_error.type && !_error.dataModel) {
            if (id === 'new') {
                const payload = {
                    data: {
                        ...data,
                        dataModel: {
                            id: data.dataModel.value,
                            name: data.dataModel.label,
                        }
                    },
                    memberId: props.member?._id,
                    "appId": props.appId,
                    "orgId": props.user.orgId,
                }
                setLoading(true)
                FormService.addMailBox(payload).then((res) => {
                    onBack()
                    toast.success(res.data.message || 'Mailbox added successfully')
                    setLoading(false)
                }).catch(e => {
                    toast.error(e.data.message || 'Unable to add')
                    setLoading(false)
                })
            } else {
                const payload = {
                    data: {
                        ...data,
                        dataModel: {
                            id: data.dataModel.value,
                            name: data.dataModel.label,
                        },
                        _id: id
                    },
                    memberId: props.member?._id,
                    "appId": props.appId,
                    "orgId": props.user.orgId,
                }
                setLoading(true)
                FormService.updateMailBox(payload).then((res) => {
                    onBack()
                    toast.success(res.data.message || 'Successfully Updated')
                    setLoading(false)
                }).catch(e => {
                    toast.error(e.data.message || 'Unable to update')
                    setLoading(false)
                })
            }
        } else {
            setError(_error)
        }

    }

    const onChange = (type, value) => {
        if (error.email || error.type || error.dataModel) {
            setError(errorObj)
        }
        let _data = data;
        _data[type] = value
        setData({ ..._data })
    }

    const onBack = () => {
        props.history.push({ pathname: `/${props.match.params.appid}/page/mailBox` });
    }


    if (loading || props.dataModels.loading) {
        return <Loader />
    }

    return (<div>
        <div className={classes.header}>
            <IconButton onClick={onBack} title={'Back'}>
                <ArrowBackIosIcon />
            </IconButton>
            <h1>{'Mail Box'}</h1>
        </div>

        <Divider />
        <Grid container xs={6} spacing={3} className={classes.box}>
            <Grid item xs={3} sm={3} >
                <InputLabel htmlFor="my-input">Email</InputLabel>
            </Grid>
            <Grid item xs={9} sm={8}>
                <TextField
                    variant="outlined"
                    className={classes.TextField}
                    placeholder={'Enter Email'}
                    onChange={(e) => onChange('email', e.target.value)}
                    name='email'
                    value={data.email}
                    error={error.email ? true : false}
                    id="standard-error-helper-text"
                    helperText={error.email ? 'Email is not valid' : ''}
                />
            </Grid>

            <Grid item xs={3} sm={3}>
                <InputLabel htmlFor="my-input">Type</InputLabel>
            </Grid>
            <Grid item xs={9} sm={8}>
                <RadioGroup aria-label="status" name="status" value={data.type} onChange={(e) => onChange('type', e.target.value)}

                    style={{ display: 'block', marginTop: 10, }}>
                    <FormControlLabel value="incoming" control={<Radio />} label="Incoming" />
                    <FormControlLabel value="outgoing" control={<Radio />} label="Outgoing" />
                </RadioGroup>
                {error.type && <FormHelperText className={classes.error}>{'Please select a option'}</FormHelperText>}
            </Grid>

            <Grid item xs={3} sm={3}>
                <InputLabel htmlFor="my-input">DataModel</InputLabel>
            </Grid>
            <Grid item xs={9} sm={8}>
                <ChipSelect
                    onChange={(e) => onChange('dataModel', e)}
                    value={data.dataModel}
                    isSearchable={true}
                    name={''}
                    className={classes.chipSelect}
                    textFieldProps={{
                        InputLabelProps: {
                            shrink: true,
                        },
                        variant: 'outlined',
                    }}
                    variant="fixed"
                    error={error.email ? true : false}
                    helperText={error.email ? 'Email is not valid' : ''}
                    options={getDataModels()} />
                {error.dataModel && <FormHelperText className={classes.error}>{'Please select a Data Model'}</FormHelperText>}

            </Grid>
            <Grid item xs={11}>
                <Button style={{ float: 'right' }} variant="contained" color="primary" onClick={onSave}>{id !== 'new' ? "Update" : "Save"}</Button>
                {id !== 'new' && 
                    <Button style={{float:'right', marginRight:10}} variant="contained" color="secondary" onClick={onBack}>{'Cancel'}</Button>}
            </Grid>
        </Grid>
    </div >)
}


const mapDispatchToProps = {
    getAppMngmtConfig,
    saveAppMngmtConfig,
    getDataModels,
};

const mapStateToProps = state => {
    return {
        formData: state.form,
        member: state.appConfig.members,
        user: state.user,
        dataModels: state.dataModels
    };
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(withRouter(MailConfig)));
