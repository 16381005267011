import { Request } from "./clientHelper";

// TODO: Update base url to notification service
const API_BASE_URL = process.env.REACT_APP_NOTIFICATION_SERVICE_URL;

export class NotificationService {

	static getAllUnreadNotifications(appId) {
		let uri = `/wsnotifications`;
		return Request(API_BASE_URL + uri, {
			method: 'GET',
			headers: { appId }
		});
	}
	
	static getNotificationById(data) {
		let uri = `/wsnotifications/${data.processId}`;
		return Request(API_BASE_URL + uri, {
			method: 'GET',
			headers: { 
				appId: data.appId
			}
		});
	}

	static clearAllNotifications(data) {
		let uri = `/wsnotifications`;
		return Request(API_BASE_URL + uri, {
			method: 'DELETE',
			payload: {},
			headers: { 
				appId: data.appId 
			}
		});
	}

	static clearSingleNotification(data) {
		let uri = `/wsnotifications`;
		return Request(API_BASE_URL + uri, {
			method: 'DELETE',
			payload: data.payload,
			headers: { 
				appId: data.appId
			}
		});
	}
}