import { ElasticSearch } from 'dsilo-ui-components';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const ElasticSearchWrapper = () => {

    return (
        <div>
            <ElasticSearch />
        </div>
    );
}

const mapDispatchToProps = {
};

const mapStateToProps = state => {
    return {
    };
};


export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ElasticSearchWrapper)));
