import { ActionTypes, PUBLIC_PATH } from 'constants/index';
import jwtService from 'jwtService';
import { AuthService, OrgService, AppService } from 'modules/api/index';
import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';
import { history } from 'dsilo-ui-components';

async function fetchAsync(func, arg) {
	const response = await func(arg);
	return response;
}

function* fetchAppLogin(action) {
	try {
		let user = yield call(fetchAsync, jwtService.signInWithEmailAndPassword, action.payload);
		yield put({ type: ActionTypes.LOGIN_SUCCESS, payload: user.data });
		yield put({ type: ActionTypes.SET_USER_DATA, payload: user.data });
		history.push(`/apploading`);
	} catch (e) {
		yield put({ type: ActionTypes.LOGIN_ERROR, error: e });
	}
}

function* checkEmailAad(action) {
	try {
		let response = yield call(fetchAsync, jwtService.checkEmailForAad, action.payload);
		yield put({ type: ActionTypes.CHECK_EMAIL_AAD_SUCCESS, payload: response.data });
	} catch (e) {
		yield put({ type: ActionTypes.CHECK_EMAIL_AAD_ERROR, error: e });
	}
}

function* AppverifyEmail(action) {
	try {
		const emailResult = yield call(fetchAsync, jwtService.verifyEmail, action.payload);
		yield put({ type: ActionTypes.VERIFY_EMAIL_SUCCESS, payload: emailResult });
	} catch (e) {
		yield put({ type: ActionTypes.VERIFY_EMAIL_ERROR, error: e.message });
	}
}
function* resetPassword(action) {
	try {
		const result = yield call(fetchAsync, jwtService.resetPassword, action.payload.data);
		action.payload.logoutUser()
		yield put({ type: ActionTypes.RESET_PASSWORD_SUCCESS, payload: result });
		toast.info(result?.message || 'Password reset successfully')
		history.push(`/${PUBLIC_PATH}login`);
	} catch (e) {
		yield put({ type: ActionTypes.RESET_PASSWORD_ERROR, error: e.message });
	}
}
function* AppforgotPassword(action) {
	try {
		const result = yield call(fetchAsync, AuthService.forgotPassword, action.payload);
		yield put({ type: ActionTypes.FORGOT_PASSWORD_SUCCESS, payload: result });
	} catch (e) {
		yield put({ type: ActionTypes.FORGOT_PASSWORD_ERROR, error: e.message });
	}
}
function* verifyUser(action) {
	try {
		const result = yield call(fetchAsync, jwtService.verifyUser, action.payload);
		yield put({ type: ActionTypes.VERIFY_USER_SUCCESS, payload: result });
	} catch (e) {
		yield put({ type: ActionTypes.VERIFY_USER_ERROR, error: e.message });
	}
}
function* resendVerifyEmail(action) {
	try {
		const result = yield call(fetchAsync, AuthService.resendVerifyEmail, action.payload);
		yield put({ type: ActionTypes.RESEND_VERIFY_EMAIL_SUCCESS, payload: result });
	} catch (e) {
		yield put({ type: ActionTypes.RESEND_VERIFY_EMAIL_ERROR, error: e.message });
	}
}
function* updateProfile(action) {
	const { user, userName } = action.payload;
	try {
		const result = yield call(fetchAsync, AuthService.updateProfile, action.payload);
		yield put({ type: ActionTypes.UPDATE_PROFILE_SUCCESS, payload: result });
		const updatedUser = { ...user, userName: userName }
		yield put({ type: ActionTypes.SET_USER_DATA, payload: updatedUser });
	} catch (e) {
		yield put({ type: ActionTypes.UPDATE_PROFILE_ERROR, error: e.message });
	}
}

export function* watchLoginSaga() {
	yield takeEvery(ActionTypes.CHECK_EMAIL_AAD, checkEmailAad);
	yield takeEvery(ActionTypes.LOGIN_LOADING, fetchAppLogin);
	yield takeEvery(ActionTypes.RESET_PASSWORD_LOADING, resetPassword);
	yield takeEvery(ActionTypes.VERIFY_EMAIL_LOADING, AppverifyEmail);
	yield takeEvery(ActionTypes.FORGOT_PASSWORD_LOADING, AppforgotPassword);
	yield takeEvery(ActionTypes.VERIFY_USER_LOADING, verifyUser);
	yield takeEvery(ActionTypes.RESEND_VERIFY_EMAIL_LOADING, resendVerifyEmail);
	yield takeEvery(ActionTypes.UPDATE_PROFILE_LOADING, updateProfile);
}

export default watchLoginSaga;
