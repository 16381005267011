import { PublicClientApplication } from '@azure/msal-browser';

const baseUrl = window.location.origin;

export const getMsalInstance = msalADData => {
    const { authorityDomain, clientId, policyName, tenantName } = msalADData;
    const authority = `https://${authorityDomain}/${tenantName}/${policyName}`;
    const msalConfig = {
        auth: {
            clientId,
            authority, // `https://login.microsoftonline.com/${tenantId}`,
            knownAuthorities: [authorityDomain],
            redirectUri: `${baseUrl}/mfaaad`,
            navigateToLoginRequestUrl: false,
        },
        cache: {
            cacheLocation: 'localStorage', // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
    };
    const msalInstance = new PublicClientApplication(msalConfig);
    return msalInstance;
};

export const loginRedirect = msalADData => {
    const { loginHint } = msalADData;
    sessionStorage.removeItem('msal.interaction.status');
    const loginRequest = {
        // scopes: [`${clientId}/.default`],
        scopes: ['https://dsilo.ai/auth-api/Task.Read', 'https://dsilo.ai/auth-api/Task.Write'],
        loginHint
    };
    const msalInstance = getMsalInstance(msalADData);
    msalInstance.loginRedirect(loginRequest);
};

export const handleRedirect = async (msalADData) => {
    const msalInstance = getMsalInstance(msalADData);
    const result = await msalInstance.handleRedirectPromise();
    return result;
};

export const logoutRedirect = async (msalADData) => {
    const { authorityDomain, policyName, tenantName } = msalADData;
    const authority = `https://${authorityDomain}/${tenantName}/${policyName}`;
    const msalInstance = getMsalInstance(msalADData);
    const result = await msalInstance.logoutRedirect({
        authority, // `https://login.microsoftonline.com/${tenantId}`, 
        postLogoutRedirectUri: `${baseUrl}/login`
    });
    return result;
};
