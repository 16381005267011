import React from 'react'

import Box from '@material-ui/core/Box'

import StepLevelBlocks from '../StepLevelBlocks'

const StepWithBlocksComponentView = (props) => {
    const { enabledBlocks } = props

    const currentDocumentId = props?.match?.params?.id

    return (
        <Box style={{
            height: "100%",
        }}>
            <React.Fragment>
                <StepLevelBlocks {...props} currentDocumentId={currentDocumentId} blocks={enabledBlocks} />
            </React.Fragment>
        </Box>
    )
}

export default StepWithBlocksComponentView