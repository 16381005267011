import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { makeStyles, Box, } from '@material-ui/core'
import 'styles/styles.scss'

import Header from './Header'
import ViewTabs from './Tabs'

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	}
}))

const View = (props) => {
	const classes = useStyles();

	return (
		<div className="rules">
			<Box>
				<Header
					executeRule={props.executeRule}
					canBeSubmitted={props.canBeSubmitted}
					saveDetails={props.saveDetails}
				/>
				<div className={classes.root} >
					<ViewTabs {...props} />
				</div>
			</Box>
		</div>
	)
}

export default withRouter(View)