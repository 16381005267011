import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    heading: { textAlign: 'center !important', marginBottom: 20, paddingTop: 10 },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    }
});
