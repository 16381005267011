import React from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
    Box, Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Divider, Grid,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography, CircularProgress, Paper
} from '@material-ui/core';

import { Helpers } from "dsilo-ui-components";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

const SimpleDataTable = (props) => {
    const classes = useStyles();
    const { tableHeaders, data, block, isLoading, loader } = props;
    console.log("Props Simple Data Table ===>>> ", props);

    return (
        <div
            className='simpleTable'
            style={{
                height: "100%",
                marginTop: "20px"
            }}
        >
            <div
                style={{
                    marginBottom: "20px"
                }}
            >
                <h4>{block.title}</h4>
            </div>
            <div
                style={{
                    height: "100%", overFlow: "auto"
                }}
            >
                <TableContainer component={Paper} style={{
                    maxHeight: "100%", overFlow: "auto"
                }}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table" >
                        <TableHead>
                            <TableRow>
                                {tableHeaders && tableHeaders.length ? tableHeaders.map((each, idx) =>
                                    <StyledTableCell>{each.label}</StyledTableCell>
                                ) : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ? loader : data.map((row, indx) => (
                                <StyledTableRow key={row.name}>
                                    {
                                        tableHeaders?.map((header, index) => {
                                            // const colValue = Helpers.convertValueWithDataType(row[header.uiReferenceField || header.name || header.value], header) || '-'
                                            const colValue = Helpers.generateValueByDataElementType(row, header) || '-'
                                            // const colValue = row[header.uiReferenceField || header.name || header.value] || '-'
                                            return (
                                                <StyledTableCell component="th" scope="row" align={Number.isFinite(Number(colValue)) ? "right" : "left"} style={{ backgroundColor: header.value == "actualPaymentTerms" ? colValue == "Matched" ? "lightgreen" : "orangered" : "transparent" }} >
                                                    {colValue}
                                                </StyledTableCell>)
                                        })
                                    }
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}

export default SimpleDataTable;