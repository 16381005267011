import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/loader';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { history } from 'dsilo-ui-components';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import {
	getOrgDetails, verifyApp, emptyPrevPath
} from '../../store/actions';
import JoinOrg from '../Org/JoinOrg';

const useStyles = makeStyles(theme => ({
	main: {
		backgroundColor: '#ffffff',
		width: '100vw',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	msg: {
		textAlign: 'center',
		marginTop: 15,
	},
	heading: {
		fontSize: 20,
	},
	textContainer: {
		marginTop: 48,
		padding: 24,
		width: 400,
	},
	link: {
		textDecoration: 'underline',
		textDecorationColor: 'red',
		cursor: 'pointer'
	}
}))


const AppLoading = (props) => {

	// write redirection logic here.
	const classes = useStyles()
	const [joinOrgVisible, toggleJoinOrg] = useState(false)
	useEffect(() => {
		console.log("useEffect 1")
		props.getOrgDetails()

		// Fetch org details
		// const org = yield call(fetchAsync, OrgService.getOrgDetails, {});

	}, [])

	useEffect(() => {
		if (props.org?.getOrgDetails?.org) {
			let org = props.org?.getOrgDetails?.org
			if (org?.defaultLandingApplication && !props.appConfig?.verifyAppLoading) {
				props.verifyApp(org?.defaultLandingApplication)
			} else {
				props.history.push({ pathname: '/apps' });
			}
		} else if (props.org?.getOrgDetails?.error) {

			props.history.push({ pathname: '/apps' });
		}

	}, [props.org?.getOrgDetails])

	useEffect(() => {
		if (props.org?.getOrgDetails?.org) {
			let org = props.org?.getOrgDetails?.org
			if (org?.defaultLandingApplication) {
				console.log("secondary apps ====", props.appConfig?.verifiedApp?.dapp);
				if (props.appConfig?.verifiedApp?.dapp) {
					let dapp = props.appConfig?.verifiedApp?.dapp
					if (typeof (dapp.appViewURL) !== 'undefined') {
						window.open(`${dapp.appViewURL}`, "_self")
					} else {
						if (props.navigation?.prevPath) {
							history.push({
								pathname: props.navigation.prevPath,
								search: props.navigation.prevSearch
							});
							props.emptyPrevPath();
						} else {
							history.push(`/${dapp._id}`);
						}
					}
				}
				else {
					if (props.appConfig?.verifyApp) {

						// check if current page is not /apps then redirect
						props.history.push({ pathname: '/apps' });
					}
				}
			} else {
				// check if current page is not /apps then redirect

				props.history.push({ pathname: '/apps' });

			}
		}
	}, [props.appConfig?.verifyApp])


	return (<div className={classes.main}>
		<div className={classes.textContainer}>
			<p className={classnames(classes.msg, classes.heading)}>
				{"Authentication completed"}
			</p>
			<p className={classes.msg}>
				{"You will be redirected to the application shortly. If the page is not redirecting automatically, please click on the below link."}
			</p>
			<div className={classes.msg}>
				<a className={classes.link} onClick={() =>
					props.history.push({ pathname: '/apps' })
				}>
					{"Application Link"}
				</a>
			</div>
			<Loader />
		</div>
	</div>)
}

const mapStateToProps = (state) => {
	return {
		apps: state.app,
		appConfig: state.appConfig,
		user: state.user,
		org: state.org,
		login: state.login,
		navigation: state.navigation,
	}
}

const mapDispatchToProps = {
	getOrgDetails,
	verifyApp,
	emptyPrevPath,
}

export default hot(connect(mapStateToProps, mapDispatchToProps)(withRouter(AppLoading)));