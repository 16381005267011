import { Request } from "./clientHelper";

const API_BASE_URL = process.env.REACT_APP_PAGE_SERVICE_URL;

export class pageService {
    static getPageConfig(pageId, appId) {
        const uri = API_BASE_URL + "/pages/" + pageId;

        return Request(uri, {
            method: 'GET'
        });
    }

    static getPages({ appId, orgId }) {
        const uri = API_BASE_URL + "/pages/";
        // const uri = API_BASE_URL + "/pages/list";
        return Request(uri, {
            method: 'GET',
            headers: {
                appId,
                orgId
            }
        });
    }

    static getPageByUrl({ appId, orgId, url }) {
        const uri = API_BASE_URL + "/pages/url/" + url;
        return Request(uri, {
            method: 'GET',
            headers: {
                appId,
                orgId
            }
        });
    }

    static savePage(data) {
        const uri = API_BASE_URL + "/pages";

        return Request(uri, {
            method: 'POST',
            payload: data,
            headers: {
                appId: data.appId
            }
        });
    }
}
