import { request } from '../client';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_CONTRACT_SERVICE;

export class ExtractionResultServices {
  static uploadPdf(file) {
    // const { files } = data;
    const uri = 'https://devbuilder-aw-1.dsilo.io/rjson/';
    console.log('=====files', file);
    return request(uri, {
      method: 'POST',
      payload: file,
      headers: {
        'Content-Type': 'multipart/form-data',
        // appId: data.appId,
      },
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }
}





