import { ServiceBusClient, delay } from "@azure/service-bus";
import React, { useEffect, useState, createContext } from 'react';
import clsx from 'clsx';
// import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import './style.scss';

// import BarChartIcon from '../../images/bar-chart.svg';
// import TrainingModel from '../../images/menu-icon4.svg';
// import Profile from '../../images/menu-icon-9-active.svg';
// import Contract from '../../images/menu-icon-contract-active.svg';
// import ContractInsights from '../../images/menu-icon-contract-insights-active1.svg';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import {
	getMemberById, hideMessage, loadAppconfig, loadApps, loadPages, logoutUser, serviceBusAction,
	setPrevPath, getAppMngmtConfig, updateBreadCrumb, getPageByUrl
} from '../../store/actions';
import { Box, Badge, CircularProgress, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { ConfirmationHandler, history, Sidebar } from 'dsilo-ui-components';
import AssistantIcon from '@material-ui/icons/Assistant';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
// import PersonIcon from '@material-ui/icons/Person';
// import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
// import GridOnIcon from '@material-ui/icons/GridOn';
// import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
// import PagesIcon from '@material-ui/icons/Pages';
// import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
// import TuneIcon from '@material-ui/icons/Tune';
// import MemoryIcon from '@material-ui/icons/Memory';
import { Loader } from 'components/loader';
import { PUBLIC_PATH } from '../../constants';
import LayoutBox from './LayoutBox';
import { generateDynamicMenu, useStyles, intermediateMenuItems } from './layoutHelper';
import NoAccess from '../../Pages/NoAccess';
import NotificationsIcon from '@material-ui/icons/Notifications';
// import SettingsIcon from '@material-ui/icons/Settings';
// import Popover from '@material-ui/core/Popover';
// import Typography from '@material-ui/core/Typography';
import logo from '../../images/logo.png';
import NotificationView from '../NotificationView';
import NotificationSocket from "../NotificationView/NotificationSocket";
import { powerBiPages } from './PowerBiPages';
import { selfServicePage, SystemJSONs } from './StaticPagesConfig';

// const BUILDER_URL = process.env.REACT_APP_BUILDER_URL;
const BUILDER_URL = 'https://builder.platform-dev.dsilo.io';

const Layout = props => {

	let receiver, sbClient
	const [MenuItems, setMenuItems] = useState([])
	const classes = useStyles();
	const [open, setOpen] = React.useState(true);
	const [hideSidebarMenu, setHideSidebarMenu] = useState(false);
	const [page, setPage] = React.useState(null);
	const [page404, setPage404] = React.useState(false);
	const [queryString, setQueryString] = React.useState();
	const [openProfileMenu, setOpenProfileMenu] = useState(false);
	const anchorRef = React.useRef(null);
	// const [anchorEl, setAnchorEl] = React.useState(null);
	const [openNotificationTray, setOpenNotificationTray] = React.useState(false);
	const appId = props.match.params.appid;

	const [defaultMenuOpen, setDefaultMenuOpen] = useState(true);
	const [pageFetched, setPageFetched] = useState(false)

	useEffect(() => {
		let isAuth = handleToken()
		let appId = getAppId()
		if (appId) {
			props.getMemberById(appId)
			props.loadAppconfig(appId)
			getAppConfig(appId)

			return async () => {
				receiver && await receiver.close();
				sbClient && await sbClient.close();
			}
		} else {
			if (isAuth) {
				history.push(`/apps`);
			}
		}
	}, [])

	useEffect(() => {
		if (props.appDetails?.app?.dapp) {
			//Recieve message from Azure bus
			receive(props.appDetails?.app?.dapp)
		}
	}, [props.appDetails?.app?.dapp])

	const getAppConfig = (appId) => {
		const payload = {
			"appId": appId,
			"dataUniqueId": 'appConfig' // don't rename dataUniqueId using it in multiple places //  TODO: move all the hardcode to a file
		}
		props.getAppMngmtConfig(payload)
	}

	useEffect(() => {
		if (props.appDetails?.app?.dapp) {
			let open = props.appDetails?.app?.dapp.defaultMenuOpen === false ? false : true
			console.log("open =======", open);
			setDefaultMenuOpen(open)
		}
	}, [props.appDetails?.app?.dapp])

	useEffect(() => {
		setPage(null)
		let prevPath;
		let prevSearch;
		prevPath = props.location.pathname
		prevSearch = props.location.search
		if (prevPath !== '/') {
			props.setPrevPath({ prevPath, prevSearch });
		}
		let { pages } = props
		if (pages && pages.length > 0) {
			setPageSupport(pages)
		} else {
			if (props.pageLoading === false && !pageFetched) {
				getPages()
				setPageFetched(true)
			}
		}
	}, [props.location])


	useEffect(() => {
		let { members } = props
		if (members && !pageFetched) {
			getPages()
			setPageFetched(true)
		} else {
			let { pages } = props
			if (pages && pages.length > 0) {
				setPageSupport(pages)
				// let page = getDefaultPage(pages)
				// if (!page) {
				// 	page = pages[0]
				// }
				// if (page?.url) {
				// 	getCurentPageByUrl(page.url)
				// }
			}
		}
	}, [props.members])

	useEffect(() => {
		let { pages } = props
		if (pages && pages.length > 0) {
			setPageSupport(pages)
			if (props.match?.params?.page) {
				getCurentPageByUrl(props.match.params.page)
			} else {
				let page = getDefaultPage(pages)
				if (!page) {
					let nonLabelPages = pages.filter(p =>!p.isLabel)
					page = nonLabelPages ? nonLabelPages[0] : pages[0]
				}
				console.log("page ===== xxxx", page);
				getCurentPageByUrl(page.url)
			}
		}
	}, [props.pages, props.appConfig])

	const handleDrawerOpen = () => {
		if (!hideSidebarMenu) {
			setOpen(!open);
			setTimeout(() => {
				window.dispatchEvent(new Event('resize'))
			}, 500)
		}
	};

	const handleToggle = () => {
		setOpenProfileMenu(prevOpen => !prevOpen);
	};

	const handleCloseProfileMenu = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpenProfileMenu(false);
	};

	const handleNotificationTray = () => {
		setOpenNotificationTray((prev) => !prev);
	}

	const openE = openNotificationTray;
	const id = openE ? 'simple-popover' : undefined;

	const getAppId = () => {
		let path = props.location.pathname.split('/');
		const _user = getUser();
		let appId = process.env.REACT_APP_PUBLIC_PATH ? path[2] : path[1];
		appId = props.match.params.appid || appId || _user?.app?._id
		return appId
	}


	async function receive(app) {
		if (app.connectionString) {
			const queueName = "outputhandler"
			sbClient = new ServiceBusClient(app.connectionString || "");
			receiver = sbClient.createReceiver(queueName, 'all-apps-subscription');
			const myMessageHandler = async (messageReceived) => {
				props.serviceBusAction(messageReceived.body)
				console.log('azureBusMsg', messageReceived.body.message)
				// toast.info(`${messageReceived.body.message}`)
			};

			const myErrorHandler = async (error) => {
				console.log('myErrorHandler', error);
			};

			receiver.subscribe({
				processMessage: myMessageHandler,
				processError: myErrorHandler
			});
		}
	}

	const getToken = () => localStorage.getItem('jwt_access_token')

	const getUser = () => JSON.parse(localStorage.getItem('jwtuserData'))

	const getOrgId = () => getUser()?.orgId

	const handleToken = () => {
		let token = getToken()
		if (!token) {
			history.push(`/${PUBLIC_PATH}login`);
			return false
		}
		return true
	}

	const hasUserMngmtAccess = () => {
		let systemConfig = props.appConfig?.system
		if (!systemConfig?.enable) {
			return false
		}
		return props.appDetails?.members?.roles.some(i => (i.name === 'Super Admin' || i.name === 'Power Admin'))
	}

	const getSystemPages = () => {
		const roles = props.appDetails?.members?.roles.map((el) => el.name);
		const systemConfig = props.appConfig?.system;
		let systemPages = [];
		if (systemConfig?.enable) {
			let hasAccess = true;
			if (systemConfig?.roles?.length) {
				hasAccess = systemConfig.roles.find((role) => roles?.includes(role.name) ? true : false)
			}
			if (hasAccess) {
				systemPages = SystemJSONs.filter(page => page.pageType ? (systemConfig[page?.pageType] && systemConfig[page?.pageType]?.enable) && systemConfig[page?.pageType]?.roles?.find((role) => roles?.includes(role?.name)) ? true : false : true)
			}
		}
		return systemPages;
	}

	const setPageSupport = (pages) => {
		// if (hasUserMngmtAccess()) {
		pages = [...pages,
		...getSystemPages(),
			//  ...selfServicePage //FIXME: commented for demo purpose
		]
		// }

		if (props.appDetails?.app?.dapp?.powerBi?.enable) {
			pages = [...pages, ...powerBiPages];
		}

		let appId = getAppId()
		const initUrl = `${process.env.REACT_APP_PUBLIC_PATH ? process.env.REACT_APP_PUBLIC_PATH + '/' : ''}${appId}`

		pages = updatePagesBasedOnRoles(pages)

		let menuItems = generateDynamicMenu(initUrl, pages)

		//FIXME: Hack added for demo
		menuItems = intermediateMenuItems(menuItems, props)

		setMenuItems(menuItems);
		let queryString = getQueryFromParams()
		setQueryString(queryString)

		console.log("on click of menu item this function called ====")
		let page = getCurrentPage(pages)
		setPage(page)

	}
	/**
	 * This function will validate current user roles against roles configured to the page
	 * @param {*} pages 
	 * @returns pages current user has accessablitity
	 */
	const updatePagesBasedOnRoles = (pages) => {
		let member = props.members
		/**
		 * Rotate over all pages 
		 * Check wether it has roles or not
		 * if no roles, page will be visible for all users
		 * else compare selectedRoles with current member selectedRoles
		 */
		let currentRoles = {};
		member?.roles?.forEach(mRole => {
			currentRoles[mRole.name] = true;
		})

		pages = pages.filter((page, i) => {
			// page.selectedRoles contains roles from current page
			if (page.selectedRoles?.length) {
				// To match roles from the current member and selectedRoles from page
				let macthedMembers = page.selectedRoles?.filter((role) => currentRoles[role.name])
				// member roles mataching between page selectedRoles
				return macthedMembers?.length
			} else {
				// When no roles configured to the page
				return true
			}
		})
		return pages
	}

	const getPages = () => {
		let data = {
			appId: getAppId(),
			orgId: getOrgId(),
		}
		if (data.appId)
			props.loadPages(data)
	}

	const getCurentPageByUrl = (url) => {
		let data = {
			appId: getAppId(),
			orgId: getOrgId(),
			url: url
		}
		if (data.appId) {
			props.getPageByUrl(data)
		}
	}

	// useEffect(() => {
	// 	if (props.match.params.page) {
	// 		getCurentPageByUrl(props.match.params.page)
	// 	}
	// }, [props.match.params.page])

	// useEffect(() => {
	// 	if (!props?.pageByUrlLoading && !props.pageByUrl) {
	// 		setPage404(true)
	// 	} else if (props?.pageByUrl?.url) {
	// 		setPage(props.pageByUrl)
	// 	}
	// }, [props.pageByUrl])

	const getCurrentPageId = () => props.match.params.page

	const getCurrentPageByRoute = (pages) => {
		return (pages && pages.length > 0) && pages.find(p => p.url === getCurrentPageId())
	}

	const getCurrentPage = (pages) => {
		let page
		if (getCurrentPageId()) {
			page = (pages && pages.length > 0) && getCurrentPageByRoute(pages)
			if (!page)
				setPage404(true)
		} else {
			page = getDefaultPage(pages)
			if (!page) {
				let nonLabelPages = pages.filter(p =>!p.isLabel)
				page = nonLabelPages ? nonLabelPages[0] : pages[0]
			}
		}
		return page
	}

	const getDefaultPage = (pages) => (pages && pages.length > 0) && pages.find(p => p.default === true)

	const handleProfile = () => {
		let appId = getAppId()
		if (appId) {
			history.push({ pathname: `/${PUBLIC_PATH}${appId}/profile` });
			setOpenProfileMenu(false);
		}
	};

	const getRole = () => {
		const { user } = props;
		if (user && user.roles && user.roles.length > 0) {
			return user.roles[0];
		}
		return '';
	};

	// This method partially closes the sidebar, i.e., which displays only icons.
	const handleSideNavBarClose = (sideBarClose) => {
		setOpen(sideBarClose)
	}

	// This method completely hides the sidebar. - For now handling it by setting the visibility: hidden
	const hideCompleteSideBarMenu = (toggleSidebarMenu) => {
		setHideSidebarMenu(toggleSidebarMenu)
	}

	// This method is for navigating to the current app onclick of App Title
	const navigateToAppDefaultPage = () => {
		props.history.push(`/${PUBLIC_PATH}${props.match.params.appid}`)
	}

	const ProfileMenu = () => {
		return (
			<Popper
				open={openProfileMenu}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleCloseProfileMenu}>
								<MenuList>
									<MenuItem onClick={handleProfile}>Profile</MenuItem>
									<MenuItem onClick={props.logoutUser}>Logout</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		);
	};

	const NotificationViews = () => {
		return (
			<NotificationView
				id={id}
				openN={openNotificationTray}
				handleNotificationTray={handleNotificationTray}
				appId={appId}
			/>
		);
	};

	const getQueryFromParams = () => {
		let path = props.location.pathname.split('/')
		let key = path[4] ? path[4] : undefined;
		let value = path[5] ? path[5] : undefined;
		return key && value ? "key=" + key + "&" + "value=" + value : '';
	}

	//TODO: static styles
	let pathArr = props.history.location.pathname.split('/')
	let pathVal = pathArr[pathArr.length - 1]

	if (props.pageLoading || props.memberLoading) {
		return <div className={classes.loaderContainer}><Loader /></div>
	}

	console.log("props.appConfig x =====", props.appConfig)
	console.log("page ====", page)

	return (
		<NotificationSocket appId={appId}>
			<div className="layout">
				<div className={classes.root}>
					<AppBar
						position="fixed"
						className={clsx(classes.appBar, {
							[classes.appBarShift]: open,
						})}
					>
						<Toolbar>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								onClick={handleDrawerOpen}
								edge="start"
								className={clsx(classes.menuButton)}
							>
								<MenuIcon />
							</IconButton>

							<Link to="/apps">
								<img src={logo} alt="" />
							</Link>

							<div className="header" onClick={navigateToAppDefaultPage} style={{ cursor: "pointer" }}>
								{props.appDetails?.app?.dapp?.subTitle || 'Contract Insights Extraction Platform'}
								{props.appDetails?.app?.dapp?.trademark && <span className='trademark'></span>}
							</div>
						</Toolbar>
						<div className="header-right">
							<ul>
								{/* <li className="search-wrapper">
									<InputBase
									className="search-input"
									placeholder="Search Data, Apps, Model, Source"
									/>
									<img src={Search} className={classes.icon} />
								</li> */}
								{/*<li>*/}
								{/*  <span className="notification-icon">*/}
								{/*    <img src={notification} alt=""/>*/}
								{/*  </span>*/}
								{/*</li>*/}
								{props.appConfig?.aiAssistant && <li title={'AI Assistant'} >
									<AssistantIcon
										className={classes.large}
										onClick={() => {
											props.history.push({ pathname: `/${PUBLIC_PATH}${props.match.params.appid}/aiAssistant` });
										}}
									/>
								</li>}
								{props.appConfig?.supportTickets && <li title={'support ticket'} >
									<ContactSupportIcon
										className={classes.large}
										onClick={() => {
											props.history.push({ pathname: `/${PUBLIC_PATH}${props.match.params.appid}/page/tickets` });
										}}
									/>
								</li>}
								{/* {
									props.members?.email &&
									(props.members?.email?.split('@')?.shift() === 'dsilosupport') &&
									props.members?.roles?.some(ele => ele.name === 'Super Admin') &&
									<li>
										<SettingsIcon className={classes.large} onClick={() => {
											window.open(`${BUILDER_URL}/dapps/${appid}`, '_blank')
										}} />
									</li>
								} */}
								<li>
									<Badge badgeContent={props.notifications.data?.length} color="secondary">
										<NotificationsIcon
											appId={props.match.params.appid}
											className={classes.large}
											onClick={handleNotificationTray}
										/>
									</Badge>
									{NotificationViews()}
								</li>
								<li onClick={handleToggle} ref={anchorRef}>
									<span className="user-profile">
										{props.user && props.user.photoURL ? (
											<Avatar alt="user photo" src={props.user.photoURL} className={classes.large} />
										) : (
											<AccountCircleIcon className={classes.large} />
										)}
									</span>
									{props.user && (
										<Box display="flex" alignItems="center" className="user-name">
											{props.user.userName} <i>{getRole()}</i>
										</Box>
									)}
								</li>
								<li title="logout" onClick={props.logoutUser}>
									<ExitToAppOutlinedIcon alt="logout" className={classes.large} />
								</li>
							</ul>
						</div>
						{ProfileMenu()}
					</AppBar>
					{(props.members) ?
						<>
							<Sidebar
								{...props}
								MenuItems={MenuItems}
								open={open}
								classes={classes}
								menuOpen={defaultMenuOpen}
								hideSideBarMenu={hideSidebarMenu}
							/>
							<main className={hideSidebarMenu ? classes.fullContentBgColor : classes.content}>
								<div className={classes.toolbar} />
								{props.children ? props.children :
									<LayoutBox
										className="classes.layout"

										page={page}
										navBarOpen={open}
										appId={getAppId()}
										pages={props.pages}
										queryString={queryString}
										loading={props.pageLoading}
										appDetails={props.appDetails}
										appConfiguration={props.appConfig}
										pageByUrlLoading={props.pageByUrlLoading}
										handleSideNavBarClose={handleSideNavBarClose}
										hideCompleteSideNavBar={hideCompleteSideBarMenu}
										hideTitle={hideSidebarMenu} // Hide Pgae title when no side bar menu
									/>
								}
							</main>
						</> : (props.noMember ? <NoAccess /> : <div className={classes.loaderContainer}><Loader />
						</div>)
					}

				</div>
				{
					props.pages.error &&
					<ConfirmationHandler open={props.pages.error.message} handleClose={props.hideMessage}
						alertMessageText={props.pages.error.message} />
				}
			</div>
		</NotificationSocket>
	);
};

const mapStateToProps = state => {
	return {
		user: state.user,
		org: state.org,
		members: state.appConfig.members,
		noMember: state.appConfig.noMember,
		memberSuccess: state.appConfig.memberSuccess,
		pages: state.pages.pages,
		pageByUrl: state.pages.pageByUrl,
		pageLoading: state.pages.loading,
		pageByUrlLoading: state.pages.pageByUrlLoading,
		appDetails: state.appConfig,
		login: state.login,
		app: state.app,
		appConfig: state.form.document?.appConfig,
		breadcrumb: state.breadcrumb.routes,
		notifications: state.notifications
	};
};

const mapDispatchToProps = {
	logoutUser,
	getMemberById,
	loadPages,
	hideMessage,
	loadAppconfig,
	serviceBusAction,
	setPrevPath,
	getAppMngmtConfig,
	updateBreadCrumb,
	getPageByUrl
};
export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout)));
