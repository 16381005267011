const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
import { request } from 'modules/client'

export class ContactDsilo {
	
	static submitContactDsilo(data) {
		const uri = API_BASE_URL + "/contact-dsilo"
		let { appId, orgId } = data
		delete data.appId
		delete data.orgId
		return request(uri, {
			method: 'POST',
			payload: data,
			headers: {
				appId,
				orgId
			}
		})
	}

}

export function apiBaseAddress() {
	return API_BASE_URL
}
