import { Login } from 'dsilo-ui-components';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { checkEmailForAad, emptyPrevPath, loadApps, resendVerifyEmail, submitLogin } from '../../store/actions';
import dsiloLogo from '../../images/logo.png';
import { loginRedirect } from './msalService';

const LoginComponent = props => {
  const secretKey = process.env.REACT_APP_CRYPT_SECRET_KEY || '';

  return (
    <div className="login-ciep" >
      <Login
        appLogo={dsiloLogo}
        handleLogin={(data) => { props.submitLogin(data) }}
        handleVerifyEmail={(data) => { props.resendVerifyEmail(data) }}
        checkEmailForAad={(data) => props?.checkEmailForAad(data)}
        loginRedirect={loginRedirect}
        hidePassword={true}
        login={props.login}
        subTitle={"ProcureGPT&trade; - AI Platform"}
        secretKey={secretKey}
      />
    </div>
  );
};

const mapDispatchToProps = {
  checkEmailForAad,
  submitLogin,
  resendVerifyEmail,
  loadApps,
  emptyPrevPath,
};

const mapStateToProps = state => {
  return {
    login: state.login,
    apps: state.app,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginComponent));
