import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { AppService } from 'modules/api';
import { ActionTypes,PUBLIC_PATH } from 'constants/index';
import jwtService from 'jwtService';
import {history} from 'dsilo-ui-components';
import { toast } from 'react-toastify'

async function fetchAsync(func, arg) {
	const response = await func(arg);
	return response;
}

function* fetchAppConfig(action) {
	try {
		const user = yield call(fetchAsync, jwtService.createUser, action.payload);
		yield put({ type: ActionTypes.REGISTER_SUCCESS });
		toast.success("You have successfully registered.");
		setTimeout(() => {
			history.push(`/${PUBLIC_PATH}login`);
		}, 1000);
	} catch (e) {
		yield put({ type: ActionTypes.REGISTER_ERROR, error: e });
	}
}

export function* watchLoginSaga() {
	yield takeEvery(ActionTypes.REGISTER_LOADING, fetchAppConfig);
}

export default watchLoginSaga;
