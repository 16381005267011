import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import RenderComponentBasedOnBlock from './RenderComponentBasedOnBlock'

import useStyles from './styles';

const StepLevelBlocks = (props) => {
    
    const { blocks, accordionSupport, setAccordionSupport } = props;

    const useStyles = makeStyles((theme) => ({

        expandedSummary: {
            '&.MuiAccordionSummary-root': {
                maxHeight: '40px',
                minHeight: '40px' 
            },
            '&.Mui-expanded': {    
                maxHeight: '40px',
                minHeight: '40px'
            },
        },
    }));

    const classes = useStyles();
    return (
        <React.Fragment>
            {
                blocks?.length > 0 ? blocks.map((block, index) => {
                    const accordian = accordionSupport[block.name]
                    const backgroundColor = block.accordion?.backgroundColor || "#4D9FEC";
                    return (
                        <React.Fragment>
                            {
                                block.title
                                    ? accordian?.enable
                                        ? <Accordion
                                            key={`block_${block.name}_${index}`}
                                            style={{ border: `1px solid ${backgroundColor}` }}
                                            className={classes.block}
                                            defaultExpanded={accordian.defaultExpanded || false}
                                        >
                                            <AccordionSummary
                                            className={classes.expandedSummary}
                                                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                                style={{
                                                    backgroundColor: accordian.backgroundColor || "#3456eb"
                                                }}
                                            >
                                                <Typography
                                                    style={{ color: "white" }}
                                                >
                                                    {block.title}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <RenderComponentBasedOnBlock
                                                    key={`${block.name}_${index}`}
                                                    {...props}
                                                    block={block}
                                                    
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                        : <div key={`block_${block.name}_${index}`} className={classes.block}>
                                            <div className={classes.blockHeader}>
                                                <Typography style={{ color: "white" }}>{block.title}</Typography>
                                            </div>
                                            <div className={classes.blockContent}>
                                                <RenderComponentBasedOnBlock
                                                    key={`${block.name}_${index}`}
                                                    {...props}
                                                    block={block}
                                                />
                                            </div>
                                        </div>
                                    : <div key={`block_${block.name}_${index}`} className={classes.block}>
                                        <RenderComponentBasedOnBlock
                                            key={`${block.name}_${index}`}
                                            {...props}
                                            block={block}
                                        />
                                    </div>
                            }
                        </React.Fragment>
                    )
                }) : <div>
                    <Typography>
                        No Blocks configured to display.
                    </Typography>
                </div>

            }
        </React.Fragment>
    )
}

export default StepLevelBlocks