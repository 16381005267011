import React from 'react'

import {
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Tooltip, CircularProgress
} from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import RenderRowData from './RenderRowData';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const SubDataModelTable = (props) => {
    const { classes, tableStyle, stickyHeader, stripedTable, headerStyle, enableActions, tableHeaders, tableRows, tableActions, isLoading, formData, chartUniqueId, selectedStep, block, loader, onTableActionClick } = props

    return (
        <TableContainer
            component={Paper}
            style={{
                maxHeight: "100%", overFlow: "auto",
                ...tableStyle
            }}
        >
            <Table
                size={'small'}
                aria-labelledby='tableTitle'
                aria-label='enhanced table'
                stickyHeader={stickyHeader}
            >
                <TableHead style={{backgroundColor: "#ccc"}}>
                    <TableRow className={classes.tableHeadCellPadding}>
                        {tableHeaders?.map((header, lIndex) => (
                            <TableCell
                                key={lIndex}
                                className={classes.tableHeaderCell}
                                title={header.label || header.description}
                                style={{ minWidth: "100px", ...headerStyle }}
                            >
                                {header.label || header.description}
                            </TableCell>
                        ))}
                        {enableActions && <TableCell
                            key={'actionsColumn'}
                            className={classes.tableHeaderCell}
                            title={'Actions'}
                            style={{ minWidth: "100px", ...headerStyle }}
                        >
                            {'Actions'}
                        </TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading ? loader : tableRows?.length > 0 ?
                        <React.Fragment>
                            {(
                                stripedTable
                                    ? tableRows.map((row, rIndex) => (
                                        <StyledTableRow key={rIndex} className={classes.tableHeadCellPadding}>
                                            <RenderRowData {...props} row={row} rIndex={rIndex} />
                                        </StyledTableRow>
                                    ))
                                    : tableRows.map((row, rIndex) => (
                                        <TableRow key={rIndex} className={classes.tableHeadCellPadding}>
                                            <RenderRowData {...props} row={row} rIndex={rIndex} />
                                        </TableRow>
                                    ))
                            )}
                        </React.Fragment>
                        : (
                            <TableRow>
                                
                                    <TableCell colSpan={tableHeaders?.length + 1}>
                                    <Typography align='center'>
                                    No Actions
                                    </Typography>
                                    </TableCell>
                                   
                                
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SubDataModelTable