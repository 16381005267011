import { Request } from "./clientHelper";

const API_BASE_URL = process.env.REACT_APP_DATA_SERVICE_URL;

export class ChatService {

	static createNewPrompt(data) {
		let uri = `/chat/ai`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data,
			headers: { appId: data.appId }
		});
	}

	static getChatHistory(data) {
		let uri = `/chat/history`;
		if (data.type && data.type !== 'my') {
			uri += '/all';
		}
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data.payload,
			headers: { appId: data.appId }
		});
	}

	static getChatHistoryByIdOld(data) {
		let uri = `/chat/quest-ans/${data.chatId}`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: {
				rowsPerPage: 'all'
			},
			headers: { appId: data.appId }
		});
	}

	static getChatHistoryById(data) {
		let uri = `/chat/quest-ans1/${data.chatId}`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			headers: { appId: data.appId },
			payload: data.payload
		});
	}

	static createNewWorkspace(data) {
		let uri = `/workspace`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data.payload,
			headers: { appId: data.appId }
		});
	}

	static updateWorkspace(data) {
		let uri = `/workspace/${data.workspaceId}`;
		return Request(API_BASE_URL + uri, {
			method: 'PUT',
			payload: data.payload,
			headers: { appId: data.appId }
		});
	}

	static getAllWorkspaces(data) {
		let uri = `/workspace`;
		if (data.type && data.type !== 'my') {
			uri += `/d/all`
		}
		return Request(API_BASE_URL + uri, {
			method: 'GET',
			headers: { appId: data.appId }
		});
	}

	static getWorkspaceById(data) {
		let uri = `/workspace/${data.workspaceId}`;
		return Request(API_BASE_URL + uri, {
			method: 'GET',
			headers: { appId: data.appId }
		});
	}

	static addNewChartToWorkspace(data) {
		let uri = `/workspace/chart`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data.payload,
			headers: { appId: data.appId }
		});
	}

	static deleteChartFromWorkspace(data) {
		let uri = `/workspace/chart`;
		return Request(API_BASE_URL + uri, {
			method: 'DELETE',
			payload: data.payload,
			headers: { appId: data.appId }
		});
	}

	static updateWorkspaceTitle(data) {
		let uri = `/workspace/${data.workspaceId}`;
		return Request(API_BASE_URL + uri, {
			method: 'PATCH',
			payload: data.payload,
			headers: { appId: data.appId }
		});
	}

	static deleteChatFromHistory(data) {
		let uri = `/chat/${data.chatId}`;
		return Request(API_BASE_URL + uri, {
			method: 'PUT',
			payload: data.payload,
			headers: { appId: data.appId }
		});
	}

	static updateChatTitle(data) {
		let uri = `/chat/${data.chatId}`;
		return Request(API_BASE_URL + uri, {
			method: 'PUT',
			payload: data.payload,
			headers: { appId: data.appId }
		});
	}

	static deleteQuestionFromChatHistory(data) {
		let uri = `/chat/${data.chatId}`;
		return Request(API_BASE_URL + uri, {
			method: 'PUT',
			payload: data.payload,
			headers: { appId: data.appId }
		});
	}

	static publishOrDraftWorkspace(data) {
		let uri = `/workspace/${data.publishType}/${data.workspaceId}`;
		return Request(API_BASE_URL + uri, {
			method: 'PUT',
			payload: data.payload,
			headers: { appId: data.appId }
		});
	}

	static deleteWorkspace(data) {
		let uri = `/workspace`;
		return Request(API_BASE_URL + uri, {
			method: 'DELETE',
			payload: data.payload,
			headers: { appId: data.appId }
		});
	}

	static getAllSaveForLaterChats(data) {
		let uri = `/chat/sfl/questions`;
		return Request(API_BASE_URL + uri, {
			method: 'GET',
			headers: { appId: data.appId }
		});
	}

	static getSingleSaveForLaterChat(data) {
		let uri = `/chat/${data.chatId}/sfl/questions`;
		return Request(API_BASE_URL + uri, {
			method: 'GET',
			headers: { appId: data.appId }
		});
	}
	static getSampleQuestions(data) {
		let uri = `/chat/initialquestions`;
		return Request(API_BASE_URL + uri, {
			method: 'GET',
			headers: { appId: data.appId }
		});
	}

	static getQuestionComments(data) {
		//need to pass question id to get the comments
		let uri = `/chat/comments/${data.questionId}`;
		return Request(API_BASE_URL + uri, {
			method: 'GET',
			headers: { appId: data.appId }
		});
	}

	static createComment(data) {
		//need to pass question id, new commenttext, chatId
		let uri = `/chat/comments`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			headers: { appId: data.appId },
			payload: data.payload
		});
	}

	static likeQuestion(data) {
		//need to pass question id to get the comments
		let uri = `/chat/${data.questionId}/like`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			headers: { appId: data.appId },
			payload: {}
		});
	}

	static disLikeQuestion(data) {
		//need to pass question id to get the comments
		let uri = `/chat/${data.questionId}/dislike`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			headers: { appId: data.appId },
			payload: {}
		});
	}

	static savedForLater(data) {
		//need to pass question id to get the comments
		let uri = `/chat/${data.questionId}/${data.savedForLater ? 'sfl' : 'rsfl'}`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			headers: { appId: data.appId },
			payload: {}
		});
	}

	static rating(data) {
		//need to pass question id to get the comments
		let uri = `/chat/${data.questionId}/r/${data.rating}`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			headers: { appId: data.appId },
			payload: {}
		});
	}

	static updateQuestionChartType(data) {
		//need to pass question id to get the comments
		let uri = `/chat/${data.questionId}/ct/${data.chartType}`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			headers: { appId: data.appId },
			payload: {}
		});
	}

	static sendEmail(data) {
		//need to pass question id to get the comments
		let uri = `/chat/mail`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			headers: { appId: data.appId },
			payload: data.payload
		});
	}

	static getAutoCompleteSuggestions(data) {
		const uri = "/chat/search";
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			headers: { appId: data.appId },
			payload: data.payload
		});
	}
}