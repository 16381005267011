const API_BASE_URL = process.env.REACT_APP_RULES_SERVICE_URL
import { request } from 'modules/client'

let route = `${API_BASE_URL}/rules`

export class BusinessRulesService {

	static getBusinessRules(appId) {
		return request(route, {
			method: 'GET',
			headers: { 'appId': appId }
		})
	}

	static getBusinessRule(params) {
		const uri = `${route}/${params.businessRuleId}`
		return request(uri, {
			method: 'GET',
			headers: { 'appId': params.appid }
		})
	}

	static saveBusinessRule(data) {
		const uri = `${route}/`
		return request(uri, {
			method: 'POST',
			payload: data,
			headers: { 'appId': data.appId }
		})
	}

	static updateBusinessRule(data) {
		const uri = `${route}`
		return request(uri, {
			method: 'PUT',
			payload: data,
			headers: { 'appId': data.appId }
		})
	}

	static deleteBusinessRule({ _id, appId }) {
		const uri = `${route}/delete/${_id}`
		return request(uri, {
			method: 'DELETE',
			headers: { appId }
		})
	}
}