import { request } from 'modules/client'
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_CREATE

export class PowerBiService {

    static getPowerBiReport({appId, orgId, workspaceId, reportId}) {
        const uri = API_BASE_URL + "/powerbi/getEmbedToken"

		return request(uri, {
			method: 'POST',
			payload: { workspaceId, reportId },
			headers: { appId, orgId }
		})
	}

}