// Notifications actions
import { ActionTypes } from 'constants/index';

export const fetchAllUnreadNotifications = (payload) => dispatch => {
    dispatch({ type: ActionTypes.GET_ALL_UNREAD_NOTIFICATIONS, payload });
};

export const getNotificationById = (payload) => dispatch => {
    dispatch({ type: ActionTypes.GET_SINGLE_NOTIFICATION, payload });
};

export const clearAllNotifications = (payload) => dispatch => {
    dispatch({ type: ActionTypes.CLEAR_ALL_NOTIFICATIONS, payload });
};

export const clearNotificationReduxState = () => dispatch => {
    dispatch({ type: ActionTypes.CLEAR_NOTIFICATION_REDUX_STATE, payload: '' });
};

export const clearSingleNotification = (payload) => dispatch => {
    dispatch({ type: ActionTypes.CLEAR_SINGLE_NOTIFICATION, payload });
};

export const updateNotification = (payload, newNotification) => dispatch => {
    dispatch({
        type: ActionTypes.UPDATE_NOTIFICATIONS,
        payload: {
            data: payload,
            newNotification
        }
    });
};