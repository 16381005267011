import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    actionHeader: {
        position: 'sticky',
        left: 0,
        boxShadow: 'inset 2px 0px 0px -2px rgb(0 0 0 / 15%)',
        width: '20%',
        zIndex: 3
    },
    head: {
        position: 'sticky',
        top: 0,
        zIndex: 4,
        backgroundColor: '#112840'
    },
    headerCellText: {
        color: 'white',
        backgroundColor: "#112840"
    },
    childTableCellBorder: {
        borderRight: '2px solid #112840',
        backgroundColor: '#ffffff'
    }
});

const UserProductivityTable = (props) => {
    const classes = useStyles();
    const  { headingData, userList } = props

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead className={classes.head}>
                    <TableRow>
                        <TableCell className={clsx(classes.actionHeader, classes.headerCellText)}>Name</TableCell>
                        {headingData.map((item, index) => (
                            <TableCell key={index} align="center" className={classes.headerCellText}>{item}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userList && Object.keys(userList).length > 0 ? (
                        Object.entries(userList).map(([name, data], listIndex) => (
                            <React.Fragment key={listIndex}>
                                <TableRow key={name}>
                                    <TableCell className={clsx(classes.actionHeader, classes.childTableCellBorder)}>{name}</TableCell>
                                    {headingData.map((date, index) => (
                                        <TableCell key={index} align="center">
                                            {data.find(item => item.date === date)?.value || '-'}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </React.Fragment>
                        ))
                    ) : (
                        <TableRow style={{ textAlign: 'center', display: 'block', padding: '1rem' }}>
                            No data available
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default UserProductivityTable;