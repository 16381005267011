const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
import { request } from 'modules/client'

const route = `${API_BASE_URL}/roles`

export class RolesService {

	static getRoles(appId) {
		return request(route, {
			method: 'GET',
			headers: { appId: appId }
		})
	}

	static getRole(params) {
		const uri = `${route}/${params.roleId}`
		return request(uri, {
			method: 'GET',
			headers: { appId: params.dappId }
		})
	}

	static saveRole(data) {
		// if (data.privileges && data.privileges.length > 0) {
		// 	let p = []
		// 	data.privileges.map((item) => {
		// 		p.push(item.value)
		// 	})
		// 	data.privileges = p
		// }
		return request(route, {
			method: 'POST',
			headers: { appId: data.appId },
			payload: data
		})
	}

	static updateRole(data) {
		// if (data.privileges && data.privileges.length > 0) {
		// 	let p = []
		// 	data.privileges.map((item) => {
		// 		p.push(item.value)
		// 	})
		// 	data.privileges = p
		// }
		const uri = `${route}/${data._id}`
		return request(uri, {
			method: 'PUT',
			headers: { appId: data.appId },
			payload: data
		})
	}

	static deleteRole(data) {
		const uri = `${route}/${data._id}`
		return request(uri, {
			method: 'DELETE',
			headers: { appId: data.appId },
		})
	}
}