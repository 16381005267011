import React from "react";


import { connect } from "react-redux";
import { hot } from "react-hot-loader/root";
import { withRouter } from "react-router-dom";
import { DocumentHierarchy } from 'dsilo-ui-components'

import {
    getContractDataById, getDataModelById, getModelDataDropDown, updateDataModelData, getMembersList, addNewEntriesByDataModel,
    clearReduxDataOfCurrentComponent, getModelData,
    getDataModels,
    getDocumentHierarchys, getDocumentsForHierarchys, saveDocumentHierarchy
} from '../../store/actions';

import { Loader } from "../loader";
import { PUBLIC_PATH } from '../../constants';

const DocumentHierarchyComponent = (props) => {
    return (
        <DocumentHierarchy
            {...props}
            loader={<Loader />}
        />
    )
}

const mapDispatchToProps = {
    getContractDataById,
    updateDataModelData,
    getModelDataDropDown,
    getMembersList,
    clearReduxDataOfCurrentComponent,
    getDataModelById,
    getModelData,
    addNewEntriesByDataModel,
    getDataModels,
    getDocumentHierarchys,
    getDocumentsForHierarchys,
    saveDocumentHierarchy
};

const mapStateToProps = state => {
    return {
        formData: state.form,
        action: state.action,
        appConfig: state.appConfig.app,
        PUBLIC_PATH: PUBLIC_PATH,
        members: state.appConfig.members,
        dataModels: state.dataModels,
        user: state.user,
        appConfiguration: state.form.document?.appConfig
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentHierarchyComponent)));