export const isJSON = str => {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};

export const getAccessToken = () => {
  if (localStorage.getItem('jwt_access_token')) {
    return true;
  }
  return false;
};

export function generateGUID() {
	function S4() {
		return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
	}
	return S4() + S4();
}
