import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';

import RenderComponentBasedOnStep from './RenderComponentBasedOnStep';
import { Font, FontSize } from 'styled-icons/boxicons-regular';

const HorizontalLinearStepper = (props) => {
    const { steps } = props
    const { chartUniqueId } = props.data
    const [activeStep, setActiveStep] = useState(0);
    const [selectedStep, setSelectedStep] = useState(steps[0]);
    const [skipped, setSkipped] = useState(new Set())
    const [saveAndNext, setSaveAndNext] = useState(false)
    const [invokeSubmit, setInvokeSubmit] = useState(false)

    const [globalErrors, setGlobalErrors] = useState([])

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const saveHandler = () => {
        if (saveAndNext) {
            setInvokeSubmit(true)
        } else {
            handleNext()
        }
    }

    const handleNext = () => {
        // let newSkipped = skipped;
        // if (isStepSkipped(activeStep)) {
        //     newSkipped = new Set(newSkipped.values());
        //     newSkipped.delete(activeStep);
        // }

        let nextStep = activeStep + 1
        setActiveStep(nextStep);
        setSelectedStep(steps[nextStep])
        // setSkipped(newSkipped);
        setSaveAndNext(false)
        setInvokeSubmit(false)
        // console.log("Cmng here Steps --->>>> ", steps, nextStep, activeStep);
    };

    const handleBack = () => {
        let prevStep = activeStep - 1
        setActiveStep(prevStep);
        setSelectedStep(steps[prevStep])
        setSaveAndNext(false)
        setInvokeSubmit(false)
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
        setSelectedStep(steps[0])
    };

    const useStyles = makeStyles((theme) => ({
        menuItem: {
            '& .MuiStepLabel-label': {
                fontSize:'1.2rem !important',     
                    },          
        },
        customposition: {
            padding: "10px 0px", 
            marginBottom: "15px",
            position: "sticky",
            top: "0px",
            zIndex: "101",
          }

    }));
    const classes = useStyles();
    return (
        <Box sx={{ width: '100%', height: '100%' }} className='pdfglcodes'>
            <Stepper activeStep={activeStep} className={classes.customposition} >
                {steps.map((step, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    // if (isStepOptional(index)) {
                    //     labelProps.optional = (
                    //         <Typography variant="caption">Optional</Typography>
                    //     );
                    // }
                    // if (isStepSkipped(index)) {
                    //     stepProps.completed = false;
                    // }
                    return (
                        <Step key={step.value} {...stepProps}>
                             <StepLabel {...labelProps} className={classes.menuItem}
                            
            
                             >{step.label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={() => handleReset()}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {/* {console.log("chartUniqueId + '_dataElements' =====>>> ", props, chartUniqueId + '_dataElements', props.formData?.dataModel[chartUniqueId + '_dataElements'])} */}

                    {/* For now adding render componets code here. later we can change */}
                    <RenderComponentBasedOnStep
                        {...props}
                        selectedStep={selectedStep}
                        dataElements={props.formData?.dataModel[chartUniqueId + '_dataElements']?.dataElements || []}
                        saveAndNext={saveAndNext}
                        setSaveAndNext={setSaveAndNext}
                        invokeSubmit={invokeSubmit}
                        handleNext={handleNext}
                        globalErrors={globalErrors}
                        setGlobalErrors={setGlobalErrors}

                    />
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                            style={{ position: 'fixed', bottom: '2.5%', zIndex: "99" }}

                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {/* {isStepOptional(activeStep) && (
                            <Button 
                            variant="contained" 
                            color="primary" 
                            size="small"
                            onClick={handleSkip} 
                            style={{marginRight: "10px", marginLeft: "10px"}}
                            sx={{ mr: 1, pr:1 }}>
                                Skip
                            </Button>
                        )} */}
                        {activeStep === steps.length ? null : <Button
                            // onClick={handleNext}
                            onClick={() => saveHandler()}
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ position: 'fixed', bottom: '2.5%', right: '0.5%', zIndex: "99"  }}
                        >
                            {(
                                props.formData?.[chartUniqueId + '_savePoInfo_loading'] ||
                                props.formData?.[chartUniqueId + '_saveSummaryData_loading'] ||
                                props.formData?.[chartUniqueId + '_saveGlCodesSummaryData_loading']
                            ) ? (
                                <CircularProgress color='#fff' size={24} />
                            ) : activeStep === steps.length -1 ? "Save" : saveAndNext ? 'Save & Next' : 'Next'}
                        </Button>}
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}

export default HorizontalLinearStepper