import { PdfWorkflowViewWrapper } from 'dsilo-ui-components';
import React from "react";
import { hot } from "react-hot-loader/root";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PUBLIC_PATH } from '../../constants';
import {
    changeAssigneeRequest, clearReduxDataOfCurrentComponent, clearSuccessMsg, completeReviewAction,
    createDocument, downloadContractPDFAction, downloadSheet, executeWorkFlow, getAuditTrailsData, getContractDataById,
    getDataModelById, getEventByID, getMembersList, getModelData, getModelDataDropDown, getNextRespondent,
    getNextTasks, getWorkflow, initiateAction, updateContractAnnotations, updateDataModelData, getChargeCodes,
    executeWorkFlowReject, getDataModels, addComment, otherDocMove, addNewEntriesByDataModel, getGroups,
    getGroupsByMemberId, getSupportingDocuments, finalDocument, splitPDF, getPDFForOtherDoc, uploadSupportDocument, getVISData,
    lockDocument, unlockDocument, rejectReviewAction, fetchmasterDataForDataelementUsingRules, moveToDuplicates
} from '../../store/actions';
import { Loader } from "../loader";

const PDFQAViewComponent = (props) => {
    return <div>
        <PdfWorkflowViewWrapper
            {...props}
            loader={<Loader />}
        />
    </div>
}

const mapDispatchToProps = {
    getContractDataById,
    updateDataModelData,
    clearSuccessMsg,
    createDocument,
    downloadSheet,
    getModelDataDropDown,
    downloadContractPDFAction,
    getEventByID,
    initiateAction,
    getMembersList,
    clearReduxDataOfCurrentComponent,
    updateContractAnnotations,
    getDataModelById,
    getModelData,
    getAuditTrailsData,
    changeAssigneeRequest,
    completeReviewAction,
    getWorkflow,
    getNextTasks,
    executeWorkFlow,
    getDataModels,
    getNextRespondent,
    getChargeCodes,
    executeWorkFlowReject,
    addComment,
    otherDocMove,
    addNewEntriesByDataModel,
    getGroups,
    getGroupsByMemberId,
    getSupportingDocuments,
    finalDocument,
    splitPDF,
    getPDFForOtherDoc,
    uploadSupportDocument,
    getVISData,
    lockDocument,
    unlockDocument,
    rejectReviewAction,
    fetchmasterDataForDataelementUsingRules,
    moveToDuplicates
};

const mapStateToProps = state => {
    return {
        formData: state.form,
        action: state.action,
        appConfig: state.appConfig.app,
        PUBLIC_PATH: PUBLIC_PATH,
        members: state.appConfig.members,
        user: state.user,
        dataModels: state.dataModels,
        appConfiguration: state.form.document?.appConfig
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFQAViewComponent)));
