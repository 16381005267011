import { lex, addToken } from './lexer'
import { tokenTypes, createTokens } from './token'
import { toPostfix } from './postfix'
import { postfixEval } from './postfix_evaluator'
import { createMathFunctions } from './functions'

class Mexp {
	static TOKEN_TYPES = tokenTypes
	static tokenTypes = tokenTypes
	tokens = []
	toPostfix = toPostfix
	addToken = addToken
	// lex(_this, inp, tokens) {
	// 	lex1(_this, inp, tokens)
	// }
	postfixEval = postfixEval
	eval(string, tokens, Constants) {
		return this.postfixEval(this.toPostfix(lex(this, string, tokens)), Constants)
	}
	math = null
	constructor() {
		this.math = createMathFunctions(this)
		this.tokens = createTokens(this)
	}
}
export default Mexp
