import { Box, Button, Grid, makeStyles, Paper } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import { AdvancedTable } from 'dsilo-ui-components';
import React from 'react';
import 'styles/styles.scss';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	headerClass: {
		backgroundColor: "#cecece"
	},
	buttonSection: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginBottom: 10
	}
}));

const exportArray = [{ key: 'xls', name: "Export as Excel", isVisible: true },
{ key: 'csv', name: "Export as csv", isVisible: true },
{ key: 'txt', name: "Export as text", isVisible: true },
{ key: 'image', name: "Export as image", isVisible: true }];

const View = (props) => {
	const classes = useStyles();
	return (
		<Box className="abcCompany-page">
			<Box className="pxy20">

				<Grid container>
					<Grid item xs={12} md={6}>
						{/* <div className="backbtn">
							<div className="backbtn">
								<img src={rightarrow} alt="backicon" className="backicon" />
								<Box component="h3" className="companyheadingtext">
									<Typography variant="h4">BusinessRules</Typography>
								</Box>
							</div>
						</div> */}
					</Grid>

					<Grid item xs={12} md={6} className={classes.buttonSection}>
						<Button variant="contained" color="primary" className="addnewentrybtn mb5"
						onClick={() => props.handleAddClick()}>
							{"ADD NEW BUSINESS RULE"}
						</Button>
					</Grid>

					<TableContainer component={Paper}>
						<AdvancedTable onRowClick={props.onRowClick}
							name={"BusinessRules"}
							{...props}
							{...props.state}
							searchHandler={props.searchHandler}
							isExport={true}
							isViewCoulmns={true}
							isSearch={true}
							isFilter={true}
							hasCheckbox={false}
							actions={{ edit: true, view: true, delete: true, copy: true }}
							isTablePagination={true}
							isTableHead={true}
							isTableSubHeader={false}
							handleInputChange={props.handleInputChange}
							resetFilters={props.resetFilters}
							handleDropdownChange={props.handleDropdownChange}
							handleRequestSort={props.handleRequestSort}
							handleSelectAllClick={props.handleSelectAllClick}
							tableCrudOperations={props.tableCrudOperations}
							handleCheck={props.handleCheck}
							handleChangePage={props.handleChangePage}
							handleChangeRowsPerPage={props.handleChangeRowsPerPage}
							handleExoprtItemClick={props.handleExoprtItemClick}
							exportArray={exportArray}
							stripRows // for changing BGcolor of odd rows
							headerstyle={{
								fontSize: "14px",
								color: "rgba(0, 0, 0, 0.87)",
								textAlign: "left",
								backgroundColor: "#"
							}}
							subHeaderstyle={{
								fontSize: "14px",
								color: "rgba(0, 0, 0, 0.87)",
								backgroundColor: "#FFF",
								textAlign: "left"
							}}
							headerClass={classes.headerClass}
							rowStyle={{
								fontSize: "14px",
								color: "rgba(0, 0, 0, 0.87)",
								backgroundColor: "none",
								lineHeight: 1.5,
								textAlign: "left"
							}}
							rowStyle={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.87)", backgroundColor: "#FFF", row1BackgroundColor: "#FFF", row2BackgroundColor: "#FFF" }} />
					</TableContainer>
				</Grid>
			</Box>

		</Box>
	)
}

export default View