import { makeStyles } from '@material-ui/core';
import { PUBLIC_PATH } from 'constants/index';
import { MiniTable } from 'dsilo-ui-components';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	getContractDataById, getModelData,
	clearReduxDataOfCurrentComponent
} from '../../store/actions';

const useStyles = makeStyles(theme => ({
	gridItem: {},
}));

const conditionType = [
	"${DOCUMENT}",
	"${CURRENT_DOCUMENT}",
	"${CURRENT_USER}",
	"${SYSTEM_USER}"
]

const defaultData = {
	title: 'Recent Promotion Contracts',
	dataSource: [],
	columns: [
		{
			key: 'originalName',
			label: 'File',
			style: {
				// width: 25,
			},
		},
		{
			key: 'createdDate',
			label: 'Date',
			style: {},
		},
		{
			key: 'status',
			label: 'Status',
			style: {},
		},
		{
			key: 'createdByName',
			label: 'Created By',
			style: {

			},
		},
	],
	styleConfig: {
		status: { conditions: { 'No Results': { color: 'red' }, "Processed": { color: 'green' } } }
	},
};

const DataTableContainer = (props) => {
	const defaultData1 = {
		...defaultData, moreClickAction: () => {
			let pageUrl = chartData?.moreLink || 'documents'
			props.history.push({
				pathname: `/${PUBLIC_PATH}${props.match.params.appid}/page/${pageUrl}`,
			});
		}
	}
	const classes = useStyles();
	const [data, setData] = useState(defaultData1);
	const { chartData } = props.data;
	const [parentInvoked, setParentInvoked] = useState(false)
	const [conditions, setConditions] = useState([])
	const _conditions = props.data.chartData?.conditions

	useEffect(() => {
		let dataSource = (props.formData?.document && props.formData?.document[props.data.chartUniqueId + '_data'])
			? props.formData?.document[props.data.chartUniqueId + '_data'].data
			: []
		dataSource = JSON.parse(JSON.stringify(dataSource));
		if (dataSource && chartData.groupByDataElement?.value) {
			dataSource = dataSource?.map(item => {
				if (Object.keys(item).includes('_id')) {
					item[chartData.groupByDataElement?.value] = item._id
				}
				return item
			})
		}
		let columns = getColumns();
		setData({ ...data, dataSource, columns })
	}, [props.formData?.document[props.data.chartUniqueId + '_data']])

	useEffect(() => {
		let title = chartData.title || chartData.name
		setData({ ...data, title })
		let called = false
		if (_conditions && chartData.parentDocumentDataModel) {
			fecthParentData(_conditions)
		}
		if (!called) {
			fetchData()
		}

		return () => {
			clearCurrentChartReduxStore()
		}
	}, []);

	const clearCurrentChartReduxStore = () => {
		let subKeys = [
			props.data.chartUniqueId + "_pData",
			props.data.chartUniqueId + "_data",
			// props.data.chartUniqueId + "_externalFilter_viewFields",
			// props.data.chartUniqueId + "_externalFilter_fields",
			// props.data.chartUniqueId + "_externalFilter",
		]

		props.clearReduxDataOfCurrentComponent({
			key: "document",
			subKey: subKeys
		})
	}

	useEffect(() => {
		let document = props.formData.document
		if (
			document[props.data.chartUniqueId + "_pData"] &&
			document[props.data.chartUniqueId + "_pData"].data &&
			!parentInvoked
		) {
			if (document[props.data.chartUniqueId + "_pData"].data?.length) {
				const _parentData = JSON.parse(
					JSON.stringify(
						document[props.data.chartUniqueId + "_pData"].data[0]
					)
				)
				if (_conditions && chartData.parentDocumentDataModel) {
					const updatedConditions = _conditions.map((condition) => {
						// TODO: currently supporting only when parent document id is there need to implement scenario when there is no parent document
						const conditionName = condition.value.split(".")[0]
						const index = conditionType.indexOf(conditionName)
						if (index > -1 || index === 0 || index === 1) {
							if (_parentData[condition.value.split(".")[1]]) {
								condition.value =
									_parentData[
										condition.value.split(".")[1]
									] instanceof Array && condition.value.length
										? _parentData[
										condition.value.split(".")[1]
										][0]
										: _parentData[
										condition.value.split(".")[1]
										]
							}
						}
						return condition
					})
					setConditions(updatedConditions)
					setParentInvoked(true)
					fetchData(updatedConditions)
				}
			}
		}
	}, [props.formData.document[props.data.chartUniqueId + "_pData"]])

	const fecthParentData = (conditions) => {
		const conditionFields = []
		conditions.filter((condition) => {
			const conditionName =
				condition.value instanceof Array && condition.value.length
					? condition.value[0].split(".")[0]
					: condition.value.split(".")[0]
			const index = conditionType.indexOf(conditionName)
			if (index > -1) {
				conditionFields.push(condition.value.split(".")[1])
			}
		})

		if (conditionFields.length) {
			// called = true
			const payload = {
				appId: props.match.params.appid,
				dataModelId: chartData.parentDocumentDataModel,
				id: props.match.params.id
			}
			payload.fields = conditionFields.map((i) => i).join(",")
			payload.dataUniqueId = props.data.chartUniqueId + "_pData"
			props.getContractDataById(payload)
		}
	}

	const fetchData = (_updatedConditions) => {
		let currentConditions = _updatedConditions
			? [..._updatedConditions]
			: [...conditions]
		if (chartData?.groupByDataElement && !chartData.fetchType) {
			let payload = {
				// fields: chartData.selectedDataElement.map(({ value }) => value).join(','),
				fields: chartData.selectedDataElement,
				appId: props.match.params.appid,
				id: chartData.selectedDataModel,
				groupByDataElement: chartData.groupByDataElement?.value,
				conditions: currentConditions,
			};
			if (chartData.fetchType) {
				payload.fetchType = chartData.fetchType
			}
			if (chartData.selectedInnerDataElement) {
				payload.viewFields = chartData.selectedInnerDataElement
					.map((i) => i.value)
					.join(",")
			}
			if (chartData.selectedDataModel && chartData.selectedDataModel !== "") {
				props.getModelData({ ...payload, dataUniqueId: props.data.chartUniqueId + '_data' });
			};
		} else {
			let payload = {
				fields: chartData.selectedDataElement.map(({ value }) => value).join(','),
				appId: props.match.params.appid,
				id: chartData.selectedDataModel,
				page: 0,
				rowsPerPage: chartData.pagination?.rowsPerPage || 5,
				conditions: currentConditions,
			};
			if (chartData.calculatedFields) {
				payload.calculatedFields = chartData.calculatedFields.map(cf => {
					cf.name = cf.value
					return cf;
				})
			}
			if (chartData.fetchType) {
				payload.fetchType = chartData.fetchType
			}
			if (chartData.selectedInnerDataElement) {
				payload.viewFields = chartData.selectedInnerDataElement
					.map((i) => i.value)
					.join(",")
			}
			if (chartData.conditions) {
				payload.conditions = chartData.conditions
			}
			if (chartData.selectedDataModel && chartData.selectedDataModel !== "") {
				props.getModelData({ ...payload, dataUniqueId: props.data.chartUniqueId + '_data' });
			};
		}
	}

	const getCalculatedFields = () => {
		let columns = []
		if (chartData.calculatedFields) {
			chartData.calculatedFields?.map(field => {
				columns.push(field)
			})
		}
		return columns
	}

	const getColumns = () => {
		let columns = []
		if (chartData.fetchType === "keysArray") {
			if (chartData?.selectedInnerDataElement?.length) {
				columns = chartData.selectedInnerDataElement
			} else if (props.formData?.data?.data?.length) {
				columns = Object.keys(document[props.data.chartUniqueId + "_data"].data[0]).map((key) => ({ label: key, value: key }))
			}
		} else {
			if (chartData?.selectedDataElement?.length) {
				columns = chartData.selectedDataElement
			} else {
				columns = Object.keys(document[props.data.chartUniqueId + "_data"].data[0]).map((key) => ({ label: key, value: key }))
			}
		}
		let addColumns = getCalculatedFields(columns)
		return [...columns, ...addColumns];
	}

	const loading = props.formData[props.data.chartUniqueId + '_data_loading'];

	return (<div style={{ height: 'calc(100% - 10px)' }}>
		{data &&
			<MiniTable isMoreLink={chartData?.moreLink}
				noData={chartData.selectedDataModel === ""} maxRows={chartData.pagination?.rowsPerPage || 5}
				loading={loading} {...props} data={data} viewPage={chartData.viewPage} />}
	</div>)
}

const mapDispatchToProps = {
	getModelData,
	getContractDataById,
	clearReduxDataOfCurrentComponent
};

const mapStateToProps = state => {
	return {
		formData: state.form,

	};
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(withRouter(DataTableContainer)));
