import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    promptParentBox: {
        display: 'flex', 
        gap: 5,
        overflowX: 'auto',
        paddingBottom: 12,
        alignItems: 'center',
        marginTop: "1.5rem"
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    promptChildBox: {
        border: '1px solid #000',
        padding: '10px',
        maxWidth: '10rem',
        minWidth: '5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#ffffff",
        wordBreak: 'break-word'
    },
	selectedPrompt: {
		backgroundColor: "#cecece",
		fontWeight: "bold"
	},
}))

export default useStyles