import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { QuestionTitle } from './index';
import {
  extractMatchedChartFromQuestion,
  generateHighchartData,
  getRandomChartType,
} from '../../ChatHelper';
import SimpleTable from '../SimpleTable';
import ChartComponent from '../ChartComponent';

const MultiResponseTable = (props) => {
    const {
      classes,
      columnSize,
      queryTexts,
      rIndex,
      message,
      output,
      chartData,
      supportedHighcharts,
      label,
      result,
    } = props;
    const [randomChartType, setRandomChartType] = useState({});
    const [chartType, setChartType] = useState('')
    const [highchartData, setHighchartData] = useState({})
    const [showHighCharts, setShowHighCharts] = useState(null)

    useEffect(() => {
        const extractedChartType = extractMatchedChartFromQuestion(queryTexts?.[rIndex] || message?.title);
        let cType = extractedChartType || getRandomChartType();
        if (!cType){
            let uniqueId = message?.questionId + '_' + rIndex
            if(randomChartType?.[uniqueId]){
                cType = randomChartType[uniqueId]
            } else {
                let rType = randomChartType
                cType = rType
                setRandomChartType({
                    ...randomChartType,
                    [uniqueId]: rType
                })
            }
        }
        let hcData = generateHighchartData(output, cType, props?.chartData);
        let sCharts = hcData && Object.keys(hcData).length && supportedHighcharts.includes(cType);
        setChartType(cType)
        setHighchartData(hcData)
        setShowHighCharts(sCharts)
    }, [rIndex]);

    return (
        <Grid item xs={columnSize} key={rIndex}>
            {/* <Paper className={classes.paper}> */}
                <QuestionTitle title={label} />
                {showHighCharts ? (
                    <Box style={{ position: 'relative', zIndex: props?.fromDashboard ? 0 : 1 }}>
                        <ChartComponent
                            chartType={chartType}
                            addCustomStyles={true}
                            chartData={highchartData?.chartData || {}}
                            responseData={highchartData}
                            id={result?.length}
                            highChartsRef={null}
                            title={queryTexts?.[rIndex] || message?.title}
                            chartUniqueId={message?._id || message?.questionId}
                        />
                    </Box>
                ) : (
                    <SimpleTable
                        index={rIndex}
                        data={output}
                        message={queryTexts?.[rIndex] || message?.title}
                    />
                )}
            {/* </Paper> */}
        </Grid>
    );
}

export default MultiResponseTable;
