import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { CappsService } from 'modules/api/index'
import { ActionTypes } from 'constants/index';


async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

function* uploadCapps(action) {
    try {
        console.log("fire3", action);
        const cappsData = yield fetchAsync(CappsService.uploadCapps, action.payload);
        yield put({ type: ActionTypes.UPLOAD_CAPPS_SUCCESS, payload: cappsData });
    } catch (e) {
        yield put({ type: ActionTypes.UPLOAD_CAPPS_ERROR, error: e.message });
    }
}

function* fetchCapp(action) {
    try {
        const capp = yield fetchAsync(CappsService.getCapp, action.payload);
        yield put({ type: ActionTypes.GET_CAPP_SUCCESS, payload: capp });
    } catch (e) {
        yield put({ type: ActionTypes.GET_CAPP_ERROR, error: e.message });
    }
}

function* fetchFilterCapp(action) {
    try {
        const capp = yield fetchAsync(CappsService.filterCappData, action.payload);
        yield put({ type: ActionTypes.FILTER_CAPP_SUCCESS, payload: capp });
    } catch (e) {
        yield put({ type: ActionTypes.FILTER_CAPP_ERROR, error: e.message });
    }
}

function* ruleExcutionApp(action) {
    try {
        const rules = yield fetchAsync(CappsService.ruleExcutionData, action.payload);
        yield put({ type: ActionTypes.RULE_EXCUTER_SUCCESS, payload: rules });
    } catch (e) {
        yield put({ type: ActionTypes.RULE_EXCUTER_ERROR, error: e.message });
    }
}
export function* watchCappsSaga() {
    yield takeEvery(ActionTypes.UPLOAD_CAPPS_LOADING, uploadCapps);
    yield takeEvery(ActionTypes.GET_CAPP_LOADING, fetchCapp);
    yield takeEvery(ActionTypes.FILTER_CAPP_LOADING, fetchFilterCapp);
    yield takeEvery(ActionTypes.RULE_EXCUTER_LOADING, ruleExcutionApp);
}

export default watchCappsSaga;