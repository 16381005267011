import { request } from 'modules/client'
const API_BASE_URL = process.env.REACT_APP_DATA_SERVICE_URL

export class DataModelService {

	static getDataModels(appId) {
		const uri = API_BASE_URL + "/datamodel"
		return request(uri, {
			method: 'GET',
			headers: { appId }
		})
	}

	static getDataModel(data) {
		const uri = API_BASE_URL + "/datamodel/" + data.datamodelId
		return request(uri, {
			method: 'GET',
			headers: { appId: data.dappId }
		})
	}

	static saveDataModel(data) {
		const uri = API_BASE_URL + "/datamodel/add"
		return request(uri, {
			method: 'POST',
			payload: data,
			headers: { appId: data.appId, orgId: data.orgId }
		})
	}

	static updateDataModel(data) {
		const uri = API_BASE_URL + "/datamodel/" + data._id
		return request(uri, {
			method: 'PUT',
			payload: data,
			headers: { appId: data.appId }
		})
	}

	static deleteDataModel({ _id, appId }) {
		const uri = API_BASE_URL + "/datamodel/" + _id
		return request(uri, {
			method: 'DELETE',
			headers: { appId }
		})
	}

	static deleteDataModels(dataModels, appId) {
		const uri = API_BASE_URL + "/dataModels/bulk"
		return request(uri, {
			method: 'DELETE',
			payload: dataModels,
			headers: { appId }
		})
	}

	static getDataModelsByOrgId(data) {
		let uri = API_BASE_URL + '/datamodel/org'
		return request(uri, {
			method: 'GET',
			headers: {
				appId: data.appId,
				orgId: data.orgId
			}
		})
	}

	static getCollectionsFromDatasource({ id, appId }) {
		console.log("id, appId", id, appId);
		let uri = API_BASE_URL + '/datasources/datamodels/' + id
		return request(uri, {
			method: 'GET',
			headers: {
				appId
			}
		})
	}
}