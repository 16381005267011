import React from 'react';
import { connect } from 'react-redux';
import { submitRegister } from '../../store/actions';
import { SignUp, Validations } from 'dsilo-ui-components';
import { withRouter } from 'react-router-dom';
import { PUBLIC_PATH } from '../../constants';
import dsiloLogo from '../../images/logo.png';

const SignupComponent = props => {
  return (
    <div className="login-ciep" >
      <SignUp
        appLogo={dsiloLogo}
        subTitle={"ProcureGPT&trade; - AI Platform"}
        register={props.register}
        handleSignUp={props.submitRegister}
        validatePassword={Validations.validatePassword}
      />
    </div>
  );
};

const mapDispatchToProps = {
  submitRegister,
};
const mapStateToProps = state => {
  return {
    register: state.register,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupComponent));
