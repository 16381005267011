import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { roundOffToTwoDecimalPlaces } from '../../ChatHelper';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    borderCollapse: 'collapse',
    marginBottom: '10px'
  },
  tableCell: {
    border: '1px solid black', 
    textAlign: 'center'
  },
  notData: {
    textAlign: 'center',
    margin: '1rem',
  }
}));

const SimpleList = props => {
  const { responseList } = props;
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (responseList && responseList?.length) {
      const responseListLength = responseList.length;
      const maxStringsPerColumn = 10; // TODO: Adjust as needed, if needed make it as a page config
      const numColumns = Math.ceil(responseListLength / maxStringsPerColumn);
      const numRows = Math.ceil(responseListLength / numColumns);
      const tempTableData = [];

      for (let row = 0; row < numRows; row++) {
        const rowData = [];
        for (let col = 0; col < numColumns; col++) {
          const index = row * numColumns + col;
          if (index < responseListLength) {
            let value = responseList[index];
            if (typeof value === 'string' && value.toLocaleLowerCase() === 'nan') {
              value = '-'
            } else if (typeof value === 'number') {
              value = roundOffToTwoDecimalPlaces(value);
            }
            rowData.push(value);
          }
        }
        tempTableData.push(rowData);
      }
      if (tempTableData && tempTableData?.length) setTableData(tempTableData);
    }
  }, [responseList]);

  return (
    <Grid item xs={12}>
      <TableContainer>
        <Table className={classes.tableContainer}>
          <TableBody>
            {tableData && tableData.length > 0 ? (
              tableData.map((item, index1) => (
                <TableRow key={index1}>
                  <React.Fragment>
                    {item.map((item2, index2) => {
                      const backgroundColor = index2 % 2 === 0 ? "rgb(255, 255, 255)" : "rgb(232 232 232)";
                      return (
                        <TableCell
                          className={classes.tableContainer}
                          key={index2}
                          style={{ backgroundColor }}
                        >
                          {item2}
                        </TableCell>
                      );
                    })}
                  </React.Fragment>
                </TableRow>
              ))
            ) : (
              <Box className={classes.notData}>
                {'No data available'}
              </Box>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default SimpleList;
