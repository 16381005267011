import { Box, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import clsx from 'clsx';
import { history } from 'dsilo-ui-components';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import logo from '../../images/logo.png';
import JoinOrg from '../Org/JoinOrg';
import { logoutUser } from '../../store/actions';
import './style.scss';

// const BUILDER_URL = process.env.REACT_APP_BUILDER_URL;
const BUILDER_URL = 'https://builder.platform-dev.dsilo.io';
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	menuButton: {
		marginRight: 20,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9) + 1,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(4),
		backgroundColor: '#fff',
		minHeight: '100%',
	},
	diffcontent: {
		flexGrow: 1,
		padding: theme.spacing(4),
		backgroundColor: '#eee',
		minHeight: '100%',
	},
	icon: {
		padding: 10,
	},
	large: {
		width: '35px !important',
		height: '35px !important',
	},
	fullContentBgColor: {
		backgroundColor: "#EDEDED",
		minHeight: "100vh",
		height: "100%"
	}
}));

const AppHeader = props => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [openProfileMenu, setOpenProfileMenu] = useState(false);
	const anchorRef = React.useRef(null);
	const isAdLogin = localStorage.getItem('isAdLogin') === 'true';
	const isSsoLogin = localStorage.getItem('isSsoLogin') === 'true';
	const hasSsoConfig = localStorage.getItem('ssoConfig');

	const hideProfileMenu = isAdLogin || isSsoLogin || (hasSsoConfig && hasSsoConfig !== '');

	const handleToggle = () => {
		setOpenProfileMenu(prevOpen => !prevOpen);
	};

	const handleCloseProfileMenu = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpenProfileMenu(false);
	};

	const handleDrawerOpen = () => {
		// setOpen(!open);
		history.push({ pathname: `/apps` });
	};
	const handleProfile = () => {
		history.push({ pathname: `/profile` });
		setOpenProfileMenu(false);
	};
	const handleOrg = () => {
		history.push({ pathname: `/org` });
		setOpenProfileMenu(false);
	};

	const getRole = () => {
		const { user } = props;
		if (user && user.roles && user.roles.length > 0) {
			return user.roles[0];
		}
		return '';
	};

	const ProfileMenu = () => {
		return (
			<Popper
				open={openProfileMenu}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleCloseProfileMenu}>
								<MenuList>
									{/* discussed and removed My Account for new GW release */}
									{/* <MenuItem onClick={handleProfile}>My Account</MenuItem> */}
									<MenuItem onClick={handleOrg}>Company Profile</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		);
	};

	let pathArr = props.history.location.pathname.split('/')
	let pathVal = pathArr[pathArr.length - 1]


	return (
		<div className="layout">
			<div className={classes.root}>
				<AppBar
					position="fixed"
					className={clsx(classes.appBar, {
						[classes.appBarShift]: open,
					})}
				>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							edge="start"
							className={clsx(classes.menuButton)}
						>
							<HomeIcon />
						</IconButton>

						<Link to="/apps">
							<img src={logo} alt="" />
						</Link>

						<div className="header">Platform</div>
					</Toolbar>
					<div className="header-right">
						<ul>
							{/* <li>
								<p>License type : Basic</p>
							</li>
							<li>
								<Button onClick={() => { history.push({ pathname: `/contact-dsilo` }); }} color="primary" variant="contained">
									Upgrade
								</Button>
							</li> */}
							{/* <li>
								<IconButton
									color="inherit"
									style={{padding: 0}}
									onClick={() => {
										window.open(`${BUILDER_URL}`, '_blank')
									}}
								>
									<SettingsIcon className={classes.large} />
								</IconButton>
							</li> */}
							<li onClick={handleToggle} ref={anchorRef}>
								<span className="user-profile">
									{props.user && props.user.photoURL ? (
										<Avatar alt="user photo" src={props.user.photoURL} className={classes.large} />
									) : (
										<AccountCircleIcon className={classes.large} />
									)}
								</span>
								{props.user && (
									<Box display="flex" alignItems="center" className="user-name">
										{props.user.userName} <i>{getRole()}</i>
									</Box>
								)}
							</li>
							<li title="Logout" onClick={props.logoutUser}>
								<ExitToAppOutlinedIcon alt="logout" className={classes.large} />
							</li>
						</ul>
					</div>
					{!hideProfileMenu && ProfileMenu()}
				</AppBar>
			</div>
			<main className={props?.showBgColor ? classes.fullContentBgColor : pathVal === 'search' ? classes.diffcontent : classes.content}>
				<div className={classes.toolbar} />
				{props.children}
			</main>
			{/* <JoinOrg /> */}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.login.user,
		org: state.org,
	};
};

const mapDispatchToProps = {
	logoutUser,
};
export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(AppHeader)));
