import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { BarChart, DateRangePicker , LabelColorBoxes } from 'dsilo-ui-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchProcessDashboard2Data, getModelDataDropDown, setSelectedChartItems } from '../../store/actions';
import { Loader } from "../loader";
import DashboardFilters from './DashboardFilters';
import UserProductivityTable from './UserProductivityTable';
import { labelColorBoxConfig2, generateHighChartData } from './utils';
import useStyles from './styles';

const inboundOutBound = 'inboundOutBound';
const productivityById = 'productivityById';
const ProcessDashboard2 = (props) => {
    const { appId, data } = props;
    const { chartUniqueId, chartData } = data;
    const { autoRefresh, selectedDataModel, filterConditions } = chartData;
    const classes = useStyles();
    const [pageFilterConditions, setPageFilterConditions] = useState([]);
    const [selectedChartItem, setSelectedChartItem] = useState({});
    const [dateFormat, setDateFormat] = useState({
        [inboundOutBound]: 'MM/DD/YYYY',
        [productivityById]: 'MM/DD/YYYY'
    });
    const [clickedItems, setClickedItems] = useState({});
    const [dashboardData, setDashboardData] = useState({});
    const [dateRange, setDateRange] = useState({
        startDate: '', endDate: ''
    });

    const fetchDashboardData = useCallback(() => {
        let payload = {}
        if (pageFilterConditions && Array.isArray(pageFilterConditions) && pageFilterConditions.length > 0) {
            payload= {
                conditions: pageFilterConditions
            }
        }
        if (clickedItems && clickedItems?.[inboundOutBound]?.publishConditions?.length > 0) {
            payload = {
                ...payload,
                publishConditions: clickedItems?.[inboundOutBound]?.publishConditions
            }
        }
        const _datefilterConditions = clickedItems?.[inboundOutBound]?.filterConditions || {};
        if (clickedItems && _datefilterConditions && Object.keys(_datefilterConditions).length > 0) {
            payload = {
                ...payload,
                filterConditions: _datefilterConditions
            }
        }
        if (dateFormat && Object.keys(dateFormat).length > 0) {
            if (dateFormat.inboundOutBound) {
                payload = {
                    ...payload,
                    [inboundOutBound]: {
                        ...payload[inboundOutBound],
                        format: dateFormat?.[inboundOutBound]
                    }
                }
            }
            if (dateFormat.productivityById) {
                payload = {
                    ...payload,
                    [productivityById]: {
                        ...payload[productivityById],
                        format: dateFormat?.[productivityById]
                    }
                }
            }
        }
        props.fetchProcessDashboard2Data({
            appId,
            dataModelId: selectedDataModel,
            dataUniqueId: `${chartUniqueId}_pb2`,
            payload
        });
    }, [pageFilterConditions, dateFormat, clickedItems]);

    useEffect(() => {
        fetchDashboardData();
    }, [fetchDashboardData]);

    useEffect(() => {
        let intervalId = 0;
        const timeout = autoRefresh?.timeout || 5 * 60 * 1000;
        if(autoRefresh && autoRefresh?.enable) {
            intervalId = setInterval(fetchDashboardData, timeout);
        }
    
        return () => {
          clearInterval(intervalId);
        };
    }, [autoRefresh, pageFilterConditions, clickedItems]);

    const updateDateFilterConditions = (dateValue) => {
        let dateRangeFilterCondition = null
        if (filterConditions && Object.keys(filterConditions)?.length > 0) {
            for (let key in filterConditions) {
                if (filterConditions.hasOwnProperty(key) && typeof filterConditions[key] === 'object') {
                    dateRangeFilterCondition = {
                        ...dateRangeFilterCondition,
                        [key]: {
                            ...filterConditions[key],
                            selectedConditionDataElement: key,
                            value: dateValue
                        }
                    }
                }
            }
        }
        return dateRangeFilterCondition
    }

    useEffect(() => {
        if (!clickedItems?.[inboundOutBound] || (dateRange?.startDate && dateRange?.endDate)) {
            const formData = { ...props?.formData?.document?.[`${chartUniqueId}_pb2`] };
            let tempDashboardData = { ...dashboardData };
            const inboundVsOutbound = formData?.inboundVsOutbound;
            if (inboundVsOutbound && Array.isArray(inboundVsOutbound) && inboundVsOutbound?.length >= 0) {
                const data = {
                    responseData: inboundVsOutbound,
                    title: 'Inbound Vs Outbound',
                    chartType: 'column',
                    generateMultiColumnChart: true,
                    enableOnChartClick: true
                }
                const inOutData = generateHighChartData(data);
                tempDashboardData = {
                    ...dashboardData,
                    ...tempDashboardData,
                    inboundOutboundData: inOutData
                }
            }
            setDashboardData(tempDashboardData)
        }
    }, [props?.formData?.document?.[`${chartUniqueId}_pb2`]?.inboundVsOutbound]);

    useEffect(() => {
        if (selectedChartItem && Object.keys(selectedChartItem).length > 0) {
            const _selectedChartItem = selectedChartItem?.[inboundOutBound]?.selectedChartItem;
            const dataElementName = selectedChartItem?.[inboundOutBound]?.dataElementName;
            const { title } = _selectedChartItem;
            const prevPublishConditions = clickedItems?.[inboundOutBound]?.publishConditions || [];
            const prevValue = prevPublishConditions.find(pv => pv.selectedConditionDataElement === dataElementName && pv.selectedConditionOperator === "equals")?.value || '';
            if (prevValue === title) {
                resetChartSelection(false);
            } else {
                let publishConditions = [];
                const selectedChartCondition = {
                    selectedConditionDataElement: dataElementName,
                    selectedConditionOperator: "equals",
                    value: title,
                    selectedConditionClause: "and",
                    format: dateFormat?.[inboundOutBound]
                }
                publishConditions.push(selectedChartCondition);
                let dateRangeFilterCondition = {}
                if (dateRange?.startDate && dateRange?.endDate) {
                    const dateValue = `${dateRange?.startDate},${dateRange?.endDate}`;
                    dateRangeFilterCondition = updateDateFilterConditions(dateValue)
                }
                setSelectedChartItem({});
                setClickedItems((prev) => {
                    return {
                        ...prev,
                        [inboundOutBound]: {
                            ...prev?.[inboundOutBound],
                            publishConditions, 
                            filterConditions: dateRangeFilterCondition
                        }
                    }
                });
            }
        }
    }, [selectedChartItem, clickedItems, dateRange]);

    useEffect(() => {
        if (dateRange?.startDate && dateRange?.endDate && Object.keys(selectedChartItem).length === 0) {
            const dateValue = `${dateRange?.startDate},${dateRange?.endDate}`;
            setClickedItems((prev) => {
                return {
                    ...prev,
                    [inboundOutBound]: {
                        ...prev?.[inboundOutBound],
                        filterConditions:  updateDateFilterConditions(dateValue)
                    }
                }
            });
        }
    }, [dateRange, selectedChartItem]);

    const labelCardBoxValues = useMemo(() => {
        const labelCardData = props?.formData?.document?.[`${chartUniqueId}_pb2`]?.vcb;
        let cardData = [];
        if (labelCardData && Object.keys(labelCardData).length > 0) {
            cardData = labelColorBoxConfig2.map(card => {
                if (labelCardData.hasOwnProperty(card.dataElement)) {
                    card.value = labelCardData?.[card.dataElement];
                }
                return card;
            });
        }        
        return cardData;
    }, [props?.formData?.document?.[`${chartUniqueId}_pb2`]?.vcb]);

    const userProductivityData = useMemo(() => {
        let userData = null;
        const userProductivity = props?.formData?.document?.[`${chartUniqueId}_pb2`]?.userProductById;
        if (userProductivity && Array.isArray(userProductivity) && userProductivity?.length > 0) {
            const tempUserData = [...userProductivity];
            const transformedData = {};
            tempUserData.forEach(item => {
                const key = item.name;
                if (!transformedData[key]) {
                    transformedData[key] = [];
                }
                transformedData[key].push({ date: item.date, value: item.value });
            });
            const headingData = [...new Set(tempUserData.map(item => item.date))];
            userData = {
                userList: transformedData,
                headingData,
            }
        }
        return userData;
    }, [props?.formData?.document?.[`${chartUniqueId}_pb2`]?.userProductById]);

    const handleDashboardItemClick = (elementName, chartType, selectedChartItem, selectedChartType = '') => {
        let dataElementName = 'createdDate'
        if (elementName === 'outbound') {
            dataElementName = 'dateOfFinalProcessing'
        }
        const chartItem = {
            [chartType]: {
                selectedChartItem,
                dataElementName
            }
        }
        props?.setSelectedChartItems(selectedChartType)
        console.log('#2 chartItem ::: *** ', chartItem);
        setSelectedChartItem(chartItem);
    };

    const resetChartSelection = (clearDateRange = true) => {
        props?.setSelectedChartItems("")
        setSelectedChartItem({});
        setPageFilterConditions([]);
        if (clearDateRange) {
            setClickedItems({});
            setDateRange(null);
        } else {
            const payload = { ...clickedItems?.[inboundOutBound] };
            setClickedItems((prev) => {
                return {
                    ...prev,
                    [inboundOutBound]: {
                        ...prev?.[inboundOutBound],
                        publishConditions: [],
                        filterConditions: payload?.filterConditions
                    }
                }
            });
        }
    }

    const handleChange = (event, type) => {
        setDateFormat((prev) => {
            return {
                ...prev,
                [type]: event.target.value
            }
        });
    }

    const onFilterChange = (value) => {
        console.log('date change ::: *** ', value);
        if (!value) {
            setClickedItems({})
        }
        setDateRange(value);
    }

    return (
        <Box className={classes.mainBox}>
            <Box className={classes.filterBox}>
                <DashboardFilters 
                    {...props}
                    key={chartUniqueId}
                    pageFilterConditions={pageFilterConditions}
                    setPageFilterConditions={setPageFilterConditions}
                />
                {clickedItems && Object.keys(clickedItems).length > 0 && (
                    <Button 
                        variant='outlined'
                        key={'reset-selected-chart'}
                        className={classes.resetSelection}
                        onClick={resetChartSelection}
                    >
                        Reset
                    </Button>
                )}
            </Box>
            <>
                {props?.formData?.[`${chartUniqueId}_pb2_loading`] && (
                    <Backdrop className={classes.backdrop} open={true}>
                        <Loader />
                    </Backdrop>
                )}
                <Paper elevation={1} className={classes.paper}>
                    {labelCardBoxValues && labelCardBoxValues?.length > 0 &&(
                        <Box className={classes.labelBox}>
                            <LabelColorBoxes cardsData={labelCardBoxValues} chartUniqueId={chartUniqueId} />
                        </Box>
                    )}
                </Paper>
                <Grid container spacing={1}>
                    {dashboardData?.inboundOutboundData && (
                        <Grid item xs={12}>
                            <Paper elevation={1} className={classes.paper}>
                                <Box className={classes.formatBox}>
                                    <h3 className={classes.heading}>Inbound Vs Outbound</h3>
                                    <Box style={{ display: 'flex', gap: '14px' }}>
                                        <Box>
                                            <DateRangePicker label=""
                                                dateRange={dateRange}
                                                onDateRangeChange={(val) => onFilterChange(val)}
                                            />
                                        </Box>
                                        <FormControl className={classes.formControl}>
                                            <Select
                                                value={dateFormat?.[inboundOutBound]}
                                                onChange={(event) => handleChange(event, inboundOutBound)}
                                                displayEmpty
                                                labelId="chart-date-format"
                                                id="chart-date-format"
                                            >
                                                <MenuItem value={'MM/DD/YYYY'}>MM/DD/YYYY</MenuItem>
                                                <MenuItem value={'MM/YYYY'}>MM/YYYY</MenuItem>
                                                <MenuItem value={'YYYY'}>YYYY</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <BarChart
                                    {...props}
                                    data={dashboardData?.inboundOutboundData} 
                                    id={chartUniqueId}
                                    handleDashboardItemClick={(selectedChartItem) => {
                                        handleDashboardItemClick(selectedChartItem.name, inboundOutBound, selectedChartItem, 'inboundVsOutbound')
                                    }}
                                />
                            </Paper>
                        </Grid>
                    )}
                    {userProductivityData && Object.keys(userProductivityData).length > 0 && (
                        <Grid item xs={12}>
                            <Paper elevation={1} className={classes.paper}>
                                <Box className={classes.formatBox}>
                                    <Box />
                                    <h3 className={classes.heading}>User Productivity by Day (No. of Documents)</h3>
                                    <FormControl className={classes.formControl}>
                                        <Select
                                            value={dateFormat?.[productivityById]}
                                            onChange={(event) => handleChange(event, productivityById)}
                                            displayEmpty
                                            labelId="chart-date-format"
                                            id="chart-date-format"
                                        >
                                            <MenuItem value={'MM/DD/YYYY'}>MM/DD/YYYY</MenuItem>
                                            <MenuItem value={'MM/YYYY'}>MM/YYYY</MenuItem>
                                            <MenuItem value={'YYYY'}>YYYY</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <UserProductivityTable 
                                    userList={userProductivityData?.userList}
                                    headingData={userProductivityData?.headingData}
                                />
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            </>
        </Box>
    );
}

const mapDispatchToProps = {
    fetchProcessDashboard2Data,
    getModelDataDropDown,
    setSelectedChartItems
};

const mapStateToProps = state => {
	return {
        formData: state.form,	
        user: state.user,
        dataLabel: state.chart.data,
	};
};
  
export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProcessDashboard2)));