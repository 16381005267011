import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { ContactDsilo } from '../services/contactDsilo'
import { ActionTypes } from 'constants/index';


async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

function*  submitContactDsilo(action) {
    try {
    
        const data = yield fetchAsync(ContactDsilo.submitContactDsilo, action.payload);
        yield put({ type: ActionTypes.SUBMIT_CONTACT_DSILO_DATA_SUCCESS, payload: data });
    } catch (e) {
        yield put({ type: ActionTypes.SUBMIT_CONTACT_DSILO_DATA_ERROR, payload: e });
    }
}


export function* watchContactDsiloSaga() {
    yield takeEvery(ActionTypes.SUBMIT_CONTACT_DSILO_DATA_LOADING,  submitContactDsilo);

}

export default watchContactDsiloSaga;