import React from 'react'
import Home from "./components/Wrapper";
import Add from './components/Add/index';
import Formula from './components/Formula';
import { ContextProvider } from './components/Context';

const FormulaGenerator = (props) => {
    return <ContextProvider>
      <Formula />
    </ContextProvider> 
}

export default FormulaGenerator