import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { withRouter } from 'react-router-dom';
import View from './View'
import Redux from './Redux'
import { updateTableData, setDatafromApi } from 'packages/common'
import ConfirmDialog from 'components/confirmDialog/confirmDialog'
import { requestSort } from 'modules/common';
import { PUBLIC_PATH } from 'constants/index';

const Container = (props) => {
	const [state, setState] = useState({
		order: 'asc',
		orderBy: null,
		selected: [],
		tableData: [],
		filterTableData: [],
		page: 0,
		count: 10,
		rowsPerPage: 10,
		searchText: "",
		filterList: [],
		filterData: []
	})

	const [open, setOpen] = React.useState(false);
	const [businessRule, setBusinessRule] = React.useState({});

	const handleChangePage = (event, page) => {
		setState({ ...state, page: page });
	};

	const handleChangeRowsPerPage = event => {
		setState({ ...state, rowsPerPage: event.target.value, page: 0 });
	};

	const onRowClick = (rowData, rowMeta) => { }

	const searchHandler = (e) => {
		setState({ ...state, searchText: e ? e.target.value : "" });
	}

	const handleInputChange = (event) => {
		let obj = state.filterTableData;
		let list = obj.columns;
		list.map((item, index) => {
			if (item.name === event.target.value) list[index].checked = event.target.checked
		})
		obj.columns = [...list]
		setState({ ...state, filterTableData: obj });
		setState({ ...updateTableData(state) });
	}

	const resetFilters = (event) => {
		setState({ ...state, filterList: [] });
	}

	const handleDropdownChange = (event, index, column) => {
		const value = event.target.value === "All" ? [] : [event.target.value];
		// filterUpdate(index, value, column, 'dropdown');
		let list = state.filterList;
		list[index] = event.target.value;
		setState({ ...state, filterList: [...list] });
		setState({ ...updateTableData(state) });
	};

	const handleRequestSort = (event, property) => {
		let obj = requestSort(event, property, state);
		setState({ ...state, ...obj });
	};

	const handleSelectAllClick = event => {
		setState({ ...state, selected: handleTableSelectAllClick(event, state.filterTableData.datasets) });
	};


	const tableCrudOperations = (item, type) => {
		if (type === "edit") {
			props.history.push({ pathname: `/${PUBLIC_PATH}${props.match.params.appid}/page/ruleview/${item._id}` });
		}
		if (type === "delete") {
			setBusinessRule(item);
			setOpen(true);
		}
	}

	const handleAddClick = () => props.history.push({ pathname: `/${PUBLIC_PATH}${props.match.params.appid}/page/ruleview/new` });

	const handleCheck = (event, id) => {
		const { selected } = state;
		let newSelected = handleSelectedRow(event, id, selected)
		setState({ ...state, selected: newSelected });
	};

	useEffect(() => {
		setState({ ...updateTableData(state) });
	}, [state.searchText, state.filterList]);


	useEffect(() => {
		const params = props.match.params;
		const { appid } = params;
		console.log("params======", params);
		props.getBusinessRules(appid);
	}, []);

	const updateRulesState = () => {
		const rows = [
			{
				id: 'name',
				numeric: false,
				disablePadding: false,
				label: 'Name',
				sort: true
			},
			{
				id: 'description',
				numeric: false,
				disablePadding: false,
				label: 'Description',
				sort: true
			}
		];
		let keysArr = ['_id', 'active', 'ruleActions', 'appId', '__v', 'ruleConditions',
			'ruleContexts', 'ruleset', 'scope', 'dataElements'];
		console.log("props.rules?.data====", props.rules?.data);
		let obj = setDatafromApi(props.rules?.data, keysArr, state, rows)
		console.log("obj=====?===", obj);
		setState({ ...obj });
	}

	useEffect(() => {
		console.log("props.rules?.data====", props.rules?.data);
		if (props.rules?.data) {
			updateRulesState()
		}
	}, [props.rules?.data]);

	return (<>
		<View
			{...props}
			state={state}
			handleChangePage={handleChangePage}
			handleChangeRowsPerPage={handleChangeRowsPerPage}
			onRowClick={onRowClick}
			searchHandler={searchHandler}
			handleCheck={handleCheck}
			handleInputChange={handleInputChange}
			resetFilters={resetFilters}
			handleDropdownChange={handleDropdownChange}
			handleRequestSort={handleRequestSort}
			handleSelectAllClick={handleSelectAllClick}
			tableCrudOperations={tableCrudOperations}
			handleAddClick={handleAddClick}
		/>
		<ConfirmDialog
			title="Delete"
			open={open}
			setOpen={setOpen}
			onConfirm={() => props.deleteBusinessRule({ _id: businessRule._id, appid: props.match.params.appid })}
		>
			businessrule {businessRule.name}
		</ConfirmDialog>
	</>)
}

export default hot(withRouter(Redux(Container)))