import React, { useEffect, useState } from 'react';
import { hot } from "react-hot-loader/root";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { history } from 'dsilo-ui-components';
import { Loader } from '../../components/loader';
import jwtService from '../../jwtService';
import { saveAadUserDetails } from '../../store/actions';

const SAMLComponent = (props) => {
	const [samlInfo, setSamlInfo] = useState(null);

	useEffect(() => {
		const checkForUrlParams = () => {
			let urlSearchParams = new URLSearchParams(window.location.search);
			let SAMLResponse = urlSearchParams.get('SAMLResponse');
			if (SAMLResponse) {
				// Encode the XML data to Base64
				const encodedSAMLResponse = window.btoa(SAMLResponse);
				console.log('Base64 Encoded:', encodedSAMLResponse);
				setSamlInfo(encodedSAMLResponse);
			} else {
				console.error('SAML Error:', error.message);
			}
		}
		checkForUrlParams();
	}, []);	

	useEffect(() => {
		try {
			const rawSsoConfig = localStorage.getItem('ssoConfig');
			if (rawSsoConfig && samlInfo) {
				const ssoConfig = JSON.parse(window.atob(rawSsoConfig));
				console.log('ssoConfig ::: *** ', ssoConfig);
				async function processRedirect() {
					// jwtService.setSession(accessToken)
					const samlLoginResponse = await jwtService.validateSAMLResponse(samlInfo);
					console.log('samlLogin Response ::: *** ', samlLoginResponse)
					const userData = {
						...samlLoginResponse?.data,
						isAdLogin: true
					}
					props.saveAadUserDetails(userData);
					localStorage.setItem('isSsoLogin', 'true');
					// localStorage.removeItem('adConfig');
					history.push(`/apploading`);
				}
				processRedirect();
			}
		} catch (cError) {
			console.log('errror in try catch ::: *** ', cError);
		}
	}, [samlInfo]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100vh' }}>
            Authenticating Please wait ...
            <Loader />
        </div>
    )
}


const mapDispatchToProps = {
	saveAadUserDetails
};

const mapStateToProps = ({ user, login, app }) => {
    return {
        login,
        user,
        app
    }
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(SAMLComponent)));
