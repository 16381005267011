import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		backgroundColor: '#181818',
		color: '#fff',
		height: '100vh'
	},
	// card: card,
	AppHeading: {
		fontWeight: 'bold',
		fontSize: '30px',
		padding: '10px'
	},
	titleSearchDiv: {
		display: "flex", 
		marginTop: -15, 
		paddingBottom: 10, 
		paddingRight: 15, 
		justifyContent: "space-between"
	},
	searchRoot: {
		height: theme.spacing(3),
		display: "flex",
		justifyContent: "space-between",
		width: "35%",
		border: "1px solid #b4b2b2 !important",
		borderRadius: "4px !important"
	},
	iconButton: {
		color: theme.palette.action.active,
		transform: "scale(1, 1)",
		transition: theme.transitions.create(["transform", "color"], {
			duration: theme.transitions.duration.shorter,
			easing: theme.transitions.easing.easeInOut,
		}),
	},
	iconButtonHidden: {
		transform: "scale(0, 0)",
		"& > $icon": {
			opacity: 0,
		},
	},
	searchIconButton: {
		marginRight: theme.spacing(-6),
	},
	icon: {
		transition: theme.transitions.create(["opacity"], {
			duration: theme.transitions.duration.shorter,
			easing: theme.transitions.easing.easeInOut,
		}),
	},
	input: {
		width: "100%",
		fontSize: '13px'
	},
	searchContainer: {
		margin: "auto 16px",
		width: `calc(100% - ${theme.spacing(6 + 4)}px)`, // 6 button + 4 margin
	},
}));