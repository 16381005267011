import React from 'react';

import PowerBi from '../../components/PowerBi/PowerBi';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const PowerBiComponent = props => {
    return (<PowerBi {...props} />);
}

const mapDispatchToProps = {};

const mapStateToProps = state => {
    return {
        user: state.user,
        org: state.org
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PowerBiComponent));