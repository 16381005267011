import { ActionTypes } from 'constants/index';

const initialState = {
    app: {},
    loading: false,
    error: '',
    appSave: false,
    datasource: {},
    datasourceSave: false,
    roles: [],
    rolesLoading: false,
    member: null,
    memberLoading: false,
    memberSuccess: false,
    entity: null,
    entityLoading: false,
    supportingDocument: null,
    verifyApp: false,
    verifyAppLoading: false,
    verifiedApp: null,
    noMember: null
};

export default function appConfigReducer(state = initialState, action) {

    switch (action.type) {
        case ActionTypes.LOAD_APP_CONFIG_LOADING: {
            return {
                ...state,
                loading: true,
                error: ''
            };
        }
        case ActionTypes.LOAD_APP_CONFIG_SUCCESS: {
            return {
                ...state,
                app: action.payload,
                loading: false
            }
        }
        case ActionTypes.LOAD_APP_CONFIG_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case ActionTypes.VERIFY_APP: {
            return {
                ...state,
                verifyAppLoading: true,
                verifyApp: false
            }
        }
        case ActionTypes.VERIFY_APP_SUCCESS: {
            return {
                ...state,
                verifiedApp: action.payload,
                verifyAppLoading: false,
                verifyApp: true
            }
        }
        case ActionTypes.VERIFY_APP_FAILURE: {
            return {
                ...state,
                verifiedApp: action.payload,
                verifyAppLoading: false,
                verifyApp: false
            }
        }
        case ActionTypes.SAVE_APP_SUCCESS: {
            return {
                ...state,
                app: action.payload,
                loading: false,
                appSave: true
            }
        }
        case ActionTypes.SAVE_DATASOURCE_SUCCESS: {
            return {
                ...state,
                loading: false,
                datasourceSave: true,
                datasource: action.payload
            }
        }
        case ActionTypes.LOAD_DATAELEMENTS_SUCCESS: {
            return {
                ...state,
                dataelements: action.payload,
                loading: false
            }
        }
        case ActionTypes.LOAD_MEMBER_ERROR: {
            return {
                ...state,
                member: action.payload,
                memberLoading: false
            }
        }
        case ActionTypes.LOAD_MEMBER_SUCCESS: {
            return {
                ...state,
                member: action.payload,
                memberLoading: false
            }
        }
        case ActionTypes.GET_MEMBER_ERROR: {
            return {
                ...state,
                members: action.payload,
                memberLoading: false
            }
        }
        case ActionTypes.GET_MEMBER_SUCCESS: {
            return {
                ...state,
                members: action.payload,
                memberLoading: false,
                memberSuccess:true, 
                noMember: null,
            }
        }
        case ActionTypes.GET_ENTITY_SUCCESS: {
            return {
                ...state,
                entity: action.payload,
                entityLoading: false
            }
        }
        case ActionTypes.LOAD_ROLES_LOADING: {
            return {
                ...state,
                rolesLoading: true
            }
        }
        case ActionTypes.LOAD_ROLES_SUCCESS: {
            return {
                ...state,
                roles: action.payload,
                loading: false
            }
        }
        case ActionTypes.SAVE_DATAMODEL_SUCCESS: {
            return {
                ...state,
                loading: false,
                dataModelSave: true,
                dataModel: action.payload
            }
        }
        case ActionTypes.SAVE_SUPPORTING_DOCUMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                supportingDocument: { ...action.payload }
            }
        }
        case ActionTypes.SAVE_SUPPORTING_DOCUMENT_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        case ActionTypes.NO_MEMBER_FOUND: {
            return {
                ...state,
                noMember: true,
            }
        }
        default: {
            return state;
        }
    }
}